import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatCard } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-zone-information',
    templateUrl: './zone-information.component.html',
    styleUrls: ['./zone-information.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, NgFor, MatCard]
})
export class ZoneInformationComponent implements OnInit, OnDestroy, AfterViewInit {
	selectedLanguage: string;
	apiDetails: {};
	images = Images;
	aboutUsLabels: any;
	isLoading: boolean;
	serverErrorMessage: string;
	isLoaded = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	zoneData: any;
	zoneArray: any[];
	serverMessage: string;
	isServerMessage: boolean;

	/**
	 * angular method for declaring packages in variables
	 * 
	 * which runs first in this particulat component
	 */
	constructor(
		private router: Router,
		private store: Store<fromStore.CEPState>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		public dialog: MatDialog
	) {
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		this.store.dispatch(new fromStore.GetZoneDetails({
			url: this.apiDetails['applicationConfigurations'].base_url +
				this.apiDetails['applicationConfigurations']['aboutUs'].getZoneInfo.url,
			method: this.apiDetails['applicationConfigurations']['aboutUs'].getZoneInfo.method
		}));
	}

	/**
	 * angular method which gets called on page initialization
	 */
	ngOnInit() {
		// server error handling
		this.store.select(fromStore.getServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverError;
						if (this.serverMessage) {
							this.isServerMessage = true;
							console.error(this.serverMessage);
						}
					}
					this.serverMessage = null;
				}
			});

		this.store.select(fromStore.getAboutUsLabels)
			.subscribe(response => {
				this.aboutUsLabels = response;
			});

		this.store.select(fromStore.getIsZoneDetailsLoading)
			.subscribe(loading => {
				this.isLoading = loading;
			});

		this.store.select(fromStore.getZoneDetailsServerErrorMessage)
			.subscribe((serverErrorMessage) => {
				if (serverErrorMessage != null) {
					this.serverErrorMessage = serverErrorMessage;
					console.error(this.serverErrorMessage);
				}
			});

		this.store.select(fromStore.getIsZoneDetailsLoaded)
			.subscribe(loaded => {
				this.isLoaded = loaded;
				if (this.isLoaded) {
					this.store.select(fromStore.getZoneDetailsResult)
						.subscribe((res) => {
							this.zoneData = res['zonesInfo'];
							this.zoneArray = []; // array of zone with its value from response
							Object.entries(this.zoneData).forEach((item, index) => {
								this.zoneArray.push(item);
							});
						});
				}
			});
	}
	ngOnDestroy() {
		this.serverErrorMessage = null;
	}
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

}
