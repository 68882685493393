<h2 mat-dialog-title class="popup-heading" style="background-color:#FFE0C3;">{{Labels[selectedLanguage].heading}}
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</h2>

 <div mat-dialog-content class="popup-content">

<div class="disc-content">
  <span class="disc-text-disc">{{
    Labels[selectedLanguage].steps }}</span>
 </div>
 </div>

