<!-- /******
 * Component -  blog component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */ -->
 <div class="feed-card">
    <div class="feed-image">
      <!-- image of card -->
      <img 
        [routerLink]="['/cep/feeds/product/', productData.engagementId]"
        src="{{ productData.image[0]}}" loading="lazy" alt="..."/>
    </div>
    <!-- main content -->
    <div class="feed-content">
      <div class="feed-tag" (mouseenter)="speakText(productData.engagementType)"  (mouseleave)="stopSpeaking()">
        <p>{{ productData.engagementType | uppercase }}</p>
      </div>
      <div
        class="feed-heading"
        (mouseenter)="speakText(productData[selectedLanguage].productName)"  (mouseleave)="stopSpeaking()"
        [routerLink]="['/cep/feeds/product/', productData.engagementId]"
      >
        <p class="font-card-heading">{{ productData[selectedLanguage].productName }}</p>
      </div>
      <div
        class="feed-desc"
        (mouseenter)="speakText(productData[selectedLanguage].description)"  (mouseleave)="stopSpeaking()"
        [routerLink]="['/cep/feeds/product/', productData.engagementId]"
      >
        <p>{{ productData[selectedLanguage].description }}</p>
      </div>
      <div class="blog-footer">
        <div class="feed-date" (mouseenter)="speakText('product added by' + productData.createdBy)"  (mouseleave)="stopSpeaking()">{{ blogDate }} | {{ productData.createdBy }}</div>
        <div
          class="blog-action-logo"
          [ngClass]="{ 'login-active-favourite': !this.userId }"
        >
        <!-- share options -->
        <div class="abuse-flag-container">
          <!-- <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon> -->
      </div>
          <!-- <div class="share-logo" (click)="clickOnShare()" 
          (mouseenter)="speakText('share')"  (mouseleave)="stopSpeaking()">
        
            <i class="material-icons" [matMenuTriggerFor]="menu">
              share
            </i> -->
            <div class="share-logo" (click)="clickOnShare()"><i class="material-icons" >
              share
            </i>
          </div>
          <!-- <mat-menu #menu="matMenu">
            <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')" (mouseenter)="speakText('share on Facebook')"  (mouseleave)="stopSpeaking()">
              <img class="social-share-img" src="{{image.facebookShareImg}}">
              Facebook
            </button>
            <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on Twitter')"  (mouseleave)="stopSpeaking()">
              <img class="social-share-img" src="{{image.twitterShareImg}}">
              Twitter
            </button>
            <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on LinkedIn')"  (mouseleave)="stopSpeaking()">
              <img class="social-share-img" src="{{image.linkedinShareImg}}">
              LinkedIn
            </button>
            <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on Whatsapp')"  (mouseleave)="stopSpeaking()"> 
              <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
              Whatsapp
            </button>
          </mat-menu>  -->
          <div *ngIf="this.userId">
            <i
              class="material-icons favorite-icon"
              (mouseenter)="speakText('add to favorites')"  (mouseleave)="stopSpeaking()"
              [ngClass]="{ 'active-favorite': productData.favourite }"
              (click)="unMarkFavorite()"
            >
              {{ favorite }}
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
  