<!-- *******************************************************************
*  Component -  User Profile
   Created By - Prajakta Patil
   Created On - 04/09/2019
   Updated By - Prajakta Patil
   Updated On - 22/09/2019
*****************************************************************  -->
<!-- Spinner code -->
<div *ngIf="isLoading && isProfileLoading" class="spinner">
  <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div>
<!-- connection lost condition -->
<div *ngIf="serverMessage" class="connectionDiv">
  <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
  <span>{{serverMessage}}</span>
</div>
<!-- main page -->
<div *ngIf="!isProfileLoading && !isServerMessage" class="main-container">
  <mat-card class="container">
    <div class="profile-content">
      <!-- **************** profile image ********************-->
      <div class="profile-container">
        <div class="profile-pic">
          <div for="FileInput" class="profile-image">
            <img class="selected-image" [src]="imgURL" *ngIf="imgURL" loading="lazy" alt="...">
            <input id="FileInput" type='file' style="display: none" accept="image/png,image/jpeg,image/jpg"
              (change)="onSelectFile($event)">
            <ng-container *ngIf="imgURL; then selectedImgBlock else initialImg"></ng-container>
            <ng-template #initialImg>
              <img class="initialProfilePic"
                src="{{(UserProfileDetails.profilePhoto) ? UserProfileDetails.profilePhoto : images.defaultProfilePicture}}"
                loading="lazy" alt="...">
              <label class="upload-profile-pic" (mouseenter)="speakText('update profile picture')"
                (mouseleave)="stopSpeaking()">
                <input id="FileInput" class="choose-img" #file style="display: none" type="file"
                  accept="image/png,image/jpeg,image/jpg" (change)="onSelectFile($event)" />
              </label>
              <label for="FileInput" (mouseenter)="speakText('update profile picture')" (mouseleave)="stopSpeaking()">
                <img class="camera" src={{images.cameraImage}} loading="lazy" alt="...">
              </label>
            </ng-template>
            <ng-template #selectedImgBlock>
              <label class="upload-profile-pic  ">
                <input id="FileInput" class="choose-img" #file style="display: none" type="file"
                  accept="image/png,image/jpeg,image/jpg" (change)="onSelectFile($event)" />
              </label>
              <label for="FileInput" (mouseenter)="speakText('update profile picture')" (mouseleave)="stopSpeaking()">
                <img class="camera" src={{images.cameraImage}} loading="lazy" alt="...">
              </label>
            </ng-template>
            <label for="FileInput" (mouseenter)="speakText('update profile picture')" (mouseleave)="stopSpeaking()">
              <img class="camera" src={{images.cameraImage}} loading="lazy" alt="...">
            </label>
          </div>

          <div class="user-profile-name font-medium">

            <label class="user-name font-medium"
              (mouseenter)="speakText(UserProfileDetails.firstName + UserProfileDetails.middleName + UserProfileDetails.lastName)"
              (mouseleave)="stopSpeaking()">{{UserProfileDetails.firstName | titlecase}}
              {{UserProfileDetails.middleName | titlecase}} {{UserProfileDetails.lastName | titlecase}}</label>
            <label class="mobile-number font-medium" (mouseenter)="speakText(UserProfileDetails.mobile)"
              (mouseleave)="stopSpeaking()"> {{UserProfileDetails.mobile}}
              <!-- <img class="updateProfileIcon" src={{images.updateMobileNoIcon}} (click)="redirectToChangeMobileNumber()"> -->
            </label>
            <label class="mobile-number font-medium"
              (mouseenter)="speakText(updatelabels[selectedLanguage].lastLogin + UserProfileDetails.lastLogin)"
              (mouseleave)="stopSpeaking()">
              {{updatelabels[selectedLanguage].lastLogin}}:
              {{UserProfileDetails.lastLogin | date: 'fullDate'}} {{ UserProfileDetails.lastLogin | date:'shortTime'}}
            </label>

          </div>
        </div>
      </div>
      <!-- ********************** Other details **********************-->
      <div class="basic-details">
        <div class="profile-detail-information">

          <mat-card class="user-detail-card">
            <!-- edit icon -->
            <div style="position: relative;">
              <div class="update-circle" (click)='editProfilePopup()' (mouseenter)="speakText('update profile')"
                (mouseleave)="stopSpeaking()">
                <img class="pencil-icon" src={{images.pencilImage}} loading="lazy" alt="...">
              </div>
            </div>
            <!-- details list -->
            <mat-list class="user-detail-field">
              <div class="user-detail">
                <mat-list-item>
                  <mat-icon class="icon-style" mat-list-icon>
                    <img class="envelope-image-icon" src={{images.envelopeImage}} loading="lazy" alt="...">
                  </mat-icon>
                  <div style="float: right;line-height: normal;">
                    <mat-label class="label-font font-medium" style="display: block;" mat-line (mouseenter)="speakText(updatelabels[selectedLanguage].email + emailId)" (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].email}}</mat-label>
                    <mat-label mat-line class="cropContent font-normal" style="display: block;">{{emailId}}</mat-label>
                  </div>
                </mat-list-item>
                <mat-list-item *ngIf="!UserProfileDetails.empCode">
                  <mat-icon class="icon-style" mat-list-icon>
                    <img class="envelope-image-icon" src={{images.calenderImage}} loading="lazy" alt="...">
                  </mat-icon>
                  <div style="float: right;line-height:normal;">
                    <mat-label class="label-font font-medium" style="display: block;" mat-line (mouseenter)="speakText(updatelabels[selectedLanguage].dateOfBirth)" (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].dateOfBirth}}</mat-label>
                    <mat-label mat-line class="font-normal" style="display: block;" (mouseenter)="speakText(UserProfileDetails.DOB)" (mouseleave)="stopSpeaking()">{{ UserProfileDetails.DOB | date: 'dd/MM/yyyy'}}</mat-label>
                  </div>
                </mat-list-item>
              </div>

              <div class="user-detail">
                <mat-list-item>
                  <mat-icon class="icon-style" mat-list-icon>
                    <img class="envelope-image-icon" src={{images.genderImage}} loading="lazy" alt="...">
                  </mat-icon>
                  <div style="float: right;line-height:normal">
                    <mat-label class="label-font font-medium" mat-line
                    (mouseenter)="speakText(updatelabels[selectedLanguage].gender)" style="display: block;"
                    (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].gender}}</mat-label>
                    <mat-label mat-line class="font-normal" (mouseenter)="speakText(gender)" style="display: block;"
                      (mouseleave)="stopSpeaking()">{{ gender }}</mat-label>
                  </div>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon class="icon-style" mat-list-icon><img class="envelope-image-icon"
                      src={{images.locationImage}} loading="lazy" alt="..."></mat-icon>
                  <div style="float: right;line-height: normal;">
                      <mat-label class="label-font font-medium" mat-line style="display: block;" 
                      (mouseenter)="speakText(updatelabels[selectedLanguage].address)"
                      (mouseleave)="stopSpeaking(updatelabels[selectedLanguage].address)">{{
                      registerLabels[selectedLanguage].address }}</mat-label>
                      <mat-label class="display-content font-normal" mat-line *ngFor="let address of address" style="display: block;" 
                      (mouseenter)="speakText(address)" (mouseleave)="stopSpeaking()">{{address}}</mat-label>
                  </div>
                </mat-list-item>
              </div>
              <!-- <div class="user-detail">
                <mat-list-item>
                  <mat-icon class="icon-style" mat-list-icon><img class="envelope-image-icon"
                      src={{images.fingerPrintImage}}></mat-icon>
                  <mat-label class="label-font font-medium" mat-line (mouseenter)="speakText(updatelabels[selectedLanguage].aadharNumber)"  (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].aadharNumber}}</mat-label>
                  <mat-label mat-line class="cropContent font-normal" (mouseenter)="speakText(aadharCard)"  (mouseleave)="stopSpeaking()">{{ aadharCard }}</mat-label>
                </mat-list-item>
                <mat-list-item>
                  <mat-icon class="icon-style" mat-list-icon><img class="envelope-image-icon"
                      src={{images.drivingLicenseImage}}></mat-icon>
                  <mat-label class="label-font font-medium" mat-line (mouseenter)="speakText(updatelabels[selectedLanguage].drivingLicenseNumber)"  (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].drivingLicenseNumber}}
                  </mat-label>
                  <mat-label mat-line class="cropContent font-normal" (mouseenter)="speakText(drivingLicence)"  (mouseleave)="stopSpeaking()">{{ drivingLicence }}</mat-label>
                </mat-list-item>
              </div> -->

              <div class="user-detail" *ngIf="UserProfileDetails.empCode">
                <mat-list-item>
                  <!-- <mat-icon class="icon-style" mat-list-icon><img class="envelope-image-icon"
                      src={{images.fingerPrintImage}}></mat-icon> -->
                  <mat-icon style="color: #707070;" class="icon-style" mat-list-icon>person_outline</mat-icon>
                  <mat-label class="label-font font-medium" mat-line
                    (mouseenter)="speakText(updatelabels[selectedLanguage].employeeCode)"
                    (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage]. employeeCode}}</mat-label>
                  <mat-label mat-line class="cropContent font-normal" (mouseenter)="speakText(aadharCard)"
                    (mouseleave)="stopSpeaking()">{{ UserProfileDetails.empCode }}</mat-label>
                </mat-list-item>
                <mat-list-item>
                  <!-- <mat-icon class="icon-style" mat-list-icon><img class="envelope-image-icon"
                      src={{images.drivingLicenseImage}}></mat-icon> -->
                  <mat-icon style="color: #707070;" class="icon-style" mat-list-icon>work_outline</mat-icon>
                  <mat-label class="label-font font-medium" mat-line
                    (mouseenter)="speakText(updatelabels[selectedLanguage]. employeeDesignation)"
                    (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].employeeDesignation}}

                  </mat-label>
                  <mat-label mat-line class="cropContent font-normal" (mouseenter)="speakText(drivingLicence)"
                    (mouseleave)="stopSpeaking()">{{ UserProfileDetails.officerDesignation }}</mat-label>
                </mat-list-item>
              </div>
            </mat-list>
          </mat-card>
        </div>
        <div class="preference-notification-card">
          <!-- preferences card -->
          <mat-card class="preference-card">
            <mat-card-title class="bottom-card-title font-medium"
              (mouseenter)="speakText(updatelabels[selectedLanguage].preferences)"
              (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].preferences}}</mat-card-title>
            <div class="preference-button-container">
              <div *ngFor="let preference of preferencesValuelist ; let id = index">
                <button id="status" class="preference-button font-normal" mat-stroked-button
                  (mouseenter)="speakText(preference.choosePreference[1])" (mouseleave)="stopSpeaking()"
                  (click)="changePreferenceChoice(preference,id)" [ngClass]="{'button-color': preference.status==true}">
                  {{preference.choosePreference[1]}}</button>
              </div>
            </div>
          </mat-card>
          <!-- notification card -->
          <mat-card class="notification-card">
            <mat-card-title class="bottom-card-title font-medium"
              (mouseenter)="speakText(updatelabels[selectedLanguage].notifications)"
              (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].notifications}}</mat-card-title>
            <div class="notification-slide-toggle">
              <mat-slide-toggle labelPosition="before" (change)="changeIsEmail($event)" [checked]="UserProfileDetails.isEmail"
                class="toggle-button font-normal"
                (mouseenter)="speakText('allow to send ' + updatelabels[selectedLanguage].email)"
                (mouseleave)="stopSpeaking()">{{updatelabels[selectedLanguage].email}}</mat-slide-toggle>
              <mat-slide-toggle labelPosition="before" (change)="updateIsSms($event)" [checked]="UserProfileDetails.isSMS"
                (mouseenter)="speakText('allow to send ' + updatelabels[selectedLanguage].SMS)"
                (mouseleave)="stopSpeaking()"
                class="toggle-button font-normal">{{updatelabels[selectedLanguage].SMS}}</mat-slide-toggle>
              <mat-slide-toggle labelPosition="before" (change)="updateIsNotification($event)" [checked]="UserProfileDetails.isNotification"
                (mouseenter)="speakText('allow to send ' + updatelabels[selectedLanguage].pushNotification)"
                (mouseleave)="stopSpeaking()"
                class="toggle-button font-normal">{{updatelabels[selectedLanguage].pushNotification}}</mat-slide-toggle>
            </div>
          </mat-card>
        </div>

        <!-- password card -->
        <div class="passward-container" *ngIf="!UserProfileDetails.empCode">
          <!-- <mat-card class="passward-field-card"> -->
          <mat-expansion-panel [expanded]="panelOpenState">
            <mat-expansion-panel-header>
              <mat-panel-title class="font-normal"
                (mouseenter)="speakText('click to ' + updatelabels[selectedLanguage].changePassword)"
                (mouseleave)="stopSpeaking()">
                {{updatelabels[selectedLanguage].changePassword}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="field-one">
              <form class="pass-form" [formGroup]="myForm">
                <div class="old-passward-container">
                  <mat-form-field floatLabel="auto" appearance="outline" class="old-passward-field"
                    (mouseenter)="speakText(updatelabels[selectedLanguage].oldPassword)" (mouseleave)="stopSpeaking()">
                    <mat-label
                      style="margin-top: 5px; font-size: 12px;  padding-left: 40px; padding-right: 0 !important;"
                      class="label font-normal">{{updatelabels[selectedLanguage].oldPassword}}
                    </mat-label>
                    <input [type]="hidepass ? 'password' : 'text'" autocomplete="off" matInput
                      [formControl]="oldpassword" [(ngModel)]="oldPassword" name="name" (input)="trimPassword()">
                    <mat-icon class="eye-mat-icon" matSuffix (click)="hidepass = !hidepass">
                      {{hidepass ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                  </mat-form-field>
                  <mat-error style="font-size: smaller;" *ngIf="oldpassword.invalid"
                    (mouseenter)="speakText(getOldPasswordErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getOldPasswordErrorMessage()}}</mat-error>
                </div>


                <div class="new-passward-container mt-2">
                  <div class="passward-new">
                    <mat-form-field floatLabel="auto" appearance="outline" class="old-passward-field"
                      (mouseenter)="speakText(updatelabels[selectedLanguage].newPassword)"
                      (mouseleave)="stopSpeaking()">
                      <mat-label
                        style="margin-top: 5px; font-size: 12px; padding-left: 40px; padding-right: 0 !important;"
                        class="label font-normal">{{updatelabels[selectedLanguage].newPassword}}
                      </mat-label>
                      <input [type]="hide ? 'password' : 'text'" autocomplete="off" matInput formControlName="password"
                        [(ngModel)]="pass" name="name" class="password-input">
                      <mat-icon class="eye-mat-icon" matSuffix (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
                    </mat-form-field>

                    <mat-error *ngIf="myForm.hasError('required','password')" class="password-error"
                      (mouseenter)="speakText(registerLabels[selectedLanguage].thisFieldIsRequired)"
                      (mouseleave)="stopSpeaking()">
                      {{registerLabels[selectedLanguage].thisFieldIsRequired}}
                    </mat-error>
                    <mat-error style="font-size: smaller;" *ngIf="myForm.hasError('pattern', 'password')"
                      class="password-error" (mouseenter)="speakText(registerLabels[selectedLanguage].passwordLength)"
                      (mouseleave)="stopSpeaking()">
                      {{registerLabels[selectedLanguage].passwordLength}}
                    </mat-error>
                  </div>
                  <div class="confirm-password">
                    <mat-form-field floatLabel="auto" appearance="outline" class="form-field"
                      (mouseenter)="speakText(registerLabels[selectedLanguage].confirmPassword)"
                      (mouseleave)="stopSpeaking()">
                      <mat-label style="font-size: 12px;  padding-left: 40px; padding-right: 0 !important;"
                        class="label font-normal">
                        {{registerLabels[selectedLanguage].confirmPassword}}
                      </mat-label>
                      <input [type]="cPasshHide ? 'password' : 'text' " autocomplete="off" [(ngModel)]='confirmPass'
                        formControlName="confirmPassword" [errorStateMatcher]="matcher" name="name" matInput>
                      <mat-icon class="eye-mat-icon" matSuffix (click)="cPasshHide = !cPasshHide">
                        {{cPasshHide ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
                      <mat-error style="font-size: larger;margin-top: 3px;padding-bottom: 2px;"
                        *ngIf="myForm.hasError('notSame')"
                        (mouseenter)="speakText(registerLabels[selectedLanguage].passwordDoNotMatch)"
                        (mouseleave)="stopSpeaking()">
                        {{registerLabels[selectedLanguage].passwordDoNotMatch}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <!-- <div class="new-passward-container mt-2">
                  <mat-form-field floatLabel="auto" appearance="outline" class="new-passward-field mt-2"
                    (mouseenter)="speakText(updatelabels[selectedLanguage].newPassword)"  (mouseleave)="stopSpeaking()">
                      <mat-label style="font-size: 12px;" class="label font-normal">{{updatelabels[selectedLanguage].newPassword}}
                      </mat-label>
                      <input [type]="hide ? 'password' : 'text'" autocomplete="off" matInput formControlName="password"
                        [(ngModel)]="pass" name="name" class="password-input">
                      <mat-icon class="eye-mat-icon" matSuffix (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}
                      </mat-icon>

                      
                      <mat-error *ngIf="myForm.hasError('required','password')" class="password-error"
                      (mouseenter)="speakText(registerLabels[selectedLanguage].thisFieldIsRequired)"  (mouseleave)="stopSpeaking()">
                        {{registerLabels[selectedLanguage].thisFieldIsRequired}}
                      </mat-error>
                      <mat-error style="font-size: smaller;" *ngIf="myForm.hasError('pattern', 'password')" class="password-error"
                      (mouseenter)="speakText(registerLabels[selectedLanguage].passwordLength)"   (mouseleave)="stopSpeaking()">
                        {{registerLabels[selectedLanguage].passwordLength}} 
                      </mat-error>
                      <mat-hint (mouseenter)="speakText(registerLabels[selectedLanguage].passwordLength)"  (mouseleave)="stopSpeaking()">
                        {{registerLabels[selectedLanguage].passwordLength}}
                      </mat-hint>
                    </mat-form-field>

                  <mat-form-field floatLabel="auto" appearance="outline" class="form-field" 
                    (mouseenter)="speakText(registerLabels[selectedLanguage].confirmPassword)"  (mouseleave)="stopSpeaking()">
                      <mat-label style="font-size: 12px;" class="label font-normal">
                        {{registerLabels[selectedLanguage].confirmPassword}}
                      </mat-label>
                      <input [type]="cPasshHide ? 'password' : 'text'" autocomplete="off" [(ngModel)]='confirmPass'
                        formControlName="confirmPassword" [errorStateMatcher]="matcher" name="name" matInput>
                      <mat-icon class="eye-mat-icon" matSuffix (click)="cPasshHide = !cPasshHide">
                        {{cPasshHide ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
                      <mat-error style="font-size: larger;margin-top: 3px;padding-bottom: 2px;" *ngIf="myForm.hasError('notSame')"
                      (mouseenter)="speakText(registerLabels[selectedLanguage].passwordDoNotMatch)"  (mouseleave)="stopSpeaking()">
                        {{registerLabels[selectedLanguage].passwordDoNotMatch}}
                      </mat-error>
                    </mat-form-field>
                </div> -->
                <div class="change-passward">
                  <button (click)="verifyPasswordStrength()" class="change-passward-button font-medium"
                    mat-stroked-button [disabled]="oldpassword.invalid || myForm.hasError('required','password') ||
                                myForm.hasError('pattern', 'password') || myForm.hasError('notSame')"
                    *ngIf="oldPassword && pass && confirmPass "
                    (mouseenter)="speakText(updatelabels[selectedLanguage].changePassword)"
                    (mouseleave)="stopSpeaking()">
                    {{updatelabels[selectedLanguage].changePassword}}
                  </button>
                </div>
              </form>
            </div>
          </mat-expansion-panel>
          <!-- </mat-card> -->
        </div>
      </div>
    </div>
  </mat-card>
</div>
