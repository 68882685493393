import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromStore from '../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackPopupComponent } from '../Merchant-flow/feedback-popup/feedback-popup.component';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../services/localstorage.service';
import { ObjectInterface } from '../../models/global-interface';
import { EncrDecrService } from '../../services/encr-decr.service';
import { from } from 'rxjs';
import { Subject } from 'rxjs';
import { CommonModule, NgIf } from '@angular/common';
import { MatCard } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-thank-you-for-deals',
    //   standalone: true,
    //   imports: [CommonModule],
    //   schemas:[CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './thank-you-for-deals.component.html',
    styleUrls: ['./thank-you-for-deals.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatCard]
})
export class ThankYouForDealsComponent implements OnInit {
  /**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	labels={};
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * Share Url variable for social sharing
	 */
	shareUrl: any;
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails : ObjectInterface;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	updateTrasCode = 0;
	transactionUpdate: {};
	transactionId: any;
	price: any;
	public ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(private store: Store<fromStore.CEPState>,
    private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute
  ) { 
    this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});
  }

  ngOnInit() {

     // token from localstorage
     this.store.select(fromStore.getToken)
     .subscribe((token) => {
       this.token = token;
       if (this.token) {
         const localUserId = this.localStorageService.getUserId();
         this.userId = this.encrDecr.get(localUserId);
       }
   });
   this.activeRoute.params.subscribe((param) => {
	this.transactionId = param['transactionId'];
	this.price = param['price']
	if(this.transactionId && this.price){
		if (Object.keys(this.apiDetails).length > 0) {
			this.shareUrl = this.apiDetails['shareUrl'];
			this.store.dispatch(new fromStore.UpdateTransactionStatus({
			  url: this.apiDetails['user'].base_url +
			  this.apiDetails['user'].updateTransactionStatus.url,
			  method: this.apiDetails['user'].updateTransactionStatus.method,
			  body: {
				userId : this.userId,
				transactionId : this.transactionId,
				status : 'success',
				prize : this.price
			  }
			}));
		  }
	}
});

  // server error handling
  this.store.select(fromStore.getMoreServicesNetworkError)
  .subscribe((serverErrorMessage) => {
    if (serverErrorMessage != null) {
      if (serverErrorMessage === 'InvalidCredentials') {
      this.router.navigate(['/Login/login']);
      } else {
      this.serverMessage = serverErrorMessage;
      this.isServerErrorMessage = true;
      console.error(this.serverMessage);
      }
    }
  });

   /**
	 * Created by neha
	 * Start Transaction
	 */

  this.store.select(fromStore.isStartTransactionLoading)
	.subscribe(loading => {
		this.isLoading = loading;
	});
	this.store.select(fromStore.getNewsletterLabels)
	.subscribe((labels) => {
		this.labels = labels;
		
	});
	this.store.select(fromStore.getSelectedLanguage)
	.subscribe(lang => {
		this.selectedLanguage = lang;
		if (this.selectedLanguage) {
			this.store.select(fromStore.isUpdateTransactionStatusLoaded)
			.subscribe(loaded => {
				if (loaded) {
					this.store.select(fromStore.updateTransactionStatusStatusCode)
					.subscribe(code => {
						this.updateTrasCode = code
						if (code === 200) {
							this.store.select(fromStore.updateTransactionStatusResult)
							.subscribe(result => {
								this.transactionUpdate = result;
							});
							this.updateTrasCode = 0
							// this.router.navigate(['/cep/thank-you']);
						}
						else if (code === 500) {
							this.store.select(fromStore.getErrorMessages)
								.subscribe(error => {
									console.error(error[this.selectedLanguage][code]);
								});
						} 
						// else {
						// 	this.store.select(fromStore.getUserCartSuccessMessage)
						// 	.subscribe(errorMessages => {
						// 		if(errorMessages)
						// 			console.error(errorMessages);
						// 	});
						// }
					});
				}
			});
		}
  });
  

  }
  /**
   * Created by neha
   * to redirect to my deals page
   */
  redirectToFeed(){
	this.router.navigate(['/cep/my-deals']);
  }

  ngOnDestroy() {
	// if (this.userId ) {
	// 	const dialogRef = this.dialog.open(FeedbackPopupComponent,
	// 		{
	// 			data: {
	// 				merchantId: this.transactionUpdate['merchantId']
	// 			},
	// 			maxHeight: '100vh',
	// 			maxWidth: '100vw',
	// 			autoFocus: false
	// 		});
	// }
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		// this.store.dispatch(new fromStore.resetServerErrorMessage());

}
}
