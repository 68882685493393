<!--************************************* 
Created by: Prajkta patil
Created Date: 
updated by: Akanksha J
Updated Date: 19/02/2020
Description: popup for confirmation of change password
***************************************** -->
<div class="dialogbox">
  <div class="popup-header">
    <div class="popup-heading font-card-heading">
      {{updatelabels[selectedLanguage].confirmation}}
    </div>
  </div>
  <div class="popup-container">
    <div class="text">
      {{updatelabels[selectedLanguage].areYouSureDoYouWantChangePassword}}
    </div>
  </div>
  <div class="popup-button-container">
    <div class="align-buttons">
      <button class="popup-reverse-button" mat-flat-button (click)="closeDialog()">
        {{updatelabels[selectedLanguage].no}}
      </button>
      <button class="popup-button" mat-raised-button
        (click)="changePassward(data)">{{updatelabels[selectedLanguage].yes}}
      </button>
    </div>
  </div>
</div>