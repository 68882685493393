<!-- /******
 * Component -  event details screen
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */ -->

<!-- loader -->
<div *ngIf="isLoading" class="spinner-loader">
  <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div>
<!-- main content -->
<div *ngIf="feedsData" class="card-detail">
  <div class="post-navigation font-sub-heading" *ngIf="!feedsData">
    <p matTooltipClass="engagement-tooltip  " matTooltip="{{ feedsData[selectedLanguage].heading }}" style="width: 100%;">
      <!-- <span (mouseenter)="speakText( feedsData[selectedLanguage].heading ,landingLables[selectedLanguage].feed)" [routerLink]="['/cep/feeds']" class="feeds-redirection font-card-heading">
        {{ landingLables[selectedLanguage].feed | uppercase }}</span>
      > {{ feedsData[selectedLanguage].heading }} -->
      <span>DATA NOT FOUND</span>
    </p>
  </div>
  <!-- {{isUserAdded}} -->

  <div  class="post-navigation font-sub-heading">
    <p matTooltipClass="engagement-tooltip" matTooltip="{{feedsData[selectedLanguage]?.heading}}" style="width: 80%;">
      <span (mouseenter)="speakText('BOLG')" (click)="goBack()" class="feeds-redirection font-card-heading">
        {{ feedLabels[selectedLanguage].blog | uppercase }}
      </span> > {{feedsData[selectedLanguage]?.heading}}
    </p>
  </div>

  <div class="post-container">
    <div class="post-image">
      <app-details-page-slider [postImages]="feedsData.image"></app-details-page-slider>
    </div>
    <div class="post-details">
      <div class="details-page-post-description">
        <div (mouseenter)="speakText(feedFilterLabels[selectedLanguage].blog)" class="post-type  ">
          <p>{{ feedFilterLabels[selectedLanguage].blog | uppercase }}</p>
        </div>
        <div (mouseenter)="speakText(feedsData[selectedLanguage].heading)" class="post-heading font-heading">
          <p class="font-heading">{{ feedsData[selectedLanguage].heading }}</p>
        </div>
        <div (mouseenter)="speakText(feedsData[selectedLanguage].content)" *ngIf="feedsData[selectedLanguage].content" class="post-containt">
          <p [innerHTML]="feedsData[selectedLanguage].content"></p>
          <!-- icon for speakin the text by speech synthesys -->
          <!-- <mat-icon class="speaker-icon-class pointer" matTooltip="{{landingLables[selectedLanguage].speak}}" (mouseenter)="speakText(feedsData[selectedLanguage].content, feedsData[selectedLanguage].heading)">volume_up</mat-icon> -->
        </div>
      </div>
      <div class="post-date-type">
        <div class="booking-sharing">
          <div class="sharing" *ngIf="isApproved == true">
            <!-- share options -->
            <div (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)" class="abuse-flag-container">
              <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon>
          </div>
            <div (mouseenter)="speakText(landingLables[selectedLanguage].share)" *ngIf="this.userId" (click)="clickOnShare()">
              <i class="material-icons pointer" [matMenuTriggerFor]="menu" matTooltip="{{landingLables[selectedLanguage].share}}">
                share
              </i>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')" (mouseenter)="speakText('share on facebook')">
                <img class="social-share-img" src="{{images.facebookShareImg}}" loading="lazy" alt="...">
                Facebook
              </button>
              <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on twitter')">
                <img class="social-share-img" src="{{images.twitterShareImg}}" loading="lazy" alt="...">
                Twitter
              </button>
              <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on linkedin')">
                <img class="social-share-img" src="{{images.linkedinShareImg}}" loading="lazy" alt="...">
                LinkedIn
              </button>
              <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on whatsapp')">
                <img class="social-share-img whatsapp-share" src="{{images.whatsappShareImg}}" loading="lazy" alt="...">
                Whatsapp
              </button>
            </mat-menu>
            <div *ngIf="userId" (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)">
              <i class="material-icons favorite-icon" [ngClass]="{'active-favorite':feedsData.favourite}" 
              matTooltip="{{landingLables[selectedLanguage].addToFavorites}}" (click)="unMarkFavorite()">
                {{favorite}}
              </i>
            </div>
          </div>
          <div class="approved" *ngIf="isUserAdded == true">
            <label class="pending font-medium" *ngIf="feedsData.isApprovedByOpeationalTeam == false">{{blogLabels[selectedLanguage].pending}}</label>
            <label class="wip font-medium" *ngIf="feedsData.isApprovedByOpeationalTeam == true && feedsData.isApprovedByGovn == false">{{blogLabels[selectedLanguage].workInProgress}}</label>
            <label class="approve font-medium" *ngIf="feedsData.isApprovedByOpeationalTeam == true && feedsData.isApprovedByGovn == true">{{blogLabels[selectedLanguage].approved}}</label>
            <label class="rejected font-medium" *ngIf="feedsData.isRejected == true">{{blogLabels[selectedLanguage].rejected}}</label>
          </div>
          <div class="update-circle"  *ngIf="isUserAdded == true" (click)='redirectToAddBlog(feedsData.engagementId)'>
            <img class="pencil-icon" src={{images.pencilImage}} loading="lazy" alt="..." matTooltip=" {{landingLables[selectedLanguage].editBlog}}">
          </div>
        </div>
        <!-- post by details -->
        <div class="post-date-venue">
          <div class="post-date" (mouseenter)="speakText(feedLabels[selectedLanguage].postedOn + feedsDate)">
            <label>{{feedLabels[selectedLanguage].postedOn}}</label>
            <p>{{ feedsDate }}</p>
          </div>
          <div class="post-date" (mouseenter)="speakText(feedLabels[selectedLanguage].postedBy + feedsData.createdBy)">
            <label>{{feedLabels[selectedLanguage].postedBy}}</label>
            <p>{{ feedsData.createdBy }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="comments-section" *ngIf ="isApproved == true">
  <label class="feeds-redirection font-card-heading">{{feedLabels[selectedLanguage].comments}}:</label>
  <label *ngIf="userId" (mouseenter)="speakText(feedDetailsResultArray.length + feedLabels[selectedLanguage].comments)"> {{feedDetailsResultArray.length}} &nbsp;{{feedLabels[selectedLanguage].comments}}</label>
  <div class="input-comment" *ngIf="userId">
    <textarea (mouseenter)="speakText('enter your comment')" rows="4" cols="50" [maxlength]="200"  class="input-box" type="text" [(ngModel)]="blogComment"></textarea><br>
    <button (mouseenter)="speakText('post your comment')" class="button-post" [disabled]="!blogComment" [class.disabledButton]="!blogComment" (keyup.enter)="addBlogComment(blogComment)" (click)="addBlogComment(blogComment)" mat-button>{{feedLabels[selectedLanguage].post}}</button>
  </div>
  <label *ngIf="userId" (mouseenter)="speakText(feedLabels[selectedLanguage].noteCommentLengthShouldBeLessThan200Characters)" class="widthMaxlength">{{feedLabels[selectedLanguage].noteCommentLengthShouldBeLessThan200Characters}}</label>
  <br><br>
  <div (mouseenter)="speakText('all comments')" class="allComments" *ngFor="let commentResult of feedDetailsResultArray; let i = index">
    <div class="commentCard">
      <label class="circle">
        <img class="profile" 
          *ngIf="feedDetailsResultArray[i]['profilePhoto'] !== null && feedDetailsResultArray[i]['profilePhoto'] !== undefined"
          src="{{feedDetailsResultArray[i]['profilePhoto']}}" loading="lazy" alt="...">
        <img class="profile" 
          *ngIf="!feedDetailsResultArray[i]['profilePhoto']"
          src="{{ images.defaultProfilePicture }}" 
          loading="lazy" 
          alt="Default Profile Image">
      </label>
      <div class="userCommentName">
        <div class="comment">
         <div class="position">
          <label *ngIf="commentResult.firstName"><b>{{commentResult.firstName}}&nbsp;</b></label>
          <label  *ngIf="commentResult.middleName"><b>{{commentResult.middleName}}&nbsp;</b> </label>
          <label *ngIf="commentResult.lastName"><b> {{commentResult.lastName}}</b> </label>
          <label class="commentDate">{{convCommentDateFormat(commentResult.updatedAt)}} </label>
         </div>
         <div class="position"  *ngIf="commentResult.userId != userId">
          <mat-icon class="report pointer" [matMenuTriggerFor]="options" matTooltipClass="engagement-tooltip" >
              more_vert</mat-icon>
          <mat-menu #options="matMenu">
              <button mat-menu-item  matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportComment(commentResult, i)">
                  <!-- {{landingLables[selectedLanguage].reportAbuseAsComment}} -->
                  {{landingLables[selectedLanguage].reportAbuse}}
                  <!-- Report{{selectedLanguage==='en'? 'hello' : 'hi'}} -->
              </button>
          </mat-menu>
        </div>
          <!-- <img src="../assets/images/blog/trash.png"
            (click)="removeBlogComment(this.feedDetailsResultArray[i]['commentId'],this.feedDetailsResultArray[i]['updatedBy'], i)"
            class="trashImg" *ngIf="this.userId == this.feedDetailsResultArray[i]['userId']"> -->
        </div>
      
        <label class="comment" (mouseenter)="speakText(commentResult.comment)" style="word-break: break-word; width: 50%;">
          {{(commentResult.visible)  ?  commentResult.comment : commentResult.comment | slice:0:50}} 
          <span *ngIf="!commentResult.visible">...</span>
        </label>
        <a href="javascript:;" *ngIf="!commentResult.visible" (click)="commentResult.visible = true" class="read-more">Read More</a>
        <a href="javascript:;" *ngIf="commentResult.visible && (commentResult.comment.length > 50)" (click)="commentResult.visible = false" class="read-more">Read Less</a>
      </div>
    </div>
  </div>
</div>
<div (mouseenter)="speakText(feedLabels[selectedLanguage].relatedArticle)" class="related-article-portion" *ngIf=" feedsData &&  isApproved == true && feedsData.relatedArticle">
  <div class="article-heading" *ngIf="feedsData?.relatedArticle.length > 0 ">
    <label>{{feedLabels[selectedLanguage].relatedArticle}}</label>
  </div>

  
  <app-related-article [feedsData]="feedsData?.relatedArticle" [selectedLanguage]="selectedLanguage" [landingLables]="landingLables"></app-related-article>
</div>
<!-- <hr> -->

<!-- <div class="details-page-error-message" *ngIf="errMessage">
  <img src="{{images.pageNotFound}}" alt="">
  <span>{{errMessage}}</span>
</div> -->