import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromStore from "../../../cep/store/index";
import { MatDialog} from "@angular/material/dialog";
import { SalarySlipComponent } from "src/app/cep/side-menu-bar/officer-zone/salary-slip/salary-slip.component";
import { ContactListComponent } from "src/app/cep/side-menu-bar/officer-zone/contact-list/contact-list.component";

@Component({
    selector: "app-officer-service-item",
    templateUrl: "./officer-service-item.component.html",
    styleUrls: ["./officer-service-item.component.scss"],
    standalone: true,
})
export class OfficerServiceItemComponent implements OnInit {
  @Input() icon;
  @Input() iconBase;
  @Input() title;
  @Input() to;
  redirectLinks:[];


  iconUrl =
    "https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif";
    selectedLanguage:any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store<fromStore.CEPState>,
  ) {}

  ngOnInit() {
    this.iconUrl = this.iconBase + this.icon;
    this.store.select(fromStore.getSelectedLanguage).subscribe((lang) => {
      this.selectedLanguage = lang;
    });
  }

  generateSalarySlip(){
    const dialogRef = this.dialog.open(SalarySlipComponent, {
      width: "50%",
      data: this.redirectLinks,
    });
  
  }

  getContactList() {
    let width = '70%';
    let height = '95%';

    if (window.innerWidth < 768) {
      width = '94%';
      height = '95%';
    }

    const dialogRef = this.dialog.open(ContactListComponent, {
      width: width,
      height: height,
      data: this.redirectLinks,
    });
  }

  
  handleClick() {
    if (this.to.en === "Complaint Dashboard") {
      this.router.navigate(["/cep/ofiice-zone/dashboard"]);
    }else if(this.to.en === "Salary_slip"){
      this.generateSalarySlip()
    }else if (this.to.en === "Contact List") { 
      this.getContactList();
    } else {
      window.open(this.to.links[this.selectedLanguage], "_blank");
    }
  }
}
