<!-- /******
 * Component -  forum details screen
 * Created By - Akshata Choulwar
 * Updated By - Akshata Choulwar
 * Updated On - 29/01/2020
 */ -->

<!-- loader -->
<div *ngIf="isLoading" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
<!-- main content -->

<div *ngIf="(!isLoading) && !errMessage && feedsData" class="card-detail">
    <div class="post-navigation font-sub-heading" *ngIf="isUserAddedForum == false">
        <p matTooltipClass="engagement-tooltip" matTooltip="{{ feedsData[selectedLanguage]?.question }}"
            style="width: 80%;">
            <span [routerLink]="['/cep/feeds']" class="feeds-redirection font-card-heading">
                {{ landingLables[selectedLanguage].feed | uppercase }}</span> > {{ feedsData[selectedLanguage]?.question
            }}
        </p>
    </div>
    <div class="post-navigation font-sub-heading" *ngIf="isUserAddedForum == true">
        <p matTooltipClass="engagement-tooltip  " matTooltip="{{feedsData[selectedLanguage]?.question}}"
            style="width: 80%;">
            <span [routerLink]="[ '/cep/my-forum-listing']" class="feeds-redirection font-card-heading">
                {{blogLabels[selectedLanguage].myForums}}
            </span> > {{feedsData[selectedLanguage]?.question}}
        </p>
    </div>
    <div class="post-container">
        <div class="post-image">
        <app-details-page-slider [postImages]="feedsData.image  "></app-details-page-slider>
      </div>
        <div class="post-details">
            <div class="details-page-post-description">
                <div class="post-type">
                    <p>{{ landingLables[selectedLanguage].forum | uppercase }}</p>
                </div>
                <div class="post-heading font-heading">
                    <p class="font-heading">{{ feedsData[selectedLanguage]?.question}}</p>
                    <!-- <mat-icon class="speaker-icon-class pointer" matTooltip="{{landingLables[selectedLanguage].speak}}" (click)="quickStart()">volume_up</mat-icon> -->

                </div>
                <!-- <div class="post-containt">
                    <p>{{ feedsData[selectedLanguage].content }}</p>
                </div> -->
                <div *ngIf="feedsData[selectedLanguage].content" class="post-containt">
                    <p [innerHTML]="feedsData[selectedLanguage].content"></p>
                    <!-- <mat-icon class="speaker-icon-class pointer" matTooltip="{{landingLables[selectedLanguage].speak}}" (click)="speakText(feedsData[selectedLanguage].content, feedsData[selectedLanguage].question)">volume_up</mat-icon> -->

                </div>
            </div>
            <div class="post-date-type">
                <div class="booking-sharing">
                    <div class="sharing" *ngIf="isApproved == true">
                        <div class="abuse-flag-container">
                            <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip"
                                matTooltip="{{landingLables[selectedLanguage].reportAbuse}}"
                                (click)="reportAsAbuse()">flag</mat-icon>
                        </div>
                        <!-- share options -->
                        <div *ngIf="this.userId" (click)="clickOnShare()">
                            <i class="material-icons" [matMenuTriggerFor]="menu" matTooltip="{{landingLables[selectedLanguage].share}}">    
                                share
                            </i>
                        </div>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item class="button" (click)="generateShareLink('facebook')">
                                <img class="social-share-img" src="{{images.facebookShareImg}}" loading="lazy" alt="...">
                                Facebook
                            </button>
                            <button mat-menu-item class="button" (click)="generateShareLink('twitter')">
                                <img class="social-share-img" src="{{images.twitterShareImg}}" loading="lazy" alt="...">
                                Twitter
                            </button>
                            <button mat-menu-item class="button" (click)="generateShareLink('linkedin')">
                                <img class="social-share-img" src="{{images.linkedinShareImg}}" loading="lazy" alt="...">
                                LinkedIn
                            </button>
                            <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')">
                                <img class="social-share-img whatsapp-share" src="{{images.whatsappShareImg}}" loading="lazy" alt="...">
                                Whatsapp
                            </button>
                        </mat-menu>
                        <!-- <div *ngIf="userId">
                <i class="material-icons favorite-icon" [ngClass]="{'active-favorite':feedsData.favourite}"
                  (click)="unMarkFavorite()">
                  {{favorite}}
                </i>
              </div> -->
                    </div>
                    <div class="approved" *ngIf="isUserAddedForum == true">
                        <label class="pending font-medium"
                            *ngIf="feedsData.isApprovedByOpeationalTeam == false">{{blogLabels[selectedLanguage].pending}}</label>
                        <label class="wip font-medium"
                            *ngIf="feedsData.isApprovedByOpeationalTeam == true && feedsData.isApprovedByGovn == false">{{blogLabels[selectedLanguage].workInProgress}}</label>
                        <label class="approve font-medium"
                            *ngIf="feedsData.isApprovedByOpeationalTeam == true && feedsData.isApprovedByGovn == true">{{blogLabels[selectedLanguage].approved}}</label>
                        <label class="rejected font-medium"
                            *ngIf="feedsData.isRejected == true">{{blogLabels[selectedLanguage].rejected}}</label>
                    </div>
                    <!-- <div class="update-circle"  *ngIf="isUserAddedForum == true" (click)='redirectToAddForum(feedsData.engagementId)'>
                        <img class="pencil-icon" src={{images.pencilImage}} matTooltip="{{landingLables[selectedLanguage].editForum}}">
                      </div> -->
                </div>
                <!-- post by details -->
                <div class="post-date-venue">
                    <div class="post-date">
                        <label>{{feedLabels[selectedLanguage].postedOn}}</label>
                        <p>{{feedsDate}}</p>
                    </div>
                    <div class="post-date" *ngIf="feedsData">
                        <label>{{feedLabels[selectedLanguage].postedBy}}</label>
                        <p>{{ feedsData.createdBy }}</p>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <!-- <hr> -->
        <div class="comments-section" *ngIf ="feedsData.isApproved == true">
            <label class="feeds-redirection font-card-heading">{{feedLabels[selectedLanguage].comments}}:</label>
            <label *ngIf="userId"> {{feedDetailsResultArray.length}} &nbsp; {{feedDetailsResultArray.length}} Comments</label>
            <!-- <form #form = "ngForm"> -->
            <div class="input-comment" *ngIf="userId">
                <textarea rows="4" cols="50" [maxlength]="200" class="input-box"
                    [(ngModel)]="forumComment"></textarea><br>
                <button class="button-post" [class.disabledButton]="!forumComment" [disabled]="!forumComment"
                    (click)="addForumComment(forumComment)" mat-button>{{feedLabels[selectedLanguage].post}}</button>
            </div>
            <label *ngIf="userId"
                class="widthMaxlength">{{feedLabels[selectedLanguage].noteCommentLengthShouldBeLessThan200Characters}}</label>
            <!-- </form> -->
            <br><br>
            <div class="commentListContainer" *ngIf="feedDetailsResultArray">
                <div class="allComments" *ngFor="let commentResult of feedDetailsResultArray; let i = index">
                    <div class="commentCard">
                        <label class="circle">
                            <img class="profile"
                            *ngIf="feedDetailsResultArray[i]['profilePhoto'] !== null && feedDetailsResultArray[i]['profilePhoto'] !== undefined"
                                src="{{feedDetailsResultArray[i]['profilePhoto']}}" loading="lazy" alt="...">
                            <img class="profile" 
                                *ngIf="!feedDetailsResultArray[i]['profilePhoto']"
                                src="{{ images.defaultProfilePicture }}" 
                                loading="lazy" 
                                alt="Default Profile Image">
                        </label>
                        <div class="userCommentName">
                            <div class="comment">
                                <div class="position">
                                    <label
                                        *ngIf="commentResult.firstName"><b>{{commentResult.firstName}}&nbsp;</b></label>
                                    <label *ngIf="commentResult.middleName"><b>{{commentResult.middleName}}&nbsp;</b>
                                    </label>
                                    <label *ngIf="commentResult.lastName"><b> {{commentResult.lastName}}</b> </label>
                                    <label
                                        class="commentDate">{{convCommentDateFormat(this.feedDetailsResultArray[i]['updatedAt'])}}
                                    </label>
                                </div>
                                <div class="position" *ngIf="commentResult.userId != userId">
                                    <mat-icon class="report pointer" [matMenuTriggerFor]="options"
                                        matTooltipClass="engagement-tooltip">
                                        more_vert</mat-icon>
                                    <mat-menu #options="matMenu">
                                        <button mat-menu-item
                                            matTooltip="{{landingLables[selectedLanguage].reportAbuse}}"
                                            (click)="reportComment(commentResult, i)">
                                            {{landingLables[selectedLanguage].reportAbuse}}
                                        </button>
                                    </mat-menu>
                                </div>
                                <!-- <img src="../assets/images/blog/trash.png" (click)="removeForumComment(this.feedDetailsResultArray[i]['commentId'],this.feedDetailsResultArray[i]['updatedBy'], i)" class="trashImg" *ngIf="this.userId == this.feedDetailsResultArray[i]['userId']"> -->
                            </div>
                            <label style="word-wrap: break-word;">
                                {{(commentResult.visible) ? commentResult.comment : commentResult.comment | slice:0:50}}
                                <span *ngIf="!commentResult.visible">...</span>
                                <a href="javascript:;" *ngIf="!commentResult['visible']"
                                    (click)="commentResult.visible = true" class="read-more">Read More</a>
                                <a href="javascript:;"
                                    *ngIf="commentResult.visible && (commentResult.comment.length > 50)"
                                    (click)="commentResult.visible = false" class="read-more">Read Less</a>

                            </label>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>

<div class="error-message" *ngIf="errMessage">
    <img src="{{images.error404}}" loading="lazy" alt="...">
    <span>{{errMessage}}</span>
</div>