<!--/*******************************
 * Component -  Cart page
 * Created By - Akshata c
 * Created on - 13/04/2020
 * Updated By - Akshata c
 * Updated On - 13/04/2020
 *************************************/-->

 <div class='overlap-container'>
    <!-- connection lost condition -->
    <div *ngIf='serverMessage' class='connectionDiv'>
        <img src={{images.connectionLost}} class='connectionLostImg' loading="lazy" alt="...">
        <span>{{serverMessage}}</span> 
    </div>
    <!-- main page loader -->
    <div *ngIf='isLoading' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>
      <!-- data not found error handling -->
      <ng-container *ngIf="dataNotFoundMsg">
        <div class="error-messages">
            <img src="{{images.noDataFound}}" class="no-data-image-favourite" loading="lazy" alt="...">
            <span class="data-not-found">{{dataNotFoundMsg}}</span>
        </div>
    </ng-container>
     <!-- main page -->
      <!-- main page -->
    <div *ngIf='!isLoading && cartDetails && !dataNotFoundMsg && myCartLables' class="post-navigation-merchant font-sub-heading">
        <p matTooltipClass="engagement-tooltip  " matTooltip="Deal detail">
          <span [routerLink]="[ '/cep/my-deals']"  class="feeds-redirection font-card-heading">
            {{myCartLables[selectedLanguage].myDeals | uppercase}}
          </span> >   {{myCartLables[selectedLanguage].dealDetail}}
        </p>
    </div>
     <div *ngIf='!isLoading && cartDetails && !dataNotFoundMsg && myCartLables' class='main-container'>
        
        <mat-card class="my-deal-card" >
            <div class="transaction-status-div">
               <!-- <span class="rate-this-merchant" *ngIf="cartDetails.merchantFeedback && cartDetails.isMerchantScans">{{myCartLables[selectedLanguage].merchantAlreadyRated}}</span> 
               <span class="rate-this-merchant" *ngIf="!cartDetails.merchantFeedback && cartDetails.isMerchantScans" (click)="redirectToMerchantRating()">{{myCartLables[selectedLanguage].rateThisMerchant}}</span>  -->
               <span class="status-Scanned" *ngIf="cartDetails.isMerchantScans" >{{myDealsLables[selectedLanguage].qrScanned}}</span>
               <span class="status-not-scanned" *ngIf="!cartDetails.isMerchantScans">{{myDealsLables[selectedLanguage].qrNotScanned}}</span> 
            </div>

            <div class="cart-remove-all">
                <div class="deal-name font-card-heading">{{myCartLables[selectedLanguage].myDeals}}</div>
            </div>
           
            <div class="details-of-cart">
                <div class="mini-card" *ngIf = "cartDetails && mydealHistoryCart && mydealHistoryCart.length > 0">
                    <div *ngFor = "let data of mydealHistoryCart">
                        <div class="each-info-card">
                            <div class="my-deal-mini-card">
                                <div class="each-card-detail">
                                    <div class="each-image">
                                        <img class="image" src={{data.image[0]}} loading="lazy" alt="...">
                                    </div>
                                    <div class="each-detail">
                                        <div class="heading">
                                            <div class="deal-info-heading font-medium">{{data.displayCompanyName}}</div>
                                            <!-- <div class="stock font-medium" *ngIf = "data.isQuantityAvailable">{{myCartLables[selectedLanguage].inStock}}</div>
                                            <div class="stock font-medium" *ngIf = "!data.isQuantityAvailable">{{myCartLables[selectedLanguage].outOfStock}}</div> -->
                                        </div>
                                        <div class="sub-heading">{{data.productName}}</div> 
                                        <div class="sub-heading">{{data.productTNC}}</div>
                                        <div class="sub-heading">{{myCartLables[selectedLanguage].qty}}: {{data.quantity}}</div>
                                        <div class="rupees" style="display: none;">
                                            <div class="price font-normal">{{myCartLables[selectedLanguage].price}}: ₹</div>
                                            <div class="digit font-normal">{{data.actualAmount}}</div>
                                            <div class="digit blurr-font font-normal"><del>₹ {{data.price}}</del></div>
                                            <div class="off-percentage">{{myCartLables[selectedLanguage].saved}} ₹ {{data.saveAmount}}</div>
                                        </div>
                                        <div class="price font-normal">{{ myDealsLables[selectedLanguage].orderDate }} : <b>{{data.updatedAt | date:'medium'}}</b></div>

                                        
                                        <!-- <span class="rate-this-product" *ngIf="!data.feedback && cartDetails.isMerchantScans" (click)="redirectToProductRating(data)">{{myCartLables[selectedLanguage].rateThisProduct}}</span> 
                                        <span class="rate-this-product" *ngIf="data.feedback && cartDetails.isMerchantScans">{{myCartLables[selectedLanguage].alreadyRatedProduct}}</span>  -->
                                        
                                    </div>
                                </div>       
                            </div>
                        </div>
                        <div class = "item-horizontal-line"> </div>
                    </div>
                </div>
               <div class = "price-and-tc">
                    <div class="qr-block" *ngIf="cartDetails.isMerchantScans"> 
                        <img src="{{images.media}}" alt="QR Code Scanned" class="dummy-image" />
                    </div>
                   <div class="qr-block" *ngIf="!cartDetails.isMerchantScans">

                        <qrcode [qrdata]="this.scanId" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                        <label class="dark-blurr-font font-normal">{{myCartLables[selectedLanguage].scanId}}: {{scanId}}</label>
                   </div>
               
                <div class="coupon-card" style="display: none;">
                    <div class="container">
                        <div class="time-and-addr">
                            <div>
                                <!-- <div class="icon-and-heading">
                                    <img class="icon-image" src="../../../../../assets/images/more-services/clock.png">
                                    <label class="dark-blurr-font font-small">{{myCartLables[selectedLanguage].applyCoupon}}</label>
                                </div> -->
                                <div class="time-day font-medium">
                                    <label class="labels">{{myCartLables[selectedLanguage].priceDetail}}</label>
                                </div>
                                <div class="day-timing">
                                    <div class="item-card">
                                    <label class="margin-class dark-blurr-font font-normal">{{myCartLables[selectedLanguage].cartTotal}}</label>
                                    <label class="margin-class dark-blurr-font font-normal">{{myCartLables[selectedLanguage].loyaltyPointDiscount}}</label>
                                    <label class="margin-class dark-blurr-font font-normal">{{myCartLables[selectedLanguage].orderTotal}}</label>
                                    </div>
                                </div>
                            </div>
                                <div class = "price-details"> 
                                    <!-- <div class="apply-button font-small coupon" (click)="applyCoupon(cartDetails)">{{myCartLables[selectedLanguage].apply}}</div> -->
                                    <div class = "price-of-items">
                                        <div class="margin-class blurr-font font-medium">₹ {{cartDetails.price}}</div>
                                        <div class="margin-class blurr-font font-medium">₹ {{cartDetails.discount}}</div>
                                        <div class="margin-class blurr-font font-medium">₹ {{cartDetails.final_price}}</div>
                                   </div>
                                </div>  
                        </div>
                        <div class = "horizontal-line"> </div>
                        <div class="time-and-addr">
                            <div>
                                <div class="total-card">
                                    <div class="item-card">
                                        <label class="margin-class dark-blurr-font font-normal">{{myCartLables[selectedLanguage].totalPrice}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class = "total-price-details"> 
                                <div class = "total-price">
                                    <div class="font-medium">₹ {{cartDetails.final_price}}</div> 
                                </div>
                            </div>  
                        </div>
                    </div>
                    <!-- <div class="button labels">
                        <button (click)= "startTransaction()" [disabled] = "!cartDetails.isValidForTransaction" mat-raised-button class="deal-button labels font-small">{{myCartLables[selectedLanguage].orderNow}}</button>
                    </div> -->
                </div>  

                <div class="coupon-card margin-coupon-class">
                    <div class="container">
                        <div class="time-and-addr">
                            <div>
                                <div class="icon-and-heading">
                                    <img class="icon-image" src="../../../../../assets/images/more-services/clock.png" loading="lazy" alt="...">
                                    <label class="dark-blurr-font font-small">{{myCartLables[selectedLanguage].aboutUs}}</label>
                                </div>
                                <div class="day-timing">
                                    <div class="item-card">
                                    <label class="margin-class dark-blurr-font font-normal">{{ cartDetails.merchant_about_us }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
            </div>           
        </mat-card>     
    </div>
</div>

