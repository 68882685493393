import { Component, OnInit, AfterContentChecked, ChangeDetectorRef, Inject, OnDestroy, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogActions } from '@angular/material/dialog';
import { Validators, UntypedFormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Images } from 'src/app/shared/config/images-config';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { AppConstants } from 'src/app/shared/config/app-constants';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { EncrDecrService } from 'src/app/shared/services/encr-decr.service';
import { LocalStorageService } from 'src/app/shared/services/localstorage.service';
import { ObjectInterface } from 'src/app/shared/models/global-interface';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatError } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-newsletter-subscription',
    templateUrl: './newsletter-subscription.component.html',
    styleUrls: ['./newsletter-subscription.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatInput, FormsModule, ReactiveFormsModule, MatError, MatDialogActions, MatButton]
})
export class NewsletterSubscriptionComponent implements OnInit, AfterContentChecked, OnDestroy {
	public images = Images;
	constants = AppConstants;
	deviceInfo = null;
	public topic;
	selectedLanguage;
	contactUsLabel;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoading = false;
	isLoaded = false;
	contactNo: {};
	device: string = null;
	apiDetails: ObjectInterface;
	isNetworkError = false;
	contactUsResult: {};
	serverErrorMessage: string;
	userData: any;
	emailId:string;
	token: string;
	userId: any;
	newsletterLabels:{};
	isNewsletterRequest = false;
	isNewsLetterSubscribe = false;
	public emailRegx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,50}$/;
	email = new UntypedFormControl('', [Validators.email, Validators.pattern(this.emailRegx), Validators.maxLength(50)]);
	registerLabels= {};
	successMessages: {};
	statusCode: number;
	profileMessage: string;
	errorMessage: {};
	isBlind;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		public router: Router,
		private store: Store<fromStore.CEPState>,
		private dialogRef: MatDialogRef<NewsletterSubscriptionComponent>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private encrDecr:EncrDecrService,
		private localStorageService:LocalStorageService,
		private ref:ChangeDetectorRef

	) {
		dialogRef.disableClose = true;
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		}

	ngOnInit() {
				// token from localstorage
				this.store.select(fromStore.getToken)
				.subscribe((token) => {
					this.token = token;
					if (this.token) {
						let localUserId = this.localStorageService.getUserId();
						this.userId = this.encrDecr.get(localUserId);
					}
				});
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				
			});
			this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((response) => {
				
			})
		if(this.data){
			this.emailId = this.data.emailId
			if(!this.emailId)
			{
				this.emailId = '';
			}	
			this.isNewsletterRequest = this.data.isNewsletterRequest;
			this.isNewsLetterSubscribe = this.data.isNewsLetterSubscribe;
		}
		this.store.select(fromStore.getNewsletterLabels)
		.subscribe((labels) => {
			this.newsletterLabels = labels;
		});
		this.store.select(fromStore.getRegisterScreenLabels)
		.subscribe((labels) => {
			this.registerLabels = labels;
		});
		this.store.select(fromStore.getSuccessMessages)
		.subscribe(res => {
			this.successMessages = res;
		});

		
		
	}


	/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }




	/**
	 * Created by Akanksha J
	 * For closing the popup
	 */
	closeDialog() {
		const dialogClose = this.dialog.closeAll();
	}
	 redirectToSubscriveApi(emailId){
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on News letter subscription', 'Click Event', 1);
				// API call for contact us details
				let localUserId = this.localStorageService.getUserId();
				this.userId = this.encrDecr.get(localUserId);
			
				if(this.userId){
				 this.store.dispatch(new fromStore.SubcribeToNewsLetter({
					url: this.apiDetails['user'].base_url +
						this.apiDetails['user'].changeNewsletterValue.url,
					method: this.apiDetails['user'].changeNewsletterValue.method,
					body:{
						emailId:emailId,
						userId:this.userId
					}
				}));
				// this.store.select(fromStore.isNewsletterSubscriptionLoaded)
				// .subscribe((loaded) => {
				// 	this.isLoaded = loaded;
				
				// 	if(this.isLoaded){
						this.store.select(fromStore.getNewsletterSubscriptionStatusCode)
						.subscribe((code) => {
						
								this.store.select(fromStore.getNewsletterSubscriptionErrorMessage)
						.subscribe((message) => {
					
							if(message && (code === 400)) {
								console.error(message)
							} else if(message&& (code === 200)) {
								this.toastr.success(this.successMessages[this.selectedLanguage][message])
								this.store.select(fromStore.getUpdateProfileStatusCode)
							}	
							});
						});
				// 	}
				// });
				const dialogClose = this.dialog.closeAll();
				}
			}
	getEmailErrorMessage() {
		return this.email.hasError('email') ? this.registerLabels[this.selectedLanguage].emailIsNotValid :
			this.email.hasError('pattern') ? this.registerLabels[this.selectedLanguage].emailIsNotValid :
				'';
	}
	ngAfterContentChecked() {
		this.ref.detectChanges();
	}

	ngOnDestroy() {
		this.serverErrorMessage = null;
	}
}
