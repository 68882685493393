<div *ngIf="isLoading" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
<!-- connection lost condition -->
<div *ngIf="serverErrorMessage" class="connectionDiv">
    <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
    <span>{{serverErrorMessage}}</span>
</div>
<div class="container" *ngIf="!isLoading && isLoaded && !serverErrorMessage">
    <div class="header-container">
        <span class="header font-heading"><strong>{{aboutUsLabels[selectedLanguage].tourismInformation}}</strong></span><br>
    </div>
    <mat-card class="card" *ngFor="let data of tourismData[selectedLanguage]['info']; let i=index">
            <div class="content">
                <span class="label"><strong>{{data[0]}}:</strong></span><br>
                <div class="description">
                    <p [innerHTML]="data[1]"></p>
                </div><br>
            </div>
    </mat-card>
</div>