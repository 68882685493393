<!-- /**************************************
 * Component -  most-used-services-component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 *******************************************/ -->
<div class="container" *ngIf="userId  && (mostUsedService && mostUsedService.length)">
  <!-- loader -->
  <div *ngIf="isLoading" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>

  <!-- main content -->
  <ng-container *ngIf="!isLoading">
    <div class="heading">
      <label class="font-card-heading" (mouseenter)="speakText(feedsLabel[selectedLanguage].mostUsedservices)"  (mouseleave)="stopSpeaking()" *ngIf="selectedLanguage === 'en' ">TOP SERVICES</label>
      <label class="font-card-heading" (mouseenter)="speakText(feedsLabel[selectedLanguage].mostUsedservices)"  (mouseleave)="stopSpeaking()" *ngIf="selectedLanguage === 'mar' ">प्रमुख सेवा</label>
      <div mat-raised-button class="all-services-button " matTooltip="{{feedsLabel[selectedLanguage].seeAll | uppercase}}" [routerLink]="['/cep/e-services']" (mouseenter)="speakText(feedsLabel[selectedLanguage].seeAll)"  (mouseleave)="stopSpeaking()">
        {{feedsLabel[selectedLanguage].seeAll}} </div>
    </div>

    <!-- services redirection -->
    <!-- <div class="services-block">
      <div class="service" *ngFor="let item of mostUsedService">
        <div class="service-icon" (click)='openServicesUrl(item)'>
          <img src="{{item.img}}">
        </div>
        <div class="service-name" (click)='openServicesUrl(item)'>
          <label>{{item[selectedLanguage]}}</label> </div>
      </div>
    </div> -->

    <div class="services-block" *ngIf="userId">
      <div class="most-used">
        <div class="service" *ngFor="let item of mostUsedService">
          <div class = "timeline-content">
            <div class="service-icon" matTooltip="{{item[selectedLanguage]}}" (click)='openServicesUrl(item)'>
              <img src="{{item.imgWeb}}" loading="lazy" alt="...">
            </div>
            <div [ngStyle]="{'background-color': item.color}" class = "service-time-line">
  
            </div>
            <div class="service-name" (click)='openServicesUrl(item)'>
              <label class="font-heading" matTooltip="{{item[selectedLanguage]}}" [ngStyle]="{'color': item.color}"
              (mouseenter)="speakText(item[selectedLanguage])"  (mouseleave)="stopSpeaking()"
              >{{item[selectedLanguage]}}</label> 
            </div>
          </div>       
        </div>
      </div>
     
    </div>


  </ng-container>
</div>