<!-- /*******************************
 * Component -  Header of website
 * Created By - Kiran G
 * Updated By - Akanksha J
 * Updated On - 11/01/2020
 **************************************/ -->
<!-- loader -->
<div *ngIf="isLoading" class="spinner-loader">
  <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div>
<!-- main content-->
<div *ngIf="!isLoading" class="container">
  <div class="header-container" id="para">
    <div class="header-content">
      <!-- tabs links -->
      <div class="tab-space pointer" *ngIf="landingScreenLabels">
        <div *ngIf="!isSearch" class="tabs">
          <a routerLink="/cep/home" [routerLinkActive]="['tab-active', 'header-color-home']" matTooltip="{{
              landingScreenLabels[selectedLanguage].home | uppercase
            }}" (mouseenter)="speakText(landingScreenLabels[selectedLanguage].home)" (mouseleave)="stopSpeaking()">{{
            landingScreenLabels[selectedLanguage].home }}</a>
          <a routerLink="/cep/feeds" [routerLinkActive]="['tab-active', 'header-color-feeds']" matTooltip="{{
              landingScreenLabels[selectedLanguage].feed | uppercase
            }}" (mouseenter)="speakText(landingScreenLabels[selectedLanguage].feed)" (mouseleave)="stopSpeaking()">{{
            landingScreenLabels[selectedLanguage].feed }}</a>
          <!-- <a (click)="goToCollab()"
                      matTooltip="{{ landingScreenLabels[selectedLanguage].home | uppercase}}"
                      (mouseenter)="speakText(landingScreenLabels[selectedLanguage].home)"
                      (mouseleave)="stopSpeaking()">Collab</a> -->
          <a routerLink="/cep/e-services" [routerLinkActive]="['tab-active', 'header-color-services']" matTooltip="{{
              landingScreenLabels[selectedLanguage].services | uppercase
            }}" (mouseenter)="
              speakText(landingScreenLabels[selectedLanguage].services)
            " (mouseleave)="stopSpeaking()">{{ landingScreenLabels[selectedLanguage].services | uppercase }}</a>
          <a routerLink="/cep/near-me" [routerLinkActive]="['tab-active', 'header-color-near-me']" matTooltip="{{
              landingScreenLabels[selectedLanguage].nearMe | uppercase
            }}" (mouseenter)="
              speakText(landingScreenLabels[selectedLanguage].nearMe)
            " (mouseleave)="stopSpeaking()">{{ landingScreenLabels[selectedLanguage].nearMe | uppercase }}</a>
          <a routerLink="/cep/offers" [routerLinkActive]="['tab-active', 'header-color-near-me']" matTooltip="{{
              landingScreenLabels[selectedLanguage].more | uppercase
            }}" (mouseenter)="speakText(landingScreenLabels[selectedLanguage].more)" (mouseleave)="stopSpeaking()">{{
            landingScreenLabels[selectedLanguage].more | uppercase }}</a>
          <!-- <a routerLink="/cep/city-info" [routerLinkActive]="['tab-active', 'header-color-city-info']" matTooltip="{{ landingScreenLabels[selectedLanguage].info	 | uppercase}}" (mouseenter)="speakText(landingScreenLabels[selectedLanguage].info	)"  (mouseleave)="stopSpeaking()">{{landingScreenLabels[selectedLanguage].info | uppercase}}</a> -->
        </div>
      </div>

      <!-- header logo on mobile screen -->
      <ng-container *ngIf="isMobile && !isSearch">
        <div class="heading-container">
          <img class="header-logo" (click)="redirectToFeeds()" *ngIf="splashScrren && splashScrren[selectedLanguage]"
            matTooltip="{{
              splashScrren[selectedLanguage].pcmcSmartSarathi | uppercase
            }}" matTooltipClass="primary-tooltip  " src="{{ images.pmc_care_logo }}" loading="lazy" alt="..." />
        </div>
      </ng-container>

      <!-- header logo when desktop screen -->
      <ng-container *ngIf="!isMobile">
        <div class="heading-container">
          <img class="header-logo" (click)="redirectToFeeds()" *ngIf="splashScrren && splashScrren[selectedLanguage]"
            matTooltip="{{
              splashScrren[selectedLanguage].pcmcSmartSarathi | uppercase
            }}" matTooltipClass="primary-tooltip  " src="{{ images.pmc_care_logo }}" loading="lazy" alt="..." />
        </div>
      </ng-container>

      <div class="tabs-container" [ngClass]="{
          'search-active': isSearch && !isLogin,
          'login-search-active': isSearch && isLogin,
          'user-active-tabs-container': isLogin
        }">
        <!-- <div class="font-size-button-container">
                  <div (click)="changeFontSize('decrease')">A-</div>
                  <div (click)="changeFontSize('default')"  >A</div>
                  <div (click)="changeFontSize('increase')">A+</div>
              </div> -->
        <div class="profile-container">
          <!-- weather status -->
          <div *ngIf="!isSearch && !isWeatherLoading" class="weather-status">
            <span class="tempreture" *ngIf="weatherTemp" (mouseenter)="
                speakText('tempreture' + replace(weatherTemp) + '°C')
              " (mouseleave)="stopSpeaking()">{{ replace(weatherTemp) }} °C</span>
          </div>
          <!-- search input box  -->
          <div *ngIf="isSearch" class="search-text" [ngClass]="{ 'login-search': isLogin }">
            <input type="text" class="search-input" (keyup.enter)="searchResult()" [matAutocomplete]="auto"
              (keyup)="autoSearch($event)" [(ngModel)]="searchText" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of options" (click)="searchResult()" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <div class="search-icon">
              <mat-icon class="active-search" (click)="closeSearch()" (mouseenter)="speakText('close search')"
                (mouseleave)="stopSpeaking()">close</mat-icon>
            </div>
          </div>
          <!-- search button -->
          <mat-icon *ngIf="!isSearch && isLogin == true" class="icon-button font-heading"
            matTooltip="{{ headerLabels[selectedLanguage].search }}" (click)="openSerach()"
            matTooltipClass="search-primary-tooltip" (mouseenter)="speakText('search')"
            (mouseleave)="stopSpeaking()">search</mat-icon>

          <!-- notification part -->

          <!-- <span *ngIf  = "userData" class="badge">{{userData.notificationCnt}}</span> -->
          <div *ngIf="userData" class="notification-dropdown">
            <mat-icon (mouseenter)="
                speakText(headerLabels[selectedLanguage].notifications)
              " (mouseleave)="stopSpeaking()" (click)="notificationFunction()" [matBadge]="userData.notificationCnt"
              matBadgeColor="warn" class="icon-button font-heading"
              matTooltip="{{ headerLabels[selectedLanguage].notifications }}"
              matTooltipClass="search-primary-tooltip">notifications</mat-icon>
            <div id="myDIV" class="notification-content">
              <div class="notification-header">
                <label class="header-label font-medium" (mouseenter)="
                    speakText(headerLabels[selectedLanguage].notifications)
                  " (mouseleave)="stopSpeaking()">{{ headerLabels[selectedLanguage].notifications }}</label>
              </div>
              <!-- loader for notification -->
              <div *ngIf="!notificationLoaded" class="spinner-loader-notification">
                <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
                </mat-progress-spinner>
              </div>
              <div *ngIf="
                  notificationResult && notificationLoaded && !noNotifications
                " class="notification-paading">
                <div class="notification-name-time" *ngFor="let notificationResult of notificationResult">
                  <div class="notification-data-div" *ngIf="
                      selectedLanguage === 'en' ||
                      !notificationResult.message_mar
                    ">
                    <label class="font-small" (mouseenter)="speakText(notificationResult.message)"
                      (mouseleave)="stopSpeaking()">{{ notificationResult.message }}</label>
                    <label class="notification-text font-small" (mouseenter)="speakText(notificationResult.text)"
                      (mouseleave)="stopSpeaking()">{{ notificationResult.text }}</label>
                  </div>
                  <div class="notification-data-div" *ngIf="selectedLanguage === 'mar'">
                    <label class="font-small" (mouseenter)="speakText(notificationResult.message)"
                      (mouseleave)="stopSpeaking()">{{ notificationResult.message_mar }}</label>
                    <label class="notification-text font-small" (mouseenter)="speakText(notificationResult.text)"
                      (mouseleave)="stopSpeaking()">{{ notificationResult.text_mar }}</label>
                  </div>
                  <div class="notification-data-div">
                    <label class="time font-small" (mouseenter)="
                        speakText(returnedDate(notificationResult.updatedAt))
                      " (mouseleave)="stopSpeaking()">{{ returnedDate(notificationResult.updatedAt) }}</label>
                    <label class="time font-small" (mouseenter)="
                        speakText(returnedTime(notificationResult.updatedAt))
                      " (mouseleave)="stopSpeaking()">{{ returnedTime(notificationResult.updatedAt) }}</label>
                  </div>
                </div>
              </div>

              <!-- data not found condition -->
              <div *ngIf="noNotifications">
                <div class="no-notification-paading">
                  <label>{{ noNotifications }}</label>
                </div>
              </div>

              <!-- Pagination DIV with next and previous buttons -->
              <div class="paginationDiv" *ngIf="notificationLoaded">
                <div class="prvPage pointer" (click)="nextNotification('prv')" *ngIf="notificationPrvCnt != 0">
                  <img src="{{ images.nextPrevIcon }}" alt="previous-button"  loading="lazy" />
                </div>
                <div class="nextPage pointer" *ngIf="!disableNotificationButton" (click)="nextNotification('next')">
                  <img src="{{ images.nextPrevIcon }}" alt="next-button" loading="lazy"  />
                </div>
              </div>
            </div>
          </div>

          <div class="font-size-button-container">
            <div class="font-size-option" [ngClass]="{ 'active-link': activeFont == 'decrease' }"
              (click)="changeFontSize('decrease')">
              A-
            </div>
            <div class="font-size-option" [ngClass]="{ 'active-link': activeFont == 'default' }"
              (click)="changeFontSize('default')">
              A
            </div>
            <div class="font-size-option" [ngClass]="{ 'active-link': activeFont == 'increase' }"
              (click)="changeFontSize('increase')">
              A+
            </div>
          </div>

          <!-- language change options -->
          <div style="position: relative; display: flex; align-items: center" class="change-language-icon"
            *ngIf="headerLabels">
            <div class="language-icon">
              <!-- <label class="language-label" (click)="changeLanguage(selectedLanguage)"
                              (mouseenter)="speakText(showSelectedLanguage)" (mouseleave)="stopSpeaking()">
                              {{showSelectedLanguage}}</label> -->
              <button mat-button mat-stroked-button class="language" (click)="changeLanguage(selectedLanguage)">
                {{ showSelectedLanguage }}
              </button>
            </div>
          </div>


          <!-- profile details -->
          <ng-container *ngIf="isLogin == true">
            <div class="profile">
              <img [matMenuTriggerFor]="options" *ngIf="userProfileImage" src="{{ userProfileImage }}" loading="lazy" alt="..."
                class="dropdown-profile-image pointer" [ngStyle]="{ 'background-color': themeBackgroundColor }"
                (mouseenter)="speakText('open side menu')" (mouseleave)="stopSpeaking()" />
              <img [matMenuTriggerFor]="options" *ngIf="!userProfileImage" src="{{ images.defaultProfilePicture }}" loading="lazy" alt="..."
                class="dropdown-profile-image pointer" [ngStyle]="{ 'background-color': themeBackgroundColor }"
                (mouseenter)="speakText('open side menu')" (mouseleave)="stopSpeaking()" />
              <mat-menu #options="matMenu" (mouseenter)="speakText('click to open side menu')"
                (mouseleave)="stopSpeaking()">
                <button mat-menu-item routerLink="/cep/user-profile" class="drop-down-button"
                  matTooltip="{{ drawerLabels[selectedLanguage].myProfile }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].myProfile)
                  " (mouseleave)="stopSpeaking()">
                  <mat-icon class="icon">person</mat-icon>
                  {{ drawerLabels[selectedLanguage].myProfile }}
                </button>
                <button mat-menu-item routerLink="/cep/favorite" class="drop-down-button"
                  matTooltip="{{ drawerLabels[selectedLanguage].favorite }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].favorite)
                  " (mouseleave)="stopSpeaking()">
                  <mat-icon class="icon">favorite</mat-icon>
                  {{ drawerLabels[selectedLanguage].favorite }}
                </button>

                <!-- My Deals History -->
                <button mat-menu-item *ngIf="enableFeature === true" (click)="openMyDealHistory()"
                  class="drop-down-button" matTooltip=" {{ drawerLabels[selectedLanguage].myDeals }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].myDeals)
                  " (mouseleave)="stopSpeaking()">
                  <img src="{{ images.myDeals }}" loading="lazy" alt="..." class="logo" />
                  {{ drawerLabels[selectedLanguage].myDeals }}
                </button>
                <!-- <button mat-menu-item *ngIf="userData.isMerchant || userData.isBrand" (click)="openMerchantTransaction()" class="drop-down-button"
                              matTooltip=" {{ drawerLabels[selectedLanguage].myDeals}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].myDeals)" (mouseleave)="stopSpeaking()">
                              <img src={{images.myDeals}} class="logo">
                                  {{ drawerLabels[selectedLanguage].transactions}}
                              </button> -->

                <!-- Products added by me -->
                <!-- <button mat-menu-item (click)="openDealHistory()" class="drop-down-button"
                              matTooltip=" {{ drawerLabels[selectedLanguage].myDeals}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].myDeals)" (mouseleave)="stopSpeaking()">
                              <img src={{images.myDeals}} class="logo">
                              {{ drawerLabels[selectedLanguage].myDeals}}
                              </button> -->

                <!-- <button mat-menu-item (click)="openMyLoyalty()" class="drop-down-button"
                               matTooltip=" {{ drawerLabels[selectedLanguage].myLoyalty}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].myLoyalty)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.myloyalty}} class="logo">
                                  {{ drawerLabels[selectedLanguage].myLoyalty}}  
                              </button> -->
                <button mat-menu-item (click)="openBlogListing()" class="drop-down-button"
                  matTooltip=" {{ drawerLabels[selectedLanguage].addBlog }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].myBlogs)
                  " (mouseleave)="stopSpeaking()">
                  <img src="{{ images.addBlog }}" loading="lazy" alt="..." class="logo" />
                  {{ drawerLabels[selectedLanguage].myBlogs }}
                </button>
                <button mat-menu-item (click)="openForumListing()" class="drop-down-button"
                  matTooltip=" {{ drawerLabels[selectedLanguage].myForums }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].myForums)
                  " (mouseleave)="stopSpeaking()">
                  <img src="{{ images.forum }}" loading="lazy" alt="..." class="logo" />
                  {{ drawerLabels[selectedLanguage].myForums }}
                </button>

                <!-- <button mat-menu-item (click)="openMomListing()" class="drop-down-button"
                              matTooltip=" {{ drawerLabels[selectedLanguage].meetings}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].meetings)" (mouseleave)="stopSpeaking()">
                              <img src={{images.meetings}} class="logo">
                              {{ drawerLabels[selectedLanguage].meetings }} 
                              </button> -->

                <!-- <button  mat-menu-item routerLink="/cep/add-deals" class="drop-down-button"
                                   matTooltip=" {{ drawerLabels[selectedLanguage].addDeals}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].addDeals)" (mouseleave)="stopSpeaking()">
                <img src={{images.addDeals}} class="logo">
                                  {{ drawerLabels[selectedLanguage].addDeals}}
                              </button>
                              <button mat-menu-item *ngIf = "(userData.isMerchant==false && isMerchantRequest==false && userData.isBrand==false && userData.isBrandRequest==false)" (click)="openMerchantForm()" class="drop-down-button"
                               matTooltip=" {{ drawerLabels[selectedLanguage].becomeAsAMerchantbrand}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].becomeAsAMerchantbrand)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.addMerchant}} class="logo">
                                      {{ drawerLabels[selectedLanguage].becomeAsAMerchantbrand}}
                              </button> -->

                <!-- Pending merchant request -->
                <!-- <button mat-menu-item *ngIf = "(enableFeature===true && userData.isMerchant==false && isMerchantRequest==true && userData.isBrand==false && userData.isBrandRequest==false)" (click)="editMerchantForm()" class="drop-down-button"
                               matTooltip=" {{ drawerLabels[selectedLanguage].merchantApprovalPending}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].merchantApprovalPending)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.addMerchant}} class="logo">
                                      {{ drawerLabels[selectedLanguage].merchantApprovalPending}}
                              </button> -->

                <!-- approved merchant request -->
                <!-- <button mat-menu-item *ngIf = "enableFeature===true && (userData.isMerchant || userData.isBrand)" (click)="editMerchantForm()" class="drop-down-button"
                               matTooltip="approvedmerchant" (mouseenter)="speakText(drawerLabels[selectedLanguage].approvedMerchant)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.addMerchant}} class="logo">
                                  {{ drawerLabels[selectedLanguage].approvedMerchant}}
                              </button>
                              
                              <button *ngIf="isNewsLetterSubscribe == false && isNewsletterRequest==false" mat-menu-item (click)="openNewsSubscription('subscription')" class="drop-down-button"
                              matTooltip=" {{ drawerLabels[selectedLanguage].subscribeToNewsletter}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].subscribeToNewsletter)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.newsLetter}} class="logo">
                                  {{ drawerLabels[selectedLanguage].subscribeToNewsletter}}
                
                              </button> -->
                <!-- Subscribe to Newsletter -->
                <!-- <button *ngIf="isNewsLetterSubscribe == true || isNewsletterRequest==true" mat-menu-item (click)="openNewsSubscription('subscription')" class="drop-down-button"
                              matTooltip=" {{ drawerLabels[selectedLanguage].unsubscribeToNewsletter}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].unsubscribeToNewsletter)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.newsLetter}} class="logo">
                                  {{ drawerLabels[selectedLanguage].unsubscribeToNewsletter}}
                
                              </button> -->
                <!-- Unsubscribe to Newsletter -->
                <!-- <button mat-menu-item (click)="openMyCart()" class="drop-down-button"  *ngIf="enableFeature === true"
                                   matTooltip=" {{ drawerLabels[selectedLanguage].myCart}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].myCart)" (mouseleave)="stopSpeaking()">
                                  <div>
                                      <div class="cart-badge">{{userData.cartCnt}}</div>
                                      <img src={{images.myCart}} class="logo">
                                  </div>
                                  {{ drawerLabels[selectedLanguage].myCart}}
                              </button> -->
                <button mat-menu-item (click)="openWebinar()" class="drop-down-button"
                  matTooltip=" {{ drawerLabels[selectedLanguage].webinar }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].webinar)
                  " (mouseleave)="stopSpeaking()">
                  <img src="{{ images.webinarIcon }}" loading="lazy" alt="..." class="logo" />
                  {{ drawerLabels[selectedLanguage].webinar }}
                </button>
                <button mat-menu-item (click)="openGroup()" class="drop-down-button"
                  matTooltip=" {{ drawerLabels[selectedLanguage].group }}"
                  (mouseenter)="speakText(drawerLabels[selectedLanguage].group)" (mouseleave)="stopSpeaking()">
                  <img src="{{ images.group }}" loading="lazy" alt="..." class="logo" />
                  {{ drawerLabels[selectedLanguage].group }}
                </button>
                <button mat-menu-item [matMenuTriggerFor]="menuForAboutUs" direction="ltr" class="drop-down-button"
                  matTooltip="{{ drawerLabels[selectedLanguage].aboutUs }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].aboutUs)
                  " (mouseleave)="stopSpeaking()">
                  <mat-icon class="icon">info</mat-icon>
                  <mat-menu #menuForAboutUs="matMenu">
                    <button mat-menu-item routerLink="/cep/about-us" class="drop-down-button"
                      matTooltip="{{ aboutUsLabels.aboutPCMCSmartSarathi }}" (mouseenter)="
                        speakText(aboutUsLabels.aboutPCMCSmartSarathi)
                      " (mouseleave)="stopSpeaking()">
                      {{ aboutUsLabels.aboutPCMCSmartSarathi }}
                    </button>
                    <button mat-menu-item routerLink="/cep/commissioner-information" class="drop-down-button"
                      matTooltip=" {{ aboutUsLabels.governingBody }}"
                      (mouseenter)="speakText(aboutUsLabels.governingBody)" (mouseleave)="stopSpeaking()">
                      {{ aboutUsLabels.governingBody }}
                    </button>
                    <!-- <button mat-menu-item routerLink="/cep/corporates-information"
                                          class="drop-down-button"
                                          matTooltip=" {{ aboutUsLabels.corporateInformation}}"
                                          (mouseenter)="speakText(aboutUsLabels.corporateInformation)"
                                          (mouseleave)="stopSpeaking()">{{aboutUsLabels.corporateInformation}}</button>
                                      <button mat-menu-item routerLink="/cep/zone-information"
                                          class="drop-down-button" matTooltip=" {{ aboutUsLabels.zoneInformation}}"
                                          (mouseenter)="speakText(aboutUsLabels.zoneInformation)"
                                          (mouseleave)="stopSpeaking()">{{aboutUsLabels.zoneInformation}}</button> -->

                    <button mat-menu-item routerLink="/cep/city-information" class="drop-down-button"
                      matTooltip=" {{ aboutUsLabels.cityInformation }}"
                      (mouseenter)="speakText(aboutUsLabels.cityInformation)" (mouseleave)="stopSpeaking()">
                      {{ aboutUsLabels.cityInformation }}
                    </button>
                    <button mat-menu-item routerLink="/cep/tourism-information" class="drop-down-button"
                      matTooltip=" {{ aboutUsLabels.tourismInformation }}"
                      (mouseenter)="speakText(aboutUsLabels.tourismInformation)" (mouseleave)="stopSpeaking()">
                      {{ aboutUsLabels.tourismInformation }}
                    </button>
                  </mat-menu>
                  {{ drawerLabels[selectedLanguage].aboutUs }}
                </button>
                <!-- <button mat-menu-item (click)="openContactUs('pcmcSmartSarathi')" class="drop-down-button"
                          matTooltip="{{ drawerLabels[selectedLanguage].contactUs }}" (mouseenter)="speakText(drawerLabels[selectedLanguage].contactUs)" (mouseleave)="stopSpeaking()">
                <img src={{images.contactUs}} class="logo">
                {{ drawerLabels[selectedLanguage].contactUs }}
              </button>
                          <button mat-menu-item (click)="openContactUs('sarathi')" class="drop-down-button"
                          matTooltip="{{ drawerLabels[selectedLanguage].contactUsSarathi }}" (mouseenter)="speakText(drawerLabels[selectedLanguage].contactUsSarathi)" (mouseleave)="stopSpeaking()">
                <img src={{images.contactSarathi}} class="logo">
                {{ drawerLabels[selectedLanguage].contactUsSarathi}}
                          </button> 
                          <button mat-menu-item (click)="openFeedback()" *ngIf="isLogin || !isLogin" class="drop-down-button"
                          matTooltip="{{ drawerLabels[selectedLanguage].feedback }}" (mouseenter)="speakText(drawerLabels[selectedLanguage].feedback)" (mouseleave)="stopSpeaking()">
              <mat-icon class="icon">contact_mail</mat-icon>
              {{ drawerLabels[selectedLanguage].feedback}}
                          </button> -->

                <!-- share -->
                <!-- <button mat-menu-item (click)="openShare()" class="drop-down-button">
                              <img src={{images.InviteOnSarathiImg}} class="logo">
                              {{ drawerLabels[selectedLanguage].shareSarathi }}
                          </button> -->

                <!-- <button mat-menu-item (click)="openCityFaq()" class="drop-down-button"
                          matTooltip="{{landingScreenLabels[selectedLanguage].info}}">
                              <img src={{images.webinarIcon}} class="logo">
                              {{landingScreenLabels[selectedLanguage].info}}
                          </button> -->

                <!-- <button mat-menu-item [matMenuTriggerFor]="menuForInvite" direction='ltr' class="drop-down-button"
                          matTooltip="{{ drawerLabels[selectedLanguage].shareTheReferralCode }}" (mouseenter)="speakText(drawerLabels[selectedLanguage].shareTheReferralCode)" (mouseleave)="stopSpeaking()">
                              <img src={{images.InviteOnSarathiImg}} class="logo">
                              <mat-menu #menuForInvite="matMenu">
                                      <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')" (mouseenter)="speakText('share on facebook')" (mouseleave)="stopSpeaking()">
                                          <img src={{images.facebookShareImg}} class="logo">
                                        Facebook
                                      </button>
                                      <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on twitter')" (mouseleave)="stopSpeaking()">
                                          <img src={{images.twitterShareImg}} class="logo">
                                          
                                        Twitter
                                      </button>
                                      <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
                                          <img src={{images.linkedinShareImg}} class="logo">

                                        LinkedIn
                                      </button>
                                      <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()"> 
                                          <img src={{images.whatsappShareImg}} class="logo">

                                        Whatsapp
                                      </button>
                                      <button mat-menu-item class="share-button" (click)="copyToClipboared('copy')" (mouseenter)="speakText('copy to Clipboared')" (mouseleave)="stopSpeaking()"> 
                                          <img src={{images.coptToClipboardImg}} class="logo">
                                        Copy To Clipboared
                                      </button>
                         
                          </mat-menu>
                          {{ drawerLabels[selectedLanguage].shareTheReferralCode }}
                          </button> -->

                <button mat-menu-item (click)="logoutTheUser()" class="drop-down-button"
                  matTooltip="{{ drawerLabels[selectedLanguage].logout }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].logout)
                  " (mouseleave)="stopSpeaking()">
                  <img src="{{ images.logout }}" loading="lazy" alt="..." class="logo" />
                  {{ drawerLabels[selectedLanguage].logout }}
                </button>
              </mat-menu>
            </div>
          </ng-container>

          <ng-container *ngIf="isLogin == false">
            <div class="profile">
              <img [matMenuTriggerFor]="options" *ngIf="!userProfileImage" src="{{ images.defaultProfilePicture }}" loading="lazy" alt="..."
                class="dropdown-profile-image pointer" [ngStyle]="{ 'background-color': themeBackgroundColor }"
                (mouseenter)="speakText('open side menu')" (mouseleave)="stopSpeaking()" />
              <mat-menu #options="matMenu" (mouseenter)="speakText('click to open side menu')"
                (mouseleave)="stopSpeaking()">
                <!-- <button
                  mat-menu-item
                  (click)="openWebinar()"
                  class="drop-down-button"
                  matTooltip=" {{ drawerLabels[selectedLanguage].webinar }}"
                  (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].webinar)
                  "
                  (mouseleave)="stopSpeaking()"
                >
                  <img src="{{ images.webinarIcon }}" class="logo" />
                  {{ drawerLabels[selectedLanguage].webinar }}
                </button> -->
                <!-- <button mat-menu-item (click)="openGroup()" class="drop-down-button"
                                   matTooltip=" {{ drawerLabels[selectedLanguage].group}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].group)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.group}} class="logo">
                                  {{ drawerLabels[selectedLanguage].group}}
                              </button> -->
                <button mat-menu-item [matMenuTriggerFor]="menuForAboutUs" direction="ltr" class="drop-down-button"
                  matTooltip="{{ drawerLabels[selectedLanguage].aboutUs }}" (mouseenter)="
                    speakText(drawerLabels[selectedLanguage].aboutUs)
                  " (mouseleave)="stopSpeaking()">
                  <mat-icon class="icon">info</mat-icon>
                  <mat-menu #menuForAboutUs="matMenu">
                    <button mat-menu-item routerLink="/cep/about-us" class="drop-down-button"
                      matTooltip="{{ aboutUsLabels.aboutPCMCSmartSarathi }}" (mouseenter)="
                        speakText(aboutUsLabels.aboutPCMCSmartSarathi)
                      " (mouseleave)="stopSpeaking()">
                      {{ aboutUsLabels.aboutPCMCSmartSarathi }}
                    </button>
                    <!-- <button mat-menu-item routerLink="/cep/commissioner-information" class="drop-down-button"
                      matTooltip=" {{ aboutUsLabels.governingBody }}"
                      (mouseenter)="speakText(aboutUsLabels.governingBody)" (mouseleave)="stopSpeaking()">
                      {{ aboutUsLabels.governingBody }}
                    </button> -->
                    <!-- <button mat-menu-item routerLink="/cep/corporates-information"
                                          class="drop-down-button"
                                          matTooltip=" {{ aboutUsLabels.corporateInformation}}"
                                          (mouseenter)="speakText(aboutUsLabels.corporateInformation)"
                                          (mouseleave)="stopSpeaking()">{{aboutUsLabels.corporateInformation}}</button>
                                      <button mat-menu-item routerLink="/cep/zone-information"
                                          class="drop-down-button" matTooltip=" {{ aboutUsLabels.zoneInformation}}"
                                          (mouseenter)="speakText(aboutUsLabels.zoneInformation)"
                                          (mouseleave)="stopSpeaking()">{{aboutUsLabels.zoneInformation}}</button> -->
<!-- 
                    <button mat-menu-item routerLink="/cep/city-information" class="drop-down-button"
                      matTooltip=" {{ aboutUsLabels.cityInformation }}"
                      (mouseenter)="speakText(aboutUsLabels.cityInformation)" (mouseleave)="stopSpeaking()">
                      {{ aboutUsLabels.cityInformation }}
                    </button>
                    <button mat-menu-item routerLink="/cep/tourism-information" class="drop-down-button"
                      matTooltip=" {{ aboutUsLabels.tourismInformation }}"
                      (mouseenter)="speakText(aboutUsLabels.tourismInformation)" (mouseleave)="stopSpeaking()">
                      {{ aboutUsLabels.tourismInformation }}
                    </button> -->
                  </mat-menu>
                  {{ drawerLabels[selectedLanguage].aboutUs }}
                </button>

                <!-- <button mat-menu-item  class="drop-down-button" (click)="openLogin()" >
                                  <mat-icon>login</mat-icon>
                                  {{ drawerLabels[selectedLanguage].login }}
                              </button> -->
              </mat-menu>
            </div>
          </ng-container>

          <!--*************** hamberger icon when small screen ****************-->
          <div class="hamberger-container" *ngIf="drawerLabels">
            <mat-icon class="hamburger-menu font-heading" [matMenuTriggerFor]="data">menu</mat-icon>
            <mat-menu #data="matMenu">
              <button mat-menu-item routerLink="/cep/user-profile" class="drop-down-button"
                matTooltip="{{ drawerLabels[selectedLanguage].myProfile }}" *ngIf="isLogin">
                <mat-icon class="icon">person</mat-icon>
                {{ drawerLabels[selectedLanguage].myProfile }}
              </button>
              <button mat-menu-item routerLink="/cep/favorite" *ngIf="isLogin" class="drop-down-button"
                matTooltip="{{ drawerLabels[selectedLanguage].favorite }}">
                <mat-icon class="icon">favorite</mat-icon>
                {{ drawerLabels[selectedLanguage].favorite }}
              </button>
              <button mat-menu-item (click)="openMyDealHistory()" *ngIf="enableFeature === true && isLogin"
                class="drop-down-button" matTooltip="{{ drawerLabels[selectedLanguage].myDeals }}">
                <img src="{{ images.myDeals }}" class="logo" loading="lazy" alt="..." />
                {{ drawerLabels[selectedLanguage].myDeals }}
              </button>
              <!--  <button mat-menu-item  *ngIf="enableFeature===true && (userData.isMerchant || userData.isBrand)" (click)="openMerchantTransaction()" class="drop-down-button"
                          matTooltip=" {{ drawerLabels[selectedLanguage].myDeals}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].myDeals)" (mouseleave)="stopSpeaking()">
                          <img src={{images.myDeals}} class="logo">
                          {{ drawerLabels[selectedLanguage].transactions}}
                      </button> -->
              <!-- <button mat-menu-item (click)="openMyLoyalty()" class="drop-down-button"
                          matTooltip="{{ drawerLabels[selectedLanguage].myLoyalty }}">
                              <img src={{images.myloyalty}} class="logo">
                              {{ drawerLabels[selectedLanguage].myLoyalty}}
                          </button> -->
              <button mat-menu-item (click)="openBlogListing()" class="drop-down-button"
                matTooltip="{{ drawerLabels[selectedLanguage].myBlogs }}" *ngIf="isLogin">
                <img src="{{ images.addBlog }}" class="logo" loading="lazy" alt="..." />
                {{ drawerLabels[selectedLanguage].myBlogs }}
              </button>
              <button mat-menu-item (click)="openForumListing()" class="drop-down-button"
                matTooltip="{{ drawerLabels[selectedLanguage].myForums }}" *ngIf="isLogin">
                <img src="{{ images.forum }}" class="logo" loading="lazy" alt="..." />
                {{ drawerLabels[selectedLanguage].myForums }}
              </button>
              <!-- <button mat-menu-item (click)="openMomListing()" class="drop-down-button"
                          matTooltip=" {{ drawerLabels[selectedLanguage].meetings}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].meetings)" (mouseleave)="stopSpeaking()">
                          <img src={{images.meetings}} class="logo">
                          {{ drawerLabels[selectedLanguage].meetings }} 
                          </button> -->
              <!-- <button *ngIf = "(userData.isMerchant || userData.isBrand)" mat-menu-item routerLink="/cep/add-deals" class="drop-down-button"
                              matTooltip="{{ drawerLabels[selectedLanguage].addDeals }}">
                              <img src={{images.addDeals}} class="logo">
                              {{ drawerLabels[selectedLanguage].addDeals}}
                          </button>
                          <button mat-menu-item (click)="openMerchantForm()"  *ngIf="(userData.isMerchant==false && isMerchantRequest==false && userData.isBrand==false && userData.isBrandRequest==false)" 
                          matTooltip="{{ drawerLabels[selectedLanguage].becomeAsAMerchantbrand }}" class="drop-down-button">
                              <img src={{images.addMerchant}} class="logo">
                                  {{ drawerLabels[selectedLanguage].becomeAsAMerchantbrand}}
                              </button> -->

              <!-- Pending merchant request -->
              <!-- <button mat-menu-item *ngIf = "(enableFeature===true && userData.isMerchant==false && isMerchantRequest==true && userData.isBrand==false && userData.isBrandRequest==false)" (click)="editMerchantForm()" class="drop-down-button"
                               matTooltip=" {{ drawerLabels[selectedLanguage].merchantApprovalPending}}" (mouseenter)="speakText(drawerLabels[selectedLanguage].merchantApprovalPending)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.addMerchant}} class="logo">
                                      {{ drawerLabels[selectedLanguage].merchantApprovalPending}}
                              </button> -->

              <!-- approved merchant request -->
              <!-- <button mat-menu-item *ngIf = "enableFeature===true && (userData.isMerchant || userData.isBrand)" (click)="editMerchantForm()" class="drop-down-button"
                               matTooltip="approvedmerchant" (mouseenter)="speakText(drawerLabels[selectedLanguage].approvedMerchant)" (mouseleave)="stopSpeaking()">
                                  <img src={{images.addMerchant}} class="logo">
                                  {{ drawerLabels[selectedLanguage].approvedMerchant}}
                              </button> -->

              <!-- <button *ngIf="isNewsLetterSubscribe == false  && isNewsletterRequest==false" mat-menu-item (click)="openNewsSubscription('subscription')" class="drop-down-button"
                          matTooltip="{{ drawerLabels[selectedLanguage].subscribeToNewsletter }}">
                              <img src={{images.newsLetter}} class="logo">
                              {{ drawerLabels[selectedLanguage].subscribeToNewsletter}}
                            
                          </button> -->
              <!-- Subscribe to Newsletter -->
              <!-- <button *ngIf="isNewsLetterSubscribe == true  || isNewsletterRequest==true" mat-menu-item (click)="openNewsSubscription('subscription')" class="drop-down-button"
                          matTooltip="{{ drawerLabels[selectedLanguage].unsubscribeToNewsletter }}">
                              <img src={{images.newsLetter}} class="logo">
                              {{ drawerLabels[selectedLanguage].unsubscribeToNewsletter}}
                              
                          </button> -->
              <!-- Unsubscribe to Newsletter -->
              <!-- <button mat-menu-item (click)="openMyCart()" class="drop-down-button" *ngIf="enableFeature === true"
                              matTooltip="{{ drawerLabels[selectedLanguage].myCart }}">
                              <div>
                                  <div class="cart-badge">{{userData.cartCnt}}</div>
                                  <img src={{images.myCart}} class="logo">
                              </div>
                              {{ drawerLabels[selectedLanguage].myCart}}
                          </button> -->
              <button mat-menu-item *ngIf="isLogin" (click)="openWebinar()" class="drop-down-button"
                matTooltip="{{ drawerLabels[selectedLanguage].webinar }}">
                <img src="{{ images.webinarIcon }}" class="logo" loading="lazy" alt="..." />
                {{ drawerLabels[selectedLanguage].webinar }}
              </button>
              <button mat-menu-item *ngIf="isLogin" (click)="openGroup()" class="drop-down-button"
                matTooltip="{{ drawerLabels[selectedLanguage].group }}">
                <img src="{{ images.group }}" class="logo" loading="lazy" alt="..." />
                {{ drawerLabels[selectedLanguage].group }}
              </button>
              <button mat-menu-item [matMenuTriggerFor]="menuForAboutUs" direction="ltr" *ngIf="isLogin || !isLogin"
                class="drop-down-button" matTooltip="{{ aboutUsLabels.aboutPCMCSmartSarathi }}">
                <mat-icon class="icon">info</mat-icon>
                <mat-menu #menuForAboutUs="matMenu">
                  <button mat-menu-item routerLink="/cep/about-us" class="drop-down-button">
                    {{ aboutUsLabels.aboutPCMCSmartSarathi }}
                  </button>
                  <!-- <button mat-menu-item routerLink="/cep/commissioner-information" class="drop-down-button"
                    matTooltip="{{ aboutUsLabels.commissionerInformation }}">
                    {{ aboutUsLabels.commissionerInformation }}
                  </button> -->
                  <!-- <button mat-menu-item routerLink="/cep/corporates-information"
                                      class="drop-down-button"
                                      matTooltip="{{ aboutUsLabels.corporateInformation }}">{{aboutUsLabels.corporateInformation}}</button>
                                  <button mat-menu-item routerLink="/cep/zone-information" class="drop-down-button"
                                      matTooltip="{{ aboutUsLabels.zoneInformation }}">{{aboutUsLabels.zoneInformation}}</button> -->
                  <!-- <button mat-menu-item routerLink="/cep/city-information" class="drop-down-button"
                    matTooltip="{{ aboutUsLabels.cityInformation }}">
                    {{ aboutUsLabels.cityInformation }}
                  </button>
                  <button mat-menu-item routerLink="/cep/tourism-information" class="drop-down-button"
                    matTooltip="{{ aboutUsLabels.tourismInformation }}">
                    {{ aboutUsLabels.tourismInformation }}
                  </button> -->
                </mat-menu>

                {{ drawerLabels[selectedLanguage].aboutUs }}
              </button>

              <!-- <button mat-menu-item (click)="openContactUs('pcmcSmartSarathi')" *ngIf="isLogin || !isLogin" class="drop-down-button"
                      matTooltip="{{ drawerLabels[selectedLanguage].contactUs }}">
              <img src={{images.contactUs}} class="logo">
              {{ drawerLabels[selectedLanguage].contactUs }}
            </button>
                      <button mat-menu-item (click)="openContactUs('sarathi')" *ngIf="isLogin || !isLogin" class="drop-down-button"
                      matTooltip="{{ drawerLabels[selectedLanguage].contactUsSarathi }}">
              <img src={{images.contactSarathi}} class="logo">
              {{ drawerLabels[selectedLanguage].contactUsSarathi}}
                          </button>
                          
                          <button *ngIf="isNewsLetterSubscribe ==false" mat-menu-item (click)="openNewsSubscription('subscription')" class="drop-down-button">
                              <img src={{images.newsLetter}} class="logo">
                              Subscribe to Newsletter
                          </button>
                          <button *ngIf="isNewsLetterSubscribe == true" mat-menu-item (click)="openNewsSubscription('subscription')" class="drop-down-button">
                              <img src={{images.newsLetter}} class="logo">
                              Unsubscribe to Newsletter
                          </button>
                          
                          <button mat-menu-item (click)="openFeedback()" *ngIf="isLogin || !isLogin" class="drop-down-button"
                          matTooltip="{{ drawerLabels[selectedLanguage].feedback }}">
              <mat-icon class="icon">contact_mail</mat-icon>
              {{ drawerLabels[selectedLanguage].feedback}}
                          </button> -->

              <!-- <button mat-menu-item (click)="openShare()" class="drop-down-button">
                              <img src={{images.InviteOnSarathiImg}} class="logo">
                              {{ drawerLabels[selectedLanguage].shareSarathi }}
                          </button> -->

              <!-- <button mat-menu-item (click)="openCityFaq()" class="drop-down-button"
                          matTooltip="{{landingScreenLabels[selectedLanguage].info}}">
                              <img src={{images.webinarIcon}} class="logo">
                              {{landingScreenLabels[selectedLanguage].info}}
                          </button> -->

              <!-- <button mat-menu-item [matMenuTriggerFor]="menuForInvite" direction='ltr' class="drop-down-button" matTooltip="{{ drawerLabels[selectedLanguage].shareTheReferralCode }}">
                              <img src={{images.InviteOnSarathiImg}} class="logo">
                              <mat-menu #menuForInvite="matMenu">
                                      <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')">
                                          <img src={{images.facebookShareImg}} class="logo">
                                        Facebook
                                      </button>
                                      <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')">
                                          <img src={{images.twitterShareImg}} class="logo">
                                          
                                        Twitter
                                      </button>
                                      <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')">
                                          <img src={{images.linkedinShareImg}} class="logo">

                                        LinkedIn
                                      </button>
                                      <button mat-menu-item class="share-button"(click)="generateShareLink('whatsapp')"> 
                                          <img src={{images.whatsappShareImg}} class="logo">

                                        Whatsapp
                                      </button>
                                      <button mat-menu-item class="share-button" (click)="copyToClipboared('copy')"> 
                                          <img src={{images.coptToClipboardImg}} class="logo">
                                        Copy To Clipboared
                                      </button>
                         
                          </mat-menu>
                          {{ drawerLabels[selectedLanguage].shareTheReferralCode }}
                          </button> -->

              <button mat-menu-item [matMenuTriggerFor]="menu" direction="ltr" class="drop-down-button"
                matTooltip="{{ drawerLabels[selectedLanguage].language }}">
                <mat-icon class="icon">language</mat-icon>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [value]="en" (click)="changeLanguage('mar')">
                    {{ drawerLabels[selectedLanguage].english }}
                  </button>
                  <button mat-menu-item [value]="mar" (click)="changeLanguage('en')">
                    {{ drawerLabels[selectedLanguage].marathi }}
                  </button>
                </mat-menu>
                {{ drawerLabels[selectedLanguage].language }}
              </button>
              <button mat-menu-item (click)="logoutTheUser()" *ngIf="isLogin" class="drop-down-button"
                matTooltip="{{ drawerLabels[selectedLanguage].logout }}">
                <img src="{{ images.logout }}" class="logo"  loading="lazy" alt="..."/>
                {{ drawerLabels[selectedLanguage].logout }}
              </button>
              <button mat-menu-item *ngIf="!isLogin" class="drop-down-button" (click)="openLogin()">
                <mat-icon>login</mat-icon>
                {{ drawerLabels[selectedLanguage].login }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="header-content-tab">
      <div class="tabs-container-tab" *ngIf="landingScreenLabels">
        <a routerLink="/cep/home" [routerLinkActive]="['tab-active', 'header-color-home']" matTooltip="{{
            landingScreenLabels[selectedLanguage].home | uppercase
          }}" (mouseenter)="speakText(landingScreenLabels[selectedLanguage].home)" (mouseleave)="stopSpeaking()">{{
          landingScreenLabels[selectedLanguage].home }}</a>
        <a routerLink="/cep/feeds" [routerLinkActive]="['tab-active-tab', 'header-color-feeds']" matTooltip="{{
            landingScreenLabels[selectedLanguage].feed | uppercase
          }}">{{ landingScreenLabels[selectedLanguage].feed | uppercase }}</a>
        <a routerLink="/cep/e-services" [routerLinkActive]="['tab-active-tab', 'header-color-services']" matTooltip="{{
          landingScreenLabels[selectedLanguage].services | uppercase
        }}">{{ landingScreenLabels[selectedLanguage].services | uppercase }}</a>
        <a routerLink="/cep/near-me" [routerLinkActive]="['tab-active-tab', 'header-color-near-me']" matTooltip="{{
            landingScreenLabels[selectedLanguage].nearMe | uppercase
          }}">{{ landingScreenLabels[selectedLanguage].nearMe | uppercase }}</a>
        <a routerLink="/cep/offers" [routerLinkActive]="['tab-active', 'header-color-near-me']" matTooltip="{{
            landingScreenLabels[selectedLanguage].more | uppercase
          }}">{{ landingScreenLabels[selectedLanguage].more | uppercase }}</a>
        <!-- <a routerLink="/cep/city-info" [routerLinkActive]="['tab-active-tab', 'header-color-city-info']" matTooltip="{{ landingScreenLabels[selectedLanguage].moreServices	 | uppercase}}">{{landingScreenLabels[selectedLanguage].info | uppercase}}</a> -->
        <!-- <a class="subnav">
        <button class="subnavbtn" name="other"
          (click)="openSubNav()">{{landingScreenLabels[selectedLanguage].others}}<i
            class="fa fa-caret-down"></i></button>
        <div class="subnav-content" *ngIf='subNavClicked'>
          <ul>
            <li *ngFor="let nameList of subHeaderNamesArr; let i = index" (click)="urlsData(i)">
              <a class=" " href={{this.subHeaderUrl}} target="_blank">{{nameList}}</a>
            </li>
          </ul>
        </div>
      </a> -->
      </div>
    </div>
  </div>
  <!-- tabs link in small screen  -->

  <!-- chatbot icon -->
  <!-- <div class="chatbot-open" *ngIf="(url != '/cep/near-me')&& staticImages && isChatbot" matTooltip="PCMC Chatbot">
      <img class="chatbot-image" (click)="openChatbot()" src="{{staticImages.chatBoatImage}}" alt="img">
  </div> -->

  <!-- chatbot in iframe -->
  <!-- <div *ngIf="isChatbotLoaded && chatbotWebLink && !isChatbot" style="position: fixed; z-index: 1;
              bottom:20px; right: 20px; width:400px; height: 500px;">
      <mat-icon class="cross-icon font-heading" (click)="closeChatbot()">clear</mat-icon>
      <iframe [src]="chatbotWebLink" frameborder="0" width="400px" height="500px"></iframe>
  </div> -->
</div>