
<!-- /*******************************
 * Component -  feeds type list block
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 **************************************/ -->
 <div class="feed-list-container" *ngIf="!isMobile">
  <!-- heading -->
  <div class="title">
    <img [matMenuTriggerFor]="options" src= {{images.filterImg}} loading="lazy" alt="..." />
    <mat-menu #options="matMenu">
      <ng-container *ngFor="let data of sortByListingArray; let i = index">
        <!-- <button mat-menu-item class="mat-menu-heading" *ngIf=" i == 0">
          {{data[1]}}
        </button> -->
        <button mat-menu-item (click)="sortByFilter(data[0])" *ngIf=" i > 1">
          {{data[1]}}
        </button>
      </ng-container>
    </mat-menu>
  </div>
  <!-- checkbox for filters -->
  <div class="feed-data">
    <!-- <div class="feed-lists">
      <div class="checkbox-filter" *ngIf = "filterData[0].value">
        <mat-checkbox name="filterData" [(ngModel)]="filterData[0].checked" [value]="filterData[0].value" (change)="changeFilter($event)"></mat-checkbox>
      </div> 
      <div class="feed-name">
        <p>{{filterData[0].value}}</p>
      </div>  
    </div> -->
    <!-- <div></div> -->
    <div class="feed-lists" *ngFor="let item of filterData; let i=index">
      <ng-container *ngIf="i==0">
        <div class="checkbox-filter">
          <mat-checkbox [(ngModel)]="item.checked" [value]="item.value" (change)="changeFilterAll()"></mat-checkbox>
        </div>
        <div class="feed-name">
          <p>{{item.value}}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="i>0">
        <div class="checkbox-filter">
          <mat-checkbox [(ngModel)]="item.checked" [value]="item.value" (change)="changeFilter()"></mat-checkbox>
        </div>
        <div class="feed-name">
          <p>{{item.value}}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<mat-accordion *ngIf="isMobile">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-description>
        <div class="title">
          <img src="../../../../assets/images/feeds/filter.png"  loading="lazy" alt="..."/>
          <!-- <p [ngStyle]="{'color': backgroundColor}">{{ feed[selectedLanguage].feed | uppercase}}</p> -->
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="sort-by-filter-icon">
      <img [matMenuTriggerFor]="filter" src={{images.sortByFilterImg}} loading="lazy" alt="...">
      <mat-menu #filter="matMenu">
        <ng-container *ngFor="let data of sortByListingArray; let i = index">
          <!-- <button mat-menu-item class="mat-menu-heading" *ngIf=" i == 0">
            {{data[1]}}
          </button> -->
          <button mat-menu-item (click)="sortByFilter(data[0])" *ngIf=" i > 1">
            {{data[1]}}
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <div class="feed-data">
      <div class="feed-lists" *ngFor="let item of filterData; let i=index">
        <ng-container *ngIf="i==0">
          <div class="checkbox-filter">
            <mat-checkbox [(ngModel)]="item.checked" [value]="item.value" (change)="changeFilterAll()"></mat-checkbox>
          </div>
          <div class="feed-name">
            <p>{{item.value}}</p>
          </div>
        </ng-container>
        <ng-container *ngIf="i>0">
          <div class="checkbox-filter">
            <mat-checkbox [(ngModel)]="item.checked" [value]="item.value" (change)="changeFilter()"></mat-checkbox>
          </div>
          <div class="feed-name">
            <p>{{item.value}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>