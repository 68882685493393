<div class="container">
  <div class="text">
    <div class="title" *ngIf=" selectedLanguage === 'en' ">
      Hello
    </div>
    <div class="sub-title" *ngIf=" selectedLanguage === 'en' ">
      Welcome and thank you for visiting the portal.
    </div>
    <div class="sub-title" *ngIf=" selectedLanguage === 'en' ">
      Please register and proceed to become a member of our community right away.
    </div>
    <div class="title" *ngIf=" selectedLanguage === 'mar' ">
      नमस्कार !
    </div>
    <div class="sub-title" *ngIf=" selectedLanguage === 'mar' ">
      PMC CARE पोर्टलमध्ये आपले स्वागत... 
      पोर्टलला भेट दिल्याबद्दल धन्यवाद !
    </div>
    <div class="sub-title" *ngIf=" selectedLanguage === 'mar' ">
      कृपया नोंदणी करा आणि लगेच आमच्या समुदायाचे सदस्य व्हा!
      
    </div>
    <div class="text-img">
      <img src="{{images.OCR_Image}}" class="img" loading="lazy" alt="..." />
    </div>
  </div>
  <div class="content">
    <div class="header">
      <div class="logo-container">
        <img src="{{images.finalLogin}}" class="logo" loading="lazy" alt="..." />
      </div>
      <div class="logo-container">
        <img src="{{images.pmc_logo}}" class="pmc-logo" loading="lazy" alt="..." />
      </div>
      <div class="logo-container">
        <button class="language-button" (click)="onChangeLangButton()">
          {{ showSelectedLanguage }}
        </button>
      </div>
    </div>
    <div class="content-title">
      <span style="margin-top: 5%;margin-left: 2%;" *ngIf="selectedLanguage === 'en' ">Citizen Registration</span>
      <span style="margin-top: 5%;margin-left: 2%;" *ngIf="selectedLanguage === 'mar' ">नागरिक नोंदणी</span>
    </div>
    <div class="ocr-form">

      <div class="ocr-containers">
        <div class="ocr-div">
          <label class="label">{{registerLabels[selectedLanguage].fullName}}: <span class="red-asterisk">*</span> </label>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input matInput style="font-size: 18px;" [(ngModel)]="fullname" (click)="validateFullName()" onkeydown="return /[A-Za-z ]/i.test(event.key)" />
          </mat-form-field>
          <p *ngIf="isFullNameRequired && !fullname " class="validation-message">Full Name is required</p>
        </div>
        <div class="ocr-div">
          <label class="label">{{updatelabels[selectedLanguage].email}}:<span class="red-asterisk">*</span></label>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input matInput style="font-size: 18px;" [(ngModel)]="emailId" (input)="onChangeEvent($event)" />
          </mat-form-field>
          <div style="font-size: 12px; margin-top: 2px;color: red;" *ngIf="emailNotValid">
          
            {{registerLabels[selectedLanguage].emailIsNotValid}}
          </div>
        </div>
      </div>

      <div class="ocr-containers">
        <div class="ocr-div">
          <label class="label" *ngIf=" selectedLanguage === 'en' ">Mobile Number:<span class="red-asterisk">*</span></label>
          <label class="label" *ngIf=" selectedLanguage === 'mar' ">मोबाईल नंबर:<span class="red-asterisk">*</span></label>
          <mat-form-field appearance="outline" style="width: 100%;" >
            <input matInput style="font-size: 18px;" [disabled]="true"  maxlength="10" [(ngModel)]="mobileNumber" (keypress)="numberOnly($event)" />
          </mat-form-field>
        </div>
        <div class="ocr-div">
          <label class="label">{{registerLabels[selectedLanguage].gender}}: <span class="red-asterisk">*</span></label>
          <mat-radio-group name="gender" [(ngModel)]="gender" class="radio-group">
            <mat-radio-button class="radio" value="M" >
              {{registerLabels[selectedLanguage].male}}
            </mat-radio-button>
            <mat-radio-button class="radio" value="F">
              {{registerLabels[selectedLanguage].female}}</mat-radio-button>
            <mat-radio-button class="radio" value="O">
              {{registerLabels[selectedLanguage].other}}</mat-radio-button>
          </mat-radio-group>
          <!-- <mat-error *ngIf="registrationForm.get('gender').invalid && (registrationForm.get('gender').dirty || registrationForm.submitted)" class="validation-message1">
            Gender is required.
          </mat-error> -->
        </div>
      </div>

      <div class="ocr-containers">
        <div style="display: flex;flex-direction: column;align-items: flex-start;width: 100%;">
          <label class="label">{{updatelabels[selectedLanguage].address}}:<span class="red-asterisk">*</span></label>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input matInput [(ngModel)]="address" maxlength='200' ngx-gp-autocomplete [options]="options" placeholder="{{updatelabels[selectedLanguage].address}}"
              (onAddressChange)="handleDataAddressChange($event)" style="font-size: 18px;" [(ngModel)]="address"  (blur)="validateAddress()"/>
          </mat-form-field>
          <p *ngIf="isAddressRequired" class="validation-message">
            <span *ngIf=" selectedLanguage === 'en' ">Address is required</span>
            <span *ngIf=" selectedLanguage === 'mar' ">पत्ता आवश्यक आहे</span>
          </p>
        </div>
      </div>

      <div class="ocr-containers">
        <div class="ocr-div">
          <label class="label">{{updatelabels[selectedLanguage].newPassword}}: <span class="red-asterisk">*</span></label>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input matInput style="font-size: 18px;" [type]="newPass ? 'text' : 'password' "
              [(ngModel)]="newpassword"  (input)="validateNewPassword()"  />
            <mat-icon class="eye-mat-icon" matSuffix (click)="newPass = !newPass">
              {{newPass ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </mat-form-field>
          <!-- <p *ngIf="isNewPasswordRequired" class="validation-message">New Password is required</p> -->
          <mat-error *ngIf="newpassword.length > 0 && passwordvalid"  style="font-size: 13px; color: red;">{{
            getPasswordError()
          }}</mat-error>

        </div>
        <div class="ocr-div">
          <label class="label">{{updatelabels[selectedLanguage].confirmedPassword}}:<span class="red-asterisk">*</span></label>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input matInput style="font-size: 18px;" [type]="confirmPass ? 'text' : 'password' "
              [(ngModel)]="confirmpassword" (input)="validateConfirmPass()"/>
            <mat-icon class="eye-mat-icon" matSuffix (click)="confirmPass = !confirmPass">
              {{confirmPass ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </mat-form-field>
          <!-- <p *ngIf="isConfirmPasswordRequired" class="validation-message">confirm Password is required</p> -->
          <p *ngIf="!passwordsMatch && !isConfirmPasswordRequired" class="validation-message">
            {{getConfirmPasswordError()}}
          </p>
         </div>
      </div>

      <div class="ocr-containers">
        <div class="ocr-div">
          <label class="label">{{ registerLabels[selectedLanguage].dateOfBirth }}:<span class="red-asterisk">*</span></label>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input style="font-size: 18px;" autocomplete="off" [readonly]="true" (click)="picker.open()" [min]="minDate" [max]="maxDate"
            matInput [matDatepicker]="picker" [(ngModel)]="dateOfBirth">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="ocr-div">
          <label class="label">{{registerLabels[selectedLanguage].referalCode}} </label>
          <mat-form-field appearance="outline" style="width: 100%;">
            <input matInput style="font-size: 18px;" [(ngModel)]="referralCode" />
          </mat-form-field>
        </div>
      </div>

      <div style="display: flex;justify-content: center;align-items: center;margin-top: 2%;">
        <input type="checkbox" class="checkbox" checked="true"  />
        <span class="term-text">{{registerLabels[selectedLanguage].submit}}  
          <span class="term-text-bold" (click)="openwindow()">{{ footerLabels[selectedLanguage].termsAndConditions}}
          </span>
        </span>
      </div>
  

      <button mat-stroked-button [disabled]=" fullname.length === 0 || emailId.length === 0 ||
       mobileNumber.length !== 10 || address.length === 0 || gender.length === 0 || birthDate.length === 0 ||
       newpassword.length === 0 || confirmpassword.length === 0|| emailNotValid || passwordvalid ||
        !passwordsMatch " class="submit" (click)="registerUser()">
        {{registerLabels[selectedLanguage]. submit}} </button>
    </div>
  </div>
</div>
