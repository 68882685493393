<!--************************************* 
Created by: Akanksha Jadhav
Created Date: 27/12/19
updated by: Akanksha Jadhav
Updated Date: 10/01/2020
Description: contact us popup
***************************************** -->
<div class="popup">
    <mat-icon class="cross-button font-card-heading" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>
     <!-- connection lost condition -->
     <div *ngIf="serverErrorMessage" class="connectionDiv">
        <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
        <span>{{serverErrorMessage}}</span>
    </div>
    <!-- loader -->
    <div *ngIf="isLoading" class="popup-spinner">
        <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
        </mat-progress-spinner>
    </div>
    
    <!-- server error messages -->
    <div *ngIf="isNetworkError" class="popup-spinner">
        <span>Contact Details are temporarily unavailable !!!</span>
    </div>
    <!-- main page -->
    <div class="container" *ngIf="!isLoading && !isNetworkError">
        <!-- cross button -->
        
        <!-- header of popup -->
        <div class="popup-header">
            <div class="pcmc-image">
                <img class="pcmc-logo" src="{{images.pcmcLogo}}" loading="lazy" alt="...">
            </div>
            <!-- contact details -->
            <div class="content" *ngIf="contactUsLabel && selectedLanguage">
                <span class="main-text">{{contactUsLabel[selectedLanguage].contactUsIfYouHaveAnyQuery}}</span>
                <div class="contact-info">
                    <ng-container *ngIf="device === 'mobile'">
                        <div class="contact-one" *ngIf="contactUsResult[selectedLanguage].contactUsNo1">
                            <img class="social-images" src="{{images.whatsapp}}" loading="lazy" alt="...">
                            <span class="text  ">
                                <a
                                    href="tel:{{contactUsResult['en'].contactUsNo1}}">{{contactUsResult[selectedLanguage].contactUsNo1}}</a>
                            </span>
                        </div>
                        <div class="contact-two" *ngIf="contactUsResult[selectedLanguage].contactUsNo2">
                            <img class="social-images" src="{{images.whatsapp}}" loading="lazy" alt="...">
                            <span class="text  ">
                                <a
                                    href="tel:{{contactUsResult['en'].contactUsNo2}}">{{contactUsResult[selectedLanguage].contactUsNo2}}</a>
                            </span>
                        </div>
                        <div class="contact-three" *ngIf="contactUsResult.contactUsEmail">
                            <img class="mail-social-images" src="{{images.mail}}" loading="lazy" alt="...">
                            <span class="text  ">
                                <a
                                    href="mailto:{{contactUsResult.contactUsEmail}}">{{contactUsResult.contactUsEmail}}</a>
                            </span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="device === 'desktop'">
                        <div class="contact-one" *ngIf="contactUsResult[selectedLanguage].contactUsNo1">
                            <img class="social-images" src="{{images.whatsapp}}" loading="lazy" alt="...">
                            <span class="text  ">{{contactUsResult[selectedLanguage].contactUsNo1}}</span>
                        </div>
                        <div class="contact-two" *ngIf="contactUsResult[selectedLanguage].contactUsNo2">
                            <img class="social-images" src="{{images.whatsapp}}" loading="lazy" alt="...">
                            <span class="text  ">{{contactUsResult[selectedLanguage].contactUsNo2}}</span>
                        </div>
                        <div class="contact-three" *ngIf="contactUsResult.contactUsEmail">
                            <img class="mail-social-images" src="{{images.mail}}" loading="lazy" alt="...">
                            <span class="text-mail  ">
                                <a
                                    href="mailto:{{contactUsResult.contactUsEmail}}">{{contactUsResult.contactUsEmail}}</a>
                            </span>
                        </div>
                    </ng-container>
                    <span *ngIf="contactUsResult[selectedLanguage].timing" class="time-text  ">
                        {{contactUsResult[selectedLanguage].timing}}
                    </span>
                </div>
            </div>
        </div>
        <!-- footer of popup-->
        <div class="popup-footer">
            <!-- follow options -->
            <div class="follow-us-container">
                <span class="follow-text">{{contactUsLabel[selectedLanguage].youCanFollowUsOn}}</span>
                <div class="follow-on">
                    <img *ngIf="contactUsResult['facebookLink']" class="social" (click)="redirectToFacebook()"
                        src="{{images.facebook}}" loading="lazy" alt="...">
                    <img *ngIf="contactUsResult['twitterLink']" class="social" (click)="redirectToTwitter()"
                        src="{{images.twitter}}" loading="lazy" alt="..." >
                    <img *ngIf="contactUsResult['instagramLink']" class="social" (click)="redirectToInstagram()"
                        src="{{images.instagram}}" loading="lazy" alt="...">
                    <img *ngIf="contactUsResult['linkedinLink']" class="social" (click)="redirectToLinkedIn()"
                        src="{{images.linkedIn}}" loading="lazy" alt="...">
                </div>
            </div>
            <!-- sponsered images -->
            <div class="image-container">
                <img class="image" src="{{images.pcmcOfficialLogo}}" loading="lazy" alt="...">
                <img class="image" src="{{images.smartCityLogo}}" loading="lazy" alt="...">
                <img class="image" src="{{images.smartCityPimpariChinchwadLogo}}" loading="lazy" alt="...">
                <img class="image" src="{{images.digitalIndiaLogo}}" loading="lazy" alt="...">
            </div>
        </div>
    </div>
</div>