<!--************************************* 
Created by: Akanksha Jadhav
Created Date: 
updated by: Akanksha Jadhav
Updated Date: 27/12/19
Description: about us scren
***************************************** -->
<!-- For Loader -->
<div *ngIf="isLoading" class="spinner">
  <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div>
  <!-- connection lost condition -->
  <div *ngIf="serverErrorMessage" class="connectionDiv">
    <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
    <span>{{serverErrorMessage}}</span>
  </div>
<!-- Error message for time out function -->
<div *ngIf="isNetworkError" class="spinner" (mouseenter)="speakText('This page is currently not available !!')"  (mouseleave)="stopSpeaking()">
  <span>This page is currently not available !!</span>
</div>

<!-- main code of page -->
<div *ngIf="!isLoading && topic && !serverErrorMessage" class="container">
  <!-- header of page -->
  <div class="heading-container" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].aboutPCMCSmartSarathi)"  (mouseleave)="stopSpeaking()">
    <span class="font-heading"><strong class="font-fix">{{aboutUsLabels[selectedLanguage].aboutPCMCSmartSarathi}}</strong></span><br>
  </div>

  <!-- card of about us -->
  <mat-card class="card">
    <div class="header-container" (mouseenter)="speakText(topic[selectedLanguage].info[0][0])"  (mouseleave)="stopSpeaking()">
      <span class="header font-card-heading">{{topic[selectedLanguage].info[0][0]}}</span><br>
      <span class="small-font"></span>
    </div>

    <!-- images below header of card -->
    <div class="img-container">
      <img class="images" src="{{images.pmc_logo}}" loading="lazy" alt="..."  (mouseenter)="speakText('PMC Official')"  (mouseleave)="stopSpeaking()">
      <img class="images" src="{{images.smartCityLogo}}" loading="lazy" alt="..." (mouseenter)="speakText('smart city')"  (mouseleave)="stopSpeaking()">
      <!-- <img class="images" src="{{images.smartCityPimpariChinchwadLogo}}"  (mouseenter)="speakText('smart city smartCity Pimpari Chinchwad')"  (mouseleave)="stopSpeaking()"> -->
      <img class="images" src="{{images.digitalIndiaLogo}}" loading="lazy" alt="..." (mouseenter)="speakText('digital india')"  (mouseleave)="stopSpeaking()">
    </div>

    <!-- about pcmc smart sarathi content -->
    <div class="content" (mouseenter)="speakText(topic[selectedLanguage].info[0][1])"  (mouseleave)="stopSpeaking()">
      <p [innerHTML]="topic[selectedLanguage].info[0][1]"></p>
    </div>
  </mat-card>
</div>