/**
 * Created by Akanksha J
 * For showing about us content on website
 */
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '../../../shared/services/localstorage.service';

/**
 * component routing
 */
import { Router } from '@angular/router';
import { Images } from '../../../shared/config/images-config';
import { MatCard } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatCard]
})

/**
 * main class of about us component
 */
export class AboutUsComponent implements OnInit, OnDestroy, AfterViewInit {
	/**
	 * about us result
	 */
	public topic;
	images = Images;
	/**
	 * selected language variable
	 */
	public selectedLanguage;
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * value of spinner
	 */
	value = 50;
	/**
	 * loading status of spinner
	 */
	isLoading = false;
	/**
	 * loading status of spinner
	 */
	isLoaded = false;
	/**
	 * nw error message
	 */
	isNetworkError: boolean;
	/**
	 * api detail reponse variable
	 */
	apiDetails: {};
	/**
	 * server error messge
	 */
	serverMessage: string;
	serverErrorMessage: string;
	/**
	 * about us labels response
	 */
	aboutUsLabels: {};
	isBlind;
	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particular component
	 */
	constructor(
		private router: Router,
		private store: Store<fromStore.CEPState>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private dialog: MatDialog,
		private localStorageService: LocalStorageService,

	) { }

	/**
	 * angular method which gets called on page initialization
	 */
	ngOnInit() {
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on About Us', 'userLabel', 1);

		/**
		 * server error handling
		 */
		this.store.select(fromStore.getAboutUsServerErrorMessage)
			.subscribe((serverError) => {
				if (serverError != null) {
					if (serverError === 'InvalidCredentials') {
						this.router.navigate(['/Login/login'])
					} else {
						this.serverErrorMessage = serverError;
						if (this.serverErrorMessage) {
							console.error(this.serverErrorMessage);
						}
					}
					// this.serverErrorMessage = null;
				}
			});

		/**
		 * about us labels
		 */
		this.store.select(fromStore.getAboutUsLabels)
			.subscribe(response => {
				this.aboutUsLabels = response;
			});

		/**
		 * selected language selector
		 */
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		/**
		 * about us loading variable
		 */
		this.store.select(fromStore.getIsAboutUsLoading)
			.subscribe((isLoading) => {
				this.isLoading = isLoading;
			});

		/**
		 * about us content assigned to a variable
		 */
		this.store.select(fromStore.getIsAboutUsLoaded)
			.subscribe((isLoaded) => {
				this.isLoaded = isLoaded;
				if (!this.isLoading && !this.isLoaded) {
					this.isNetworkError = false;
				}
				if (this.isLoaded) {
					this.store.select(fromStore.getAboutUsResult)
						.subscribe((labels) => {
							this.topic = labels['aboutUs'];
						});
				}
			});

		/**
		 * get api details
		 */
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		/**
		 * About us details API call
		 */
		this.store.dispatch(new fromStore.GetAboutUsDetails({
			url: this.apiDetails['applicationConfigurations'].base_url +
				this.apiDetails['applicationConfigurations']['aboutUs'].getAboutUsDetails.url,
			method: this.apiDetails['applicationConfigurations']['aboutUs'].getAboutUsDetails.method
		}));
	}



/**
	 * Created by Vinita Sharma
	 * For removing HTML tags from text 
	 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return this.splitSentance(span.textContent || span.innerText)
	  };

	  /**
	 * Created by Vinita Sharma
	 * to split the paragraph in sentances
	 */
	splitSentance(sen){
		if(!sen.match(/[^\.!\?]+[\.!\?]+/g)){
			return [sen]
		}else{
			return sen.match(/[^\.!\?]+[\.!\?]+/g)
		}
	}

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
			speechSynthesis.cancel();
			var newmessage = this.extractContent(textToSpeak)	
			var voices = speechSynthesis.getVoices();
			
			for (let sentance = 0; sentance < newmessage.length; sentance++) {
				var message = new SpeechSynthesisUtterance(newmessage[sentance]);
				message.voice = voices[9];
				message.lang = 'hi-IN';
				message.volume = 1;
				speechSynthesis.speak(message);
			}
		}
	}


 /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	stopSpeaking(){
		speechSynthesis.cancel();
	  }



	/**
	 * angular method which gets called when page gets destroyed
	 */
	ngOnDestroy() {
		this.serverErrorMessage = null;
	}

	/**
	 * angular method which gets called on page view initialization
	 */
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}
}
