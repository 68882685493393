import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClientModule } from '@angular/common/http';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';

@Injectable({
	providedIn: 'root'
})

export class AroundMeService {

	constructor(
		private http: HttpClientModule,
		private apiCallService: ApiCallService
	) { }

	getAroundMeLocations(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}
	getAroundMeCategory(request) {
		let method = request.method;
		let url = request.url;
		let body = request.body;
		return this.apiCallService.apiCall(method, url, body);
	}

}
