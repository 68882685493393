/**********************************
 * Component -  shared fav component
 * Created By - Akshata choulwar
 * Updated By - Akshata choulwar
 * Updated On - 29/04/2020
 **********************************/
import { Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Images } from '../../config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ClipboardService } from '../../services/clipboard.service';
import { Router, RouterModule, RouterLink } from '@angular/router';
import { CommonModule, UpperCasePipe, NgClass, NgIf } from '@angular/common';


@Component({
    selector: 'app-shared-fav-card',
    //   standalone: true,
    //   imports: [CommonModule, UpperCasePipe, RouterModule],
    //   schemas:[CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: './shared-fav-card.component.html',
    styleUrls: ['./shared-fav-card.component.scss'],
    standalone: true,
    imports: [RouterLink, NgClass, NgIf, UpperCasePipe]
})
export class SharedFavCardComponent implements OnInit, AfterContentChecked {
  @Input() productData: any;
  blogDate;
  image = Images;
  selectedLanguage: string;
  landingLables: {};
  favorite: string;
  apiDetails: {};
  feedsDate: Date;
  todaysDate: Date;
  dateDiff: number;
  userId: string;
  getUserId: string;
  token: string;
  articleData: any;
  shareUrl: any;
  isBlind;
	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particulat component
	 */
	constructor(
		private dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private ref: ChangeDetectorRef,
		private router: Router,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private dynamicLink: DynamicLinksService,
		private toast: ToastrService,
		private apiCallService: ApiCallService,
		private clipboardService:ClipboardService,
		private googleAnalyticsService: GoogleAnalyticsService) { }

	ngOnInit() {
		if (this.productData) {
			if (this.productData.favourite) {
				this.favorite = 'favorite';
			} else {
				this.favorite = 'favorite_border';
			}
		}
		

			this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
					if (this.userId) {
						
					} else {
						this.router.navigate[('/cep/home')]
					}
				
				} else {
					this.userId = null;
				}
			});
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
				}
			});
	}
   

	/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }

	/**
	 * Created by Akanksha J
	 * for reporting blog as abuse
	 */
	// REPORT_ABUSE() {
	// 	this.dialog.open(ReportAbusePopupComponent, {
	// 		data: {
	// 			userId: this.userId,
	// 			timelineId: this.productData.timelineId,
	// 			engagementType: 'blog',
	// 			engagementId: this.productData.engagementId
	// 		},
	// 		maxHeight: '100vh',
	// 		maxWidth: '100vw',
	// 		disableClose: false
	// 	});
	// }
	/**
	 * Craeted by Ajay C
	 * For unmarking the favourites
	 */
	unMarkFavorite() {
		if (this.productData.favourite) {
			this.productData = Object.assign({}, this.productData, { favourite: false });
			this.favorite = 'favorite_border';
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.productData = Object.assign({}, this.productData, { favourite: true });
			this.favorite = 'favorite';
		}
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.apiDetails) {
				if (this.userId) {
					this.store.dispatch(new fromStore.MarkFavorite({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].addToFavourite.url,
						method: this.apiDetails['user'].addToFavourite.method,
						body: {
							userId: this.userId,
							engagementId: this.productData.engagementId,
							engagementType: this.productData.engagementType
						}
					}));
				}
			}
		}
	}

/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url =  this.shareUrl+ '/cep/feeds/product/'+ this.productData.engagementId
		var metaTag  = {
			socialTitle: this.productData.en.title,
			socialImageLink: this.productData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	} 

	ngAfterContentChecked() {
		this.ref.detectChanges();
		this.blogDate = new Date(this.productData.updatedAt).toDateString();
	}


	// clickOnShare() {
	// 	//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of product', 'Click Event', 1);
	// }
	async clickOnShare() {
			
	
		let url = environment.shareurl +"/cep/feeds/product/" + this.productData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toast.success("Link Copied to Clipboard")
		}else{
			this.toast.error("Unable to copy Link")
		}

	}
}

