<!-- <div class="article-heading">
  <label>RELATED ARTICLES</label>
</div> -->
<div class="overflow-div">
  <div class="related-articles-card">
      <div *ngFor="let data of feedsData; let i=index">
        <div class="related-article-card" *ngIf="data.engagementType != 'survey'">
          <div class="relatedImg">
            <img class="relImg" src="{{data.imageFavourite.length > 0 ? data.imageFavourite : data.imageContent}}" loading="lazy" alt="...">
          </div>
          <div class="details">
            <div class="engagementType-not-survay">
              <p *ngIf="data.engagementType == 'blog' && selectedLanguage === 'en' ">BLOG</p>
              <p *ngIf="data.engagementType == 'blog' && selectedLanguage === 'mar' ">ब्लॉग</p>
              <p *ngIf="data.engagementType == 'article' && selectedLanguage === 'en' ">ARTICLE</p>
              <p *ngIf="data.engagementType == 'article' && selectedLanguage === 'mar' ">लेख</p>
              <p *ngIf="data.engagementType == 'event' && selectedLanguage === 'en' ">EVENT</p>
              <p *ngIf="data.engagementType == 'event' && selectedLanguage === 'mar' ">कार्यक्रम</p>
              <p *ngIf="data.engagementType == 'news' && selectedLanguage === 'en' ">NEWS</p>
              <p *ngIf="data.engagementType == 'news' && selectedLanguage === 'mar' ">बातम्या</p>
              <p *ngIf="data.engagementType == 'competition' && selectedLanguage === 'en' ">COMPETITION</p>
              <p *ngIf="data.engagementType == 'competition' && selectedLanguage === 'mar' ">स्पर्धा</p>
              <p *ngIf="data.engagementType == 'forum' && selectedLanguage === 'en' ">FORUM</p>
              <p *ngIf="data.engagementType == 'forum' && selectedLanguage === 'mar' ">मंच</p>
            </div>
            <div class="article-post-heading" (click)="redirection(data)">
              <p class="font-medium">{{data[selectedLanguage].heading ? data[selectedLanguage]?.heading : data[selectedLanguage]?.question}}</p>
            </div>
            <!-- <div class="article-post-heading" [routerLink]="['/cep/feeds/', data.engagementType , data.timelineId,'/feeds']">
              <p class="font-medium">{{data[selectedLanguage].heading?data[selectedLanguage].heading:data[selectedLanguage].question}}</p>
            </div> -->
            <div class="article-desc" [routerLink]="['/cep/feeds/', data.engagementType, data.timelineId]">
              <p>{{data[selectedLanguage].description ? data[selectedLanguage].description : data[selectedLanguage].content}}</p>
            </div>

            <div class="related-article-footer">
                <div class="related-post-date">
                  
                  <!-- <label>{{feedLabels[selectedLanguage].postedOn}}</label> -->
                  <p>{{convertDate(data.updatedAt)}}</p>
                </div>
                <div class="share-and-fav">
                  <!-- <div>
                    <i class="material-icons" [matMenuTriggerFor]="menu" matTooltip="{{landingLables[selectedLanguage].share}}" >
                      share
                    </i>
                  </div>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item class="share-button" (click)="generateShareLink('facebook', data)">
                      <img class="social-share-img" src="{{image.facebookShareImg}}">
                      Facebook
                    </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('twitter', data)">
                      <img class="social-share-img" src="{{image.twitterShareImg}}">
                      Twitter
                    </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin', data)">
                      <img class="social-share-img" src="{{image.linkedinShareImg}}">
                      LinkedIn
                    </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp', data)"> 
                      <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
                      Whatsapp
                    </button>
                  </mat-menu> -->
                  <div *ngIf="data.engagementType != 'news'"> 
                    <i class="material-icons favorite-icon" [ngClass]="{'active-favorite': data.favourite}" matTooltip="{{landingLables[selectedLanguage]?.addToFavorites}}"
                      (click)="unMarkFavorite(data, i)">
                      {{data.favouriteIcon}}
                    </i>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
<!-- for survay/poll -->
      <div *ngFor="let data of feedsData; let i=index">
        <div class="survay-related-article-card" *ngIf="data.engagementType == 'survey'">
          <div class="feed-tag">
            <p>{{ landingLables[selectedLanguage]?.surveypoll | uppercase }}</p>
          </div>

          <div class="details">
            <div class="article-post-heading" [routerLink]="['/cep/feeds/', data.engagementType , data.timelineId]">
              <p class="font-medium">{{data[selectedLanguage]?.heading}}</p>
            </div>
            <div class="survay-article-desc" [routerLink]="['/cep/feeds/', data.engagementType , data.timelineId]">
              <p>{{data[selectedLanguage]?.description}}</p>
            </div>
          </div>

          <div class="survey-link-hading">
            <button mat-raised-button *ngIf="data[selectedLanguage].selectedType">{{data[selectedLanguage]?.selectedType}}</button>
            <button mat-raised-button *ngIf="!data[selectedLanguage].selectedType">Take Survay</button>
          </div>

          <div class="survay-related-article-footer">
            <div class="related-post-date">
              <!-- <label>{{feedLabels[selectedLanguage].postedOn}}</label> -->
              <p>{{convertDate(data.updatedAt)}}</p>
            </div>
            <div class="share-and-fav">
              <!-- <div>
                <i class="material-icons" [matMenuTriggerFor]="menu" matTooltip="{{landingLables[selectedLanguage].share}}" >
                  share
                </i>
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="share-button" (click)="generateShareLink('facebook', data)">
                  <img class="social-share-img" src="{{image.facebookShareImg}}">
                  Facebook
                </button>
                <button mat-menu-item class="share-button"  (click)="generateShareLink('twitter', data)">
                  <img class="social-share-img" src="{{image.twitterShareImg}}">
                  Twitter
                </button>
                <button mat-menu-item class="share-button"  (click)="generateShareLink('linkedin', data)">
                  <img class="social-share-img" src="{{image.linkedinShareImg}}">
                  LinkedIn
                </button>
                <button mat-menu-item class="share-button"  (click)="generateShareLink('whatsapp', data)"> 
                  <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
                  Whatsapp
                </button>
              </mat-menu> -->
              <div *ngIf="data.engagementType != 'news'"> 
                <i class="material-icons favorite-icon" [ngClass]="{'active-favorite': data.favourite}" matTooltip="{{landingLables[selectedLanguage]?.addToFavorites}}"
                  (click)="unMarkFavorite(data, i)">
                  {{data?.favouriteIcon}}
                </i>
              </div>
            </div>
        </div>

        </div>
      </div>

  </div>
</div>