
<div class="dialogbox" *ngIf="data">
  <div *ngIf="isLoading" class="dialog-spinner">
      <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
      </mat-progress-spinner>
  </div>
  <div class="dialogbox" *ngIf="!isLoading">
    <div class="popup-header">
      <div class="popup-heading">
        <h2>{{extraLabels[selectedLanguage].Heading}}</h2>
        <button class="close-button" (click)="closeDialog()">X</button> 
      </div>
    </div>
    <div class="popup-container">
      <mat-radio-group [(ngModel)]="selectedBank">
        <div class="mat-option">
          <mat-radio-button [value]="extraLabels[selectedLanguage].value1">
            <div class="radio-label">
              <span>{{extraLabels[selectedLanguage].Title1}}</span>
              <span>{{extraLabels[selectedLanguage].subTitle1}}</span>
              <span>{{extraLabels[selectedLanguage].value1}}</span>
            </div>
          </mat-radio-button>
        </div>
        <div  class="mat-option">
          <mat-radio-button [value]="extraLabels[selectedLanguage].value2">
            <div class="radio-label">
              <span>{{extraLabels[selectedLanguage].Title2}}</span>
              <span>{{extraLabels[selectedLanguage].subTitle2}}</span>
              <span>{{extraLabels[selectedLanguage].value2}}</span>
            </div>
          </mat-radio-button>
        </div>       
      </mat-radio-group>
    </div>
    <div class="popup-button-container">
      <div class="align-buttons">
        <div
         
          (click)="pay(data)"          
          [ngClass]="{'pay-button': selectedBank, 'pay-button-disabled': !selectedBank}"
        >          
          <span id="buttonText">{{ extraLabels[selectedLanguage].selectBank }}</span>
        </div>
      </div>
    </div>
  </div>
</div>