/*********************************
 * Component -  product details screen
 * Created By - Akanksha J
 * Updated By - Akanksha J
 * Updated On - 20/01/2020
 **********************************/

import { Component, OnInit, Input, ChangeDetectorRef, Pipe, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Images } from '../../config/images-config';
import { ActivatedRoute, Router, RouterModule, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
// import { FeedbackComponent } from '../feedback/feedback.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ClipboardService } from '../../services/clipboard.service';
import { environment } from 'src/environments/environment';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { DetailsPageSliderComponent } from '../details-page-slider/details-page-slider.component';
import { MatTooltip } from '@angular/material/tooltip';
import { NgIf, NgClass, UpperCasePipe } from '@angular/common';


@Component({
    selector: 'app-details-page-product',
    templateUrl: './details-page-product.component.html',
    styleUrls: ['./details-page-product.component.scss'],
    standalone: true,
    imports: [NgIf, MatTooltip, RouterLink, DetailsPageSliderComponent, MatButton, MatIcon, MatMenuTrigger, MatMenu, MatMenuItem, NgClass, UpperCasePipe]
})

export class DetailsPageProductComponent implements OnInit {
  @Input() productData: any;
  image = Images;
  public feedsData: any;
  public eventDate;
  apiDetails = {};
  timelineId: any;
  color = 'primary';
  mode = "indeterminate";
  value = 50;
  isLoaded = false;
  isLoading = false;
  message: string;
  selectedLanguage: string;
  landingLables: {};
  feedsDate: string;
  postDate: string;
  favorite: string;
  userId: string;
  token: string;
  feedFilterLabels: {};
  feedLabels: {};
  endDate: string;
  startDate: string;
  latlag: any;
  latitude: any;
  longitude: any;
  feedStatusCode: number;
  shareUrl: string;
  errMessage: string;
  feedsContent: any;

  /**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particular component
	*/
  constructor(private activeParams: ActivatedRoute,
    private store: Store<fromStore.CEPState>,
    private changeDetectRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private encrDecr: EncrDecrService,
    public dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsService: GoogleAnalyticsService,
    private apiCallService: ApiCallService,
		private deviceService: DeviceDetectorService,
    private router: Router,
    private clipboardService: ClipboardService,
		private dynamicLink: DynamicLinksService
  ) { }

  ngOnInit() {
    this.activeParams.params.subscribe((params:any) => {
      this.timelineId = params.timelineId;
    });

    //getting token and user Id
    this.store.select(fromStore.getToken)
      .subscribe((token) => {
        this.token = token;
        if (this.token) {
          let localUserId = this.localStorageService.getUserId();
          this.userId = this.encrDecr.get(localUserId);
          //this.googleAnalyticsService.eventEmitter('Dashboard', 'Click on event by ' + this.userId, 'userLabel', 1);
        } else {
          this.userId = null;
        }
      });

    // API call for getting engagement details
    this.store.select(fromStore.getApiCallingList)
      .subscribe((apiDetails) => {
        this.apiDetails = apiDetails;
        if (Object.keys(this.apiDetails).length > 0) {
          this.shareUrl = this.apiDetails['shareUrl'];
          this.store.dispatch(new fromStore.GetEngeagementDetails({
            url: this.apiDetails['userEngagement'].base_url +
              this.apiDetails['userEngagement'].timeline.getEngagement.url,
            method: this.apiDetails['userEngagement'].timeline.getEngagement.method,
            body: {
              'timelineId': this.timelineId,
              'userId': this.userId
            }
          }));
        }
      });

    // this.store.select(fromStore.feedListingStatusCode).subscribe((feedStatusCode) => {
    //   this.feedStatusCode = feedStatusCode;
    //   if (this.feedStatusCode == 200) {
    this.store.select(fromStore.isDetailsScreenLoading)
      .subscribe(isLoaded => {
        this.isLoaded = isLoaded;
        if (this.isLoaded) {

          this.store.select(fromStore.getFeedsServerErrorMessage)
            .subscribe((error) => {
              this.errMessage = error;
              if (this.errMessage) {

              }
            })


          // getting favouriyes details
          this.store.select(fromStore.feedDetailsResult)
            .subscribe((feedsData) => {
              this.feedsData = feedsData;
              if (Object.keys(this.feedsData).length != 0) {
                this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
                if (this.feedsData['favourite']) {
                  this.favorite = 'favorite';
                } else {
                  this.favorite = 'favorite_border';
                }
              }
              this.postDate = new Date(feedsData['updatedAt']).toDateString();
              this.endDate = new Date(feedsData['startDate']).toDateString();
              this.startDate = new Date(feedsData['endDate']).toDateString();
              this.latlag = this.feedsData['geometry'];
              if (this.latlag) {
                this.latitude = this.latlag.coordinates[0];
                this.longitude = this.latlag.coordinates[1];
              }
            });
        }
      });
    //   } else if (this.feedStatusCode == 500) {
    //     this.router.navigate(['/404/no-page-found']);
    //   }
    // })



    this.store
      .select(fromStore.isDetailsScreenLoading)
      .subscribe(isLoading => {
        this.isLoading = isLoading;
      });

    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
        this.store.select(fromStore.getLandingScreenLabels)
          .subscribe((labels) => {
            this.landingLables = labels;
          });

        this.store.select(fromStore.getfeedsFilterLabel)
          .subscribe((labels) => {
            this.feedFilterLabels = labels;
          });

        this.store.select(fromStore.getFeedsLabels)
          .subscribe((labels) => {
            this.feedLabels = labels;
          });
      });

    this.store.select(fromStore.getFeedsListingErrorMessage)
      .subscribe(message => {
        this.message = message;
      });



    //this.googleAnalyticsService.eventEmitter('Dashboard', ' User click on event', 'userLabel', 1);
  }

  ngAfterContentChecked() {
    this.changeDetectRef.detectChanges();
  }

  /**
	 * Created by Akanksha J
	 * for reporting product as abuse
	 */
	reportAsAbuse() {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.feedsData['timelineId'],
				engagementType: 'product',
				engagementId: this.feedsData['engagementId']
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
  }
  
  /**
   * Created by Ajay C
   * For unmarking or marking as favourites 
   */
  unMarkFavorite() {
    if (this.feedsData['favourite']) {
      this.feedsData = Object.assign({}, this.feedsData, { favourite: false })
      this.favorite = 'favorite_border'
    } else {
      this.feedsData = Object.assign({}, this.feedsData, { favourite: true })
      this.favorite = 'favorite'
    }
    // this.store.select(fromStore.getApiCallingList)
    //   .subscribe((apiDetails) => {
    //     this.apiDetails = apiDetails;
    if (Object.keys(this.apiDetails).length > 0) {
      if (this.userId) {
        this.store.dispatch(new fromStore.MarkFavorite({
          url: this.apiDetails['user'].base_url +
            this.apiDetails['user'].addToFavourite.url,
          method: this.apiDetails['user'].addToFavourite.method,
          body: {
            "userId": this.userId,
            "engagementId": this.feedsData['engagementId'],
            "engagementType": "event"
          }
        }));
      }
    }
    // });

  }

	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url = this.shareUrl + "/cep/feeds/product/" + this.feedsData['timelineId']
		var metaTag  = {
			socialTitle: this.feedsData.en.heading,
			socialImageLink: this.feedsData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

  /**
   * Created by Ajay C
   * For redirecting to google map to show event venue
   */
  redirectToGoogleMap() {
    window.open("http://maps.google.com?q=" + this.latitude + "," + this.longitude + "");
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    //this.googleAnalyticsService.eventEmitter('Dashboard', 'User change the page', 'Click Event', 1);
    // if (this.userId && !this.feedsData['feedback']) {
    //   const dialogRef = this.dialog.open(FeedbackComponent,
    //     {
    //       data: {
    //         engagementId: this.feedsData['engagementId'],
    //         engagementType: this.feedsData['engagementType']
    //       },
    //       height: '360px',
    //       width: '350px',
    //       autoFocus: false
    //     });
    // }
  }

  async clickOnShare() {
    //this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of event', 'Click Event', 1);
    this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.DEAL_DEATILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.feedsData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/product/" + this.feedsData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toastr.success("Link Copied to Clipboard")
		}else{
			this.toastr.error("Unable to copy Link")
		}
  }
}
