import { Component, Input, OnInit, Output, EventEmitter, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { Images } from "../../../shared/config/images-config";
import { PartialPropertyTaxPay } from '../partial-ptax-pay/partial-ptax-pay.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from "@angular/material/icon";
import { NgIf } from "@angular/common";



enum ARREARS {
  PENDING,
  DUE,
  PROCESSING,
  PAID,
}

interface IProperty {
  ACCT_NO: string;
  OWNER_NAME1: string;
  BILL_ADDRESS: string;
  OWNER_NAME2: string;
  BILL_NAME: string;
  MOBILE: string;
  EMAIL: string;
  PROPERTY_DESC: string;
  TOTAL_RV: string;
  PROPERTY_TYPE: string;
  SECTION: string;
  WARD: string;
  PETH: string;
  DUES: string;
}

@Component({
    selector: "app-property-card",
    templateUrl: "./property-card.component.html",
    styleUrls: ["./property-card.component.scss"],
    standalone: true,
    imports: [NgIf, MatIcon],
})

export class PropertyCardComponent implements OnInit {
  @Input() data: IProperty;
  @Input() labels;
  @Input() selectedLanguage;
  arrears: typeof ARREARS = ARREARS;
  state: ARREARS = ARREARS.PENDING;
  AppImages: typeof Images = Images;
  @Input() progress: any;

  @Output() viewAction = new EventEmitter();
  @Output() payAction = new EventEmitter();
  @Output() partialPayAction = new EventEmitter();
  partialAnount:any


  imageBase =
    "https://api.pmccare.in/image/v1/PMC%2Fuser%2FU54381623904889125%2FImage%2Fmoney-icon";
  images = this.AppImages.money;
  

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  getCardState(property: IProperty) {
    const due = Number(property.DUES);
    
    if (!due) return (this.state = ARREARS.PENDING);
    if (due >= 1) return (this.state = ARREARS.DUE);
    if (due < 1) return (this.state = ARREARS.PAID);
    //TODO: add state for processing payment
  }

  getIconByState(property: IProperty) {
    if (!property) return this.images.pending;

    const state = this.getCardState(property);

    if (state === this.arrears.DUE) return this.images.due;
    if (state === this.arrears.PAID) return this.images.paid;
    if (state === this.arrears.PENDING) return this.images.pending;
    if (state === this.arrears.PROCESSING) return this.images.processing;
  }

  view(property: IProperty) {
    this.viewAction.emit("view");
  }

  pay(property: IProperty) {
    this.payAction.emit("pay");
  }

  partialPay(property: IProperty) {
    const dialogWidth = window.innerWidth <= 600 ? '90%' : '30%'; // Set width based on screen size
    const dialogRef = this.dialog.open(PartialPropertyTaxPay, {
      width:dialogWidth,
      data: { amountDue: parseFloat(property.DUES) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Partial payment amount:', result);
        this.partialAnount = result
      this.partialPayAction.emit(result);
      }
    });
  }
}
