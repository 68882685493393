<!--/*******************************
 * Component -  Loyalty point page
 * Created By - Neha R
 * Created on - 13/04/2020
 * Updated By - Neha R
 * Updated On - 13/04/2020
 *************************************/-->

 <div class='overlap-container'>
    <!-- connection lost condition -->
    <div *ngIf='serverMessage' class='connectionDiv'>
        <img src={{images.connectionLost}} loading="lazy" alt="..." class='connectionLostImg'>
        <span>{{serverMessage}}</span> 
    </div>
    <!-- main page loader -->
    <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>

     <!-- main page -->
     <div *ngIf='!isLoading' class='main-container'>
        <div class="my-loyalty-name font-main-heading" 
        (mouseenter)="speakText(myLoyaltyLables[selectedLanguage].myLoyalty)"  (mouseleave)="stopSpeaking()">{{myLoyaltyLables[selectedLanguage].myLoyalty | uppercase}}</div>
             <!-- data not found error handling -->
  

         <div class="my-loyalty" >
            <div *ngIf="dataNotFoundMsg || loyaltyData['loyalty'].length == 0 ">
                <div class="error-messages" (mouseenter)="speakText('no data found')"  (mouseleave)="stopSpeaking()">
                    <img src="{{images.noDataFound}}" loading="lazy" alt="..." class="no-data-image-favourite" alt="">
                    <!-- <span class="data-not-found">{{errorMessages[selectedLanguage]['500']}}</span> -->
                </div>
            </div>
             <div class="my-loyalty-box" *ngIf = "myLoyaltyLables  && !dataNotFoundMsg && loyaltyData['loyalty'].length > 0">

                 <div class="loyalty-image">
                     <img class="image-loyalty" src="{{images.myLoyalty}}" loading="lazy" alt="..." (mouseenter)="speakText('total loyalty' + loyaltyPoint?.earned + '+' + loyaltyPoint?.redeem )"  (mouseleave)="stopSpeaking()">
                     <div class="loyalty-point" *ngIf = "loyaltyPoint.earned != 0 || loyaltyPoint.redeem != 0">
                         <div class="points font-main-heading" (mouseenter)="speakText('total loyalty' + loyaltyPoint?.earned + '+' + loyaltyPoint?.redeem )"  (mouseleave)="stopSpeaking()">{{loyaltyPoint?.earned}} + {{loyaltyPoint?.redeem}}</div>
                         <div class="name font-sub-heading" (mouseenter)="speakText(myLoyaltyLables[selectedLanguage]?.totalLoyalty)"  (mouseleave)="stopSpeaking()">{{myLoyaltyLables[selectedLanguage]?.totalLoyalty | uppercase}}</div>
                     </div>
                 </div>
                 <div class="loyalty-boxes"  *ngIf = "loyaltyData['loyalty'] != []">
                     <div  class="redeem-card"  *ngFor = "let item of loyaltyData['loyalty']">
                        <!-- won -->
                        <div *ngIf = "item.isEarnedLoyalty == true">
                            <div  class="redeem-container" >
                                <mat-card class="my-loylty-mini-card">
                                    <div class="each-card-detail">
                                        <div class="each-image">
                                            <img class="image-won" src="{{images.wonLoyalty}}" loading="lazy" alt="..." >
                                            <div class="loyalty-used">
                                                <div class="font-small" (mouseenter)="speakText('you won' + myLoyaltyLables[selectedLanguage]?.youWon)"  (mouseleave)="stopSpeaking()">{{myLoyaltyLables[selectedLanguage]?.youWon | uppercase}}</div>
                                                <div class="loyalty-points-won font-medium" 
                                                (mouseenter)="speakText(item?.loyaltyPoint + myLoyaltyLables[selectedLanguage]?.loyalty)"  (mouseleave)="stopSpeaking()">{{item?.loyaltyPoint}}  {{myLoyaltyLables[selectedLanguage]?.loyalty | uppercase}}</div>
                                            </div>
                                        </div>
                                        <div class="each-detail">
                                            <div class="loyalty-info-heading font-sub-heading" 
                                            (mouseenter)="speakText('earned' + myLoyaltyLables[selectedLanguage]?.earned)"  (mouseleave)="stopSpeaking()">{{myLoyaltyLables[selectedLanguage]?.earned}}</div>
                                            <div class="loyalty-info-heading font-small" 
                                            (mouseenter)="speakText(item[selectedLanguage]?.productName)"  (mouseleave)="stopSpeaking()">{{item[selectedLanguage]?.productName}} </div>
                                            <div class="sub-heading font-small"><p [innerHTML]="item[selectedLanguage].description"></p></div>
                                        </div>
                                    </div>
                                </mat-card>
                           </div>
                        </div>
                        <!-- redeem -->
                        <div *ngIf= "item.isRedeemLoyalty == true">
                        <div class="redeem-container">
                            <mat-card class="my-loylty-mini-card">
                                <div class="each-card-detail">
                                   <div class="each-image">
                                       <img class="image-redeem" src="{{images.redeem}}" loading="lazy" alt="..." (mouseenter)="speakText(myLoyaltyLables[selectedLanguage]?.tapToRedeem)"  (mouseleave)="stopSpeaking()">
                                       <div class="tap-redeem" (click)="tapToRedeem(item)" 
                                       (mouseenter)="speakText(myLoyaltyLables[selectedLanguage]?.tapToRedeem)"  (mouseleave)="stopSpeaking()">{{myLoyaltyLables[selectedLanguage]?.tapToRedeem}}</div>
                                    </div>
                                    <div class="each-detail" (mouseenter)="speakText(myLoyaltyLables[selectedLanguage]?.tapToRedeem)"  (mouseleave)="stopSpeaking()">
                                        <div class="loyalty-info-heading font-sub-heading" (click)="tapToRedeem(item)">{{myLoyaltyLables[selectedLanguage]?.tapToRedeem}}</div>
                                        <div class="loyalty-info-heading font-small" (mouseenter)="speakText(item[selectedLanguage]?.productName)"  (mouseleave)="stopSpeaking()">{{item[selectedLanguage]?.productName}} </div>
                                        <div class="sub-heading font-small" (mouseenter)="speakText(item[selectedLanguage].description)"  (mouseleave)="stopSpeaking()"><p [innerHTML]="item[selectedLanguage].description"></p></div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                        </div>
                         <!-- used -->
                        <div  *ngIf = "item.isUsedLoyalty == true">
                            <div  class="redeem-container">
                                <mat-card class="my-loylty-mini-card">
                                    <div class="each-card-detail">
                                        <div class="each-image">
                                            <img class="image" src="{{images.usedLoyalty}}" loading="lazy" alt="...">
                                            <div class="loyalty-used">
                                                <div class="spent font-small" 
                                                (mouseenter)="speakText('you spent' + myLoyaltyLables[selectedLanguage]?.youSpent)"  (mouseleave)="stopSpeaking()">{{myLoyaltyLables[selectedLanguage]?.youSpent | uppercase}}</div>
                                                <div class="loyalty-points font-medium" 
                                                (mouseenter)="speakText(item.loyaltyPoint + myLoyaltyLables[selectedLanguage]?.loyalty)"  (mouseleave)="stopSpeaking()">{{item.loyaltyPoint}} {{myLoyaltyLables[selectedLanguage]?.loyalty | uppercase}}</div>
                                            </div>
                                        </div>
                                        <div class="each-detail">
                                            <div class="loyalty-info-heading font-sub-heading" 
                                            (mouseenter)="speakText(myLoyaltyLables[selectedLanguage]?.used)"  (mouseleave)="stopSpeaking()">{{myLoyaltyLables[selectedLanguage]?.used}}</div>
                                            <div class="loyalty-info-heading font-small" 
                                            (mouseenter)="speakText(item[selectedLanguage]?.productName)"  (mouseleave)="stopSpeaking()">{{item[selectedLanguage]?.productName}} </div>
                                            <div class="sub-heading font-small"><p [innerHTML]="item[selectedLanguage]?.description"></p></div>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                     </div>
                 </div>
             </div>
         </div>
    </div>
</div>

