import { Injectable } from '@angular/core';
import { Auth, signInWithPopup, TwitterAuthProvider } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceTwitter {
  constructor(private auth: Auth) {}

  // Sign in with Twitter
  TwitterAuth() {
    const provider = new TwitterAuthProvider();
    return signInWithPopup(this.auth, provider)
      .then((result) => {
        console.log('Twitter login successful', result);
        return result;
      })
      .catch((error) => {
        console.error('Twitter login failed', error);
        throw error;
      });
  }

  // General method to sign in with any provider
  AuthLogin(provider: TwitterAuthProvider) {
    return signInWithPopup(this.auth, provider)
      .then((result) => {
        console.log('Login successful', result);
        return result;
      })
      .catch((error) => {
        console.error('Login failed', error);
        throw error;
      });
  }
}
