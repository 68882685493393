import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as FavoriteAction from '../../store/action/favorite.action';
import { FavoriteService } from '../../store/api-services/favorite.service';
import { of } from 'rxjs';

@Injectable()

export class FavoriteEffect {
	/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private actions$: Actions,
		private FavoriteService: FavoriteService
	) { }


	apiFavoriteDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FavoriteAction.GetFavoriteDetails>(FavoriteAction.GET_FAVORITE_DETAILS),
			switchMap((action) => {
				return this.FavoriteService.getFavoriteDetails(action.payload).pipe(
					map(favoriteData => new FavoriteAction.GetFavoriteDetailsSuccess(favoriteData)),
					catchError(error => of(new FavoriteAction.GetFavoriteDetailsFail(error)))
				);
			})
		)
	);

	markFavorite$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FavoriteAction.MarkFavorite>(FavoriteAction.MARK_FAVORITE),
			switchMap((action) => {
				return this.FavoriteService.markFavoriteCall(action.payload).pipe(
					map(markFavorite => new FavoriteAction.MarkFavoriteSuccess(markFavorite)),
					catchError(error => of(new FavoriteAction.MarkFavoriteFail(error)))
				);
			})
		)
	);

	markFavoriteWebinar$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FavoriteAction.MarkFavoriteWebinar>(FavoriteAction.MARK_FAVORITE_WEBINAR),
			switchMap((action) => {
				return this.FavoriteService.markFavoriteWebinarCall(action.payload).pipe(
					map(markFavoriteWebinar => new FavoriteAction.MarkFavoriteWebinarSuccess(markFavoriteWebinar)),
					catchError(error => of(new FavoriteAction.MarkFavoriteWebinarFail(error)))
				);
			})
		)
	);


}
