<!--/*******************************
 * Component -  Merchant transactions listing page
 * Created By - Vinita Sharma
 * Created on - 8/1/2021
 *************************************/-->

 <div class='overlap-container'>
    <!-- connection lost condition -->
    <div *ngIf='serverMessage' class='connectionDiv'>
        <img src={{images.connectionLost}}  loading="lazy" alt="..." class='connectionLostImg'>
        <span>{{serverMessage}}</span> 
    </div>
    <!-- main page loader -->
    <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>
  
     <!-- main page -->

      <!-- heading -->
    <div class="feed-title">
        <label class="font-heading">{{merchantTransactionLables[selectedLanguage].transactionHistory | uppercase}}</label>
    </div>

     <div  class='main-container'>
        <mat-card class="my-deal-card">


            <div class="deal-name font-sub-heading" >
            <div class="QRButton" (click)="openPopUp()" id="myBtn">{{merchantTransactionLables[selectedLanguage].enterScanId}}</div>
            </div>


        <!-- data not found error handling -->
        <ng-container *ngIf="dataNotFoundMsg">
            <div class="error-messages">
                <img src="{{images.noDataFound}}" loading="lazy" alt="..." class="no-data-image-favourite" alt=""
                (mouseenter)="speakText(errorMessages[selectedLanguage]['500'])"  (mouseleave)="stopSpeaking()">
            <span class="data-not-found">{{errorMessages[selectedLanguage]['500']}}</span>
            </div>
        </ng-container>

            <div class="mini-card"  *ngIf='!isLoading && !dataNotFoundMsg'>
                <div *ngFor = "let item of merchantTransactionList">
                    <div class="each-info-card">
                        <div class="order font-normal">{{merchantTransactionLables[selectedLanguage].transactionId}}: {{item.transactionId}}</div>
                        <mat-card class="my-deal-mini-card">
                            <div class="each-card-detail">
                                <div class="each-image">
                                    <img class="image" src={{item.profilePhoto?item.profilePhoto:images.defaultProfilePicture}} loading="lazy" alt="...">
                                </div>
                                <div class="each-detail">
                                    <div class="buyer-name" >{{item.firstName}} {{item.lastName}} </div>
                                    <div class="deal-info-heading font-heading">{{item.displayCompanyName}}</div>
                                    <div class="sub-heading font-medium" >{{item[selectedLanguage].productName}}  </div>
                                    <div class="rupees">
                                        <div class="price font-medium" >{{merchantTransactionLables[selectedLanguage].price}}: ₹</div>
                                        <div class="digit font-medium" >{{item.endFinalPrice}}</div>
                                    </div>
                                    <div class="date font-normal">{{item.updatedAt | date}}</div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-card>     
    </div>
</div>
