<!--************************************* 
Created by: Priyanka Suryawanshi
Created Date: 7/04/20
Description: NewsLetter subscription popup
***************************************** -->
<div class="popup" *ngIf="newsletterLabels && selectedLanguage">
  <!-- <mat-icon class="cross-button" aria-hidden="false" (click)="closeDialog()">clear</mat-icon> -->

  <div class="newsletter-popup-header">
    <span class="newsletter-title font-card-heading" 
    (mouseenter)="speakText(newsletterLabels[selectedLanguage].subscribeNewsLetter)" (mouseleave)="stopSpeaking()">{{newsletterLabels[selectedLanguage].subscribeNewsLetter}}</span>
  </div>
  <div *ngIf="isNewsLetterSubscribe== false && isNewsletterRequest == false" class="popup-container">
    <span (mouseenter)="speakText(newsletterLabels[selectedLanguage].emailId)" (mouseleave)="stopSpeaking()">{{newsletterLabels[selectedLanguage].emailId}}</span>
    <mat-form-field appearance="outline" (mouseenter)="speakText('Go back')" (mouseleave)="stopSpeaking()">
      <input matInput [formControl]="email" name="name" [(ngModel)]="emailId" maxlength='50'>
      <mat-error *ngIf="emailId.invalid" (mouseenter)="speakText(getEmailErrorMessage())"  (mouseleave)="stopSpeaking()">{{getEmailErrorMessage()}}</mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="isNewsletterRequest== true && isNewsLetterSubscribe== false" class="popup-container">
    <span 
    (mouseenter)="speakText(newsletterLabels[selectedLanguage].pleaseVerifyYourEmail)"  (mouseleave)="stopSpeaking()">{{newsletterLabels[selectedLanguage].pleaseVerifyYourEmail}}</span>
    <span class="resend-link pointer" (click)="redirectToSubscriveApi(emailId)"
    (mouseenter)="speakText(newsletterLabels[selectedLanguage].resendLinkOnMail)"  (mouseleave)="stopSpeaking()">{{newsletterLabels[selectedLanguage].resendLinkOnMail}}</span>
  </div>
  <div *ngIf="isNewsletterRequest == false && isNewsLetterSubscribe == true" class="popup-container">
    <span 
    (mouseenter)="speakText(newsletterLabels[selectedLanguage].areYouSureYouWantToUnsubscribeNewsletters)"  (mouseleave)="stopSpeaking()">{{newsletterLabels[selectedLanguage].areYouSureYouWantToUnsubscribeNewsletters}}</span>
  </div>
  <div class="newsletter-popup-button-container">

    <mat-dialog-actions class="button-align">
      <div class="cancel-button-align"  (mouseenter)="speakText(newsletterLabels[selectedLanguage].cancel)"  (mouseleave)="stopSpeaking()">
        <button mat-raised-button class="cancel-button" (click)="closeDialog()">
          {{newsletterLabels[selectedLanguage].cancel}}
        </button>
      </div>

      <div class="ok-button-align">
        <button *ngIf="(isNewsletterRequest== false && isNewsLetterSubscribe== true)" mat-raised-button class="ok-button" 
        (mouseenter)="speakText(newsletterLabels[selectedLanguage].yes)"  (mouseleave)="stopSpeaking()"
        (click)="redirectToSubscriveApi(emailId)" [disabled]="!emailId">
        {{newsletterLabels[selectedLanguage].yes}}
        </button>
        <button *ngIf="isNewsLetterSubscribe== false && isNewsletterRequest == false" mat-raised-button class="ok-button" 
        (mouseenter)="speakText(newsletterLabels[selectedLanguage].ok)"  (mouseleave)="stopSpeaking()"
        (click)="redirectToSubscriveApi(emailId)" [disabled]="!emailId">
        {{newsletterLabels[selectedLanguage].ok}}
        </button>
        <button *ngIf="isNewsLetterSubscribe== false && isNewsletterRequest == true" mat-raised-button class="ok-button" 
        (mouseenter)="speakText(newsletterLabels[selectedLanguage].ok)"  (mouseleave)="stopSpeaking()"
        (click)="closeDialog()">
        {{newsletterLabels[selectedLanguage].ok}}
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</div>