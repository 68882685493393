

/**
 * image configuaration file
 *
 * image assigned to variables
 */
export const Images = {

	// loading
	loading: '../../../assets/images/loading/loading-icon.svg',
  money: {
    due: '../../../assets/images/loading/money-icon-due.png',
    paid: '../../../assets/images/loading/money-icon-paid.png',
    pending: '../../../assets/images/loading/money-icon-pending.png',
    processing: '../../../assets/images/loading/money-icon-processing.png',
  },
  	tiger:'../../../assets/images/zoo-booking/tiger.png',
	tree:'../../../assets/images/zoo-booking/tree.png',
	payment_success:'../../../assets/images/zoo-booking/payment_success.png',
	payment_success_icon:'../../../assets/images/zoo-booking/payment_success_icon.png',
	payment_fail:'../../../assets/images/zoo-booking/payment_failed.png',
	payment_fail_icon:'../../../assets/images/zoo-booking/payment_failed_icon.png',
	grass:'../../../assets/images/zoo-booking/grass.png',
  	ticket:'../../../assets/images/loading/Featured icon.png',
	phones:'../../../assets/images/icons/phones.png',
	compliant :'../../../assets/images/icons/Complaint.svg',
	office:'../../../assets/images/icons/Office.svg',
	department:'../../../assets/images/icons/Department.svg',
	website:'../../../assets/images/icons/Resolved expected.svg',
   call:'../../../assets/images/icons/call.svg',
   department1:'../../../assets/images/icons/Department.svg',
   remark:'../../../assets/images/icons/remark.svg',
   user:'../../../assets/images/icons/User.svg',
   history:'../../../assets/images/icons/History.svg',
   advice:'../../../assets/images/icons/advice.svg',
   date:'../../../assets/images/icons/Date.svg',
   description:'../../../assets/images/icons/Description.svg',
  	location:'../../../assets/images/icons/loaction.svg',
	customer:'../../../assets/images/icons/Customer.svg',
	Email:'../../../assets/images/icons/Email.png',
	designation:'../../../assets/images/icons/designation.png',
    name:'../../../assets/images/icons/name.png',
  	sgyNoDataFound:'../../../assets/images/icons/sgy_image_home_page.png',
	sgyNoActiveCardsFound:'../../../assets/images/icons/nac-tr.png',
	dbtNoDataFound:'../../../assets/images/DBT/DBTimg.png',
	dbtfilter:'../../../assets/images/DBT/Group 3215.png',
	dbticon:'../../../assets/images/DBT/dbticon.png',
   logodbt:'../../../assets/images/DBT/logodbt.png',
	// Common Images
	headerPcmcLogo: '../../../assets/images/header/pcmc-smart-sarathi.png',
	pcmcLogo: '../../../assets/images/header/smart-sarthi-logo.png',
	pcmcOfficialLogo: '../../../assets/images/footer/pcmc-official-logo.png',
	smartCityLogo: '../../../assets/images/footer/smart-city-india.png',
	digitalIndiaLogo: '../../../assets/images/footer/digital-india.png',
	smartCityPimpariChinchwadLogo: '../../../assets/images/footer/smart-city-pc-logo.png',
	defaultMerchantLogo: '../../../assets/images/header/Mdefault.jpg',
	defaultProfilePicture: '../../../assets/images/header/default-profile-picture.png',

	// Header Images
	notification: '../../../assets/images/header/notification.png',
	search: '../../../assets/images/header/search.png',
	browseLanguageImage: '../../../assets/images/header/browse-language.png',
	contactSarathi: '../../../assets/images/header/contact-sarathi.png',
	chromeIcon : '../../../assets/images/header/chrome.png',
	webinarIcon:'../../../assets/images/header/webinar.png',
	nextPrevIcon:'../../../assets/images/header/arrow-next.png',
	pmc_demo_Img:'../../../assets/images/header/PMC_demo.jpg',
	// pmc_logo:'../../../assets/images/header/pmc_logo.png',
	pmc_logo:'../../../assets/images/header/pmc-logo-png1.png',
	pmc_care_logo:'../../../assets/images/header/pmc-care-logo.jpg',
  no_image_found: '../../../assets/images/feeds/No_Image.png',

	// feeds images
	filterImg: '../../../assets/images/feeds/filter.png',
	sortByFilterImg: '../../../assets/images/feeds/sort-by-filter.png',
	shareImg: '../../../assets/images/feeds/share.png',
	likeImg: '../../../assets/images/feeds/like.png',
	facebookShareImg: '../../../assets/images/feeds/facebook.png',
	twitterShareImg: '../../../assets/images/feeds/twitter.png',
	linkedinShareImg: '../../../assets/images/feeds/linkedin.png',
	whatsappShareImg: '../../assets/images/feeds/whatsapp-share.png',
	coptToClipboardImg: '../../assets/images/feeds/copytoclipboaredicon.png',
	firstMedal: '../../assets/images/feeds/medal1.png',
	secondMedal: '../../assets/images/feeds/medal2.png',
	thirdMedal: '../../assets/images/feeds/medal3.png',
	uploadImg: '../../assets/images/feeds/upload.png',

	// Login Screen images
	loginWithFacebook: '../../../assets/images/login/facebook.png',
	loginWithGoogle: '../../../assets/images/login/google.png',
	loginWithMobile: '../../../assets/images/login/mobile.png',
	loginWithTwitter: '../../../assets/images/login/tweeter.png',
	backgroundWithStation: '../../../assets/images/login/background-station.png',
	backgroundWithoutLogo: '../../../assets/images/login/background-station2.png',
	background: '../../../assets/images/login/background.png',
	Withoutbackground: '../../../assets/images/login/background2.png',
	backgroundForMobile: '../../../assets/images/login/Frame 44.png',
	loginMobile: '../../../assets/images/login/Frame 45.png',
	finalLogin: '../../../assets/images/login/pmc cares logo final-01.png',
	mobileimg: '../../../assets/images/login/Frame 106.png',
	backgroundWithLogo:'../../../assets/images/login/istockphoto-1133938241-612x612-removebg-preview 1.png',
	backgroundWithLogo1:'../../../assets/images/login/image 18.png',
	facebook1:'../../../assets/images/login/facebook 1.png',
	instagram1:'../../../assets/images/login/instagram 1.png',
	twitter1:'../../../assets/images/login/twitter 1.png',
	backgroundSigninImg:'../../../assets/images/login/image 18.png',
	backgroundSigninImg1:'../../../assets/images/login/istockphoto-1133938241-612x612-removebg-preview 1 (3).png',
	birdrotate:'../../../assets/images/login/bird-rotate.png',
	gradientBackgroundImage:'../../../assets/images/login/Frame 6.png',
	OCR_Image:'../../../assets/images/login/OCR-Image.png',

	// Contact-us and footer images
	whatsapp: '../../../assets/images/contact-us/whatsapp.png',
	facebook: '../../../assets/images/contact-us/facebook.png',
	mail: '../../../assets/images/contact-us/mail.png',
	twitter: '../../../assets/images/contact-us/twitter.png',
	instagram: '../../../assets/images/contact-us/instagram.png',
	linkedIn: '../../../assets/images/contact-us/linkdin.png',
	whatsappBlack: '../../../assets/images/contact-us/whatsapp-black.png',
	mailBlack: '../../../assets/images/contact-us/email.png',
	phone:'../../../assets/images/contact-us/phone.png',
	

	// Footer images
	appStore: '../../../assets/images/footer/app-store.png',
	googlePlayStore: '../../../assets/images/footer/google-play.png',

	// near images
	backArrow: '../../../assets/images/near-me/back-arrow.svg',
	map: '../../../assets/images/near-me/map.jpg',
	propertyTaxImage: '../../../assets/images/near-me/property-tax.svg',
	rtiImage: '../../../assets/images/near-me/rti.svg',
	rtsImage: '../../../assets/images/near-me/rts.svg',
	directionImage: '../../../assets/images/near-me/shape.svg',
	telephoneImage: '../../../assets/images/near-me/telephone.svg',
	cfcImage: '../../../assets/images/near-me/cfc.png',
	bikeMarker : '../../../assets/images/near-me/bike-marker.png',
	bicycleMarker : '../../../assets/images/near-me/bicycle-marker.png',

	// detail card popup images
	detailCardImage: '../../../assets/images/near-me/detail-card-img.jpg',

	// error pages
	noResponse: '../../../assets/images/error-pages/No_response.png',
	connectionLost: '../../../assets/images/error-pages/Connection_lost.png',
	pageNotFound: '../../../assets/images/error-pages/Page_not_found.png',
	noDataFound: '../../../assets/images/error-pages/Data_not_found.png',
	comingSoon: '../../../assets/images/error-pages/Coming_soon.png',

	// weather images
	cloud: '../../../assets/images/weather/cloud.png',
	// My profile
	profileImage: '../../../assets/images/profile/profile-image.png',
	calenderImage: '../../../assets/images/profile/calendar-image.png',
	envelopeImage: '../../../assets/images/profile/envelope-image.png',
	genderImage: '../../../assets/images/profile/gender-image.png',
	fingerPrintImage: '../../../assets/images/profile/fingerprint-image.png',
	drivingLicenseImage: '../../../assets/images/profile/driving-license-image.png',
	cameraImage: '../../../assets/images/profile/camera-image.png',
	pencilImage: '../../../assets/images/profile/pencil-image.png',
	locationImage: '../../../assets/images/profile/location-image.png',
	updateMobileNoIcon: '../../../assets/images/profile/changeMobileNo.png',

	// profile-drop-down
	profile: '../../../assets/images/profile-drop-down/profile.png',
	cityNews: '../../../assets/images/profile-drop-down/city-news.png',
	aboutUs: '../../../assets/images/profile-drop-down/about-us.png',
	contactUs: '../../../assets/images/profile-drop-down/contact-us.png',
	favourite: '../../../assets/images/profile-drop-down/favourite.png',
	language: '../../../assets/images/profile-drop-down/language.png',
	forum: '../../../assets/images/profile-drop-down/forum.png',
	addDeals: '../../../assets/images/profile-drop-down/add-deals.png',
	addMerchant: '../../../assets/images/profile-drop-down/add-merchant.png',
	logout: '../../../assets/images/profile-drop-down/logout.png',
	login: '../../../assets/images/profile-drop-down/login.png',

	newsLetter: '../../../assets/images/profile-drop-down/news-letter.png',
	myloyalty: '../../../assets/images/profile-drop-down/my-loyalty.png',
	myDeals: '../../../assets/images/profile-drop-down/my-deals.png',
	myCart: '../../../assets/images/profile-drop-down/my-cart.png',
	addBlog: '../../../assets/images/profile-drop-down/add-blog.png',
	group: '../../../assets/images/profile-drop-down/group.jpeg',

	// qr code image
	qrCodePlayStore : '../../../assets/images/header/qr-code-playstore.png',
	qrCodeAppStore : '../../../assets/images/header/qr-code-appstore.png',

	// more-services
	myLoyalty: '../../../assets/images/more-services/my-loyalty.png',
	usedLoyalty: '../../../assets/images/more-services/used-loyalty.png',
	wonLoyalty: '../../../assets/images/more-services/won-loyalty.png',
	redeem: '../../../assets/images/more-services/redeem.png',
	like: '../../../assets/images/more-services/like.png',
	vote: '../../../assets/images/more-services/gestures.png',
	sendMessage: '../../../assets/images/more-services/telegram.png',
	noDataFoundForCart: '../../../assets/images/more-services/emptyCart.png',
	noDataFoundForOrderHistory: '../../../assets/images/more-services/noOrderHistory.png',
	sendMsg: '../../../assets/images/more-services/send.png',
	// down Arrow
	downArrow: '../../../assets/images/more-services/down-arrow.png',
	// thank you
	thanksForShopping: '../../../assets/images/more-services/thanks-for-shopping.png',
	// thanksForShoppingen: '../../../assets/images/more-services/Thank-for-shopping-en.jpg',
	// thanksForShoppingmar: '../../../assets/images/more-services/Thank-for-shopping-mar.jpg',
	thanksForShoppingen: '../../../assets/images/more-services/Thank-for-shopping1.jpeg',
	thanksForShoppingmar: '../../../assets/images/more-services/Thank-for-shopping1.jpeg',

	// forum image
	forumImg: '../../../assets/images/feeds/forum.png',
	//group image
	defaultImg: '../../../assets/images/more-services/default-group-image.png',
	downArrowForChat: '../../../assets/images/downarrow.png',
	InviteOnSarathiImg : '../../../assets/images/header/invite icon.png',
	couponImage:'../../../assets/images/profile-drop-down/coupon.png',

	property_dots:'../../../assets/images/property-tax/more.png',
	property_pmclogo:'../../../assets/images/property-tax/pmclogo.png',
	property_text:'../../../assets/images/property-tax/image 77.png',
	property_bill:'../../../assets/images/property-tax/Property-image.png',
	water_bill:'../../../assets/images/header/watertax.images.png',
	get_pdf:'../../../assets/images/property-tax/pdf.png',
	doc:'../../../assets/images/property-tax/word.png',
	
	//election-popup icons
	downloadImage:'../../../assets/images/icons/download_eepic.png',
	electionResult:'../../../assets/images/icons/election-results.png',
	electionMgmt:'../../../assets/images/icons/election-management.png',
	faq:'../../../assets/images/icons/faq.png',
	voterList:'../../assets/images/icons/voter-list.png',
	pollingBooth:'../../assets/images/icons/polling-booth.png',
	candidate:'../../assets/images/icons/candidate.png',
	codeOfConduct:'../../assets/images/icons/code-of-conduct.png',
	androidIcon:'../../assets/images/icons/android-icon.png',
	appleIcon:'../../assets/images/icons/apple-icon.png',
	carousalImg1:'../../assets/images/election-page/Election_banner_-02.jpg',

	//deals 
	media:'../../../assets/images/deals/Media.jpg',

	// pet images
	petDataNotFound:'../../../assets/images/petLicense/pet-data-not-found.png'
};
