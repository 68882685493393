import * as fromConstants from '../action/constants.action';
import { AppConstants } from 'src/app/shared/config/app-constants';
import { state } from '@angular/animations';
import { ContentObserver } from '@angular/cdk/observers';

export interface ConstantsState {
	getBannerImages: {};
	getprofileScreenLabels: any;
	nearMeMap: any;
	nearMeScreen: any;
	bgColor: {};
	themeBackgroundColor: {};
	background: {};
	header: {};
	contactUs: {};
	aboutUsLabels: {};
	drawer: {};
	getAreaLabels: {};
	getAreaAndZones: {};
	getPasswordScreenLabels: {};
	getRegisterScreenLabels: {};
	getSplashScreenLabels: {};
	getServicesListLabel: {};
	getOtpScreenLabels: {};
	getMobileScreenLabels: {};
	getLoginScreenLabels: {};
  getOnboardingLabels:{};
	getVerifyOtpScreenLabels: {};
	getBlockUserMessage: {};
	getFooterLabels: {};
  getIntroducionImagesLabels: {};
  getMobileIntroducionImagesLabels:{};
  getPropertyTaxLabels:{};
  getWaterTaxLabels:{};
  landingScreen: {};
  newsletterLabels:{};
	getServiceHeadings: {};
	getCityInfoLabels: {};
	feedsFilterLabel: {};
  onboardingLabel:{};
  deathLabels:{},
  birthLabels:{},
  feeds: {};
  groups:{};
	apiCallingList: {};
  appLinks: {};
  feature: {};
	allConstantLabels: {};
	getErrorMessages: {};
	getSuccessMessages: {};
	isLabelLoading: boolean;
	labelEnableDetails: [];
	isLabelEnableLoading: boolean;
	isLabelEnableLoaded: boolean;
	isLabelLoaded: boolean;
	errMessage: null;
	hotFeedbackResult: [];
	isHotFeedbackLoaded: boolean;
  isHotFeedbackLoading: boolean;
  hotFeedbackStatus: number;
  hotFeedbackMessage: string;
	getPreferncesList: {};
	getChatbotKeyResult: {};
	isChatbotKeyLoading: boolean;
	isChatbotKeyLoaded: boolean;
	isWeatherLoading: boolean;
	isWeatherLoaded: boolean;
	getScreenColor: {};
	getStaticImages: {};
	getStaticUrlsListForSubHeader: {};
	getStaticNamesListForSubHeader: {};
	getWeatherStatus: {};
	getWeatherStatusCode: number;
	getWeatherStatusErrorMessage: string;
	getApplicationUrls: {};
	getBlogLabels: {};
	getForumLabels: {};
	getMerchantAndBrandLabels: {};
	getSortByListing: {};
  getMoreServicesLabels: {};
  getMyCartLabels: {};
  getMyDealsLabels: {};
  getMyLoyaltyLabels: {};
  getAddDealsLabels: {};
  getSortByLabels: {};
  getSortByGrievanceLabels: {};
  getGrievanceLabels:{};
  getRemoveExistDeallabels:{};
  getWebinarLabels:{};
  getComplanitDashboardLabels:{};
  getComplanitListLabels:{};
  dashboardLinks:[];
  getComplanitDetailsLabels:{};
  getActionDashboardLabels:{};
  getInsructionPopupLabels:{};
  getSGYLabels:{};
  getZooBookingLabels:{};
  getDBTLabels:{};
  getAntiRabiesVaccinatedLabels:{};
  getCatAgeRangesLabels:{};
  getCatBreedsLabels:{};
  getCatColorsLabels:{};
  getCatTenureLabels:{};
  getDogAgeRangesLabels:{};
  getDogBreedsLabels:{};
  getDogColorsLabels:{};
  getDogTenureLabels:{};
  getGenderOptionsLabels:{};
  getYesNoOptionsLabels:{};
  getHousingOwnershipLabels:{};
  getPetDocUploadlabels:{};
  getDogApplicationLabels:{};
  getCatApplicationLabels:{};
  getPetHomePageLabels:{};

}

export const initialState: ConstantsState = {
	getBannerImages: {},
	bgColor: {},
	themeBackgroundColor: {},
	background: {},
	header: {},
	contactUs: {},
	aboutUsLabels: {},
	drawer: {},
	getAreaLabels: {},
	getAreaAndZones: {},
	getPasswordScreenLabels: {},
	getRegisterScreenLabels: {},
	getOtpScreenLabels: {},
	getMobileScreenLabels: {},
	getServicesListLabel: {},
	getLoginScreenLabels: {},
  getOnboardingLabels:{},
	getVerifyOtpScreenLabels: {},
	getBlockUserMessage: {},
	feedsFilterLabel: {},
  onboardingLabel: {},
  deathLabels:{},
  birthLabels:{},
  getPropertyTaxLabels:{},
  getWaterTaxLabels:{},
	landingScreen: {},
	getSplashScreenLabels: {},
	feeds: {},
	getFooterLabels: {},
  getIntroducionImagesLabels: {},
  getMobileIntroducionImagesLabels:{},
	apiCallingList: {},
  appLinks: {},
  feature: {},
	getErrorMessages: {},
	getSuccessMessages: {},
	allConstantLabels: {},
	nearMeMap: {},
  nearMeScreen: {},
  newsletterLabels:{},
	getServiceHeadings: {},
	getCityInfoLabels: [],
	isLabelLoading: false,
	labelEnableDetails: [],
	isLabelEnableLoading: false,
	isLabelEnableLoaded: false,
	isLabelLoaded: false,
  errMessage: null,
  groups:{},
	getprofileScreenLabels: {},
	hotFeedbackResult: [],
	isHotFeedbackLoaded: false,
  isHotFeedbackLoading: false,
  hotFeedbackStatus: 0,
  hotFeedbackMessage: null,
	getPreferncesList: {},
	getChatbotKeyResult: {},
	isChatbotKeyLoading: false,
	isChatbotKeyLoaded: false,
	isWeatherLoading: false,
	isWeatherLoaded: false,
	getScreenColor: {},
	getStaticImages: {},
	getStaticNamesListForSubHeader: {},
	getStaticUrlsListForSubHeader: {},
	getWeatherStatus: {},
	getWeatherStatusCode: 0,
	getWeatherStatusErrorMessage: null,
	getApplicationUrls: {},
	getBlogLabels: {},
	getForumLabels: {},
	getMerchantAndBrandLabels: {},
	getSortByListing: {},
  getMoreServicesLabels: {},
  getMyCartLabels: {},
  getMyDealsLabels: {},
  getMyLoyaltyLabels: {},
  getAddDealsLabels: {},
  getSortByLabels: {},
  getSortByGrievanceLabels: {},
  getGrievanceLabels:{},
  getRemoveExistDeallabels:{},
  getWebinarLabels:{},
  getComplanitDashboardLabels:{},
  getComplanitListLabels:{},
  dashboardLinks:[],
  getComplanitDetailsLabels:{},
  getActionDashboardLabels:{},
  getInsructionPopupLabels:{},
  getSGYLabels:{},
  getZooBookingLabels:{},
  getDBTLabels:{},
  getAntiRabiesVaccinatedLabels:{},
  getCatAgeRangesLabels:{},
  getCatBreedsLabels:{},
  getCatColorsLabels:{},
  getCatTenureLabels:{},
  getDogAgeRangesLabels:{},
  getDogBreedsLabels:{},
  getDogColorsLabels:{},
  getDogTenureLabels:{},
  getGenderOptionsLabels:{},
  getYesNoOptionsLabels:{},
  getHousingOwnershipLabels:{},
  getPetDocUploadlabels:{},
  getDogApplicationLabels:{},
  getCatApplicationLabels:{},
  getPetHomePageLabels:{},

};

export function reducer(
  state = initialState,
  action: fromConstants.constantsAction
): ConstantsState {
  switch (action.type) {
    case fromConstants.SET_HEADER_BACKGROUND_COLOR: {
      let screenColor = state.getScreenColor;
      let bgColor = state.bgColor;
      let themeBackgroundColor = state.themeBackgroundColor;
      let background = {};
      bgColor = action.payload.backgroundColor;
      switch (bgColor) {
        case AppConstants.FEEDS_BACKGROUND:
          bgColor = screenColor['feeds']?.primaryColor;
          themeBackgroundColor = screenColor['feeds']?.secondaryColor;
          break;

        case AppConstants.NEAR_ME_BACKGROUND:
          bgColor = screenColor['nearMe']?.primaryColor;
          themeBackgroundColor = screenColor['nearMe']?.secondaryColor;

          break;

        case AppConstants.SERVICES_BACKGROUND:
          bgColor = screenColor['services']?.primaryColor;
          themeBackgroundColor = screenColor['services']?.secondaryColor;

          break;

        case AppConstants.CITY_INFO_BACKGROUND:
          bgColor = screenColor['eFAQ']?.primaryColor;
          themeBackgroundColor = screenColor['eFAQ']?.secondaryColor;

          break;
      }
      background['bgColor'] = bgColor;
      background['themeBackgroundColor'] = themeBackgroundColor;
      background['isImage'] = true;
      return {
        ...state,
        background
      };
    }
    case fromConstants.GET_API_LIST: {

      return {
        ...state,
        isLabelLoading: true,
        isLabelLoaded: false
      };
    }
    case fromConstants.GET_API_LIST_SUCCESS: {
      
      
      let statusCode = action.payload.code;
	  let apiCallingList = state.apiCallingList;
    let appLinks = state.appLinks;
    let feature = state.feature
      if (statusCode == 200) {
		  apiCallingList = action.payload.result.api_details;
      appLinks = action.payload.result.app_versions;
      feature = action.payload.result.feature
      
      }

      return {
        ...state,
		    apiCallingList,
        appLinks,
        feature,
        isLabelLoading: true,
        isLabelLoaded: false
      };
    }
    case fromConstants.GET_API_LIST_FAIL: {
      let errResponse = action.payload;
      let errMessage;
      switch (errResponse.status) {
        case 0:
          errMessage = errResponse.statusText;
          break;

        case 401:
          errMessage = errResponse.statusText;
          break;

        default:
          errMessage = action.payload.error.message;
          break;

      }
      return {
        ...state,
        errMessage,
        isLabelLoading: false
      };
    }
    case fromConstants.GET_CONSTANTS_LABEL_SUCCESS: {
      const statusCode = action.payload.code;
      let isLabelLoaded = state.isLabelLoaded;
      let header = state.header;
      let nearMeMap = state.nearMeScreen;
      let contactUs = state.contactUs;
      let aboutUsLabels = state.aboutUsLabels;
      let drawer = state.drawer;
      let groups = state.groups;
      let getErrorMessages = state.getErrorMessages;
      let getSuccessMessages = state.getSuccessMessages;
      let getAreaLabels = state.getAreaLabels;
      let getAreaAndZones = state.getAreaAndZones;
      let feedsFilterLabel = state.feedsFilterLabel;
      let onboardingLabel = state.onboardingLabel;
      let deathLabels = state.deathLabels;
      let birthLabels = state.birthLabels;
      let allConstantLabels = state.allConstantLabels;
      let getPasswordScreenLabels = state.getPasswordScreenLabels;
      let getRegisterScreenLabels = state.getRegisterScreenLabels;
      let getOtpScreenLabels = state.getOtpScreenLabels;
      let getMobileScreenLabels = state.getMobileScreenLabels;
      let getLoginScreenLabels = state.getLoginScreenLabels;
      let getOnboardingLabels = state.getOnboardingLabels;
      let getServicesListLabel = state.getServicesListLabel;
      let landingScreen = state.landingScreen;
      let getPropertyTaxLabels = state.getPropertyTaxLabels;
      let getWaterTaxLabels = state.getWaterTaxLabels; 
      let isLabelLoading = state.isLabelLoading;
      let getServiceHeadings = state.getServiceHeadings;
      let getCityInfoLabels = state.getCityInfoLabels;
      let feeds = state.feeds;
      let getprofileScreenLabels = state.getprofileScreenLabels;
      let getSplashScreenLabels = state.getSplashScreenLabels;
      let getFooterLabels = state.getFooterLabels;
      let getIntroducionImagesLabels = state.getIntroducionImagesLabels;
      let getMobileIntroducionImagesLabels = state.getMobileIntroducionImagesLabels;
      let getPreferncesList = state.getPreferncesList;
      let getVerifyOtpScreenLabels = state.getVerifyOtpScreenLabels;
      let getScreenColor = state.getScreenColor;
      let getStaticImages = state.getStaticImages;
      let getStaticUrlsListForSubHeader = state.getStaticUrlsListForSubHeader;
      let getBannerImages = state.getBannerImages;
      let getBlockUserMessage = state.getBlockUserMessage;
      let getApplicationUrls = state.getApplicationUrls;
      let getMerchantAndBrandLabels = state.getMerchantAndBrandLabels;
			let getForumLabels = state.getForumLabels;
			let getBlogLabels = state.getBlogLabels;
			let getSortByListing = state.getSortByListing;
      let getMoreServicesLabels = state.getMoreServicesLabels;
      let getMyCartLabels = state.getMyCartLabels;
      let getMyDealsLabels = state.getMyDealsLabels;
      let getMyLoyaltyLabels = state.getMyLoyaltyLabels;
      let getAddDealsLabels = state.getAddDealsLabels;
      let getSortByLabels = state.getSortByLabels;
      let getSortByGrievanceLabels= state.getSortByGrievanceLabels;
      let getGrievanceLabels= state.getGrievanceLabels;
      let getRemoveExistDeallabels = state.getRemoveExistDeallabels;
      let newsletterLabels = state.newsletterLabels;
      let getWebinarLabels = state.getWebinarLabels;
      let getComplanitDashboardLabels = state.getComplanitDashboardLabels;
      let getComplanitListLabels = state.getComplanitListLabels;
      let dashboardLinks = state.dashboardLinks;
      let getComplanitDetailsLabels = state.getComplanitDetailsLabels;
      let getActionDashboardLabels = state.getActionDashboardLabels;
      let getInsructionPopupLabels = state.getInsructionPopupLabels;
      let getSGYLabels = state.getSGYLabels;
      let getZooBookingLabels = state.getZooBookingLabels;
      let getDBTLabels = state.getDBTLabels;
      let getAntiRabiesVaccinatedLabels = state.getAntiRabiesVaccinatedLabels;
      let getCatAgeRangesLabels = state.getCatAgeRangesLabels; 
      let getCatBreedsLabels = state.getCatBreedsLabels; 
      let getCatColorsLabels = state.getCatColorsLabels; 
      let getCatTenureLabels = state.getCatTenureLabels; 
      let getDogAgeRangesLabels = state.getDogAgeRangesLabels; 
      let getDogBreedsLabels = state.getDogBreedsLabels; 
      let getDogColorsLabels = state.getDogColorsLabels; 
      let getDogTenureLabels = state.getDogTenureLabels; 
      let getGenderOptionsLabels = state.getGenderOptionsLabels; 
      let getYesNoOptionsLabels = state.getYesNoOptionsLabels; 
      let getHousingOwnershipLabels = state.getHousingOwnershipLabels;
      let getPetDocUploadlabels = state.getPetDocUploadlabels;
      let getDogApplicationLabels = state.getDogApplicationLabels;
      let getCatApplicationLabels = state.getCatApplicationLabels;
      let getPetHomePageLabels = state.getPetHomePageLabels;


      if (statusCode == 200) {
      
        isLabelLoaded = true;
        isLabelLoading = false;
        allConstantLabels = action.payload.result;
        // console.log("All Labels",allConstantLabels)
        header = action.payload.result.citizen.header;
        feedsFilterLabel = action.payload.result.citizen.feedsFilterForWeb;
        onboardingLabel = action.payload.result.citizen.onboarding;
        deathLabels = action.payload.result.citizen.deathcertificate;
        birthLabels = action.payload.result.citizen.birthcertificate;
        getServicesListLabel = action.payload.result.citizen.ePCMCServicesScreen;
        getSplashScreenLabels = action.payload.result.citizen.splashScreen;
        getScreenColor = action.payload.result.citizen.screenColor;
        contactUs = action.payload.result.citizen.contactUs;
        aboutUsLabels = action.payload.result.citizen.aboutUs;
        nearMeMap = action.payload.result.citizen.nearMeScreen;
        feeds = action.payload.result.citizen.feeds;
        drawer = action.payload.result.citizen.drawer;
        getErrorMessages = action.payload.result.errorMessges;
        getSuccessMessages = action.payload.result.successMessages;
        getAreaLabels = action.payload.result.citizen.area;
        getAreaAndZones = action.payload.result.citizen.areasAndZones;
        getPasswordScreenLabels = action.payload.result.citizen.passwordScreen;
        getRegisterScreenLabels = action.payload.result.citizen.registrationScreen;
        getOtpScreenLabels = action.payload.result.citizen.getOTPScreen;
        getMobileScreenLabels = action.payload.result.citizen.getMobileNumberScreen;
        getLoginScreenLabels = action.payload.result.citizen.loginScreen;
        getOnboardingLabels = action.payload.result.citizen.onboarding;
        landingScreen = action.payload.result.citizen.landingScreen;
        getPropertyTaxLabels = action.payload.result.citizen.propertyTaxScreen; 
        getWaterTaxLabels = action.payload.result.citizen.waterTaxScreen; 
        getServiceHeadings = action.payload.result.citizen['servicesHeading'];
        getCityInfoLabels = action.payload.result.citizen.cityInfo;
        newsletterLabels = action.payload.result.citizen.newsletter;
        getprofileScreenLabels = action.payload.result.citizen.profilePage;
        getFooterLabels = action.payload.result.citizen.footer;
        groups = action.payload.result.citizen.groups;
        getIntroducionImagesLabels = action.payload.result.citizen.introductionImages;
        getMobileIntroducionImagesLabels = action.payload.result.citizen.introductionImagesForMobile;
        getPreferncesList = action.payload.result.citizen.preference;
        getVerifyOtpScreenLabels = action.payload.result.citizen.verifyOtpScreen;
        getStaticImages = action.payload.result.citizen.staticImages;
        getStaticUrlsListForSubHeader = action.payload.result.citizen.staticUrlsListForSubHeader
        getBannerImages = action.payload.result.citizen.bannerImages;
        getBlockUserMessage = action.payload.result.citizen.blockedusermessage;
        getApplicationUrls = action.payload.result.citizen.staticApplicationUrls;
        getMerchantAndBrandLabels = action.payload.result.citizen.merchantAndBrandLabels;
				getForumLabels = action.payload.result.citizen.forum;
				getBlogLabels = action.payload.result.citizen.blog;
				getSortByListing = action.payload.result.citizen.sortBy;
        getMoreServicesLabels = action.payload.result.citizen.moreServices;
        getMyCartLabels = action.payload.result.citizen.mycart;
        getMyDealsLabels = action.payload.result.citizen.myDeals;
        getMyLoyaltyLabels = action.payload.result.citizen.myloyalty;
        getAddDealsLabels = action.payload.result.citizen.addDeals;
        getSortByLabels = action.payload.result.citizen.sortBy;
        getSortByGrievanceLabels=  action.payload.result.citizen.sortGrievance;
        getGrievanceLabels=  action.payload.result.citizen.grievance;
        getRemoveExistDeallabels = action.payload.result.citizen.removeBeforeDealPopupScreen
        getWebinarLabels =  action.payload.result.citizen.webinar;
        getComplanitDashboardLabels = action.payload.result.officerConnect.complaintDashboard
        getComplanitListLabels = action.payload.result.officerConnect.complaintList
        dashboardLinks = action.payload.result.officerConnect.serviceData
        getComplanitDetailsLabels = action.payload.result.officerConnect.complaintDetails
        getActionDashboardLabels = action.payload.result.officerConnect.actionDashboard
        getInsructionPopupLabels = action.payload.result.citizen.instructionPopup
        getSGYLabels = action.payload.result.citizen.sgy
        getZooBookingLabels = action.payload.result.citizen.zooBooking
        getDBTLabels = action.payload.result.citizen.dbt

        // pet License Labels
        getAntiRabiesVaccinatedLabels = action.payload.result.petLicenses.antiRabiesVaccinated;
        getCatAgeRangesLabels = action.payload.result.petLicenses.catAgeRanges; 
        getCatBreedsLabels = action.payload.result.petLicenses.catBreeds; 
        getCatColorsLabels = action.payload.result.petLicenses.catColors; 
        getCatTenureLabels = action.payload.result.petLicenses.catTenure; 
        getDogAgeRangesLabels = action.payload.result.petLicenses.dogAgeRanges; 
        getDogBreedsLabels = action.payload.result.petLicenses.dogBreeds; 
        getDogColorsLabels = action.payload.result.petLicenses.dogColors; 
        getDogTenureLabels = action.payload.result.petLicenses.dogTenure; 
        getGenderOptionsLabels = action.payload.result.petLicenses.genderOptions; 
        getYesNoOptionsLabels = action.payload.result.petLicenses.yesNoOptions; 
        getHousingOwnershipLabels = action.payload.result.petLicenses.housingOwnership;
        getPetDocUploadlabels = action.payload.result.petLicenses.docUploadScreen;
        getDogApplicationLabels = action.payload.result.petLicenses.dogApplicationScreen;
        getCatApplicationLabels = action.payload.result.petLicenses.catApplicationScreen;
        getPetHomePageLabels = action.payload.result.petLicenses.petHomeScreen;

      }
      return {
        ...state,
		
		header,
        drawer,
        groups,
        getAreaLabels,
        getAreaAndZones,
        getErrorMessages,
        getSuccessMessages,
        getPasswordScreenLabels,
        getRegisterScreenLabels,
        getOtpScreenLabels,
        getMobileScreenLabels,
        getLoginScreenLabels,
        getOnboardingLabels,
        landingScreen,
        getPropertyTaxLabels,
        getWaterTaxLabels,
        feeds,
        isLabelLoaded,
        getServiceHeadings,
        getCityInfoLabels,
        allConstantLabels,
        contactUs,
        aboutUsLabels,
        isLabelLoading,
        nearMeMap,
        getprofileScreenLabels,
        feedsFilterLabel,
        onboardingLabel,
        deathLabels,
        birthLabels,
        getServicesListLabel,
        getSplashScreenLabels,
        getFooterLabels,
        getIntroducionImagesLabels,
        getMobileIntroducionImagesLabels,
        getPreferncesList,
        getVerifyOtpScreenLabels,
        getScreenColor,
        getStaticImages,
        getStaticUrlsListForSubHeader,
        getBannerImages,
        getBlockUserMessage,
        getApplicationUrls,
        getMerchantAndBrandLabels,
				getBlogLabels,
				getForumLabels,
				getSortByListing,
        getMoreServicesLabels,
        getMyCartLabels,
        getMyDealsLabels,
        getMyLoyaltyLabels,
        getAddDealsLabels,
        getSortByLabels,
        getSortByGrievanceLabels,
        getGrievanceLabels,
        getRemoveExistDeallabels,
        newsletterLabels,
        getWebinarLabels,
        getComplanitDashboardLabels,
        getComplanitListLabels,
        dashboardLinks,
        getComplanitDetailsLabels,
        getActionDashboardLabels,
        getInsructionPopupLabels,
        getSGYLabels,
        getDBTLabels,
        getZooBookingLabels,
        getAntiRabiesVaccinatedLabels ,
        getCatAgeRangesLabels , 
        getCatBreedsLabels , 
        getCatColorsLabels , 
        getCatTenureLabels , 
        getDogAgeRangesLabels , 
        getDogBreedsLabels , 
        getDogColorsLabels , 
        getDogTenureLabels , 
        getGenderOptionsLabels , 
        getYesNoOptionsLabels ,
        getHousingOwnershipLabels,
        getPetDocUploadlabels,
        getDogApplicationLabels,
        getCatApplicationLabels,
        getPetHomePageLabels,
      };
    }

    case fromConstants.GET_LABEL_ENABLE_DETAILS: {

      return {
        ...state,
        isLabelEnableLoading: true,
        isLabelEnableLoaded: false,
      };
    }
    case fromConstants.GET_LABEL_ENABLE_DETAILS_SUCCESS: {
      const statusCode = action.payload.code;
      let labelEnableDetails = state.labelEnableDetails;
      let isLabelEnableLoading = state.isLabelEnableLoading;
      let isLabelEnableLoaded = state.isLabelEnableLoaded;
      if (statusCode == 200) {
        isLabelEnableLoading = false;
        isLabelEnableLoaded = true;
        labelEnableDetails = action.payload.result.nearMeEnable;


      }
      return {
        ...state,
        labelEnableDetails,
        isLabelEnableLoading,
        isLabelEnableLoaded
      };
    }
    case fromConstants.GET_LABEL_ENABLE_DETAILS_FAIL: {
      let errResponse = action.payload;
      let errMessage;
      switch (errResponse.status) {
        case 0:
          errMessage = errResponse.statusText;
          break;

        case 401:
          errMessage = errResponse.statusText;
          break;

        default:
          errMessage = action.payload.error.message;
          break;
      }
      return {
        ...state,
        errMessage,
        isLabelEnableLoading: false
      };
    }

    case fromConstants.GET_HOT_FEEDBACK: {
      return {
        ...state,
        isHotFeedbackLoaded: false,
        isHotFeedbackLoading: true
      };
    }

    case fromConstants.GET_HOT_FEEDBACK_SUCCESS: {
      let statusCode = action.payload.code;
      let hotFeedbackResult = state.hotFeedbackResult;
      let isHotFeedbackLoaded = state.isHotFeedbackLoaded;
      let isHotFeedbackLoading = state.isHotFeedbackLoading;
      let hotFeedbackStatus = statusCode;
      let hotFeedbackMessage = state.hotFeedbackMessage

      switch (statusCode) {
        case 200:
          hotFeedbackResult = action.payload.result;
          isHotFeedbackLoaded = true;
          hotFeedbackMessage = action.payload.message;
          isHotFeedbackLoading = false;
          break;

        case 500:
          isHotFeedbackLoaded = true;
          isHotFeedbackLoading = false;
          break;
      }

      return {
        ...state,
        hotFeedbackResult,
        hotFeedbackMessage,
        hotFeedbackStatus,
        isHotFeedbackLoaded,
        isHotFeedbackLoading
      };
    }

    case fromConstants.GET_HOT_FEEDBACK_FAIL: {
      let errResponse = action.payload;
      let errMessage;
      switch (errResponse.status) {
        case 0:
          errMessage = errResponse.statusText;
          break;

        case 401:
          errMessage = errResponse.statusText;
          break;

        default:
          errMessage = action.payload.error.message;
          break;
      }
      return {
        ...state,
        errMessage,
        isHotFeedbackLoading: false
      };
    }

    case fromConstants.GET_CHATBOT_KEY: {
      return {
        ...state,
        isChatbotKeyLoaded: false,
        isChatbotKeyLoading: true
      };
    }

    case fromConstants.GET_CHATBOT_KEY_SUCCESS: {
      let statusCode = action.payload.statusCode;
      let getChatbotKeyResult = action.payload.result;
      let isChatbotKeyLoaded = true;
      let isChatbotKeyLoading = false;
      return {
        ...state,
        getChatbotKeyResult,
        isChatbotKeyLoaded,
        isChatbotKeyLoading
      };
    }

    case fromConstants.GET_CHATBOT_KEY_FAIL: {
      let errResponse = action.payload;
      let errMessage;
      switch (errResponse.status) {
        case 0:
          errMessage = errResponse.statusText;
          break;

        case 401:
          errMessage = errResponse.statusText;
          break;

        default:
          errMessage = action.payload.error.message;
          break;
      }
      return {
        ...state,
        errMessage,
        isChatbotKeyLoading: false
      };
    }

    case fromConstants.GET_WEATHER_STATUS: {
      return {
        ...state,
        getWeatherStatusCode: 0,
        getWeatherStatus: {},
        isWeatherLoading: true,
        isWeatherLoaded: false,
        getWeatherStatusErrorMessage: null
      };
    }

    case fromConstants.GET_WEATHER_STATUS_SUCCESS: {
      let statusCode = action.payload.code;
      let getWeatherStatus = state.getWeatherStatus;
      let getWeatherStatusCode = statusCode;
      let getWeatherStatusErrorMessage = state.getWeatherStatusErrorMessage;
      switch (statusCode) {
        case 200:
          getWeatherStatus = action.payload.result;
          getWeatherStatusCode = 200;
          getWeatherStatusErrorMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        getWeatherStatus,
        isWeatherLoading: false,
        isWeatherLoaded: true,
        getWeatherStatusCode,
        getWeatherStatusErrorMessage
      };
    }

    case fromConstants.GET_WEATHER_STATUS_FAIL: {
      let errResponse = action.payload;
      let errMessage;
      switch (errResponse.status) {
        case 0:
          errMessage = errResponse.statusText;
          break;

        case 401:
          errMessage = errResponse.statusText;
          break;

        default:
          errMessage = action.payload.error.message;
          break;
      }
      return {
        ...state,
        errMessage,
        isWeatherLoading: false,
        isWeatherLoaded: false,
      };

    }
    case fromConstants.GET_WEATHER_STATUS: {
      return {
        ...state,
        getWeatherStatusCode: 0,
        getWeatherStatus: {},
        isWeatherLoading: true,
        isWeatherLoaded: false,
        getWeatherStatusErrorMessage: null
      };
    }

    case fromConstants.GET_WEATHER_STATUS_SUCCESS: {
      let statusCode = action.payload.code;
      let getWeatherStatus = state.getWeatherStatus;
      let getWeatherStatusCode = statusCode;
      let getWeatherStatusErrorMessage = state.getWeatherStatusErrorMessage;
      switch (statusCode) {
        case 200:
          getWeatherStatus = action.payload.result;
          getWeatherStatusCode = 200;
          getWeatherStatusErrorMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        getWeatherStatus,
        isWeatherLoading: false,
        isWeatherLoaded: true,
        getWeatherStatusCode,
        getWeatherStatusErrorMessage
      };
    }

    case fromConstants.GET_WEATHER_STATUS_FAIL: {
      let errResponse = action.payload;
      let errMessage;
      switch (errResponse.status) {
        case 0:
          errMessage = errResponse.statusText;
          break;

        case 401:
          errMessage = errResponse.statusText;
          break;

        default:
          errMessage = action.payload.error.message;
          break;
      }
      return {
        ...state,
        errMessage,
        isWeatherLoading: false,
        isWeatherLoaded: false,
      };

    }
    case fromConstants.RESET_CHATBOT_ID: {
      return {
        ...state,
        isChatbotKeyLoaded: false,
        isChatbotKeyLoading: false,
        getChatbotKeyResult: null
      }
    }
  }
  return state;
}

export const getBannerImages = (state: ConstantsState) => state.getBannerImages;
export const getBackgroundColor = (state: ConstantsState) => state.background;
export const getHeaderLabels = (state: ConstantsState) => state.header;
export const getDrawerLabels = (state: ConstantsState) => state.drawer;
export const getErrorMessages = (state: ConstantsState) => state.getErrorMessages;
export const getSuccessMessages = (state: ConstantsState) => state.getSuccessMessages;
export const getContactUsLabels = (state: ConstantsState) => state.contactUs;
export const getAboutUsLabels = (state: ConstantsState) => state.aboutUsLabels;
export const getApiCallingList = (state: ConstantsState) => state.apiCallingList;
export const getAppLinks = (state: ConstantsState) => state.appLinks;
export const getfeature = (state: ConstantsState) => state.feature;


export const getIsLabelLoading = (state: ConstantsState) => state.isLabelLoading;
export const getIsLabelLoaded = (state: ConstantsState) => state.isLabelLoaded;

export const getServiceHeadings = (state: ConstantsState) => state.getServiceHeadings;
export const getCityInfoLabels = (state: ConstantsState) => state.getCityInfoLabels;
export const getNearMeMapLabels = (state: ConstantsState) => state.nearMeMap;
export const getAreaLabels = (state: ConstantsState) => state.getAreaLabels;
export const getAreaAndZones = (state: ConstantsState) => state.getAreaAndZones;
export const getPasswordScreenLabels = (state: ConstantsState) => state.getPasswordScreenLabels;
export const getRegisterScreenLabels = (state: ConstantsState) => state.getRegisterScreenLabels;
export const getOtpScreenLabels = (state: ConstantsState) => state.getOtpScreenLabels;
export const getMobileScreenLabels = (state: ConstantsState) => state.getMobileScreenLabels;
export const getLoginScreenLabels = (state: ConstantsState) => state.getLoginScreenLabels;
export const getOnboardingLabels = (state:ConstantsState) => state.getOnboardingLabels;
export const getLandingScreenLabels = (state: ConstantsState) => state.landingScreen;
export const getPropertyTaxLabels = (state:ConstantsState) => state.getPropertyTaxLabels;
export const getWaterTaxLabels = (state:ConstantsState) => state.getWaterTaxLabels;
export const getFeedsLabels = (state: ConstantsState) => state.feeds;
export const getfeedsFilterLabel = (state: ConstantsState) => state.feedsFilterLabel;
export const onboardingLabel = (state:ConstantsState) => state.onboardingLabel;
export const getDeathLabels = (state:ConstantsState) => state.deathLabels;
export const getbirthLabels = (state:ConstantsState) => state.birthLabels
export const getLabelEnableDetails = (state: ConstantsState) => state.labelEnableDetails;
export const getServicesListLabel = (state: ConstantsState) => state.getServicesListLabel;
export const getNearMeEnableLableLoading = (state: ConstantsState) => state.isLabelEnableLoading;
export const getprofileScreenLabels = (state: ConstantsState) => state.getprofileScreenLabels;
export const getErrorMessage = (state: ConstantsState) => state.errMessage;
export const getSplashScreenLabel = (state: ConstantsState) => state.getSplashScreenLabels;
export const getFooterLabels = (state: ConstantsState) => state.getFooterLabels;
export const getIntroducionImagesLabels = (state: ConstantsState) => state.getIntroducionImagesLabels;
export const getMobileIntroducionImagesLabels = (state: ConstantsState) => state.getMobileIntroducionImagesLabels;
export const getVerifyOtpScreenLabels = (state: ConstantsState) => state.getVerifyOtpScreenLabels;

export const getHotFeedbackResult = (state: ConstantsState) => state.hotFeedbackResult;
export const getHotFeedbackLoading = (state: ConstantsState) => state.isHotFeedbackLoading;
export const getHotFeedbackLoaded = (state: ConstantsState) => state.isHotFeedbackLoaded;
export const hotFeedbackMessage = (state: ConstantsState) => state.hotFeedbackMessage;
export const hotFeedbackStatus = (state: ConstantsState) => state.hotFeedbackStatus;

export const getPreferncesList = (state: ConstantsState) => state.getPreferncesList;

export const getChatbotKeyResult = (state: ConstantsState) => state.getChatbotKeyResult;
export const getChatbotKeyLoading = (state: ConstantsState) => state.isChatbotKeyLoading;
export const getChatbotKeyLoaded = (state: ConstantsState) => state.isChatbotKeyLoaded;

export const getStaticImages = (state: ConstantsState) => state.getStaticImages;
export const getStaticNamesListForSubHeader = (state: ConstantsState) => state.getStaticNamesListForSubHeader;
export const getStaticUrlListForSubHeader = (state: ConstantsState) => state.getStaticUrlsListForSubHeader;
export const getWeatherStatus = (state: ConstantsState) => state.getWeatherStatus;
export const getWeatherStatusCode = (state: ConstantsState) => state.getWeatherStatusCode;
export const getWeatherStatusErrorMessage = (state: ConstantsState) => state.getWeatherStatusErrorMessage;
export const getIsWatherLoading = (state: ConstantsState) => state.isWeatherLoading;
export const getIsWatherLoaded = (state: ConstantsState) => state.isWeatherLoaded;
export const getBlockUserMessage = (state: ConstantsState) => state.getBlockUserMessage;
export const getApplicationUrls = (state: ConstantsState) => state.getApplicationUrls;
export const getBlogLabels = (state: ConstantsState) => state.getBlogLabels;
export const getMerchantAndBrandLabels = (state: ConstantsState) => state.getMerchantAndBrandLabels;
export const getForumLabels = (state: ConstantsState) => state.getForumLabels;
export const getSortByListing = (state: ConstantsState) => state.getSortByListing;
export const getMoreServicesLabels = (state: ConstantsState) => state.getMoreServicesLabels; 
export const getMyCartLabels = (state: ConstantsState) => state.getMyCartLabels;
export const getMyDealsLabels = (state: ConstantsState) => state.getMyDealsLabels;
export const getMyLoyaltyLabels = (state: ConstantsState) => state.getMyLoyaltyLabels;
export const getAddDealsLabels = (state:ConstantsState) => state.getAddDealsLabels;
export const getSortByLabels = (state:ConstantsState) => state.getSortByLabels; 
export const getSortByGrievanceLabels = (state:ConstantsState) => state.getSortByGrievanceLabels; 
export const getGrievanceLabels = (state:ConstantsState) => state.getGrievanceLabels; 
export const getRemoveExistDeallabels = (state:ConstantsState) => state.getRemoveExistDeallabels;
export const getGroupsLabels = (state:ConstantsState) => state.groups;
export const getNewsletterLabels = (state:ConstantsState) => state.newsletterLabels;
export const getWebinarLabels = (state:ConstantsState) => state.getWebinarLabels;
export const getComplanitDashboardLabels = (state:ConstantsState) => state.getComplanitDashboardLabels
export const getComplanitListLabels = (state:ConstantsState) => state.getComplanitListLabels
export const getDashboardLinks = (state:ConstantsState) => state.dashboardLinks
export const getComplanitDetailsLabels = (state:ConstantsState) => state.getComplanitDetailsLabels
export const getActionDashboardLabels = (state:ConstantsState) => state.getActionDashboardLabels
export const getInsructionPopupLabels = (state:ConstantsState) => state.getInsructionPopupLabels
export const getSGYLabels = (state:ConstantsState) => state.getSGYLabels
export const getZooBookingLabels = (state:ConstantsState) => state.getZooBookingLabels
export const getDBTLabels = (state:ConstantsState) => state.getDBTLabels;

export const getAntiRabiesVaccinatedLabels = (state:ConstantsState) => state.getAntiRabiesVaccinatedLabels;
export const getCatAgeRangesLabels = (state:ConstantsState) => state.getCatAgeRangesLabels;
export const getCatBreedsLabels = (state:ConstantsState) => state.getCatBreedsLabels;
export const getCatColorsLabels = (state:ConstantsState) => state.getCatColorsLabels;
export const getCatTenureLabels = (state:ConstantsState) => state.getCatTenureLabels;
export const getDogAgeRangesLabels = (state:ConstantsState) => state.getDogAgeRangesLabels;
export const getDogBreedsLabels = (state:ConstantsState) => state.getDogBreedsLabels;
export const getDogColorsLabels = (state:ConstantsState) => state.getDogColorsLabels;
export const getDogTenureLabels = (state:ConstantsState) => state.getDogTenureLabels;
export const getGenderOptionsLabels = (state:ConstantsState) => state.getGenderOptionsLabels;
export const getYesNoOptionsLabels = (state:ConstantsState) => state.getYesNoOptionsLabels;
export const getHousingOwnershipLabels = (state:ConstantsState) => state.getHousingOwnershipLabels;
export const getPetDocUploadlabels = (state:ConstantsState) => state.getPetDocUploadlabels;
export const getDogApplicationLabels = (state:ConstantsState) => state.getDogApplicationLabels;
export const getCatApplicationLabels = (state:ConstantsState) => state.getCatApplicationLabels;
export const getPetHomePageLabels = (state:ConstantsState) => state.getPetHomePageLabels;



