<div class="box">
  <div>
    <div class="image">
      <img src="{{ getImage(item) }}" loading="lazy" alt="..."/>
    </div>
    <div class="type">
      {{eventType | uppercase}}
    </div>
    <div class="title">
      {{item[selectedLanguage].heading ? item[selectedLanguage].heading : item[selectedLanguage].question}}
    </div>
    <div class="description">
      {{item[selectedLanguage].description ? item[selectedLanguage].description : item[selectedLanguage].content}}
    </div>
  </div>
  <div class="date">
    {{ dayName }}<br />
    {{ item.createdAt | date: 'MMMM d, yyyy'}}
  </div>
</div>