import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { OnClickEvent, OnRatingChangeEven, OnHoverRatingChangeEvent } from 'angular-star-rating';
import * as fromStore from '../../../../cep/store';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../../services/localstorage.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'app-feedback-popup',
    //   standalone:true,
    //   imports:[FormsModule],
    templateUrl: './feedback-popup.component.html',
    styleUrls: ['./feedback-popup.component.scss'],
    standalone: true,
    imports: [MatInput, FormsModule, MatButton]
})
export class FeedbackPopupComponent implements OnInit {
  	rate;
	apiDetails: any;
	feedbackComment: string = null;
	token: any;
	UserProfileDetails: any;
	userName: any;
	mobileNo: any;
	userId: any;
	feedsLabels: {};
	selectedLanguage: string;
	dataFromBackPage: any;
	againstId = null;

  constructor(public dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private router: Router,
		private localStorageService: LocalStorageService,
		private dialogRef: MatDialogRef<FeedbackPopupComponent>,
		private encrDecr: EncrDecrService,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		if (data) {
		
			this.dataFromBackPage = data;
			this.againstId = this.dataFromBackPage['merchantId'];
		}
		dialogRef.disableClose = true;
		
	}

  ngOnInit() {
    this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		this.store.select(fromStore.getFeedsLabels)
			.subscribe((labels) => {
				this.feedsLabels = labels;
			});

		if (Object.keys(this.apiDetails).length > 0) {
			if (this.localStorageService.getToken()) {
				this.token = this.encrDecr.get(this.localStorageService.getToken());
				if (this.token) {
					this.store.select(fromStore.getVerifyPasswordResponse)
						.subscribe((data) => {
							this.UserProfileDetails = data;
							this.userName = this.UserProfileDetails.firstName;
							this.mobileNo = this.UserProfileDetails.mobile;
							this.userId = this.UserProfileDetails.userId;
						}
						);
				}
			}
		}
  }

	changeRating(doc) {
		this.rate = doc.srcElement.value;
	}


	/**
	 * Created by Neha R
	 * For closing the popup
	 */
	cancleButton() {
		this.router.navigate(['/cep/my-deals']);
		this.dialog.closeAll();
	}

	// onClickResult: OnClickEvent;
	// onHoverRatingChangeResult: OnHoverRatingChangeEvent;
	// onRatingChangeResult: OnRatingChangeEven;
	// // onclick value of star-rating
	// onClick = ($event: OnClickEvent) => {
	// 	this.onClickResult = $event;
	// }

	// start rating change value
	// onRatingChange = ($event: OnRatingChangeEven) => {
	// 	this.onRatingChangeResult = $event;
	// }
	// on hover star rating value
	// onHoverRatingChange = ($event: OnHoverRatingChangeEvent) => {
	// 	this.onHoverRatingChangeResult = $event;
	// }

	// call hot feedback API when user click on submit feedback
	hotFeedbackApiCall() {
	
		
		if (this.userId) {
			this.store.dispatch(new fromStore.GetHotFeedback({
				url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].addHotFeedback.url,
				method: this.apiDetails['user'].addHotFeedback.method,
				body: {
					userId: this.userId,
					// hotFeedbackValue: this.onClickResult.rating,
					hotFeedbackValue: this.rate,
					mobile: this.mobileNo,
					firstName: this.userName,
					comment: this.feedbackComment,
					againstId: this.againstId
				}
			}));
		}
		this.router.navigate(['/cep/my-deals']);
		this.dialog.closeAll();
	}

}
