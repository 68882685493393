<!-- /*******************************
 * Component -  Footer of website
 * Created By - Kiran G
 * Updated By - Vinita Sharma
 * Updated On - 28/10/2020
 **************************************/ -->

<div class="container1">
  <div class="container">
    <div class="Logo-container">
      <img src="{{images?.finalLogin}}" class="pmc-care-logo"  loading="lazy" alt="..."/>
      <span class="corp-text">{{footerLabel[selectedLanguage]?.copyright | uppercase }}</span>
      <div class="footer-image-div">
        <a href="https://pmc.gov.in/mr/?main=true" target="_blank">
          <img src="{{images?.pmc_logo}}" class="footer-images" loading="lazy" alt="..." />
        </a>
        <a href="https://punesmartcity.in/smart-city/feeds" target="_blank">
          <img src="{{images?.smartCityLogo}}" class="footer-images" loading="lazy" alt="..." />
        </a>
        <a href="https://csc.gov.in/digitalIndia" target="_blank">
          <img src="{{images?.digitalIndiaLogo}}" class="footer-images"  loading="lazy" alt="..."/>
        </a>
      </div>
    </div>
    <div class="links-social">
      <div class="links">
        <div class="quick-links">
          <h2>{{landingLables[selectedLanguage]?.quickLinks | uppercase}}</h2>
          <div class="links-div">
            <a style="color: white;margin-top: 5px;" routerLink="/cep/home"
              [routerLinkActive]="['tab-active', 'header-color-home']"
              [matTooltip]="drawer[selectedLanguage]?.home | uppercase"
              (mouseenter)="speakText(drawer[selectedLanguage]?.home)"
              (mouseleave)="stopSpeaking()">{{drawer[selectedLanguage]?.home | uppercase}}</a>
            <a style="color: white;margin-top: 5px;" routerLink="/cep/feeds"
              [routerLinkActive]="['tab-active', 'header-color-feeds']"
              [matTooltip]="drawer[selectedLanguage]?.feed | uppercase"
              (mouseenter)="speakText(drawer[selectedLanguage]?.feed)"
              (mouseleave)="stopSpeaking()">{{drawer[selectedLanguage]?.feed | uppercase}}</a>
            <a style="color: white;margin-top: 5px;" routerLink="/cep/near-me"
              [routerLinkActive]="['tab-active', 'header-color-near-me']"
              [matTooltip]="drawer[selectedLanguage]?.nearMe | uppercase"
              (mouseenter)="speakText(drawer[selectedLanguage]?.nearMe)"
              (mouseleave)="stopSpeaking()">{{drawer[selectedLanguage]?.nearMe | uppercase}}</a>
            <a style="color: white;margin-top: 5px;cursor: pointer;" routerLink="/cep/e-services"
              [routerLinkActive]="['tab-active', 'header-color-services']"
              [matTooltip]="drawer[selectedLanguage]?.services | uppercase"
              (mouseenter)="speakText(drawer[selectedLanguage]?.services)"
              (mouseleave)="stopSpeaking()">{{drawer[selectedLanguage]?.services | uppercase}}</a>
              <a style="color: white;margin-top: 5px;" routerLink="/cep/offers"
              [routerLinkActive]="['tab-active', 'header-color-near-me']"
              [matTooltip]="drawer[selectedLanguage]?.more | uppercase"
              (mouseenter)="speakText(drawer[selectedLanguage]?.more)"
              (mouseleave)="stopSpeaking()">{{drawer[selectedLanguage]?.more | uppercase}}</a>
            <!-- <span matTooltip="{{ footerLabel[selectedLanguage].mahaTendor | uppercase }}"
              style="color: white;margin-top: 5px;cursor: pointer;" (click)="openTendor()" >
              {{ footerLabel[selectedLanguage].mahaTendor | uppercase }}
            </span> -->
            <span [matTooltip]="footerLabel[selectedLanguage]?.termsAndConditions | uppercase"
              style="color: white;margin-top: 5px;cursor: pointer;" (click)="openNewWindow()">
              {{footerLabel[selectedLanguage]?.termsAndConditions | uppercase}}
            </span>
            <span [matTooltip]="footerLabel[selectedLanguage]?.privacypolicy | uppercase"
              style="color: white;margin-top: 5px;cursor: pointer;" (click)="openNewWindow1()">
              {{footerLabel[selectedLanguage]?.privacypolicy | uppercase}}
            </span>
          </div>
        </div>
        <div class="contact-us" *ngIf="contactLabels">
          <h2>{{drawerLabel[selectedLanguage]?.contactUs | uppercase}}</h2>
          <div class="contact-content">
            <div class="contact">
              <img class="image-logo" src="{{images?.whatsapp}}"loading="lazy" alt="..." >
              <label style="margin-left:5px"><a style="color: white;"
                  (mouseenter)="speakText(contactLabels[selectedLanguage]?.contactUsNo1)" (mouseleave)="stopSpeaking()"
                  href="tel:{{contactLabels[selectedLanguage]?.contactUsNo1}}">{{
                  contactLabels[selectedLanguage]?.contactUsNo1 }}</a></label>
            </div>
            <div class="contact">
              <img class="image-logo" src="{{images?.whatsapp}}" loading="lazy" alt="...">
              <label style="margin-left:5px"><a style="color: white;"
                  (mouseenter)="speakText(contactLabels[selectedLanguage]?.contactUsNo2)" (mouseleave)="stopSpeaking()"
                  href="tel:{{contactLabels[selectedLanguage]?.contactUsNo2}}">{{
                  contactLabels[selectedLanguage]?.contactUsNo2 }}</a></label>
            </div>
            <div class="contact">
              <img class="image-logo-mail" src="{{images?.mail}}" loading="lazy" alt="...">
              <label style="margin-left:5px"><a style="color: white;"
                  (mouseenter)="speakText(contactLabels?.contactUsEmail)" (mouseleave)="stopSpeaking()"
                  href="mailto:{{contactLabels?.contactUsEmail}}">{{contactLabels?.contactUsEmail}}</a></label>
            </div>
          </div>
        </div>
        <div class="download-links">
          <h2>{{footerLabel[selectedLanguage]?.appAvailableOn | uppercase}}</h2>
          <div class="app-links" >
           <a target="_blank" href="{{playStoreUrl}}"><img class="store-image" matTooltip="PMC CARE Playstore" src="{{images?.googlePlayStore}}" loading="lazy" alt="..."></a>
           <a target="_blank" href="{{AppStoreUrl}}"><img class="store-image" matTooltip="PMC CARE Appstore" src="{{images?.appStore}}" loading="lazy" alt="..."></a>
          </div>
        </div>
        <div class="icons">
          <h2>{{contactUsLabels[selectedLanguage]?.youCanFollowUsOn | uppercase}}</h2>
          <div class="social-div">
            <img class="socialIcons " matTooltip="Facebook" (click)="redirectToFacebookPage()"
              src="{{images?.facebook1}}" loading="lazy" alt="..." (mouseenter)="speakText('follow us on facebook')"
              (mouseleave)="stopSpeaking()">
            <img class="socialIcons " matTooltip="Twitter" (click)="redirectToTwitter()" src="{{images?.twitter1}}" loading="lazy" alt="..."
              alt="" (mouseenter)="speakText('follow us on twitter')" (mouseleave)="stopSpeaking()">
            <img class="socialIcons " matTooltip="Instagram" (click)="redirectToInstagram()" src="{{images?.instagram1}}" loading="lazy" alt="..."
              (mouseenter)="speakText('follow us on instagram')" (mouseleave)="stopSpeaking()">
          </div>
        </div>
      </div>
      <div class="social">
      </div>
    </div>
  </div>
  <hr>
  <div class="disclaimer" style="font-size: 12px;">
    <span> {{footerLabel[selectedLanguage]?.footerDisclaimer | uppercase}}</span>
  </div>

  <hr>
  <div class="copyright text-center" style="font-size: small">
    <span> {{ footerLabel[selectedLanguage]?.footerCopyright | uppercase }}</span>
  </div>

</div>


<div class="fixed-bottom">
</div>