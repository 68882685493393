import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as AboutUsAction from '../../store/action/about-us.action';
import { AboutUsService } from '../../store/api-services/about-us.service';
import { of } from 'rxjs';

@Injectable()

export class AboutUsEffect {
	/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private actions$: Actions,
		private AboutUsService: AboutUsService
	) { }

	apiAboutUsDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<AboutUsAction.GetAboutUsDetails>(AboutUsAction.GET_ABOUT_US_DETAILS),
			switchMap((action) => {
				return this.AboutUsService.getAboutUsDetails(action.payload).pipe(
					map(aboutUsData => new AboutUsAction.GetAboutUsDetailsSuccess(aboutUsData)),
					catchError(error => of(new AboutUsAction.GetAboutUsDetailsFail(error)))
				);
			})
		)
	);

	apiCommissionerDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<AboutUsAction.GetCommissionerDetails>(AboutUsAction.GET_COMMISSIONER_DETAILS),
			switchMap((action) => {
				return this.AboutUsService.getCommissionerDetails(action.payload).pipe(
					map(commissionerData => new AboutUsAction.GetCommissionerDetailsSuccess(commissionerData)),
					catchError(error => of(new AboutUsAction.GetCommissionerDetailsFail(error)))
				);
			})
		)
	);

	apiCityDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<AboutUsAction.GetCityDetails>(AboutUsAction.GET_CITY_DETAILS),
			switchMap((action) => {
				return this.AboutUsService.getCityDetails(action.payload).pipe(
					map(cityData => new AboutUsAction.GetCityDetailsSuccess(cityData)),
					catchError(error => of(new AboutUsAction.GetCityDetailsFail(error)))
				);
			})
		)
	);

	apiTourismDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<AboutUsAction.GetTourismDetails>(AboutUsAction.GET_TOURISM_DETAILS),
			switchMap((action) => {
				return this.AboutUsService.getTourismDetails(action.payload).pipe(
					map(tourismData => new AboutUsAction.GetTourismDetailsSuccess(tourismData)),
					catchError(error => of(new AboutUsAction.GetTourismDetailsFail(error)))
				);
			})
		)
	);

	apiZoneDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<AboutUsAction.GetZoneDetails>(AboutUsAction.GET_ZONE_DETAILS),
			switchMap((action) => {
				return this.AboutUsService.getZoneDetails(action.payload).pipe(
					map(ZoneData => new AboutUsAction.GetZoneDetailsSuccess(ZoneData)),
					catchError(error => of(new AboutUsAction.GetZoneDetailsFail(error)))
				);
			})
		)
	);

	apiCorporatesDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<AboutUsAction.GetCorporatesDetails>(AboutUsAction.GET_CORPORATES_DETAILS),
			switchMap((action) => {
				return this.AboutUsService.getCorporatesDetails(action.payload).pipe(
					map(CorporatesData => new AboutUsAction.GetCorporatesDetailsSuccess(CorporatesData)),
					catchError(error => of(new AboutUsAction.GetCorporatesDetailsFail(error)))
				);
			})
		)
	);

}
