import { Component, OnInit, AfterContentChecked, ChangeDetectorRef, Inject, OnDestroy, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogActions } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Images } from 'src/app/shared/config/images-config';
import * as fromStore from '../../../../cep/store/index';
import { Store } from '@ngrx/store';
import { AppConstants } from 'src/app/shared/config/app-constants';
import { LocalStorageService } from '../../../services/localstorage.service';
import { Router } from '@angular/router';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-apply-coupon-popup',
    templateUrl: './apply-coupon-popup.component.html',
    styleUrls: ['./apply-coupon-popup.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon, MatFormField, MatInput, FormsModule, MatDialogActions]
})
export class ApplyCouponPopupComponent implements OnInit, AfterContentChecked, OnDestroy {
	public images = Images;
	constants = AppConstants;
	deviceInfo = null;
	public topic;
	selectedLanguage;
	contactUsLabel;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	isLoading = false;
	isLoaded = false;
	contactNo: {};
	device: string = null;
	apiDetails: {};
	isNetworkError = false;
	contactUsResult: {};
	couponNumber: number;
	serverErrorMessage: string;
	/**
	 * user token
	 */
	token: string;
	ifqrCode= false
	scanValue;
	/**
	 * user id
	 */
	userId: any;
	myCartLables: {}
	errorMessages: {};
	successMsg: {};
	couponCode: number;
	isBlind;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private ref: ChangeDetectorRef,
		public router: Router,
		private store: Store<fromStore.CEPState>,
		private dialogRef: MatDialogRef<ApplyCouponPopupComponent>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService
	) {
		dialogRef.disableClose = true;
		this.ifqrCode  = data.transaction
		
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});


	}

	ngOnInit() {
			

		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on apply coupon button', 'Click Event', 1);

		// token from localstorage
		this.store.select(fromStore.getToken)
		.subscribe((token) => {
		this.token = token;
		if (this.token) {
			const localUserId = this.localStorageService.getUserId();
			this.userId = this.encrDecr.get(localUserId);
		}
		});
		this.store.select(fromStore.getMyCartLabels)
		.subscribe(labels => {
			this.myCartLables = labels;
		});
		this.store.select(fromStore.getErrorMessages)
			.subscribe((errorMessage) => {
				this.errorMessages = errorMessage;
			});

			this.store.select(fromStore.getSuccessMessages)
			.subscribe((successMsg) => {
				this.successMsg = successMsg;
			});
		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

	}

	/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }
/**
	 * Created by Akshata c
	 * api call for apply coupon
	 */
	async applyCoupon() {
		
		if (Object.keys(this.apiDetails).length > 0) {
			await this.store.dispatch(new fromStore.ApplyCoupon({
			  url: this.apiDetails["user"].base_url +
				this.apiDetails["user"].addCouponToCart.url,
			  method: this.apiDetails["user"].addCouponToCart.method,
			  body:  {
				userId: this.userId,
				couponCode: this.couponNumber,
				price: this.data.label.price
			  }
			}));
			await this.store.select(fromStore.applyCouponStatusCode)
			.subscribe(code => {
				this.couponCode = code;
				if(this.couponCode == 200){
					
					this.store.select(fromStore.applyCouponSuccessMessage)
					.pipe(take(1))
					.subscribe(errorMessages => {
						if(errorMessages) {
								this.toastr.success(this.successMsg[this.selectedLanguage][errorMessages]);
								
						}
					})
								if (Object.keys(this.apiDetails).length > 0) {
									this.store.dispatch(new fromStore.GetUserCart({
									  url: this.apiDetails['user'].base_url +
										this.apiDetails['user'].getUserCart.url,
									  method: this.apiDetails['user'].getUserCart.method,
									  body:  {
										userId: this.userId
									  }
									}));
									const dialogClose = this.dialog.closeAll();
						}				
				}
				else if( this.couponCode !== 0 && this.couponCode !== 200  ){
					console.error(this.errorMessages[this.selectedLanguage][this.couponCode]);
					const dialogClose = this.dialog.closeAll();
				}
			});
		}
	}
	


	addMerchantTransaction(){
		if (Object.keys(this.apiDetails).length > 0 && this.userId) {
			this.store.dispatch(new fromStore.AddMerchantTransaction({
			  url: this.apiDetails['user'].base_url +
				this.apiDetails['user'].merchantScanUpdate.url,
			  method: this.apiDetails['user'].merchantScanUpdate.method,
			  body:{
						merchantId : this.userId,
						scanValue : this.scanValue
					}
			}));
		}

		this.store.select(fromStore.addMerchantTransactionLoading)
    .subscribe(result => {
      this.isLoading = result
    });
    this.store.select(fromStore.addMerchantTransactionLoaded)
    .subscribe(isLoaded => {
      if(isLoaded){
        this.store.select(fromStore.addMerchantTransactionStatusCode)
        .subscribe(code => {
          if(code===200){
		this.toastr.success(this.errorMessages[this.selectedLanguage].merchantscanupdate);
		const dialogClose = this.dialog.closeAll();
          }else if (code === 500) {
            this.store.select(fromStore.getErrorMessages)
              .subscribe((errorMessage) => {
				this.errorMessages = errorMessage;
                console.error(this.errorMessages[this.selectedLanguage][500]);
              });
          }
        });
       
      }
    });

	}




	/**
	 * Created by Akshata c
	 * For closing the popup
	 */
	closeDialog() {
		const dialogClose = this.dialog.closeAll();
	}

	ngAfterContentChecked() {
		// this.ref.detectChanges();
	}

	ngOnDestroy() {
		// this.serverErrorMessage = null;
	}
}

