<!-- /******************************************
 * Component -  event details screen
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ***********************************************/ -->

<!-- loader -->
<div *ngIf="isLoading" class="spinner-loader">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
<!-- main content -->
<div *ngIf="!isLoading">
  <div *ngIf="feedsData != undefined" class="post-navigation font-sub-heading">
    <p matTooltipClass="engagement-tooltip  " matTooltip="{{feedsData[selectedLanguage].heading}}" style="width: 80%;">
      <span [routerLink]="[ '/cep/feeds']" class="feeds-redirection font-card-heading">
        {{ landingLables[selectedLanguage].feed | uppercase}}</span> > {{feedsData[selectedLanguage].heading}}</p>
    </div>
    <div class="post-container">
      <div class="post-image">
        <app-details-page-slider [postImages]="feedsData.image"></app-details-page-slider>
      </div>
    <div class="post-details">
      <div class="details-page-post-description">
        <div class="post-type">
          <p>{{feedFilterLabels[selectedLanguage].event | uppercase}}</p>
          <!-- <button mat-raised-button class="mat-button">{{feedLabels[selectedLanguage][feedsData.type]}}</button> -->
        </div>
        <div class="post-heading font-heading">
          <p class="font-heading">{{feedsData[selectedLanguage].heading}}</p>
          <!-- <mat-icon class="speaker-icon-class pointer" matTooltip="{{landingLables[selectedLanguage].speak}}" (click)="speakText(feedsData[selectedLanguage].heading)">volume_up</mat-icon> -->
        </div>
        <div *ngIf="feedsData[selectedLanguage].content" class="post-containt  ">
          <p [innerHTML]="feedsData[selectedLanguage].content"></p>
          <!-- <mat-icon class="speaker-icon-class pointer" matTooltip="{{landingLables[selectedLanguage].speak}}" (click)="speakText(feedsData[selectedLanguage].content, feedsData[selectedLanguage].heading)">volume_up</mat-icon> -->
        </div>
        <div class="post-containt">
          <label class="website-link">{{feedLabels[selectedLanguage].websiteLink}}</label>
          <p><a href="{{feedsData.webLink}}" target="_blank">{{feedsData.webLink}}</a></p>
        </div>
        <div class="post-containt">
          <label class="website-link">{{feedLabels[selectedLanguage].termsAndCondition}}</label>
          <p [innerHTML]="feedsData[selectedLanguage].termAndCondition"></p>
        </div>

      </div>
      <div class="post-date-type">
        <div class="booking-sharing">
          <div class="booking">
            <button mat-raised-button class="mat-button" disabled>{{feedLabels[selectedLanguage].bookYourSeat}}</button>
          </div>
          <!-- share options -->

          <div class="sharing">
            <div class="abuse-flag-container">
              <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon>
          </div>
            <div *ngIf="this.userId" (click)="clickOnShare()"><i class="material-icons" [matMenuTriggerFor]="menu" matTooltip="{{landingLables[selectedLanguage].share}}">
                share
              </i></div>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')">
                <img class="social-share-img" src="{{image.facebookShareImg}}" loading="lazy" alt="...">
                Facebook
              </button>
                            <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')">
                <img class="social-share-img" src="{{image.twitterShareImg}}" loading="lazy" alt="...">
                Twitter
              </button>
                            <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')">
                <img class="social-share-img" src="{{image.linkedinShareImg}}" loading="lazy" alt="...">
                LinkedIn
              </button>
                            <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')"> 
                <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}" loading="lazy" alt="...">
                Whatsapp
              </button>
                        </mat-menu>
                        <div *ngIf="this.userId"><i class="material-icons favorite-icon" [ngClass]="{'active-favorite':feedsData.favourite}" matTooltip="{{landingLables[selectedLanguage].addToFavorites}}" (click)="unMarkFavorite()">
                {{favorite}}
              </i></div>
          </div>
        </div>
        <!-- event details -->
        <div class="post-date-venue">
          <div class="post-date">
            <label>{{feedLabels[selectedLanguage].eventDate}}</label>
            <p>{{startDate}} - {{endDate}}</p>
          </div>
          <div class="post-date" (mouseenter)="speakText(feedLabels[selectedLanguage].postedBy + feedsData.createdBy)">
            <label>{{feedLabels[selectedLanguage].postedBy}}</label>
            <p>{{ feedsData.createdBy }}</p>
          </div>
          <div class="post-venue-direction">
            <div class="post-venue">
              <label>{{feedLabels[selectedLanguage].venue}}</label>
              <p>{{feedsData[selectedLanguage].address}}</p>
            </div>
            <div class="direction-icon">
              <img src="{{image.directionImage}}" loading="lazy" alt="..." (click)="redirectToGoogleMap()">
            </div>
        </div>
    </div>
  
  </div>
  
</div>
<div class = "related-article-portion" *ngIf = "feedsData.relatedArticle.length > 0">
  <div class="article-heading">
    <label>
      <strong>
        {{feedLabels[selectedLanguage].relatedArticle}}
      </strong>
    </label>
  </div>
  <app-related-article [feedsData]="feedsData?.relatedArticle" [selectedLanguage]="selectedLanguage" [landingLables]="landingLables"></app-related-article>
</div>
</div>
</div>
<!-- error messages -->
<!-- <div class="details-page-error-message" *ngIf="errMessage">
    <img src="{{image.pageNotFound}}" alt="">
    <span>{{errMessage}}</span>
</div> -->