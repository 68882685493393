<!-- *******************************************************************
*  Component -  Near Me type popup
   Created By - Prajakta Patil
   Created On - 04/09/2019
   Updated By - Prajakta Patil
   Updated On - 22/09/2019
*****************************************************************  -->
<div class="popup-container">
  <div>
    <div>
      <p class="popup-heading-container heading">{{ data.type }}
        <mat-icon class="cross-button" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>
      </p>
    </div>

    <span *ngIf="data.img" class="card-image">
      <img class="detail-card-image" src={{data.img}} loading="lazy" alt="...">
    </span>
    <div>
      <ng-container *ngIf="type == 'Bicycle' ; then yuluAPPLink else leapBikeLink"></ng-container>
      <ng-template #yuluAPPLink>
        <button *ngIf="showLink" class="link-color" (click)="openLinkOnBrowser()" mat-stroked-button>GO TO APP</button>
      </ng-template>
      <ng-template #leapBikeLink>
        <button *ngIf="showLink" class="link-color" (click)="openLinkOfLeapBike()" mat-stroked-button>GO TO APP</button>
      </ng-template>
    </div>
    <div class="card-details">
      <div *ngIf="data.address" class="address-container">
        <label>{{address}} :</label> 
        {{ data.address }}
          <img class="direction-icon"
            (click)="$event.stopPropagation();redirectToGoogleMap(data.latitude, data.longitude)"
            src="{{images.directionImage}}" loading="lazy" alt="...">
      </div>
      <div> 
        <label> {{description}} : </label>
        {{ data.destination }}</div>
      <div *ngIf="data.contactNo1"><label>{{contactNo1}} :</label> 
        <a href="tel:{{data.contactNo1}}">{{ data.contactNo1 }}</a></div>
      <div *ngIf="data.contactNo2"><label>{{contactNo2}} :</label>
        <a href="tel:{{data.contactNo2}}">{{ data.contactNo2 }}</a></div>
        <div class="button"  *ngIf="data.moreServices == true">
          <button mat-raised-button class="deal-button" (click)="dealListing()">{{merchantDetailLabels[selectedLanguage].dealNow}}</button>
      </div>
    </div>
  </div>
</div> 