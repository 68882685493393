/*******************************
 * Component -  Group
 * Created By - Akshata c
 * Created on - 13/05/2020
 * Updated By - Akshata c
 * Updated On - 13/05/2020
 *************************************/
import { Component, OnInit, AfterViewInit, ChangeDetectorRef,TemplateRef, AfterContentChecked, ElementRef, AfterViewChecked, ViewChild, OnDestroy } from '@angular/core';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { Subject } from 'rxjs';
import { LocalStorageService } from '../../../services/localstorage.service';
import { ObjectInterface } from '../../../models/global-interface';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { PopupQrScanComponent } from '../../popup-qr-scan/popup-qr-scan.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RemoveFromCartPopupComponent } from '../remove-from-cart-popup/remove-from-cart-popup.component';
import { ApplyCouponPopupComponent } from '../apply-coupon-popup/apply-coupon-popup.component';
import { take } from 'rxjs/operators';
import { SocketioService } from '../../../services/socketio.service';
import { getChatPaginationCount } from 'src/app/cep/dashboard-management/store/reducer/group.reducer';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { HttpClient } from '@angular/common/http';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatDrawerContainer, MatDrawer } from '@angular/material/sidenav';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { MatList, MatListItem } from '@angular/material/list';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor, NgClass, NgStyle, UpperCasePipe, SlicePipe, DatePipe } from '@angular/common';


@Component({
    selector: 'app-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatCard, MatCardContent, NgFor, MatList, MatListItem, MatTooltip, MatDivider, MatDrawerContainer, MatDrawer, MatIcon, NgClass, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatButton, MatMenuTrigger, MatMenu, FormsModule, MatMenuItem, NgStyle, UpperCasePipe, SlicePipe, DatePipe]
})
export class GroupComponent implements OnInit, OnDestroy, AfterContentChecked {
    @ViewChild('replyDialogTemplate',{static:false}) replyDialogTemplate: TemplateRef<any>;
	@ViewChild('scrollBottom', { static: false }) private scrollContainer: ElementRef;
	@ViewChild(MatMenuTrigger, { static: false }) menuTrigger!: MatMenuTrigger;
	selectedGroupId: any;
	isLoadingChat: boolean;
	isAlreadyBottom = false;
	isDataLoading: boolean;
	lengthOfChat: any;
	firstName: any;
	lastName: any;
	selectedMessage: any;
	successMsg: {};
	displayConfirmationModal = 'none';
	showImage: any;
	months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
	attachments = []
	Isattchment: boolean = false;
	contextMenu: any;
	replyMessage: string = '';
showReplyDialog: boolean = false;



	goToBottom() {
		let myElement = document.getElementById("msgcard");
		myElement.scrollIntoView(false)
	}

	ngAfterViewChecked() {
		// this.scrollToBottom();
		// this.toGoBottom();
	}

	scrollToBottom(): void {
		let myElement = document.getElementById("msgcard");
		myElement.scrollIntoView(false)
	}

	// chat message array 
	chatArrayOfGroup: any;
	isNewChatLoading = false;
	isSeeMoreButtonShow = true;
	count = 0;

	/**
	   * loading status of spinner
	   */
	isLoading = true;
	showMore = false;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	valueChat = 35
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails: ObjectInterface;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 */
	data: any;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * variable use
	 */
	allGroupData: any;
	/**
	 * variable for data not found
	 */
	dataNotFoundMsg: any;
	allUserGroupData: any;
	groupDetailsData: any;
	public ngUnsubscribe: Subject<void> = new Subject<void>();
	errorMessages: {};
	successMessages: {};
	msgText: string;
	element: any;
	timer: any;
	replyTO:any = null;
	updatedMsg: any;
	eventGroupId: any;
	isMobile: boolean;
	throttle = 10;
	scrollDistance = 1;
	scrollUpDistance = 1;
	groupsLabels: {};
	msgChatDetails: any;
	isBlind;
	preferences;
	/**
	 * Angular method which runs on component initialization
	 */
	constructor(
		private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute,
		private ref: ChangeDetectorRef,
		private deviceService: DeviceDetectorService,
		private socketioService: SocketioService,
		private apiCallService: ApiCallService,
		private httpClient: HttpClient,
	) {
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});
	}

	ngOnInit() {
		this.googleAnalyticsService.eventEmitter(
			screenName.MY_GROUP+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.MY_GROUP + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	
		this.isMobile = this.deviceService.isMobile();

		setInterval(() => {
			if (this.selectedGroupId) {
				let url = this.apiDetails['user'].base_url + '/v1/GroupCtrl/getGroupDetails1';
				let method = this.apiDetails['user'].getGroupDetails.method;
				let body = {
					groupId: this.selectedGroupId,
					prvCnt: 0
				}
				this.apiCallService.apiCall(method, url, body).subscribe((data: any) => {
					console.log(data)
					
					this.chatArrayOfGroup = Object.assign([], data.result.chatDetails).reverse();
					this.lengthOfChat = this.chatArrayOfGroup.length

					// below code is for read more condition for long mmessages in chat
					for (let i = 0; i < this.chatArrayOfGroup.length; i++) {
						var textData = new String(this.chatArrayOfGroup[i].text)
						if (textData.length > 250) {
							this.chatArrayOfGroup[i].visible = false;
						}
						else {
							this.chatArrayOfGroup[i].visible = true;
						}
					}
				});
			}
		}, 5000)

		this.socketioService.eventCallback$.subscribe(data => {
			this.updatedMsg = data['text'];
			this.eventGroupId = data['groupId'];
			// data['firstName'] = this.firstName;  
			// data['lastName'] = this.lastName;
			var textData = new String(data['text'])
			if (textData.length > 300) {
				data['visible'] = false;
			}
			else {
				data['visible'] = true;
			}
			this.chatArrayOfGroup.push(data)
		});

		// token from localstorage
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				}
			});

		//   get user data
		this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((response) => {

				//   this.preferences = response['preferences'];
			

				this.firstName = response['firstName'];
				this.lastName = response['lastName'];
			});

		if (Object.keys(this.apiDetails).length > 0) {
			// api call for group details

			// this.store.dispatch(new fromStore.GetAllUserGroups({
			// 	url: this.apiDetails.user.base_url +
			// 		this.apiDetails.user.getAllUserGroups.url,
			// 	method: this.apiDetails.user.getAllUserGroups.method,
			// 	body: {
			// 		userId: this.userId,
			// 	}
			// }))
			this.store.select(fromStore.getSuccessMessages)
				.subscribe(res => {
					this.successMsg = res;
				})

			this.store.select(fromStore.isGetAllUserGroupsLoading)
				.subscribe(loading => {
					this.isLoading = loading;
				});

			this.store.select(fromStore.getGroupsLabels)
				.subscribe(groupsLabels => {
					if (groupsLabels) {
						this.groupsLabels = groupsLabels;
					}
				});

			this.store.select(fromStore.getSelectedLanguage)
				.subscribe(lang => {
					this.selectedLanguage = lang;
				});

			this.store.select(fromStore.createMessageSuccessMessage)
				.subscribe(msg => {
					this.store.select(fromStore.createMessageResult)
						.subscribe((result: any) => {
							this.store.dispatch(new fromStore.GetGroupDetails({
								url: this.apiDetails['user'].base_url +
									'/v1/GroupCtrl/getGroupDetails',
								method: this.apiDetails['user'].getGroupDetails.method,
								body: {
									groupId: result.groupId,
									prvCnt: 0
								}
							}));

							this.store.dispatch(new fromStore.GetAllUserGroups({
								url: this.apiDetails['user'].base_url +
									'/v1/GroupCtrl/getAllUserGroups',
								method: this.apiDetails['user'].getAllUserGroups.method,
								body: {
									userId: this.userId
								}
							}));
						})
				})

			// this.store.select(fromStore.getSelectedLanguage)
			// .subscribe(lang => {
			// 	this.selectedLanguage = lang;
			// 	if (this.selectedLanguage) {
			// 		this.store.select(fromStore.isGetAllGroupsLoading)
			// 		.subscribe(loaded => {
			// 			if (loaded) {
			// 				this.store.select(fromStore.getAllGroupsStatusCode)
			// 				.subscribe(code => {
			// 					if(code) {
			// 						if (code === 200) {
			// 							this.store.select(fromStore.getAllGroupsResult)
			// 							.subscribe(result => {
			// 								if(result) {
			// 									this.allGroupData = result;
			// 								}
			// 							});
			// 						} else if (code === 500) {
			// 							this.store.select(fromStore.getAllGroupsSuccessMessage)
			// 							.subscribe(errorMessages => {
			// 								this.dataNotFoundMsg = errorMessages;
			// 							});
			// 						}
			// 						else {
			// 						this.store.select(fromStore.getAllGroupsSuccessMessage)
			// 							.subscribe(errorMessages => {
			// 								console.error(errorMessages[this.selectedLanguage].code);
			// 							});
			// 						}
			// 					}
			// 				});
			// 			}
			// 		});
			// 	}
			// });

			this.store.select(fromStore.getChatPaginationCount)
				.subscribe(prevCount => {
					this.count = prevCount;
				});

			this.store.select(fromStore.isGetAllUserGroupsLoading)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.getAllUserGroupsStatusCode)
							.subscribe(code => {
								if (code) {
									if (code === 200) {
										this.store.select(fromStore.getAllUserGroupsResult)
											.subscribe(result => {
												if (result) {
													this.allUserGroupData = result;
												}
											});
									} else if (code === 500) {
										this.store.select(fromStore.getAllGroupsSuccessMessage)
											.subscribe(errorMessages => {
												this.dataNotFoundMsg = errorMessages;
											});
									}
									else {
										this.store.select(fromStore.getAllGroupsSuccessMessage)
											.subscribe(errorMessages => {
												console.error(errorMessages[this.selectedLanguage].code);
											});
									}
								}
							});
					}
				});
		}

	}


	openReplyDialog(chatDetails: any): void {
		this.replyTO = chatDetails;
	
	  }

	  closeDialog(): void {
		this.dialog.closeAll();
	  }
 
	/**
		 * Created by Vinita Sharma
		 * For removing HTML tags from text 
		 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return this.splitSentance(span.textContent || span.innerText)
	};

	/**
   * Created by Vinita Sharma
   * to split the paragraph in sentances
   */
	splitSentance(sen) {
		if (!sen.match(/[^\.!\?]+[\.!\?]+/g)) {
			return [sen]
		} else {
			return sen.match(/[^\.!\?]+[\.!\?]+/g)
		}
	}


	
	  

	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			speechSynthesis.cancel();
			var newmessage = this.extractContent(textToSpeak)
			var voices = speechSynthesis.getVoices();

			for (let sentance = 0; sentance < newmessage.length; sentance++) {
				var message = new SpeechSynthesisUtterance(newmessage[sentance]);
				message.voice = voices[9];
				message.lang = 'hi-IN';
				message.volume = 1;
				speechSynthesis.speak(message);
			}
		}
	}

	addImage(event: any) {
		this.googleAnalyticsService.eventEmitter(screenName.MY_GROUP, {
			log: clickedOn.ADD_IMAGE,
			screenName: screenName.MY_GROUP + "_SCREEN",
			SECTION: screenName.MY_GROUP ,
			clickedOn: clickedOn.ADD_IMAGE,
			title: clickedOn.ADD_IMAGE,
			time: new Date(),
			isInput: false,
				  })
		this.Isattchment = true;
		if (event.target.files.length < 5) {
			const files = event.target.files;
			if (files) {
				for (let i = 0; i < files.length; i++) {
					const file = files[i];
					if (file.type.split('/')[0] !== 'image') {
						// Display error message and clear input
						this.selectedLanguage === 'en' ? alert("Please only upload images") : alert("कृपया केवळ चित्र अपलोड करा")
						return;
					}
					if (file.size > 800000) {
						// Display error message and clear input
						this.selectedLanguage === 'en' ? alert("Upload Image of size 800kb or less") : alert("800kb किंवा त्यापेक्षा कमी आकाराची प्रतिमा अपलोड करा")
						event.target.value = null;
						return;
					}
					else {
						// Create a new FormData object for the current file
						const fd = new FormData();
						fd.append('userId', this.userId);
						fd.append('type', 'groupImage');
						fd.append('link', '');

						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = (e: any) => {
							fd.append('file', file, file.name);

							// Upload the file to the server using HttpClient
							const link = this.apiDetails['objectStorage'].base_url + this.apiDetails['objectStorage'].storeObject.url;
							this.httpClient.post(link, fd)
								.subscribe((res: any) => {
									let obj = {
										type: 'img',
										uri: res.result[0]
									}
									
									this.attachments.push(obj)
									this.Isattchment = false
								});
						};
					}
				}
			}
		}
		else {
			alert('Please upload only 5 docs');
			this.Isattchment = false
		}
	}

	addDoc(event: any) {
		this.googleAnalyticsService.eventEmitter(screenName.MY_GROUP, {
			log: clickedOn.ADD_DOCUMENT,
			screenName: screenName.MY_GROUP + "_SCREEN",
			SECTION: screenName.MY_GROUP ,
			clickedOn: clickedOn.ADD_DOCUMENT,
			title: clickedOn.ADD_DOCUMENT,
			time: new Date(),
			isInput: false,
				  })
		this.Isattchment = true;
		if (event.target.files.length < 5) {
			const files = event.target.files;
			if (files) {
				for (let i = 0; i < files.length; i++) {
					const file = files[i];
					if (file.size > 800000) {
						// Display error message and clear input
						this.selectedLanguage === 'en' ? alert("Upload Image of size 800kb or less") : alert("800kb किंवा त्यापेक्षा कमी आकाराची प्रतिमा अपलोड करा")
						event.target.value = null;
						return;
					}
					else {
						
						// Create a new FormData object for the current file
						const fd = new FormData();
						fd.append('userId', this.userId);
						fd.append('type', 'groupDoc');
						fd.append('link', '');

						const reader = new FileReader();
						reader.readAsDataURL(file);
						reader.onload = (e: any) => {
							fd.append('file', file, file.name);

							// Upload the file to the server using HttpClient
							const link = this.apiDetails['objectStorage'].base_url + this.apiDetails['objectStorage'].storeObject.url;
							this.httpClient.post(link, fd)
								.subscribe((res: any) => {
									if(res.code === 200){
										let obj = {
											type: 'document',
											uri: res.result[0]
										}
									
										this.attachments.push(obj)
										this.Isattchment = false
									}else{
										this.selectedLanguage === 'en' ? alert("This file format is not supported") : alert("हा फाईल फॉरमॅट समर्थित नाही")
										this.Isattchment = false
									}
								});
						};
					}
				}
			}
		}
		else {
			alert('Please upload only 5 docs');
			this.Isattchment = false
		}
	}


	/**
		* Created by Vinita Sharma
		* Stops speaking when mouse leave the text content
		*/
	stopSpeaking() {
		speechSynthesis.cancel();
	}



	ngAfterViewInit() {
		//  this.toGoBottom();
		// 	var container = document.getElementById("msgcard");   	
		// 	this.scrollToBottom();

		// // 	// var element = document.getElementById("msgcard");
		

	
		// // 		// element.scrollTop = element.scrollHeight;
		// // 		element.scroll(1000, 1000);

	}

	// QR scanner for play store
	OpenQRScanPopup() {
		this.googleAnalyticsService.eventEmitter(screenName.MY_GROUP, {
			log: clickedOn.OPEN_QRSCANPOPUP,
			screenName: screenName.MY_GROUP + "_SCREEN",
			SECTION: screenName.MY_GROUP ,
			clickedOn: clickedOn.OPEN_QRSCANPOPUP,
			title: clickedOn.OPEN_QRSCANPOPUP,
			time: new Date(),
			isInput: false,
				  })
		const isMobile = this.deviceService.isMobile();
		if (isMobile) {
			window.location.href = 'https://pcmcsmartsarathi.page.link/jKYg';
		} else {
			this.dialog.open(PopupQrScanComponent, {
				autoFocus: false
			});
		}

	}
	returnedTime(updatedAtTime) {
		if ((new Date(updatedAtTime)).getDate() == new Date().getDate()) {
			return (new Date(updatedAtTime)).getHours() + ':' + String((new Date(updatedAtTime)).getMinutes()).padStart(2, "0")
		} else {
			return (new Date(updatedAtTime)).getDate() + " " + this.months[(new Date(updatedAtTime)).getMonth()] + ", " + (new Date(updatedAtTime)).getFullYear() + " " + (new Date(updatedAtTime)).getHours() + ':' + String((new Date(updatedAtTime)).getMinutes()).padStart(2, "0")
		}
	}
	// api call for accept group invitation
	async invitation(status, id) {
		await this.store.dispatch(new fromStore.AcceptPublicGroupInvitation({
			url: this.apiDetails['user'].base_url +
				'/v1/GroupCtrl/updateGroupAccept',
			method: this.apiDetails['user'].updateRequest.method,
			body: {
				groupId: id,
				userId: this.userId,
				status: status
			}
		}));
		await this.store.select(fromStore.isAcceptPublicGroupInvitationLoading)
			.subscribe(loading => {
				this.isLoading = loading;
			});
		await this.store.select(fromStore.isAcceptPublicGroupInvitationLoaded)
			.subscribe(loaded => {
				if (loaded) {
					this.store.select(fromStore.acceptPublicGroupInvitationResult)
						.subscribe(result => {
							if (result) {
								this.store.dispatch(new fromStore.GetAllUserGroups({
									url: this.apiDetails['user'].base_url +
										'/v1/GroupCtrl/getAllUserGroups',
									method: this.apiDetails['user'].getAllUserGroups.method,
									body: {
										userId: this.userId
									}
								}));
								this.store.select(fromStore.isGetAllUserGroupsLoading)
									.subscribe(loading => {
										this.isLoading = loading;
									});

								this.store.select(fromStore.isGetAllUserGroupsLoaded)
									.subscribe(loaded => {
										if (loaded) {
											this.store.select(fromStore.getAllUserGroupsStatusCode)
												.subscribe(code => {
													if (code) {
														if (code === 200) {
															this.store.select(fromStore.getAllUserGroupsResult)
																.subscribe(result => {
																	if (result) {
																		this.allUserGroupData = result;
																	}
																});
														} else if (code === 500) {
															this.store.select(fromStore.getAllGroupsSuccessMessage)
																.subscribe(errorMessages => {
																	this.dataNotFoundMsg = errorMessages;
																});
														}
														else {
															this.store.select(fromStore.getAllGroupsSuccessMessage)
																.subscribe(errorMessages => {
																	console.error(errorMessages[this.selectedLanguage].code);
																});
														}
													}
												});
										}
									});
							}
						})
				}
			})
	}
	//   Api call for getting chat details
	async chatDetails(groupData) {
		this.googleAnalyticsService.eventEmitter(screenName.MY_GROUP, {
			log: clickedOn.CHAT_DETAILS,
			screenName: screenName.MY_GROUP + "_SCREEN",
			SECTION: screenName.MY_GROUP ,
			clickedOn: clickedOn.CHAT_DETAILS,
			title: clickedOn.CHAT_DETAILS,
			time: new Date(),
			isInput: false,
				  })
		this.msgText = null;
		this.replyTO = null;
		if (groupData.isAccept) {
			this.store.dispatch(new fromStore.SetPaginationCountChat({
				ChatPaginationCount: 0
			}));
			// this.updateScroll();
			// api call for group details
			this.selectedGroupId = groupData.groupId
			this.store.dispatch(new fromStore.GetGroupDetails({
				url: this.apiDetails['user'].base_url +
					'/v1/GroupCtrl/getGroupDetails',
				method: this.apiDetails['user'].getGroupDetails.method,
				body: {
					groupId: groupData.groupId,
					prvCnt: 0
				}
			}));
			this.store.select(fromStore.isGetGroupDetailsLoading)
				.subscribe(loading => {
					this.isDataLoading = loading
				
				})
			this.store.select(fromStore.isGetGroupDetailsLoaded)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.getGroupDetailsStatusCode)
							.subscribe(code => {
								if (code) {
									if (code === 200) {
										this.store.select(fromStore.getGroupDetailsResult)
											.subscribe(result => {
												if (result) {
													this.isNewChatLoading = false;
													this.groupDetailsData = result;													
													this.store.select(fromStore.getGroupChatArray)
														.subscribe(chatResult => {

															this.chatArrayOfGroup = Object.assign([], chatResult).reverse();

															this.lengthOfChat = this.chatArrayOfGroup.length

															// below code is for read more condition for long mmessages in chat
															for (let i = 0; i < this.chatArrayOfGroup.length; i++) {
																var textData = new String(this.chatArrayOfGroup[i].text)
																if (textData.length > 250) {
																	this.chatArrayOfGroup[i].visible = false;
																}
																else {
																	this.chatArrayOfGroup[i].visible = true;
																}
															}
															// this.updateScroll();

														});
												}
											});
									} else if (code === 500) {
										this.store.select(fromStore.getGroupDetailsSuccessMessage)
											.subscribe(errorMessages => {
												this.dataNotFoundMsg = errorMessages;
											});
									}
									else {
										this.store.select(fromStore.getGroupDetailsSuccessMessage)
											.subscribe(errorMessages => {
												console.error(errorMessages[this.selectedLanguage].code);
											});
									}
								}
							});
					}
				});
				console.log(groupData)
			// this.socketioService.listen(groupData.groupId);

		} else {
			console.error(this.groupsLabels[this.selectedLanguage].pleaseAcceptTheGroupRequest);
		}
	}


	//   getGroupByPref(prefValue){
	// 	  

	// 	if (Object.keys(this.apiDetails).length > 0) {  
	// 		// api call for group details
	// 		 this.store.dispatch(new fromStore.GetAllUserGroups({
	// 		   url: this.apiDetails.user.base_url +
	// 			 this.apiDetails.user.getAllUserGroups.url,
	// 		   method: this.apiDetails.user.getAllUserGroups.method,
	// 		   body:  {
	// 			 userId: this.userId,
	// 			 pref : prefValue
	// 		   }
	// 		 }));
	// 		}


	// 		this.store.select(fromStore.getAllUserGroupsStatusCode)
	// 				.subscribe(code => {
	// 					if(code) {
	// 						if (code === 200) {
	// 							this.store.select(fromStore.getAllUserGroupsResult)
	// 							.subscribe(result => {
	// 								if(result) {
	// 									this.allUserGroupData = result;
	// 								}
	// 							});
	// 						} else if (code === 500) {
	// 							this.store.select(fromStore.getAllGroupsSuccessMessage)
	// 							.subscribe(errorMessages => {
	// 								this.dataNotFoundMsg = errorMessages;
	// 							});
	// 							this.allUserGroupData = [];
	// 						}
	// 						else {
	// 						this.store.select(fromStore.getAllGroupsSuccessMessage)
	// 							.subscribe(errorMessages => {
	// 								console.error(errorMessages[this.selectedLanguage].code);
	// 							});
	// 						}
	// 					}
	// 				});
	//   }





	// api call for create message send
	createMsg(groupDetailsData) {
		this.googleAnalyticsService.eventEmitter(screenName.MY_GROUP, {
			log: clickedOn.CREATE_MSG,
			screenName: screenName.MY_GROUP + "_SCREEN",
			SECTION: screenName.MY_GROUP ,
			clickedOn: clickedOn.CREATE_MSG,
			title: clickedOn.CREATE_MSG,
			time: new Date(),
			isInput: false,
				  })
		if(this.replyTO){
			this.store.dispatch(new fromStore.CreateMessage({
				url: this.apiDetails['user'].base_url +
					'/v1/MessageCtrl/createMessage1',
				method: this.apiDetails['user'].createMessage.method,
				body: {
					groupId: groupDetailsData.groupId,
					userId: this.userId,
					text: this.msgText,
					firstName: this.firstName,
					lastName: this.lastName,
					attachment: this.attachments,
					repliedData:this.replyTO
				}
			}));
		}else{
			this.store.dispatch(new fromStore.CreateMessage({
				url: this.apiDetails['user'].base_url +
					'/v1/MessageCtrl/createMessage1',
				method: this.apiDetails['user'].createMessage.method,
				body: {
					groupId: groupDetailsData.groupId,
					userId: this.userId,
					text: this.msgText,
					firstName: this.firstName,
					lastName: this.lastName,
					attachment: this.attachments,
				}
			}));
		}
		this.msgText = ""
		this.replyTO = null
		this.attachments = []
		return false;
	}

	openDoc(url) {
		window.open(url)
	}


	closeReply(): void {
		this.replyTO = false; 
	  }
	openImage(url) {
		
		this.displayConfirmationModal = 'block';
		this.showImage = url;
	}

	closeConfirmationModal() {
		this.displayConfirmationModal = 'none';
		this.showImage = null;
	}

	async onScroll() {

		await this.store.dispatch(new fromStore.SetPaginationCountChat({
			ChatPaginationCount: this.count + 1
		}));
		if (this.selectedGroupId) {
			if (Object.keys(this.apiDetails).length > 0) {
				await this.store.dispatch(new fromStore.GetGroupDetails({
					url: this.apiDetails['user'].base_url +
						this.apiDetails['user'].getGroupDetails.url,
					method: this.apiDetails['user'].getGroupDetails.method,
					body: {
						groupId: this.selectedGroupId,
						prvCnt: JSON.stringify(this.count),
					}
				}));
			}
		}

		if (this.groupDetailsData['chatDetails'].length % 100 !== 0) {
			this.isSeeMoreButtonShow = false;
		}

		await this.store.select(fromStore.isGetGroupDetailsLoading)
			.subscribe(isLoading => {
				if (isLoading) {
					this.isDataLoading = false;
					if (this.count) {
						this.isNewChatLoading = true;
						this.isLoadingChat = isLoading;
					} else {
						this.isNewChatLoading = false;
						this.isLoadingChat = isLoading;
					}
				}
			});

	}

	truncateLabel(label: string): string {
		return label.split(' ').slice(0, 7).join(' ');
	}
	
	isLabelTruncated(label: string): boolean {
		return label.split(' ').length > 7;
	}
	ngAfterContentChecked() {
		this.ref.detectChanges();
	}
	ngOnDestroy() {
		this.googleAnalyticsService.eventEmitter(
			screenName.MY_GROUP + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.MY_GROUP + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		// this.store.dispatch(new fromStore.resetServerErrorMessage());
	}

}

