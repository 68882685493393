import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as fromStore from '../../../cep/store/index';;
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatCard } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-merchant-terms-and-conditions',
    templateUrl: './merchant-terms-and-conditions.component.html',
    styleUrls: ['./merchant-terms-and-conditions.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatCard]
})
export class MerchantTermsAndConditionsComponent implements OnInit {

  selectedLanguage: string;
	apiDetails: {};
	images = Images;
	aboutUsLabels: any;
	serverErrorMessage: string;
	isLoaded = false;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	serverMessage: string;
	isServerMessage: boolean;
  loadingTC= false;
  termsAndConditionData: any;

  constructor(
    private router: Router,
		private store: Store<fromStore.CEPState>,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		public dialog: MatDialog
  ) { 
    this.store.select(fromStore.getSelectedLanguage)
    .subscribe((language) => {
      this.selectedLanguage = language;
    });

  this.store.select(fromStore.getApiCallingList)
    .subscribe((apiDetails) => {
      this.apiDetails = apiDetails;
    });
  }


  ngOnInit() {
    // server error handling
  this.store.select(fromStore.getServerErrorMessage)
  .subscribe((serverError) => {
    if (serverError != null) {
      if (serverError === 'InvalidCredentials') {
        this.router.navigate(['/Login/login']);
      } else {
        this.serverMessage = serverError;
        if (this.serverMessage) {
          this.isServerMessage = true;
          console.error(this.serverMessage);
        }
      }
      this.serverMessage = null;
    }
  });

  this.store.dispatch(new fromStore.MerchantTermsAndConditions({
    url: this.apiDetails['info'].base_url +
    this.apiDetails['info'].getMerchantTandCList.url,
    method: this.apiDetails['info'].getMerchantTandCList.method,
    body:{}
  }));
  this.store.select(fromStore.merchantTermsAndConditionsLoading)
  .subscribe(isloaded =>{
    if(isloaded == true){
      this.loadingTC =  isloaded;
    }
    else{
      this.loadingTC = false;
    }
  })
  this.store.select(fromStore.merchantTermsAndConditionsLoaded)
  .subscribe(loaded => {
    // this.loadingTC=false;
    if (loaded) {
    this.store.select(fromStore.merchantTermsAndConditionsStatusCode)
    .subscribe(code => {
      if (code === 200) {
        this.store.select(fromStore.merchantTermsAndConditions)
        .subscribe(response =>{          
          for (var value in response){
            if(response[value].isEnabled){
              this.termsAndConditionData = response[value];
            }
          }
        })
      }})
    }})
}


/**
   * Created by Vinita Sharma
   * For closing the popup
   */
  closeDialog() {
    const dialogClose = this.dialog.closeAll();
  }


   /**
 * angular method which gets called on page view initialization
 */
ngAfterViewInit() {
  window.scrollTo(0, 0);
}

}
