<!-- *******************************************************************
*  Component -  Dialog of registration screen & edit profile (if data)
   Created By - Akanksha Jadhav
   Created On - 16/09/2019
   Updated By - Akanksha Jadhav
   Updated On - 1/01/2020
*****************************************************************  -->

<div class="dialogbox-register">
  <!-- ********************** spinner code ********************* -->
  <div *ngIf="isLoading" class="dialog-spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
  <!-- ********************** server error message with image ********************* -->
  <ng-container *ngIf="isServerMessage">
    <mat-icon class="blue-mat-icon font-heading" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>
    <div class="server-error-image-container">
      <img class="server-error-image" src="{{images.pageNotFound}}" loading="lazy" alt="...">
    </div>
    <div class="server-error-message-heading"> {{serverMessage}}</div>
  </ng-container>
  <!-- ********************** Registration page ********************* -->
  <div *ngIf="!isLoading && !isServerMessage" class="dialogbox-register">
    <div class="popup" form>

      <!-- cross button -->
      <ng-container *ngIf="!data">
        <mat-icon class="blue-mat-icon font-heading" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>
      </ng-container>
      <ng-container *ngIf="data">
        <mat-icon class="blue-mat-icon font-heading" aria-hidden="false" (click)="closeUpdateDialog()">clear</mat-icon>
      </ng-container>

      <!-- heading of page -->
      <div class="head-container">
        <ng-container *ngIf="!data">
          <span class="header font-heading">{{registerLabels[selectedLanguage].register | uppercase}}</span>
          <span class="sub-header font-heading">
            {{registerLabels[selectedLanguage].youSeemToBeANewUserPleaseProvideYourDetails}}
          </span>
        </ng-container>
        <ng-container *ngIf="data">
          <span class="header font-heading">{{updatelabels[selectedLanguage].updateUserProfile}}</span>
        </ng-container>
      </div>

      <!---------------------start of the form----------------------------->
      <div class="form-container">
        <form #userForm="ngForm" class="form">
          <div class="registration-field">
            <!-- First name and middle name fields -->
            <div class="field-one">
              <div class="input-field" style="width: 100%;">
                <span class="label">
                  {{registerLabels[selectedLanguage].fullName}}
                  <span style="font-size: 12px;"><span
                      class="red-asterisk">*</span>({{registerLabels[selectedLanguage].required}})</span>
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <input matInput type="text" [formControl]="fName" [(ngModel)]="firstName" autocomplete="off"
                      name="name" maxlength='50' onkeydown="return /[a-zA-Z-_ ]/i.test(event.key)">
                  </ng-container>
                  <ng-container *ngIf="data">
                    <input matInput [formControl]="fName" [(ngModel)]="data.firstName" name="name" autocomplete="off"
                      maxlength='50' onkeydown="return /[a-zA-Z-_ ]/i.test(event.key)">
                  </ng-container>
                  <mat-error style="font-size: 12px; margin-top: 2px;"
                    *ngIf="fName.invalid">{{getFirstNameErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>




              <!-- <div class="input-field">
                <span class="label  ">
                  {{registerLabels[selectedLanguage].middleName}}
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <input matInput type="text" [formControl]="mName" [(ngModel)]="middleName" autocomplete="off"
                      name="name" maxlength='50' onkeydown="return /[a-z]/i.test(event.key)">
                  </ng-container>
                  <ng-container *ngIf="data">
                    <input matInput type="text" [formControl]="mName" [(ngModel)]="data.middleName" name="name"
                      autocomplete="off" maxlength='50' onkeydown="return /[a-z]/i.test(event.key)">
                  </ng-container>
                  <mat-error *ngIf="mName.invalid">{{getMiddleNameErrorMessage()}}</mat-error>
                </mat-form-field>
              </div> -->
            </div>

            <!-- Last name and DOB fields -->
            <div class="field-one">
              <!-- <div class="input-field">
                <span class="label  ">
                  {{registerLabels[selectedLanguage].lastName}}
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <input matInput type="text" [formControl]="lName" [(ngModel)]="lastName" autocomplete="off"
                      name="name" maxlength='50' onkeydown="return /[a-z]/i.test(event.key)">
                  </ng-container>
                  <ng-container *ngIf="data">
                    <input type="text" matInput [formControl]="lName" [(ngModel)]="data.lastName" name="name"
                      autocomplete="off" maxlength='50' onkeydown="return /[a-z]/i.test(event.key)">
                  </ng-container>
                  <mat-error *ngIf="lName.invalid">{{getLastNameErrorMessage()}}</mat-error>
                </mat-form-field>
              </div> -->

              <div class="input-field" style="width: 100%;">
                <span class="label  ">
                  {{registerLabels[selectedLanguage].dateOfBirth}}
                  <span class="font-small"> <span class="red-asterisk">*</span>
                    ({{registerLabels[selectedLanguage].required}})</span>
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <input matInput (click)="picker.open()" [readonly]="true" [min]="minDate" [max]="maxDate"
                      [matDatepicker]="picker" autocomplete="off" [formControl]="dateOfBirth" [(ngModel)]="DOB"
                      name="first">
                  </ng-container>
                  <ng-container *ngIf="data">
                    <input autocomplete="off" [readonly]="true" (click)="picker.open()" [min]="minDate" [max]="maxDate"
                      matInput [matDatepicker]="picker" [formControl]="dateOfBirth" [(ngModel)]="data.DOB">
                  </ng-container>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error style="font-size: 12px; margin-top: 2px;"
                    *ngIf="dateOfBirth.invalid">{{getDobErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- Email and middle Gender fields -->
            <div class="field-one">
              <div class="input-field">
                <!-- <span class="label  ">{{registerLabels[selectedLanguage].emailId}}
                  <span class="font-small"> ({{registerLabels[selectedLanguage].required}})*</span>
                </span> -->
                <span class="label" *ngIf="selectedLanguage === 'mar' ">ई-मेल आयडी
                  <span class="font-small"><span class="red-asterisk">*</span>
                    ({{registerLabels[selectedLanguage].required}})</span>
                </span>
                <span class="label" *ngIf="selectedLanguage === 'en' ">EmailId
                  <span class="font-small"> <span
                      class="red-asterisk">*</span>({{registerLabels[selectedLanguage].required}})</span>
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <input matInput [formControl]="email" name="name" [(ngModel)]="emailId" maxlength='50'>
                  </ng-container>
                  <ng-container *ngIf="data">
                    <input matInput [formControl]="email" autocomplete="off" name="email" [(ngModel)]="data.emailId"
                      maxlength='50'>
                  </ng-container>
                  <mat-error style="font-size: 12px; margin-top: 2px;"
                    *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>

              <div class="input-field">
                <div class="add-bottom-padding">
                  <ng-container *ngIf="!data">
                    <span class="label  ">{{registerLabels[selectedLanguage].gender}}
                      <span class="font-small"><span class="red-asterisk">*</span>
                        ({{registerLabels[selectedLanguage].required}})</span>
                    </span>
                    <mat-radio-group name="gender" [formControl]="gender" [(ngModel)]="genderLabel">
                      <div class="radio-group" style="margin-top: 10%;">
                        <mat-radio-button class="radio" value="M">
                          {{registerLabels[selectedLanguage].male}}</mat-radio-button>
                        <mat-radio-button class="radio" value="F">
                          {{registerLabels[selectedLanguage].female}}</mat-radio-button>
                        <mat-radio-button class="radio" value="O">
                          {{registerLabels[selectedLanguage].other }}</mat-radio-button>
                      </div>
                      <!-- <mat-error class="font-small" *ngIf="gender.invalid">
                        {{getGenderErrorMessage()}}</mat-error> -->
                    </mat-radio-group>
                  </ng-container>
                  <ng-container *ngIf="data">
                    <span class="label  ">{{registerLabels[selectedLanguage].gender}}
                    </span>
                    <mat-radio-group [formControl]="gender" [(ngModel)]="data.gender" color="primary">
                      <br>
                      <div class="radio-group" style="margin-top: 10%;">
                        <mat-radio-button class="radio" value="M">
                          {{registerLabels[selectedLanguage].male}}</mat-radio-button>
                        <mat-radio-button class="radio" value="F">
                          {{registerLabels[selectedLanguage].female}}</mat-radio-button>
                        <mat-radio-button class="radio" value="O">
                          {{registerLabels[selectedLanguage].other }}</mat-radio-button>
                      </div>
                      <mat-error style="font-size: 12px; margin-top: 2px;"
                        *ngIf="gender.invalid">{{getGenderErrorMessage()}}</mat-error>
                    </mat-radio-group>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- Zone and Area fields  ([(value)]="selectedZone"-->
            <!-- <div class="field-one">
              <div class="input-field">
                <span class="label  ">{{registerLabels[selectedLanguage].zone}}
                  <span class="font-small"> ({{registerLabels[selectedLanguage].required}})*</span>
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <mat-select matNativeControl name="zone" [formControl]="zone" [(ngModel)]="zoneValue">
                      <mat-option value="{{zones[0]}}" (click)="onZoneValueChanged(i)"
                        *ngFor="let zones of zoneArray; let i = index">
                        {{zones[0]}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                  <ng-container *ngIf="data">
                    <mat-select matNativeControl name="zone" [formControl]="zone" [(ngModel)]="data.Zone">
                      <mat-option value="{{zones[0]}}" (click)="onZoneValueChanged(i)"
                        *ngFor="let zones of zoneArray; let i = index">
                        {{zones[0]}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                  <mat-error *ngIf="zone.invalid">{{getZoneErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>

              <div class="input-field">
                <span class="label  ">{{registerLabels[selectedLanguage].area}}
                  <span class="font-small" *ngIf="(zoneValue != 'Other' && zoneValue != 'इतर') 
                    || (data && data.Zone!='Other'&& data.Zone!='इतर' && data.Zone != null && data.Zone != undefined)">
                    ({{registerLabels[selectedLanguage].required}})*
                  </span>
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <mat-select matNativeControl name="area" [disabled]="isDisable" *ngIf="zoneValue"
                      [formControl]="Area" [(ngModel)]="area">
                      <mat-option value="{{areaArray[key]}}" *ngFor="let key of convertToArray(); let i = index"
                        (click)="selectedArea(i)">
                        {{areaArray[key]}}
                      </mat-option>
                    </mat-select>
                    <mat-select matNativeControl name="area" *ngIf="!zoneValue" [formControl]="AreaDisable"
                      [(ngModel)]="area">
                      <mat-option value="{{areaArray[key]}}" *ngFor="let key of convertToArray(); let i = index"
                        (click)="selectedArea(i)">
                        {{areaArray[key]}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                  <ng-container *ngIf="data">
                    <mat-select matNativeControl name="area"
                      *ngIf="(data.Zone!='Other'&& data.Zone!='इतर' && data.Zone != null && data.Zone != undefined)"
                      [formControl]="Area" [(ngModel)]="data.area">
                      <mat-option value="{{areaArray[key]}}" *ngFor="let key of convertToArray(); let i = index"
                        (click)="selectedArea(i)">
                        {{areaArray[key]}}
                      </mat-option>
                    </mat-select>
                    <mat-select matNativeControl name="area" *ngIf="(data.Zone == null || data.Zone == undefined)"
                      [formControl]="AreaDisable" [(ngModel)]="data.area">
                      <mat-option value="{{areaArray[key]}}" *ngFor="let key of convertToArray(); let i = index"
                        (click)="selectedArea(i)">
                        {{areaArray[key]}}
                      </mat-option>
                    </mat-select>
                    <mat-select matNativeControl name="area" *ngIf="(data.Zone=='Other'|| data.Zone=='इतर')"
                      [formControl]="AreaDisable" [(ngModel)]="data.area">
                      <mat-option value="{{areaArray[key]}}" *ngFor="let key of convertToArray(); let i = index"
                        (click)="selectedArea(i)">
                        {{areaArray[key]}}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                  <mat-error *ngIf="Area.invalid">{{getAreaErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>
            </div> -->

            <!-- home/building and city fields -->
            <div class="field-one">
              <!-- <div class="input-field">
                <span class="label  ">{{registerLabels[selectedLanguage].homeBuilding}}
                  <span class="font-small" *ngIf="(zoneValue != 'Other' && zoneValue != 'इतर') 
                    || (data && data.Zone!='Other'&& data.Zone!='इतर' && data.Zone != null && data.Zone != undefined)">
                    ({{registerLabels[selectedLanguage].required}})*
                  </span>
                </span>
                 <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <input autocomplete="off" matInput [formControl]="bldgOne" [(ngModel)]="addressOne" name="home"
                      maxlength='50'>
                  </ng-container>
                  <ng-container *ngIf="data">
                    <input autocomplete="off" matInput [formControl]="bldgOne" [(ngModel)]="addressOne" name="home"
                      maxlength='50'>
                  </ng-container>
                  <mat-error *ngIf="bldgOne.invalid">{{getBldgOneErrorMessage()}}</mat-error>
                </mat-form-field> 
              </div> -->

              <div class="input-field" style="width: 100%;">
                <!-- <span class="label" *ngIf="selectedLanguage === 'mar' "> 
                  <span class="font-small"><span class="red-asterisk">*</span> ({{registerLabels[selectedLanguage].required}})</span>
                </span> -->
                <span class="label"> {{ registerLabels[selectedLanguage].address }}
                  <span class="font-small"><span class="red-asterisk">*</span>
                    ({{registerLabels[selectedLanguage].required}})</span>
                </span>
                <mat-form-field appearance="outline" class="form-field" style="position: relative;">
                  <ng-container *ngIf="!data">
                     <input matInput #placesRef="ngx-places" [formControl]="addressName" name="address" [(ngModel)]="address" maxlength='200'
                     ngx-gp-autocomplete [options]="options"
                      (onAddressChange)="handleAddressChange($event)"> 
                    <!-- <app-custom-google-place-autocomplete [formControl]="addressName" name="address" [placeholder]="'Enter Place'" [APIKEY]="'your-api-key-here'"
                      [value]="address" (selectionChanged)="handleAddressChange($event)">
                    </app-custom-google-place-autocomplete> -->
                  </ng-container>                
                     
                  <mat-error style="font-size: 12px; margin-top: 2px;margin-left:-10px"
                  *ngIf="!data && addressName.invalid && addressName.errors.required">
                  {{ registerLabels[selectedLanguage].thisFieldIsRequired }}
                  </mat-error>

                  <ng-container *ngIf="data">
                    <input matInput [formControl]="addressName" [(ngModel)]="data.address" name="address"
                      maxlength='200' ngx-gp-autocomplete [options]="options"
                      (onAddressChange)="handleDataAddressChange($event)"> 
                    <!-- <app-custom-google-place-autocomplete  [placeholder]="'Enter Place'" [APIKEY]="'your-api-key-here'"
                      [value]="data.address[0]" (selectionChanged)="handleDataAddressChange($event)">
                    </app-custom-google-place-autocomplete> -->
                  </ng-container>
                  <mat-error style="font-size: 12px; margin-top: 2px;"
                    *ngIf=" data && data.address.length === 0">{{getAddressErrorMessage()}}</mat-error>
                </mat-form-field>
                <mat-error style="font-size: 12px; margin-top: 15px;" *ngIf="!data && !Addlat">
                  {{ registerLabels[selectedLanguage].youHaveEnteredInvalidCity }}
               </mat-error>
              </div>


            </div>

            <!-- landmark and aadhar cand number fields -->
            <!-- <div class="field-one"> -->
            <!-- <div class="input-field">
                <span class="label  ">
                  {{registerLabels[selectedLanguage].landmark}}
                  <span class="font-small" *ngIf="(zoneValue != 'Other' && zoneValue != 'इतर') 
                    || (data && data.Zone!='Other'&& data.Zone!='इतर' && data.Zone != null && data.Zone != undefined)">
                    ({{registerLabels[selectedLanguage].required}})*
                  </span>
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <input matInput ngx-google-places-autocomplete #placesRef="ngx-places" [formControl]="landmark"
                      autocomplete="off" [(ngModel)]="landmarkInput" name="name">
                  </ng-container>
                  <ng-container *ngIf="data">
                    <input autocomplete="off" matInput ngx-google-places-autocomplete #placesRef="ngx-places"
                      [formControl]="landmark" [(ngModel)]="landmarkInput" name="name">
                  </ng-container>
                  <mat-error *ngIf="landmark.invalid">{{getLandmarkErrorMessage()}}</mat-error>
                </mat-form-field>
              </div> -->

            <!-- <div class="input-field">
                <span class="label  ">{{registerLabels[selectedLanguage].aadharNumber}}</span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container *ngIf="!data">
                    <input matInput [formControl]="aadhar" name="name" autocomplete="off" maxlength='12'
                      [(ngModel)]="aadharCard">
                  </ng-container>
                  <ng-container *ngIf="data">
                    <input autocomplete="off" maxlength='12' name="name" matInput [formControl]="aadhar"
                      [(ngModel)]="aadharCard">
                  </ng-container>
                  <mat-error *ngIf="aadhar.invalid">{{getAadharErrorMessage()}}</mat-error>
                </mat-form-field>
              </div> -->
            <!-- </div> -->

            <!-- password and confirm password fields -->
            <div class="field-one" *ngIf="!data">
              <form class="pass-form" [formGroup]="myForm">
                <div class="input-field">
                  <mat-label>
                    <span class="label  ">
                      {{registerLabels[selectedLanguage].password}}
                      <span class="font-small"> ({{registerLabels[selectedLanguage].required}})*</span>
                    </span>
                  </mat-label>
                  <mat-form-field appearance="outline" class="form-field">
                      <input [type]="hide ? 'password' : 'text'" autocomplete="off" matInput formControlName="password"
                        [(ngModel)]="pass" name="name" maxlength="20">
                      <mat-icon class="eye-mat-icon" matSuffix (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
        
                    </mat-form-field>
                    <mat-error style="font-size: 12px; margin-top: 2px;" *ngIf="myForm.hasError('required','password')">
                      {{registerLabels[selectedLanguage].thisFieldIsRequired}}
                    </mat-error>
                    <mat-error style="font-size: 12px; margin-top: 2px;" *ngIf="myForm.hasError('pattern', 'password')">
                      {{registerLabels[selectedLanguage].passwordLength}}
                    </mat-error>
                  <!-- <mat-label>
                    <span> {{registerLabels[selectedLanguage].password}}</span> <span class="red-asterisk">*</span>
                    <span style="font-size: 12px;">({{registerLabels[selectedLanguage].required}})</span></mat-label>
                  <mat-form-field appearance="outline">
                    <input [type]="hidePassword ? 'password' : 'text'" autocomplete="off" matInput
                      formControlName="password" [(ngModel)]="pass" name="name" maxlength="20">
                    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
                      <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-error style="font-size: 12px; margin-top: 2px;"
                    *ngIf="myForm.hasError('required','password') && pass.length > 0">
                    {{registerLabels[selectedLanguage].thisFieldIsRequired}}
                  </mat-error>
                  <mat-error style="font-size: 12px; margin-top: 2px;" *ngIf="myForm.hasError('pattern', 'password')">
                    {{registerLabels[selectedLanguage].passwordLength}}
                  </mat-error> -->
                </div>
                <!-- <div class="input-field">
                    <span class="label  ">
                      {{registerLabels[selectedLanguage].confirmPassword}}
                      <span class="font-small"> ({{registerLabels[selectedLanguage].required}})*</span>
                    </span>
                    <mat-form-field appearance="outline" class="form-field">
                      <input [type]="cPasshHide ? 'password' : 'text'" autocomplete="off"
                        formControlName="confirmPassword" [errorStateMatcher]="matcher" name="name" matInput
                        maxlength="20">
                      <mat-icon class="eye-mat-icon" matSuffix (click)="cPasshHide = !cPasshHide">
                        {{cPasshHide ? 'visibility_off' : 'visibility'}}
                      </mat-icon>
                      <mat-error *ngIf="myForm.hasError('notSame')">
                        {{registerLabels[selectedLanguage].passwordDoNotMatch}}
                      </mat-error>
                    </mat-form-field>
                  </div> -->
                <div class="input-field">
                  <mat-label>
                    {{registerLabels[selectedLanguage].confirmPassword}} <span class="red-asterisk">*</span><span
                      style="font-size: 12px;">({{registerLabels[selectedLanguage].required}})</span></mat-label>
                  <mat-form-field appearance="outline">
                    <input [type]="cPasshHide ? 'password' : 'text'" autocomplete="off" [(ngModel)]="cpass"
                      formControlName="confirmPassword" [errorStateMatcher]="matcher" name="name" matInput
                      maxlength="20">
                    <button mat-icon-button matSuffix (click)="cPasshHide = !cPasshHide">
                      <mat-icon> {{cPasshHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-error style="font-size: 12px; margin-top: 2px;" *ngIf="myForm.hasError('notSame')">
                    {{registerLabels[selectedLanguage].passwordDoNotMatch}}
                  </mat-error>
                </div>
              </form>
            </div>

            <!-- Driving Licence field -->
            <!-- <div class="field-one">
              <div class="input-field">
                <span class="label  ">{{registerLabels[selectedLanguage].drivingLicenceNumber}}
                </span>
                <mat-form-field appearance="outline" class="form-field">
                  <ng-container>
                    <input *ngIf="!data" autocomplete="off" matInput [formControl]="drivingLicence" name="name"
                      [(ngModel)]="drivingL" maxlength='15'>
                  </ng-container>
                  <ng-container>
                    <input *ngIf="data" autocomplete="off" matInput [formControl]="drivingLicence" name="name"
                      [(ngModel)]="data.drivingLicence" maxlength='15'>
                  </ng-container>
                  <mat-error *ngIf="drivingLicence.invalid">{{getDrivingLicenceErrorMessage()}}
                  </mat-error>
                </mat-form-field>
              </div>

              <referred by field -->
              <!-- <div class="referral">
                <div *ngIf="!data" class="input-fieldReferal">
                  <span class="label  ">{{registerLabels[selectedLanguage].referalCode}}
                  </span>
                  <mat-form-field appearance="outline">
                    <ng-container>
                      <input *ngIf="!data" autocomplete="off" matInput [formControl]="referredByCode" name="name"
                        [(ngModel)]="referrefBy">
                    </ng-container>
                    <mat-error *ngIf="errorReferalCode" class="error-msg">{{getRefferedByErrorMessage()}}</mat-error>
                  </mat-form-field>
                </div>
              </div> -->
            </div>
            <!-- </div>  -->

            <!-- checkbox for accept terms and conditions -->
            <ng-container *ngIf="!data">
              <div class="checkbox" style="margin-top: 25px;text-align: center;">
                <mat-checkbox [(ngModel)]="myModel" [formControl]="checkBox" (change)="onChkChange()">

                </mat-checkbox>

                <!-- <span (click)="openNewWindow()" class="tAC">
                  {{footerLabels[selectedLanguage].termsAndConditions}}
                </span> -->
                <span> </span>
                <a class="pointer" target="_blank" style="color: blue;text-decoration: underline;"
                  href="/assets/terms/terms-and-conditions.pdf">
                  {{footerLabels[selectedLanguage].termsAndConditions}}</a>
                <span> </span>
                <label *ngIf=" selectedLanguage === 'en' "> and </label>
                <label *ngIf=" selectedLanguage === 'mar' "> व </label>
                <span> </span>
                <a class="pointer" style="color: blue;text-decoration: underline;" target="_blank"
                  href="/assets/terms/privacy-policy.pdf">
                  <span *ngIf="selectedLanguage==='en'"> Privacy Policy</span>
                  <span *ngIf="selectedLanguage==='mar'"> गोपनीयता धोरण</span>

                </a>
                <span>
                  {{registerLabels[selectedLanguage].termsAndCondition}}
                </span>
              </div>
              <div *ngIf="loadingTC" class="dialog-spinner">
                <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="valueTC">
                </mat-progress-spinner>
              </div>
              <div class="terms-and-condition" *ngIf="termsAndConditionData && !loadingTC && showTerm">
                <div> {{termsAndConditionData[selectedLanguage].termsAndConditions}}</div>
              </div>



            </ng-container>
          <br>


          <label *ngIf="errMessage">{{errMessage}}</label>

          <!-- ***********************Action buttons for registration and update profile page*****************-->
          <mat-dialog-actions class="buttons">
            <!-- Registeration button -->
            <ng-container *ngIf="!data">
              <mat-dialog-actions class="button-container">
                <button mat-raised-button class="button" (click)="verifyPasswordStrength()"
                  (keyup.enter)="redirectToFeeds()" [disabled]="fName.invalid  || dateOfBirth.invalid ||
                                    email.invalid || gender.invalid  || addressName.invalid|| 
                                     myForm.invalid || checkBox.hasError('required')|| !latLongReceived ">
                  {{registerLabels[selectedLanguage].submit | uppercase }}
                </button>
              </mat-dialog-actions>
            </ng-container>

            <!-- Update Button -->
            <ng-container *ngIf="data">
              <mat-dialog-actions class="button-container">
                <button mat-raised-button class="button" (click)="redirectToUpdate(data)"
                  (keyup.enter)="redirectToUpdate(data)" [disabled]="!userForm.form.valid">
                  {{registerLabels[selectedLanguage].update | uppercase}}
                </button>
              </mat-dialog-actions>
            </ng-container>
          </mat-dialog-actions>
        </form>
      </div>
    </div>
  </div>
</div>