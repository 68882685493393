<!--/*******************************
 * Component -  Cart page
 * Created By - Akshata c
 * Created on - 13/04/2020
 * Updated By - Akshata c
 * Updated On - 13/04/2020
 *************************************/-->

 <div class='overlap-container'>
    <!-- connection lost condition -->
    <div *ngIf='serverMessage && dataNotFoundMsg' class='connectionDiv'>
        <img src={{images.connectionLost}} class='connectionLostImg' loading="lazy" alt="...">
        <span>{{serverMessage}}</span> 
    </div>
    <!-- main page loader -->
    <div *ngIf='(isLoading || isLoadingCart) && !serverMessage' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>
      <!-- data not found error handling -->
      <ng-container *ngIf="dataNotFoundMsg">
        <div class="error-messages">
            <img src="{{images.noDataFoundForCart}}" class="no-data-image-favourite" loading="lazy" alt="..."
            (mouseenter)="speakText(myCartLables[selectedLanguage]['don’tHaveAnyItemInCartToAddPleaseGoToDeals'])"  (mouseleave)="stopSpeaking()">
            <span class="data-not-found">{{myCartLables[selectedLanguage]['don’tHaveAnyItemInCartToAddPleaseGoToDeals']}}</span>
        </div>
    </ng-container>
     <!-- main page -->
     <div  *ngIf='!isLoading && !isLoadingCart  && cartDetails && !dataNotFoundMsg && myCartLables' class='main-container'>
        
        <mat-card class="my-deal-card" >
            <div class="cart-remove-all">
                <div class="deal-name font-heading"
                (mouseenter)="speakText(myCartLables[selectedLanguage].myCart)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].myCart}}</div>
                <div class="spend-loyalty-button font-small" (click) = "clearCart()"
                (mouseenter)="speakText(myCartLables[selectedLanguage].clearCart)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].clearCart}}</div>
            </div>
            <div class="details-of-cart">
                <div class="mini-card" *ngIf = "data && data.length > 0">
                    <div *ngFor = "let data of data">
                        <div class="each-info-card">
                            <div class="my-deal-mini-card">
                                <div class="each-card-detail">
                                    <div class="each-image">
                                        <img class="image" src={{data.image[0]}} loading="lazy" alt="..." (mouseenter)="speakText(data.displayCompanyName)"  (mouseleave)="stopSpeaking()">
                                    </div>
                                    <div class="each-detail">
                                        <div class="heading">
                                            <div class="deal-info-heading font-medium" (mouseenter)="speakText('Go back')"  (mouseleave)="stopSpeaking()">{{data.displayCompanyName}}</div>
                                            <div class="stock font-medium" *ngIf = "data.isQuantityAvailable"
                                            (mouseenter)="speakText(myCartLables[selectedLanguage].inStock)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].inStock}}</div>
                                            <div class="stock font-medium" *ngIf = "!data.isQuantityAvailable"
                                            (mouseenter)="speakText(myCartLables[selectedLanguage].outOfStock)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].outOfStock}}</div>
                                        </div> 
                                        <div class="sub-heading"  (mouseenter)="speakText(data.productTNC)"  (mouseleave)="stopSpeaking()">{{data[selectedLanguage].productName}}</div>
                                        <div class="sub-heading"  (mouseenter)="speakText(data.productTNC)"  (mouseleave)="stopSpeaking()">{{data[selectedLanguage].TandC}}</div>
                                        <!-- <div class="sub-heading">Qty: {{item.quantity}}</div> -->
                                        <div class="rupees" style="display: none;">
                                            <div class="price font-normal" (mouseenter)="speakText(myCartLables[selectedLanguage].price)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].price}}: ₹</div>
                                            <div class="digit font-normal" (mouseenter)="speakText(data.actualAmount)"  (mouseleave)="stopSpeaking()">{{data.actualAmount}}</div>
                                            <div class="digit blurr-font font-normal"  (mouseenter)="speakText(data.price)"  (mouseleave)="stopSpeaking()"><del>₹ {{data.price}}</del></div>
                                            <div class="off-percentage"  (mouseenter)="speakText(myCartLables[selectedLanguage].saved + data.saveAmount)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].saved}} ₹ {{data.saveAmount}}</div>
                                        </div>
                                       <!-- <div *ngIf="userData.loyaltyPoint === 0">
                                        <div class="rupees">
                                            <label class="no-loyalty font-small" 
                                            (mouseenter)="speakText(myCartLables[selectedLanguage].noMoreLoyaltyToSpend)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].noMoreLoyaltyToSpend}}</label>
                                        </div>
                                       </div> -->
                                       <!-- <div *ngIf="userData.loyaltyPoint !==0">
                                        <div class="rupees" *ngIf = "data.isLoyaltySpend">
                                            <label class="loyalty font-small"
                                            (mouseenter)="speakText(myCartLables[selectedLanguage].useYour + data.loyaltySpend + myCartLables[selectedLanguage].loyalty)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].useYour}} {{data.loyaltySpend}} {{myCartLables[selectedLanguage].loyalty}}</label>
                                            <div class="spend-loyalty-button font-small" (click) = "addToCartt(data, 'actualPointsRemove')"
                                            (mouseenter)="speakText(myCartLables[selectedLanguage].removeLoyalty)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].removeLoyalty}}</div>
                                        </div>
                                        <div class="rupees" *ngIf = "!data.isLoyaltySpend">
                                            <label class="loyalty font-small"
                                            (mouseenter)="speakText(myCartLables[selectedLanguage].useYour + data.loyaltySpend + myCartLables[selectedLanguage].loyalty)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].useYour}} {{data.loyaltySpend}} {{myCartLables[selectedLanguage].loyalty}}</label>
                                            <div class="spend-loyalty-button font-small" (click) = "addToCartt(data, 'actualPointsSpend')"
                                            (mouseenter)="speakText(myCartLables[selectedLanguage].spendLoyalty)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].spendLoyalty}}</div>
                                        </div>
                                       </div> -->
                                        <div class="date">
                                                <label class="font-medium"
                                                (mouseenter)="speakText(myCartLables[selectedLanguage].quantity)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].quantity}}</label>
                                                <button class="quantity"  [disabled]="data.availableQuantity == 0" (click) = "addToCartt(data, false)" 
                                                (mouseenter)="speakText('decrease quantity')"  (mouseleave)="stopSpeaking()">-</button>
                                                <label class="count" (mouseenter)="speakText(data.quantity)"  (mouseleave)="stopSpeaking()">{{data.quantity}}</label>
                                                <button class="quantity" [disabled]="data.quantity === 5 " (click) = "addToCartt(data, true)" 
                                                (mouseenter)="speakText('increase quantity')"  (mouseleave)="stopSpeaking()">+</button>
                                        </div>
                                        <div class="remove-button font-small" (click) = "removeFromCart(data)" 
                                        (mouseenter)="speakText(myCartLables[selectedLanguage].remove)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].remove}}</div>
                                    </div>
                                </div>       
                            </div>
                        </div>
                        <div class ="item-horizontal-line"> </div>
                    </div>
                </div>
               <div class = "price-and-tc">
                <div class="coupon-card">
                    <div class="container" style="display: none;">
                        <div class="time-and-addr">
                            <div>
                                <div class="icon-and-heading" >
                                    <img class="icon-image" src="../../../assets/images/profile-drop-down/coupon.png" loading="lazy" alt="...">
                                    <label class="dark-blurr-font font-small" 
                                    (mouseenter)="speakText(myCartLables[selectedLanguage].applyCoupon)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].applyCoupon}}</label>
                                </div> 
                                <div class="time-day font-medium">
                                    <label class="labels" (mouseenter)="speakText(myCartLables[selectedLanguage].priceDetail)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].priceDetail}}</label>
                                </div>
                                <div class="day-timing">
                                    <div class="item-card">
                                    <label class="margin-class dark-blurr-font font-medium"
                                    (mouseenter)="speakText(myCartLables[selectedLanguage].cartTotal)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].cartTotal}}</label>
                                    <label class="margin-class dark-blurr-font font-medium"
                                    (mouseenter)="speakText(myCartLables[selectedLanguage].loyaltyPointDiscount)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].loyaltyPointDiscount}}</label>
                                    <label class="margin-class dark-blurr-font font-medium"
                                    (mouseenter)="speakText(myCartLables[selectedLanguage].orderTotal)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].orderTotal}}</label>
                                    </div>
                                </div>
                            </div>
                                <div class = "price-details"> 
                                    <!-- <div class="apply-button font-small coupon" (click)="applyCoupon(cartDetails)" 
                                    (mouseenter)="speakText(myCartLables[selectedLanguage].apply)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].apply}}</div> -->
                                    <div class = "price-of-items">
                                        <div class="margin-class blurr-font font-medium" 
                                        (mouseenter)="speakText(cartDetails.price)"  (mouseleave)="stopSpeaking()">₹ {{cartDetails.price}}</div>
                                        <div class="margin-class blurr-font font-medium" 
                                        (mouseenter)="speakText(cartDetails.discount)"  (mouseleave)="stopSpeaking()">₹ {{cartDetails.discount}}</div>
                                        <div class="margin-class blurr-font font-medium" 
                                        (mouseenter)="speakText(cartDetails.final_price)"  (mouseleave)="stopSpeaking()">₹ {{cartDetails.final_price}}</div>
                                   </div>
                                </div>  
                        </div>
                        <div class = "horizontal-line"> </div>
                        <div class="time-and-addr">
                            <div>
                                <div class="total-card">
                                    <div class="item-card">
                                    <label class="margin-class dark-blurr-font font-medium" 
                                    (mouseenter)="speakText(myCartLables[selectedLanguage].totalPrice)"  (mouseleave)="stopSpeaking()">{{myCartLables[selectedLanguage].totalPrice}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class = "total-price-details"> 
                                <div class = "total-price">
                                    <div class="font-medium" 
                                    (mouseenter)="speakText(cartDetails.final_price)"  (mouseleave)="stopSpeaking()">₹ {{cartDetails.final_price}}</div> 
                                </div>
                            </div>  
                        </div>
                    </div>
                    </div>
                    <div class="button labels">
                        <button (click)= "startTransaction()" [disabled] = "!cartDetails.isValidForTransaction"
                        (mouseenter)="speakText(myCartLables[selectedLanguage].orderNow)"  (mouseleave)="stopSpeaking()"
                        mat-raised-button class="deal-button labels font-small">{{myCartLables[selectedLanguage].orderNow}}</button>
                    </div>
                 

                <div class="coupon-card margin-coupon-class">
                    <div class="container">
                        <div class="time-and-addr">
                            <div>
                                <div class="icon-and-heading" (mouseenter)="speakText(myCartLables[selectedLanguage].aboutUs)"  (mouseleave)="stopSpeaking()">
                                    <img class="icon-image" src="../../../../../assets/images/more-services/clock.png" loading="lazy" alt="...">
                                    <label class="dark-blurr-font font-small">{{myCartLables[selectedLanguage].aboutUs}}</label>
                                </div>
                                <div class="day-timing">
                                    <div class="item-card" (mouseenter)="speakText(cartDetails.merchant_about_us )"  (mouseleave)="stopSpeaking()">
                                    <label class="margin-class dark-blurr-font font-medium">{{ selectedLanguage === 'en' ? cartDetails.merchant_about_us : cartDetails.merchant_about_us_mar }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
            </div>           
        </mat-card>     
    </div>
</div>

