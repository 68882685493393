<div>
  <h2 class="heading">
    <mat-icon style="color: white;margin-right: 20px;scale: 1.7;">check_circle</mat-icon>
    You have registered successfully !
  </h2>
  <div class="button-div">

    <h2 class="heading1">Welcome on board !</h2>
    <h2 class="heading1">You are ready to procced</h2>


    <button mat-stroked-button color="primary" class="button" (click)="goToHomePage()" >Continue with Website</button>
    <button mat-stroked-button color="accent" class="button" (click)="OpenQRScanPopup()">Continue with Mobile Application</button>
  </div>
</div>