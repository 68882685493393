<!-- /******
 * Component -  Survey card component
 * Created By - Ajay Chavan
 * Updated By - Vinita Sharma
 * Updated On - 28/10/2020
 */ -->
<div class="feed-card">
    <div class="feed-image">
    <!-- <img src="{{ surveyData.image }}" alt="img" /> -->
    <img class="mat-card-image" src="{{ surveyData.image ? surveyData.image[0] : 'https://fastly.picsum.photos/id/1/5000/3333.jpg?hmac=Asv2DU3rA_5D1xSe22xZK47WEAN0wjWeFOhzd13ujW4' }}" loading="lazy" alt="...">
  </div>

    <!-- main content -->
    <div class="feed-content">
        <div class="feed-tag">
            <p *ngIf="!surveyData[selectedLanguage].selectedType"
            (mouseenter)="speakText(landingLables[selectedLanguage].survey)" (mouseleave)="stopSpeaking()"
            >{{ landingLables[selectedLanguage].survey | uppercase }}</p>
            <p *ngIf="surveyData[selectedLanguage].selectedType && selectedLanguage === 'en' "
            (mouseenter)="speakText(surveyData.engagementType)" (mouseleave)="stopSpeaking()"
            >{{ surveyData.engagementType | uppercase }}</p>
            <p *ngIf="surveyData[selectedLanguage].selectedType && selectedLanguage === 'mar' "
            (mouseenter)="speakText('सर्वेक्षण / मत')" (mouseleave)="stopSpeaking()">सर्वेक्षण / मत</p>
        </div>

    <div class="feed-heading" matTooltip="{{surveyData[selectedLanguage].heading}}" 
    (mouseenter)="speakText(surveyData[selectedLanguage].heading)" (mouseleave)="stopSpeaking()">
      <p class="font-card-heading">{{ surveyData[selectedLanguage].heading }}</p>
    </div>

        <div class="feed-desc"
        (mouseenter)="speakText(surveyData[selectedLanguage].description)" (mouseleave)="stopSpeaking()">
            <p class="font-card-desc">{{ surveyData[selectedLanguage].description }}</p>
        </div>

        <div class="survey-link-hading" (click)="onClick()" >
            <!-- <p>{{feedsLabel[selectedLanguage].surveyLink}}</p> -->
            <!-- <button mat-raised-button *ngIf="surveyData[selectedLanguage].selectedType"
            (mouseenter)="speakText('survey type' + surveyData[selectedLanguage].selectedType)" (mouseleave)="stopSpeaking()"
            >{{surveyData[selectedLanguage].selectedType}}</button> -->
            <button mat-raised-button *ngIf="surveyData[selectedLanguage].selectedType" 
            (mouseenter)="speakText(feedsLabel[selectedLanguage].takeSurvey)" (mouseleave)="stopSpeaking()"
            >{{feedsLabel[selectedLanguage].takeSurvey}}</button>
        </div>
        <div class="news-footer">
            <div class="feed-date" (mouseenter)="speakText('survey by' + surveyData.createdBy)" (mouseleave)="stopSpeaking()">{{ newsDate }}</div>
            <div class="news-action-logo">
                <div class="abuse-flag-container">
                    <!-- <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()"  (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)" (mouseleave)="stopSpeaking()">flag</mat-icon> -->
                </div>
                <!-- share options -->
                <!-- <div class="share-logo">
                    <i class="material-icons" [matMenuTriggerFor]="menu" matTooltip="{{landingLables[selectedLanguage].share}}"  (mouseenter)="speakText(landingLables[selectedLanguage].share)" (mouseleave)="stopSpeaking()">
            share
          </i>
                </div> -->
                <!-- <mat-menu #menu="matMenu">
                    <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')"  (mouseenter)="speakText('share on facebook')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.facebookShareImg}}">
            Facebook
          </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on twitter')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.twitterShareImg}}">
            Twitter
          </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')"  (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.linkedinShareImg}}">
            LinkedIn
          </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')"  (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()"> 
            <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
            Whatsapp
          </button>
                </mat-menu> -->

                <div *ngIf="this.userId">
                    <!-- <i
                      class="material-icons favorite-icon"
                      [ngClass]="{ 'active-favorite': surveyData.favourite }"
                      matTooltip="{{landingLables[selectedLanguage].addToFavorites}}"
                      (click)="unMarkFavorite()"
                      (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)" (mouseleave)="stopSpeaking()"
                    >
                      {{ favorite }}
                    </i> -->
                  </div>
            </div>
        </div>
    </div>