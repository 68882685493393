<!-- /*************************************
 * Component - Feedback for deals
 * Created By - Neha r
 * Updated By - Neha r
 * Updated On - 21/04/2020
 ******************************************/ -->

 <div class="dialogbox">
    <div class="popup-header">
      <div class="popup-heading">
        {{feedsLabels[selectedLanguage].writeYourFeedback}}
      </div>
    </div>
    <div class="popup-container">
      <!-- <star-rating-comp [starType]="'svg'" staticColor="ok" size="large" (onClick)="onClick($event)"
        (onRatingChange)="onRatingChange($event)" (onHoverRatingChange)="onHoverRatingChange($event)"
        [showHalfStars]="true" [step]="0.5" [hoverEnabled]="true">
      </star-rating-comp> -->
      
  <div class="rating-container">
    <div class="rating">
      <input type="radio" (click)="changeRating($event)" id="star5" name="rating" value="5" />
      <label class = "full" for="star5" title="Awesome - 5 stars"></label>
      <input type="radio" (click)="changeRating($event)" id="star4half" name="rating" value="4.5" />
      <label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>
      <input type="radio" (click)="changeRating($event)" id="star4" name="rating" value="4" />
      <label class = "full" for="star4" title="Pretty good - 4 stars"></label>
      <input type="radio" (click)="changeRating($event)" id="star3half" name="rating" value="3.5" />
      <label class="half" for="star3half" title="Meh - 3.5 stars"></label>
      <input type="radio" (click)="changeRating($event)" id="star3" name="rating" value="3" />
      <label class = "full" for="star3" title="Meh - 3 stars"></label>
      <input type="radio" (click)="changeRating($event)" id="star2half" name="rating" value="2.5" />
      <label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>
      <input type="radio" (click)="changeRating($event)" id="star2" name="rating" value="2" />
      <label class = "full" for="star2" title="Kinda bad - 2 stars"></label>
      <input type="radio" (click)="changeRating($event)" id="star1half" name="rating" value="1.5" />
      <label class="half" for="star1half" title="Meh - 1.5 stars"></label>
      <input type="radio" (click)="changeRating($event)" id="star1" name="rating" value="1" />
      <label class = "full" for="star1" title="Sucks big time - 1 star"></label>
      <input type="radio" (click)="changeRating($event)" id="starhalf" name="rating" value="0.5" />
      <label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
    </div>
  </div>
      <div class="comment-textarea">
        <textarea matInput placeholder="{{feedsLabels[selectedLanguage].writeYourFeedbackHere}}" rows="5" cols="45"
          [(ngModel)]="feedbackComment"></textarea>
      </div>
    </div>
    <div class="popup-button-container">
      <div class="align-buttons">
        <button mat-flat-button class="popup-reverse-button" (click)="cancleButton()">
          {{feedsLabels[selectedLanguage].notNow}}
        </button>
        <button (click)="hotFeedbackApiCall()" mat-raised-button class="popup-button">
          {{feedsLabels[selectedLanguage].submitFeedback}}
        </button>
      </div>
    </div>
  </div>