<div class="main-container">
  <div class="cross">
    <h1 class="heading">Contact-List</h1>
    <mat-icon class="close-icon" (click)="closePopup()">close</mat-icon>
  </div>
  <div class="container">
    <div class="button-container">
      <button class="custom-button " [ngClass]="{'active-button': showFilteredContactData}"
        (click)="showFilterContact()">
        DMC/HOD/WC
      </button>
      <button class="custom-button second-button" [ngClass]="{'active-button': showContactListData}"
        (click)="showContactList()">
        Other Officer
      </button>
    </div>

    <div class="search-container">
      <input type="text" placeholder="Search by Name/Designation" [(ngModel)]="searchTerm" (input)="filterData()" />
    </div>
  </div>
  <div class="contact-container">
    <ng-container *ngIf="showContactListData ">
      <mat-card class="contact-card" *ngFor="let item of filtercontactList">
        <mat-card-content>
          <div class="contact-info">
            <div class="contact-detail">
              <img src="{{images.name}}" alt="" style="width: 25px;height: 25px;cursor: pointer;">
              <h4 class="wrap">{{item.node.Firstname}} {{item.node.Middlename}} {{item.node.Lastname}}</h4>
            </div>
            <div class="contact-detail">
              <img src="{{images.designation}}" alt="" style="width: 25px;height: 25px;cursor: pointer;">
              <h4 class="wrap"> {{item.node.Designation.toLowerCase()}}</h4>
            </div>
            <div class="contact-detail">
              <img src="{{images.phones}}" alt="" style="width: 30px;height: 30px;cursor: pointer;">
              <h4 class="wrap"><a href="tel:8888888888"> {{item.node.Number}}</a></h4>
            </div>
          </div>
        </mat-card-content>

        <!-- <div class="last-div">
      </div> -->

      </mat-card>
    </ng-container>
    <ng-container *ngIf="showFilteredContactData">
      <mat-card class="contact-card" *ngFor="let item of filtercontact">
        <mat-card-content>
          <div class="contact-info">
            <div class="contact-detail">
              <img src="{{images.name}}" alt="" style="width: 25px;height: 25px;cursor: pointer;">
              <h4>{{item.node.Departmentofficer}}</h4>
            </div>
            <div class="contact-detail">
              <img src="{{images.designation}}" alt="" style="width: 25px;height: 25px;cursor: pointer;">
              <h4>{{item.node.Designation.toLowerCase()}}</h4>
            </div>
            <div class="contact-detail">
              <img src="{{images.Email}}" alt="" style="width: 25px;height: 25px;cursor: pointer;">
              <h4> <a href="mailto:suva@123">{{item.node.Email}}</a></h4>
            </div>
            <div class="contact-detail">
              <img src="{{images.phones}}" alt="" style="width: 30px;height: 30px;cursor: pointer;">
              <h4><a href="tel:8888888888"> {{item.node.Mobile}}</a></h4>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</div>