<!-- /*************************************
 * Component -  detail page survey
 * Created By - Akanksha J
 * Updated By - Akanksha J
 * Updated On - 25/03/2020
 ******************************************/ -->
 <!-- heading -->
 <div class="navigation font-sub-heading" *ngIf="feedLabels && feedsData">
    <p matTooltipClass="engagement-tooltip  " matTooltip="{{feedsData[selectedLanguage]}}" style="width: 80%;"><span [routerLink]="[ '/cep/feeds']" class="feeds-redirection">
           {{feedLabels[selectedLanguage].feed}} </span> > {{feedsData[selectedLanguage]}}
    </p>
  </div>
  <!-- opening survey in iframe -->  
  <ng-container>
    <div *ngIf="loading" class="spinner-loader">                                                                                                                                         
      <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
      </mat-progress-spinner>
    </div>
  <div class="container" *ngIf="!loading && allow">
    <iframe id="survey" [src]='surveylink' frameborder="0" width="100%" height="800"
    webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  </div>

  <div *ngIf="!loading && !allow" [routerLink]="['/cep/feeds/']"></div>
</ng-container>
