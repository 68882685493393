import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as MoreServicesAction from '../action/more-services.action';
import { MoreServicesService } from '../api-services/more-services.service';
import { of } from 'rxjs';

@Injectable()

export class MoreServiesEffects {
	constructor(
		private actions$: Actions,
		private moreServiceService: MoreServicesService
	) { }

	moreServices$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetMoreServices>(MoreServicesAction.GET_MORE_SERVICES),
			switchMap((action) => {
				return this.moreServiceService.getMoreServices(action.payload).pipe(
					map(data => new MoreServicesAction.GetMoreServicesSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetMoreServicesFail(error)))
				);
			})
		)
	);

	categoryServices$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetCategoryServices>(MoreServicesAction.GET_CATEGORY_SERVICES),
			switchMap((action) => {
				return this.moreServiceService.getCategoryServices(action.payload).pipe(
					map(data => new MoreServicesAction.GetCategoryServicesSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetCategoryServicesFail(error)))
				);
			})
		)
	);

	getAllEngagemeng$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetAllEngagementByMerchantId>(MoreServicesAction.GET_ALL_ENGAGEMENT_BY_MERCHANT_ID),
			switchMap((action) => {
				return this.moreServiceService.getAllEngagement(action.payload).pipe(
					map(data => new MoreServicesAction.GetAllEngagementByMerchantIdSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetAllEngagementByMerchantIdFail(error)))
				);
			})
		)
	);

	dealDetailByDealId$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetDealDetailByDealId>(MoreServicesAction.GET_DEAL_DETAIL_BY_DEAL_ID),
			switchMap((action) => {
				return this.moreServiceService.getDealDetailByDealId(action.payload).pipe(
					map(data => new MoreServicesAction.GetDealDetailByDealIdSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetDealDetailByDealIdFail(error)))
				);
			})
		)
	);

	myOrderHistoryId$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetMyOrderHistoryId>(MoreServicesAction.GET_MY_ORDER_HISTORY_ID),
			switchMap((action) => {
				return this.moreServiceService.getMyOrderHistoryId(action.payload).pipe(
					map(data => new MoreServicesAction.GetMyOrderHistoryIdSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetMyOrderHistoryIdFail(error)))
				);
			})
		)
	);

	merchantById$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetMerchantById>(MoreServicesAction.GET_MERCHANT_BY_ID),
			switchMap((action) => {
				return this.moreServiceService.getMerchantById(action.payload).pipe(
					map(data => new MoreServicesAction.GetMerchantByIdSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetMerchantByIdFail(error)))
				);
			})
		)
	);

	userCart$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetUserCart>(MoreServicesAction.GET_USER_CART),
			switchMap((action) => {
				return this.moreServiceService.getUserCart(action.payload).pipe(
					map(data => new MoreServicesAction.GetUserCartSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetUserCartFail(error)))
				);
			})
		)
	);

	itemToCart$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.AddItemToCart>(MoreServicesAction.ADD_ITEM_TO_CART_CART),
			switchMap((action) => {
				return this.moreServiceService.addItemToCart(action.payload).pipe(
					map(data => new MoreServicesAction.AddItemToCartSuccess(data)),
					catchError(error => of(new MoreServicesAction.AddItemToCartFail(error)))
				);
			})
		)
	);

	removeItemFromCart$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.RemoveItemFromCart>(MoreServicesAction.REMOVE_ITEM_FROM_CART),
			switchMap((action) => {
				return this.moreServiceService.removeItemFromCart(action.payload).pipe(
					map(data => new MoreServicesAction.RemoveItemFromCartSuccess(data)),
					catchError(error => of(new MoreServicesAction.RemoveItemFromCartFail(error)))
				);
			})
		)
	);

	myLoyaltyPoints$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetMyLoyaltyPoints>(MoreServicesAction.GET_MY_LOYALTY_POINTS),
			switchMap((action) => {
				return this.moreServiceService.getMyLoyaltyPoints(action.payload).pipe(
					map(data => new MoreServicesAction.GetMyLoyaltyPointsSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetMyLoyaltyPointsFail(error)))
				);
			})
		)
	);

	emptyCart$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.EmptyCart>(MoreServicesAction.EMPTY_CART),
			switchMap((action) => {
				return this.moreServiceService.emptyCart(action.payload).pipe(
					map(data => new MoreServicesAction.EmptyCartSuccess(data)),
					catchError(error => of(new MoreServicesAction.EmptyCartFail(error)))
				);
			})
		)
	);

	applyCoupon$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.ApplyCoupon>(MoreServicesAction.APPLY_COUPON),
			switchMap((action) => {
				return this.moreServiceService.applyCoupon(action.payload).pipe(
					map(data => new MoreServicesAction.ApplyCouponSuccess(data)),
					catchError(error => of(new MoreServicesAction.ApplyCouponFail(error)))
				);
			})
		)
	);

	dealHistoryCart$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.GetDealHistoryDetailsCart>(MoreServicesAction.GET_DEAL_HISTORY_DETAILS_CART),
			switchMap((action) => {
				return this.moreServiceService.dealHistoryCartDetails(action.payload).pipe(
					map(data => new MoreServicesAction.GetDealHistoryDetailsCartSuccess(data)),
					catchError(error => of(new MoreServicesAction.GetDealHistoryDetailsCartFail(error)))
				);
			})
		)
	);

	startTransaction$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.StartTransaction>(MoreServicesAction.START_TRANSACTION),
			switchMap((action) => {
				return this.moreServiceService.startTransaction(action.payload).pipe(
					map(data => new MoreServicesAction.StartTransactionSuccess(data)),
					catchError(error => of(new MoreServicesAction.StartTransactionFail(error)))
				);
			})
		)
	);

	merchantTransactionList$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.MerchantTransactionList>(MoreServicesAction.MERCHANT_TRANSACTION_LIST),
			switchMap((action) => {
				return this.moreServiceService.merchantTransactionList(action.payload).pipe(
					map(merchantTransactions => new MoreServicesAction.MerchantTransactionListSuccess(merchantTransactions)),
					catchError(error => of(new MoreServicesAction.MerchantTransactionListFail(error)))
				);
			})
		)
	);

	addMerchantTransaction$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.AddMerchantTransaction>(MoreServicesAction.ADD_MERCHANT_TRANSACTION),
			switchMap((action) => {
				return this.moreServiceService.addMerchantTransaction(action.payload).pipe(
					map(addMerchantTransactions => new MoreServicesAction.AddMerchantTransactionSuccess(addMerchantTransactions)),
					catchError(error => of(new MoreServicesAction.AddMerchantTransactionFail(error)))
				);
			})
		)
	);

	updateTransactionStatus$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.UpdateTransactionStatus>(MoreServicesAction.UPDATE_TRANSACTION_STATUS),
			switchMap((action) => {
				return this.moreServiceService.updateTransactionStatus(action.payload).pipe(
					map(data => new MoreServicesAction.UpdateTransactionStatusSuccess(data)),
					catchError(error => of(new MoreServicesAction.UpdateTransactionStatusFail(error)))
				);
			})
		)
	);

	addDeals$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.AddDeals>(MoreServicesAction.ADD_DEALS),
			switchMap((action) => {
				return this.moreServiceService.addDeals(action.payload).pipe(
					map(data => new MoreServicesAction.AddDealsSuccess(data)),
					catchError(error => of(new MoreServicesAction.AddDealsFail(error)))
				);
			})
		)
	);

	redeemLoyalty$ = createEffect(() =>
		this.actions$.pipe(
			ofType<MoreServicesAction.RedeemLoyalty>(MoreServicesAction.REDEEM_LOYALTY),
			switchMap((action) => {
				return this.moreServiceService.redeemLoyalty(action.payload).pipe(
					map(data => new MoreServicesAction.RedeemLoyaltySuccess(data)),
					catchError(error => of(new MoreServicesAction.RedeemLoyaltyFail(error)))
				);
			})
		)
	);
	
}
