<!--************************************* 
Created by: Akanksha Jadhav
Created Date: 22/01/2020
updated by: Akanksha Jadhav
Updated Date: 27/12/19
Description: Merchant Registration Form
***************************************** -->
<!-- For Loader -->
<!-- <div *ngIf="isLoading" class="spinner">
  <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div> -->

<!--*********************** merchant-registration form *******************************-->
<ng-container>
  <div class="container" *ngIf="selectedLanguage">
    <!-- header of page -->
    <div class="heading-container">
      <span class="heading font-heading"
        (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].merchantRegistrationForm)"
        (mouseleave)="stopSpeaking()"><strong>{{merchantAndBrandLabels[selectedLanguage].merchantRegistrationForm}}</strong></span>
      <span class="form-status-pending"
        *ngIf="(isMerchantRequest == true) && (isMerchantRejected == false)">Pending</span>
      <span class="form-status-approved" *ngIf="(isMerchant == true)">Approved</span>
      <span class="form-status-rejected" *ngIf="(isMerchantRejected == true)">Rejected</span>
      <br>

      <div class="update-circle" *ngIf="(isNewMerchant == false)" (click)='enableAllInputBox()'>
        <img class="pencil-icon" src={{images.pencilImage}} loading="lazy" alt="...">
      </div>
    </div>

    <!-- card for form -->
    <mat-card class="card">
      <form #userForm="ngForm">
        <ng-container>
          <!-- basic details -->
          <div class="main-field">
            <div class="header" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].basicDetails)"
              (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].basicDetails}}</div>
            <div class="input-container">
              <!-- input form company name -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label"
                    (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].companyName)"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].companyName}}*</mat-label>
                  <input matInput type="text" [formControl]="companyNameControl" [(ngModel)]="companyName"
                    [attr.disabled]="isInEditView ? true : null" autocomplete="off" name="name" maxlength='50'
                    placeholder="Company Name">
                  <mat-error *ngIf="companyNameControl.invalid"
                    (mouseenter)="speakText(getCompanyNameControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getCompanyNameControlErrorMessage()}}</mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Company Name</span>
              </div>

              <!-- input form company name Mar -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label"
                    (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].companyNameMar)"
                    (mouseleave)="stopSpeaking()">{{ merchantAndBrandLabels[selectedLanguage].companyNameMar }}*</mat-label>
                  <input matInput type="text" [formControl]="companyNameControlMar" [(ngModel)]="companyNameMar"
                    [attr.disabled]="isInEditView ? true : null" autocomplete="off" name="name" maxlength='50'
                    placeholder="Company Name">
                  <mat-error *ngIf="companyNameControl.invalid"
                    (mouseenter)="speakText(getCompanyNameControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getCompanyNameMarControlErrorMessage()}}</mat-error>
                </mat-form-field>
                <span class="field-guid">उदा. कंपनीचे नाव.</span>
              </div>

              <!-- input for display name -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label"
                    (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].displayName)"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].displayName}}*</mat-label>
                  <input matInput type="text" [formControl]="displayNameControl" [(ngModel)]="displayName"
                    [attr.disabled]="isInEditView ? true : null" autocomplete="off" name="name" maxlength='50'
                    placeholder="Company Name">
                  <mat-error *ngIf="displayNameControl.invalid"
                    (mouseenter)="speakText(getDisplayNameControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getDisplayNameControlErrorMessage()}}</mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Company Name</span>
              </div>

              <!-- input for display name Mar -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label"
                    (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].displayNameMar)"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].displayNameMar}}*</mat-label>
                  <input matInput type="text" [formControl]="displayNameControlMar" [(ngModel)]="displayNameMar"
                    [attr.disabled]="isInEditView ? true : null" autocomplete="off" name="name" maxlength='50'
                    placeholder="Company Name">
                  <mat-error *ngIf="displayNameControl.invalid"
                    (mouseenter)="speakText(getDisplayNameMARControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getDisplayNameMARControlErrorMessage()}}</mat-error>
                </mat-form-field>
                <span class="field-guid">उदा. कंपनीचे नाव.</span>
              </div>

              <!-- input for company type -->
              <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].companyType)"
                  (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].companyType}}*</mat-label>
                <mat-select [(value)]="selected" matNativeControl name="name" [formControl]="companyTypeControl"
                  [disabled]="isInEditView" [(ngModel)]="companyType">
                  <mat-option value="proprietorship" (mouseenter)="speakText('Proprietorship')"
                    (mouseleave)="stopSpeaking()">Proprietorship</mat-option>
                  <mat-option value="partnership" (mouseenter)="speakText('Partnership')"
                    (mouseleave)="stopSpeaking()">Partnership</mat-option>
                  <mat-option value="pvtLtdCo" (mouseenter)="speakText('Pvt ltd co.')" (mouseleave)="stopSpeaking()">Pvt
                    ltd co.</mat-option>
                  <mat-option value="ltdCo" (mouseenter)="speakText('Ltd co.')" (mouseleave)="stopSpeaking()">Ltd
                    co.</mat-option>
                  <mat-option value="llp" (mouseenter)="speakText('llp')" (mouseleave)="stopSpeaking()"> LLP (limited
                    liability partnership)</mat-option>
                </mat-select>
                <mat-error *ngIf="companyTypeControl.invalid"
                  (mouseenter)="speakText(getCompanyTypeControlErrorMessage())"
                  (mouseleave)="stopSpeaking()">{{getCompanyTypeControlErrorMessage()}}</mat-error>
              </mat-form-field>
              <!--- input for category list -->

              <mat-form-field class="form-field" appearance="outline" *ngIf="selectedLanguage === 'en' ">
                <mat-label class="label" *ngIf="selectedLanguage === 'en' ">Category list*</mat-label>
                <mat-select [(ngModel)]="selectedCategory" name="category" [formControl]="selectedCategoryControl"
                  [disabled]="isInEditView">
                  <mat-option *ngFor="let category of categoryNames" [value]="category">{{ category.en }}</mat-option>
                </mat-select>
                <mat-error *ngIf="selectedCategoryControl.invalid" class="required-error"
                  (mouseenter)="speakText(getSelectedCategoryControlErrorMessage())" (mouseleave)="stopSpeaking()">
                  {{getSelectedCategoryControlErrorMessage()}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="form-field" appearance="outline" *ngIf="selectedLanguage === 'mar' ">
                <mat-label class="label" *ngIf="selectedLanguage === 'mar' "> कॅटेगरी*</mat-label>
                <mat-select [(ngModel)]="selectedCategory" name="category" [formControl]="selectedCategoryControl"
                  [disabled]="isInEditView">
                  <mat-option *ngFor="let category of categoryNames" [value]="category">{{ category.mar }}</mat-option>
                </mat-select>
                <mat-error *ngIf="selectedCategoryControl.invalid" class="required-error"
                  (mouseenter)="speakText(getSelectedCategoryControlErrorMessage())" (mouseleave)="stopSpeaking()">
                  {{getSelectedCategoryControlErrorMessage()}}
                </mat-error>
              </mat-form-field>

            </div>
          </div>


          <!-- head office address -->
          <div class="main-field">
            <div class="header" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].headOfficeAddress)"
              (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].headOfficeAddress}}</div>
            <div class="input-container">
              <!-- address -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field"
                  [attr.disabled]="isInEditView ? true : null">
                  <mat-label class="label" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].address)"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].address}}*</mat-label>
                  <input matInput type="text" [formControl]="headOfficeAddressControl" [(ngModel)]="headOfficeAddress"
                    placeholder="e.g. Address" autocomplete="off" name="name"
                    [attr.disabled]="isInEditView ? true : null">
                  <mat-error *ngIf="headOfficeAddressControl.invalid"
                    (mouseenter)="speakText(getHeadOfficeAddressControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getHeadOfficeAddressControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Address</span>
              </div>


              <!-- city -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].city)"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].city}}*</mat-label>
                  <input matInput type="text" [formControl]="headOfficeCityControl" [(ngModel)]="headOfficeCity"
                    placeholder="e.g. Thane" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="isInEditView ? true : null">
                  <mat-error *ngIf="headOfficeCityControl.invalid"
                    (mouseenter)="speakText(getHeadOfficeCityControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getHeadOfficeCityControlErrorMessage()}}</mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Thane</span>

              </div>


              <!-- pincode -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].pincode)"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].pincode}}*</mat-label>
                  <input matInput type="text" [formControl]="headOfficePincodeControl" [(ngModel)]="headOfficePincode"
                    placeholder="e.g. 410206" autocomplete="off" name="name" maxlength='6'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="headOfficePincodeControl.invalid"
                    (mouseenter)="speakText(getHeadOfficePincodeControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getHeadOfficePincodeControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 410206</span>

              </div>



              <!-- state -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].state)"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].state}}*</mat-label>
                  <input matInput type="text" [formControl]="headOfficeStateControl" [(ngModel)]="headOfficeState"
                    placeholder="e.g. Maharashtra" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="headOfficeStateControl.invalid"
                    (mouseenter)="speakText(getHeadOfficeStateControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getHeadOfficeStateControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Maharashtra</span>
              </div>


              <!-- mobile no -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label" (mouseenter)="speakText(getHeadOfficePincodeControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].mobileNumber}}*</mat-label>
                  <input matInput type="text" [formControl]="headOfficeMobileNoControl" [(ngModel)]="headOfficeMobileNo"
                    placeholder="e.g. 8452886934" autocomplete="off" name="name" maxlength='10'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="headOfficeMobileNoControl.invalid"
                    (mouseenter)="speakText(getHeadOfficePincodeControlErrorMessage())"
                    (mouseleave)="stopSpeaking()">{{getHeadOfficeMobileNoControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 8452886934</span>
              </div>
            </div>
          </div>

          <!-- Owner Details -->
          <div class="main-field">
            <div class="header">
              <span>{{merchantAndBrandLabels[selectedLanguage].ownerDetails}}</span>

            </div>
            <div class="input-container">
              <!-- first name -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].firstName}}*</mat-label>
                  <input matInput type="text" [formControl]="ownerFirstNameControl" [(ngModel)]="ownerFirstName"
                    placeholder="e.g. Stan" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="ownerFirstNameControl.invalid">{{getOwnerFirstNameControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Stan</span>

              </div>


              <!-- middle name -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].middleName}}</mat-label>
                  <input matInput type="text" [formControl]="ownerMiddleNameControl" [(ngModel)]="ownerMiddleName"
                    placeholder="e.g. Oliver" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="ownerMiddleNameControl.invalid">{{getOwnerMiddleNameControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Oliver</span>
              </div>


              <!-- last name -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].lastName}}</mat-label>
                  <input matInput type="text" [formControl]="ownerLastNameControl" [(ngModel)]="ownerLastName"
                    placeholder="e.g. hardy" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="isInEditView ? true : null">
                  <mat-error *ngIf="ownerLastNameControl.invalid">{{getOwnerLastNameControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Hardy</span>

              </div>


              <!-- mobile no -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].mobileNumber}}*</mat-label>
                  <input matInput type="text" [formControl]="ownerMobileNumberControl" [(ngModel)]="ownerMobileNumber"
                    placeholder="e.g. 8452886934" autocomplete="off" name="name" maxlength='10'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="ownerMobileNumberControl.invalid">{{getOwnerMobileNumberControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 8452886934</span>
              </div>


              <!-- tel no -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].telephoneNumber}}</mat-label>
                  <input matInput type="text" [formControl]="ownerTelNoControl" [(ngModel)]="ownerTelNo"
                    placeholder="e.g. 15555551212" autocomplete="off" name="name" maxlength='11'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="ownerTelNoControl.invalid">{{getOwnerTelNoControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 15555551212</span>

              </div>


              <!-- email id -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].emailId}}*</mat-label>
                  <input matInput type="text" [formControl]="ownerEmailIdControl" [(ngModel)]="ownerEmailId"
                    placeholder="e.g. abc&#64;email.com" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="ownerEmailIdControl.invalid">{{getOwnerEmailIdControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. abc&#64;email.com</span>

              </div>

            </div>
          </div>

          <!-- outlet  address -->
          <div class="main-field">
            <div class="header">
              <span>{{merchantAndBrandLabels[selectedLanguage].outletOfficeAddress}}</span>
              <div class="sameAsHeadOffice">
                <mat-checkbox [checked]="isSameAsHeadOffice" [disabled]="isInEditView" (change)="autofillHeadOffice()">
                  <span>{{merchantAndBrandLabels[selectedLanguage].sameAsHeadOfficeAddress}}</span>
                </mat-checkbox>
              </div>
            </div>
            <div class="input-container">
              <!-- address -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].address}}*</mat-label>
                  <input matInput type="text" [formControl]="outletOfficeAddressControl"
                    [(ngModel)]="outletOfficeAddress" placeholder="e.g. office 104" autocomplete="off" name="name"
                    [attr.disabled]="(isSameAsHeadOffice || isInEditView) ? true : null">
                  <mat-error *ngIf="outletOfficeAddressControl.invalid">{{getOutletOfficeAddressControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. office 104</span>
              </div>



              <!-- city -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].city}}*</mat-label>
                  <input matInput type="text" [formControl]="outletOfficeCityControl" [(ngModel)]="outletOfficeCity"
                    placeholder="e.g. Thane" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="(isSameAsHeadOffice || isInEditView) ? true : null">

                  <mat-error *ngIf="outletOfficeCityControl.invalid">{{getOutletOfficeCityControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Thane</span>

              </div>


              <!-- pincode -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].pincode}}*</mat-label>
                  <input matInput type="text" [formControl]="outletOfficePincodeControl"
                    [(ngModel)]="outletOfficePincode" placeholder="e.g. 410206" autocomplete="off" name="name"
                    maxlength='6' [attr.disabled]="(isSameAsHeadOffice || isInEditView) ? true : null">

                  <mat-error *ngIf="outletOfficePincodeControl.invalid">{{getOutletOfficePincodeControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 410206</span>

              </div>



              <!-- state -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].state}}*</mat-label>
                  <input matInput type="text" [formControl]="outletOfficeStateControl" [(ngModel)]="outletOfficeState"
                    placeholder="e.g. Maharashtra" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="(isSameAsHeadOffice || isInEditView) ? true : null">

                  <mat-error *ngIf="outletOfficeStateControl.invalid">{{getOutletOfficeStateControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Maharashtra</span>
              </div>


              <!-- mobile no -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].mobileNumber}}*</mat-label>
                  <input matInput type="text" [formControl]="outletOfficeMobileNoControl" placeholder="e.g. 8453648292"
                    [(ngModel)]="outletOfficeMobileNo" autocomplete="off" name="name" maxlength='10'
                    [attr.disabled]="(isInEditView || isSameAsHeadOffice) ? true : null">

                  <mat-error
                    *ngIf="outletOfficeMobileNoControl.invalid">{{getOutletOfficeMobileNoControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 8453648292</span>

              </div>

            </div>
            <!-- <hr> -->
            <div class="input-container">
              <!-- check box for brand enrollment -->
              <!-- <div>
                <mat-checkbox [checked]="myModel" (change)="onChkChange()"  [disabled]="isInEditView">
                  <span>
                   {{merchantAndBrandLabels[selectedLanguage].doYouWantToEnrollAnyBrand}}
                  </span>
                </mat-checkbox>
              </div> -->

              <!-- dropdown for brand names -->
              <mat-form-field floatLabel="auto" *ngIf="isClicked" appearance="outline" class="form-field">
                <mat-label class="label">Select Brand</mat-label>
                <mat-select matNativeControl name="name" [formControl]="brandIdControl" [(ngModel)]="brandName"
                  [disabled]="isInEditView">
                  <mat-option value="data['displayCompanyName']" (click)="onBrandNameChanged(i)"
                    *ngFor="let data of brandList; let i = index">
                    {{data['displayCompanyName']}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="companyTypeControl.invalid">{{getCompanyTypeControlErrorMessage()}}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- contact person details -->
          <div class="main-field">
            <div class="header">
              <span>{{merchantAndBrandLabels[selectedLanguage].contactPersonAddress}}</span>
              <div class="sameAsHeadOffice">
                <mat-checkbox [checked]="isSameAsOwnerDetails" [disabled]="isInEditView"
                  (change)="autofillOwnerDetails()">
                  <span>{{merchantAndBrandLabels[selectedLanguage].sameAsOwnerDetails}} </span>
                </mat-checkbox>
              </div>
            </div>
            <div class="input-container">
              <!-- First Name -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].firstName}}*</mat-label>
                  <input matInput type="text" [formControl]="contactPersonFirstNameControl" placeholder="e.g. Stan"
                    [(ngModel)]="contactPersonFirstName" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="(isSameAsOwnerDetails ||isInEditView) ? true : null">

                  <mat-error *ngIf="contactPersonFirstNameControl.invalid">
                    {{getContactPersonFirstNameControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Stan</span>

              </div>


              <!-- Middle Name -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].middleName}}</mat-label>
                  <input matInput type="text" [formControl]="contactPersonMiddleNameControl" placeholder="e.g. Oliver"
                    [(ngModel)]="contactPersonMiddleName" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="(isSameAsOwnerDetails ||isInEditView) ? true : null">

                  <mat-error *ngIf="contactPersonMiddleNameControl.invalid">
                    {{getContactPersonMiddleNameControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Oliver</span>

              </div>


              <!-- Last Name -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].lastName}}</mat-label>
                  <input matInput type="text" [formControl]="contactPersonLastNameControl" placeholder="e.g. Hardy"
                    [(ngModel)]="contactPersonLastName" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="(isSameAsOwnerDetails ||isInEditView) ? true : null">

                  <mat-error *ngIf="contactPersonLastNameControl.invalid">
                    {{getContactPersonLastNameControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Hardy</span>

              </div>


              <!-- designation -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].designation}}*</mat-label>
                  <input matInput type="text" [formControl]="contactPersonDesignationControl" placeholder="e.g. Manager"
                    [(ngModel)]="contactPersonDesignation" autocomplete="off" name="name" maxlength='50'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="contactPersonDesignationControl.invalid">
                    {{getContactPersonDesignationControlErrorMessage()}}</mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Manager</span>

              </div>


              <!-- mobile no -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].mobileNumber}}*</mat-label>
                  <input matInput type="text" [formControl]="contactPersonMobileNoControl" placeholder="e.g. 8452886934"
                    [(ngModel)]="contactPersonMobileNo" autocomplete="off" name="name" maxlength='10'
                    [attr.disabled]="(isSameAsOwnerDetails ||isInEditView) ? true : null">

                  <mat-error *ngIf="contactPersonMobileNoControl.invalid">
                    {{getContactPersonMobileNoControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 8452886934</span>

              </div>

              <!-- tel no -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].telephoneNumber}}</mat-label>
                  <input matInput type="text" [formControl]="contactPersonTelNoControl" [(ngModel)]="contactPersonTelNo"
                    placeholder="e.g. 15555551212" autocomplete="off" name="name" maxlength='11'
                    [attr.disabled]="(isSameAsOwnerDetails ||isInEditView) ? true : null">

                  <mat-error *ngIf="contactPersonTelNoControl.invalid">{{getContactPersonTelNoControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 15555551212</span>

              </div>


              <!-- email id -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].emailId}}*</mat-label>
                  <input matInput type="text" [formControl]="contactPersonEmailIdControl"
                    placeholder="e.g. abc&#64;email.com" [(ngModel)]="contactPersonEmailId" autocomplete="off" name="name"
                    maxlength='50' [attr.disabled]="(isSameAsOwnerDetails ||isInEditView) ? true : null">

                  <mat-error
                    *ngIf="contactPersonEmailIdControl.invalid">{{getContactPersonEmailIdControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. abc&#64;email.com</span>

              </div>

            </div>
          </div>


          <!-- Merchant opening and closing time as per the week -->
          <div class="dateTimeContainer">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].operatingTime}}</div>

            <div class="weekday-Heading">
              <div class="weekLabel">{{merchantAndBrandLabels[selectedLanguage].weekday}}</div>
              <div class="startTime-1">{{merchantAndBrandLabels[selectedLanguage].startTime}}</div>
              <div class="endTime">{{merchantAndBrandLabels[selectedLanguage].endTime}}</div>
              <div class="holiday">{{merchantAndBrandLabels[selectedLanguage].holiday}}</div>
            </div>

            <div class="weekday">
              <span class="weekLabel">{{merchantAndBrandLabels[selectedLanguage].monday}}</span>
              <div class="startTime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="monStartTimeControl" [(ngModel)]="monStartTimeModel"
                    name="monStart" [attr.disabled]="isInEditView ? true : null"
                    (change)="updateStartTime('mon', monStartTimeModel)">
                </mat-form-field>
              </div>
              <div class="endtime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="monEndTimeControl" [(ngModel)]="monEndTimeModel"
                    name="monEnd" [attr.disabled]="isInEditView ? true : null"
                    (change)="updateEndTime('mon', monEndTimeModel)">
                </mat-form-field>
              </div>




              <div class="holiday">
                <div class="sameAsHeadOffice">
                  <mat-checkbox [checked]="monHolidayModel" [disabled]="isInEditView" name="monday"
                    [(ngModel)]="monHolidayModel" (change)="HolidayStatus($event, 'mon')">
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="weekday">
              <span class="weekLabel">{{merchantAndBrandLabels[selectedLanguage].tuesday}}</span>
              <div class="startTime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="tueStartTimeControl" [(ngModel)]="tueStartTimeModel"
                    (change)="uncheckHoliday('tue')" name="tueStart" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="endtime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="tueEndTimeControl" [(ngModel)]="tueEndTimeModel"
                    (change)="uncheckHoliday('tue')" name="tueEnd" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="holiday">
                <div class="sameAsHeadOffice">
                  <mat-checkbox [checked]="tueHolidayModel" [disabled]="isInEditView" name="tuesday"
                    [(ngModel)]="tueHolidayModel" (change)="HolidayStatus($event, 'tue')">
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="weekday">
              <span class="weekLabel">{{merchantAndBrandLabels[selectedLanguage].wednesday}}</span>
              <div class="startTime" class="width">
                <mat-form-field class="field" class="field" appearance="outline">
                  <input matInput type="time" [formControl]="wedStartTimeControl" [(ngModel)]="wedStartTimeModel"
                    (change)="uncheckHoliday('wed')" name="wedStart" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="endtime" class="width">
                <mat-form-field class="field" style="width: 100%;" appearance="outline">
                  <input matInput type="time" [formControl]="wedEndTimeControl" [(ngModel)]="wedEndTimeModel"
                    (change)="uncheckHoliday('wed')" name="wedEnd" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="holiday">
                <div class="sameAsHeadOffice">
                  <mat-checkbox [checked]="wedHolidayModel" [disabled]="isInEditView" name="wednesday"
                    [(ngModel)]="wedHolidayModel" (change)="HolidayStatus($event, 'wed')">
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="weekday">
              <span class="weekLabel">{{merchantAndBrandLabels[selectedLanguage].thursday}}</span>
              <div class="startTime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="thuStartTimeControl" [(ngModel)]="thuStartTimeModel"
                    (change)="uncheckHoliday('thu')" name="thuStart" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="endtime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="thuEndTimeControl" [(ngModel)]="thuEndTimeModel"
                    (change)="uncheckHoliday('thu')" name="thuEnd" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="holiday">
                <div class="sameAsHeadOffice">
                  <mat-checkbox [checked]="thuHolidayModel" [disabled]="isInEditView" name="thursday"
                    [(ngModel)]="thuHolidayModel" (change)="HolidayStatus($event, 'thu')">
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="weekday">
              <span class="weekLabel">{{merchantAndBrandLabels[selectedLanguage].friday}}</span>
              <div class="startTime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="friStartTimeControl" [(ngModel)]="friStartTimeModel"
                    (change)="uncheckHoliday('fri')" name="friStart" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="endtime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="friEndTimeControl" [(ngModel)]="friEndTimeModel"
                    (change)="uncheckHoliday('fri')" name="friEnd" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="holiday">
                <div class="sameAsHeadOffice">
                  <mat-checkbox [checked]="friHolidayModel" [disabled]="isInEditView" name="friday"
                    [(ngModel)]="friHolidayModel" (change)="HolidayStatus($event, 'fri')">
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="weekday">
              <span class="weekLabel">{{merchantAndBrandLabels[selectedLanguage].saturday}}</span>
              <div class="startTime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="satStartTimeControl" [(ngModel)]="satStartTimeModel"
                    (change)="uncheckHoliday('sat')" name="satStart" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="endtime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="satEndTimeControl" [(ngModel)]="satEndTimeModel"
                    (change)="uncheckHoliday('sat')" name="satEnd" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="holiday">
                <div class="sameAsHeadOffice">
                  <mat-checkbox [checked]="satHolidayModel" [disabled]="isInEditView" name="saturday"
                    [(ngModel)]="satHolidayModel" (change)="HolidayStatus($event, 'sat')">
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="weekday">
              <span class="weekLabel">{{merchantAndBrandLabels[selectedLanguage].sunday}}</span>
              <div class="startTime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="sunStartTimeControl" [(ngModel)]="sunStartTimeModel"
                    (change)="uncheckHoliday('sun')" name="sunStart" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="endtime" class="width">
                <mat-form-field class="field" appearance="outline">
                  <input matInput type="time" [formControl]="sunEndTimeControl" [(ngModel)]="sunEndTimeModel"
                    (change)="uncheckHoliday('sun')" name="sunEnd" [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
              </div>
              <div class="holiday">
                <div class="sameAsHeadOffice">
                  <mat-checkbox [checked]="sunHolidayModel" [disabled]="isInEditView" name="sunday"
                    [(ngModel)]="sunHolidayModel" (change)="HolidayStatus($event, 'sun')">
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>


          <!-- minimum order value -->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].minimumOrderValue}}</div>
            <div class="input-container">
              <!-- minimum order value -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].minimumOrderValue}}</mat-label>
                  <input matInput type="text" [formControl]="minimumOrderValueControl" [(ngModel)]="minimumOrderValue"
                    placeholder="e.g. 10" autocomplete="off" name="name" maxlength='3'
                    [attr.disabled]="isInEditView ? true : null">
                </mat-form-field>
                <span class="field-guid">e.g. 10</span>

              </div>

            </div>
          </div>

          <!-- AGM-MAP search input box-->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].chooseYourShopoutletLocation}}</div>
            <div class="input-container" *ngIf="!isInEditView">
              <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">Search Location</mat-label>
                <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                  placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off"
                  [attr.disabled]="isInEditView ? true : null" type="text" #search>
              </mat-form-field>
            </div>
            <div class="input-container" *ngIf="isInEditView">
              <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">Search Location</mat-label>
                <input matInput type="text" [(ngModel)]="searchAddress" name="address" [disabled]="true" />
              </mat-form-field>
            </div>
          </div>

          <!-- AGM-MAP to get lat-long-->
          <div class="main-field">
            <div class="input-container">
              <agm-map [zoom]="15" [latitude]="latModel" [longitude]="lngModel">
                <agm-marker [latitude]="latModel" [longitude]="lngModel" [markerDraggable]="true"
                  (dragEnd)="markerDragEnd($event)"></agm-marker>
              </agm-map>
            </div>
          </div>

          <!-- lat long -->
          <div class="main-field">
            <div class="input-container">
              <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].latitude}}*</mat-label>
                <input matInput [formControl]="latitude" [(ngModel)]="latModel" readonly>
              </mat-form-field>
              <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].longitude}}*</mat-label>
                <input matInput [formControl]="longitude" [(ngModel)]="lngModel" readonly>
              </mat-form-field>
            </div>
          </div>



          <!-- About us -->
          <div class="main-field1">
            <div class="input1">
              <div class="header">{{merchantAndBrandLabels[selectedLanguage].aboutUs}}<span class="red">*</span></div>
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <textarea matInput type="text" [formControl]="aboutUsControl" [(ngModel)]="aboutUs" autocomplete="off"
                    placeholder="e.g. We are artists." name="aboutUs"
                    [attr.disabled]="isInEditView ? true : null"></textarea>
                  <mat-error *ngIf="aboutUsControl.invalid">{{getAboutUsControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. We are artists.</span>
              </div>
            </div>

            <div class="input2">
              <div class="header">{{merchantAndBrandLabels[selectedLanguage].aboutUsMar}}<span class="red">*</span>
              </div>
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <textarea matInput type="text" [formControl]="aboutUsControlMarthi" [(ngModel)]="aboutUsMar"
                    autocomplete="off" placeholder="उदा. आम्ही कलाकार आहोत.." name="aboutUsMar"
                    [attr.disabled]="isInEditView ? true : null"></textarea>
                  <mat-error *ngIf="aboutUsControlMarthi.invalid">{{getAboutUsControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">उदा. आम्ही कलाकार आहोत.</span>

              </div>

            </div>
          </div>



          <!-- company particular -->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].companyPraticular}}</div>
            <div class="input-container">
              <!-- permanant address -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].permanantAddress}}*</mat-label>
                  <input matInput type="text" [formControl]="permanantAddressControl" [(ngModel)]="permanantAddress"
                    placeholder="e.g. Thane" autocomplete="off" name="name"
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="permanantAddressControl.invalid">{{getPermanantAddressControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. Thane</span>
              </div>


              <!-- FSS certificate -->
              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].fssCertificate}}</mat-label>
                  <input matInput type="text" [formControl]="fssCertificateControl" [(ngModel)]="fssCertificate"
                    placeholder="e.g. 12216017000287" autocomplete="off" name="name" maxlength='14'
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error
                    *ngIf="fssCertificateControl.invalid">{{getFssCertificateControlErrorMessage()}}</mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 12216017000287</span>

              </div>

              <!-- GST no-->

              <div class="outer-input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].gstNumber)"
                    (mouseleave)="stopSpeaking()">
                    {{merchantAndBrandLabels[selectedLanguage].gstNumber}}<span class="required">*</span>
                  </mat-label>
                  <input matInput type="text" [formControl]="gstNumberControl" [(ngModel)]="gstNumber"
                    autocomplete="off" placeholder="e.g. 27AAAAA0000A1Z5" name="gst" maxlength="15"
                    [attr.disabled]="isInEditView ? true : null">

                  <mat-error *ngIf="gstNumberControl.invalid" class="required-error"
                    (mouseenter)="speakText(getGstNumberControlErrorMessage())" (mouseleave)="stopSpeaking()">
                    {{getGstNumberControlErrorMessage()}}
                  </mat-error>
                </mat-form-field>
                <span class="field-guid">e.g. 27AAAAA0000A1Z5</span>
              </div>
            </div>
          </div>

          <!-- ===========================================================Document Upload section Open====================================================================================== -->
          <!-- Shop act upload -->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].shopActLicense}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfShopActFile($event)"
                  [attr.disabled]="isInEditView ? true : null" accept="application/pdf" /><br><br>
                <span *ngIf="selectedLanguage ==='en' ">Upload pdf up to 1MB</span>
                <span *ngIf="selectedLanguage ==='mar' "> 1MB पर्यंत pdf अपलोड करा</span>
              </div>
              <button class="download-button" *ngIf="ShopActFileAsResponse && !isNewMerchant"
                (click)="downloadShopActDocument()">Download</button>

            </div>
          </div>
          <!-- Udyog Aadhar upload -->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].udyogAadhar}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfUdyogAadharFile($event)"
                  [attr.disabled]="isInEditView ? true : null" accept="application/pdf" /><br><br>
                <span *ngIf="selectedLanguage ==='en' ">Upload pdf up to 1MB</span>
                <span *ngIf="selectedLanguage ==='mar' "> 1MB पर्यंत pdf अपलोड करा</span>
              </div>
              <button class="download-button" *ngIf="UdyogAadharFileAsResponse && !isNewMerchant"
                (click)="downloadUdyogAadharDocument()">Download</button>

            </div>
          </div>
          <!-- Pan upload -->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].panCard}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfPanFile($event)"
                  [attr.disabled]="isInEditView ? true : null" accept="application/pdf" /><br><br>
                <span *ngIf="selectedLanguage ==='en' ">Upload pdf up to 1MB</span>
                <span *ngIf="selectedLanguage ==='mar' "> 1MB पर्यंत pdf अपलोड करा</span>
              </div>
              <button class="download-button" *ngIf="PanFileAsResponse && !isNewMerchant"
                (click)="downloadPanDocument()">Download</button>
            </div>
          </div>
          <!-- Registration Certificate Issued under legal metrology -->
          <div class="main-field">
            <div class="header">
              {{merchantAndBrandLabels[selectedLanguage].registrationCertificateIssuedUnderLegalMetrology}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelect" type="file" (change)="onSelectOfCertificateFile($event)"
                  [attr.disabled]="isInEditView ? true : null" accept="application/pdf" /><br><br>
                <span *ngIf="selectedLanguage ==='en' ">Upload pdf up to 1MB</span>
                <span *ngIf="selectedLanguage ==='mar' "> 1MB पर्यंत pdf अपलोड करा</span>
              </div>
              <button class="download-button" *ngIf="certificateFileAsResponse && !isNewMerchant"
                (click)="downloadLeagalMetrlgDocument()">Download</button>

            </div>
          </div>
          <!-- Intellectual property rights -->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].intellectualPropertyRights}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfPropertyFile($event)"
                  [attr.disabled]="isInEditView ? true : null" accept="application/pdf" /><br><br>
                <span *ngIf="selectedLanguage ==='en' ">Upload pdf up to 1MB</span>
                <span *ngIf="selectedLanguage ==='mar' "> 1MB पर्यंत pdf अपलोड करा</span>
              </div>
              <button class="download-button" *ngIf="propertyFileAsResponse && !isNewMerchant"
                (click)="downloadIntelActualDocument()">Download</button>
            </div>
          </div>
          <!-- GSt upload -->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].gstDocument}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfGstFile($event)"
                  [attr.disabled]="isInEditView ? true : null" accept="application/pdf" /><br><br>
                <span *ngIf="selectedLanguage ==='en' ">Upload pdf up to 1MB</span>
                <span *ngIf="selectedLanguage ==='mar' "> 1MB पर्यंत pdf अपलोड करा</span>
              </div>
              <button class="download-button" *ngIf="GstFileAsResponse && !isNewMerchant"
                (click)="downloadGSTDocument()">Download</button>
            </div>
          </div>

          <!-- Fss upload -->
          <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].fssCertificate}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfFssFile($event)"
                  [attr.disabled]="isInEditView ? true : null" accept="application/pdf" /><br><br>
                <span *ngIf="selectedLanguage ==='en' ">Upload pdf up to 1MB</span>
                <span *ngIf="selectedLanguage ==='mar' "> 1MB पर्यंत pdf अपलोड करा</span>
              </div>
              <button class="download-button" *ngIf="FssFileAsResponse && !isNewMerchant"
                (click)="downloadFSSDocument()">Download</button>

            </div>
          </div>
          <!-- ===========================================================Document Upload section Closed====================================================================================== -->

          <!-- isLoadingUdyogAadhar || isLoadingFss || isLoadingPan || isLoadingGst || isLoadingShopAct -->
          <!-- <div class="spinner-img"  *ngIf="isLoadingUdyogAadhar || isLoadingShopAct">
            <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [value]="valueImg">
            </mat-progress-spinner>
          </div> -->
          <!-- <div class="inputCard">
            <label>Merchant Logo</label>
            <input id="FileInput" #file class="chooseImage" type="file" accept="image/png,image/jpeg,image/jpg" multiple (change)="preview($event)" />
            <label for="FileInput">
                <mat-icon class="circle">add_circle</mat-icon>
            </label>
            <div *ngFor="let url of urls; let i= index">
                <img [src]="url" class="rounded" width="180">
                <button mat-button (click)="removeImage(i)">REMOVE</button>
            </div>
        </div>

        <div class="inputCard">
          <label>upload Image</label>
          <input id="FileInput" #file class="chooseImage" type="file" accept="image/png,image/jpeg,image/jpg" multiple (change)="preview1($event)" />
          <label for="FileInput">
              <mat-icon class="circle">add_circle</mat-icon>
          </label>
          <div *ngFor="let url of urls1; let i= index">
              <img [src]="url1" class="rounded" width="180"> 
              <button mat-button (click)="removeImage1(i)">REMOVE</button>
          </div>

      </div> -->
        </ng-container>

        <div class="main-field" *ngIf="isNewMerchant == true">
          <mat-checkbox class="TandC-checkbox" [checked]="myTCModel" (change)="onTandCChkChange()">
            <div class="condition">
              <span *ngIf="selectedLanguage == 'mar'">
                मला सर्व <span class="TandC" (click)="openTermsAndConditions()">
                  {{merchantAndBrandLabels[selectedLanguage].termsAndConditions}}</span> मान्य आहेत
              </span>

              <span *ngIf="selectedLanguage == 'en'">
                {{merchantAndBrandLabels[selectedLanguage].iAgreeTo}}<span class="TandC"
                  (click)="openTermsAndConditions()">
                  {{merchantAndBrandLabels[selectedLanguage].termsAndConditions}}</span>.
              </span>
            </div>
          </mat-checkbox>
        </div>


        <!-- button action for merchant registration form -->
        <div class="button-container">
          <ng-container *ngIf="isClicked">
            <button mat-raised-button class="register-button" (click)="registreAsMerchant()"
              (keyup.enter)="registreAsMerchant()"
              [disabled]="companyNameControl.invalid || companyTypeControl.invalid ||
            displayNameControl.invalid || headOfficeAddressControl.invalid || headOfficeCityControl.invalid || 
            headOfficePincodeControl.invalid || headOfficeStateControl.invalid || headOfficeMobileNoControl.invalid ||
            ownerFirstNameControl.invalid || ownerMiddleNameControl.invalid || ownerLastNameControl.invalid || 
            ownerMobileNumberControl.invalid || ownerTelNoControl.invalid || 
            ownerEmailIdControl.invalid || outletOfficeAddressControl.invalid || outletOfficeCityControl.invalid ||
            outletOfficePincodeControl.invalid || outletOfficeStateControl.invalid || outletOfficeMobileNoControl.invalid || 
            contactPersonFirstNameControl.invalid || contactPersonMiddleNameControl.invalid || contactPersonLastNameControl.invalid || 
            contactPersonDesignationControl.invalid || contactPersonMobileNoControl.invalid
            || contactPersonTelNoControl.invalid || contactPersonEmailIdControl.invalid || aboutUsControl.invalid || permanantAddressControl.invalid ||
            fssCertificateControl.invalid || gstNumberControl.invalid  
             || latitude.invalid || longitude.invalid || isLoadingPan || (!isTCClicked && isNewMerchant) || !selectedCategory ">

              {{merchantAndBrandLabels[selectedLanguage].submit}}
            </button>
          </ng-container>

          <ng-container *ngIf="!isClicked">
            <button mat-raised-button class="register-button" (click)="registreAsMerchant()"
              (keyup.enter)="registreAsMerchant()" [disabled]="companyNameControl.invalid || companyTypeControl.invalid ||
              displayNameControl.invalid || headOfficeAddressControl.invalid || headOfficeCityControl.invalid || 
              headOfficePincodeControl.invalid || headOfficeStateControl.invalid || headOfficeMobileNoControl.invalid ||
              ownerFirstNameControl.invalid || ownerMiddleNameControl.invalid || ownerLastNameControl.invalid || 
              ownerMobileNumberControl.invalid || ownerTelNoControl.invalid || ownerEmailIdControl.invalid ||
              contactPersonFirstNameControl.invalid || contactPersonMiddleNameControl.invalid || 
              contactPersonLastNameControl.invalid || contactPersonDesignationControl.invalid || contactPersonMobileNoControl.invalid
              || contactPersonTelNoControl.invalid || contactPersonEmailIdControl.invalid || aboutUsControl.invalid ||
              minimumOrderValueControl.invalid || permanantAddressControl.invalid ||
              fssCertificateControl.invalid || gstNumberControl.invalid 
              || latitude.invalid || longitude.invalid || (!isTCClicked && isNewMerchant) || !selectedCategory  ">
              {{merchantAndBrandLabels[selectedLanguage].submit}}
            </button>
          </ng-container>
        </div>
      </form>
    </mat-card>
  </div>
</ng-container>

<!--******************************* brand-registration form ***************************-->
<!-- <ng-container *ngIf="this.option === 'brand-registration'">
  <div class="container">
    header of page -->
<!-- <div class="heading-container">
      <span
        class="heading"><strong>{{merchantAndBrandLabels[selectedLanguage].brandRegistrationForm}}</strong></span><br>
    </div> -->

<!-- card for form -->
<!-- <mat-card class="card">
      <form #userForm="ngForm">
        <ng-container> -->
<!-- basic details -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].basicDetails}}</div>
            <div class="input-container"> -->
<!-- input form company name -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].companyName}}*</mat-label>
                <input matInput type="text" [formControl]="companyNameControl" [(ngModel)]="companyName"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="companyNameControl.invalid">{{getCompanyNameControlErrorMessage()}}</mat-error>
              </mat-form-field> -->

<!-- input for company type -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].companyType}}*</mat-label>
                <mat-select [(value)]="selected" matNativeControl name="name" [formControl]="companyTypeControl"
                  [(ngModel)]="companyType">
                  <mat-option value="proprietorship">Proprietorship</mat-option>
                  <mat-option value="partnership">Partnership</mat-option>
                  <mat-option value="pvtLtdCo">Pvt ltd co.</mat-option>
                  <mat-option value="ltdCo">Ltd co.</mat-option>
                </mat-select>
                <mat-error *ngIf="companyTypeControl.invalid">{{getCompanyTypeControlErrorMessage()}}</mat-error>
              </mat-form-field> -->

<!-- input for display name -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].displayName	}}*</mat-label>
                <input matInput type="text" [formControl]="displayNameControl" [(ngModel)]="displayName"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="displayNameControl.invalid">{{getDisplayNameControlErrorMessage()}}</mat-error>
              </mat-form-field>
            </div>
          </div> -->

<!-- head office address -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].headOfficeAddress}}</div>
            <div class="input-container"> -->
<!-- address -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].address}}*</mat-label>
                <input matInput type="text" [formControl]="headOfficeAddressControl" [(ngModel)]="headOfficeAddress"
                  autocomplete="off" name="name">
                <mat-error *ngIf="headOfficeAddressControl.invalid">{{getHeadOfficeAddressControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- city -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].city}}*</mat-label>
                <input matInput type="text" [formControl]="headOfficeCityControl" [(ngModel)]="headOfficeCity"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="headOfficeCityControl.invalid">{{getHeadOfficeCityControlErrorMessage()}}</mat-error>
              </mat-form-field> -->

<!-- pincode -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].pincode}}*</mat-label>
                <input matInput type="text" [formControl]="headOfficePincodeControl" [(ngModel)]="headOfficePincode"
                  autocomplete="off" name="name" maxlength='6'>
                <mat-error *ngIf="headOfficePincodeControl.invalid">{{getHeadOfficePincodeControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- state -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].state}}*</mat-label>
                <input matInput type="text" [formControl]="headOfficeStateControl" [(ngModel)]="headOfficeState"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="headOfficeStateControl.invalid">{{getHeadOfficeStateControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- mobile no -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].mobileNumber}}*</mat-label>
                <input matInput type="text" [formControl]="headOfficeMobileNoControl" [(ngModel)]="headOfficeMobileNo"
                  autocomplete="off" name="name" maxlength='10'>
                <mat-error *ngIf="headOfficeMobileNoControl.invalid">{{getHeadOfficeMobileNoControlErrorMessage()}}
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->

<!-- Owner Details -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].ownerDetails}}</div>
            <div class="input-container"> -->
<!-- First Name -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].firstName}}*</mat-label>
                <input matInput type="text" [formControl]="ownerFirstNameControl" [(ngModel)]="ownerFirstName"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="ownerFirstNameControl.invalid">{{getOwnerFirstNameControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- Middle Name -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].middleName}}</mat-label>
                <input matInput type="text" [formControl]="ownerMiddleNameControl" [(ngModel)]="ownerMiddleName"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="ownerMiddleNameControl.invalid">{{getOwnerMiddleNameControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- Last Name -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].lastName}}</mat-label>
                <input matInput type="text" [formControl]="ownerLastNameControl" [(ngModel)]="ownerLastName"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="ownerLastNameControl.invalid">{{getOwnerLastNameControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- mobile no -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].mobileNumber}}*</mat-label>
                <input matInput type="text" [formControl]="ownerMobileNumberControl" [(ngModel)]="ownerMobileNumber"
                  autocomplete="off" name="name" maxlength='10'>
                <mat-error *ngIf="ownerMobileNumberControl.invalid">{{getOwnerMobileNumberControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- tel no -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].telephoneNumber}}</mat-label>
                <input matInput type="text" [formControl]="ownerTelNoControl" [(ngModel)]="ownerTelNo"
                  autocomplete="off" name="name" maxlength='11'>
                <mat-error *ngIf="ownerTelNoControl.invalid">{{getOwnerTelNoControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- email id -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].emailId}}*</mat-label>
                <input matInput type="text" [formControl]="ownerEmailIdControl" [(ngModel)]="ownerEmailId"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="ownerEmailIdControl.invalid">{{getOwnerEmailIdControlErrorMessage()}}
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->

<!-- contact person details -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].contactPersonAddress}}</div>
            <div class="input-container"> -->
<!-- First Name -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].firstName}}*</mat-label>
                <input matInput type="text" [formControl]="contactPersonFirstNameControl"
                  [(ngModel)]="contactPersonFirstName" autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="contactPersonFirstNameControl.invalid">
                  {{getContactPersonFirstNameControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- Middle Name -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].middleName}}</mat-label>
                <input matInput type="text" [formControl]="contactPersonMiddleNameControl"
                  [(ngModel)]="contactPersonMiddleName" autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="contactPersonMiddleNameControl.invalid">
                  {{getContactPersonMiddleNameControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- Last Name -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].lastName}}</mat-label>
                <input matInput type="text" [formControl]="contactPersonLastNameControl"
                  [(ngModel)]="contactPersonLastName" autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="contactPersonLastNameControl.invalid">
                  {{getContactPersonLastNameControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- designation -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].designation}}*</mat-label>
                <input matInput type="text" [formControl]="contactPersonDesignationControl"
                  [(ngModel)]="contactPersonDesignation" autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="contactPersonDesignationControl.invalid">
                  {{getContactPersonDesignationControlErrorMessage()}}</mat-error>
              </mat-form-field> -->

<!-- mobile no -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].mobileNumber}}*</mat-label>
                <input matInput type="text" [formControl]="contactPersonMobileNoControl"
                  [(ngModel)]="contactPersonMobileNo" autocomplete="off" name="name" maxlength='10'>
                <mat-error *ngIf="contactPersonMobileNoControl.invalid">
                  {{getContactPersonMobileNoControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- tel no -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].telephoneNumber}}</mat-label>
                <input matInput type="text" [formControl]="contactPersonTelNoControl" [(ngModel)]="contactPersonTelNo"
                  autocomplete="off" name="name" maxlength='11'>
                <mat-error *ngIf="contactPersonTelNoControl.invalid">{{getContactPersonTelNoControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- email id -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].emailId}}*</mat-label>
                <input matInput type="text" [formControl]="contactPersonEmailIdControl"
                  [(ngModel)]="contactPersonEmailId" autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="contactPersonEmailIdControl.invalid">{{getContactPersonEmailIdControlErrorMessage()}}
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->

<!-- operating time -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].operatingTime}}</div>
            <div class="input-container"> -->
<!-- operating days -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].operatingDays}}*</mat-label>
                <input matInput type="text" [formControl]="operatingDaysControl" [(ngModel)]="operatingDays"
                  autocomplete="off" name="name" maxlength='2'>
                <mat-error *ngIf="operatingDaysControl.invalid">{{getOperatingDaysControlErrorMessage()}}</mat-error>
              </mat-form-field> -->

<!-- operating hrs -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].operatingHrs}}*</mat-label>
                <input matInput type="text" [formControl]="operatingHrsControl" [(ngModel)]="operatingHrs"
                  autocomplete="off" name="name" maxlength='2'>
                <mat-error *ngIf="operatingHrsControl.invalid">{{getOperatingHrsControlErrorMessage()}}</mat-error>
              </mat-form-field>
            </div>

            <div class="input-container"> -->
<!-- operating days -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].openWeekDays}}*</mat-label>
                <input matInput type="text" [formControl]="openWeekDaysControl" [(ngModel)]="openWeekDay"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="openWeekDaysControl.invalid">{{getOpenWeekDaysControlErrorMessage()}}</mat-error>
              </mat-form-field> -->

<!-- operating hrs -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].openWeekTiming}}*</mat-label>
                <input matInput type="text" [formControl]="openWeekTimingControl" [(ngModel)]="openWeekTiming"
                  autocomplete="off" name="name" maxlength=50>
                <mat-error *ngIf="openWeekTimingControl.invalid">{{getOpenWeekTimingControlErrorMessage()}}</mat-error>
              </mat-form-field>
            </div>

            <div class="input-container"> -->
<!-- operating days -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].otherWeekDay}}*</mat-label>
                <input matInput type="text" [formControl]="otherWeekDaysControl" [(ngModel)]="otherWeekDays"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="otherWeekDaysControl.invalid">{{getOtherWeekDaysControlErrorMessage()}}</mat-error>
              </mat-form-field> -->

<!-- operating hrs -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].otherWeekTiming}}*</mat-label>
                <input matInput type="text" [formControl]="otherWeekTimingControl" [(ngModel)]="otherWeekTiming"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="otherWeekTimingControl.invalid">{{getOtherWeekTimingControlErrorMessage()}}
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->

<!-- minimum order value -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].minimumOrderValue}}</div>
            <div class="input-container">
              <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].minimumOrderValue}}*</mat-label>
                <input matInput type="text" [formControl]="minimumOrderValueControl" [(ngModel)]="minimumOrderValue"
                  autocomplete="off" name="name" maxlength='50'>
                <mat-error *ngIf="minimumOrderValueControl.invalid">{{getMinimumOrderValueControlErrorMessage()}}
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->


<!-- lat long -->
<!-- <div class="main-field">
              <div class="input-container">
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].latitude}}*</mat-label>
                  <input matInput type="number" [formControl]="latitude" [(ngModel)]="lat" autocomplete="off"
                    name="gst" maxlength='15'>
                  <mat-error *ngIf="latitude.invalid">{{getLatitudeControlErrorMessage()}}</mat-error>
                </mat-form-field>
                <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                  <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].longitude}}*</mat-label>
                  <input matInput type="number" [formControl]="longitude" [(ngModel)]="lng" autocomplete="off"
                    name="gst" maxlength='15'>
                  <mat-error *ngIf="longitude.invalid">{{getLongitudeControlErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>
            </div> -->
<!-- About us -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].aboutUs}}</div>
            <div class="input-container">
              <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <textarea matInput type="text" [formControl]="aboutUsControl" [(ngModel)]="aboutUs" autocomplete="off"
                  name="aboutUs"></textarea>
                <mat-error *ngIf="aboutUsControl.invalid">{{getAboutUsControlErrorMessage()}}
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->
<!-- company particular -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].companyPraticular}}</div>
            <div class="input-container"> -->
<!-- permanant address -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].permanantAddress}}*</mat-label>
                <input matInput type="text" [formControl]="permanantAddressControl" [(ngModel)]="permanantAddress"
                  autocomplete="off" name="name">
                <mat-error *ngIf="permanantAddressControl.invalid">{{getPermanantAddressControlErrorMessage()}}
                </mat-error>
              </mat-form-field> -->

<!-- FSS certificate -->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].fssCertificate}}</mat-label>
                <input matInput type="text" [formControl]="fssCertificateControl" [(ngModel)]="fssCertificate"
                  autocomplete="off" name="name" maxlength='14'>
                <mat-error *ngIf="fssCertificateControl.invalid">{{getFssCertificateControlErrorMessage()}}</mat-error>
              </mat-form-field> -->

<!-- GST no-->
<!-- <mat-form-field floatLabel="auto" appearance="outline" class="form-field">
                <mat-label class="label">{{merchantAndBrandLabels[selectedLanguage].gstNumber}}</mat-label>
                <input matInput type="text" [formControl]="gstNumberControl" [(ngModel)]="gstNumber" autocomplete="off"
                  name="gst" maxlength='15'>
                <mat-error *ngIf="gstNumberControl.invalid">{{getGstNumberControlErrorMessage()}}</mat-error>
              </mat-form-field>
            </div>
          </div> -->
<!-- Registration Certificate Issued under legal metrology -->
<!-- <div class="main-field">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].registrationCertificateIssuedUnderLegalMetrology}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelect" type="file" (change)="onSelectOfCertificateFile($event)"
                  accept="application/pdf" />
              </div>
            </div>
          </div> -->
<!-- Intellectual property rights -->
<!-- <div class="main-field" *ngIf="certificateFileAsResponse">
            <div class="header">{{merchantAndBrandLabels[selectedLanguage].intellectualPropertyRights}}</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfPropertyFile($event)"
                  accept="application/pdf" />
              </div>
            </div>
          </div> -->
<!-- GSt upload -->
<!-- <div class="main-field" >
              <div class="header">GST</div>
              <div class="input-container">
                <div for="FileInput" class="profile-image">
                  <input id="fileSelected" type="file" (change)="onSelectOfGstFile($event)"
                    accept="application/pdf" />
                </div>
              </div>
            </div> -->
<!-- Pan upload -->
<!-- <div class="main-field" >
              <div class="header">PAN</div>
              <div class="input-container">
                <div for="FileInput" class="profile-image">
                  <input id="fileSelected" type="file" (change)="onSelectOfPanFile($event)"
                    accept="application/pdf" />
                </div>
              </div>
            </div> -->
<!-- Fss upload -->
<!-- <div class="main-field" >
            <div class="header">FSS</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfFssFile($event)"
                  accept="application/pdf" />
              </div>
            </div>
            </div> -->
<!-- Shop act upload -->
<!-- <div class="main-field" >
              <div class="header">Shop Act</div>
              <div class="input-container">
                <div for="FileInput" class="profile-image">
                  <input id="fileSelected" type="file" (change)="onSelectOfShopActFile($event)"
                    accept="application/pdf" />
                </div>
              </div>
            </div> -->
<!-- Shop act upload -->
<!-- <div class="main-field" >
            <div class="header">Udyog Aadhar</div>
            <div class="input-container">
              <div for="FileInput" class="profile-image">
                <input id="fileSelected" type="file" (change)="onSelectOfUdyogAadharFile($event)"
                  accept="application/pdf" />
              </div>
            </div>
          </div> isLoadingUdyogAadhar  || isLoadingFss || isLoadingPan || isLoadingGst || isLoadingShopAct -->
<!-- <div class="spinner-img"  *ngIf="  isLoadingProperty || isLoadingCertificate">
              <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [value]="valueImg">
              </mat-progress-spinner>
            </div> -->
<!-- <div class="inputCard">
            <label class="header">Merchant Logo</label>
            <input id="FileInput" #file class="chooseImage" type="file" accept="image/png,image/jpeg,image/jpg" multiple (change)="preview($event)" />
            <label for="FileInput">
                <mat-icon class="circle">add_circle</mat-icon>
            </label>
            <div *ngFor="let url of urls; let i= index">
                <img [src]="url" class="rounded" width="180">
                <button mat-button (click)="removeImage(i)">REMOVE</button>
            </div>
        </div>
 
        <div class="inputCard">
          <label class="header">Upload Banner Image</label>
          <input id="FileInput" #file class="chooseImage" type="file" accept="image/png,image/jpeg,image/jpg" multiple (change)="preview1($event)" />
          <label for="FileInput">
              <mat-icon class="circle">add_circle</mat-icon>
          </label>
          <div *ngFor="let url of urls1; let i= index">
              <img [src]="url1" class="rounded" width="180"> 
              <button mat-button (click)="removeImage1(i)">REMOVE</button>
          </div>

      </div> -->

<!-- </ng-container> -->

<!-- button action for brand registration form -->
<!-- <div class="button-container">
          <ng-container>
            <button mat-raised-button class="register-button" (click)="registerAsBrand()"
              (keyup.enter)="registreAsMerchant()" [attr.disabled]="companyNameControl.invalid || companyTypeControl.invalid ||
            displayNameControl.invalid || headOfficeAddressControl.invalid || headOfficeCityControl.invalid || 
            headOfficePincodeControl.invalid || headOfficeStateControl.invalid || headOfficeMobileNoControl.invalid ||
            ownerFirstNameControl.invalid || ownerMiddleNameControl.invalid || ownerLastNameControl.invalid || 
            ownerMobileNumberControl.invalid || ownerTelNoControl.invalid || ownerEmailIdControl.invalid ||
            contactPersonFirstNameControl.invalid || contactPersonMiddleNameControl.invalid || 
            contactPersonLastNameControl.invalid || contactPersonDesignationControl.invalid || contactPersonMobileNoControl.invalid
            || contactPersonTelNoControl.invalid || contactPersonEmailIdControl.invalid || operatingDaysControl.invalid
            || operatingHrsControl.invalid || openWeekDaysControl.invalid || openWeekTimingControl.invalid || otherWeekDaysControl.invalid
            || otherWeekTimingControl.invalid || aboutUsControl.invalid ||
            minimumOrderValueControl.invalid || permanantAddressControl.invalid ||
            fssCertificateControl.invalid || gstNumberControl.invalid || !certificateFileAsResponse
            || latitude.invalid || longitude.invalid || !isTCClicked">

              {{merchantAndBrandLabels[selectedLanguage].submit}}
            </button>
          </ng-container>
        </div>
      </form>
    </mat-card>
  </div>
</ng-container> -->