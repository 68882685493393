<!--/**************************** 
 * Component -  slider section
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ********************************/ -->

<div class="carousel carousel-slider" *ngIf="postImages">
  <div class="carousel-item" *ngFor="let postImage of filteredBannerImages">
  <!-- <div class="carousel-item"> {{imageLOad()}} -->
    <img src="{{postImage?.images}}" loading="lazy" alt="..." (click)="NavigateTo(postImage)" />
    <!-- <span class="centered" [innerHTML]="feedsImagesText"></span> -->
    <!-- <span class="centered" >{{feedsImagesText}}</span> -->
    </div>
      <div class="carousel-navigation">
        <button class="prev-button" (click)="previousImage()" >
          <span class="material-icons"> arrow_back_ios </span>
        </button>
        <button class="next-button" (click)="nextImage()" [disabled]="currentImageIndex === postImages.length - 1">
          <span class="material-icons"> arrow_forward_ios </span>
        </button>
      </div>
    </div>
  
    
<!-- <div class="carousel carousel-slider" *ngIf="feedsImagesTexts">
  <div class="carousel-item" *ngFor="let postText of feedsImagesTexts">
    <span class="centered">{{feedsImagesText}} suvarna </span>
  
  </div>
</div> -->
