<!-- /*************************************
 * Component -  Display webinar video
 * Created By - Kiran Gajbhiye
 * Updated By - 
 * Updated On - 24/05/2020
 ******************************************/ -->

<!-- loader -->
<div *ngIf="isLoading" class="spinner-loader">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
<!-- main content -->
<!-- <div *ngIf="!isLoading && webinarData">
  </div> -->
<!-- error messages -->

<div *ngIf="webinarData">
    <div *ngIf="!isLoading && webinarData">
        <div class="post-navigation font-sub-heading">
            <p matTooltipClass="engagement-tooltip" matTooltip="{{ webinarData[selectedLanguage].title }}">
                <span [routerLink]="['/cep/feeds/webinar']" class="feeds-redirection font-card-heading">
                    {{ landingLables[selectedLanguage].webinar | uppercase }}</span> > {{
                webinarData[selectedLanguage].title }}
            </p>
        </div>
    
    
        <div class="video-container">
            <iframe [src]='videourl' frameborder="0" (mouseenter)="speakText('click to play video')"
                (mouseleave)="stopSpeaking()"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    
    
        <div class="post-container">
            <!-- <div class="post-image">
            <app-details-page-slider [postImages]="feedsData.image"></app-details-page-slider>
          </div> -->
            <div class="post-details">
                <div class="post-description">
                    <div class="post-type  ">
                        <p>{{landingLables[selectedLanguage].webinar | uppercase}}</p>
                    </div>
                    <div class="post-heading">
                        <p class="font-heading">{{webinarData[selectedLanguage].title}}</p>
                    </div>
                    <div class="post-containt">
                        <p>{{webinarData[selectedLanguage].description }}</p>
                    </div>
    
                </div>
                <div class="post-date-type">
                    <div class="booking-sharing">
                        <div class="sharing">
                            <div class="abuse-flag-container">
                                <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip"
                                    matTooltip="{{landingLables[selectedLanguage].reportAbuse}}"
                                    (click)="reportAsAbuse()">flag</mat-icon>
                            </div>
                            <!-- share options -->
                            <!-- <div (click)="clickOnShare()">
                                <i class="material-icons" [matMenuTriggerFor]="menu"
                                    matTooltip="{{landingLables[selectedLanguage].share}}">
                                    <i class="material-icons">
                                    share
                                </i>
                            </div>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item class="button" (click)="generateShareLink('facebook')">
                                    <img class="social-share-img" src="{{image.facebookShareImg}}">
                                    Facebook
                                </button>
                                <button mat-menu-item class="button" (click)="generateShareLink('twitter')">
                                    <img class="social-share-img" src="{{image.twitterShareImg}}">
                                    Twitter
                                </button>
                                <button mat-menu-item class="button" (click)="generateShareLink('linkedin')">
                                    <img class="social-share-img" src="{{image.linkedinShareImg}}">
                                    LinkedIn
                                </button>
                                <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')">
                                    <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
                                    Whatsapp
                                </button>
                            </mat-menu>
                            <div *ngIf="userId">
                                <i class="pointer material-icons favorite-icon"
                                    [ngClass]="{'active-favorite':webinarData.favourite}"
                                    (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)"
                                    (mouseleave)="stopSpeaking()" (click)="unMarkFavorite()"
                                    matTooltip="{{ landingLables[selectedLanguage].addToFavorites }}">
                                    {{favorite}}
                                </i>
                            </div> -->
                        </div>
    
                    </div>
                    <!-- post by details -->
                    <div class="post-date-venue">
                        <div class="post-date">
                            <label>{{webinarLabels[selectedLanguage].postedBy}}</label>
                            <p>{{postedByUser}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <!-- <hr> -->
            <div class="comments-section">
                <label>{{(feedDetailsResultArray) ? feedDetailsResultArray.length : 0}}
                    &nbsp;{{feedLabels[selectedLanguage].comments}}</label>
                <!-- <form #form = "ngForm"> -->
                <div class="input-comment" style="width: 96%;">
                    <textarea rows="4" cols="50" [maxlength]="200" class="input-box"
                        [(ngModel)]="webinarComment"></textarea><br>
                    <button class="button-post" [class.disabledButton]="!webinarComment" [disabled]="!webinarComment"
                        (click)="addWebinarComment(webinarComment)"
                        mat-button>{{feedLabels[selectedLanguage].post}}</button>
                </div>
                <label
                    class="widthMaxlength">{{feedLabels[selectedLanguage].noteCommentLengthShouldBeLessThan200Characters}}</label>
                <!-- </form> -->
                <br><br>
                <div class="commentListContainer" *ngIf="feedDetailsResultArray">
                    <div class="allComments" *ngFor="let commentResult of feedDetailsResultArray; let i = index">
                        <div class="commentCard">
                            <label class="circle"> 
                                <img class="profile"
                                    *ngIf="feedDetailsResultArray[i]['profilePhoto'] !== null && feedDetailsResultArray[i]['profilePhoto'] !== undefined"
                                    src="{{feedDetailsResultArray[i]['profilePhoto']}}" loading="lazy" alt="...">
                                    <img class="profile" 
                                    *ngIf="!feedDetailsResultArray[i]['profilePhoto']"
                                    src="{{ images.defaultProfilePicture }}" 
                                    loading="lazy" 
                                    alt="Default Profile Image"></label>
                            <div class="userCommentName">
                                <div class="comment" style="width: 100%;">
                                    <div class="position">
                                        <label
                                            *ngIf="commentResult.firstName"><b>{{commentResult.firstName}}&nbsp;</b></label>
                                        <label *ngIf="commentResult.middleName"><b>{{commentResult.middleName}}&nbsp;</b>
                                        </label>
                                        <label *ngIf="commentResult.lastName"><b> {{commentResult.lastName}}</b> </label>
                                        <!-- <label><b>{{commentResult.firstName + commentResult.middleName + commentResult.lastName}}</b> </label> -->
                                        <label
                                            class="commentDate">{{convCommentDateFormat(this.feedDetailsResultArray[i]['updatedAt'])}}
                                        </label>
                                    </div>
                                    <div class="position" *ngIf="commentResult.userId != userId">
                                        <mat-icon class="report pointer" [matMenuTriggerFor]="options"
                                            matTooltipClass="engagement-tooltip">
                                            more_vert</mat-icon>
                                        <mat-menu #options="matMenu">
                                            <button mat-menu-item
                                                matTooltip="{{landingLables[selectedLanguage].reportAbuseAsComment}}"
                                                (click)="reportComment(commentResult, i)">
                                               <!-- {{landingLables[selectedLanguage].reportAbuseAsComment}} -->
                                               <!-- {{this.selectedLanguage === 'en' ? 'Report' : 'अहवाल द्या'}} -->
                                               {{landingLables[selectedLanguage].reportAbuse}}

                                            </button>
                                        </mat-menu>
                                    </div>
                                    <!-- <img src="../assets/images/blog/trash.png" (click)="removeForumComment(this.feedDetailsResultArray[i]['commentId'],this.feedDetailsResultArray[i]['updatedBy'], i)" class="trashImg" *ngIf="this.userId == this.feedDetailsResultArray[i]['userId']"> -->
                                </div>
                                
                                <label class="comment-message" style="word-wrap: break-word;width: 50%;">
                                    {{commentResult.visible ? commentResult.comment : commentResult.comment | slice:0:50}}
                                    <span *ngIf="!commentResult.visible">...</span>
                                    <a href="javascript:;" *ngIf="!commentResult['visible']"
                                        (click)="commentResult.visible = true" class="read-more">Read More</a>
                                    <a href="javascript:;"
                                        *ngIf="commentResult.visible && (commentResult.comment.length > 50)"
                                        (click)="commentResult.visible = false" class="read-more">Read Less</a>
    
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    
    </div>
</div>