<!-- /******
 * Component -  city info block component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */ -->
<div class="city-info-container" *ngIf="typeResult.length">
  <!-- heading -->
  <div class="city-info-heading font-card-heading" [ngStyle]="{'background-color': backgroundColor}">
    {{ cityInfo[selectedLanguage].info | uppercase}}
    <!-- <input type="image" name="a" id="a" [value]="userId" onload="chatbotAPI(document.getElementById('a').value)"> -->
  </div>
  <!-- list of FAQ type -->
  <div class="service-list">
    <div class="city-info-list" *ngFor="let typeResult of typeResult | slice:0:6; let i=index" 
    (click)="redirectToSubTopics(typeResult)">
      <div class="item-icon">
        <img src="{{ typeResult.img }}" loading="lazy" alt="..." />
      </div>
      <div class="item-name  ">
        {{ typeResult[selectedLanguage].type }}
      </div>
    </div>
  </div>
  <div class="city-info-btn">
    <!-- see All button -->
    <div [routerLink]="['/cep/city-info']">
      {{ feedsLabel[selectedLanguage].seeAll | uppercase}}
      <i class="material-icons">
        arrow_forward
      </i>
    </div>
  </div>
</div>
