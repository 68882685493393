import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { DomSanitizer } from '@angular/platform-browser';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-details-page-corona-survey',
    templateUrl: './details-page-corona-survey.component.html',
    styleUrls: ['./details-page-corona-survey.component.scss'],
    standalone: true,
    imports: [NgIf, MatTooltip, RouterLink, MatProgressSpinner]
})
export class DetailsPageCoronaSurveyComponent implements OnInit, OnDestroy, AfterViewInit {
	lng: number;
	lat: number;
	allow = false;
	userData: {};
	mobile: any;
	name: string;
	selectedLanguage: string;
	feedLabels: {};
	feedsData: {};
	surveylink: any;
	loading = true;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;

	constructor(
		private store: Store<fromStore.CEPState>,
		public sanitizer: DomSanitizer,
		private router: Router
	) { }

	ngOnInit() {
		this.store.select(fromStore.getVerifyPasswordResponse)
		.subscribe(response => {
			this.userData = response;
			this.mobile = this.userData['mobile'];
			this.name = this.userData['firstName'] + ' ' + this.userData['middleName'] + ' ' + this.userData['lastName'];
		});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedLabels = labels;
					});
			});

		this.store.select(fromStore.getCoronaSurveyDetails)
			.subscribe(res => {
				this.feedsData = res;
				if (Object.keys(this.feedsData).length < 1) {
					this.router.navigate(['/cep/feeds']);
				} else {
					this.store.select(fromStore.getLatitude)
					.subscribe(lat => {
						this.lat = lat;
						this.store.select(fromStore.getLongitude)
						.subscribe(lng => {
							this.lng = lng;
							if ((this.lat === 0 && this.lng === 0) || (this.lat !== 0 && this.lng !== 0)) {
								if (Object.keys(this.feedsData).length !== 0) {
									this.loading = false;
									this.allow = true;
									if (this.selectedLanguage === 'en') {
										this.surveylink = this.sanitizer.bypassSecurityTrustResourceUrl(this.feedsData['enLink'] + '?n=' + this.name + '&lat=' + this.lat + '&long=' + this.lng + '&mob=' + this.mobile);
									} else if (this.selectedLanguage === 'mar') {
										this.surveylink = this.sanitizer.bypassSecurityTrustResourceUrl(this.feedsData['marLink'] + '?n=' + this.name + '&lat=' + this.lat + '&long=' + this.lng + '&mob=' + this.mobile);
									}
								}
							} else {
								this.loading = true;
								this.router.navigate(['/cep/feeds']);
								window.confirm('Please allow location permission to fill this survey');
							}
						});
					});
				}
		});
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

	ngOnDestroy() {
		this.allow = false;
	}

}
