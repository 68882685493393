<!--************************************* 
Created by: Akanksha Jadhav
Created Date: 27/12/19
updated by: Akanksha Jadhav
Updated Date: 10/01/2020
Description: Governing body screen
***************************************** -->
<!-- loader -->
<div *ngIf="isLoading" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
 <!-- connection lost condition -->
 <div *ngIf="serverErrorMessage" class="connectionDiv">
    <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
    <span>{{serverErrorMessage}}</span>
</div>
<!-- main page -->
<div class="container" *ngIf="!isLoading && isLoaded && !serverErrorMessage">
    <!-- heading -->
    <div class="heading-container">
        <label class="heading font-heading"><strong class="font-fix"
            (mouseenter)="speakText(aboutUsLabels[selectedLanguage].governingBody)"  (mouseleave)="stopSpeaking()">{{aboutUsLabels[selectedLanguage].governingBody}}</strong></label><br>
    </div>
    <!-- card of mayor info -->
    <mat-card class="card" *ngIf="mayorData && selectedLanguage && mayorData[selectedLanguage]">
        <div class="card-content">
            <div class="header-container" *ngIf="aboutUsLabels">
               <strong class="font-fix"
               (mouseenter)="speakText(aboutUsLabels[selectedLanguage].mayorInformation)"  (mouseleave)="stopSpeaking()">{{aboutUsLabels[selectedLanguage].mayorInformation}}:</strong><br>
            </div>
            <div class="sub-container" *ngIf="mayorData && selectedLanguage">
                <div class="Commissioner-image">
                    <img class="image" src="{{mayorData.img}}" loading="lazy" alt="...">
                </div>
                <div class="content">
                    <div class="row" *ngFor="let data of mayorData[selectedLanguage]['info']; let i=index">
                        <div (mouseenter)="speakText(data[0] + data[1])"  (mouseleave)="stopSpeaking()">
                            <div class="label" >{{data[0]}}</div>
                            <div class="description">{{data[1]}}</div>
                        </div>
                        
                    </div><br>
                </div>
            </div>
        </div>
    </mat-card>

    <!-- card of commissioner info -->
    <mat-card class="card" *ngIf="commissionerData && selectedLanguage && commissionerData[selectedLanguage]">
        <div class="card-content">
            <div class="header-container" *ngIf="aboutUsLabels">
               <strong class="font-fix"  (mouseenter)="speakText(aboutUsLabels[selectedLanguage].commissionerInformation)"  (mouseleave)="stopSpeaking()">{{aboutUsLabels[selectedLanguage].commissionerInformation}}:</strong>
                <br>
            </div>
            <div class="sub-container" *ngIf="commissionerData && selectedLanguage">
                <div class="Commissioner-image">
                    <img class="image" src="{{commissionerData.img}}" loading="lazy" alt="...">
                </div>
                <div class="content">
                    <div class="row" *ngFor="let data of commissionerData[selectedLanguage]['info']; let i=index">
                        <div  (mouseenter)="speakText(data[0] + data[1])"  (mouseleave)="stopSpeaking()">
                            <div class="label">{{data[0]}}</div>
                            <div class="description">{{data[1]}}</div>
                        </div>
                        
                    </div><br>
                </div>
            </div>
        </div>
    </mat-card>

    <!-- card of committee info -->
    <mat-card class="card" *ngIf="committeeData && selectedLanguage && committeeData[selectedLanguage]">
        <div class="card-content">
            <div class="header-container">
                <div class="header"><strong class="font-fix"
                    (mouseenter)="speakText(aboutUsLabels[selectedLanguage].committeeInformation)"  (mouseleave)="stopSpeaking()">{{aboutUsLabels[selectedLanguage].committeeInformation}}:</strong></div>
            </div>
            <div class="content" *ngIf="committeeData && selectedLanguage && committeeData[selectedLanguage]">
                <table class="table">
                    <tr (mouseenter)="speakText(aboutUsLabels[selectedLanguage].committeeName + aboutUsLabels[selectedLanguage].chairPerson)"  (mouseleave)="stopSpeaking()">
                        <th>{{aboutUsLabels[selectedLanguage].committeeName}}</th>
                        <th>{{aboutUsLabels[selectedLanguage].chairPerson}}</th>
                    </tr>
                    <tr *ngFor="let data of committeeData[selectedLanguage]['info']">
                        <td (mouseenter)="speakText(data[0])"  (mouseleave)="stopSpeaking()">{{data[0]}}</td>
                        <td (mouseenter)="speakText(data[1])"  (mouseleave)="stopSpeaking()">{{data[1]}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </mat-card>
</div>