import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Images } from 'src/app/shared/config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store/index';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
@Component({
    selector: 'app-near-me-type-popup',
    templateUrl: './near-me-type-popup.component.html',
    styleUrls: ['./near-me-type-popup.component.scss'],
    standalone: true,
    imports: [MatIcon, NgIf, MatButton]
})
export class NearMeTypePopupComponent implements OnInit, OnDestroy {
  images = Images;
  address: any;
  contactNo1: any;
  contactNo2: any;
  description: any;
  selectedLanguage: string;
  type: any;
  showLink: any;
  serverErrorMessage: string;
  isServerErrorMessage: boolean;
  lat: any;
  lng: any;
  initlat = 18.62861241;
	initlng = 73.80478858;
  mobileNumber: any;
  userId: any;
  merchantDetailLabels = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialogRef: MatDialogRef<NearMeTypePopupComponent>,
    private store: Store<fromStore.CEPState>,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    dialogRef.disableClose = true;
  }

  	/**
	 * Created by Prajkta P
	 * For showing the location permission popup and getting the location of user 
	 */
  
	getUserLocation() {
		if (navigator) {
			navigator.geolocation.getCurrentPosition(pos => {
				this.lng = +pos.coords.longitude;
				this.lat = +pos.coords.latitude;
			},
				error => {
					this.lat = this.initlat;
					this.lng = this.initlng;
				});
		}
	}

  ngOnInit() {
  
    this.type = this.data.type;
    if (this.type == 'Bike' || this.type == 'Bicycle') {
      this.showLink = this.type;
    }

    // server error handling
    this.store.select(fromStore.getServerErrMessage)
      .subscribe((serverError) => {
        if (serverError != null) {
          if (serverError == 'InvalidCredentials') {
            this.router.navigate(['/Login/login']);
          }
          else {
            this.serverErrorMessage = serverError
            this.isServerErrorMessage = true
            if (this.serverErrorMessage) {
              console.error(this.serverErrorMessage)
            }
          }
          this.serverErrorMessage = null;
        }
      });

    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((selectedLanguage) => {
        this.selectedLanguage = selectedLanguage
        this.store.select(fromStore.NearMeMapLabels)
          .subscribe((nearMeHeadingLabel) => {
            nearMeHeadingLabel = nearMeHeadingLabel;
            this.address = nearMeHeadingLabel[selectedLanguage].address;
            this.contactNo1 = nearMeHeadingLabel[selectedLanguage].contactNo1;
            this.contactNo2 = nearMeHeadingLabel[selectedLanguage].contactNo2;
            this.mobileNumber = nearMeHeadingLabel[selectedLanguage].mobileNo;
            this.description = nearMeHeadingLabel[selectedLanguage].description;
            this.type = nearMeHeadingLabel[selectedLanguage].type;
          });
      })

      this.store.select(fromStore.getMoreServicesLabels)
			.subscribe(labels => {
				
				this.merchantDetailLabels = labels;
			});
  }

  /**
	 * Created by Akanksha J
	 * For redircting to google map if clicked on cfc direction icon
	 */
	redirectToGoogleMap(directionLatitude, directionLongitude) {
		var lat = this.lat;
		var lng = this.lng;
    // console.log(lat);
    // console.log(lng);
    // console.log('https://www.google.com/maps/dir/?api=1&directionLatitudeorigin='+lat+','+lng+'&destination='+directionLatitude+','+directionLongitude+'&travelmode=driving')

		  window.open('https://www.google.com/maps/dir/?api=1&directionLatitudeorigin='+lat+','+lng+'&destination='+directionLatitude+','+directionLongitude+'&travelmode=driving','_blank' );
	}

  /**
   * created by Prajkta P
   * For redirecting to respective App
   */
  openLinkOnBrowser() {
    window.open("https://play.google.com/store/apps/details?id=app.yulu.bike");
  }
  openLinkOfLeapBike() {
    window.open("https://play.google.com/store/apps/details?id=leap.bike&hl=en_IN");
  }

  /**
   * Creted by prajkta P
   * For closing the popup
   */
  closeDialog() {
    this.dialog.closeAll();
  }
	/**
	 * Created by akshata
	 * redirect to deal listing on click deal now button
	 */
	dealListing(){
    
    this.dialog.closeAll();
		this.router.navigate(['/cep/deals/', this.data.userId]);
	}
  ngOnDestroy() {
    this.serverErrorMessage = null;
  }
}
