/******
 * Component -  city info block component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */
import { Component, OnInit } from "@angular/core";
import { Images } from "../../config/images-config";
import { Store } from "@ngrx/store";
import * as fromStore from "../../../cep/store/index";
import { LocalStorageService } from "../../services/localstorage.service";
import { Router, RouterLink } from '@angular/router';
import { EncrDecrService } from '../../services/encr-decr.service';
import { NgIf, NgStyle, NgFor, UpperCasePipe, SlicePipe } from "@angular/common";

// declare var chatbotAPI: any;
@Component({
    selector: "app-card-city-info",
    templateUrl: "./card-city-info.component.html",
    styleUrls: ["./card-city-info.component.scss"],
    standalone: true,
    imports: [NgIf, NgStyle, NgFor, RouterLink, UpperCasePipe, SlicePipe]
})
export class CardCityInfoComponent implements OnInit {
  image = Images;
  apiDetails: any;
  typeResult: any;
  selectedLanguage: string;
  cityInfo: {};
  feedsLabel: {};
  backgroundColor: any;
  token: string;
  userId: any;

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(private store: Store<fromStore.CEPState>,
    private router: Router,
    private localStorageService: LocalStorageService,
    private encrDecr: EncrDecrService) { }

  ngOnInit() {

    this.store.select(fromStore.getBackgroundColor)
      .subscribe((background) => {
        this.backgroundColor = background['bgColor'];
      });

    this.store.select(fromStore.getToken)
      .subscribe((token) => {
        this.token = token;
        if (this.token) {
          let localUserId = this.localStorageService.getUserId();
          this.userId = this.encrDecr.get(localUserId);
        } else {
          this.userId = null;
        }
      });

      // chatbotAPI(null);
    this.store.select(fromStore.getApiCallingList).subscribe(apiDetails => {
      this.apiDetails = apiDetails;
      if (Object.keys(this.apiDetails).length > 0) {
        this.store.dispatch(
          new fromStore.GetDepartmentTypeDetails({
            url:
              this.apiDetails["info"].base_url +
              this.apiDetails["info"].getFAQType.url,
            method: this.apiDetails["info"].getFAQType.method,
            body: null
          })
        );
      }
    });

    this.store.select(fromStore.getSelectedLanguage).subscribe(language => {
      this.selectedLanguage = language;
      this.store.select(fromStore.getLandingScreenLabels).subscribe(labels => {
        this.cityInfo = labels;
      });
      this.store.select(fromStore.getFeedsLabels).subscribe(labels => {
        this.feedsLabel = labels;
      });
      this.store.select(fromStore.getDepartmentTypeResult).subscribe(labels => {
        this.typeResult = labels;
      });
    });
  }

  /**
   * Craeted by Ajay C
   * For redirecting to e-faq screen
   */
  redirectToSubTopics(selectedDepartment) {
    this.store.dispatch(new fromStore.StoreSelectedDepartment({
      selectedDepartment: selectedDepartment
    }));
    this.router.navigate(['/cep/city-info/department', selectedDepartment.en.type]);
  }
}
