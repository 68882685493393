<!--/*******************************
 * Component -  Group page
 * Created By - Akshata c
 * Created on - 14/05/2020
 * Updated By - Akshata c
 * Updated On - 14/05/2020
 *************************************/-->
<script src="/socket.io/socket.io.js"></script>
<script>
    var socket = io('http://localhost');
    socket.on('connect', function () { });
    socket.on('event', function (data) { });
    socket.on('disconnect', function () { });
</script>

<!-- <script>
  var element = document.getElementById("msgcard");
	element.scrollTop = element.scrollHeight;
    
</script> -->

<div class="group-list-card" *ngIf="!isMobile">
    <!-- main page loader -->
    <div *ngIf='isLoading' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>

    <!-- <div class="dataNotFoundContainer" *ngIf='!allUserGroupData || allUserGroupData.length <= 0'>

        <div class="error-messages">
            <img src="{{images.noDataFound}}" class="no-data-image-favourite" alt="">        
        </div>
        <span class="data-not-found">Data Not Found</span>
    </div> -->

    <div *ngIf='!isLoading && allUserGroupData' class="group-card">
        <div class="group-list">
            <div class="header">
                <label class="header-label font-sub-heading"
                    (mouseenter)="speakText(this.groupsLabels[selectedLanguage].groups)"
                    (mouseleave)="stopSpeaking()">{{this.groupsLabels[selectedLanguage].groups}}</label>
                <!-- <div class="sort-by font-normal">Preferences
                <button mat-button [matMenuTriggerFor]="sort">
                    <img class="sort-image pointer" src="{{images.downArrow}}" alt="">
                </button>
                <mat-menu #sort="matMenu">
                    <ng-container *ngFor="let data of preferences; let i = index">
                        <button mat-menu-item (click)="getGroupByPref(data)">{{data | titlecase}}</button>
                    </ng-container>
                </mat-menu>
            </div> -->
            </div>
            <mat-card class="card">
                <mat-card-content>

                    <div class="noGroupFound" *ngIf="!allUserGroupData || allUserGroupData.length <= 0">
                        <span *ngIf=" selectedLanguage === 'en' "> Data Not Found </span>
                        <span *ngIf=" selectedLanguage === 'mar' "> माहिती उपलब्ध नाही</span>
                    </div>

                    <mat-list *ngFor="let groupData of allUserGroupData">
                        <mat-list-item class="items" (click)="chatDetails(groupData)" style="height:90px">
                            <div class="name-info">
                                <div class="items">
                                    <div *ngIf='isDataLoading' class='spinner-loader-side'>
                                        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode'
                                            [value]='value'>
                                        </mat-progress-spinner>
                                    </div>
                                    <label class="circle"><img class="profile" src="{{groupData.logo}}"
                                            (mouseenter)="speakText(groupData[selectedLanguage].name)"
                                            (mouseleave)="stopSpeaking()"></label>
                                    <div class="inner">
                                        <div class="group-name-time">
                                            <label class="group-name font-small pointer"
                                                matTooltip="{{groupData[selectedLanguage].name}}"
                                                (mouseenter)="speakText(groupData[selectedLanguage].name)"
                                                (mouseleave)="stopSpeaking()">{{groupData[selectedLanguage].name}}</label>
                                            <label *ngIf="groupData.type === 'private'" class="group-type font-small"
                                                (mouseenter)="speakText(groupData.type)"
                                                (mouseleave)="stopSpeaking()">{{groupData.type | uppercase}}</label>
                                            <label *ngIf="groupData.type !== 'private'"
                                                class="group-type color font-small"
                                                (mouseenter)="speakText(groupData.type)"
                                                (mouseleave)="stopSpeaking()">{{groupData.type | uppercase}}</label>
                                        </div>
                                        <div class="group-name-time">
                                            <label
                                                *ngIf="groupData.groupId == eventGroupId && updatedMsg; else elseBlock"
                                                class="last-msg font-small"
                                                (mouseenter)="speakText(groupData.recentName + updatedMsg)"
                                                (mouseleave)="stopSpeaking()">{{groupData.recentName}} :
                                                {{updatedMsg}}</label>
                                            <ng-template #elseBlock>
                                                <label class="last-msg ellipses font-small pointer"
                                                    matTooltip="{{groupData.recentText}}"
                                                    *ngIf="groupData.isAccept == true && groupData.recentText"
                                                    (mouseenter)="speakText(groupData.recentText)"
                                                    (mouseleave)="stopSpeaking()">{{groupData.recentText | slice:0:25}}
                                                    <span *ngIf="groupData.recentText.length > 25">...</span>
                                                </label>
                                            </ng-template>
                                            <div class="recent-chat-time"
                                                *ngIf="groupData.recentTime && groupData.isAccept == true && groupData.recentText">
                                                <label class="time font-small"
                                                    (mouseenter)="speakText(returnedTime(groupData.recentTime))"
                                                    (mouseleave)="stopSpeaking()">{{returnedTime(groupData.recentTime)}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons" *ngIf="groupData.isAccept == false">
                                    <div class="accept-button font-small"
                                        (click)="invitation('Accept', groupData.groupId)"
                                        (mouseenter)="speakText(this.groupsLabels[selectedLanguage].accept)"
                                        (mouseleave)="stopSpeaking()">{{this.groupsLabels[selectedLanguage].accept}}
                                    </div>
                                    <div class="reject-button font-small"
                                        (click)="invitation('Reject', groupData.groupId)"
                                        (mouseenter)="speakText(this.groupsLabels[selectedLanguage].reject)"
                                        (mouseleave)="stopSpeaking()">{{this.groupsLabels[selectedLanguage].reject}}
                                    </div>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>

        <mat-drawer-container class="msg-list">
            <div class="group-image-container" *ngIf="!chatDetails.visible">
                <!-- <img src="{{images.defaultImg}}" alt="defaultimageofgroup" class="defailtimage" > -->
            </div>
            <mat-drawer #drawer class="example-sidenav" position="end" mode="side">
                <div class="group-info">
                    <div class="name-cross">
                        <mat-icon class="cross-button" aria-hidden="false"
                            (mouseenter)="speakText('close group information')" (mouseleave)="stopSpeaking()"
                            (click)="drawer.toggle()">clear</mat-icon>
                        <label class="group-name-heading font-medium" (mouseenter)="speakText('group information')"
                            (mouseleave)="stopSpeaking()"> Group info</label>
                    </div>
                </div>
                <div *ngIf="groupDetailsData" class="profile-card">
                    <label class="profile-group-circle" *ngIf="groupDetailsData['groupDetails'].logo"><img
                            class="group-profile" src="{{groupDetailsData['groupDetails'].logo}}" loading="lazy" alt="..."></label>
                    <label class="group-name font-sub-heading margin-top centered"
                        (mouseenter)="speakText(groupDetailsData?.groupDetails[selectedLanguage]?.name)"
                        (mouseleave)="stopSpeaking()"
                        [ngClass]="{'truncate': isLabelTruncated(groupDetailsData?.groupDetails[selectedLanguage]?.name)}">
                        {{ truncateLabel(groupDetailsData?.groupDetails[selectedLanguage]?.name) }}</label>
                    <label class="group-type font-normal" (mouseenter)="speakText(groupDetailsData.groupDetails.type)"
                        (mouseleave)="stopSpeaking()">{{groupDetailsData.groupDetails.type}}</label>
                </div>
                <div *ngIf="groupDetailsData" class="desc">
                    <!-- <div class="alignment  margin-top">
                    <div class="abuse-flag-container ">
                        <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon>
                    </div>
                </div> -->
                    <div class="alignment margin-top">
                        <div class="row">
                            <div class="name">
                                <label class="group-name font-normal"
                                    (mouseenter)="speakText(this.groupsLabels[selectedLanguage].description)"
                                    (mouseleave)="stopSpeaking()">{{this.groupsLabels[selectedLanguage].description}}:</label>
                            </div>
                            <!-- <div class="abuse">
                            <div class="abuse-flag-container ">
                                <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{this.groupsLabels[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon>
                            </div>
                        </div> -->
                        </div>
                        <label class="group-name align font-small"
                            (mouseenter)="speakText(groupDetailsData.groupDetails[selectedLanguage].description)"
                            (mouseleave)="stopSpeaking()"
                            matTooltip="{{groupDetailsData.groupDetails[selectedLanguage].description}}">{{groupDetailsData.groupDetails[selectedLanguage].description}}</label>
                    </div>
                    <div class="alignment margin-top">
                        <label class="group-name font-normal"
                            (mouseenter)="speakText(this.groupsLabels[selectedLanguage].createdOn)"
                            (mouseleave)="stopSpeaking()">{{this.groupsLabels[selectedLanguage].createdOn}}:</label>
                        <label class="group-name align font-small"
                            (mouseenter)="speakText(groupDetailsData.groupDetails.updatedAt)"
                            (mouseleave)="stopSpeaking()">{{groupDetailsData.groupDetails.updatedAt | date}}</label>
                    </div>
                </div>
                <div class="desc" *ngIf="groupDetailsData">
                    <!-- <div class="alignment margin-top">
                        <label class="group-name font-normal"
                            (mouseenter)="speakText(this.groupsLabels[selectedLanguage].groupsMembers)"
                            (mouseleave)="stopSpeaking()">{{this.groupsLabels[selectedLanguage].groupsMembers}}:</label>
                    </div> -->
                    <div class="group-members" *ngIf="groupDetailsData.groupDetails.memberData.length > 2">
                        <mat-accordion class="accordion-container" #accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class="member" *ngFor="let memberData of groupDetailsData.groupDetails.memberData.slice(0, 2); let i = index;">
                                            <label class="circle">
                                                <img class="profile" *ngIf="memberData.profilePhoto !== null && memberData.profilePhoto !== undefined" [src]="memberData.profilePhoto" loading="lazy" alt="...">
                                                <img class="profile" *ngIf="!memberData.profilePhoto" [src]="images.defaultProfilePicture" loading="lazy" alt="Default Profile Image">
                                            </label>
                                            <label class="group-name font-normal margin-left margin-top" matTooltip="{{memberData.firstName}}"
                                                (mouseenter)="speakText(memberData.firstName)" (mouseleave)="stopSpeaking()">
                                                {{memberData.firstName}}
                                            </label>
                                        </div>
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        {{groupDetailsData.groupDetails.memberData.length}} {{this.groupsLabels[selectedLanguage].groupsMembers}}
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div class="member" *ngFor="let memberData of groupDetailsData.groupDetails.memberData; let i = index;">
                                    <label class="circle">
                                        <img class="profile" *ngIf="memberData.profilePhoto"
                                            [src]="memberData.profilePhoto" loading="lazy" alt="...">
                                        <img class="profile" *ngIf="!memberData.profilePhoto"
                                            [src]="images.defaultProfilePicture" loading="lazy" alt="Default Profile Image">
                                    </label>
                                    <label class="group-name font-normal margin-left margin-top"
                                        matTooltip="{{memberData.firstName}}"
                                        (mouseenter)="speakText(memberData.firstName)"
                                        (mouseleave)="stopSpeaking()">
                                        {{memberData.firstName}}
                                    </label>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                
                <div class="desc align-items">
                    <div *ngIf="groupDetailsData" class="exit-group font-small"
                        (mouseenter)="speakText(this.groupsLabels[selectedLanguage].exitGroup)"
                        (mouseleave)="stopSpeaking()"
                        (click)="invitation('Leave', groupDetailsData.groupDetails.groupId)">
                        {{this.groupsLabels[selectedLanguage].exitGroup}}</div>
                </div>
            </mat-drawer>
            <div class="group-header-name pointer" *ngIf="groupDetailsData" (click)="drawer.toggle()">
                <label class="circle-profile"><img class="profile" src="{{groupDetailsData.groupDetails.logo}}" loading="lazy" alt="..."></label>
                <div class="inner">
                    <label class="group-name font-medium pointer"
                        matTooltip="{{groupDetailsData.groupDetails[selectedLanguage].name}}"
                        (mouseenter)="speakText(groupDetailsData.groupDetails[selectedLanguage].name)"
                        (mouseleave)="stopSpeaking()">{{groupDetailsData.groupDetails[selectedLanguage].name}}</label>
                    <label class="last-msg pointer font-normal"
                        (mouseenter)="speakText(groupsLabels[selectedLanguage].tapHereForGroupInfo)"
                        (mouseleave)="stopSpeaking()"
                        matTooltip="{{groupsLabels[selectedLanguage].tapHereForGroupInfo}}">{{this.groupsLabels[selectedLanguage].tapHereForGroupInfo}}</label>
                </div>
            </div>


            <mat-card class="msg-card" *ngIf="groupDetailsData">
                <div [ngClass]="{'width-screen': lengthOfChat < 5 }">
                    <!-- "*ngIf='isLoadingChat' <img src="../../../../../assets/images/more-services/default-group-image.png" alt="defaultimageofgroup" class="defailtimage" > -->

                    <div #scrollBottom id="msgcard">
                        <div class="loader-pagination">
                            <div *ngIf='isNewChatLoading' class='spinner-loader-scroll'>
                                <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode'
                                    [value]='valueChat'>
                                </mat-progress-spinner>
                            </div>
                        </div>
                        <ng-container *ngIf='!isNewChatLoading'>
                            <div class="load-more-button-allignment" *ngIf="lengthOfChat==100">
                                <button mat-button (click)="onScroll()" class="load-more-button"
                                    (mouseenter)="speakText('load more chats')" (mouseleave)="stopSpeaking()">
                                    Show more
                                </button>
                            </div>
                        </ng-container>

                        <div id="downarrow" *ngIf="isAlreadyBottom == false" (click)="goToBottom()"
                            (mouseenter)="speakText('go to bottom of chat')" (mouseleave)="stopSpeaking()">
                            <img src="{{images.downArrow}}" alt="go to bottom" loading="lazy" >
                        </div>

                        <div *ngFor="let chatDetails of chatArrayOfGroup">
                            
                            <div class="sender-div">
                                <div class="sender-text-box" *ngIf="chatDetails.userId == userId">
                                    <div class="conversation-container">
                                        <div class="message sent">
                                            
                                                <mat-icon [matMenuTriggerFor]="replyMenu">more_vert</mat-icon>
                                                <mat-menu style="width: 100%;padding: 0%;margin: 0%;" #replyMenu="matMenu">
                                                    <button mat-button style="width: 100%;justify-content: space-between;" (click)="openReplyDialog(chatDetails)">
                                                        <mat-icon style="color: grey;cursor: pointer;">reply</mat-icon>  Reply
                                                    </button>
                                                </mat-menu>
                                        
                                            <div class="reply-to" *ngIf="chatDetails.repliedData && chatDetails.repliedData[0]">
                                                
                                                <div class="name-msg-text-1">
                                                    Replyto:
                                                    <label
                                                        (mouseenter)="speakText(chatDetails.firstName + chatDetails.lastName)"
                                                        (mouseleave)="stopSpeaking()">{{chatDetails.repliedData[0].firstName}}
                                                        {{chatDetails.repliedData[0].lastName}}</label>
                                                </div>
                                                <div *ngIf="chatDetails.repliedData[0] && chatDetails.repliedData[0].text">
                                                    {{chatDetails.repliedData[0].text}}
                                                </div>
                                                <div *ngIf="chatDetails.repliedData[0] && chatDetails.repliedData[0].attachment && chatDetails.repliedData[0].attachment.length > 0">
                                                    <div *ngFor=" let item of chatDetails.repliedData[0].attachment ">
                                                        <div *ngIf="item.type === 'img' ">
                                                            <img src="{{item.uri}}" style="margin: 5px;width: 40px;height: 40px;" loading="lazy" alt="..." />
                                                        </div>
                                                        <div *ngIf="item.type === 'img1' ">
                                                            <img src="{{item.uri}}" style="margin: 5px;width: 40px;height: 40px;" loading="lazy" alt="..." />
                                                        </div>
                                                        <div *ngIf="item.type === 'document' ">
                                                            <img src="{{ images.doc }}" style="margin: 5px;width: 40px;height: 40px;" loading="lazy" alt="..."
                                                                (click)="openDoc(item.uri)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="msg-text" (mouseenter)="speakText(chatDetails.text)"
                                                (mouseleave)="stopSpeaking()">
                                                <div
                                                    *ngIf="chatDetails.attachment && chatDetails.attachment.length > 0">
                                                    <div *ngFor=" let item of chatDetails.attachment">
                                                        <div *ngIf="item.type === 'img' ">
                                                            <img src="{{item.uri}}" loading="lazy" alt="..."  class="ImgClass"
                                                                (click)="openImage(item.uri)" />
                                                        </div>
                                                        <div *ngIf="item.type === 'img1' ">
                                                            <img src="{{item.uri}}" loading="lazy" alt="..." class="ImgClass"
                                                                (click)="openImage(item.uri)" />
                                                        </div>
                                                        <div *ngIf="item.type === 'document' ">
                                                            <img src="{{ images.doc }}" loading="lazy" alt="..." class="DocClass"
                                                                (click)="openDoc(item.uri)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label>{{chatDetails.text}}

                                                    <!-- <label>{{(chatDetails.visible) ? chatDetails.text : chatDetails.text | slice:0:300}} -->
                                                    <!-- <span *ngIf="!chatDetails.visible">...</span> -->
                                                    <!-- <a href="javascript:;" *ngIf="!chatDetails.visible" (click)="chatDetails.visible=true" class="read-more">Read More</a> -->
                                                    <!-- <a href="javascript:;" *ngIf="chatDetails.visible && (chatDetails.text.length > 300)" (click)="chatDetails.visible = false" class="read-more">Read Less</a> -->

                                                </label>
                                            </div>
                                            <hr class="line-black">
                                            <div class="sender-div">
                                                <div class="time-tick-mark-black"
                                                    (mouseenter)="speakText('created at' + returnedTime(chatDetails.createdAt))"
                                                    (mouseleave)="stopSpeaking()">
                                                    <label>{{returnedTime(chatDetails.createdAt)}}</label>
                                                    <!-- <span>&#10003;</span> -->
                                                </div>
                                            
                                             
               
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            </div>

                           
                            <div class="receiver-text-box" *ngIf="chatDetails.userId != userId">
                                <div class="conversation">
                                    <div class="conversation-container">
                                        <div class="message received">
                                            <div class="more">
                                                <div class="name-msg-text2">
                                                  <div class="name-container">
                                                    <label (mouseenter)="speakText(chatDetails.firstName + chatDetails.lastName)"
                                                           (mouseleave)="stopSpeaking()">
                                                      {{ chatDetails.firstName }} {{ chatDetails.lastName }}
                                                    </label>
                                                  </div>
                                                  
                                                    <mat-icon [matMenuTriggerFor]="replyMenu">more_vert</mat-icon>
                                                    <mat-menu style="width: 100%;padding: 0%;margin: 0%;" #replyMenu="matMenu">
                                                        <button mat-button style="width: 100%;justify-content: space-between;" (click)="openReplyDialog(chatDetails)">
                                                            <mat-icon style="color: grey;cursor: pointer;">reply</mat-icon>  Reply
                                                        </button>
                                                    </mat-menu>
                                                  
                                                </div>
                                              </div>
                                              
                                            <div class="recipient-reply" *ngIf="chatDetails.repliedData && chatDetails.repliedData[0]">
                                                <div class="name-msg-text">
                                                    Replyto:
                                                    <label
                                                        (mouseenter)="speakText(chatDetails.firstName + chatDetails.lastName)"
                                                        (mouseleave)="stopSpeaking()">{{chatDetails.repliedData[0].firstName}}
                                                        {{chatDetails.repliedData[0].lastName}}</label>
                                                </div>
                                                <div *ngIf="chatDetails.repliedData[0] && chatDetails.repliedData[0].text">
                                                    {{chatDetails.repliedData[0].text}}
                                                </div>
                                                <div *ngIf="chatDetails.repliedData[0] && chatDetails.repliedData[0].attachment && chatDetails.repliedData[0].attachment.length > 0">
                                                    <div *ngFor=" let item of chatDetails.repliedData[0].attachment ">
                                                        <div *ngIf="item.type === 'img' ">
                                                            <img src="{{item.uri}}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;" />
                                                        </div>
                                                        <div *ngIf="item.type === 'img1' ">
                                                            <img src="{{item.uri}}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;" />
                                                        </div>
                                                        <div *ngIf="item.type === 'document' ">
                                                            <img src="{{ images.doc }}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;"
                                                                (click)="openDoc(item.uri)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="msg-text" (mouseenter)="speakText(chatDetails.text)"
                                                (mouseleave)="stopSpeaking()">
                                                <div
                                                    *ngIf="chatDetails.attachment && chatDetails.attachment.length > 0">
                                                    <div *ngFor=" let item of chatDetails.attachment">
                                                        <div *ngIf="item.type === 'img' ">
                                                            <img src="{{item.uri}}" loading="lazy" alt="..." class="ImgClass"
                                                                (click)="openImage(item.uri)" />
                                                        </div>
                                                        <div *ngIf="item.type === 'img1' ">
                                                            <img src="{{item.uri}}"  loading="lazy" alt="..."class="ImgClass"
                                                                (click)="openImage(item.uri)" />
                                                        </div>
                                                        <div *ngIf="item.type === 'document' ">
                                                            <img src="{{ images.doc }}" loading="lazy" alt="..." class="DocClass"
                                                                (click)="openDoc(item.uri)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label>{{chatDetails.text}}

                                                    <!-- <label>{{(chatDetails.visible) ? chatDetails.text : chatDetails.text | slice:0:300}} -->
                                                    <!-- <span *ngIf="!chatDetails.visible">...</span> -->
                                                    <!-- <a href="javascript:;" *ngIf="!chatDetails.visible" (click)="chatDetails.visible=true" class="read-more">Read More</a> -->
                                                    <!-- <a href="javascript:;" *ngIf="chatDetails.visible && (chatDetails.text.length > 300)" (click)="chatDetails.visible = false" class="read-more">Read Less</a> -->

                                                </label>
                                            </div>
                                            <hr class="line">
                                            <div class="sender-div">
                                                <div class="time-tick-mark"
                                                    (mouseenter)="speakText(returnedTime(chatDetails.updatedAt))"
                                                    (mouseleave)="stopSpeaking()">
                                                    <label>{{returnedTime(chatDetails.updatedAt)}}</label>
                                                    <!-- <span>&#10003;</span> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                           </div>
                          
                          
                        
                        <!-- <div  *ngFor = "let chatDetails of chatArrayOfGroup">
                {{chatDetails.text}}
            </div> -->
                        <!-- <span >{{chatArrayOfGroup[chatArrayOfGroup.length-1]}} last element </span>  -->

                    </div>
                </div>
            </mat-card>
            
           <div class="attach">
            <div class="attachment-container" *ngIf="attachments.length > 0">
                <div *ngFor=" let item of attachments ">
                    <div *ngIf="item.type === 'img' ">
                        <img src="{{item.uri}}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;" />
                    </div>
                    <div *ngIf="item.type === 'img1' ">
                        <img src="{{item.uri}}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;" />
                    </div>
                    <div *ngIf="item.type === 'document' ">
                        <img src="{{ images.doc }}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;"
                            (click)="openDoc(item.uri)" />
                    </div>
                </div>
            </div>

               <div class="attachment-container" *ngIf="replyTO">
                <div class="close-button" (click)="closeReply()">
                  <mat-icon>close</mat-icon>
                </div>
                <div>
                   <div class="name-msg-text">
                    Replyto:
                    <label
                        (mouseenter)="speakText(chatDetails.firstName + chatDetails.lastName)"
                        (mouseleave)="stopSpeaking()">{{replyTO.firstName}}
                        {{replyTO.lastName}}</label>
                </div>
                <div *ngIf="replyTO && replyTO.text">
                    {{replyTO.text}}
                </div>
                <div *ngIf="replyTO && replyTO.attachment.length > 0">
                    <div *ngFor=" let item of replyTO.attachment ">
                        <div *ngIf="item.type === 'img' ">
                            <img src="{{item.uri}}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;" />
                        </div>
                        <div *ngIf="item.type === 'img1' ">
                            <img src="{{item.uri}}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;" />
                        </div>
                        <div *ngIf="item.type === 'document' ">
                            <img src="{{ images.doc }}" loading="lazy" alt="..." style="margin: 5px;width: 40px;height: 40px;"
                                (click)="openDoc(item.uri)" />
                        </div>
                    </div>
                </div>
                </div>
              </div>
           </div>
              
            <div *ngIf="groupDetailsData && groupDetailsData.groupDetails && !groupDetailsData.groupDetails.isDisabled" class="send-msg-div">
                <div class="send-msg" *ngIf="!Isattchment">
                    <input class="msg-text-box" type="text" (keyup.enter)="createMsg(groupDetailsData.groupDetails)"
                        (mouseenter)="speakText('enter your message')" (mouseleave)="stopSpeaking()"
                        [(ngModel)]="msgText" placeholder="{{this.groupsLabels[selectedLanguage].typeMessage}}">
                    <div class="send-container">
                        <mat-icon [matMenuTriggerFor]="options" style="color: grey;cursor: pointer;">
                            attach_file</mat-icon>
                        <mat-menu #options="matMenu">
                            <input style="display: none;" type="file" #send_image (click)="send_image.value = null"
                                (change)="addImage($event)" />

                            <button mat-menu-item (click)="send_image.click()">
                                <mat-icon>add_photo_alternate</mat-icon> Upload Image
                            </button>

                            <input style="display: none;" type="file" #send_doc (click)="send_doc.value = null"
                                (change)="addDoc($event)" />

                            <button mat-menu-item (click)="send_doc.click()">
                                <mat-icon>insert_drive_file</mat-icon> Upload Document
                            </button>

                        </mat-menu>

                        <img class="send-icon" *ngIf="msgText || attachments.length > 0 "
                            (click)="createMsg(groupDetailsData.groupDetails)"
                            (mouseenter)="speakText('send your message')" (mouseleave)="stopSpeaking()"
                            src="{{images.sendMsg}}" loading="lazy" alt="...">
                    </div>
                </div>
                <div class="send-msg" *ngIf="Isattchment">
                    <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
                    </mat-progress-spinner>
                </div>
            </div>

            <div *ngIf="groupDetailsData && groupDetailsData.groupDetails.isDisabled" class="send-msg-div">
                <div class="send-msg">
                    {{groupsLabels[selectedLanguage].thisGroupIsDisabledByTheAdmin}}
                </div>
            </div>

        </mat-drawer-container>

    </div>
    <div *ngIf="!isLoading && !allUserGroupData " class="empty">
        <h1 *ngIf=" selectedLanguage === 'en' ">
            No Group Found
        </h1>
        <h1 *ngIf=" selectedLanguage === 'mar' ">
            माहिती उपलब्ध नाही
        </h1>
    </div>
</div>
<div class="deleteModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true"
    [ngStyle]="{'display':displayConfirmationModal}">
    <div class="modal-dialog" role="document">
        <div *ngIf="displayConfirmationModal=='block'" class="deleteModalContent">
            <div class="closeIcon">
                <mat-icon style="float: right;cursor: pointer;" (click)="closeConfirmationModal()">close</mat-icon>
            </div>
            <div class="modal-image">
                <img src="{{showImage}}" class="image-content" loading="lazy" alt="..." />
                <button mat-stroked-button style="margin: 10px;" (click)="openDoc(showImage)">
                    <mat-icon style="cursor: pointer;">download</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="group-list-card-mobile" *ngIf="isMobile">

    <mat-card class="mat-shadow">
        <label class="label-isMobile"> {{groupsLabels[selectedLanguage].forChattingUsePcmcAppGoToBelowLink}}</label>
        <!-- app downloading options -->
        <div class="container-section">
            <!-- <div class="store-image"> -->

            <div class="image-container">
                <img class="store-image" (click)="OpenQRScanPopup()" src="{{images.googlePlayStore}}" loading="lazy" alt="...">
                <img class="store-image" (click)="OpenQRScanPopup()" src="{{images.appStore}}"loading="lazy" alt="...">
            </div>

            <!-- </div> -->
        </div>
    </mat-card>
</div>