/*******************************
 * Component -  My Cart
 * Created By - Kiran Gajbhiye
 * Created on - 20/04/2020
 * Updated By - Neha R
 * Updated On - 11/04/2020
 *************************************/
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { LocalStorageService } from '../../../services/localstorage.service';
import { ObjectInterface } from '../../../models/global-interface';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { RemoveFromCartPopupComponent } from '../remove-from-cart-popup/remove-from-cart-popup.component';
import { ApplyCouponPopupComponent } from '../apply-coupon-popup/apply-coupon-popup.component';
import { RemoveCartDataFromProductPopupComponent } from '../remove-cart-data-from-product-popup/remove-cart-data-from-product-popup.component';
import { FeedbackPopupComponent } from '../../Merchant-flow/feedback-popup/feedback-popup.component';
import { FeedbackComponent } from '../../../../shared/components/feedback/feedback.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatCard } from '@angular/material/card';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor, UpperCasePipe, DatePipe } from '@angular/common';


@Component({
    selector: 'app-deal-history-detail-page',
    templateUrl: './deal-history-detail-page.component.html',
    styleUrls: ['./deal-history-detail-page.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatTooltip, RouterLink, MatCard, NgFor, QRCodeModule, UpperCasePipe, DatePipe]
})
export class DealHistoryDetailPageComponent implements OnInit {
  /**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails : ObjectInterface;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 * 
	 */
	data: any;
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * variable use
	 */
	mydealHistoryCart: any;
	/**
	 * variable use
	 */
	cartDetails: any
	myDeal: any
		/**
	 * variable for data not found
	 */
	dataNotFoundMsg: any
	scanId: any;
	cartId: any;
	myCartLables:{};
	price: any;
	merchantData;
	scanData:any;
	myDealsLables:{}
	/**
	 * Angular method which runs on component initialization
	 */
  constructor(
    private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute,
		private ref: ChangeDetectorRef,
  ) { 
    this.activeRoute.params.subscribe((params:any) => {
      this.scanId = params.scanId;
      this.cartId = params.cartId;
	  this.scanData = encrDecr.set(JSON.stringify({
		scanId:params.scanId,
		cartId:params.cartId
	  }))
    
    });
  }

  ngOnInit() {
    // token from localstorage
		this.store.select(fromStore.getToken)
    .subscribe((token) => {
      this.token = token;
      if (this.token) {
        const localUserId = this.localStorageService.getUserId();
        this.userId = this.encrDecr.get(localUserId);
      }
  });
  this.store.select(fromStore.getMyCartLabels)
  .subscribe(labels => {
	  this.myCartLables = labels;
	
  });

  this.store.select(fromStore.getMyDealsLabels)
  .subscribe(labels => {
	  this.myDealsLables = labels;
	  
  });

  this.store.select(fromStore.getApiCallingList)
  .subscribe((apiDetails:any) => {
    this.apiDetails = apiDetails;
    if (Object.keys(this.apiDetails).length > 0 && this.cartId) {
      this.store.dispatch(new fromStore.GetDealHistoryDetailsCart({
        url: this.apiDetails['user'].base_url +
          this.apiDetails['user'].getUserCartByCartId.url,
        method: this.apiDetails['user'].getUserCartByCartId.method,
        body:  {
		userId: this.userId,
        cartId: this.cartId
        }
      }));
    }
  });


    // server error handling
	// 	this.store.select(fromStore.getMoreServicesNetworkError)
  //   .subscribe((serverErrorMessage) => {
  //     if (serverErrorMessage != null) {
  //       if (serverErrorMessage === 'InvalidCredentials') {
  //         this.router.navigate(['/Login/login']);
  //       } else {
  //         this.serverMessage = serverErrorMessage;
  //         this.isServerErrorMessage = true;
  //         console.error(this.serverMessage);
  //       }
  //     }
	// });
	
	this.store.select(fromStore.isDealHistoryCartLoading)
	.subscribe(loading => {
		this.isLoading = loading;
	});


	this.store.select(fromStore.getSelectedLanguage)
	.subscribe(lang => {
		this.selectedLanguage = lang;
		if (this.selectedLanguage) {
			// this.store.select(fromStore.isDetailsScreenLoaded)
			// .subscribe(loaded => {
			
				// if (loaded) {
					this.store.select(fromStore.dealHistoryCartStatusCode)
					.subscribe(code => {
						if(code) {
							if (code === 200) {
								this.store.select(fromStore.dealHistoryCartResult)
								.subscribe(result => {
								this.cartDetails = result;
								this.mydealHistoryCart = result['data'];
								this.price = result['price'];
								});
							} else if (code === 500) {
								this.store.select(fromStore.dealHistoryCartSuccessMessage)
								.subscribe(errorMessages => {
									this.dataNotFoundMsg = errorMessages;
								});
							} else {
								this.store.select(fromStore.getErrorMessages)
								.subscribe(errorMessages => {
									if(errorMessages) {
										console.error(errorMessages[this.selectedLanguage].code);
									}
										
								});
							}
						}
					});
			// 	}
			// });
		}
	});

  }

 /**
   * Created by neha
   * to redirect to my deals page
   */
  redirectToMerchantRating(){	
	
	// this.router.navigate(['/cep/my-deals']);
	if (this.userId ) {
		const dialogRef = this.dialog.open(FeedbackPopupComponent,
			{ 
				data: {
					merchantId: this.cartDetails.merchantId
				},
				maxHeight: '100vh',
				maxWidth: '100vw',
				autoFocus: false
			});
	}
  }


  redirectToProductRating(productData){	

	  
  // this.router.navigate(['/cep/my-deals']);
  if (this.userId ) {
	  const dialogRef = this.dialog.open(FeedbackComponent,
		  { 
			  data: {
				engagementId: productData.engagementId,
				engagementType: productData.engagementType
			  },
			  maxHeight: '100vh',
			  maxWidth: '100vw',
			  autoFocus: false
		  });
  }
}





  ngAfterContentChecked() {
	this.ref.detectChanges();
}
	/**
	 * angular method which gets called on page view initialization
	 */
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}
}

