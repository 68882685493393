import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as  GroupAction from '../action/group.action';
import { Observable, of } from 'rxjs';
import { GroupService } from '../api-services/group.service';


@Injectable()

export class GroupEffects {
    constructor(
        private actions$: Actions,
        private groupService: GroupService
    ) {
    }

    groupData$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GroupAction.GetGroupDetails>(GroupAction.GET_GROUP_DETAILS),
            switchMap((action) => {
                return this.groupService.getGroupDetails(action.payload).pipe(
                    map(data => new GroupAction.GetGroupDetailsSuccess(data)),
                    catchError(error => of(new GroupAction.GetGroupDetailsFail(error)))
                );
            })
        )
    );

    getAllGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GroupAction.GetAllGroups>(GroupAction.GET_ALL_GROUPS),
            switchMap((action) => {
                return this.groupService.getAllGroups(action.payload).pipe(
                    map(data => new GroupAction.GetAllGroupsSuccess(data)),
                    catchError(error => of(new GroupAction.GetAllGroupsFail(error)))
                );
            })
        )
    );

    acceptPublicGroupInvitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GroupAction.AcceptPublicGroupInvitation>(GroupAction.ACCEPT_PUBLIC_GROUP_INVITAION),
            switchMap((action) => {
                return this.groupService.acceptPublicGroupInvitation(action.payload).pipe(
                    map(data => new GroupAction.AcceptPublicGroupInvitationSuccess(data)),
                    catchError(error => of(new GroupAction.AcceptPublicGroupInvitationFail(error)))
                );
            })
        )
    );

    createMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GroupAction.CreateMessage>(GroupAction.CREATE_MESSAGE),
            switchMap((action) => {
                return this.groupService.createMessage(action.payload).pipe(
                    map(data => new GroupAction.CreateMessageSuccess(data)),
                    catchError(error => of(new GroupAction.CreateMessageFail(error)))
                );
            })
        )
    );

    getAllUserGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GroupAction.GetAllUserGroups>(GroupAction.GET_ALL_USER_GROUPS),
            switchMap((action) => {
                return this.groupService.getAllUserGroups(action.payload).pipe(
                    map(data => new GroupAction.GetAllUserGroupsSuccess(data)),
                    catchError(error => of(new GroupAction.GetAllUserGroupsFail(error)))
                );
            })
        )
    );

}

