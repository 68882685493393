    <!-- /******
 * Component -  competition component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */ -->
<div class="feed-card" (click)="onCardClick()">
  <!-- image of card -->
  <div class="feed-image" 
  (click)="onClick()"
  >
    <img mat-card-image src="{{competitionData.imageFavourite[0]}}" loading="lazy" alt="...">
  </div>
  <!-- main content -->
  <div class="feed-content">
    <div class="post-tag-container">
      <div class="feed-tag" (mouseenter)="speakText(landingLables[selectedLanguage].competition)" (mouseleave)="stopSpeaking()">
        <p>{{landingLables[selectedLanguage].competition | uppercase}}</p>
      </div>
    </div>
    <!-- post heading -->
    <div class="feed-heading"  matTooltip="{{competitionData[selectedLanguage].heading}}" 
    (click)="onClick()"
    
    (mouseenter)="speakText(competitionData[selectedLanguage].heading)" (mouseleave)="stopSpeaking()"
    >
      <p class="font-card-heading">{{competitionData[selectedLanguage].heading}}</p>
    </div>
    <!-- short desc -->
    <div class="feed-desc"  matTooltip="{{competitionData[selectedLanguage].description}}" 
    (click)="onClick()"
    
    (mouseenter)="speakText(competitionData[selectedLanguage].description)" (mouseleave)="stopSpeaking()"
    >
      <p class="font-card-desc">{{competitionData[selectedLanguage].description}}</p>
    </div>
    <!-- post footer -->
    <div class="event-date-label" (mouseenter)="speakText('competition location'+ competitionData[selectedLanguage].location)" (mouseleave)="stopSpeaking()"
    >
      <p class=" " >{{competitionData[selectedLanguage].location}}</p>
    </div>
    <div class="post-footer">
      <div class="post-date  " (mouseenter)="speakText('competition date from' + competitionStartDate + 'to' + competitionEndtDate)" (mouseleave)="stopSpeaking()">
        <p class=" " [ngStyle]="{ 'color': 'black' }" >{{competitionStartDate}} - {{competitionEndtDate}}</p>
      </div>
    </div>
    <!-- action logo -->
    <div class="align-logo">
      <div class="post-date  " (mouseenter)="speakText('competition posted by ' + competitionData.createdBy)" (mouseleave)="stopSpeaking()">
        <p class=" ">{{postDate}}</p>
      </div>
      <div class="post-action-logo" [ngClass]="{'login-active-favourite': !this.userId}">
        <div class="abuse-flag-container">
          <!-- <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()" (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)" (mouseleave)="stopSpeaking()">flag</mat-icon> -->
      </div>
        <div class="share-logo" *ngIf="this.userId" (click)="clickOnShare()"><i class="material-icons"  matTooltip="{{landingLables[selectedLanguage].share}}" (mouseenter)="speakText(landingLables[selectedLanguage].share)" (mouseleave)="stopSpeaking()">
            share
          </i></div>
        <!-- <mat-menu #menu="matMenu">
          <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')" (mouseenter)="speakText('share on facebook')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.facebookShareImg}}"> 
            Facebook
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on twitter')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.twitterShareImg}}">
            Twitter
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.linkedinShareImg}}">
            LinkedIn
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()"> 
            <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
            Whatsapp
          </button>
        </mat-menu> -->
        <div *ngIf="this.userId"><i class="material-icons favorite-icon"
            [ngClass]="{'active-favorite':competitionData.favourite}"  (click)="unMarkFavorite()"  (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)" (mouseleave)="stopSpeaking()">
            {{favorite}}
          </i></div>
      </div>
    </div>
  </div>
</div>
