<!-- *******************************************************************
*  Component -  Near Me Map
   Created By - Prajakta Patil
   Created On - 04/09/2019
   Updated By - Prajakta Patil
   Updated On - 22/09/2019
*****************************************************************  -->


<div *ngIf="isCategoryLoading " class="spinner">
    <!-- loader -->
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="primary" [mode]="mode" [value]=50>
    </mat-progress-spinner>
  </div>
  
  <!-- connection lost condition -->
  <div *ngIf="serverErrorMessage && isCategoryLoading" class="connectionDiv">
    <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
    <span>{{serverErrorMessage}}</span>
  </div>
  
  <!-- server error messages -->
  <div class="error-message" *ngIf="isServerErrorMessage">
    <div class="server-error-image-container">
        <img class="server-error-image" src="{{images.pageNotFound}}" loading="lazy" alt="...">
    </div>
    <div class="server-error-message-heading">{{serverErrorMessage}}</div>
  </div>
  
  <!-- main page -->
  <div *ngIf="!isCategoryLoading && !isServerErrorMessage && !serverErrorMessage && moreServiceLabels">
    <div class="near-me-map-container">
      <mat-drawer-container class="explore-nearby-container" [ngClass]="{'drawer-card': showList}">
        <!--Explore Nearby Sidebar-->
        <mat-drawer mode="side" class="explore-nearby-sidebar drawer" opened>
          <!--Explore nearby list-->
          <div *ngIf="!showList" class='drawer-menu'>
            <div class="subheader-text sidebar-heading font-card-heading">
              <div class="location-header">
                <div><img class="back-arrow-style" (click)="redirectToMoreServices()" src="{{images.backArrow}}" loading="lazy" alt="..."></div>
                <div class="card-title font-card-heading">{{moreServiceLabels[selectedLanguage].aroundMe}}</div>
              </div>
              <span><mat-icon></mat-icon></span></div>
              <mat-list class="list-footer">
              <div class="explore-nearby-list">
                <mat-list-item class="list-item" *ngFor="let aroundMeEnablelabel of categoryList; let i = index">
                  <div mat-line
                   class="nearby-places line-container"
                    (click)="onClick(aroundMeEnablelabel)">
                    <div mat-line  class="nearby-places line-container">
                      <div class=icon matTooltip="{{aroundMeEnablelabel[selectedLanguage]}}">
                        <img [attr.id]="'img.'+ i"  src={{aroundMeEnablelabel.image}}  loading="lazy" alt="..." class="nearby-places-icon"></div>
                      <div [attr.id]="'label.'+ i"  class="nearby-items" matTooltip="{{aroundMeEnablelabel[selectedLanguage]}}" > {{aroundMeEnablelabel[selectedLanguage]}}</div>
                    </div>
                  </div>
                </mat-list-item>
              </div>
            </mat-list>
          </div>
          <!--Nearby location details card-->
          <div>
            <div *ngIf="showList">
              <mat-list class="card-container card-footer">
                <div class="near-me-header ">
                  <div class="location-header">
                    <div><img class="back-arrow-style" (click)="locationList('back')" src="{{images.backArrow}}" loading="lazy" alt="..."></div>
                    <div class="card-title font-card-heading">{{nearMeListHeading[selectedLanguage]}}</div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-alignment">
                    <mat-card *ngFor="let location of locations ; let i = index " class="card-list"
                      (mouseenter)="updateMarkerOnHover(location.geometry.coordinates[1])"
                      (mouseleave)="updateMarker(location.geometry.coordinates[1],location.img)">
                      <div class="container card-container">
                        <div (click)="openPopupNearMe(location,location.img, location.geometry)"
                          class="cfc-card-heading">{{location.companyType | titlecase }}</div>
                        <div class="near-me-direction-icon container">
                          <img
                            (click)="redirectToGoogleMap(location.geometry.coordinates[1],location.geometry.coordinates[0])"
                            src={{images.directionImage}}  loading="lazy" alt="...">
                          <div>
                          </div>
                        </div>
                      </div>
                      <div (click)="openPopupNearMe(location,location.img, location.geometry)"
                        *ngIf="location.displayCompanyName"
                        matTooltipClass="my-custom-tooltip  " matTooltip={{location.displayCompanyName}}
                        aria-label="Button that displays a tooltip when focused or hovered over" class="location-title">
                        {{location.displayCompanyName}}
                      </div>
                      <mat-card-content (click)="openPopupNearMe(location,location.img, location.geometry)"
                        *ngIf="location.headOfficeAddress && location.headOfficeCity"
                        class="card-content" matTooltipClass="my-custom-tooltip  "
                        matTooltip={{location.headOfficeAddress}},{{location.headOfficeCity}}
                        aria-label="Button that displays a tooltip when focused or hovered over">
                        {{location.headOfficeAddress}}, {{location.headOfficeCity}}
                      </mat-card-content>
                    </mat-card>
                    </div>
                </div>
              </mat-list>
            </div>
          </div>
        </mat-drawer>
        <!--*************************** end of side menu bar****************************** -->
        <!-- ***********************Map section ******************************-->
        <mat-drawer-content class="map-container">
          <div class="loader spinner">
            <mat-progress-spinner [diameter]="35" class="example-margin" [color]="primary" [mode]="mode" [value]=50>
            </mat-progress-spinner>
          </div>
          <div *ngIf="!isSelect">
            <agm-map class="map" [latitude]="lat" [longitude]="lng" [zoom]="10">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>
          <div *ngIf="!showList">
            <agm-map class="map" [latitude]="lat" [longitude]="lng" [zoom]="10">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>
          <ng-container *ngIf="isSelect">
            <div *ngIf="showList">
              <!-- ************************ agm map ********************************* -->
              <div >
                <ng-container  *ngIf="locations">
                  <agm-map [zoom]="zoom" class="map" [latitude]="lat" [longitude]="lng">
                    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                    <agm-marker *ngFor="let location of locations; let i=index" 
                      [longitude]="location.geometry.coordinates[1]" [latitude]="location.geometry.coordinates[0]"
                      [iconUrl]=markerIcon (markerClick)="clickedMarker(infoWindow, $event)">
                      <agm-info-window #infoWindow>
                        <div class="info-window-size">
                          <div>
                            <strong>{{type}}</strong> {{nearMeListHeading[selectedLanguage]}}
                          </div>
                          <div class="card-image-mouse-hover">
                            <img class="detail-card-image" src={{location.logo}} loading="lazy" alt="...">
                          </div>
                          <div>
                            <strong>{{moreServiceLabels[selectedLanguage].companyName}}</strong> : {{location.displayCompanyName}}
                          </div>
                          <div *ngIf="location">
                            <strong>{{moreServiceLabels[selectedLanguage].address}}</strong> : {{location.headOfficeAddress}}, {{location.headOfficeCity}}
                          </div>
                        </div>
                      </agm-info-window>
                    </agm-marker>
                  </agm-map>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </div>
