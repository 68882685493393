<!-- /*************************************
 * Component -  carousel section
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ******************************************/ -->

<div class="carousel carousel-slider">
  <div class="carousel-item" *ngFor="let postImages of postImages">
    <img src="{{postImages}}"  loading="lazy" alt="..."/>
  </div>
</div>
