<!-- *******************************************************************
*  Component -  Dialog of OTP verification choices 
   Created By - Akanksha Jadhav
   Created On - 04/09/2019
   Updated By - Vinita Sharma
   Updated On - 28/10/2020
*****************************************************************  -->

<div class="dialogbox">
    <div *ngIf="isLoading" class="dialog-spinner">
        <mat-progress-spinner [diameter]="35" class="example-margin" [color]="primary" [mode]="mode" [value]="value">
        </mat-progress-spinner>
    </div>
    <div *ngIf="!isLoading" class="dialogbox">
        <div class="popup-header">
            <span class="popup-heading font-card-heading" (mouseenter)="speakText(verifyOtpScreenLabels[selectedLanguage].otpVerification)" (mouseleave)="stopSpeaking()">{{verifyOtpScreenLabels[selectedLanguage].otpVerification}}</span>
        </div>
        <div class="popup-container">
            <mat-radio-group name="option" [formControl]="option" [(ngModel)]="chooseOption">
                <div class="radio-group">
                    <span class=" " (mouseenter)="speakText(verifyOtpScreenLabels[selectedLanguage].sendOtpViaSms)" (mouseleave)="stopSpeaking()">{{verifyOtpScreenLabels[selectedLanguage].sendOtpViaSms}}</span>
                    <mat-radio-button class="radio" value="sms"></mat-radio-button>
                </div>
                <!-- <div class="radio-group">
                    <span class=" " (mouseenter)="speakText(verifyOtpScreenLabels[selectedLanguage].sendOtpViaEmail)" (mouseleave)="stopSpeaking()">{{verifyOtpScreenLabels[selectedLanguage].sendOtpViaEmail}}</span>
                    <mat-radio-button class="radio" value="email"></mat-radio-button>
                </div> -->
            </mat-radio-group>
        </div>
        <div class="popup-button-container">
            <div class="align-buttons">
                <button mat-flat-button class="popup-reverse-button"
                    (click)="cancleButton()"  (mouseenter)="speakText(verifyOtpScreenLabels[selectedLanguage].cancle)"  (mouseleave)="stopSpeaking()">{{verifyOtpScreenLabels[selectedLanguage].cancle}}</button>
                <button mat-raised-button class="popup-button"
                    (click)="redirectToOtp()"  (mouseenter)="speakText(verifyOtpScreenLabels[selectedLanguage].ok)"  (mouseleave)="stopSpeaking()">{{verifyOtpScreenLabels[selectedLanguage].ok}}</button>
            </div>
        </div>
    </div>
</div>