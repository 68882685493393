<!-- /******
 * Component -  event component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */ -->
<div *ngIf="eventData !=undefined" class="feed-card">
    <!-- event img -->
    <div class="feed-image" 
    (click)="onClick()"
    >
        <img mat-card-image src="{{eventData.imageFavourite[0]}}" loading="lazy" alt="...">
    </div>
    <!-- (Error)='src="image.pageNotFound"'  onerror='src="image.pageNotFound"' -->
    <div class="feed-content">
        <div class="post-tag-container">
            <div class="feed-tag" (mouseenter)="speakText(landingLables[selectedLanguage].event)" (mouseleave)="stopSpeaking()">
                <p>{{landingLables[selectedLanguage].event | uppercase}}</p>
            </div>
            <!-- <div class="paid-free-button" (mouseenter)="speakText(feedsLabel[selectedLanguage][eventData.type] + 'event')" (mouseleave)="stopSpeaking()">
                <button mat-raised-button class="mat-button">{{feedsLabel[selectedLanguage][eventData.type]}}</button>
            </div> -->
        </div>

    <!-- post heading -->
    <div class="feed-heading" matTooltip="{{eventData[selectedLanguage].heading}}" 
    (click)="onClick()"
      (mouseenter)="speakText(eventData[selectedLanguage].heading)" (mouseleave)="stopSpeaking()">
      <p class="font-card-heading">{{eventData[selectedLanguage].heading}}</p>
    </div>

    <!-- short desc -->
    <div class="feed-desc" matTooltip="{{eventData[selectedLanguage].description}}" 
    (click)="onClick()"
     (mouseenter)="speakText(eventData[selectedLanguage].description)" (mouseleave)="stopSpeaking()">
      <p class="font-card-desc">{{eventData[selectedLanguage].description}}</p>
    </div>

    <!-- post footer -->
    <!-- <div class="event-date-label " (mouseenter)="speakText('event location' + eventData[selectedLanguage].location)" (mouseleave)="stopSpeaking()">
      <p>{{eventData[selectedLanguage].location}}</p>
    </div>
    <div class="post-footer" (mouseenter)="speakText('event date from' + startDate + 'to' + endDate)" (mouseleave)="stopSpeaking()">
      <div class="feed-date" [ngStyle]="{ 'color': 'black' }">{{startDate}} - {{endDate}}</div>
    </div> -->

        <!-- action logo -->
        <div class="align-logo">
          <div class="event-date-label " (mouseenter)="speakText('event location' + eventData[selectedLanguage].location)" (mouseleave)="stopSpeaking()">
            <p>{{eventData[selectedLanguage].location}}</p>
          </div>
          <div class="post-footer" (mouseenter)="speakText('event date from' + startDate + 'to' + endDate)" (mouseleave)="stopSpeaking()">
            <div class="feed-date" >{{startDate}} - {{endDate}}</div>
          </div>
            <!-- <div class="feed-date" (mouseenter)="speakText('event posted by' + eventData.createdBy)" (mouseleave)="stopSpeaking()">{{postDate}}</div> -->
            <div class="post-action-logo" [ngClass]="{'login-active-favourite': !this.userId}">
                <div class="abuse-flag-container">
                    <!-- <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()" (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)" (mouseleave)="stopSpeaking()">flag</mat-icon> -->
                </div>
                <div class="share-logo" *ngIf="this.userId" (click)="clickOnShare()"><i class="material-icons"  matTooltip="{{landingLables[selectedLanguage].share}}" (mouseenter)="speakText(landingLables[selectedLanguage].share)" (mouseleave)="stopSpeaking()">
            share
          </i></div>
                <!-- <mat-menu #menu="matMenu">
                    <button mat-menu-item class="share-button"  (click)="generateShareLink('facebook')" (mouseenter)="speakText('share on facebook')" (mouseleave)="stopSpeaking()"> 
            <img class="social-share-img" src="{{image.facebookShareImg}}">
            Facebook
          </button>
                    <button mat-menu-item class="share-button"  (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on twitter')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.twitterShareImg}}">
            Twitter
          </button>
                    <button mat-menu-item class="share-button"  (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.linkedinShareImg}}">
            LinkedIn
          </button>
                    <button mat-menu-item class="share-button"  (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()"> 
            <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
            Whatsapp
          </button>
                </mat-menu> -->
                <div *ngIf="this.userId"><i class="material-icons favorite-icon" [ngClass]="{'active-favorite':eventData.favourite}"   (click)="unMarkFavorite()"  (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)" (mouseleave)="stopSpeaking()">
            {{favorite}}
          </i></div>
            </div>
        </div>

    </div>
</div>