<!--************************************* 
Created by: Akanksha Jadhav
Created Date: 27/12/19
updated by: Akanksha Jadhav
Updated Date: 27/12/19
Description: Zone Information screen in about us 
***************************************** -->

<div *ngIf="isLoading && !serverErrorMessage" class="spinner">
  <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div>
 <!-- connection lost condition -->
<div *ngIf="serverErrorMessage" class="connectionDiv">
  <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
  <span>{{serverErrorMessage}}</span>
</div>
<div class="container" *ngIf="!isLoading && selectedLanguage && !serverErrorMessage">
  <div class="heading-container">
    <label class="heading font-heading"><strong class="font-fix">{{aboutUsLabels[selectedLanguage]?.zoneInformation}}</strong></label><br>
  </div>
  <mat-card class="card" *ngFor="let data of zoneArray; let i = index">
    <div class="label">
      <strong>{{data[1][selectedLanguage]?.name}}</strong>
    </div>
    <div class="line"></div>
    <div class="description">
      <div class="info-in-line">
        <div class="main-label"><b>{{aboutUsLabels[selectedLanguage]?.contactNo}}.</b></div>
        <div class="info">
          <div *ngFor="let contact of data[1][selectedLanguage]?.contactNo; let j = index">{{contact}}
            <!-- <ng-container *ngIf="j+1 < data[1][selectedLanguage].contactNo.length"> {{contact}}, </ng-container>
            <ng-container *ngIf="j+1 >= data[1][selectedLanguage].contactNo.length"> {{contact}}</ng-container> -->
            </div>
        </div>
      </div>
      <div class="info-in-line">
        <div class="main-label"><b>{{aboutUsLabels[selectedLanguage]?.email}}</b></div>
        <div class="info">{{data[1]?.emailId}}</div>
      </div>
      <div class="info-in-line">
        <div class="main-label"><b>{{aboutUsLabels[selectedLanguage]?.address}}</b></div>
        <div class="info">{{data[1][selectedLanguage]?.address}}</div>
      </div>
    </div>
  </mat-card>
</div>