import { Component, OnInit, ChangeDetectorRef,AfterViewInit,Input,OnDestroy,Output} from '@angular/core';
import { Images } from '../../config/images-config';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { Router,ActivatedRoute } from '@angular/router';
import { ObjectInterface } from '../../models/global-interface';
import { EncrDecrService } from 'src/app/shared/services/encr-decr.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/shared/services/localstorage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import * as M from "../../../../assets/materialize/js/materialize.min.js";
import { AuthGuard } from 'src/app/cep/_guards';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-election-popup',
    templateUrl: './election-popup.component.html',
    styleUrls: ['./election-popup.component.scss'],
    providers: [AuthGuard],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class ElectionPopupComponent implements OnInit {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() interval: number = 15000;

  images = Images;
  options = { fullWidth: true, indicators: true };
  selectedLanguage: string= 'mar';
  postImages; 
  feedImag :any;
  feedsLabels: ObjectInterface;
  loginData: ObjectInterface;
  registerCode = 0;
	isLogin = false;
	token: string;
	userId: any;
  carouselInstance: M.Carousel;

  extraLabels={
    en:{
      heading1:"Pune Loksabha Constituency Election Date 13 May 2024",
      heading2:"Baramati Loksabha Constituency Election Date 07 May 2024",
      heading3:"Download the Android and iOS apps from the following links",
      click_here_for_info:"Click here for more information",
      check_your_name:"Check your name in the Electoral roll",
      polling_booth:"Know your polling booth",
      know_your_candidate:"Know about your candidate",
      code_of_conduct:"Code of Conduct",
      download_eepic:"Download E-EPIC",
      election_managemnet:"Election Management",
      election_result:"ELECTION RESULTS",
      faq:"FAQ",
      voter_helpline:"Voter Helpline",
      eci_helpline:"ECI Helpline",
      saksham_eci:"Saksham ECI",
      cvigil_helpline:"CVIGIL Helpline",
      click_for_andorid_download:"Click here to download Android app.",
      click_for_ios_download:"Click here to download iOS app.",
    },
    mar:{
      heading1: "पुणे लोकसभा मतदारसंघ मतदान दिनांक १३ मे २०२४",
      heading2: "बारामती लोकसभा मतदारसंघ मतदान दिनांक ०७ मे २०२४",
      heading3:"खालील लिंक्सवरून Android आणि iOS ॲप्स डाउनलोड करा",
      click_here_for_info: "अधिक माहितीकरिता येथे क्लिक करा.",
      check_your_name: "मतदार यादीत तुमचे नाव शोधा",
      polling_booth: "तुमचे मतदान केंद्र जाणून घ्या",
      know_your_candidate:"तुमचे उमेदवार जाणून घ्या",
      code_of_conduct:"आचारसंहिता",
      download_eepic: "डाउनलोड E-EPIC",
      election_managemnet: "निवडणूक व्यवस्थापन",
      election_result:"निवडणूक निकाल",
      faq:"FAQ",      
      voter_helpline: "मतदार हेल्पलाइन",
      eci_helpline:"ECI हेल्पलाइन",
      saksham_eci:"सक्षम ईसीआय",
      cvigil_helpline:"CVIGIL हेल्पलाइन",
      click_for_andorid_download: "Android ॲपसाठी येथे क्लिक करा.",
      click_for_ios_download: "iOS ॲपसाठी येथे क्लिक करा.",      
    }
  };
  carousalImages=[{image:this.images.carousalImg1}];

  cardLinks1=[
    { "label": this.extraLabels[this.selectedLanguage].check_your_name,
      "link": "https://electoralsearch.eci.gov.in/",
      "image": this.images.voterList ,
      "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
    },
    {"label":this.extraLabels[this.selectedLanguage].polling_booth,
      "link": "https://electoralsearch.eci.gov.in/pollingstation",
      "image": this.images.pollingBooth ,
      "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
    },
    {"label":this.extraLabels[this.selectedLanguage].know_your_candidate,
      "link": "https://affidavit.eci.gov.in/",
      "image": this.images.candidate ,
      "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
    },
    {"label":this.extraLabels[this.selectedLanguage].code_of_conduct,
      "link": "https://www.eci.gov.in/mcc",
      "image": this.images.codeOfConduct ,
      "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
    },
    {"label":this.extraLabels[this.selectedLanguage].download_eepic,
      "link": "https://voters.eci.gov.in/",
      "image": this.images.downloadImage ,
      "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
    },
    {"label":this.extraLabels[this.selectedLanguage].election_managemnet,
      "link": "https://www.eci.gov.in/election-management",
      "image": this.images.electionResult ,
      "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
    },
    {"label":this.extraLabels[this.selectedLanguage].election_result,
      "link": "https://results.eci.gov.in/AcResultByeJan2024/index.htm",
      "image": this.images.electionMgmt ,
      "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
    },
    {
      "label":this.extraLabels[this.selectedLanguage].faq,
      "link": "https://www.eci.gov.in/mcc",
      "image": this.images.faq ,
      "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
    }]
    cardLinks2=[
    {
      "label":this.extraLabels[this.selectedLanguage].voter_helpline,
      "link": "https://play.google.com/store/apps/details?id=com.eci.citizen",
      "image": this.images.androidIcon ,
      "info": this.extraLabels[this.selectedLanguage].click_for_andorid_download 
    },
    {
      "label":this.extraLabels[this.selectedLanguage].voter_helpline,
      "link": "https://apps.apple.com/in/app/voter-helpline/id1456535004",
      "image": this.images.appleIcon ,
      "info": this.extraLabels[this.selectedLanguage].click_for_ios_download 
    },
    {
      "label": this.extraLabels[this.selectedLanguage].saksham_eci,
      "link": "https://play.google.com/store/apps/details?id=pwd.eci.com.pwdapp&hl=en_IN&gl=US",
      "image": this.images.androidIcon ,
      "info": this.extraLabels[this.selectedLanguage].click_for_andorid_download 
    },
    {
      "label":this.extraLabels[this.selectedLanguage].saksham_eci,
      "link": "https://apps.apple.com/in/app/saksham-eci/id1497864568",
      "image": this.images.appleIcon ,
      "info": this.extraLabels[this.selectedLanguage].click_for_ios_download 
    },
    {
      "label": this.extraLabels[this.selectedLanguage].eci_helpline,
      "link": "https://play.google.com/store/apps/details?id=com.eci.ksa",
      "image": this.images.androidIcon ,
      "info": this.extraLabels[this.selectedLanguage].click_for_andorid_download 
    },
    {
      "label": this.extraLabels[this.selectedLanguage].eci_helpline,
      "link": "https://apps.apple.com/in/app/kyc-eci/id1604172836",
      "image": this.images.appleIcon ,
      "info": this.extraLabels[this.selectedLanguage].click_for_ios_download 
    },
    {
      "label": this.extraLabels[this.selectedLanguage].cvigil_helpline,
      "link": "https://play.google.com/store/apps/details?id=in.nic.eci.cvigil",
      "image": this.images.androidIcon ,
      "info": this.extraLabels[this.selectedLanguage].click_for_andorid_download 
    },
    {
      "label": this.extraLabels[this.selectedLanguage].cvigil_helpline,
      "link": "https://apps.apple.com/in/app/cvigil/id1455719541",
      "image": this.images.appleIcon ,
      "info": this.extraLabels[this.selectedLanguage].click_for_ios_download 
    }
  ];
  currentImageIndex: number = 0;


  constructor(
    private store: Store<fromStore.CEPState>,
    private route: ActivatedRoute,
    private router:Router,
    private encrDecr: EncrDecrService,
    private localStorageService: LocalStorageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private deviceService: DeviceDetectorService,
		private apiCallService: ApiCallService,		
    private ref: ChangeDetectorRef,    

  ) { }

  ngOnInit() {
    this.googleAnalyticsService.eventEmitter(
			screenName.ELECTION_POPUP+ "_SCREEN",
			{
			  log: clickedOn.ELECTION_POPUP_CARD,
			  screenName: screenName.ELECTION_POPUP + "_UNMOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	

      this.startImageInterval();

    this.store.select(fromStore.getVerifyPasswordResponse)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(loginData => {
				this.loginData = loginData;
			}); 

      this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					this.userId = this.encrDecr.get(this.localStorageService.getUserId());
					//this.googleAnalyticsService.eventEmitter('Dashboard', 'feeds section loaded by ' + this.userId, 'Page Load', 1);
				} else {
					this.userId = null;
					//this.googleAnalyticsService.eventEmitter('Dashboard', 'feeds section loaded ', 'Page Load', 1);
				}
			});


    this.store.select(fromStore.getSelectedLanguage)
    .subscribe((language) => {
      this.selectedLanguage = language;
      if (this.selectedLanguage) {
        this.store.select(fromStore.getBannerImages).subscribe((bannerImages) => {
          this.feedImag = bannerImages;
          this.feedImag = bannerImages[this.selectedLanguage]['feed'];
          if (this.feedImag) {
            this.postImages = this.feedImag['feedsImages'];
          }
        });
        this.store.select(fromStore.getFeedsLabels)
          .subscribe((labels) => {
            if (labels) {
              this.feedsLabels = labels;
            }
          });
      }
    
      this.cardLinks1=[
        { "label": this.extraLabels[this.selectedLanguage].check_your_name,
          "link": "https://electoralsearch.eci.gov.in/",
          "image": this.images.voterList ,
          "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
        },
        {"label":this.extraLabels[this.selectedLanguage].polling_booth,
          "link": "https://electoralsearch.eci.gov.in/pollingstation",
          "image": this.images.pollingBooth ,
          "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
        },
        {"label":this.extraLabels[this.selectedLanguage].know_your_candidate,
          "link": "https://affidavit.eci.gov.in/",
          "image": this.images.candidate ,
          "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
        },
        {"label":this.extraLabels[this.selectedLanguage].code_of_conduct,
          "link": "https://www.eci.gov.in/mcc",
          "image": this.images.codeOfConduct ,
          "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
        },
        {"label":this.extraLabels[this.selectedLanguage].download_eepic,
          "link": "https://voters.eci.gov.in/login",
          "image": this.images.downloadImage ,
          "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
        },
        {"label":this.extraLabels[this.selectedLanguage].election_managemnet,
          "link": "https://www.eci.gov.in/election-management",
          "image": this.images.electionResult ,
          "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
        },
        {"label":this.extraLabels[this.selectedLanguage].election_result,
          "link": "https://results.eci.gov.in/AcResultByeJan2024/index.htm",
          "image": this.images.electionMgmt ,
          "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
        },
        {
          "label":this.extraLabels[this.selectedLanguage].faq,
          "link": "https://www.eci.gov.in/mcc",
          "image": this.images.faq ,
          "info": this.extraLabels[this.selectedLanguage].click_here_for_info 
        }
      ]

      this.cardLinks2=[
        {
          "label":this.extraLabels[this.selectedLanguage].voter_helpline,
          "link": "https://play.google.com/store/apps/details?id=com.eci.citizen",
          "image": this.images.androidIcon ,
          "info": this.extraLabels[this.selectedLanguage].click_for_andorid_download 
        },
        {
          "label":this.extraLabels[this.selectedLanguage].voter_helpline,
          "link": "https://apps.apple.com/in/app/voter-helpline/id1456535004",
          "image": this.images.appleIcon ,
          "info": this.extraLabels[this.selectedLanguage].click_for_ios_download 
        },
        {
          "label": this.extraLabels[this.selectedLanguage].saksham_eci,
          "link": "https://play.google.com/store/apps/details?id=pwd.eci.com.pwdapp&hl=en_IN&gl=US",
          "image": this.images.androidIcon ,
          "info": this.extraLabels[this.selectedLanguage].click_for_andorid_download 
        },
        {
          "label":this.extraLabels[this.selectedLanguage].saksham_eci,
          "link": "https://apps.apple.com/in/app/saksham-eci/id1497864568",
          "image": this.images.appleIcon ,
          "info": this.extraLabels[this.selectedLanguage].click_for_ios_download 
        },
        {
          "label": this.extraLabels[this.selectedLanguage].eci_helpline,
          "link": "https://play.google.com/store/apps/details?id=com.eci.ksa",
          "image": this.images.androidIcon ,
          "info": this.extraLabels[this.selectedLanguage].click_for_andorid_download 
        },
        {
          "label": this.extraLabels[this.selectedLanguage].eci_helpline,
          "link": "https://apps.apple.com/in/app/kyc-eci/id1604172836",
          "image": this.images.appleIcon ,
          "info": this.extraLabels[this.selectedLanguage].click_for_ios_download 
        },
        {
          "label": this.extraLabels[this.selectedLanguage].cvigil_helpline,
          "link": "https://play.google.com/store/apps/details?id=in.nic.eci.cvigil",
          "image": this.images.androidIcon ,
          "info": this.extraLabels[this.selectedLanguage].click_for_andorid_download 
        },
        {
          "label": this.extraLabels[this.selectedLanguage].cvigil_helpline,
          "link": "https://apps.apple.com/in/app/cvigil/id1455719541",
          "image": this.images.appleIcon ,
          "info": this.extraLabels[this.selectedLanguage].click_for_ios_download 
        }
      ]
    })
  }

  handleCardClick(link: string): void {
    this.googleAnalyticsService.eventEmitter(
      screenName.ELECTION_POPUP + "_SCREEN",
     {
      log: clickedOn.ELECTION_POPUP_CARD,
      screenName: screenName.ELECTION_POPUP + "_SCREEN",
      SECTION: screenName.BANNER,
      clickedOn: clickedOn.ELECTION_POPUP_CARD,   
      time: new Date(),
      isInput: false,
      
    });

    const confirmation = window.confirm("Are you sure you want to go to an external source?");
    if (confirmation) {
      window.open(link, '_blank');
    }else{

    }
  }

  startImageInterval() {
    const intervalDuration = 15000; // 15 seconds
    setInterval(() => {
      this.nextImage();
    }, intervalDuration);
  }

  onClick(object:any){
  if (object.link.includes("/cep/")) {
    this.router.navigate([object.link]); 
  }else{
    window.open(object.link, '_blank');
  }
  }

  nextImage() {
    if (this.currentImageIndex < this.carousalImages.length - 1) {
      // this.currentImageIndex++;
      this.carouselInstance.next();
  
    } else {
      this.currentImageIndex = 0;
    }
  }
  ngAfterViewInit() {
    var elems = document.querySelectorAll(".carousel");
    var instances = M.Carousel.init(elems, this.options);

    const carouselElement = document.querySelector('.carousel');
    this.carouselInstance = M.Carousel.init(carouselElement, {
      fullWidth: true,
      indicators: true
    });

    // Start the automatic image change
    this.startAutomaticImageChange();
  }

  ngOnDestroy() {
    this.googleAnalyticsService.eventEmitter(
			screenName.ELECTION_POPUP+ "_SCREEN",
			{
			  log: clickedOn.ELECTION_POPUP_CARD,
			  screenName: screenName.ELECTION_POPUP + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
			);	  
   
  }
  startAutomaticImageChange() {
    if (this.carouselInstance && this.interval > 0) {
      setInterval(() => {
        // this.carouselInstance.next();
        this.carouselInstance.next();
      }, this.interval);
    } 
                                                          
  }
  stopAutomaticImageChange() {
    if (this.carouselInstance) {
      clearInterval(this.carouselInstance);
    }
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

}
