import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Images } from 'src/app/shared/config/images-config';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
// import { FormGroup, FormControl,Validators } from '@angular/forms';
// import { Store } from '@ngrx/store';
import * as fromStore from '../../store/index';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Store } from '@ngrx/store';
// import * as fromStore from '../../store/index';
import { EncrDecrService } from 'src/app/shared/services/encr-decr.service';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, NgIf } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { MatButton } from '@angular/material/button';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatIcon } from '@angular/material/icon';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix, MatError } from '@angular/material/form-field';

@Component({
    selector: 'app-one-click-registration',
    templateUrl: './one-click-registration.component.html',
    styleUrls: ['./one-click-registration.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatInput, FormsModule, MatRadioGroup, MatRadioButton, NgxGpAutocompleteModule, MatIcon, MatSuffix, MatError, MatDatepickerInput, MatDatepickerToggle, MatDatepicker, MatButton]
})
export class OneClickRegistrationComponent implements OnInit {
  // public passwordRegx = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
  public passwordRegx = /^[A-Za-z0-9`~!@#$%^&*()_+-=:'<>?;',./]{8,20}$/;
  // passwordCtrl = new FormControl('', [Validators.required, Validators.pattern(this.passwordRegx)]);
  images = Images
  registrationForm: UntypedFormGroup;
  selectedLanguage: string;
  isFullNameRequired: boolean = false;
  isGenderRequired: boolean = false;
  isEmailIdRequired: boolean = false;
  isAddressRequired: boolean = false;
  isNewPasswordRequired: boolean = false;
  isConfirmPasswordRequired: boolean = false;
  showGenderValidation: boolean = false;
  newpassword = '';
  confirmpassword = '';
  newPass: boolean = false;
  confirmPass: boolean = false;
  fullname: String = '';
  mobileNumber: String = '';
  emailId: String = '';
  address: String = '';
  gender: String = '';
  updatelabels: any;
  referralCode: String = '';
  showSelectedLanguage:any;
  options: any = {
    componentRestrictions: { country: 'IN' }
  }
  Addlat: any;
  Addlong: any;
  apiDetails: {};

  emailNotValid: boolean = false;
  passwordvalid: boolean = false;
  passwordsMatch: boolean = true;
  registerLabels: {};
  footerLabels: {};
  errMessage: any;
  registerMessage: any;
  successMessages: {};
  toastr: any;
  registerStatusCode: number;
  isLoaded: any;
  dateOfBirth:any = '';
  birthDate:any = '';
	minDate = new Date(1920, 10, 1);
	currentDate = new Date();
  newDay = this.currentDate.getDate();
	newMonth = this.currentDate.getMonth();
	newYear = this.currentDate.getFullYear() - 18;
	maxDate = new Date(this.newYear, this.newMonth, this.newDay);

  constructor( private store: Store<fromStore.CEPState>, private aRoute: ActivatedRoute,
    private apiCallService: ApiCallService,
    // private store: Store<fromStore.CEPState>,
    private encrDecr: EncrDecrService,
    private dialog: MatDialog,
		private ref: ChangeDetectorRef,
		public datepipe: DatePipe,
		private titleService: Title

    ) { 

    this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getprofileScreenLabels)
						.subscribe((labels) => {
							this.updatelabels = labels;
        
						});
				}
			});


    this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getRegisterScreenLabels)
						.subscribe((labels) => {

							this.registerLabels = labels;
              
            
						});
				}
        if (this.selectedLanguage === 'mar') {
					this.showSelectedLanguage = 'English';
				} else {
					this.showSelectedLanguage = 'मराठी';
				}
			});

      this.store.select(fromStore.getFooterLabels)
			.subscribe(res => {
				this.footerLabels = res;
      
			});
  }



  ngOnInit() {

    this.getParams()
		this.titleService.setTitle('PMC CARE: OCR Registration Page');
    this.store.select(fromStore.getApiCallingList)
      .subscribe((apiDetails) => {
        this.apiDetails = apiDetails;
      });

    this.registrationForm = new UntypedFormGroup({
      gender: new UntypedFormControl(null)
    });

    this.store.select(fromStore.registerUserLoaded)
      .subscribe((response) => {
        this.isLoaded = response;
        if (this.isLoaded) {
          this.store.select(fromStore.registerStatusCode)
            .subscribe((statusCode) => {
              this.registerStatusCode = statusCode;
              if (this.registerStatusCode === 200) {
                this.store.select(fromStore.registerUserErrorMessage)
                  .subscribe((registerMessage) => {
                    this.registerMessage = registerMessage;
                    
                    const dialogRef = this.dialog.open(SuccessDialogComponent, {
                      width: '500px',
                      height: '300px',
                    });
                    if (this.registerMessage) {
                      this.store.select(fromStore.getSuccessMessages)
                        .subscribe(res => {
                          this.successMessages = res;
                          this.toastr.success(this.successMessages[this.selectedLanguage][this.registerMessage]);
                          this.registerMessage = null;
                        });
                      this.registerMessage = null;
                    }
                  });}
              else {
                this.errMessage = null;
              }
            });
        }
      });


       this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getprofileScreenLabels)
						.subscribe((labels) => {
							this.updatelabels = labels;
          
						});
				}
			});


    this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getRegisterScreenLabels)
						.subscribe((labels) => {

							this.registerLabels = labels;
              
            
              if (this.selectedLanguage === 'mar') {
                this.showSelectedLanguage = 'English';
              } else {
                this.showSelectedLanguage = 'मराठी';
              }
						});
				}
			});

      this.store.select(fromStore.getFooterLabels)
			.subscribe(res => {
				this.footerLabels = res;
        
	
			}); 
  }

  

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  validateFullName(): void {
    this.isFullNameRequired = false; // Reset the flag
    if (!this.fullname) {
      this.isFullNameRequired = true; // Set the flag if the field is empty
    }
  }

  onChangeLangButton() {

		let language = ''
		if (this.selectedLanguage === 'mar') {
			language = 'en';
		} else {
			language = 'mar';
		}
		// //this.googleAnalyticsService.eventEmitter('Language', 'Change Language to ' + this.selectedLanguage, 'Click Event', 1);
		this.store.dispatch(new fromStore.ChangeLanguage({
			language
		}));
	}

 
  validateGender(): void {
    this.isGenderRequired = this.gender === undefined || this.gender === '';
  }
  validateEmailId(): void {
    this.isEmailIdRequired = true;
  }
  validateAddress(): void {
    this.isAddressRequired = this.address === undefined || this.address.trim() === '';
  }

  validateNewPassword(): void {
    this.isNewPasswordRequired = this.newpassword === undefined || this.newpassword.trim() === '';
    
    if (this.passwordRegx.test(this.newpassword) === false) {
      this.passwordvalid = true

    } else {
      this.passwordvalid = false
    }
  }
  validateConfirmPass(): void {
    this.isConfirmPasswordRequired = this.confirmpassword === undefined || this.confirmpassword.trim() === '';
    this.passwordsMatch = this.newpassword === this.confirmpassword;
  }
  onChangeEvent(event: any) {

    var email = event.target.value;
  
    var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (!expr.test(email)) {
      this.emailNotValid = true
      this.emailId = email;
    } else {
      this.emailNotValid = false
      this.emailId = email;
    }
  }

  



  getPasswordError() {
    const passwordEnMessage = 'Passwords must be at least 8 characters in length and include at least one uppercase letter, one lowercase letter, one digit, and one special character.';
			const passwordMarMessage = 'आपला पासवर्ड हा किमान 8 कॅरेक्टर असणे आवश्यक आहे आणि कमीत कमी एक अपरकेस अक्षर, एक लोअरकेस अक्षर, एक अंक आणि एक विशेष वर्ण असला पाहिजे.';
    return !this.passwordRegx.test(this.newpassword) ? this.selectedLanguage === 'en' ? passwordEnMessage : passwordMarMessage : "";
  }

  getConfirmPasswordError(){
    return this.selectedLanguage === 'en' ? 'Passwords do not match' : 'पासवर्ड जुळत नाही'
  }



  handleDataAddressChange(address: Address) {
    this.address = address.formatted_address;
    this.Addlat = address.geometry.location.lat()
    this.Addlong = address.geometry.location.lng()
  }

  getParams() {
    this.aRoute.queryParams.subscribe((params:any) => {
  
      if (Object.values(params).length > 0) {
        this.fullname = params.Name;
        this.emailId = params.emailId;
        this.mobileNumber = params.mobile
      }
    });
  }

  registerUser() {

    let encrPassword = this.encrDecr.set(this.newpassword)

    const reqBody = {
      firstName: this.fullname,
      gender: this.gender,
      DOB: this.birthDate,
      mobile: '+91' + this.mobileNumber,
      address: [this.address],
      area: '',
      zone: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
      emailId: this.emailId,
      drivingLicence: '',
      referredBy: this.referralCode,
      aadharCard: '',
      password: encrPassword,
      createdSource: 'Web',
      sourceLocation: '',
      lang: this.selectedLanguage,
      lat: this.Addlat,
      long: this.Addlong
    };

  

    this.store.dispatch(new fromStore.RegisterUser({
    	 url: this.apiDetails['user'].base_url +
    		this.apiDetails['user'].pmcRegisterUser.url,

    	method: this.apiDetails['user'].pmcRegisterUser.method,
    	body: reqBody
    }));
  }

  ngAfterContentChecked() {
		this.ref.detectChanges();
    if(this.dateOfBirth){
			this.birthDate = this.datepipe.transform(this.dateOfBirth, 'MM-dd-yyyy');
    
    }
	}

  openwindow(){
		window.open('../../../assets/terms/terms-and-conditions.pdf');
  }

}
