<!--************************************* 
Created by: Akanksha Jadhav
Created Date: 27/12/19
updated by: Akanksha Jadhav
Updated Date: 10/01/2020
Description: Corporates info screen
***************************************** -->
<!-- loader -->
<div *ngIf="isLoading && !serverErrorMessage" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
 <!-- connection lost condition -->
 <div *ngIf="serverErrorMessage" class="connectionDiv">
    <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
    <span>{{serverErrorMessage}}</span>
 </div>
<!-- main page -->
<div class="container" *ngIf="!isLoading && !serverErrorMessage">
    <!--********************** Corporators Information******************-->
    <!-- header -->
    <div class="heading-container">
        <label class="heading"><strong class="font-fix" 
            (mouseenter)="speakText(aboutUsLabels[selectedLanguage].corporateInformation)"  (mouseleave)="stopSpeaking()">{{aboutUsLabels[selectedLanguage].corporateInformation}}</strong></label><br>
    </div>
    <!-- ward wise information -->
    <mat-expansion-panel class="expansion-panel" [expanded]="panelOpenState"
        *ngFor="let data of corporatesInfo; let i = index">
        <mat-expansion-panel-header class="expansion-header">
            <mat-panel-title>
                <strong class="expansion-title" 
                (mouseenter)="speakText(data[selectedLanguage]['ward'])"  (mouseleave)="stopSpeaking()">{{data[selectedLanguage]['ward']}}</strong>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="information-container">
            <mat-card class="card">
                <div class="profile">
                    <img class="image" src="{{data.imgCorporatorA}}" loading="lazy" alt="...">
                </div>
                <div class="main-info">
                    <span class="title" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].corporatorA)"  (mouseleave)="stopSpeaking()"><strong>{{aboutUsLabels[selectedLanguage].corporatorA}}</strong></span>
                    <div class="corporate-info"
                        *ngFor="let info of data[selectedLanguage]['corporatorA']; let j = index">
                        <div class="label" (mouseenter)="speakText(info[0])"  (mouseleave)="stopSpeaking()">{{info[0]}}</div>
                        <div class="description" (mouseenter)="speakText(info[1])"  (mouseleave)="stopSpeaking()">{{info[1]}}</div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card">
                <div class="profile">
                    <img class="image" src="{{data.imgCorporatorB}}" loading="lazy" alt="...">
                </div>
                <div class="main-info">
                    <span class="title" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].corporatorB)"  (mouseleave)="stopSpeaking()"><strong>{{aboutUsLabels[selectedLanguage].corporatorB}}</strong></span>
                    <div class="corporate-info"
                        *ngFor="let info of data[selectedLanguage]['corporatorB']; let j = index">
                        <div class="label" (mouseenter)="speakText(info[0])"  (mouseleave)="stopSpeaking()">{{info[0]}}</div>
                        <div class="description" (mouseenter)="speakText(info[1])"  (mouseleave)="stopSpeaking()">{{info[1]}}</div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card">
                <div class="profile">
                    <img class="image" src="{{data.imgCorporatorC}}" loading="lazy" alt="...">
                </div>
                <div class="main-info">
                    <span class="title" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].corporatorC)"  (mouseleave)="stopSpeaking()"><strong>{{aboutUsLabels[selectedLanguage].corporatorC}}</strong></span>
                    <div class="corporate-info"
                        *ngFor="let info of data[selectedLanguage]['corporatorC']; let j = index">
                        <div class="label" (mouseenter)="speakText(info[0])"  (mouseleave)="stopSpeaking()">{{info[0]}}</div>
                        <div class="description" (mouseenter)="speakText(info[1])"  (mouseleave)="stopSpeaking()">{{info[1]}}</div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card">
                <div class="profile">
                    <img class="image" src="{{data.imgCorporatorD}}" loading="lazy" alt="...">
                </div>
                <div class="main-info">
                    <span class="title" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].corporatorD)"  (mouseleave)="stopSpeaking()"><strong>{{aboutUsLabels[selectedLanguage].corporatorD}}</strong></span>
                    <div class="corporate-info"
                        *ngFor="let info of data[selectedLanguage]['corporatorD']; let j = index">
                        <div class="label" (mouseenter)="speakText(info[0])"  (mouseleave)="stopSpeaking()">{{info[0]}}</div>
                        <div class="description" (mouseenter)="speakText(info[1])"  (mouseleave)="stopSpeaking()">{{info[1]}}</div>
                    </div>
                </div>
            </mat-card>
        </div>
    </mat-expansion-panel>


<!-- *****************Nominated Corporators information******************** -->
<!-- header -->
    <div class="heading-container">
        <label class="heading" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].nominatedCorporators)"  (mouseleave)="stopSpeaking()"><strong class="font-fix">{{aboutUsLabels[selectedLanguage].nominatedCorporators}}</strong></label><br>
    </div>
    <!-- card for nominated corporators -->
    <mat-card class="nominated-card" *ngFor="let data of nominatedCorporatesInfo; let i = index">
        <div class="main-info">
            <div class="corporate-info"
                *ngFor="let info of data[selectedLanguage]; let j = index">
                <div class="label" (mouseenter)="speakText(info[0])"  (mouseleave)="stopSpeaking()">{{info[0]}}</div>
                <div class="description" (mouseenter)="speakText(info[1])"  (mouseleave)="stopSpeaking()">{{info[1]}}</div>
            </div>
        </div>
    </mat-card>
</div>