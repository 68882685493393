<div class="overlap-container" *ngIf="userId">
  <div class="carousel carousel-slider" *ngIf="carousalImages">
    <div class="carousel-item" *ngFor="let carousalImage of carousalImages">
    <!-- <div class="carousel-item"> {{imageLOad()}} -->
      <img src="{{carousalImage.image}}" loading="lazy" alt="slide image" (click)="onClick(carousalImage)" />
      <!-- <span class="centered" [innerHTML]="feedsImagesText"></span> -->
      <!-- <span class="centered" >{{feedsImagesText}}</span> -->
      </div>
        <!-- <div class="carousel-navigation">
          <button class="prev-button" (click)="previousImage()" >
            <span class="material-icons"> arrow_back_ios </span>
          </button>
          <button class="next-button" (click)="nextImage()" [disabled]="currentImageIndex === postImages.length - 1">
            <span class="material-icons"> arrow_forward_ios </span>
          </button>
        </div> -->
      </div>

  <section class="content sec-footerlist-cnt">
    <div class="view-content">
        <div class="pmc-news-card">
            <div class="card-submenu card-submenu_urben95" >             
                <h3 class=" mt-3 mb-3 text-center borderBottom">{{extraLabels[selectedLanguage].heading1}}</h3>
                <ul class="electionLinksWrapper">                   
                      <li *ngFor="let card of cardLinks1">
                        <div class="card" (click)="handleCardClick(card.link)">
                          <a href="javascript:void(0)" >                         
                            <img alt="Home | Pune Municipal Corporation" height="227" src="{{ card.image }}" typeof="foaf:Image" />
                            <span><b>{{ card.label }}</b><br>{{ card.info }}</span>
                          </a>
                        </div>
                      </li>
              </ul>

                <h3 class="mb-3 text-center borderBottom" style="margin-top:30px">{{extraLabels[selectedLanguage].heading2}}</h3>
                  <ul class="electionLinksWrapper">
                    <li *ngFor="let card of cardLinks1">
                      <div class="card" (click)="handleCardClick(card.link)">
                        <a href="javascript:void(0)" >
                          <img alt="Home | Pune Municipal Corporation" height="227" src="{{ card.image }}" typeof="foaf:Image" />
                          <span><b>{{ card.label }}</b><br>{{ card.info }}</span>
                        </a>
                      </div>
                    </li>
                  </ul>

                <h3 class="mb-3 text-center borderBottom" style="margin-top:30px">{{extraLabels[selectedLanguage].heading3}}</h3>
                  <ul class="electionLinksWrapper">
                    <li *ngFor="let card of cardLinks2">
                      <div class="card" (click)="handleCardClick(card.link)">
                        <a href="javascript:void(0)" >
                          <img alt="Home | Pune Municipal Corporation" height="227" src="{{ card.image }}" typeof="foaf:Image" />
                          <span><b>{{ card.label }}</b><br>{{ card.info }}</span>
                        </a>
                      </div>
                    </li>
                  </ul>
            </div>
        </div>
    </div>
  </section>
</div>