import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as ContactUsAction from '../../store/action/contact-us.action';
import { ContactUsService } from '../../store/api-services/contact-us.service';
import { of } from 'rxjs';

@Injectable()

export class ContactUsEffect {
	/**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
	constructor(
		private actions$: Actions,
		private ContactUsService: ContactUsService
	) { }

	apiContactUsDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<ContactUsAction.GetContactUsDetails>(ContactUsAction.GET_CONTACT_US_DETAILS),
			switchMap((action) => {
				return this.ContactUsService.getContactUsDetails(action.payload).pipe(
					map(contactUsData => new ContactUsAction.GetContactUsDetailsSuccess(contactUsData)),
					catchError(error => of(new ContactUsAction.GetContactUsDetailsFail(error)))
				);
			})
		)
	);
}
