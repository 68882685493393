import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ObjectInterface } from '../../models/global-interface';
import { environment } from 'src/environments/environment';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-report-abuse-popup',
    templateUrl: './report-abuse-popup.component.html',
    styleUrls: ['./report-abuse-popup.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatInput, FormsModule, MatButton]
})
export class ReportAbusePopupComponent implements OnInit {
	/**
	 * API details response
	 */
	apiDetails: ObjectInterface;
	/**
	 * token from local storage
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * reason as a input
	 */
	comment: any;
	/**
	 * spinner properties
	 */
	color = 'primary';
	/**
	 * spinner properties
	 */
	mode = 'indeterminate';
	/**
	 * spinner properties
	 */
	value = 50;
	/**
	 * loader status
	 */
	isLoading = false;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * Labels for report abuse
	 */
	reportAbuseLabels: {};

	/**
	 * Angular method which gets called on conponent initialization
	 * (first one to bget called)
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<ReportAbusePopupComponent> ) {
			dialogRef.disableClose = true;
		}

		/**
		 * angular method whice gets called on page initialization
		 */
	ngOnInit() {
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});

			this.store
    .select(fromStore.getApiCallingList)
    .subscribe((apiDetails) => {
      this.apiDetails=apiDetails;
      
	});

		this.store.select(fromStore.getSelectedLanguage)
		.subscribe(lang => {
			this.selectedLanguage = lang;
		});
		this.store.select(fromStore.getFeedsLabels)
		.subscribe((labels) => {
			this.reportAbuseLabels = labels;
			
		});
	}

	/**
	 * Created by Akanksha j
	 * For closing dialog
	 */
	closeDialog() {
		this.dialog.closeAll();
	}

	/**
	 * Created by Akanksha j
	 * For report a content as abused
	 */
	report() {
		
		
		if(!this.data.commentId && this.data.engagementId){		
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.ReportAbuse({
						url: this.apiDetails['userEngagement'].base_url +
						this.apiDetails['userEngagement'].abuseControl.storeAbuse.url,
					method: this.apiDetails['userEngagement'].abuseControl.storeAbuse.method,
						body: {
							userId: this.data.userId,
							timelineId: this.data.timelineId,
							engagementType: this.data.engagementType,
							comment: this.comment,
							engagementId: this.data.engagementId
						}
					}));
					this.store.select(fromStore.isReportAbuseLoading)
					.subscribe(loading => {
						this.isLoading = loading;
					});

					this.store.select(fromStore.isReportAbuseLoaded)
					.subscribe(loaded => {
						if (loaded) {
							this.store.select(fromStore.ReportAbuseStatusCode)
							.subscribe(code => {
								if (code === 200) {
									this.store.select(fromStore.ReportAbuseErrorMessage)
									.subscribe(message => {
										if (message) {
											this.store.select(fromStore.getSuccessMessages)
											.subscribe(successMessage => {
												this.toastr.success(successMessage[this.selectedLanguage][message]);
												this.dialog.closeAll();
											});
										}
									});
								} else {
									this.store.select(fromStore.ReportAbuseErrorMessage)
									.subscribe(message => {
										if (message) {
											this.store.select(fromStore.getErrorMessages)
											.subscribe(errorMessage => {
												console.error(errorMessage[this.selectedLanguage][message]);
												this.dialog.closeAll();
											});
										}
									});
								}
							});
						}
					});
				}
			})
		}else if(this.data.commentId && this.data.engagementId){			
			this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.ReportAbuseComment({
						url: environment.base_url + '/userEngagement/v1/AbuseCtrl/storeAbuseComment',
					method: 'post',
						body: {
							userId: this.data.userId,
							timelineId: this.data.timelineId,
							engagementType: this.data.engagementType,
							comment: this.comment,
							commentId: this.data.commentId,
							engagementId: this.data.engagementId
						}
					}));
					this.store.select(fromStore.isReportAbuseCommentLoading)
					.subscribe(loading => {
						this.isLoading = loading;
					});

					this.store.select(fromStore.isReportAbuseCommentLoaded)
					.subscribe(loaded => {
						if (loaded) {
							this.store.select(fromStore.ReportAbuseCommentStatusCode)
							.subscribe(code => {
								switch (code) {
									case 200:
										this.store.select(fromStore.ReportAbuseCommentErrorMessage)
										.subscribe(message => {
											if (message) {
												this.store.select(fromStore.getSuccessMessages)
												.subscribe(successMessage => {
													this.toastr.success(successMessage[this.selectedLanguage][message]);
													this.dialog.closeAll();
												});
											}
										});
										break;
									case 603:
										this.store.select(fromStore.ReportAbuseCommentErrorMessage)
										.subscribe(message => {
											if (message) {
												this.store.select(fromStore.getErrorMessages)
												.subscribe(errorMessage => {
													console.error(errorMessage[this.selectedLanguage][message]);
													this.dialog.closeAll();
												});
											}
										});
										break;
									default:
										this.store.select(fromStore.ReportAbuseCommentErrorMessage)
										.subscribe(message => {
											if (message) {
												this.store.select(fromStore.getErrorMessages)
												.subscribe(errorMessage => {
													console.error(errorMessage[this.selectedLanguage][message]);
													this.dialog.closeAll();
												});
											}
										});
										break;
								}
							});
						}
					});
				}
			})
		}else if(!this.data.commentId && this.data.webinarId){
			
			this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {			
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					
					this.store.dispatch(new fromStore.ReportWebinarAbuse({
						url: this.apiDetails['user'].base_url +
						this.apiDetails['user'].storeWebinarAbuse.url,
					method: this.apiDetails['user'].storeWebinarAbuse.method,
						body: {
							userId: this.data.userId,
							comment: this.comment,
							webinarId: this.data.webinarId
						}
					}));
					
					this.store.select(fromStore.isReportWebinarAbuseLoading)
					.subscribe(loading => {						
						this.isLoading = loading;
					});

					this.store.select(fromStore.isReportWebinarAbuseLoaded)
					.subscribe(loaded => {
						
						if (loaded) {
							this.store.select(fromStore.ReportWebinarAbuseStatusCode)
							.subscribe(code => {
								
								if (code === 200) {
									this.store.select(fromStore.ReportWebinarAbuseErrorMessage)
									.subscribe(message => {
										
										if (message) {
											this.store.select(fromStore.getSuccessMessages)
											.subscribe(successMessage => {												
												this.toastr.success(successMessage[this.selectedLanguage][message]);
												this.dialog.closeAll();
											});
										}
									});
								} else {
									
									this.store.select(fromStore.ReportWebinarAbuseErrorMessage)
									.subscribe(message => {
										
										if (message) {
											this.store.select(fromStore.getErrorMessages)
											.subscribe(errorMessage => {
												
												console.error(errorMessage[this.selectedLanguage][message]);
												this.dialog.closeAll();
											});
										}
									});
								}
							});
						}
					});
				}
			})
		}else if(this.data.commentId && this.data.webinarId){
			this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.ReportWebinarAbuseComment({
						url: this.apiDetails['user'].base_url +
						this.apiDetails['user'].storeWebinarAbuseComment.url,
					method: this.apiDetails['user'].storeWebinarAbuseComment.method,
						body: {
							userId: this.data.userId,
							comment: this.comment,
							commentId: this.data.commentId,
							webinarId: this.data.webinarId
						}
					}));
					this.store.select(fromStore.isReportWebinarAbuseCommentLoading)
					.subscribe(loading => {
						this.isLoading = loading;
					});

					this.store.select(fromStore.isReportWebinarAbuseCommentLoaded)
					.subscribe(loaded => {
						if (loaded) {
							this.store.select(fromStore.ReportWebinarAbuseCommentStatusCode)
							.subscribe(code => {
								switch (code) {
									case 200:
										this.store.select(fromStore.ReportWebinarAbuseCommentErrorMessage)
										.subscribe(message => {
											if (message) {
												this.store.select(fromStore.getSuccessMessages)
												.subscribe(successMessage => {													
													let msg = this.selectedLanguage === 'en' ? 'Report Abused Successful !!' : 'गैरवर्तनाची तक्रार यशस्वी !!'
													this.toastr.success(msg);
													this.dialog.closeAll();
												});
											}
										});
										break;
									case 603:
										this.store.select(fromStore.ReportWebinarAbuseCommentErrorMessage)
										.subscribe(message => {
											if (message) {
												this.store.select(fromStore.getErrorMessages)
												.subscribe(errorMessage => {
													console.error(errorMessage[this.selectedLanguage][message]);
													this.dialog.closeAll();
												});
											}
										});
										break;
									default:
										this.store.select(fromStore.ReportWebinarAbuseCommentErrorMessage)
										.subscribe(message => {
											if (message) {
												this.store.select(fromStore.getErrorMessages)
												.subscribe(errorMessage => {
													console.error(errorMessage[this.selectedLanguage][message]);
													this.dialog.closeAll();
												});
											}
										});
										break;
								}
							});
						}
					});
				}
			})
		}

		// this.store.select(fromStore.getApiCallingList)
		// 	.subscribe((apiDetails) => {
		// 		this.apiDetails = apiDetails;
		// 		if (Object.keys(this.apiDetails).length > 0) {
		// 			if(!this.data.commentId){
		// 				this.store.dispatch(new fromStore.ReportAbuse({
		// 					url: this.apiDetails.userEngagement.base_url +
		// 					this.apiDetails.userEngagement.abuseControl.storeAbuse.url,
		// 				method: this.apiDetails.userEngagement.abuseControl.storeAbuse.method,
		// 					body: {
		// 						userId: this.data.userId,
		// 						timelineId: this.data.timelineId,
		// 						engagementType: this.data.engagementType,
		// 						comment: this.comment,
		// 						engagementId: this.data.engagementId
		// 					}
		// 				}));
		// 				this.store.select(fromStore.isReportAbuseLoading)
		// 				.subscribe(loading => {
		// 					this.isLoading = loading;
		// 				});

		// 				this.store.select(fromStore.isReportAbuseLoaded)
		// 				.subscribe(loaded => {
		// 					if (loaded) {
		// 						this.store.select(fromStore.ReportAbuseStatusCode)
		// 						.subscribe(code => {
		// 							if (code === 200) {
		// 								this.store.select(fromStore.ReportAbuseErrorMessage)
		// 								.subscribe(message => {
		// 									if (message) {
		// 										this.store.select(fromStore.getSuccessMessages)
		// 										.subscribe(successMessage => {
		// 											this.toastr.success(successMessage[this.selectedLanguage][message]);
		// 											this.dialog.closeAll();
		// 										});
		// 									}
		// 								});
		// 							} else {
		// 								this.store.select(fromStore.ReportAbuseErrorMessage)
		// 								.subscribe(message => {
		// 									if (message) {
		// 										this.store.select(fromStore.getErrorMessages)
		// 										.subscribe(errorMessage => {
		// 											console.error(errorMessage[this.selectedLanguage][message]);
		// 											this.dialog.closeAll();
		// 										});
		// 									}
		// 								});
		// 							}
		// 						});
		// 					}
		// 				});
		// 			}
		// 			else if(this.data.commentId){
		// 				this.store.dispatch(new fromStore.ReportAbuseComment({
		// 					url: 'http://115.124.111.76:8002/v1/AbuseCtrl/storeAbuseComment',
		// 				method: 'post',
		// 					body: {
		// 						userId: this.data.userId,
		// 						timelineId: this.data.timelineId,
		// 						engagementType: this.data.engagementType,
		// 						comment: this.comment,
		// 						engagementId: this.data.engagementId
		// 					}
		// 				}));
		// 				this.store.select(fromStore.isReportAbuseCommentLoading)
		// 				.subscribe(loading => {
		// 					this.isLoading = loading;
		// 				});

		// 				this.store.select(fromStore.isReportAbuseCommentLoaded)
		// 				.subscribe(loaded => {
		// 					if (loaded) {
		// 						this.store.select(fromStore.ReportAbuseCommentStatusCode)
		// 						.subscribe(code => {
		// 							if (code === 200) {
		// 								this.store.select(fromStore.ReportAbuseCommentErrorMessage)
		// 								.subscribe(message => {
		// 									if (message) {
		// 										this.store.select(fromStore.getSuccessMessages)
		// 										.subscribe(successMessage => {
		// 											this.toastr.success(successMessage[this.selectedLanguage][message]);
		// 											this.dialog.closeAll();
		// 										});
		// 									}
		// 								});
		// 							} else {
		// 								this.store.select(fromStore.ReportAbuseCommentErrorMessage)
		// 								.subscribe(message => {
		// 									if (message) {
		// 										this.store.select(fromStore.getErrorMessages)
		// 										.subscribe(errorMessage => {
		// 											console.error(errorMessage[this.selectedLanguage][message]);
		// 											this.dialog.closeAll();
		// 										});
		// 									}
		// 								});
		// 							}
		// 						});
		// 					}
		// 				});
		// 			}
		// 		}
		// });

		
	}
}
