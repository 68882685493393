import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as  NearMeonMapAction from '../action/near-me-map.actions';
import { NearMeMapService } from '../api-services/near-me-map.service';
import { Observable, of } from 'rxjs';


@Injectable()

export class CfcCentresEffects {
    constructor(
        private actions$: Actions,
        private nearMeMapService: NearMeMapService
    ) {
    }
    cfcCentresListing$ = createEffect(() =>
        this.actions$.pipe(
            ofType<NearMeonMapAction.GetCfcCentres>(NearMeonMapAction.GET_CFC_CENTRES),
            switchMap((action) => {
                return this.nearMeMapService.getCfcCentres(action.payload).pipe(
                    map(labelData => new NearMeonMapAction.GetCfcCentresSuccess(labelData)),
                    catchError(error => of(new NearMeonMapAction.GetCfcCentresFail(error)))
                );
            })
        )
    );

    nearMeOnMap$ = createEffect(() =>
        this.actions$.pipe(
            ofType<NearMeonMapAction.GetNearMeOnMap>(NearMeonMapAction.GET_NEAR_ME_ON_MAP),
            switchMap((action) => {
                return this.nearMeMapService.getNearMeOnMAP(action.payload).pipe(
                    map(labelData => new NearMeonMapAction.GetNearMeOnMapSuccess(labelData)),
                    catchError(error => of(new NearMeonMapAction.GetNearMeOnMapFail(error)))
                );
            })
        )
    );

    bicycleOnMap$ = createEffect(() =>
        this.actions$.pipe(
            ofType<NearMeonMapAction.GetBicycleOnMap>(NearMeonMapAction.GET_BICYCLE_ON_MAP),
            switchMap((action) => {
                return this.nearMeMapService.getBicycleOnMap(action.payload).pipe(
                    map(bicycleData => new NearMeonMapAction.GetBicycleOnMapSuccess(bicycleData)),
                    catchError(error => of(new NearMeonMapAction.GetBicycleOnMapFail(error)))
                );
            })
        )
    );

    bikeOnMap$ = createEffect(() =>
        this.actions$.pipe(
            ofType<NearMeonMapAction.getBikeOnMap>(NearMeonMapAction.GET_BIKE_ON_MAP),
            switchMap((action) => {
                return this.nearMeMapService.getBikeOnMap(action.payload).pipe(
                    map(bikeData => new NearMeonMapAction.getBikeOnMapSuccess(bikeData)),
                    catchError(error => of(new NearMeonMapAction.getBikeOnMapFail(error)))
                );
            })
        )
    );
    
}

