<div class="popup">
  <div class="webinar-data" *ngIf="WebinarListData && landingLables">
    <!-- <div class='category-name font-medium'> {{landingLables[selectedLanguage].emergency | uppercase}}</div> -->
    <!-- heading -->
    <div class="feed-title">
      <label class="page-heading font-main-heading" (mouseenter)="speakText(landingLables[selectedLanguage].webinar)"
        (mouseleave)="stopSpeaking()">{{landingLables[selectedLanguage].webinar | uppercase}}</label>
    </div>
    <div *ngIf='serverMessage' class='connectionDiv'>
      <img src={{image.connectionLost}} class='connectionLostImg' loading="lazy" alt="...">
      <span>{{serverMessage}}</span>
    </div>
    <!-- main page loader -->
    <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
      <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
      </mat-progress-spinner>
    </div>
    <!-- connection lost condition -->
    <ng-container *ngIf=" WebinarListData.length == 0 && !serverMessage && !isLoading ">
      <div class="error-messages" (mouseenter)="speakText('no data found')" (mouseleave)="stopSpeaking()">
        <img src="{{images.noDataFound}}" class="no-data-image-favourite"loading="lazy" alt="..." >
        <span *ngIf="selectedLanguage === 'en'" class="data-not-found">Data not found</span>
        <span *ngIf="selectedLanguage === 'mar'" class="data-not-found">डेटा सापडला नाही</span>
      </div>
    </ng-container>
    <div class="webinar-array">
      <div class="webinar-list">
        <div class="overflow-div">
          <div class="last">
            <div class="data">
              <div class='font-normal card-content'>
                <mat-card class="services" *ngFor='let item of WebinarListData; let i = index'>
                  <img (click)="redirectToWebinarDetail(item)" class='service-image' src='{{item.image}}' loading="lazy" alt="..."
                    (mouseenter)="speakText(item[selectedLanguage].title)" (mouseleave)="stopSpeaking()">
                  <div class="webinar">
                    <div (click)="redirectToWebinarDetail(item)" matTooltip="{{item[selectedLanguage].title}}"
                      (mouseenter)="speakText(item[selectedLanguage].title)" (mouseleave)="stopSpeaking()"
                      class="label font-card-heading">{{item[selectedLanguage].title}}</div>
                    <div (click)="redirectToWebinarDetail(item)" matTooltip="{{item[selectedLanguage].description}}"
                      (mouseenter)="speakText(item[selectedLanguage].description)" (mouseleave)="stopSpeaking()"
                      class="label font-small opacity">{{item[selectedLanguage].description}}</div>
                    <div class="share-like">
                      <div class="date-format"
                        (mouseenter)="speakText(webinarLabels[selectedLanguage].webinarDate + returnedDate(item.updatedAt))"
                        (mouseleave)="stopSpeaking()">

                        <label class="font-normal"> {{webinarLabels[selectedLanguage].webinarDate}}: </label>
                        <div (click)="redirectToWebinarDetail(item)" class="label opacity">
                          {{item.createdAt | date }}</div>
                      </div>

                      <!-- <div class="abuse-flag-container">
                                        <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" 
                                        (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)"  (mouseleave)="stopSpeaking()"
                                        matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse(item)">flag</mat-icon>
                                    </div> -->
                      <!-- <div class="share-data" (click)="clickOnShare()"
                        (mouseenter)="speakText(landingLables[selectedLanguage].share)" (mouseleave)="stopSpeaking()">

                        <i class="material-icons" [matMenuTriggerFor]="menu"
                          matTooltip="{{landingLables[selectedLanguage].share}}">
                          share
                        </i> -->
                        <!-- </div> -->
                        <!-- <mat-menu #menu="matMenu">
                          <button mat-menu-item class="share-button" (click)="generateShareLink('facebook', item)"
                            (mouseenter)="speakText('share on Facebook')" (mouseleave)="stopSpeaking()">
                            <img class="social-share-img" src="{{images.facebookShareImg}}">
                            Facebook
                          </button>
                          <button mat-menu-item class="share-button" (click)="generateShareLink('twitter', item)"
                            (mouseenter)="speakText('share on Twitter')" (mouseleave)="stopSpeaking()">
                            <img class="social-share-img" src="{{images.twitterShareImg}}">
                            Twitter
                          </button>
                          <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin', item)"
                            (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
                            <img class="social-share-img" src="{{images.linkedinShareImg}}">
                            LinkedIn
                          </button>
                          <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp', item)"
                            (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()">
                            <img class="social-share-img whatsapp-share" src="{{images.whatsappShareImg}}">
                            Whatsapp
                          </button>
                        </mat-menu>
                        <div *ngIf="this.userId">
                          <i class="material-icons favorite-icon" [ngClass]="{ 'active-favorite': item.favourite }"
                            matTooltip="{{landingLables[selectedLanguage].addToFavorites}}"
                            (mouseenter)="speakText('add to favourites')" (mouseleave)="stopSpeaking()"
                            (click)="unMarkFavorite(item, i)">
                            {{item.favouriteIcon}}
                          </i>
                        </div>
                      </div> -->




                      
                    </div>

                  </div>
                </mat-card>
              </div>
              <ng-container
                *ngIf="isSeeMoreButtonShow && !isNewWebinarLoading && WebinarListData.length >= 10 && noData==true">
                <div class="load-more-button-allignment">
                  <button mat-button (click)="onScroll()" class="load-more-button font-normal"
                    (mouseenter)="speakText(feedsLabels[selectedLanguage].showMore)" (mouseleave)="stopSpeaking()">
                    {{ feedsLabels[selectedLanguage].showMore | uppercase }}
                  </button>
                </div>
              </ng-container>
              <div *ngIf="isNewWebinarLoading" class="new-feed-list-spinner">
                <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
                </mat-progress-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>