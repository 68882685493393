import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../cep/store/index';
import { Observable, Subscriber, Subject } from 'rxjs'
import { GroupComponent } from '../components/Merchant-flow/group/group.component'

@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  apiDetails: any;
  private eventCallback = new Subject<string>(); // Source
  eventCallback$ = this.eventCallback.asObservable(); // Stream
  
  socket: any;
  // readonly url: string = "https://api.pmccare.in/userService.io";
  // readonly url: string = this.apiDetails.base_url ;
  // url: any;
  // readonly url: string = "http://115.124.111.181:8001";
  readonly url: string = "api.digipmc.foxberry.link";

  constructor (
     private store: Store<fromStore.CEPState>
    ) { 
      this.store.select(fromStore.getApiCallingList)
      .subscribe((apiDetails) => {
        if (Object.keys(apiDetails).length > 0) {
          this.apiDetails = apiDetails["user"];
          
        }
      });
      // this.socket = io(this.url, {
      //   secure: true,
      //   rejectUnauthorized: false,
      //   path: '/user/socket.io'
      // });
  }
  
  

  // public connect(groupId) {
  
  //   this.listen(groupId);
  // }



  public listen(groupId) {
    this.socket.on("connect", () => {
    
    });

    this.socket.on("disconnect", () => {

    });
    // if(groupId) {
      this.socket.on('pcmc-' + groupId + '-message',(payload => {
        
        this.eventCallback.next(payload);
      }));
    // }
  //  else {
    this.socket.on('Notification',(payload => {
      this.eventCallback.next(payload);
    }));
    this.socket.on('Reset Notification',(payload => {
      this.eventCallback.next(payload);
    }));
  //  }
  }

  public listenForNotification() {
    this.socket.on('Notification',(payload => {
      
      this.eventCallback.next(payload);
    }));
    this.socket.on('Reset Notification',(payload => {
      
      this.eventCallback.next(payload);
    }));
    this.socket.on('Clear Cart',(payload => {
    
      this.eventCallback.next(payload);
    }));
    this.socket.on('Cart Updated',(payload => {
    
      this.eventCallback.next(payload);
    }));
  }

}
