import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../shared/services/localstorage.service';
import { MatCard } from '@angular/material/card';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-corporates-information',
    templateUrl: './corporates-information.component.html',
    styleUrls: ['./corporates-information.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, NgFor, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatCard]
})
export class CorporatesInformationComponent implements OnInit, OnDestroy {
  selectedLanguage: any;
  apiDetails: any;
  images = Images;
  aboutUsLabels: any;
  isLoading: boolean;
  serverErrorMessage: string;
  isLoaded = false;
  corporatesInfo: any;
  color = 'primary';
  mode = "indeterminate";
  value = 50;
  nominatedCorporatesInfo: any;
  sampleImage = 'https://iecos.enlightcloud.com:8443/v2.1/AUTH_1eef31df48f64a7aab2b45dab0274475/PCMCSmartSarathi/user/U59581577366491590/profile/U59581577366491590gboaklgoodam';
  isBlind;
    /**
     *
     * angular method for declaring packages in variables
     *
     * which runs first in this particular component
    */
  constructor(
    private router: Router,
    private store: Store<fromStore.CEPState>,
    private googleAnalyticsService: GoogleAnalyticsService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    public dialog:MatDialog
  ) { 
    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
      });

    this.store.select(fromStore.getApiCallingList)
      .subscribe((apiDetails) => {
        this.apiDetails = apiDetails;
      });

      // server error handling
    this.store.select(fromStore.getCorporatesDetailsServerErrorMessage)
    .subscribe((serverError) => {
      if (serverError != null) {
        if (serverError == 'InvalidCredentials') {
          this.router.navigate(['/Login/login']);
        }
        else {
          this.serverErrorMessage = serverError
          if (this.serverErrorMessage) {
            console.error(this.serverErrorMessage);
          }
        }
        // this.serverErrorMessage = null;
      }
    });

      //API call for corporates information 
    this.store.dispatch(new fromStore.GetCorporatesDetails({
      url: this.apiDetails['applicationConfigurations'].base_url +
        this.apiDetails['applicationConfigurations']['aboutUs'].getCorporatorInfo.url,
      method: this.apiDetails['applicationConfigurations']['aboutUs'].getCorporatorInfo.method
    }));
  }

  /**
	 * angular method which gets called on page initialization
	 */
  ngOnInit() {
    this.store.select(fromStore.getAboutUsLabels)
      .subscribe(response => {
        this.aboutUsLabels = response;
      });

    this.store.select(fromStore.getIsCorporatesDetailsLoading)
      .subscribe(loading => {
        this.isLoading = loading;
      });

      // server error messages handling 
    this.store.select(fromStore.getCorporatesDetailsServerErrorMessage)
      .subscribe((serverErrorMessage) => {
        if (serverErrorMessage != null) {
          this.serverErrorMessage = serverErrorMessage;
          console.error(this.serverErrorMessage);
        }
      });

      //response of API 
    this.store.select(fromStore.getIsCorporatesDetailsLoaded)
      .subscribe(loaded => {
        this.isLoaded = loaded;
        if (this.isLoaded) {
          this.store.select(fromStore.getCorporatesDetailsResult)
            .subscribe((res) => {
              this.corporatesInfo = res['listOfCorporatorsInfo'];
              this.nominatedCorporatesInfo = res['nominatedCorporatorInfo'];
            });
        }
      });
  }


/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }



  ngOnDestroy() {
    this.serverErrorMessage = null;
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

}
