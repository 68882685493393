<div class="modal">
  <div class="modal-content">
    <div class="text">

      <mat-icon class="cross-button" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>

      <div class="AllText">
        <span class="header">{{ labels[selectedLanguage].join_us_heading }}</span>
        <!-- <span class="header" *ngIf=" selectedLanguage === 'en' ">Join us for an exciting journey together</span> -->
        <label class="label" *ngIf=" selectedLanguage === 'mar' ">लाॅगीन करण्यासाठी येथे क्लिक करा</label>
        <label class="label" *ngIf=" selectedLanguage === 'en' ">Click Here to Login.</label>
        <button mat-button mat-stroked-button class="login" (click)="login()">
          <span *ngIf=" selectedLanguage === 'en' ">Login</span>
          <span *ngIf=" selectedLanguage === 'mar' ">लाॅगीन</span>
        </button>
      </div>
      <img src="{{images.backgroundWithLogo1}}" class="image" loading="lazy" alt="..." />
    </div>
  </div>
</div>