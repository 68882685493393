<!-- /***************************************
 * Component -  article component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ****************************************/ -->
<div class="feed-card">
  <!-- image of card -->
  <div class="feed-image">
    <img mat-card-image (click)="onClick()" src="{{articleData.imageFavourite[0]}}" loading="lazy" alt="...">
  </div>
  <!-- main page -->
  <div class="feed-content">
    <div class="feed-tag" (mouseenter)="speakText(landingLables[selectedLanguage].article)"
      (mouseleave)="stopSpeaking()">
      <p>{{landingLables[selectedLanguage].article | uppercase}}</p>
    </div>
    <div class="feed-heading" matTooltip="{{articleData[selectedLanguage].heading}}" (click)="onClick()"
      (mouseenter)="speakText(articleData[selectedLanguage].heading)" (mouseleave)="stopSpeaking()">
      <p class="font-card-heading">{{articleData[selectedLanguage].heading}}</p>
    </div>
    <div class="feed-desc" matTooltip="{{articleData[selectedLanguage].description}}" (click)="onClick()"
      (mouseenter)="speakText(articleData[selectedLanguage].description)" (mouseleave)="stopSpeaking()">
      <p class="font-card-desc">{{articleData[selectedLanguage].description}}</p>
    </div>
    <div class="article-footer">
      <div class="feed-date" (mouseenter)="speakText('article  added by' + articleData.createdBy)"
        (mouseleave)="stopSpeaking()">{{articleDate}}</div>
      <div class="article-action-logo" [ngClass]="{'login-active-favourite': !this.userId}">
        <div class="abuse-flag-container">
          <!-- <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()" (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)" (mouseleave)="stopSpeaking()">flag</mat-icon> -->
        </div>
        <!-- share options -->
        <div *ngIf="userId" class="share-logo" (click)="clickOnShare()"><i class="material-icons"  matTooltip="{{landingLables[selectedLanguage].share}}" (mouseenter)="speakText(landingLables[selectedLanguage].share)" (mouseleave)="stopSpeaking()">
            share
          </i>
        </div>
        <!-- <mat-menu #menu="matMenu">
          <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')" (mouseenter)="speakText('share on facebook')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.facebookShareImg}}">
            Facebook
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on twitter')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.twitterShareImg}}">
            Twitter
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.linkedinShareImg}}">
            LinkedIn
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()"> 
            <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
            Whatsapp
          </button>
        </mat-menu> -->
        <!-- favourite button -->
        <div *ngIf="this.userId">
          <i class="material-icons favorite-icon" [ngClass]="{'active-favorite':articleData.favourite}"
             (click)="unMarkFavorite()"
            (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)" (mouseleave)="stopSpeaking()">
            {{favorite}}
          </i>
        </div>
      </div>
    </div>
  </div>
</div>