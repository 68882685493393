import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, PipeTransform, Pipe, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { FeedbackComponent } from '../feedback/feedback.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Images } from '../../config/images-config';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Location, NgIf, NgFor, UpperCasePipe, SlicePipe } from '@angular/common';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { ObjectInterface } from '../../models/global-interface';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ClipboardService } from '../../services/clipboard.service';
import { environment } from 'src/environments/environment';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { DetailsPageSliderComponent } from '../details-page-slider/details-page-slider.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
// @Pipe({ name: 'safeHtml' })

@Component({
    selector: 'app-details-page-forum',
    templateUrl: './details-page-forum.component.html',
    styleUrls: ['./details-page-forum.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatTooltip, RouterLink, DetailsPageSliderComponent, MatIcon, MatMenuTrigger, MatMenu, MatMenuItem, FormsModule, MatButton, NgFor, UpperCasePipe, SlicePipe]
})
export class DetailsPageForumComponent implements OnInit {

	@Input() forumdata: any;
	/**
	* feedsData object
	*/
	public feedsData;
	/**
	* object for feeds label
	*/
	feedLabels: {};
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	* mode of spinner
	*/
	mode = "indeterminate";
	/**
	 * value of spinner
	 */
	value = 50;
	/**
	* loading status of spinner
	*/
	isLoaded = false;
	/**
	* loading status of spinner
	*/
	isLoading = false;
	/**
	* timelineId of forum
	*/
	timelineId: any;
	/**
	* shareUrl variable
	*/
	shareUrl: any;
	/**
	* token variable to store user token
	*/
	token: string;
	/**
 * api details response object
 */
	apiDetails : ObjectInterface;
	/**
	* userId variable to store user id
	*/
	userId;
	/**
	 * fetched images from image.config
	 */
	images = Images;
	/**
	* selected language variable
	*/
	selectedLanguage: string;
	/**
	 * array for feed details result
	 */
	feedDetailsResultArray: [];
	/**
	* array for feed details comments
	*/
	feedDetailsComments: ObjectInterface[];
	/**
	* feedFilterLabels for forum name
	*/
	feedFilterLabels: {};
	/**
	* object for landing lables
	*/
	landingLables: {};
	/**
	* feeds date variable
	*/
	feedsDate: string;
	/**
	 * error message
	 */
	errMessage: string;
	//feedData: {}[];
	/**
	* forum comment variable
	*/
	forumComment: any;
	/**
	* object for successMessages
	*/
	successMessages: {};
	/**
	 * object for feed details result
	 */
	feedDetailsResult: {};
	/**
	 * feedsContent variable
	 */
	feedsContent: any;	
	/**
	 * variable for comment date
	 */
	commentDate: any;
	/**
	 * object for user details
	 */
	UserProfileDetails: {};
	/**
	* object for  remove blog comment
	*/
	removeBlogCommentData: ObjectInterface;
	forumId: any;
	isUserAddedForum = false;
	isApproved: any;
	isLoadingForum: boolean;
	blogLabels: {};
	imageContent:any;

	constructor(
		private activeParams: ActivatedRoute,
		private store: Store<fromStore.CEPState>,
		private changeDetectRef: ChangeDetectorRef,
		private spinner: NgxSpinnerService,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		public dialog: MatDialog,
		private deviceService: DeviceDetectorService,
		private domSanitizer: DomSanitizer,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private apiCallService: ApiCallService,
		private router: Router,
		private location:Location,
		private clipboardService: ClipboardService,
		private dynamicLink: DynamicLinksService
	) {
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
					//this.googleAnalyticsService.eventEmitter('Dashboard', 'Click on forum by ' + this.userId, 'userLabel', 1);
				} else {
					this.userId = null;
				}
			});
			this.store.select(fromStore.getForumLabels)
			.subscribe(res => {
				if (res) {
					this.blogLabels = res;
				}
			});
	}

	// ngOnInit() {
		
	// 	this.googleAnalyticsService.eventEmitter(
	// 		screenName.FORUM_DETAILS+ "_SCREEN",
	// 		{
	// 		  log: clickedOn.LOG_SCREEN,
	// 		  screenName: screenName.FORUM_DETAILS + "_MOUNT",
	// 		  screenStartTime: new Date(),
	// 		  isScroll: false,
	// 		  screenEndTime: "",
	// 		  scrollValue: "",
	// 		}
	// 		);	
	// 	var voices = speechSynthesis.getVoices();
		
	// 	this.store.select(fromStore.getApiCallingList)
	// 		.subscribe((apiDetails) => {
	// 			this.apiDetails = apiDetails;
	// 			this.shareUrl = this.apiDetails['shareUrl'];
	// 		});
	// 	this.activeParams.params.subscribe((params) => {
	// 		this.timelineId = params.timelineId;
			
	// 		this.store.dispatch(new fromStore.CheckTimeline({
	// 			timelineId: this.timelineId
	// 		}))
			
	// 		if (Object.keys(this.apiDetails).length > 0) {
	// 			this.shareUrl = this.apiDetails['shareUrl'];
	// 			this.store.dispatch(new fromStore.GetEngeagementDetails({
	// 				url: this.apiDetails['userEngagement'].base_url +
	// 					this.apiDetails['userEngagement'].timeline.getEngagement.url,
	// 				method: this.apiDetails['userEngagement'].timeline.getEngagement.method,
	// 				body: {
	// 					'userId': this.userId,
	// 					'timelineId': this.timelineId,
	// 				}
	// 			}));
	// 		}
	// 		this.store.select(fromStore.isDetailsScreenLoading)
	// 			.subscribe(isLoading => {
	// 				this.isLoading = isLoading;
	// 			});
	// 		this.store.select(fromStore.feedDetailsResult)
	// 			.subscribe((res:any) => {
					
	// 				this.feedsData = res.result;
	// 				this.errMessage = null;
	// 				this.isApproved = this.feedsData.isApproved;					 
	// 				if (params.isForumFrom == 'feeds') {
	// 					this.isUserAddedForum = false;
	// 				}
	// 				else if (params.isForumFrom == 'myForum') {
	// 					this.isUserAddedForum = true;
	// 				}
	// 				this.feedsDate = new Date(res['updatedAt']).toDateString();
	// 				if (Object.keys(this.feedsData).length != 0) {
	// 					console.log("feeds",this.feedsData)
	// 					this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
	// 					// if (this.feedsData['favourite']) {
	// 					//   this.favorite = 'favorite';
	// 					// } else {
	// 					//   this.favorite = 'favorite_border';
	// 					// }
	// 				}
	// 			});
	// 	});
	// 	this.store.select(fromStore.getVerifyPasswordResponse)
	// 		.subscribe((data) => {
	// 			this.UserProfileDetails = data;
	// 		});

	// 	this.store.select(fromStore.AddOrRemoveBlogCommentStatusCode)
	// 		.subscribe(statusCode => {
	// 			if (statusCode == 200) {
	// 				this.store.select(fromStore.AddOrRemoveBlogCommentErrorMessage)
	// 					.subscribe((message) => {
	// 						if (message) {
	// 							this.store.select(fromStore.getSuccessMessages)
	// 								.subscribe(res => {
	// 									this.successMessages = res;
	// 									this.toastr.success(this.successMessages[this.selectedLanguage][message]);
	// 								});
	// 							message = null;
	// 						}
	// 					});
	// 				this.forumComment = null;
	// 				this.store.dispatch(new fromStore.GetEngeagementDetails({
	// 					url: this.apiDetails['userEngagement'].base_url +
	// 						this.apiDetails['userEngagement'].timeline.getEngagement.url,
	// 					method: this.apiDetails['userEngagement'].timeline.getEngagement.method,
	// 					body: {
	// 						'userId': this.userId,
	// 						'timelineId': this.timelineId,
	// 					}
	// 				}));
	// 			}
	// 		})
	// 	this.store.select(fromStore.feedDetailsResult)
	// 		.subscribe(feedDetailsResult => {
	// 			this.feedDetailsResultArray = feedDetailsResult['comments'];
	// 			if (this.feedDetailsResultArray) {
	// 				this.feedDetailsResultArray.forEach((element: any) => {
	// 					if (element.comment.length > 50) {
	// 						element['visible'] = false;
	// 					} else {
	// 						element['visible'] = true;
	// 					}
	// 				});
	// 			}

	// 		});


	// 	this.store.select(fromStore.getSelectedLanguage)
	// 		.subscribe((language) => {
	// 			this.selectedLanguage = language;
	// 			this.store.select(fromStore.getLandingScreenLabels)
	// 				.subscribe((labels) => {
	// 					this.landingLables = labels;
	// 					//console.log("landingLabels:",this.landingLables)

	// 				});

	// 			this.store.select(fromStore.getfeedsFilterLabel)
	// 				.subscribe((labels) => {
	// 					this.feedFilterLabels = labels;
	// 				});

	// 			this.store.select(fromStore.getFeedsLabels)
	// 				.subscribe((labels) => {
	// 					this.feedLabels = labels;
	// 				});
	// 		});


	// 	this.store.select(fromStore.isDetailsScreenLoading)
	// 		.subscribe(isLoading => {
	// 			this.isLoading = isLoading;
	// 		});

	// 	this.store.select(fromStore.isDetailsScreenLoading)
	// 		.subscribe(isLoaded => {
	// 			this.isLoaded = isLoaded;
	// 			if (this.isLoaded) {
	// 				this.store.select(fromStore.getFeedsServerErrorMessage)
	// 					.subscribe((error) => {
	// 						this.errMessage = error;
	// 						this.store.dispatch(new fromStore.GetEngeagementDetails({
	// 							url: this.apiDetails['userEngagement'].base_url +
	// 								this.apiDetails['userEngagement'].timeline.getEngagement.url,
	// 							method: this.apiDetails['userEngagement'].timeline.getEngagement.method,
	// 							body: {
	// 								'userId': this.userId,
	// 								'timelineId': this.timelineId,
	// 							}
	// 						}));
	// 						// if (this.errMessage) {
	// 						// 	window.location.reload()
	// 						// }
	// 					})


	// 			}
	// 		});

	// 	this.store.select(fromStore.getFeedsListingErrorMessage)
	// 		.subscribe(errMessage => {
	// 			if (errMessage) {
	// 				this.errMessage = errMessage;
	// 			}
	// 		});

	// 	this.store.select(fromStore.getSelectedLanguage)
	// 		.subscribe((language) => {
	// 			this.selectedLanguage = language;

	// 		});
	// 	//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on forum', 'userLabel', 1);

	// }

	ngOnInit() {

		this.googleAnalyticsService.eventEmitter(
			screenName.FORUM_DETAILS+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.FORUM_DETAILS + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});
		this.activeParams.params.subscribe((params:any) => {

			this.timelineId = params.timelineId;
			// console.log("timelieID:",this.timelineId)

			this.store.dispatch(new fromStore.CheckTimeline({
				timelineId: this.timelineId
			}))	

		});
		
        this.fetchComments();
		
		this.store.select(fromStore.getFeedsListingErrorMessage)
			.subscribe(errMessage => {
				if (errMessage) {
					this.errMessage = errMessage;
				}
			});
			this.store
    .select(fromStore.getApiCallingList)
    .subscribe((apiDetails) => {
      this.apiDetails=apiDetails;      
    });

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getLandingScreenLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.feedFilterLabels = labels;
					});

				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedLabels = labels;
					});
			});
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on blog', 'userLabel', 1);
		this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((data) => {
				this.UserProfileDetails = data;
			});
	}


	/**
		 * Created by Vinita Sharma
		 * For removing HTML tags from text 
		 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;

		return this.splitSentance(span.textContent || span.innerText)
	};

	/**
   * Created by Vinita Sharma
   * to split the paragraph in sentances
   */
	splitSentance(sen) {
		if (!sen.match(/[^\.!\?]+[\.!\?]+/g)) {
			return [sen]
		} else {
			return sen.match(/[^\.!\?]+[\.!\?]+/g)
		}
	}

	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak, t2) {
		speechSynthesis.cancel();
		var newmessage = this.extractContent(textToSpeak)
		// var sentanceList = this.splitSentance(newmessage)
		var voices = speechSynthesis.getVoices();
		newmessage.unshift(t2);
	

		for (let sentance = 0; sentance < newmessage.length; sentance++) {
			var message = new SpeechSynthesisUtterance(newmessage[sentance]);
			message.voice = voices[9];
			message.lang = 'hi-IN';
			message.volume = 1;
			speechSynthesis.speak(message);
		}
	}



	/**
	 * Created by Neha 
	 * For redirect to edit blog
	 */

	redirectToAddForum(data) {
		this.router.navigate(['/cep/add-forum/' + data]);
	}

	/**
	 * Created by Akanksha J
	 * for reporting forum as abuse
	 */
	REPORT_ABUSE() {
		this.googleAnalyticsService.eventEmitter(screenName.FORUM_DETAILS, {
			log: clickedOn.REPORT_ABUSE,
			screenName: screenName.FORUM_DETAILS + "_SCREEN",
			SECTION: screenName.FORUM_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.REPORT_ABUSE,
			time: new Date(),
			isInput: false,

		  })
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.feedsData.timelineId,
				engagementType: 'forum',
				engagementId: this.feedsData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}

	/**
	 * Created by Akshata Choulwar
	 * For adding forum comment
	 */
	addForumComment(comment) {
		this.googleAnalyticsService.eventEmitter(screenName.FORUM_DETAILS, {
			log: clickedOn.ADDFORUMCOMMENT,
			screenName: screenName.FORUM_DETAILS + "_SCREEN",
			SECTION: screenName.FORUM_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.ADDFORUMCOMMENT,
			time: new Date(),
			isInput: false,

		  })
		let commentData = {};
		commentData['userId'] = this.userId;
		commentData['updatedBy'] = this.UserProfileDetails['firstName'];
		commentData['firstName'] = this.UserProfileDetails['firstName'];
		commentData['middleName'] = this.UserProfileDetails['middleName']
		commentData['lastName'] = this.UserProfileDetails['lastName']
		commentData['comment'] = comment;
		commentData['updatedAt'] = Date();
		commentData['engagementId'] = this.feedsData.engagementId;
		commentData['profilePhoto'] = this.UserProfileDetails['profilePhoto'];
		if (comment.length > 50) {
			commentData['visible'] = false;
		} else if (comment.length < 50) {
			commentData['visible'] = true;
		}

		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				this.store.dispatch(new fromStore.AddOrRemoveBlogComment({
					url: this.apiDetails['userEngagement'].base_url +
						this.apiDetails['userEngagement'].comment.addComment.url,
					method: this.apiDetails['userEngagement'].comment.addComment.method,
					body: {
						"userId": this.userId,
						"engagementId": this.feedsData['engagementId'],
						"firstName": this.UserProfileDetails['firstName'],
						"middleName": this.UserProfileDetails['middleName'],
						"lastName": this.UserProfileDetails['lastName'],
						"profilePhoto": this.UserProfileDetails['profilePhoto'],
						"comment": this.forumComment
					}
				}));
				this.forumComment = null;
				if (this.isUserAddedForum) {
					this.store.dispatch(new fromStore.GetForumByForumId({
						url: this.apiDetails['userEngagement'].base_url +
							this.apiDetails['userEngagement'].forum.getForumById.url,
						method: this.apiDetails['userEngagement'].forum.getForumById.method,
						body: {
							forumId: this.forumId,
							userId: this.userId
						}
					}))
					this.fetchComments();
				}
				else {
					this.store.dispatch(new fromStore.GetEngeagementDetails({
						url: this.apiDetails['userEngagement'].base_url +
							this.apiDetails['userEngagement'].timeline.getEngagement.url,
						method: this.apiDetails['userEngagement'].timeline.getEngagement.method,
						body: {
							'userId': this.userId,
							'timelineId': this.timelineId,
						}
					}));
					this.fetchComments();
				}
			}
			else if (!this.userId) {
				this.router.navigate(['/Login/login']);
			}
		}
	}

	/**
		 * Created by Akshata Choulwar
		 * For removing forum comment
		 */
	removeForumComment(commentId, updatedBy, index) {
		this.googleAnalyticsService.eventEmitter(screenName.FORUM_DETAILS, {
			log: clickedOn.REMOVEFORUMCOMMENT,
			screenName: screenName.FORUM_DETAILS + "_SCREEN",
			SECTION: screenName.FORUM_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.REMOVEFORUMCOMMENT,
			time: new Date(),
			isInput: false,

		  })
		this.feedDetailsResultArray.splice(index, 1);
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				this.store.dispatch(new fromStore.AddOrRemoveBlogComment({
					url: this.apiDetails['userEngagement'].base_url +
						this.apiDetails['userEngagement'].comment.removeComment.url,
					method: this.apiDetails['userEngagement'].comment.removeComment.method,
					body: {
						"userId": this.userId,
						"updatedBy": updatedBy,
						"commentId": commentId
					}
				}));
				this.store.dispatch(new fromStore.GetEngeagementDetails({
					url: this.apiDetails['userEngagement'].base_url +
						this.apiDetails['userEngagement'].timeline.getEngagement.url,
					method: this.apiDetails['userEngagement'].timeline.getEngagement.method,
					body: {
						'userId': this.userId,
						'timelineId': this.timelineId,
					}
				}));
			}
			else if (!this.userId) {
				this.router.navigate(['/Login/login']);
			}
		}
	}

	/**
	 * Created By Neha R
	 * For reporting comment as abuse
	 */
	reportComment(data) {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.feedsData.timelineId,
				engagementType: this.feedsData.engagementType,
				engagementId: this.feedsData.engagementId,
				commentId: data.commentId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}

	fetchComments(){
		let link = this.apiDetails['userEngagement'].base_url + this.apiDetails['userEngagement'].timeline.getEngagement.url
		this.apiCallService.apiCall(this.apiDetails['userEngagement'].timeline.getEngagement.method, link,
			{
				"timelineId": this.timelineId,
				"userId": this.userId
			}).subscribe((res: any) => {			
				this.feedsData = res.result
				this.feedsDate = new Date(this.feedsData.updatedAt).toDateString();
				this.forumComment = null;
				this.isApproved = this.feedsData.isApproved
				this.feedDetailsResultArray = this.feedsData.comments;
				
				if (this.feedDetailsResultArray) {
					this.feedDetailsResultArray.forEach((element: any) => {
						if (element.comment.length > 50) {
							element['visible'] = false;
						} else {
							element['visible'] = true;
						}
					});
				}
				try {
					this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
					// if (this.feedsData.favourite) {
					// 	this.favorite = 'favorite';
					// } else {
					// 	this.favorite = 'favorite_border';
					// }
				} catch (err) { }
			})

	}

	ngAfterContentChecked() {
		this.changeDetectRef.detectChanges();
	}

	/**
		 * Created by Akshata Choulwar
		 * For converting comment date format
		 */
	convCommentDateFormat(x) {
		this.commentDate = new Date(x).toDateString()
		return this.commentDate
	}

	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {

		var url = this.shareUrl + "/cep/feeds/forum/" + this.feedsData.timelineId
		var metaTag = {
			socialTitle: this.feedsData.en.question,
			socialImageLink: this.feedsData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}


	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

	ngOnDestroy() {
		this.googleAnalyticsService.eventEmitter(
			screenName.FORUM_DETAILS + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.FORUM_DETAILS + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User change the page', 'Click Event', 1);
		// if (this.userId && this.feedsData.feedback == false && this.feedsData.isApproved == true) {
		// 	const dialogRef = this.dialog.open(FeedbackComponent,
		// 		{
		// 			data: {
		// 				engagementId: this.feedsData['engagementId'],
		// 				engagementType: this.feedsData['engagementType']
		// 			},
		// 			height: '360px',
		// 			width: '350px',
		// 			autoFocus: false
		// 		});
		// }
	}

	async clickOnShare() {
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of forum', 'Click Event', 1);
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.FORUM_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.feedsData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/forum/" + this.feedsData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toastr.success("Link Copied to Clipboard")
		}else{
			this.toastr.error("Unable to copy Link")
		}
	}

	goBack(){
		this.location.back()
	}

}
