<!-- /******************************
 * Component -  Popup for qr scanner 
 * Created By - Prajkta P
 * Updated By - Prajkta P
 * Updated On - 22/09/2019
 ************************************/ -->

<div class="dialog-container">
  <h3 mat-dialog-title class="dialog-title font-heading">Get the app by scanning the code below</h3>
  <mat-icon class="cross-button" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>
  <div class="download-app">
    <div class="store">
      <img class="store-image" src="{{images.googlePlayStore}}" loading="lazy" alt="...">
      <qrcode [qrdata]="this.playStoreUrl" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <div class="store">
      <img class="store-image" src="{{images.appStore}}" loading="lazy" alt="...">
      <qrcode [qrdata]="this.AppStoreUrl" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
  </div>
</div>
