import * as fromLogin from "../action/login.action";
import { EncrDecrUtil } from "src/app/util/EncrDecr";

export interface LoginState {
  // serverStatusCode: number;
  userStateServerErrorMessage: string;
  selectedLanguage: string;
  isUserStateLoading: boolean;
  isUserStateLoaded: boolean;
  userStateResponse: {};
  userErrorMessage: string;
  userStateStatusCode: number;
  mobileNumber: string;

  EmployeeStateResponse: {};
  isEmployeeStateLoading: boolean;
  isEmployeeStateLoaded: boolean;
  employeeErrorMessage: string;
  EmployeeStateStatusCode: number;
  employeeId: string;

  verifyPasswordResponse: {};
  isPasswordLoading: boolean;
  isPasswordLoaded: boolean;
  passwordErrorMessage: string;
  verifyPasswordStatusCode: number;
  verifyPasswordsValidate: string;
  verifyPasswordCheckSum: string;

  loginWithOTPResponse: {};
  isloginWithOTPLoading: boolean;
  isloginWithOTPLoaded: boolean;
  loginWithOTPErrorMessage: string;
  loginWithOTPStatusCode: number;
  loginWithOTPValidate: string;
  loginWithOTPCheckSum: string;


  loginEmployeeResponse: {};
  isloginEmployeeLoading: boolean;
  isloginEmployeeLoaded: boolean;
  loginEmployeeErrorMessage: string;
  loginEmployeeStatusCode: number;
  loginEmployeeValidate:string;

  sendOtpResponse: {};
  isSendOtpLoading: boolean;
  isSendOtpLoaded: boolean;
  sendOtpErrorMessage: string;
  sendOtpSuccessCode: number;

  verifyOtpResponse: {};
  verifyOtpStatusCode: number;
  isverifyOtpLoading: boolean;
  isverifyOtpLoaded: boolean;
  verifyOtpErrorMessage: string;
  verifyOtpSuccessCode: number;
  fetchOtp: string;

  facebookLoginResponse: {};
  facebookLoginLoading: boolean;
  facebookLoginLoaded: boolean;
  facebookErrorMessage: string;
  facebookStatusCode: number;
  facebookLoginData: {};

  twitterLoginResponse: {};
  twitterLoginLoading: boolean;
  twitterLoginLoaded: boolean;
  twitterErrorMessage: string;
  twitterStatusCode: number;
  twitterLoginData: {};

  googleLoginResponse: {};
  googleLoginLoading: boolean;
  googleLoginLoaded: boolean;
  googleErrorMessage: string;
  googleStatusCode: number;
  googleLoginData: {};

  forgotPasswordResponse: number;
  isForgotPasswordLoading: boolean;
  isForgotPasswordLoaded: boolean;
  forgotPasswordErrorMessage: string;
  forgotPasswordStatusCode: number;
  forgotPasswordValidate: string;

  forgotPasswordByEmailResponse: number;
  isForgotPasswordByEmailLoading: boolean;
  isForgotPasswordByEmailLoaded: boolean;
  forgotPasswordByEmailErrorMessage: string;
  forgotPasswordByEmailStatusCode: number;

  isUpdateProfileLoading: boolean;
  isUpdateProfileLoaded: boolean;
  updateUserProfile: {};
  updateStatusCode: number;

  registerUserResponse: {};
  registerUserLoading: boolean;
  registerUserLoaded: boolean;
  registerUserErrorMessage: string;
  registerStatusCode: number;

  validateRefCodeResponse: {};
  validateRefCodeResult: {};
  validateReferralCodeErrorMessage: string;
  validateReferralCodeStatusCode: number;

  UserDetailsList: [];
  isUserDetailsLoading: boolean;
  isUserDetailsLoaded: boolean;
  errorMessage: string;
  token: string;

  isUserProfileLoading: boolean;

  mostUsedServicesResult: [];
  isMostUsedServicesLoaded: boolean;
  isMostUsedServicesLoading: boolean;

  updateLanguage: {};
  updateLanguageStatusCode: number;

  getUpdatePasswardResponse: string;
  isUpdatePasswardLoading: boolean;
  isUpdatePasswardLoaded: boolean;
  updatePasswordStatusCode: number;
  updatePasswordResponse:string;
  updatePasswordErrorMessage: string;
  updatePasswordValidate:string;

  updateProfilePhotoStatusCode: number;
  updateProfilePhotoResponse: {};
  isUpdateProfilePhotoLoading: boolean;
  isUpdateProfilePhotoLoaded: boolean;
  updateProfilePhotoResponseCertificate: {};
  updateProfilePhotoResponseGst: {};
  isUpdateProfilePhotoGstLoading: boolean;
  isUpdateProfilePhotoGstLoaded: boolean;
  updateProfilePhotoResponsePan: {};
  isUpdateProfilePhotoPanLoading: boolean;
  isUpdateProfilePhotoPanLoaded: boolean;
  updateProfilePhotoResponseFss: {};
  isUpdateProfilePhotoFssLoading: boolean;
  isUpdateProfilePhotoFssLoaded: boolean;
  updateProfilePhotoResponseShopAct: {};
  isUpdateProfilePhotoShopActLoading: boolean;
  isUpdateProfilePhotoShopActLoaded: boolean;
  updateProfilePhotoResponseProperty: {};
  isUpdateProfilePhotoPropertyLoading: boolean;
  isUpdateProfilePhotoPropertyLoaded: boolean;
  updateProfilePhotoResponseUdyogAadhar: {};
  isUpdateProfilePhotoUdyogAadharLoading: boolean;
  isUpdateProfilePhotoUdyogAadharLoaded: boolean;

  optionForFileUpload: string;

  favImageResponse: {};
  favImageResponseStatusCode: number;
  favImageResponseErrorMessage: string;
  favImageResponseLoading: boolean;
  favImageResponseLoaded: boolean;

  changeMobileNumberResponse: {};
  changeMobileNumberStatusCode: number;
  changeMobileNumberErrorMessage: string;
  changeMobileNumbrLoading: boolean;
  changeMobileNumbrLoaded: boolean;

  verifyMobileNumberResponse: {};
  verifyMobileNumberStatusCode: number;
  verifyMobileNumberErrorMessage: string;
  verifyMobileNumbrLoading: boolean;
  verifyMobileNumbrLoaded: boolean;

  getOtpChoicesResponse: {};
  getOtpChoicesStatusCode: number;
  getOtpChoicesErrorMessage: string;
  getOtpChoicesLoading: boolean;
  getOtpChoicesLoaded: boolean;

  termsAndConditions: {};
  termsAndConditionsStatusCode: number;
  termsAndConditionsErrorMessage: string;
  isTermsAndConditionsLoading: boolean;
  isTermsAndConditionsLoaded: boolean;

  merchantTermsAndConditions: {};
  merchantTermsAndConditionsStatusCode: number;
  merchantTermsAndConditionsErrorMessage: string;
  merchantTermsAndConditionsLoading: boolean;
  merchantTermsAndConditionsLoaded: boolean;

  isProfilePasswordLoading: boolean;

  interestedToBeMerchantStatusCode: number;
  interestedToBeMerchantErrorMessage: string;
  isInterestedToBeMerchantLoading: boolean;
  isInterestedToBeMerchantLoaded: boolean;
  interestedToBeMerchantResponse: {};

  interestedToBeBrandStatusCode: number;
  interestedToBeBrandErrorMessage: string;
  isInterestedToBeBrandLoading: boolean;
  isInterestedToBeBrandLoaded: boolean;
  interestedToBeBrandResponse: {};

  registerAsMerchantStatusCode: number;
  registerAsMerchantErrorMessage: string;
  isRegisterAsMerchantLoading: boolean;
  isRegisterAsMerchantLoaded: boolean;

  registerAsBrandStatusCode: number;
  registerAsBrandErrorMessage: string;
  isRegisterAsBrandLoading: boolean;
  isRegisterAsBrandLoaded: boolean;

  getAllBrandStatusCode: number;
  getAllBrandErrorMessage: string;
  isGetAllBrandLoading: boolean;
  isGetAllBrandLoaded: boolean;
  getAllBrandResponse: [];

  getNewsletterSubscriptionStatusCode: number;
  getNewsletterSubscriptionErrorMessage: string;
  isNewsletterSubscriptionLoading: boolean;
  isNewsletterSubscriptionLoaded: boolean;
  uploadContentType: string;

  gotoPageOnLogin: string;
  gotoService:string;
  fcmToken:string
}

export const initialState: LoginState = {
  selectedLanguage: "mar",

  userStateResponse: {},
  isUserStateLoading: false,
  isUserStateLoaded: false,
  userErrorMessage: null,
  userStateStatusCode: 0,
  mobileNumber: null,

  EmployeeStateResponse: {},
  isEmployeeStateLoading: false,
  isEmployeeStateLoaded: false,
  employeeErrorMessage: null,
  EmployeeStateStatusCode: 0,
  employeeId: null,

  verifyPasswordResponse: {},
  isPasswordLoading: false,
  isPasswordLoaded: false,
  passwordErrorMessage: null,
  verifyPasswordStatusCode: 0,
  verifyPasswordsValidate:null,
  verifyPasswordCheckSum:null,

  loginWithOTPResponse: {},
  isloginWithOTPLoaded: false,
  isloginWithOTPLoading: false,
  loginWithOTPErrorMessage: null,
  loginWithOTPStatusCode: 0,
  loginWithOTPValidate:null,
  loginWithOTPCheckSum:null,

  loginEmployeeResponse: {},
  isloginEmployeeLoading: false,
  isloginEmployeeLoaded: false,
  loginEmployeeErrorMessage: null,
  loginEmployeeStatusCode: 0,
  loginEmployeeValidate:null,

  sendOtpResponse: {},
  isSendOtpLoading: false,
  isSendOtpLoaded: false,
  sendOtpErrorMessage: null,
  sendOtpSuccessCode: 0,

  verifyOtpResponse: {},
  verifyOtpStatusCode: null,
  isverifyOtpLoading: false,
  isverifyOtpLoaded: false,
  verifyOtpErrorMessage: null,
  verifyOtpSuccessCode: 0,
  fetchOtp: null,

  facebookLoginResponse: {},
  facebookLoginLoading: false,
  facebookLoginLoaded: false,
  facebookErrorMessage: null,
  facebookStatusCode: 0,
  facebookLoginData: null,

  twitterLoginResponse: {},
  twitterLoginLoading: false,
  twitterLoginLoaded: false,
  twitterErrorMessage: null,
  twitterStatusCode: 0,
  twitterLoginData: null,

  googleLoginResponse: {},
  googleLoginLoading: false,
  googleLoginLoaded: false,
  googleErrorMessage: null,
  googleStatusCode: 0,
  googleLoginData: null,

  forgotPasswordResponse: 0,
  isForgotPasswordLoading: false,
  isForgotPasswordLoaded: false,
  forgotPasswordErrorMessage: null,
  forgotPasswordStatusCode: 0,
  forgotPasswordValidate:null,

  forgotPasswordByEmailResponse: 0,
  isForgotPasswordByEmailLoading: false,
  isForgotPasswordByEmailLoaded: false,
  forgotPasswordByEmailErrorMessage: null,
  forgotPasswordByEmailStatusCode: 0,

  isUpdateProfileLoading: false,
  isUpdateProfileLoaded: false,
  updateUserProfile: {},
  errorMessage: null,
  updateStatusCode: 0,

  registerUserResponse: {},
  registerUserLoading: false,
  registerUserLoaded: false,
  registerUserErrorMessage: null,
  registerStatusCode: 0,

  validateRefCodeResponse: {},
  validateRefCodeResult: {},
  validateReferralCodeErrorMessage: null,
  validateReferralCodeStatusCode: 0,

  UserDetailsList: [],
  isUserDetailsLoading: false,
  isUserDetailsLoaded: false,
  token: null,
  isUserProfileLoading: false,

  mostUsedServicesResult: [],
  isMostUsedServicesLoaded: false,
  isMostUsedServicesLoading: false,

  updateLanguage: {},
  updateLanguageStatusCode: 0,

  getUpdatePasswardResponse: null,
  isUpdatePasswardLoading: false,
  isUpdatePasswardLoaded: false,
  updatePasswordErrorMessage: null,
  updatePasswordStatusCode: 0,
  updatePasswordResponse:null,
  updatePasswordValidate:null,

  updateProfilePhotoResponse: {},
  updateProfilePhotoResponseCertificate: {},
  updateProfilePhotoResponseGst: {},
  updateProfilePhotoResponsePan: {},
  updateProfilePhotoResponseFss: {},
  isUpdateProfilePhotoGstLoading: false,
  isUpdateProfilePhotoGstLoaded: false,
  isUpdateProfilePhotoPanLoading: false,
  isUpdateProfilePhotoPanLoaded: false,
  isUpdateProfilePhotoFssLoading: false,
  isUpdateProfilePhotoFssLoaded: false,
  updateProfilePhotoResponseShopAct: {},
  isUpdateProfilePhotoShopActLoading: false,
  isUpdateProfilePhotoShopActLoaded: false,
  updateProfilePhotoResponseProperty: {},
  isUpdateProfilePhotoPropertyLoading: false,
  isUpdateProfilePhotoPropertyLoaded: false,
  updateProfilePhotoResponseUdyogAadhar: {},
  isUpdateProfilePhotoUdyogAadharLoading: false,
  isUpdateProfilePhotoUdyogAadharLoaded: false,
  optionForFileUpload: null,
  isUpdateProfilePhotoLoading: false,
  isUpdateProfilePhotoLoaded: false,
  updateProfilePhotoStatusCode: 0,

  favImageResponse: {},
  favImageResponseStatusCode: 0,
  favImageResponseErrorMessage: null,
  favImageResponseLoading: false,
  favImageResponseLoaded: false,

  changeMobileNumberResponse: {},
  changeMobileNumberStatusCode: 0,
  changeMobileNumberErrorMessage: null,
  changeMobileNumbrLoading: false,
  changeMobileNumbrLoaded: false,

  verifyMobileNumberResponse: {},
  verifyMobileNumberStatusCode: 0,
  verifyMobileNumberErrorMessage: null,
  verifyMobileNumbrLoading: false,
  verifyMobileNumbrLoaded: false,

  getOtpChoicesResponse: {},
  getOtpChoicesStatusCode: 0,
  getOtpChoicesErrorMessage: null,
  getOtpChoicesLoading: false,
  getOtpChoicesLoaded: false,

  termsAndConditions: {},
  termsAndConditionsStatusCode: 0,
  termsAndConditionsErrorMessage: null,
  isTermsAndConditionsLoading: false,
  isTermsAndConditionsLoaded: false,
  userStateServerErrorMessage: null,

  merchantTermsAndConditions: {},
  merchantTermsAndConditionsStatusCode: 0,
  merchantTermsAndConditionsErrorMessage: null,
  merchantTermsAndConditionsLoading: false,
  merchantTermsAndConditionsLoaded: false,

  isProfilePasswordLoading: false,

  interestedToBeMerchantStatusCode: 0,
  interestedToBeMerchantErrorMessage: null,
  isInterestedToBeMerchantLoading: false,
  isInterestedToBeMerchantLoaded: false,
  interestedToBeMerchantResponse: {},

  interestedToBeBrandStatusCode: 0,
  interestedToBeBrandErrorMessage: null,
  isInterestedToBeBrandLoading: false,
  isInterestedToBeBrandLoaded: false,
  interestedToBeBrandResponse: {},

  registerAsMerchantStatusCode: 0,
  registerAsMerchantErrorMessage: null,
  isRegisterAsMerchantLoading: false,
  isRegisterAsMerchantLoaded: false,

  registerAsBrandStatusCode: 0,
  registerAsBrandErrorMessage: null,
  isRegisterAsBrandLoading: false,
  isRegisterAsBrandLoaded: false,

  getAllBrandStatusCode: 0,
  getAllBrandErrorMessage: null,
  isGetAllBrandLoading: false,
  isGetAllBrandLoaded: false,
  getAllBrandResponse: [],

  getNewsletterSubscriptionStatusCode: 0,
  getNewsletterSubscriptionErrorMessage: null,
  isNewsletterSubscriptionLoading: false,
  isNewsletterSubscriptionLoaded: false,
  uploadContentType: null,

  gotoPageOnLogin: null,
  gotoService:null,
  fcmToken:null
};

export function reducer(
  state = initialState,
  action: fromLogin.loginAction,
): LoginState {
  switch (action.type) {
    case fromLogin.CHANGE_LANGUAGE: {
      let selectedLanguage = state.selectedLanguage;
      selectedLanguage = action.payload.language;
      return {
        ...state,
        selectedLanguage,
      };
    }

    case fromLogin.GO_TO_PAGE_ONLOGIN: {
      let gotoPageOnLogin = state.gotoPageOnLogin;
      gotoPageOnLogin = action.payload
      return {
        ...state,
        gotoPageOnLogin,
      }
    }

    case fromLogin.NULL_ONLOGIN:{
      let gotoPageOnLogin = state.gotoPageOnLogin;
      gotoPageOnLogin = null
      return {
        ...state,
        gotoPageOnLogin,
      }
    }

    case fromLogin.FCM_TOKEN:{
      let fcmToken = state.fcmToken;
      fcmToken = action.payload.token
      return{
        ...state,
        fcmToken
      }
    }

    case fromLogin.NULL_OTP_STATE:{
  
      let sendOtpResponse = state.sendOtpResponse;
      let isSendOtpLoading = state.isSendOtpLoading;
      let isSendOtpLoaded = state.isSendOtpLoaded;
      let sendOtpErrorMessage = state.sendOtpErrorMessage;
      let sendOtpSuccessCode = state.sendOtpSuccessCode;
      let mobileNumber = state.mobileNumber;

        sendOtpResponse = {};
        isSendOtpLoading = false;
        isSendOtpLoaded = false;
        sendOtpErrorMessage = null;
        sendOtpSuccessCode = 0;
        mobileNumber = null;

      return {
        ...state,
        sendOtpResponse,
        isSendOtpLoading,
        isSendOtpLoaded,
        sendOtpErrorMessage,
        sendOtpSuccessCode,
        mobileNumber,
      };

    }

    case fromLogin.Go_TO_SERVICE:{
      let gotoService = state.gotoService;
      gotoService = action.payload
      return{
        ...state,
        gotoService,
      }
    }

    case fromLogin.NULL_SERVICE:{
      let gotoService = state.gotoService;
      gotoService = null;
      return{
        ...state,
        gotoService,
      }
    }


    case fromLogin.GET_USER_STATE_RESPONSE_DETAILS: {
      let mobileNumber = state.mobileNumber;
      mobileNumber = action.payload.mobileNumber;
      return {
        ...state,
        isUserStateLoading: true,
        isUserStateLoaded: false,
        userErrorMessage: null,
        mobileNumber,
      };
    }
    case fromLogin.GET_USER_STATE_RESPONSE_DETAILS_SUCCESS: {
      const statusCode = action.payload.code;
      let userStateResponse = state.userStateResponse;
      let isUserStateLoading = state.isUserStateLoading;
      let isUserStateLoaded = state.isUserStateLoaded;
      const userErrorMessage = state.userErrorMessage;
      const userStateStatusCode = statusCode;
      switch (statusCode) {
        case 200:
          userStateResponse = action.payload.result;
          isUserStateLoading = false;
          isUserStateLoaded = true;
          break;
        case 717:
          isUserStateLoading = false;
          isUserStateLoaded = true;
          break;
      }
      return {
        ...state,
        userStateResponse,
        isUserStateLoading,
        isUserStateLoaded,
        userErrorMessage,
        userStateStatusCode,
      };
    }
    case fromLogin.GET_USER_STATE_RESPONSE_DETAILS_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isUserStateLoading: false,
        isUserStateLoaded: true,
      };
    }

    case fromLogin.GET_EMPLOYEE_STATE: {
      let employeeId = state.employeeId;
      employeeId = action.payload.employeeId;
      return {
        ...state,
        isEmployeeStateLoading: true,
        isEmployeeStateLoaded: false,
        employeeErrorMessage: null,
        employeeId,
      };
    }
    case fromLogin.GET_EMPLOYEE_STATE_SUCCESS: {
      const statusCode = action.payload.code;
      let EmployeeStateResponse = state.EmployeeStateResponse;
      let isEmployeeStateLoading = state.isEmployeeStateLoading;
      let isEmployeeStateLoaded = state.isEmployeeStateLoaded;
      let employeeErrorMessage = state.employeeErrorMessage;
      const EmployeeStateStatusCode = statusCode;
      switch (statusCode) {
        case 200:
          EmployeeStateResponse = action.payload.result;
          isEmployeeStateLoading = false;
          isEmployeeStateLoaded = true;
          employeeErrorMessage = action.payload.message
          break;
        case 404:
          isEmployeeStateLoading = false;
          isEmployeeStateLoaded = true;
          break;
      }
      return {
        ...state,
        EmployeeStateResponse,
        isEmployeeStateLoading,
        isEmployeeStateLoaded,
        employeeErrorMessage,
        EmployeeStateStatusCode,
      };
    }
    case fromLogin.GET_EMPLOYEE_STATE_FAIL: {
      
    }

    case fromLogin.SEND_OTP: {
      return {
        ...state,
        isSendOtpLoading: true,
        isSendOtpLoaded: false,
        sendOtpErrorMessage: null,
      };
    }
    case fromLogin.SEND_OTP_SUCCESS: {
      const statusCode = action.payload.code;
      let sendOtpResponse = state.sendOtpResponse;
      let isSendOtpLoading = state.isSendOtpLoading;
      let isSendOtpLoaded = state.isSendOtpLoaded;
      let sendOtpErrorMessage = state.sendOtpErrorMessage;
      let sendOtpSuccessCode = state.sendOtpSuccessCode;
      switch (statusCode) {
        case 200:
          sendOtpResponse = action.payload.result;
          isSendOtpLoading = false;
          isSendOtpLoaded = true;
          sendOtpSuccessCode = 200;
          sendOtpErrorMessage = action.payload.message;
          break;
        case 717:
          isSendOtpLoading = false;
          isSendOtpLoaded = true;
          sendOtpSuccessCode = action.payload.code;
          break;
      }
      return {
        ...state,
        sendOtpResponse,
        isSendOtpLoading,
        isSendOtpLoaded,
        sendOtpErrorMessage,
        sendOtpSuccessCode,
      };
    }
    case fromLogin.SEND_OTP_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isSendOtpLoading: false,
        isSendOtpLoaded: true,
      };
    }

    case fromLogin.VERIFY_OTP: {
      const fetchOtp = action.payload.otp;
      return {
        ...state,
        isverifyOtpLoading: true,
        isverifyOtpLoaded: false,
        verifyOtpErrorMessage: null,
        fetchOtp,
      };
    }
    case fromLogin.VERIFY_OTP_SUCCESS: {
    
      const encrDecrUtil = new EncrDecrUtil();
      let verifyOtpSuccessCode = 0;
      const verifyOtpStatusCode = action.payload.code;
      if (verifyOtpStatusCode === 'Error') {
        if (state.selectedLanguage === 'en') {
          alert("Invalid OTP")
          // toastr.error("Invalid OTP")
        }
        if (state.selectedLanguage === 'mar') {
          alert("अवैध OTP")
          // toastr.error("अवैध OTP")
        }
      }
      let verifyOtpResponse = state.verifyOtpResponse;
      let isverifyOtpLoading = state.isverifyOtpLoading;
      let isverifyOtpLoaded = state.isverifyOtpLoaded;
      let verifyOtpErrorMessage = null;
      verifyOtpSuccessCode = verifyOtpStatusCode;

      // Verify the mobile number here
      // const encryptedMobileString = encrDecrUtil.set("mobile_number"); 
      // // Prep for local storage
      // const encryptedMobileNumberString = localStorage.getItem(
      //   encryptedMobileString
      // );
      // const decryptedMobileNumberString = encrDecrUtil.get(
      //   encryptedMobileNumberString
      // );

      switch (verifyOtpStatusCode) {
        case 200:
          // This is good, no tampering
          verifyOtpResponse = action.payload.result;
          isverifyOtpLoading = false;
          isverifyOtpLoaded = true;
          verifyOtpErrorMessage = action.payload.message;
          // localStorage.removeItem(encryptedMobileString);
          break;

        case 701:
          // verifyOtpResponse = action.payload.message;
          isverifyOtpLoading = false;
          isverifyOtpLoaded = true;
          break;
        case 702:
          // verifyOtpResponse = action.payload.message;
          isverifyOtpLoading = false;
          isverifyOtpLoaded = true;
          break;
        case 717:
          isverifyOtpLoading = false;
          isverifyOtpLoaded = true;
          break;
        case 603:
          isverifyOtpLoading = false;
          isverifyOtpLoaded = true;
          break;
      }

      return {
        ...state,
        verifyOtpResponse,
        verifyOtpStatusCode,
        isverifyOtpLoading,
        isverifyOtpLoaded,
        verifyOtpErrorMessage,
        verifyOtpSuccessCode,
      };
    }
    case fromLogin.VERIFY_OTP_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isverifyOtpLoading: false,
        isverifyOtpLoaded: true,
      };
    }

    case fromLogin.FACEBOOK_LOGIN: {
      const facebookLoginData = action.payload.facebookLoginData;
      return {
        ...state,
        facebookLoginLoading: true,
        facebookLoginLoaded: false,
        facebookErrorMessage: null,
        facebookLoginData,
      };
    }
    case fromLogin.FACEBOOK_LOGIN_SUCCESS: {
      const statusCode = action.payload.code;
      let verifyPasswordResponse = state.facebookLoginResponse;
      let facebookLoginLoading = state.facebookLoginLoading;
      let facebookLoginLoaded = state.facebookLoginLoaded;
      let facebookErrorMessage = state.facebookErrorMessage;
      let facebookStatusCode = action.payload.code;
      switch (statusCode) {
        case 200:
          verifyPasswordResponse = action.payload.result;
          facebookLoginLoading = false;
          facebookLoginLoaded = true;
          facebookStatusCode = action.payload.code;
          break;
        case 603:
          facebookLoginLoading = false;
          facebookLoginLoaded = true;
          facebookErrorMessage = action.payload.message;
          facebookStatusCode = action.payload.code;
          break;
        case 203:
          facebookLoginLoading = false;
          facebookLoginLoaded = true;
          facebookErrorMessage = null;
          facebookStatusCode = action.payload.code;
          break;
        case 405:
          facebookLoginLoading = false;
          facebookLoginLoaded = true;
          facebookErrorMessage = action.payload.message;
          facebookStatusCode = action.payload.code;
          break;
      }
      return {
        ...state,
        verifyPasswordResponse,
        facebookLoginLoading,
        facebookLoginLoaded,
        facebookErrorMessage,
        facebookStatusCode,
      };
    }
    case fromLogin.FACEBOOK_LOGIN_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        facebookLoginLoading: false,
        facebookLoginLoaded: true,
      };
    }

    case fromLogin.TWITTER_LOGIN: {
      let twitterLoginData = action.payload.twitterUserData;
      return {
        ...state,
        twitterLoginLoading: true,
        twitterLoginLoaded: false,
        twitterErrorMessage: null,
        twitterLoginData,
      };
    }
    case fromLogin.TWITTER_LOGIN_SUCCESS: {
      let statusCode = action.payload.code;
      let verifyPasswordResponse = state.twitterLoginResponse;
      let twitterLoginLoading = state.twitterLoginLoading;
      let twitterLoginLoaded = state.twitterLoginLoaded;
      let twitterErrorMessage = state.twitterErrorMessage;
      let twitterStatusCode = action.payload.code;
      switch (statusCode) {
        case 200:
          verifyPasswordResponse = action.payload.result;
          twitterLoginLoading = false;
          twitterLoginLoaded = true;
          twitterStatusCode = action.payload.code;
          break;
        case 603:
          twitterLoginLoading = false;
          twitterLoginLoaded = true;
          twitterErrorMessage = action.payload.message;
          twitterStatusCode = action.payload.code;
          break;
        case 203:
          twitterLoginLoading = false;
          twitterLoginLoaded = true;
          twitterErrorMessage = null;
          twitterStatusCode = action.payload.code;
          break;
        case 405:
          twitterLoginLoading = false;
          twitterLoginLoaded = true;
          twitterErrorMessage = action.payload.message;
          twitterStatusCode = action.payload.code;
          break;
      }
      return {
        ...state,
        verifyPasswordResponse,
        twitterLoginLoading,
        twitterLoginLoaded,
        twitterErrorMessage,
        twitterStatusCode,
      };
    }
    case fromLogin.TWITTER_LOGIN_FAIL: {
      let statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode != 401 && statusCode != 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        twitterLoginLoading: false,
        twitterLoginLoaded: true,
      };
    }

    case fromLogin.GOOGLE_LOGIN: {
      const googleLoginData = action.payload.googleLoginData;
      return {
        ...state,
        googleLoginLoading: true,
        googleLoginLoaded: false,
        googleErrorMessage: null,
        googleLoginData,
      };
    }
    case fromLogin.GOOGLE_LOGIN_SUCCESS: {
      const statusCode = action.payload.code;
      let verifyPasswordResponse = state.googleLoginResponse;
      let googleLoginLoading = state.googleLoginLoading;
      let googleLoginLoaded = state.googleLoginLoaded;
      let googleErrorMessage = state.googleErrorMessage;
      let googleStatusCode = action.payload.code;
      switch (statusCode) {
        case 200:
          verifyPasswordResponse = action.payload.result;
          googleLoginLoading = false;
          googleLoginLoaded = true;
          googleStatusCode = action.payload.code;
          googleErrorMessage = action.payload.message;
          break;
        case 603:
          googleLoginLoading = false;
          googleLoginLoaded = true;
          googleErrorMessage = action.payload.message;
          googleStatusCode = action.payload.code;
          break;
        case 203:
          googleLoginLoading = false;
          googleLoginLoaded = true;
          googleErrorMessage = null;
          googleStatusCode = action.payload.code;
      }
      return {
        ...state,
        verifyPasswordResponse,
        googleLoginLoading,
        googleLoginLoaded,
        googleErrorMessage,
        googleStatusCode,
      };
    }
    case fromLogin.GOOGLE_LOGIN_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        googleLoginLoading: false,
        googleLoginLoaded: true,
      };
    }

    case fromLogin.FORGOT_PASSWORD: {
      return {
        ...state,
        isForgotPasswordLoading: true,
        isForgotPasswordLoaded: false,
        forgotPasswordErrorMessage: null,
      };
    }
    case fromLogin.FORGOT_PASSWORD_SUCCESS: {
      const statusCode = action.payload.code;
      let forgotPasswordResponse = state.forgotPasswordResponse;
      let isForgotPasswordLoading = state.isForgotPasswordLoading;
      let isForgotPasswordLoaded = state.isForgotPasswordLoaded;
      let forgotPasswordErrorMessage = state.forgotPasswordErrorMessage;
      let forgotPasswordValidate = state.forgotPasswordValidate;
      switch (statusCode) {
        case 200:
          forgotPasswordResponse = action.payload.code;
          isForgotPasswordLoading = false;
          isForgotPasswordLoaded = true;
          forgotPasswordErrorMessage = action.payload.message;
          forgotPasswordValidate = action.payload.validate;
          break;
        case 702:
          // forgotPasswordResponse = action.payload.code;
          isForgotPasswordLoading = false;
          isForgotPasswordLoaded = true;
          break;
        case 603:
          // forgotPasswordResponse = action.payload.code;
          isForgotPasswordLoading = false;
          isForgotPasswordLoaded = true;
          break;
      }
      return {
        ...state,
        forgotPasswordResponse,
        isForgotPasswordLoading,
        isForgotPasswordLoaded,
        forgotPasswordErrorMessage,
        forgotPasswordValidate,
      };
    }
    case fromLogin.FORGOT_PASSWORD_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isForgotPasswordLoading: false,
        isForgotPasswordLoaded: true,
      };
    }

    case fromLogin.FORGOT_PASSWORD_BY_EMAIL: {
      return {
        ...state,
        isForgotPasswordByEmailLoading: true,
        isForgotPasswordByEmailLoaded: false,
        forgotPasswordByEmailErrorMessage: null,
      };
    }
    case fromLogin.FORGOT_PASSWORD_BY_EMAIL_SUCCESS: {
      const statusCode = action.payload.code;
      let forgotPasswordByEmailResponse = state.forgotPasswordByEmailResponse;
      let isForgotPasswordByEmailLoading = state.isForgotPasswordByEmailLoading;
      let isForgotPasswordByEmailLoaded = state.isForgotPasswordByEmailLoaded;
      let forgotPasswordByEmailErrorMessage =
        state.forgotPasswordByEmailErrorMessage;
      switch (statusCode) {
        case 200:
          forgotPasswordByEmailResponse = action.payload.code;
          isForgotPasswordByEmailLoading = false;
          isForgotPasswordByEmailLoaded = true;
          forgotPasswordByEmailErrorMessage = action.payload.message;
          break;
        case 750:
          forgotPasswordByEmailResponse = action.payload.code;
          isForgotPasswordByEmailLoading = false;
          isForgotPasswordByEmailLoaded = true;
          forgotPasswordByEmailErrorMessage = action.payload.message;
          break;
        case 751:
          forgotPasswordByEmailResponse = action.payload.code;
          isForgotPasswordByEmailLoading = false;
          isForgotPasswordByEmailLoaded = true;
          forgotPasswordByEmailErrorMessage = action.payload.message;
          break;
        case 603:
          forgotPasswordByEmailResponse = action.payload.code;
          isForgotPasswordByEmailLoading = false;
          isForgotPasswordByEmailLoaded = true;
          forgotPasswordByEmailErrorMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        forgotPasswordByEmailResponse,
        isForgotPasswordByEmailLoading,
        isForgotPasswordByEmailLoaded,
        forgotPasswordByEmailErrorMessage,
      };
    }
    case fromLogin.FORGOT_PASSWORD_BY_EMAIL_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isForgotPasswordByEmailLoading: false,
        isForgotPasswordByEmailLoaded: true,
      };
    }

    case fromLogin.VERIFY_PASSWORD: {     
      return {
        ...state,
        isPasswordLoading: true,
        isPasswordLoaded: false,
        passwordErrorMessage: null,
        verifyPasswordResponse: {},
        };
       
    }
    case fromLogin.ADD_PASSWORD_CHECKSUM:{
      return {
        ...state,
        verifyPasswordCheckSum: action.payload.calulatedChecksum
        };
    }
    case fromLogin.VERIFY_PASSWORD_SUCCESS: {
      
      const statusCode = action.payload.code;
      let verifyPasswordResponse = state.verifyPasswordResponse;

      let isPasswordLoading = state.isPasswordLoading;
      let isPasswordLoaded = state.isPasswordLoaded;
      let passwordErrorMessage = action.payload.message;
      let verifyPasswordStatusCode = state.verifyPasswordStatusCode;
      let token = state.token;
      verifyPasswordStatusCode = statusCode;
      let selectedLanguage = state.selectedLanguage;
      let verifyPasswordsValidate = state.verifyPasswordsValidate;
      let verifyPasswordCheckSum = state.verifyPasswordCheckSum;      
      
  
      switch (statusCode) {
        case 200:
          
          if(verifyPasswordCheckSum === action.payload.checksum){

            verifyPasswordResponse = action.payload.result;
            token = verifyPasswordResponse["token"];
            selectedLanguage = verifyPasswordResponse["lang"];
            verifyPasswordsValidate = action.payload.validate
            isPasswordLoading = false;
            isPasswordLoaded = true;
            passwordErrorMessage = action.payload.message;

            // Store user data only if checksums match
            const encoder = new EncrDecrUtil(); //encodeing user here for later use
            const user_encoder_path = encoder.set('user');
            localStorage.setItem(user_encoder_path, encoder.set(JSON.stringify(action.payload.result)));
            break;
          } else {
            // Handle checksum mismatch
            isPasswordLoading = false;
            isPasswordLoaded = true;            
            passwordErrorMessage = "Checksum Authorization Failed!";
            verifyPasswordStatusCode = 202       
            break;
        }         

        case 201:          
          verifyPasswordResponse = action.payload.message;
          passwordErrorMessage = action.payload.message;
          verifyPasswordsValidate = action.payload.validate;
          verifyPasswordCheckSum = action.payload.checksum;
          isPasswordLoading = false;
          isPasswordLoaded = true;
        break;

        case 603:
          isPasswordLoading = false;
          isPasswordLoaded = true;
        break;

        case 599:
          isPasswordLoading = false;
          isPasswordLoaded = true;
        break;

        case 751:
          isPasswordLoading = false;
          isPasswordLoaded = true;
          passwordErrorMessage = action.payload.message;
        break;
      }         

      return {
        ...state,
        verifyPasswordResponse,
        isPasswordLoading,
        isPasswordLoaded,
        passwordErrorMessage,
        verifyPasswordStatusCode,
        verifyPasswordsValidate,
        verifyPasswordCheckSum,
        selectedLanguage,
        token,
      };
    }

    case fromLogin.VERIFY_PASSWORD_FAIL: {
    
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isPasswordLoading: false,
        isPasswordLoaded: true,
      };
    }

    case fromLogin.LOGIN_WITH_OTP: {
      return {
        ...state,
        isloginWithOTPLoading: true,
        isloginWithOTPLoaded: false,
        loginWithOTPErrorMessage: null,
        loginWithOTPResponse: {},       
      }
    }
     case fromLogin.ADD_OTP_CHECKSUM: {
      return {
        ...state,
        loginWithOTPCheckSum: action.payload.calulatedChecksum
      };
     }

    case fromLogin.LOGIN_WITH_OTP_SUCCESS: {
      const statusCode = action.payload.code
      let loginWithOTPResponse = state.loginWithOTPResponse;
      let isloginWithOTPLoading = state.isloginWithOTPLoading;
      let isloginWithOTPLoaded = state.isloginWithOTPLoaded;
      let loginWithOTPErrorMessage = state.loginWithOTPErrorMessage;
      let loginWithOTPStatusCode = state.loginWithOTPStatusCode;
      let loginWithOTPValidate = state.loginWithOTPValidate;
      let loginWithOTPCheckSum = state.loginWithOTPCheckSum;
      let token = state.token;
      loginWithOTPStatusCode = statusCode;
      let selectedLanguage = state.selectedLanguage;

      switch (statusCode) {
        case 200:
          if(loginWithOTPCheckSum === action.payload.checksum){
            // console.log("otp checksums to be compared", loginWithOTPCheckSum, action.payload.checksum)

            loginWithOTPResponse = action.payload.result;
            token = loginWithOTPResponse["token"];
            selectedLanguage = loginWithOTPResponse["lang"];
      
            isloginWithOTPLoading = false;
            isloginWithOTPLoaded = true;
            loginWithOTPErrorMessage = action.payload.message;
            loginWithOTPValidate = action.payload.validate;
            loginWithOTPCheckSum = action.payload.checksum;

            // Store user data only if checksums match
            const encoder = new EncrDecrUtil();
            const user_encoder_path = encoder.set('user');
            localStorage.setItem(
              user_encoder_path,
              encoder.set(JSON.stringify(action.payload.result))
            );
            break;
          }else{
            isloginWithOTPLoading = false;
            isloginWithOTPLoaded = true;
            loginWithOTPErrorMessage = "Checksum validation failed!";
            loginWithOTPStatusCode = 202;
            break;
          }
          
        case 702:
          isloginWithOTPLoading = false;
          isloginWithOTPLoaded = true;
          loginWithOTPErrorMessage = action.payload.message
          break;
      }      

      return {
        ...state,
        loginWithOTPResponse,
        isloginWithOTPLoading,
        isloginWithOTPLoaded,
        loginWithOTPErrorMessage,
        loginWithOTPStatusCode,
        loginWithOTPValidate,
        loginWithOTPCheckSum,
        selectedLanguage,
        token,
      };

    }

    case fromLogin.LOGIN_WITH_OTP_FAIL: {

    }

    case fromLogin.LOGIN_EMPLOYEE: {
      return {
        ...state,
        isloginEmployeeLoading: true,
        isloginEmployeeLoaded: false,
        loginEmployeeErrorMessage: null,
        loginEmployeeResponse: {}
      }
    }

    case fromLogin.LOGIN_EMPLOYEE_SUCCESS: {
      const statusCode = action.payload.code
      let loginEmployeeResponse = state.loginEmployeeResponse;
      let isloginEmployeeLoading = state.isloginEmployeeLoading;
      let isloginEmployeeLoaded = state.isloginEmployeeLoaded;
      let loginEmployeeErrorMessage = state.loginEmployeeErrorMessage;
      let loginEmployeeStatusCode = state.loginEmployeeStatusCode;
      let token = state.token;
      loginEmployeeStatusCode = statusCode;
      let selectedLanguage = state.selectedLanguage;
      let loginEmployeeValidate = action.payload.validate;

      switch (statusCode) {
        case 200:
          loginEmployeeResponse = action.payload.result;
          token = action.payload.result["token"];
          selectedLanguage = action.payload.result["lang"];
          isloginEmployeeLoading = false;
          isloginEmployeeLoaded = true;
          loginEmployeeErrorMessage = action.payload.message;
          loginEmployeeValidate = action.payload.validate;
          break;
        case 702:
          isloginEmployeeLoading = false;
          isloginEmployeeLoaded = true;
          loginEmployeeErrorMessage = action.payload.message
          break;
      }

      if (statusCode === 200) {
        const encoder = new EncrDecrUtil();

        const user_encoder_path = encoder.set('user');
        localStorage.setItem(
          user_encoder_path,
          encoder.set(JSON.stringify(action.payload.result))
        );
      }

      return {
        ...state,
        loginEmployeeResponse,
        isloginEmployeeLoading,
        isloginEmployeeLoaded,
        loginEmployeeErrorMessage,
        loginEmployeeStatusCode,
        loginEmployeeValidate,
        selectedLanguage,
        token,
      };
    }

    case fromLogin.LOGIN_EMPLOYEE_FAIL: {
  
    }


    case fromLogin.UPDATE_PROFILE_DETAILS: {
      return {
        ...state,
        isUpdateProfileLoading: true,
        isUpdateProfileLoaded: false,
        passwordErrorMessage: null,
        verifyPasswordResponse: {},
      };
    }
    case fromLogin.UPDATE_PROFILE_DETAILS_SUCCESS: {
      let updateStatusCode = action.payload.code;
      let verifyPasswordResponse = state.verifyPasswordResponse;
      let isUpdateProfileLoading = state.isUpdateProfileLoading;
      let isUpdateProfileLoaded = state.isUpdateProfileLoaded;
      let passwordErrorMessage = state.passwordErrorMessage;
      let selectedLanguage = state.selectedLanguage;
      switch (updateStatusCode) {
        case 200:
          verifyPasswordResponse = action.payload.result;
          selectedLanguage = verifyPasswordResponse["lang"];
          isUpdateProfileLoading = false;
          isUpdateProfileLoaded = true;
          passwordErrorMessage = action.payload.message;
          updateStatusCode = 200;
          break;
        case 201:
          isUpdateProfileLoading = false;
          isUpdateProfileLoaded = true;
        case 603:
          isUpdateProfileLoading = false;
          isUpdateProfileLoaded = true;
          break;
        case 612:
          updateStatusCode = 612;
      }
      return {
        ...state,
        verifyPasswordResponse,
        isUpdateProfileLoading,
        isUpdateProfileLoaded,
        passwordErrorMessage,
        updateStatusCode,
        selectedLanguage,
      };
    }
    case fromLogin.UPDATE_PROFILE_DETAILS_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isUpdateProfileLoading: false,
        isUpdateProfileLoaded: true,
      };
    }

    case fromLogin.VALIDATE_REFERRAL_CODE: {
      return {
        ...state,
        validateReferralCodeErrorMessage: null,
      };
    }

    case fromLogin.VALIDATE_REFERRAL_CODE_SUCCESS: {
      const statusCode = action.payload.code;
      let validateRefCodeResult = state.validateRefCodeResult;
      let validateReferralCodeErrorMessage =
        state.validateReferralCodeErrorMessage;
      let validateReferralCodeStatusCode = statusCode;

      switch (statusCode) {
        case 200:
          validateRefCodeResult = action.payload.result;
          // validateReferralCodeStatusCode = action.payload.code;
          validateReferralCodeErrorMessage = action.payload.message;
        
          break;
        case 500:
          validateRefCodeResult = {};
          validateReferralCodeErrorMessage = action.payload.message;
          // validateReferralCodeStatusCode = 500;
          break;
      }
      return {
        ...state,
        validateRefCodeResult,
        validateReferralCodeErrorMessage,
        validateReferralCodeStatusCode,
      };
    }

    // case fromLogin.VALIDATE_REFERRAL_CODE_FAIL: {

    // 	const statusCode = action.payload.status;
    // 	let userStateServerErrorMessage = state.userStateServerErrorMessage;
    // 	if (statusCode === 401 || statusCode === 0) {
    // 		userStateServerErrorMessage = action.payload.statusText;
    // 	} else if (statusCode !== 401 && statusCode !== 0) {
    // 		userStateServerErrorMessage = action.payload.error.message;
    // 	}
    // 	return {
    // 		...state,
    // 		userStateServerErrorMessage,
    // 		registerUserLoading: false,
    // 		registerUserLoaded: true
    // 	};
    // }

    case fromLogin.REGISTER_USER: {
      return {
        ...state,
        registerUserLoading: true,
        registerUserLoaded: false,
        registerUserErrorMessage: null,
      };
    }

    case fromLogin.REGISTER_USER_SUCCESS: {
      const statusCode = action.payload.code;
      let verifyPasswordResponse = state.verifyPasswordResponse;
      let registerUserLoading = state.registerUserLoading;
      let registerUserLoaded = state.registerUserLoaded;
      let registerUserErrorMessage = state.registerUserErrorMessage;
      let registerStatusCode = 0;
      let token = state.token;
      switch (statusCode) {
        case 200:
          verifyPasswordResponse = action.payload.result;
          token = verifyPasswordResponse["token"];
          const encoder = new EncrDecrUtil();
          const user_encoder_path = encoder.set('user');
          localStorage.setItem(
            user_encoder_path,
            encoder.set(JSON.stringify(action.payload.result))
          );
          registerUserLoading = false;
          registerUserLoaded = true;
          registerStatusCode = action.payload.code;
          registerUserErrorMessage = action.payload.message;
          break;
        case 717:
          registerUserLoading = false;
          registerUserLoaded = true;
          registerStatusCode = 717;
          break;
        case 603:
          registerUserLoading = false;
          registerUserLoaded = true;
          registerStatusCode = 603;
          break;
      }
      return {
        ...state,
        verifyPasswordResponse,
        registerUserLoading,
        registerUserLoaded,
        registerUserErrorMessage,
        registerStatusCode,
        token,
      };
    }
    case fromLogin.REGISTER_USER_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        registerUserLoading: false,
        registerUserLoaded: true,
      };
    }

    case fromLogin.GET_USER_DETAILS: {
      const token = action.payload.token;
      return {
        ...state,
        isPasswordLoading: true,
        isPasswordLoaded: false,
        passwordErrorMessage: null,
        verifyPasswordResponse: {},
        token,
      };
    }
    case fromLogin.GET_USER_DETAILS_SUCCESS: {
      const statusCode = action.payload.code;
      let verifyPasswordResponse = state.verifyPasswordResponse;
      let isPasswordLoading = state.isPasswordLoading;
      let isPasswordLoaded = state.isPasswordLoaded;
      let passwordErrorMessage = state.passwordErrorMessage;
      let selectedLanguage = state.selectedLanguage;
      const verifyPasswordStatusCode = statusCode;
      switch (statusCode) {
        case 200:
          verifyPasswordResponse = action.payload.result;
          
          selectedLanguage = verifyPasswordResponse["lang"];
          isPasswordLoading = false;
          isPasswordLoaded = true;
          break;
        case 201:
          isPasswordLoading = false;
          isPasswordLoaded = true;
          passwordErrorMessage = action.payload.message;
          break;
        case 603:
          isPasswordLoading = false;
          isPasswordLoaded = true;
          passwordErrorMessage = action.payload.message;
          break;
        case 605:
          isPasswordLoading = false;
          isPasswordLoaded = true;
          passwordErrorMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        verifyPasswordResponse,
        isPasswordLoading: false,
        isPasswordLoaded: true,
        passwordErrorMessage,
        verifyPasswordStatusCode,
        selectedLanguage,
      };
    }
    case fromLogin.GET_USER_DETAILS_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isPasswordLoading: false,
        isPasswordLoaded: true,
      };
    }

    case fromLogin.ADD_TOKEN_USER_ID: {
      const token = action.payload.token;
      return {
        ...state,
        token,
      };
    }

    case fromLogin.RESET_MOBILE_NUMBER: {
      const userStateResponse = state.userStateResponse;
      return {
        ...state,
        userStateResponse: {},
        isUserStateLoading: false,
        isUserStateLoaded: false,
        userErrorMessage: null,

        sendOtpResponse: {},
        isSendOtpLoading: false,
        isSendOtpLoaded: false,
        sendOtpErrorMessage: null,

        verifyPasswordResponse: {},
        isPasswordLoading: false,
        isPasswordLoaded: false,
        passwordErrorMessage: null,
        verifyOtpSuccessCode: 0,

        verifyOtpResponse: {},
        verifyOtpStatusCode: null,
        isverifyOtpLoading: false,
        isverifyOtpLoaded: false,
        verifyOtpErrorMessage: null,
        fetchOtp: null,

        facebookErrorMessage: null,
        twitterErrorMessage: null,
        googleErrorMessage: null,
        forgotPasswordErrorMessage: null,
        registerUserErrorMessage: null,
      };
    }

    case fromLogin.RESET_LOGIN: {
      return {
        ...state,
        userStateResponse: {},
        isUserStateLoading: false,
        isUserStateLoaded: false,
        userErrorMessage: null,
        mobileNumber: null,
        userStateStatusCode: 0,

        verifyPasswordResponse: {},
        isPasswordLoading: false,
        isPasswordLoaded: false,
        passwordErrorMessage: null,
        verifyPasswordStatusCode: 0,
        verifyPasswordsValidate:null,
        verifyPasswordCheckSum:null,

        isloginWithOTPLoaded:false,
        isloginWithOTPLoading:false,
        loginWithOTPResponse:{},
        loginWithOTPStatusCode:0,
        loginWithOTPErrorMessage:null,
        loginWithOTPValidate:null,

        isloginEmployeeLoaded:false,
        isloginEmployeeLoading:false,
        loginEmployeeResponse:{},
        loginEmployeeStatusCode:0,
        loginEmployeeErrorMessage:null,
        loginEmployeeValidate:null,

        isEmployeeStateLoaded:false,
        isEmployeeStateLoading:false,
        EmployeeStateResponse:{},
        EmployeeStateStatusCode:0,
        employeeErrorMessage:null,

        sendOtpResponse: {},
        isSendOtpLoading: false,
        isSendOtpLoaded: false,
        sendOtpErrorMessage: null,
        sendOtpSuccessCode: 0,

        verifyOtpResponse: {},
        verifyOtpStatusCode: null,
        isverifyOtpLoading: false,
        isverifyOtpLoaded: false,
        verifyOtpErrorMessage: null,
        verifyOtpSuccessCode: 0,
        fetchOtp: null,

        facebookLoginResponse: {},
        facebookLoginLoading: false,
        facebookLoginLoaded: false,
        facebookErrorMessage: null,
        facebookStatusCode: 0,
        facebookLoginData: {},

        twitterLoginResponse: {},
        twitterLoginLoading: false,
        twitterLoginLoaded: false,
        twitterErrorMessage: null,
        twitterStatusCode: 0,
        twitterLoginData: {},

        googleLoginResponse: {},
        googleLoginLoading: false,
        googleLoginLoaded: false,
        googleErrorMessage: null,
        googleStatusCode: 0,
        googleLoginData: {},

        forgotPasswordResponse: 0,
        isForgotPasswordLoading: false,
        isForgotPasswordLoaded: false,
        forgotPasswordErrorMessage: null,
        forgotPasswordStatusCode: 0,
        forgotPasswordValidate:null,

        isUpdateProfileLoading: false,
        isUpdateProfileLoaded: false,
        updateUserProfile: {},
        errorMessage: null,
        updateStatusCode: 0,

        registerUserResponse: {},
        registerUserLoading: false,
        registerUserLoaded: false,
        registerUserErrorMessage: null,
        registerStatusCode: 0,

        UserDetailsList: [],
        isUserDetailsLoading: false,
        isUserDetailsLoaded: false,
        // token: null,

        isUserProfileLoading: false,

        mostUsedServicesResult: [],
        isMostUsedServicesLoaded: false,
        isMostUsedServicesLoading: false,

        updateLanguage: {},
        updateLanguageStatusCode: 0,

        getUpdatePasswardResponse: null,
        isUpdatePasswardLoading: false,
        isUpdatePasswardLoaded: false,
        updatePasswordErrorMessage: null,
        updatePasswordStatusCode: 0,
        updatePasswordResponse:null,
        updatePasswordValidate:null,

        updateProfilePhotoResponse: {},
        updateProfilePhotoStatusCode: 0,

        favImageResponse: {},
        favImageResponseStatusCode: 0,
        favImageResponseErrorMessage: null,
        favImageResponseLoading: false,
        favImageResponseLoaded: false,

        changeMobileNumberResponse: {},
        changeMobileNumberStatusCode: 0,
        changeMobileNumberErrorMessage: null,
        changeMobileNumbrLoading: false,
        changeMobileNumbrLoaded: false,

        verifyMobileNumberResponse: {},
        verifyMobileNumberStatusCode: 0,
        verifyMobileNumberErrorMessage: null,
        verifyMobileNumbrLoading: false,
        verifyMobileNumbrLoaded: false,

        getOtpChoicesResponse: {},
        getOtpChoicesStatusCode: 0,
        getOtpChoicesErrorMessage: null,
        getOtpChoicesLoading: false,
        getOtpChoicesLoaded: false,

        termsAndConditions: {},
        termsAndConditionsStatusCode: 0,
        termsAndConditionsErrorMessage: null,
        isTermsAndConditionsLoading: false,
        isTermsAndConditionsLoaded: false,
        userStateServerErrorMessage: null,

        isProfilePasswordLoading: false,
      };
    }

    case fromLogin.RESET_UPDATE_PROFILE: {
      return {
        ...state,
        isPasswordLoading: false,
        isPasswordLoaded: false,
        passwordErrorMessage: null,
        verifyPasswordStatusCode: 0,
      };
    }

    case fromLogin.RESET_CHANGE_MOBILE_NUMBER: {
      return {
        ...state,
        changeMobileNumberStatusCode: 0,
        changeMobileNumberErrorMessage: null,
        changeMobileNumbrLoading: false,
        changeMobileNumbrLoaded: false,

        verifyMobileNumberStatusCode: 0,
        verifyMobileNumberErrorMessage: null,
        verifyMobileNumbrLoading: false,
        verifyMobileNumbrLoaded: false,
      };
    }

    case fromLogin.RESET_FEEDS: {
      return {
        ...state,
        mostUsedServicesResult: [],
        isMostUsedServicesLoaded: false,
        isMostUsedServicesLoading: false,
      };
    }

    case fromLogin.RESET_FORGET_PASSWORD: {
      return {
        ...state,
        getOtpChoicesResponse: {},
        getOtpChoicesStatusCode: 0,
        getOtpChoicesErrorMessage: null,
        getOtpChoicesLoading: false,
        getOtpChoicesLoaded: false,
      };
    }

    case fromLogin.RESET_UPDATE_PASSWORD: {
      return {
        ...state,
        getUpdatePasswardResponse: null,
        isUpdatePasswardLoading: false,
        isUpdatePasswardLoaded: false,
        updatePasswordErrorMessage: null,
        updatePasswordStatusCode: 0,
        updatePasswordResponse:null,
        updatePasswordValidate:null,
      };
    }

    case fromLogin.GET_MOST_USED_SERVICES_LISTING: {
      return {
        ...state,
        isMostUsedServicesLoaded: false,
        isMostUsedServicesLoading: true,
      };
    }
    case fromLogin.GET_MOST_USED_SERVICES_LISTING_SUCCESS: {
      const statusCode = action.payload.code;
      let mostUsedServicesResult = state.mostUsedServicesResult;
      let isMostUsedServicesLoaded = state.isMostUsedServicesLoaded;
      let isMostUsedServicesLoading = state.isMostUsedServicesLoading;
      let errorMessage = state.errorMessage;

      switch (statusCode) {
        case 200:
          mostUsedServicesResult = action.payload.result;
          isMostUsedServicesLoaded = true;
          isMostUsedServicesLoading = false;
          break;

        case 500:
          isMostUsedServicesLoaded = true;
          isMostUsedServicesLoading = false;
          errorMessage = action.payload.message;
          break;
      }

      return {
        ...state,
        mostUsedServicesResult,
        isMostUsedServicesLoaded,
        isMostUsedServicesLoading,
      };
    }
    case fromLogin.GET_MOST_USED_SERVICES_LISTING_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
      };
    }

    case fromLogin.UPDATE_LANGUAGE: {
      return {
        ...state,
      };
    }
    case fromLogin.UPDATE_LANGUAGE_SUCCESS: {
      const statusCode = action.payload.code;
      let updateLanguage = state.updateLanguage;
      let updateLanguageStatusCode = 0;
      switch (statusCode) {
        case 200:
          updateLanguage = action.payload.result;
          updateLanguageStatusCode = action.payload.code;
          break;
      }
      return {
        ...state,
        updateLanguage,
        updateLanguageStatusCode,
      };
    }
    case fromLogin.UPDATE_LANGUAGE_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
      };
    }

    case fromLogin.UPDATE_PASSWARD: {
      return {
        ...state,
        isUpdatePasswardLoading: true,
        isUpdatePasswardLoaded: false,
      };
    }
    case fromLogin.UPDATE_PASSWARD_SUCCESS: {
      const statusCode = action.payload.code;
      let getUpdatePasswardResponse = state.getUpdatePasswardResponse;
      let isUpdatePasswardLoading = state.isUpdatePasswardLoading;
      let isUpdatePasswardLoaded = state.isUpdatePasswardLoaded;
      let updatePasswordResponse = action.payload.message;
      let updatePasswordErrorMessage = state.updatePasswordErrorMessage;
      let updatePasswordStatusCode = state.updatePasswordStatusCode;
     let updatePasswordValidate = action.payload.validate;
      switch (statusCode) {
        case 200:
          getUpdatePasswardResponse = action.payload.result;
          updatePasswordErrorMessage = action.payload.message;
          isUpdatePasswardLoading = false;
          isUpdatePasswardLoaded = true;
          updatePasswordStatusCode = 200;
          updatePasswordResponse = action.payload.message;
          updatePasswordValidate = action.payload.validate;
          break;
        case 201:
          getUpdatePasswardResponse = action.payload.result;
          updatePasswordResponse =action.payload.message;
          isUpdatePasswardLoading = false;
          isUpdatePasswardLoaded = true;
          updatePasswordStatusCode = 201;
          updatePasswordErrorMessage = action.payload.message;
          updatePasswordValidate = action.payload.validate;
          break;
      }

      // if onChangePasswordSuccess method was supplied, call it
      // if (action.payload.onChangePasswordSuccess && statusCode === 200) {
      //   action.payload.onChangePasswordSuccess(action.payload);
      // }

      return {
        ...state,
        getUpdatePasswardResponse,
        isUpdatePasswardLoading,
        isUpdatePasswardLoaded,
        updatePasswordErrorMessage,
        updatePasswordStatusCode,
        updatePasswordResponse,
        updatePasswordValidate,
      };
    }
    case fromLogin.UPDATE_PASSWARD_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
      };
    }

    case fromLogin.UPDATE_PROFILE_PHOTO: {
      let optionForFileUpload = action.payload.option;
      let uploadContentType = action.payload.type;
      return {
        ...state,
        optionForFileUpload,
        uploadContentType,
        isUpdateProfilePhotoLoading: true,
        isUpdateProfilePhotoLoaded: false,
      };
    }
    case fromLogin.UPDATE_PROFILE_PHOTO_SUCCESS: {
      let statusCode = action.payload.code;
      let option = state.optionForFileUpload;
      let updateProfilePhotoResponse = state.updateProfilePhotoResponse;
      let isUpdateProfilePhotoLoading = state.isUpdateProfilePhotoLoading;
      let isUpdateProfilePhotoLoaded = state.isUpdateProfilePhotoLoaded;
      let isUpdateProfilePhotoPropertyLoading =
        state.isUpdateProfilePhotoPropertyLoading;
      let isUpdateProfilePhotoPropertyLoaded =
        state.isUpdateProfilePhotoPropertyLoaded;
      let updateProfilePhotoResponseCertificate =
        state.updateProfilePhotoResponseCertificate;
      let updateProfilePhotoResponseProperty =
        state.updateProfilePhotoResponseProperty;
      let isUpdateProfilePhotoUdyogAadharLoading =
        state.isUpdateProfilePhotoUdyogAadharLoading;
      let isUpdateProfilePhotoUdyogAadharLoaded =
        state.isUpdateProfilePhotoUdyogAadharLoaded;
      let updateProfilePhotoResponseUdyogAadhar =
        state.updateProfilePhotoResponseUdyogAadhar;
      let updateProfilePhotoResponseGst = state.updateProfilePhotoResponseGst;
      let updateProfilePhotoResponseFss = state.updateProfilePhotoResponseFss;
      let updateProfilePhotoResponsePan = state.updateProfilePhotoResponsePan;
      let updateProfilePhotoResponseShopAct =
        state.updateProfilePhotoResponseShopAct;
      let isUpdateProfilePhotoGstLoading = state.isUpdateProfilePhotoGstLoading;
      let isUpdateProfilePhotoGstLoaded = state.isUpdateProfilePhotoGstLoaded;
      let isUpdateProfilePhotoFssLoading = state.isUpdateProfilePhotoFssLoading;
      let isUpdateProfilePhotoFssLoaded = state.isUpdateProfilePhotoFssLoaded;
      let isUpdateProfilePhotoPanLoading = state.isUpdateProfilePhotoPanLoading;
      let isUpdateProfilePhotoPanLoaded = state.isUpdateProfilePhotoPanLoaded;
      let isUpdateProfilePhotoShopActLoading =
        state.isUpdateProfilePhotoShopActLoading;
      let isUpdateProfilePhotoShopActLoaded =
        state.isUpdateProfilePhotoShopActLoaded;
      let updateProfilePhotoStatusCode = 0;
      switch (statusCode) {
        case 200:
          if (option === "certificateFileUpload") {
            isUpdateProfilePhotoLoading = false;
            isUpdateProfilePhotoLoaded = true;
            isUpdateProfilePhotoPropertyLoading = false;
            isUpdateProfilePhotoPropertyLoaded = false;
            isUpdateProfilePhotoGstLoading = false;
            isUpdateProfilePhotoGstLoaded = false;
            isUpdateProfilePhotoFssLoading = false;
            isUpdateProfilePhotoFssLoaded = false;
            isUpdateProfilePhotoPanLoading = false;
            isUpdateProfilePhotoPanLoaded = false;
            isUpdateProfilePhotoShopActLoading = false;
            isUpdateProfilePhotoShopActLoaded = false;
            isUpdateProfilePhotoUdyogAadharLoading = false;
            isUpdateProfilePhotoUdyogAadharLoaded = false;
            updateProfilePhotoResponseCertificate = action.payload.result;
            updateProfilePhotoStatusCode = action.payload.code;
            break;
          } else if (option === "propertyFileUpload") {
            isUpdateProfilePhotoPropertyLoading = false;
            isUpdateProfilePhotoPropertyLoaded = true;
            isUpdateProfilePhotoLoading = false;
            isUpdateProfilePhotoLoaded = false;
            isUpdateProfilePhotoGstLoading = false;
            isUpdateProfilePhotoGstLoaded = false;
            isUpdateProfilePhotoFssLoading = false;
            isUpdateProfilePhotoFssLoaded = false;
            isUpdateProfilePhotoPanLoading = false;
            isUpdateProfilePhotoPanLoaded = false;
            isUpdateProfilePhotoShopActLoading = false;
            isUpdateProfilePhotoShopActLoaded = false;
            isUpdateProfilePhotoUdyogAadharLoading = false;
            isUpdateProfilePhotoUdyogAadharLoaded = false;
            updateProfilePhotoResponseProperty = action.payload.result;
            updateProfilePhotoStatusCode = action.payload.code;
            break;
          } else if (option === "gstFileUpload") {
            isUpdateProfilePhotoGstLoading = false;
            isUpdateProfilePhotoGstLoaded = true;
            isUpdateProfilePhotoLoading = false;
            isUpdateProfilePhotoLoaded = false;
            isUpdateProfilePhotoFssLoading = false;
            isUpdateProfilePhotoFssLoaded = false;
            isUpdateProfilePhotoPanLoading = false;
            isUpdateProfilePhotoPanLoaded = false;
            isUpdateProfilePhotoShopActLoading = false;
            isUpdateProfilePhotoShopActLoaded = false;
            isUpdateProfilePhotoUdyogAadharLoading = false;
            isUpdateProfilePhotoUdyogAadharLoaded = false;
            updateProfilePhotoResponseGst = action.payload.result;
            updateProfilePhotoStatusCode = action.payload.code;
          } else if (option === "fssFileUpload") {
            isUpdateProfilePhotoFssLoading = false;
            isUpdateProfilePhotoFssLoaded = true;
            isUpdateProfilePhotoLoading = false;
            isUpdateProfilePhotoLoaded = false;
            isUpdateProfilePhotoGstLoading = false;
            isUpdateProfilePhotoGstLoaded = false;
            isUpdateProfilePhotoPanLoading = false;
            isUpdateProfilePhotoPanLoaded = false;
            isUpdateProfilePhotoShopActLoading = false;
            isUpdateProfilePhotoShopActLoaded = false;
            isUpdateProfilePhotoUdyogAadharLoading = false;
            isUpdateProfilePhotoUdyogAadharLoaded = false;
            updateProfilePhotoResponseFss = action.payload.result;
            updateProfilePhotoStatusCode = action.payload.code;
          } else if (option === "panFileUpload") {
            isUpdateProfilePhotoPanLoading = false;
            isUpdateProfilePhotoPanLoaded = true;
            isUpdateProfilePhotoLoading = false;
            isUpdateProfilePhotoLoaded = false;
            isUpdateProfilePhotoGstLoading = false;
            isUpdateProfilePhotoGstLoaded = false;
            isUpdateProfilePhotoFssLoading = false;
            isUpdateProfilePhotoFssLoaded = false;
            isUpdateProfilePhotoShopActLoading = false;
            isUpdateProfilePhotoShopActLoaded = false;
            isUpdateProfilePhotoUdyogAadharLoading = false;
            isUpdateProfilePhotoUdyogAadharLoaded = false;
            updateProfilePhotoResponsePan = action.payload.result;
            updateProfilePhotoStatusCode = action.payload.code;
          } else if (option === "shopActFileUpload") {
            isUpdateProfilePhotoShopActLoading = false;
            isUpdateProfilePhotoShopActLoaded = true;
            isUpdateProfilePhotoLoading = false;
            isUpdateProfilePhotoLoaded = false;
            isUpdateProfilePhotoGstLoading = false;
            isUpdateProfilePhotoGstLoaded = false;
            isUpdateProfilePhotoFssLoading = false;
            isUpdateProfilePhotoFssLoaded = false;
            isUpdateProfilePhotoPanLoading = false;
            isUpdateProfilePhotoPanLoaded = false;
            isUpdateProfilePhotoUdyogAadharLoading = false;
            isUpdateProfilePhotoUdyogAadharLoaded = false;
            updateProfilePhotoResponseShopAct = action.payload.result;
            updateProfilePhotoStatusCode = action.payload.code;
          } else if (option === "udyogAadharFileUpload") {
            isUpdateProfilePhotoShopActLoading = false;
            isUpdateProfilePhotoShopActLoaded = false;
            isUpdateProfilePhotoLoading = false;
            isUpdateProfilePhotoLoaded = false;
            isUpdateProfilePhotoGstLoading = false;
            isUpdateProfilePhotoGstLoaded = false;
            isUpdateProfilePhotoFssLoading = false;
            isUpdateProfilePhotoFssLoaded = false;
            isUpdateProfilePhotoPanLoading = false;
            isUpdateProfilePhotoPanLoaded = false;
            isUpdateProfilePhotoUdyogAadharLoading = false;
            isUpdateProfilePhotoUdyogAadharLoaded = true;
            updateProfilePhotoResponseUdyogAadhar = action.payload.result;
            updateProfilePhotoStatusCode = action.payload.code;
          } else {
            isUpdateProfilePhotoLoading = false;
            isUpdateProfilePhotoLoaded = true;
            isUpdateProfilePhotoGstLoading = false;
            isUpdateProfilePhotoGstLoaded = false;
            isUpdateProfilePhotoFssLoading = false;
            isUpdateProfilePhotoFssLoaded = false;
            isUpdateProfilePhotoShopActLoading = false;
            isUpdateProfilePhotoShopActLoaded = false;
            isUpdateProfilePhotoPanLoading = false;
            isUpdateProfilePhotoPanLoaded = false;
            updateProfilePhotoResponse = action.payload.result;
            updateProfilePhotoStatusCode = action.payload.code;
            break;
          }
      }

      return {
        ...state,
        updateProfilePhotoResponse,
        updateProfilePhotoResponseGst,
        updateProfilePhotoResponseFss,
        updateProfilePhotoResponsePan,
        updateProfilePhotoResponseShopAct,
        updateProfilePhotoResponseUdyogAadhar,
        updateProfilePhotoStatusCode,
        isUpdateProfilePhotoLoading,
        isUpdateProfilePhotoPropertyLoading,
        isUpdateProfilePhotoPropertyLoaded,
        isUpdateProfilePhotoLoaded,
        isUpdateProfilePhotoGstLoading,
        isUpdateProfilePhotoGstLoaded,
        isUpdateProfilePhotoFssLoading,
        isUpdateProfilePhotoFssLoaded,
        isUpdateProfilePhotoPanLoading,
        isUpdateProfilePhotoPanLoaded,
        isUpdateProfilePhotoShopActLoading,
        isUpdateProfilePhotoShopActLoaded,
        isUpdateProfilePhotoUdyogAadharLoading,
        isUpdateProfilePhotoUdyogAadharLoaded,
        updateProfilePhotoResponseProperty,
        updateProfilePhotoResponseCertificate,
      };
    }
    case fromLogin.UPDATE_PROFILE_PHOTO_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
      };
    }

    case fromLogin.FAV_IMAGE: {
      // let optionForFileUpload = action.payload.option;
      // let uploadContentType = action.payload.type;
      return {
        ...state,
        // optionForFileUpload,
        // uploadContentType,
        favImageResponseLoading: true,
        favImageResponseLoaded: false,
      };
    }

    case fromLogin.FAV_IMAGE_SUCCESS: {
      let statusCode = action.payload.code;
      let favImageResponse = state.favImageResponse;
  
      let favImageResponseLoading = state.favImageResponseLoading;
      let favImageResponseLoaded = state.favImageResponseLoaded;
      let favImageResponseStatusCode = 0;
      switch (statusCode) {
        case 200:
          favImageResponse = action.payload.result;
          favImageResponseStatusCode = action.payload.code;
          favImageResponseLoading = false;
          favImageResponseLoaded = true;
          break;
      }
      
      return {
        ...state,
        favImageResponse,
        favImageResponseStatusCode,
        favImageResponseLoading,
        favImageResponseLoaded,
      };
    }
    case fromLogin.FAV_IMAGE_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
      };
    }

    case fromLogin.CHANGE_MOBILE_NUMBER: {
      let mobileNumber = state.mobileNumber;
      mobileNumber = action.payload.mobileNumber;
      return {
        ...state,
        changeMobileNumbrLoading: true,
        changeMobileNumbrLoaded: false,
        changeMobileNumberErrorMessage: null,
        mobileNumber,
      };
    }
    case fromLogin.CHANGE_MOBILE_NUMBER_SUCCESS: {
      const statusCode = action.payload.code;
      let changeMobileNumberResponse = state.changeMobileNumberResponse;
      let changeMobileNumberStatusCode = 0;
      let changeMobileNumbrLoading = state.changeMobileNumbrLoading;
      let changeMobileNumbrLoaded = state.changeMobileNumbrLoaded;
      let changeMobileNumberErrorMessage = state.changeMobileNumberErrorMessage;
      switch (statusCode) {
        case 200:
          changeMobileNumberResponse = action.payload.result;
          changeMobileNumbrLoading = false;
          changeMobileNumbrLoaded = true;
          changeMobileNumberStatusCode = action.payload.code;
          changeMobileNumberErrorMessage = action.payload.message;
          break;
        case 713:
          changeMobileNumbrLoading = false;
          changeMobileNumbrLoaded = true;
          changeMobileNumberStatusCode = action.payload.code;
          break;
      }
      return {
        ...state,
        changeMobileNumberResponse,
        changeMobileNumbrLoading,
        changeMobileNumbrLoaded,
        changeMobileNumberErrorMessage,
        changeMobileNumberStatusCode,
      };
    }
    case fromLogin.CHANGE_MOBILE_NUMBER_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
      };
    }

    case fromLogin.VERIFY_MOBILE_NUMBER: {
      return {
        ...state,
        verifyMobileNumbrLoading: true,
        verifyMobileNumbrLoaded: false,
        verifyMobileNumberErrorMessage: null,
      };
    }
    case fromLogin.VERIFY_MOBILE_NUMBER_SUCCESS: {
      const statusCode = action.payload.code;
      const verifyMobileNumberResponse = state.verifyMobileNumberResponse;
      let verifyMobileNumberStatusCode = 0;
      let verifyMobileNumbrLoading = state.verifyMobileNumbrLoading;
      let verifyMobileNumbrLoaded = state.verifyMobileNumbrLoaded;
      let verifyPasswordResponse = state.verifyPasswordResponse;
      let verifyMobileNumberErrorMessage = state.verifyMobileNumberErrorMessage;
      let selectedLanguage = state.selectedLanguage;
      switch (statusCode) {
        case 200:
          verifyPasswordResponse = action.payload.result;
          selectedLanguage = verifyPasswordResponse["lang"];
          verifyMobileNumbrLoading = false;
          verifyMobileNumbrLoaded = true;
          verifyMobileNumberStatusCode = action.payload.code;
          verifyMobileNumberErrorMessage = action.payload.message;
          break;
        case 701:
          verifyMobileNumberStatusCode = action.payload.code;
          verifyMobileNumbrLoading = false;
          verifyMobileNumbrLoaded = true;
          break;
        case 702:
          verifyMobileNumberStatusCode = action.payload.code;
          verifyMobileNumbrLoading = false;
          verifyMobileNumbrLoaded = true;
          break;
        case 603:
          verifyMobileNumberStatusCode = action.payload.code;
          verifyMobileNumbrLoading = false;
          verifyMobileNumbrLoaded = true;
          break;
      }
      return {
        ...state,
        verifyMobileNumberResponse,
        verifyMobileNumbrLoading,
        verifyMobileNumbrLoaded,
        verifyPasswordResponse,
        verifyMobileNumberErrorMessage,
        verifyMobileNumberStatusCode,
        selectedLanguage,
      };
    }
    case fromLogin.VERIFY_MOBILE_NUMBER_FAIL: {
      const statusCode = action.payload.status;
      let verifyPasswordResponse = state.verifyMobileNumberResponse;
      let selectedLanguage = state.selectedLanguage;
      if (statusCode === 400) {
        verifyPasswordResponse = action.payload.result;
        selectedLanguage = verifyPasswordResponse["lang"];
      }
      return {
        ...state,
        verifyPasswordResponse,
        selectedLanguage,
        verifyMobileNumbrLoading: false,
        verifyMobileNumbrLoaded: true,
      };
    }

    case fromLogin.GET_OTP_CHOICES: {
      return {
        ...state,
        getOtpChoicesErrorMessage: null,
        getOtpChoicesLoading: true,
        getOtpChoicesLoaded: false,
      };
    }
    case fromLogin.GET_OTP_CHOICES_SUCCESS: {
      const statusCode = action.payload.code;
      let getOtpChoicesResponse = state.getOtpChoicesResponse;
      let getOtpChoicesStatusCode = statusCode;
      let getOtpChoicesLoading = state.getOtpChoicesLoading;
      let getOtpChoicesLoaded = state.getOtpChoicesLoaded;
      let getOtpChoicesErrorMessage = state.getOtpChoicesErrorMessage;
      switch (statusCode) {
        case 200:
          getOtpChoicesResponse = action.payload.result;
          getOtpChoicesLoading = false;
          getOtpChoicesLoaded = true;
          getOtpChoicesStatusCode = action.payload.code;
          getOtpChoicesErrorMessage = action.payload.message;
          break;
        case 205:
          getOtpChoicesLoading = false;
          getOtpChoicesLoaded = true;
          getOtpChoicesStatusCode = action.payload.code;
          getOtpChoicesErrorMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        getOtpChoicesResponse,
        getOtpChoicesLoading,
        getOtpChoicesLoaded,
        getOtpChoicesErrorMessage,
        getOtpChoicesStatusCode,
      };
    }
    case fromLogin.GET_OTP_CHOICES_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        getOtpChoicesLoading: false,
        getOtpChoicesLoaded: true,
      };
    }

    case fromLogin.TERMS_AND_CONDITIONS: {
      return {
        ...state,
        termsAndConditionsErrorMessage: null,
        isTermsAndConditionsLoading: true,
        isTermsAndConditionsLoaded: false,
      };
    }
    case fromLogin.TERMS_AND_CONDITIONS_SUCCESS: {
      const statusCode = action.payload.code;
      let termsAndConditions = state.termsAndConditions;
      let termsAndConditionsStatusCode = action.payload.code;
      let isTermsAndConditionsLoading = state.isTermsAndConditionsLoading;
      let isTermsAndConditionsLoaded = state.isTermsAndConditionsLoaded;
      let termsAndConditionsErrorMessage = state.termsAndConditionsErrorMessage;
      switch (statusCode) {
        case 200:
          termsAndConditions = action.payload.result;
          isTermsAndConditionsLoading = false;
          isTermsAndConditionsLoaded = true;
          termsAndConditionsStatusCode = 200;
          break;
        case 500:
          isTermsAndConditionsLoading = false;
          isTermsAndConditionsLoaded = true;
          termsAndConditionsStatusCode = action.payload.code;
          termsAndConditionsErrorMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        termsAndConditions,
        isTermsAndConditionsLoaded: true,
        isTermsAndConditionsLoading: false,
        termsAndConditionsErrorMessage,
        termsAndConditionsStatusCode,
      };
    }
    case fromLogin.TERMS_AND_CONDITIONS_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      // let serverStatusCode = state.serverStatusCode;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        // serverStatusCode,
        userStateServerErrorMessage,
        isTermsAndConditionsLoaded: true,
        isTermsAndConditionsLoading: false,
      };
    }

    case fromLogin.MERCHANT_TERMS_AND_CONDITIONS: {
      return {
        ...state,
        merchantTermsAndConditionsErrorMessage: null,
        merchantTermsAndConditionsLoading: true,
        merchantTermsAndConditionsLoaded: false,
      };
    }
    case fromLogin.MERCHANT_TERMS_AND_CONDITIONS_SUCCESS: {
      const statusCode = action.payload.code;
      let merchantTermsAndConditions = state.merchantTermsAndConditions;
      let merchantTermsAndConditionsStatusCode = action.payload.code;
      let merchantTermsAndConditionsLoading =
        state.merchantTermsAndConditionsLoading;
      let merchantTermsAndConditionsLoaded =
        state.merchantTermsAndConditionsLoaded;
      let merchantTermsAndConditionsErrorMessage =
        state.merchantTermsAndConditionsErrorMessage;
      switch (statusCode) {
        case 200:
          merchantTermsAndConditions = action.payload.result;
          merchantTermsAndConditionsLoading = false;
          merchantTermsAndConditionsLoaded = true;
          merchantTermsAndConditionsStatusCode = 200;
          break;
        case 500:
          merchantTermsAndConditionsLoading = false;
          merchantTermsAndConditionsLoaded = true;
          merchantTermsAndConditionsStatusCode = action.payload.code;
          merchantTermsAndConditionsErrorMessage = action.payload.message;
          break;
      }
      return {
        ...state,
        merchantTermsAndConditions,
        merchantTermsAndConditionsLoaded: true,
        merchantTermsAndConditionsLoading: false,
        merchantTermsAndConditionsErrorMessage,
        merchantTermsAndConditionsStatusCode,
      };
    }
    case fromLogin.MERCHANT_TERMS_AND_CONDITIONS_FAIL: {
      const statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      // let serverStatusCode = state.serverStatusCode;
      if (statusCode === 401 || statusCode === 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode !== 401 && statusCode !== 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        // serverStatusCode,
        userStateServerErrorMessage,
        merchantTermsAndConditionsLoaded: true,
        merchantTermsAndConditionsLoading: false,
      };
    }

    case fromLogin.INTERESTED_TO_BE_MERCHANT: {
      return {
        ...state,
        interestedToBeMerchantErrorMessage: null,
        isInterestedToBeMerchantLoading: true,
        isInterestedToBeMerchantLoaded: false,
      };
    }
    case fromLogin.INTERESTED_TO_BE_MERCHANT_SUCCESS: {
      let statusCode = action.payload.code;
      let interestedToBeMerchantErrorMessage =
        state.interestedToBeMerchantErrorMessage;
      let isInterestedToBeMerchantLoading =
        state.isInterestedToBeMerchantLoading;
      let isInterestedToBeMerchantLoaded = state.isInterestedToBeMerchantLoaded;
      let interestedToBeMerchantStatusCode = statusCode;
      let interestedToBeMerchantResponse = state.interestedToBeMerchantResponse;
      switch (statusCode) {
        case 200: {
          interestedToBeMerchantResponse = action.payload.result;
          interestedToBeMerchantErrorMessage = action.payload.message;
          isInterestedToBeMerchantLoading = false;
          isInterestedToBeMerchantLoaded = true;
          break;
        }
        case 1009: {
          interestedToBeMerchantResponse = action.payload.result;
          interestedToBeMerchantErrorMessage = action.payload.message;
          isInterestedToBeMerchantLoading = false;
          isInterestedToBeMerchantLoaded = true;
          break;
        }
      }
      return {
        ...state,
        interestedToBeMerchantErrorMessage,
        isInterestedToBeMerchantLoading: false,
        isInterestedToBeMerchantLoaded: true,
        interestedToBeMerchantStatusCode,
        interestedToBeMerchantResponse,
      };
    }
    case fromLogin.INTERESTED_TO_BE_MERCHANT_FAIL: {
      let statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode != 401 && statusCode != 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isInterestedToBeMerchantLoaded: true,
        isInterestedToBeMerchantLoading: false,
      };
    }

    case fromLogin.INTERESTED_TO_BE_BRAND: {
      return {
        ...state,
        interestedToBeBrandErrorMessage: null,
        isInterestedToBeBrandLoading: true,
        isInterestedToBeBrandLoaded: false,
      };
    }
    case fromLogin.INTERESTED_TO_BE_BRAND_SUCCESS: {
      let statusCode = action.payload.code;
      let interestedToBeBrandErrorMessage =
        state.interestedToBeBrandErrorMessage;
      let isInterestedToBeBrandLoading = state.isInterestedToBeBrandLoading;
      let isInterestedToBeBrandLoaded = state.isInterestedToBeBrandLoaded;
      let interestedToBeBrandStatusCode = statusCode;
      let interestedToBeBrandResponse = state.interestedToBeBrandResponse;
      switch (statusCode) {
        case 200: {
          interestedToBeBrandResponse = action.payload.result;
          interestedToBeBrandErrorMessage = action.payload.message;
          isInterestedToBeBrandLoading = false;
          isInterestedToBeBrandLoaded = true;
          break;
        }
        case 1009: {
          interestedToBeBrandResponse = action.payload.result;
          interestedToBeBrandErrorMessage = action.payload.message;
          isInterestedToBeBrandLoading = false;
          isInterestedToBeBrandLoaded = true;
          break;
        }
      }
      return {
        ...state,
        interestedToBeBrandErrorMessage,
        isInterestedToBeBrandLoading: false,
        isInterestedToBeBrandLoaded: true,
        interestedToBeBrandStatusCode,
        interestedToBeBrandResponse,
      };
    }
    case fromLogin.INTERESTED_TO_BE_BRAND_FAIL: {
      let statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode != 401 && statusCode != 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isInterestedToBeBrandLoaded: true,
        isInterestedToBeBrandLoading: false,
      };
    }

    case fromLogin.REGISTER_AS_MERCHANT: {
      return {
        ...state,
        registerAsMerchantErrorMessage: null,
        isRegisterAsMerchantLoading: true,
        isRegisterAsMerchantLoaded: false,
      };
    }
    case fromLogin.REGISTER_AS_MERCHANT_SUCCESS: {
      let statusCode = action.payload.code;
      let verifyPasswordResponse = state.verifyPasswordResponse;
      let registerAsMerchantErrorMessage = state.registerAsMerchantErrorMessage;
      let isRegisterAsMerchantLoading = state.isRegisterAsMerchantLoading;
      let isRegisterAsMerchantLoaded = state.isRegisterAsMerchantLoaded;
      let registerAsMerchantStatusCode = statusCode;
      switch (statusCode) {
        case 200: {
          verifyPasswordResponse = action.payload.result;
          registerAsMerchantErrorMessage = action.payload.message;
          isRegisterAsMerchantLoading = false;
          isRegisterAsMerchantLoaded = true;
          break;
        }
      }
      return {
        ...state,
        verifyPasswordResponse,
        registerAsMerchantErrorMessage,
        isRegisterAsMerchantLoading: false,
        isRegisterAsMerchantLoaded: true,
        registerAsMerchantStatusCode,
      };
    }
    case fromLogin.REGISTER_AS_MERCHANT_FAIL: {
      let statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode != 401 && statusCode != 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isRegisterAsMerchantLoaded: true,
        isRegisterAsMerchantLoading: false,
      };
    }

    case fromLogin.REGISTER_AS_BRAND: {
      return {
        ...state,
        registerAsBrandErrorMessage: null,
        isRegisterAsBrandLoading: true,
        isRegisterAsBrandLoaded: false,
      };
    }
    case fromLogin.REGISTER_AS_BRAND_SUCCESS: {
      let statusCode = action.payload.code;
      let verifyPasswordResponse = state.verifyPasswordResponse;
      let registerAsBrandErrorMessage = state.registerAsBrandErrorMessage;
      let isRegisterAsBrandLoading = state.isRegisterAsBrandLoading;
      let isRegisterAsBrandLoaded = state.isRegisterAsBrandLoaded;
      let registerAsBrandStatusCode = statusCode;
      switch (statusCode) {
        case 200: {
          verifyPasswordResponse = action.payload.result;
          registerAsBrandErrorMessage = action.payload.message;
          isRegisterAsBrandLoading = false;
          isRegisterAsBrandLoaded = true;
          break;
        }
      }
      return {
        ...state,
        verifyPasswordResponse,
        registerAsBrandErrorMessage,
        isRegisterAsBrandLoading: false,
        isRegisterAsBrandLoaded: true,
        registerAsBrandStatusCode,
      };
    }
    case fromLogin.REGISTER_AS_BRAND_FAIL: {
      let statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode != 401 && statusCode != 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isRegisterAsBrandLoaded: true,
        isRegisterAsBrandLoading: false,
      };
    }

    case fromLogin.GET_ALL_BRAND: {
      return {
        ...state,
        getAllBrandErrorMessage: null,
        isGetAllBrandLoading: true,
        isGetAllBrandLoaded: false,
      };
    }
    case fromLogin.GET_ALL_BRAND_SUCCESS: {
      let statusCode = action.payload.code;
      let getAllBrandResponse = state.getAllBrandResponse;
      let getAllBrandErrorMessage = state.getAllBrandErrorMessage;
      let isGetAllBrandLoading = state.isGetAllBrandLoading;
      let isGetAllBrandLoaded = state.isGetAllBrandLoaded;
      let getAllBrandStatusCode = statusCode;
      switch (statusCode) {
        case 200: {
          getAllBrandResponse = action.payload.result;
          getAllBrandErrorMessage = action.payload.message;
          isGetAllBrandLoading = false;
          isGetAllBrandLoaded = true;
          break;
        }
      }
      return {
        ...state,
        getAllBrandResponse,
        getAllBrandErrorMessage,
        isGetAllBrandLoading: false,
        isGetAllBrandLoaded: true,
        getAllBrandStatusCode,
      };
    }
    case fromLogin.GET_ALL_BRAND_FAIL: {
      let statusCode = action.payload.status;
      let userStateServerErrorMessage = state.userStateServerErrorMessage;
      if (statusCode == 401 || statusCode == 0) {
        userStateServerErrorMessage = action.payload.statusText;
      } else if (statusCode != 401 && statusCode != 0) {
        userStateServerErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        userStateServerErrorMessage,
        isGetAllBrandLoaded: true,
        isGetAllBrandLoading: false,
      };
    }
    case fromLogin.SUBSCRIBE_TO_NEWSLETTER: {
      return {
        ...state,
        getNewsletterSubscriptionErrorMessage: null,
        isNewsletterSubscriptionLoading: true,
        isNewsletterSubscriptionLoaded: false,
      };
    }
    case fromLogin.SUBSCRIBE_TO_NEWSLETTER_SUCCESS: {
      let statusCode = action.payload.code;
      
      let verifyPasswordResponse = state.verifyPasswordResponse;
      let getNewsletterSubscriptionErrorMessage =
        state.getNewsletterSubscriptionErrorMessage;
      let isNewsletterSubscriptionLoading =
        state.isNewsletterSubscriptionLoading;
      let isNewsletterSubscriptionLoaded = state.isNewsletterSubscriptionLoaded;
      let getNewsletterSubscriptionStatusCode = statusCode;
    
      switch (statusCode) {
        case 200: {
          verifyPasswordResponse = action.payload.result;
          getNewsletterSubscriptionErrorMessage = action.payload.message;
          isNewsletterSubscriptionLoading = false;
          isNewsletterSubscriptionLoaded = true;
          break;
        }
      }
      return {
        ...state,
        verifyPasswordResponse,
        getNewsletterSubscriptionErrorMessage,
        isNewsletterSubscriptionLoading,
        isNewsletterSubscriptionLoaded,
        getNewsletterSubscriptionStatusCode,
      };
    }
    case fromLogin.SUBSCRIBE_TO_NEWSLETTER_FAIL: {
      let statusCode = action.payload.status;
      let getNewsletterSubscriptionErrorMessage =
        state.getNewsletterSubscriptionErrorMessage;

      let getNewsletterSubscriptionStatusCode = 400;
      if (statusCode == 401 || statusCode == 0) {
        getNewsletterSubscriptionErrorMessage = action.payload.statusText;
      } else if (statusCode != 401 && statusCode != 0) {
        getNewsletterSubscriptionErrorMessage = action.payload.error.message;
      }
      return {
        ...state,
        getNewsletterSubscriptionStatusCode,
        getNewsletterSubscriptionErrorMessage,
        isNewsletterSubscriptionLoaded: true,
        isNewsletterSubscriptionLoading: false,
      };
    }
  }
  return state;
}

export const getSelectedLanguage = (state: LoginState) =>
  state.selectedLanguage;

export const getUserStateResponse = (state: LoginState) =>
  state.userStateResponse;
export const getUserStateLoading = (state: LoginState) =>
  state.isUserStateLoading;
export const getUserStateLoaded = (state: LoginState) =>
  state.isUserStateLoaded;
export const userErrorMessage = (state: LoginState) => state.userErrorMessage;
export const userStateStatusCode = (state: LoginState) =>
  state.userStateStatusCode;
export const mobileNumber = (state: LoginState) => state.mobileNumber;

export const getEmployeeStateResponse = (state: LoginState) =>
  state.EmployeeStateResponse;
export const getisEmployeeStateLoading = (state: LoginState) =>
  state.isEmployeeStateLoading;
export const getisEmployeeStateLoaded = (state: LoginState) =>
  state.isEmployeeStateLoaded;
export const getemployeeErrorMessage = (state: LoginState) => state.employeeErrorMessage;
export const getEmployeeStateStatusCode = (state: LoginState) =>
  state.EmployeeStateStatusCode;
export const getemployeeId = (state: LoginState) => state.employeeId;

export const sendOtpResponse = (state: LoginState) => state.sendOtpResponse;
export const isSendOtpLoading = (state: LoginState) => state.isSendOtpLoading;
export const isSendOtpLoaded = (state: LoginState) => state.isSendOtpLoaded;
export const sendOtpErrorMessage = (state: LoginState) =>
  state.sendOtpErrorMessage;
export const sendOtpSuccessCode = (state: LoginState) =>
  state.sendOtpSuccessCode;

export const verifyOtpResponse = (state: LoginState) => state.verifyOtpResponse;
export const verifyOtpStatusCode = (state: LoginState) =>
  state.verifyOtpStatusCode;
export const isverifyOtpLoading = (state: LoginState) =>
  state.isverifyOtpLoading;
export const isverifyOtpLoaded = (state: LoginState) => state.isverifyOtpLoaded;
export const verifyOtpErrorMessage = (state: LoginState) =>
  state.verifyOtpErrorMessage;
export const getVerifyOtpSuccessCode = (state: LoginState) =>
  state.verifyOtpSuccessCode;
export const fetchOtp = (state: LoginState) => state.fetchOtp;

export const facebookLoginResponse = (state: LoginState) =>
  state.facebookLoginResponse;
export const facebookLoginLoading = (state: LoginState) =>
  state.facebookLoginLoading;
export const facebookLoginLoaded = (state: LoginState) =>
  state.facebookLoginLoaded;
export const facebookLoginErrorMessage = (state: LoginState) =>
  state.facebookErrorMessage;
export const facebookStatusCode = (state: LoginState) =>
  state.facebookStatusCode;
export const facebookLoginData = (state: LoginState) => state.facebookLoginData;

export const twitterLoginResponse = (state: LoginState) =>
  state.twitterLoginResponse;
export const twitterLoginLoading = (state: LoginState) =>
  state.twitterLoginLoading;
export const twitterLoginLoaded = (state: LoginState) =>
  state.twitterLoginLoaded;
export const twitterLoginErrorMessage = (state: LoginState) =>
  state.twitterErrorMessage;
export const twitterStatusCode = (state: LoginState) => state.twitterStatusCode;
export const twitterLoginData = (state: LoginState) => state.twitterLoginData;

export const googleLoginResponse = (state: LoginState) =>
  state.googleLoginResponse;
export const googleLoginLoading = (state: LoginState) =>
  state.googleLoginLoading;
export const googleLoginLoaded = (state: LoginState) => state.googleLoginLoaded;
export const googleLoginErrorMessage = (state: LoginState) =>
  state.googleErrorMessage;
export const googleStatusCode = (state: LoginState) => state.googleStatusCode;
export const googleLoginData = (state: LoginState) => state.googleLoginData;

export const forgotPasswordResponse = (state: LoginState) =>
  state.forgotPasswordResponse;
export const isForgotPasswordLoading = (state: LoginState) =>
  state.isForgotPasswordLoading;
export const isForgotPasswordLoaded = (state: LoginState) =>
  state.isForgotPasswordLoaded; 
export const forgotPasswordErrorMessage = (state: LoginState) =>
  state.forgotPasswordErrorMessage;
export const forgotPasswordValidate = (state:LoginState) => 
  state.forgotPasswordValidate;

export const forgotPasswordByEmailResponse = (state: LoginState) =>
  state.forgotPasswordByEmailResponse;
export const isForgotPasswordByEmailLoading = (state: LoginState) =>
  state.isForgotPasswordByEmailLoading;
export const isForgotPasswordByEmailLoaded = (state: LoginState) =>
  state.isForgotPasswordByEmailLoaded;
export const forgotPasswordByEmailErrorMessage = (state: LoginState) =>
  state.forgotPasswordByEmailErrorMessage;

export const getVerifyPasswordResponse = (state: LoginState) =>
  state.verifyPasswordResponse;
export const getVerifyPasswordLoading = (state: LoginState) =>
  state.isPasswordLoading;
export const getVerifyPasswordLoaded = (state: LoginState) =>
  state.isPasswordLoaded;
export const getVerifyPasswordErrorMessage = (state: LoginState) =>
  state.passwordErrorMessage;
export const verifyPasswordStatusCode = (state: LoginState) =>
  state.verifyPasswordStatusCode;
export const verifyPasswordsValidate = (state:LoginState) =>
  state.verifyPasswordsValidate;
export const getverifyPasswordCheckSum = (state:LoginState) =>
  state.verifyPasswordCheckSum;


export const getLoginWithOTPResponse = (state:LoginState) => state.loginWithOTPResponse;
export const getLoginWithOTPLoading = (state:LoginState) => state.isloginWithOTPLoading;
export const getLoginWithOTPLoaded = (state:LoginState) => state.isloginWithOTPLoaded;
export const getLoginWithOTPStatusCode = (state:LoginState) => state.loginWithOTPStatusCode;
export const getLoginWithOTPValidate = (state:LoginState) => state.loginWithOTPValidate;
export const getLoginWithOTPCheckSum = (state:LoginState) => state.loginWithOTPCheckSum;
export const getLoginWithOTPErrorMessage = (state:LoginState) => state.loginWithOTPErrorMessage;

export const getloginEmployeeResponse = (state:LoginState) => state.loginEmployeeResponse;
export const getisloginEmployeeLoading = (state:LoginState) => state.isloginEmployeeLoading;
export const getisloginEmployeeLoaded = (state:LoginState) => state.isloginEmployeeLoaded;
export const getloginEmployeeStatusCode = (state:LoginState) => state.loginEmployeeStatusCode;
export const getloginEmployeeValidate = (state:LoginState) => state.loginEmployeeValidate;
export const getloginEmployeeErrorMessage = (state:LoginState) => state.loginEmployeeErrorMessage;

export const getUpdateProfile = (state: LoginState) => state.updateUserProfile;
export const getUpdateProfileLoading = (state: LoginState) =>
  state.isUpdateProfileLoading;
export const getUpdateProfileLoaded = (state: LoginState) =>
  state.isUpdateProfileLoaded;
export const getUpdateProfileStatusCode = (state: LoginState) =>
  state.updateStatusCode;
export const getUpdateProfileErrorMessage = (state: LoginState) =>
  state.passwordErrorMessage;

export const registerUserResponse = (state: LoginState) =>
  state.registerUserResponse;
export const registerStatusCode = (state: LoginState) =>
  state.registerStatusCode;
export const registerUserLoading = (state: LoginState) =>
  state.registerUserLoading;
export const registerUserLoaded = (state: LoginState) =>
  state.registerUserLoaded;
export const registerUserErrorMessage = (state: LoginState) =>
  state.registerUserErrorMessage;

export const validateRefCodeResponse = (state: LoginState) =>
  state.validateRefCodeResponse;
export const validateReferralCodeErrorMessage = (state: LoginState) =>
  state.validateReferralCodeErrorMessage;
export const validateRefCodeResult = (state: LoginState) =>
  state.validateRefCodeResult;
export const validateReferralCodeStatusCode = (state: LoginState) =>
  state.validateReferralCodeStatusCode;

export const getUserDetails = (state: LoginState) => state.UserDetailsList;
export const getUserDetailsLoading = (state: LoginState) =>
  state.isUserDetailsLoading;
export const getUserDetailsLoaded = (state: LoginState) =>
  state.isUserDetailsLoaded;
export const getUserDetailsErrorMessage = (state: LoginState) =>
  state.errorMessage;
export const getIsUserProfileLoading = (state: LoginState) =>
  state.isUserProfileLoading;

export const getToken = (state: LoginState) => state.token;

export const getIsMostUsedServicesLoaded = (state: LoginState) =>
  state.isMostUsedServicesLoaded;
export const getIsMostUsedServicesLoading = (state: LoginState) =>
  state.isMostUsedServicesLoading;
export const getMostUsedServicesResult = (state: LoginState) =>
  state.mostUsedServicesResult;

export const updateLanguage = (state: LoginState) => state.updateLanguage;
export const updateLanguageStatusCode = (state: LoginState) =>
  state.updateLanguageStatusCode;

export const getUpdatePasswardResponse = (state: LoginState) =>
  state.getUpdatePasswardResponse;
export const getIsUpdatePasswardLoading = (state: LoginState) =>
  state.isUpdatePasswardLoading;
export const getIsUpdatePasswardLoaded = (state: LoginState) =>
  state.isUpdatePasswardLoaded;
export const updatePasswordStatusCode = (state: LoginState) =>
  state.updatePasswordStatusCode;
export const updatePasswordErrorMessage = (state: LoginState) =>
  state.updatePasswordErrorMessage;
export const updatePasswordValidate = (state: LoginState) =>
  state.updatePasswordValidate;
export const updatePasswordResponse = (state: LoginState) => 
  state.updatePasswordResponse;

export const updateProfilePhotoResponse = (state: LoginState) =>
  state.updateProfilePhotoResponse;
export const updateProfilePhotoStatusCode = (state: LoginState) =>
  state.updateProfilePhotoStatusCode;
export const updateProfilePhotoResponseCertificate = (state: LoginState) =>
  state.updateProfilePhotoResponseCertificate;
export const updateProfilePhotoResponseProperty = (state: LoginState) =>
  state.updateProfilePhotoResponseProperty;
export const isUpdateProfilePhotoLoading = (state: LoginState) =>
  state.isUpdateProfilePhotoLoading;
export const isUpdateProfilePhotoLoaded = (state: LoginState) =>
  state.isUpdateProfilePhotoLoaded;
export const isUpdateProfilePhotoPropertyLoaded = (state: LoginState) =>
  state.isUpdateProfilePhotoPropertyLoaded;
export const isUpdateProfilePhotoPropertyLoading = (state: LoginState) =>
  state.isUpdateProfilePhotoPropertyLoading;
export const updateProfilePhotoResponseGst = (state: LoginState) =>
  state.updateProfilePhotoResponseGst;
export const updateProfilePhotoResponsePan = (state: LoginState) =>
  state.updateProfilePhotoResponsePan;
export const updateProfilePhotoResponseShopAct = (state: LoginState) =>
  state.updateProfilePhotoResponseShopAct;
export const updateProfilePhotoResponseFss = (state: LoginState) =>
  state.updateProfilePhotoResponseFss;
export const isUpdateProfilePhotoGstLoaded = (state: LoginState) =>
  state.isUpdateProfilePhotoGstLoaded;
export const isUpdateProfilePhotoGstLoading = (state: LoginState) =>
  state.isUpdateProfilePhotoGstLoading;
export const isUpdateProfilePhotoPanLoaded = (state: LoginState) =>
  state.isUpdateProfilePhotoPanLoaded;
export const isUpdateProfilePhotoPanLoading = (state: LoginState) =>
  state.isUpdateProfilePhotoPanLoading;
export const isUpdateProfilePhotoShopActLoaded = (state: LoginState) =>
  state.isUpdateProfilePhotoShopActLoaded;
export const isUpdateProfilePhotoShopActLoading = (state: LoginState) =>
  state.isUpdateProfilePhotoShopActLoading;
export const isUpdateProfilePhotoFssLoaded = (state: LoginState) =>
  state.isUpdateProfilePhotoFssLoaded;
export const isUpdateProfilePhotoFssLoading = (state: LoginState) =>
  state.isUpdateProfilePhotoFssLoading;
export const updateProfilePhotoResponseUdyogAadhar = (state: LoginState) =>
  state.updateProfilePhotoResponseUdyogAadhar;
export const isUpdateProfilePhotoUdyogAadharLoaded = (state: LoginState) =>
  state.isUpdateProfilePhotoUdyogAadharLoaded;
export const isUpdateProfilePhotoUdyogAadharLoading = (state: LoginState) =>
  state.isUpdateProfilePhotoUdyogAadharLoading;

export const favImageResponse = (state: LoginState) => state.favImageResponse;
export const favImageResponseStatusCode = (state: LoginState) =>
  state.favImageResponseStatusCode;
export const favImageResponseLoading = (state: LoginState) =>
  state.favImageResponseLoading;
export const favImageResponseLoaded = (state: LoginState) =>
  state.favImageResponseLoaded;

export const changeMobileNumberResponse = (state: LoginState) =>
  state.changeMobileNumberResponse;
export const changeMobileNumberStatusCode = (state: LoginState) =>
  state.changeMobileNumberStatusCode;
export const changeMobileNumbrLoading = (state: LoginState) =>
  state.changeMobileNumbrLoading;
export const changeMobileNumbrLoaded = (state: LoginState) =>
  state.changeMobileNumbrLoaded;
export const changeMobileNumberErrorMessage = (state: LoginState) =>
  state.changeMobileNumberErrorMessage;

export const verifyMobileNumberResponse = (state: LoginState) =>
  state.verifyMobileNumberResponse;
export const verifyMobileNumberStatusCode = (state: LoginState) =>
  state.verifyMobileNumberStatusCode;
export const verifyMobileNumbrLoading = (state: LoginState) =>
  state.verifyMobileNumbrLoading;
export const verifyMobileNumbrLoaded = (state: LoginState) =>
  state.verifyMobileNumbrLoaded;
export const verifyMobileNumberErrorMessage = (state: LoginState) =>
  state.verifyMobileNumberErrorMessage;

export const getOtpChoicesResponse = (state: LoginState) =>
  state.getOtpChoicesResponse;
export const getOtpChoicesStatusCode = (state: LoginState) =>
  state.getOtpChoicesStatusCode;
export const getOtpChoicesLoading = (state: LoginState) =>
  state.getOtpChoicesLoading;
export const getOtpChoicesLoaded = (state: LoginState) =>
  state.getOtpChoicesLoaded;
export const getOtpChoicesErrorMessage = (state: LoginState) =>
  state.getOtpChoicesErrorMessage;

export const termsAndConditions = (state: LoginState) =>
  state.termsAndConditions;
export const termsAndConditionsStatusCode = (state: LoginState) =>
  state.termsAndConditionsStatusCode;
export const isTermsAndConditionsLoading = (state: LoginState) =>
  state.isTermsAndConditionsLoading;
export const isTermsAndConditionsLoaded = (state: LoginState) =>
  state.isTermsAndConditionsLoaded;
export const termsAndConditionsErrorMessage = (state: LoginState) =>
  state.termsAndConditionsErrorMessage;

export const merchantTermsAndConditions = (state: LoginState) =>
  state.merchantTermsAndConditions;
export const merchantTermsAndConditionsStatusCode = (state: LoginState) =>
  state.merchantTermsAndConditionsStatusCode;
export const merchantTermsAndConditionsLoading = (state: LoginState) =>
  state.merchantTermsAndConditionsLoading;
export const merchantTermsAndConditionsLoaded = (state: LoginState) =>
  state.merchantTermsAndConditionsLoaded;
export const merchantTermsAndConditionsErrorMessage = (state: LoginState) =>
  state.merchantTermsAndConditionsErrorMessage;

export const getUserStateServerErrorMessage = (state: LoginState) =>
  state.userStateServerErrorMessage;

export const getInterestedToBeMerchantErrorMessage = (state: LoginState) =>
  state.interestedToBeMerchantErrorMessage;
export const getIsInterestedToBeMerchantLoading = (state: LoginState) =>
  state.isInterestedToBeMerchantLoading;
export const getIsInterestedToBeMerchantLoaded = (state: LoginState) =>
  state.isInterestedToBeMerchantLoaded;
export const getInterestedToBeMerchantStatusCode = (state: LoginState) =>
  state.interestedToBeMerchantStatusCode;
export const getInterestedToBeMerchantResponse = (state: LoginState) =>
  state.interestedToBeMerchantResponse;

export const getInterestedToBeBrandErrorMessage = (state: LoginState) =>
  state.interestedToBeBrandErrorMessage;
export const getIsInterestedToBeBrandLoading = (state: LoginState) =>
  state.isInterestedToBeBrandLoading;
export const getIsInterestedToBeBrandLoaded = (state: LoginState) =>
  state.isInterestedToBeBrandLoaded;
export const getInterestedToBeBrandStatusCode = (state: LoginState) =>
  state.interestedToBeBrandStatusCode;
export const getInterestedToBeBrandResponse = (state: LoginState) =>
  state.interestedToBeBrandResponse;

export const getRegisterAsMerchantErrorMessage = (state: LoginState) =>
  state.registerAsMerchantErrorMessage;
export const getIsRegisterAsMerchantLoading = (state: LoginState) =>
  state.isRegisterAsMerchantLoading;
export const getIsRegisterAsMerchantLoaded = (state: LoginState) =>
  state.isRegisterAsMerchantLoaded;
export const getRegisterAsMerchantStatusCode = (state: LoginState) =>
  state.registerAsMerchantStatusCode;

export const getRegisterAsBrandErrorMessage = (state: LoginState) =>
  state.registerAsBrandErrorMessage;
export const getIsRegisterAsBrandLoading = (state: LoginState) =>
  state.isRegisterAsBrandLoading;
export const getIsRegisterAsBrandLoaded = (state: LoginState) =>
  state.isRegisterAsBrandLoaded;
export const getRegisterAsBrandStatusCode = (state: LoginState) =>
  state.registerAsBrandStatusCode;

export const getAllBrandErrorMessage = (state: LoginState) =>
  state.getAllBrandErrorMessage;
export const getIsGetAllBrandLoading = (state: LoginState) =>
  state.isGetAllBrandLoading;
export const getIsGetAllBrandLoaded = (state: LoginState) =>
  state.isGetAllBrandLoaded;
export const getAllBrandStatusCode = (state: LoginState) =>
  state.getAllBrandStatusCode;
export const getAllBrandResponse = (state: LoginState) =>
  state.getAllBrandResponse;

export const getNewsletterSubscriptionStatusCode = (state: LoginState) =>
  state.getNewsletterSubscriptionStatusCode;
export const getNewsletterSubscriptionErrorMessage = (state: LoginState) =>
  state.getNewsletterSubscriptionErrorMessage;
export const isNewsletterSubscriptionLoading = (state: LoginState) =>
  state.isNewsletterSubscriptionLoading;
export const isNewsletterSubscriptionLoaded = (state: LoginState) =>
  state.isNewsletterSubscriptionLoaded;
export const uploadContentType = (state: LoginState) => state.uploadContentType;
export const gotoPageOnLogin = (state: LoginState) => state.gotoPageOnLogin;
export const gotoService = (state:LoginState) => state.gotoService;
export const getFCMToken = (state:LoginState) => state.fcmToken;


