import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'app-chatbot',
    templateUrl: './chatbot.component.html',
    styleUrls: ['./chatbot.component.scss'],
    standalone: true,
    imports: [MatIcon]
})
export class ChatbotComponent implements OnInit {
  deviceWidth = window.innerWidth;
  deviceHeight = window.innerHeight;
  token: string;
  userId: string;
  isLoaded: boolean;
  staticImages: {};
  apiDetails: {};
  lng: number;
  lat: number;
  initlat = 18.62861241;
  initlng = 73.80478858;
  selectedLanguage: string;
  isChatbotLoaded: boolean;
  chatbotResult: {};
  chatbotWebLink: any;

  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store<fromStore.CEPState>,
    private localStorageService: LocalStorageService,
    private encrDecr: EncrDecrService,
    public sanitizer: DomSanitizer
  ) { }

  /**
   * Created by Akanksha J
   * For getting user current location(show popup)
   */
  getUserLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;
      },
        error => {
          this.lat = this.initlat;
          this.lng = this.initlng;
        });
    }
  }

  ngOnInit() {
    this.getUserLocation();

    this.store.select(fromStore.getToken)
      .subscribe((token) => {
        this.token = token;
        if (this.token) {
          let localUserId = this.localStorageService.getUserId();
          this.userId = this.encrDecr.get(localUserId);
        }
      });

    this.store.select(fromStore.getApiCallingList)
      .subscribe((apiDetails) => {
        this.apiDetails = apiDetails;
      });

    // For API calling of chatbot
    let requestBody = {};
    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
        if (this.selectedLanguage) {
          if (this.userId) {
            requestBody = {
              "userId": this.userId,
              "latitude": this.lat,
              "longitude": this.lng,
              "lang": this.selectedLanguage
            }

          } else {
            requestBody = {
              "latitude": this.lat,
              "longitude": this.lng,
              "lang": this.selectedLanguage
            }
          }

          this.store.dispatch(new fromStore.GetChatbotKey({
            url: this.apiDetails['user'].base_url +
              this.apiDetails['user'].floatBoatEncryKey.url,
            method: this.apiDetails['user'].floatBoatEncryKey.method,
            body: requestBody
          }));
        }
      });

    // For getting chatbot link as a response
    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
        if (this.selectedLanguage == 'mar') {
          this.store.select(fromStore.getChatbotKeyLoaded)
            .subscribe(isLoaded => {
              this.isChatbotLoaded = isLoaded;
              if (this.isChatbotLoaded) {
                this.store.select(fromStore.getChatbotKeyResult)
                  .subscribe((chatbotResult) => {
                    this.chatbotResult = chatbotResult;
                    if (Object.keys(this.apiDetails).length > 0) {
                      if (this.chatbotResult && this.chatbotResult['floatBoatCryptedKeyForWeb']) {
                        //this.chatbotWebLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.apiDetails['info']['staticURL']['floatboatURLForWebsite'] + this.chatbotResult['floatBoatCryptedKeyForWeb'] + "&language=marathi");
                        this.chatbotWebLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://floatbot.ai/hostedbot/?botId=5d9d8e4ee6293674bd56ea33&param=' + this.chatbotResult['floatBoatCryptedKeyForWeb'] + "&language=marathi");
                      }
                    }
                  });
              }
            });
        }
        else {
          this.store.select(fromStore.getChatbotKeyLoaded)
            .subscribe(isLoaded => {
              this.isChatbotLoaded = isLoaded;
              if (this.isChatbotLoaded) {
                this.store.select(fromStore.getChatbotKeyResult)
                  .subscribe((chatbotResult) => {
                    this.chatbotResult = chatbotResult;
                    if (Object.keys(this.apiDetails).length > 0) {
                      if (this.chatbotResult && this.chatbotResult['floatBoatCryptedKeyForWeb']) {
                       // this.chatbotWebLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.apiDetails['info']['staticURL']['floatboatURLForWebsite'] + this.chatbotResult['floatBoatCryptedKeyForWeb'] + "&language=english");
                        this.chatbotWebLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://floatbot.ai/hostedbot/?botId=5d9d8e4ee6293674bd56ea33&param=' + this.chatbotResult['floatBoatCryptedKeyForWeb'] + "&language=english");
                      }
                    }
                  });
              }
            });

        }
      });

  }

  /**
   * Craeted by Ajay C
   * For closing chatbot popup
   */
  closeChatbot() {
    this.dialog.closeAll();
  }
}
