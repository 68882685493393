<div class="container">
  <div class="text">
    <div class="title" *ngIf=" selectedLanguage === 'en' ">
      Hello
    </div>
    <div class="sub-title" *ngIf=" selectedLanguage === 'en' ">
      Welcome and thank you for visiting the portal.
    </div>
    <div class="sub-title" *ngIf=" selectedLanguage === 'en' ">
      Please register and proceed to become a member of our community right away.
    </div>
    <div class="title" *ngIf=" selectedLanguage === 'mar' ">
      नमस्कार !
    </div>
    <div class="sub-title" *ngIf=" selectedLanguage === 'mar' ">
      PMC CARE पोर्टलमध्ये आपले स्वागत... 
      पोर्टलला भेट दिल्याबद्दल धन्यवाद !
    </div>
    <div class="sub-title" *ngIf=" selectedLanguage === 'mar' ">
      कृपया नोंदणी करा आणि लगेच आमच्या समुदायाचे सदस्य व्हा!
    </div>
    <div class="text-img">
      <img src="{{images.OCR_Image}}" class="img" loading="lazy" alt="..." />
    </div>
  </div>
  <div class="content">
    <div class="header">
      <div class="logo-container">
        <img src="{{images.finalLogin}}" class="logo" loading="lazy" alt="..." />
      </div>
      <div class="logo-container">
        <img src="{{images.pmc_logo}}" class="pmc-logo" loading="lazy" alt="..." />
      </div>
      <div class="logo-container">
        <button class="language-button" (click)="onChangeLangButton()">
          {{ showSelectedLanguage }}
        </button>
      </div>
    </div>
    <div class="otp-container">
      <h1 style="text-align: center;">
        {{ otpLabels[selectedLanguage].verifyMobileNumber }}
      </h1>
      <div class="otp">
        <div class="input-form">
          <label style="margin: 10px;font-size: large;font-weight: 600;">
            <span *ngIf=" selectedLanguage === 'en' ">Mobile Number:</span>
            <span *ngIf=" selectedLanguage === 'mar'">मोबाईल नंबर:</span>
          </label><br/>
          <input style="width:95%;font-size: 24px;margin: 10px;border-radius: 8px;" [disabled]="sendOTPDOne" matInput [(ngModel)]="mobileNumber" (keypress)="numberOnly($event)" maxlength="10" />
          <button mat-raised-button style="width: 95%;" [disabled]="mobileNumber.length !== 10 " *ngIf="!sendOTPDOne" color="primary" class="button"
            (click)="sendOTP()">
            {{ mobileScreenLabels[selectedLanguage].sendOTP | uppercase }}
          </button>
        </div>
        <div class="input-form">
          <label style="font-size: large;font-weight: 600;">
            <span *ngIf=" selectedLanguage === 'en' ">OTP:</span>
            <span *ngIf=" selectedLanguage === 'mar'">ओटीपी:</span>
          </label><br/>
        </div>
        <div class="input-form">
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"
            style="width: 120px; height: 60px; font-size: 24px;" ></ng-otp-input>
        </div>
        <div class="button-container" style="margin-top: 2%">
          <button mat-raised-button [disabled]="mobileNumber.length !== 10 && otpLength === '' || otpLength != 4" color="primary" class="button"
            (click)="submitOTP()">
            {{ otpLabels[selectedLanguage].verify | uppercase }}
          </button>
          <button mat-stroked-button disabled class="button-reverse" [disabled]="ishow" (click)="sendOTP()">
            {{ otpLabels[selectedLanguage].resendOTP | uppercase }}
            <span *ngIf="ishow" style="color: gray">{{ display }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>