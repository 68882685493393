import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as DepartmentsAction from '../action/departments.action';
import { DepartmentsService } from '../api-services/departments.service';
import { of } from 'rxjs';

@Injectable()

export class DepartmentsEffects {
	constructor(
		private actions$: Actions,
		private DepartmentsService: DepartmentsService
	) {
	}

	apiDepartmentTypeDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<DepartmentsAction.GetDepartmentTypeDetails>(DepartmentsAction.GET_DEPARTMENT_TYPE_DETAILS),
			switchMap((action) => {
				return this.DepartmentsService.getDepartmentTypes(action.payload).pipe(
					map(departmentData => new DepartmentsAction.GetDepartmentTypeDetailsSuccess(departmentData)),
					catchError(error => of(new DepartmentsAction.GetDepartmentTypeDetailsFail(error)))
				);
			})
		)
	);

	apiDepartmentSubTypeDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<DepartmentsAction.GetDepartmentSubTypeDetails>(DepartmentsAction.GET_DEPARTMENT_SUBTYPE_DETAILS),
			switchMap((action) => {
				return this.DepartmentsService.getDepartmentSubTypes(action.payload).pipe(
					map(departmentData => new DepartmentsAction.GetDepartmentSubTypeDetailsSuccess(departmentData)),
					catchError(error => of(new DepartmentsAction.GetDepartmentSubTypeDetailsFail(error)))
				);
			})
		)
	);

	apiDepartmentFAQDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<DepartmentsAction.GetDepartmentFAQDetails>(DepartmentsAction.GET_DEPARTMENT_FAQ_DETAILS),
			switchMap((action) => {
				return this.DepartmentsService.getDepartmentFAQ(action.payload).pipe(
					map(departmentData => new DepartmentsAction.GetDepartmentFAQDetailsSuccess(departmentData)),
					catchError(error => of(new DepartmentsAction.GetDepartmentFAQDetailsFail(error)))
				);
			})
		)
	);


}
