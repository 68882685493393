<!-- /*******************************
 * Component -  Popup for visualy impaired people with audio
 * Created By - Vinita Sharma
 * Updated On - 23/10/2020
 **************************************/ -->
<div class="popup">
        <div class="titleForBlindPeople">Are you visually impaired?</div>
        <div class="MessageForBlindUser">
           {{textSpeak}}
        </div>

        <div class="button-container">
            <button mat-raised-button class="cancel-button" (click)="closeDialog()">
                cancel
            </button>
        </div>
</div>
