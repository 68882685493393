/******
 * Component -  feeds type list
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */
import { Component, OnInit } from '@angular/core';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { EncrDecrService } from '../../services/encr-decr.service';
import { LocalStorageService } from '../../services/localstorage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Images } from '../../config/images-config';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelDescription } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { NgIf, NgFor } from '@angular/common';
// import { exists } from 'fs';
@Component({
    selector: 'app-feeds-list',
    templateUrl: './feeds-list.component.html',
    styleUrls: ['./feeds-list.component.scss'],
    standalone: true,
    imports: [NgIf, MatMenuTrigger, MatMenu, NgFor, MatMenuItem, MatCheckbox, FormsModule, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelDescription]
})
export class FeedsListComponent implements OnInit {
	images = Images;
	selectedLanguage;
	landingLables;
	feedlist;
	feed;
	isMobile: any;
	isLoaded = false;
	apiDetails: any;
	count: any;
	filterData = [];
	sortByListingArray = [];
	backgroundColor: any;
	userId: any;
	token: string;
	isFilterSelected = true;
	sortBylisting: {};
	sortByValue: any;
	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particulat component
	 */
	constructor(
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private deviceService: DeviceDetectorService,
		private encrDecr: EncrDecrService, ) {
		this.isMobile = this.deviceService.isMobile();
	}
	ngOnInit() {
		this.store.select(fromStore.getApiCallingList)
			.subscribe(apiDetails => {
				this.apiDetails = apiDetails;
			});
		this.store.select(fromStore.getBackgroundColor)
			.subscribe((background) => {
				this.backgroundColor = background['bgColor'];
			});
		this.store.select(fromStore.getLandingScreenLabels)
			.subscribe(labels => {
				this.feed = labels;
			});
		this.store.select(fromStore.getSelectedLanguage).subscribe(language => {
			this.selectedLanguage = language;
			this.store.select(fromStore.getisFiltterAppled).subscribe((isFillterApplied) => {
				this.isFilterSelected = isFillterApplied;
				if (this.isFilterSelected) {
					this.store.select(fromStore.getIsLabelLoaded).subscribe(isLoaded => {
						this.isLoaded = isLoaded;
						if (this.isLoaded) {
							this.store.select(fromStore.getfeedsFilterLabel).subscribe(labels => {
								this.feedlist = labels[this.selectedLanguage];
								this.filterData = [];
								Object.keys(this.feedlist).forEach(item => {
									let eachItem = {};
									eachItem['checked'] = true;
									eachItem['key'] = item;
									eachItem['value'] = this.feedlist[item];
									this.filterData.push(eachItem);
								});
							});
						}
					});
				}
			});

			if (this.selectedLanguage) {
				this.store.select(fromStore.getSortByListing)
				.subscribe(response => {
					this.sortBylisting = response[this.selectedLanguage];
					this.sortByListingArray = [];
					Object.entries(this.sortBylisting).forEach((item, index) => {
						this.sortByListingArray.push(item);
					});
				})
			}
		});
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});
	}

	/**
	 * Created by akanksha j
	 * For sorting the engagement list
	 * Created Date: 24/03/2020
	 */
	sortByFilter(value) {
		this.sortByValue = value;
	}
	/**
	 * created by Ajay C
	 * For detecting the changed value of feeds filter
	 */
	changeFilter() {
		let filterApplied = [];
		this.filterData.forEach(element => {
			if (element.checked) {
				filterApplied.push(element.key);
			}
		});
		this.callTofilter(filterApplied);
	}
	changeFilterAll() {
		let filterApplied = [];
		this.filterData.forEach((element, i) => {
			if (i === 0 && element.checked) {
				this.filterData.forEach(element => {
					element.checked = true;
					filterApplied.push(element.key);
				});
			} else if (i === 0 && !element.checked) {
				this.filterData.forEach((element, i) => {
					if (i > 0) {
						element.checked = false;
					}
				});
			}
		});
		this.callTofilter(filterApplied);
	}
	/**
		* created by Akshata c
		* For calling feeds api
		*/
	callTofilter(filterApplied) {
		this.store.dispatch(
			new fromStore.SetPaginationCount({
				feedsPaginationCount: 0
			})
		);
		if (Object.keys(this.apiDetails).length > 0) {
			this.store.dispatch(
				new fromStore.GetFeedsListing({
					url:
						this.apiDetails['userEngagement'].base_url +
						this.apiDetails['userEngagement'].timeline.getTimeline.url,
					method: this.apiDetails['userEngagement'].timeline.getTimeline.method,
					body: {
						userId: this.userId,
						prevCnt: '0',
						engagementType: filterApplied,
						sortBy: {
							key: this.sortByValue,
							order: 'desc'
						}
					}
				})
			);
		}
	}
}
