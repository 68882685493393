<!-- *******************************************************************
   Component -  Thank You screen
   Created By - Neha R
   Created On - 20/04/2020
   Updated By - Neha R
   Updated On - 20/04/2020
*****************************************************************  -->

<div class='overlap-container'>
    <!-- connection lost condition -->
    <div *ngIf='serverMessage' class='connectionDiv'>
        <img src={{images.connectionLost}} loading="lazy" alt="..." class='connectionLostImg'>
        <span>{{serverMessage}}</span> 
    </div>
    <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>
    <div *ngIf='!isLoading' class='main-container'>
        <div class="container">
            <mat-card class="card">
                <div class="image-thanks" *ngIf="selectedLanguage == 'en'">
                    <img class="image" src="{{images.thanksForShoppingen}}" alt="Thank-you-for-shopping-en" loading="lazy" >
                </div>
                <div class="image-thanks" *ngIf="selectedLanguage == 'mar'">
                    <img class="image" src="{{images.thanksForShoppingmar}}" alt="Thank-you-for-shopping-mar" loading="lazy" >
                </div>
                <mat-button class="cart" (click)="redirectToFeed()">{{labels[selectedLanguage].forQrCodeGoToMyDeals}}</mat-button>
            </mat-card>
        </div>
    </div>
</div>
<!-- <html>

<head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Mukta">
    <script>
        const userAction = async () => {
            var selectedLanguage = await getUrlVars();
            if ( selectedLanguage === 'en' ) {
                document.getElementById( "title" ).innerHTML = "Thank you for applying deals !!";
            }
            else if ( selectedLanguage === 'mar' ) {
                document.getElementById( "title" ).innerHTML = "हे सर्वेक्षण भरल्याबद्दल धन्यवाद !!";
            }
        }
        function getUrlVars() {
            var parts = window.location.search;
            if ( !parts ) {
                return 'mar';
            }
            var data = parts.split( '=' );
            return data[ 1 ];
        }

    </script>
    <script>
        userAction();
    </script>
</head>

<body>
    <div class="container">
        <mat-card class="card">
            <div class="heading" id="title"></div>
        </mat-card>
    </div>
</body>

</html> -->

<!-- <style>
    body {
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: #f3f3f3;
        font-family: 'Roboto';

    }

    .container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .heading {
        display: flex;
        justify-content: center;
        align-self: center;
        font-size: 24px;
        font-weight: 700;
        height: 60px;
        align-items: center;
    }

    .spinner {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 55%;
        min-height: 260px;
        border-radius: 20px;
        position: relative;
        background: white;
        top: 30px;
        margin-bottom: 30px;
    }

    .header {
        font-size: 24px;
        font-weight: bolder;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .sub-header {
        font-size: 14px;
        padding-left: 10px;
        padding-bottom: 5px;
    }

    .main-points {
        padding-left: 10px;
        padding-right: 20px;
        text-align: justify;
    }

    .topic {
        font-weight: bold;
    }

    .content {
        text-align: justify;
        opacity: 0.9;
        padding: 0 20px 20px 20px;
        font-size: 14px;
        color: rgba(0, 0, 0, .87);
    }

    @media (max-width: 1024px) and (min-width: 600px) {
        .card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 90%;
            border-radius: 20px;
            position: relative;
            top: 85px;
            margin-bottom: 85px;
        }
    }

    @media (max-width: 599px) and (min-width: 100px) {
        .card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 90%;
            border-radius: 20px;
            position: relative;
            top: 65px;
            margin-bottom: 18px; 
        }

        .header {
            font-size: 24px;
        }
    }
</style> -->