<!-- *******************************************************************
*  Component -  Dialog of Merchant confirmation 
   Created By - Akanksha Jadhav
   Created On - 20/01/2020
   Updated By - Akanksha Jadhav
   Updated On - 31/01/2020
*****************************************************************  -->
<!-- merchant confirmation popup-->
<div class="popup">
    <!-- dialog spinner -->
    <div *ngIf="isMerchantLoading || isBrandLoading">
        <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
        </mat-progress-spinner>
    </div>
    <!-- main page -->
    <div class="dialogbox" *ngIf="!isMerchantLoading && !isBrandLoading">
        <div class="header">
            <span class="heading"
                (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].confirmationForRegistrationOfMerchant)"
                (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].confirmationForRegistrationOfMerchant}}</span>
            <mat-icon class="cross-button font-sub-heading" aria-hidden="false" (click)="closeDialog()"
                (mouseenter)="speakText('close')" (mouseleave)="stopSpeaking()">clear</mat-icon>
        </div>
        <div class="radio-button">
            <!-- radio buttons of OTP choices -->
            <mat-radio-group name="option" [formControl]="option" [(ngModel)]="chooseOption">
                <!-- <div class="radio-group" (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].registerAsBrand)"  (mouseleave)="stopSpeaking()">
                    <span class="text">{{merchantAndBrandLabels[selectedLanguage].registerAsBrand}}</span>
                    <mat-radio-button class="radio" value="brand-registration"></mat-radio-button>
                </div> -->
                <div class="radio-group"
                    (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].registerAsMerchant)"
                    (mouseleave)="stopSpeaking()">
                    <span class="text">{{ merchantAndBrandLabels[selectedLanguage].registerAsMerchant }}</span>
                    <mat-radio-button class="radio" value="merchant-registration"></mat-radio-button>
                </div>
            </mat-radio-group>
            <div class="button">
                <button mat-flat-button class="cancle-button" (click)="closeDialog()"
                    (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].no)"
                    (mouseleave)="stopSpeaking()">{{merchantAndBrandLabels[selectedLanguage].no}}</button>
                <button mat-raised-button class="ok-button" (click)="redirectToMerchantForm()"
                    (mouseenter)="speakText(merchantAndBrandLabels[selectedLanguage].yes)" (mouseleave)="stopSpeaking()"
                    [disabled]="!chooseOption">{{ merchantAndBrandLabels[selectedLanguage].yes }}</button>

            </div>
        </div>
    </div>
</div>