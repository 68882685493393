import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromStore from '../../store/index';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { ToastrService } from 'ngx-toastr';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../shared/services/localstorage.service';
import { MatCard } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-commissioner-information',
    templateUrl: './commissioner-information.component.html',
    styleUrls: ['./commissioner-information.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatCard, NgFor]
})
export class CommissionerInformationComponent implements OnInit {
  images = Images;
  selectedLanguage: string;
  apiDetails: {};
  commissionerData: {};
  isLoaded = false;
  isLoading: boolean;
  color = 'primary';
  mode = "indeterminate";
  value = 50;
  serverErrorMessage: string;
  aboutUsLabels: any;
  mayorData: any;
  committeeData: any;
  isBlind;
    /**
     *
     * angular method for declaring packages in variables
     *
     * which runs first in this particular component
    */
  constructor(
    private router: Router,
    private store: Store<fromStore.CEPState>,
    private googleAnalyticsService: GoogleAnalyticsService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,

  ) {
    this.store.select(fromStore.getSelectedLanguage)
      .subscribe((language) => {
        this.selectedLanguage = language;
      });

    this.store.select(fromStore.getApiCallingList)
      .subscribe((apiDetails) => {
        this.apiDetails = apiDetails;
      });

    // API call for commissioner info details
    this.store.dispatch(new fromStore.GetCommissionerDetails({
      url: this.apiDetails['applicationConfigurations'].base_url +
        this.apiDetails['applicationConfigurations']['aboutUs'].getMuncipalCommittee.url,
      method: this.apiDetails['applicationConfigurations']['aboutUs'].getMuncipalCommittee.method
    }));
  }

  /**
   * angular method which gets called on page initialization
   */
  ngOnInit() {
    this.store.select(fromStore.getAboutUsLabels)
      .subscribe(response => {
        this.aboutUsLabels = response;
      });

    this.store.select(fromStore.getIsCommissionerDetailsLoading)
      .subscribe(loading => {
        this.isLoading = loading;
      });
    // server error handling
    this.store.select(fromStore.getCommissionerDetailsServerErrorMessage)
      .subscribe((serverError) => {
        if (serverError != null) {
          if (serverError == 'InvalidCredentials') {
            this.router.navigate(['/Login/login']);
          }
          else {
            this.serverErrorMessage = serverError;
            if (this.serverErrorMessage) {
              console.error(this.serverErrorMessage);
            }
          }
          // this.serverErrorMessage = null;
        }
      });


    // rsponse of commissioner info details API
    this.store.select(fromStore.getIsCommissionerDetailsLoaded)
      .subscribe(loaded => {
        this.isLoaded = loaded;
        if (this.isLoaded) {
          this.store.select(fromStore.getCommissionerDetailsResult)
            .subscribe((res) => {
              this.commissionerData = res['commissionerInfo'];
              this.mayorData = res['mayorInfo'];
              this.committeeData = res['committeeInfo'];
            });
        }
      });
  }

/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }


  ngOnDestroy() {
    this.serverErrorMessage = null;
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
}
