<div>
  <mat-form-field appearance="outline" class="mat-form">
    <input matInput [(ngModel)]="inputText" (input)="search(inputText)" placeholder="{{placeholder}}">
  </mat-form-field>
  <div class="options-container" *ngIf="options.length > 0">
    <label class="options" *ngFor="let item of options" (click)="onSelectionChange(item)">
      {{ item.description }}
    </label>
  </div>
  <div class="options-container" *ngIf="showError">
    <label class="options" >
      No data found. Please Select nearby Landmark
    </label>
  </div>
</div>