import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  AfterContentChecked,
} from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import * as fromStore from "../../store/index";
import { ToastrService } from "ngx-toastr";
// import { TouchSequence } from "selenium-webdriver";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "src/app/shared/services/google-analytics.service";
import { LocalStorageService } from "src/app/shared/services/localstorage.service";
import { EncrDecrUtil } from "src/app/util/EncrDecr";
import { environment } from "src/environments/environment";
import {
  screenName,
  clickedOn,
} from "src/app/shared/config/google-analytics-constants";
import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { LoadshCompareService } from "src/app/shared/services/loadsh-compare.service";
import { MatButton } from "@angular/material/button";

@Component({
    selector: "app-change-passward-popup",
    templateUrl: "./change-passward-popup.component.html",
    styleUrls: ["./change-passward-popup.component.scss"],
    standalone: true,
    imports: [MatButton],
})
export class changePasswordPopupComponent implements OnInit, OnDestroy {
  apiDetails: {};
  updatePass: string;
  updatelabels: any;
  selectedLanguage: string;
  serverErrorMessage: string;
  isLoaded = false;
  updatePasswordStatusCode: any;
  updatePasswordResponse: any;
  errMessage = null;
  errorMessage = null;

  /**
   *
   * angular method for declaring packages in variables
   *
   * which runs first in this particular component
   */
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private store: Store<fromStore.CEPState>,
    private toastr: ToastrService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private localStorageService: LocalStorageService,
    private loadashCompare: LoadshCompareService
  ) {}

  /**
   * angular method which gets called on page initialization
   */
  ngOnInit() {
    // server error handling
    this.store
      .select(fromStore.getUserStateServerErrorMessage)
      .subscribe((serverError) => {
        if (serverError != null) {
          if (serverError == "InvalidCredentials") {
            // this.router.navigate(['/Login/login']);
          } else {
            this.serverErrorMessage = serverError;
            if (this.serverErrorMessage) {
              console.error(this.serverErrorMessage);
            }
          }
          this.serverErrorMessage = null;
        }
      });

    this.store.select(fromStore.getprofileScreenLabels).subscribe((labels) => {
      this.updatelabels = labels;
    });

    this.store.select(fromStore.getSelectedLanguage).subscribe((language) => {
      this.selectedLanguage = language;
    });

    this.store.select(fromStore.getApiCallingList).subscribe((apiDetails) => {
      this.apiDetails = apiDetails;
    });
  }

  /**
   * Created by Prajkta Patil
   * For Changing Passward when click on yes button
   */
  // changePassward(data) {
  //   console.log("Change password method is being called.");
  //   this.dialog.closeAll();
  //   console.log("After closing dialog");
  //   let reqBody = {
  //     mobile: data.mobile,
  //     oldPassword: data.oldPassword,
  //     password: data.password
  //   }
  //   console.log("data",reqBody)
  //   this.googleAnalyticsService.eventEmitter(screenName.CHANGE_PASSWORD, {
  // 		log: clickedOn.SUBMIT_BTN,
  // 		screenName: screenName.CHANGE_PASSWORD + "_SCREEN",
  // 		clickedOn: clickedOn.SUBMIT_BTN,
  // 		title: clickedOn.SUBMIT_BTN,
  // 		time: new Date(),
  // 		isInput: false,
  // 		inputData:reqBody
  // 	  })
  //     const apiUrl = this.apiDetails['userauth'].base_url + this.apiDetails['userauth'].changePassword.url;
  //     console.log("API URL:", apiUrl);

  //   // Api call of update password
  //   this.store.dispatch(new fromStore.getUpdatePasswardResponse({
  //     url: this.apiDetails['userauth'].base_url +
  //       this.apiDetails['userauth'].changePassword.url,
  //     method: this.apiDetails['userauth'].changePassword.method,
  //     body: reqBody,
  //     onChangePasswordSuccess: this.logoutUserOnPasswordChange
  //   }));

  // }

  changePassward(data) {
    this.dialog.closeAll();
    let reqBody = {
      mobile: data.mobile,
      oldPassword: data.oldPassword,
      password: data.password,
    };
    this.googleAnalyticsService.eventEmitter(screenName.CHANGE_PASSWORD, {
      log: clickedOn.SUBMIT_BTN,
      screenName: screenName.CHANGE_PASSWORD + "_SCREEN",
      clickedOn: clickedOn.SUBMIT_BTN,
      title: clickedOn.SUBMIT_BTN,
      time: new Date(),
      isInput: false,
      inputData: reqBody,
    });

    this.store.dispatch(
      new fromStore.getUpdatePasswardResponse({
        url:
          this.apiDetails["userauth"].base_url +
          this.apiDetails["userauth"].changePassword.url,
        method: this.apiDetails["userauth"].changePassword.method,
        body: reqBody,
        // onChangePasswordSuccess: this.handlePasswordUpdateSuccess
      })
    );
    // Subscribe to the response observables
    this.store
      .select(fromStore.updatePasswordStatusCode)
      .subscribe((updatePasswordStatusCode) => {
        // console.log("code:",updatePasswordStatusCode)
        this.updatePasswordStatusCode = updatePasswordStatusCode;

        if(updatePasswordStatusCode !== 0){
          switch (updatePasswordStatusCode) {
            case 200:
              this.store
                .select(fromStore.updatePasswordResponse)
                .subscribe((updatePasswordResponse) => {
                  // console.log("message", updatePasswordResponse);
                  let body = {
                    code: updatePasswordStatusCode,
                    message: updatePasswordResponse,
                    result: "",
                    mobile: "",
                  };
                  // console.log("body:",body)
                  // Subscribe to validate observable
                  this.store
                    .select(fromStore.updatePasswordValidate)
                    .subscribe((validate) => {
                      // console.log("validate",validate)
                      // Ensure validate is available
                      if (validate !== null) {
                        if (body.code !== null && body.message !== null) {
                          const isValidate = this.loadashCompare.compareObjects(
                            body,
                            validate
                          );
                          // console.log("isValidate",isValidate)
                          if (isValidate) {
                            // Password change is allowed
                            this.toastr.success("Password changed successfully.");
                            this.logoutUserOnPasswordChange();
                          } else {
                            // Unauthorized access
                            this.toastr.error("Profile1 : Unauthorized Access!");
                            this.logoutUserOnPasswordChange();
                          }
                        }
                      }
                    });
                });
              break;
            case 401:
              // Unauthorized access
              this.toastr.error("Profile2 : Unauthorized Access!");
              this.logoutUserOnPasswordChange();
              break;
            default:
              this.errMessage = null;
              this.errorMessage = null;
              // Unauthorized access
              this.toastr.error(
                "Unable to chnage password,plz logout and login again"
              );
              this.logoutUserOnPasswordChange();
              break;
          }
        }
      });
  }

  handlePasswordUpdateSuccess = (response: any) => {
    // console.log("handle password success getting called", response);

    const updatePasswordStatusCode = response.status;
    const updatePasswordResponse = response.message;

    if (updatePasswordStatusCode === 200) {
      const body = {
        code: updatePasswordStatusCode,
        message: updatePasswordResponse,
        result: response.result,
        mobile: response.mobileNumber,
      };

      this.store
        .select(fromStore.updatePasswordValidate)
        .subscribe((validate) => {
          if (validate !== null) {
            if (body.code !== null && body.message !== null) {
              const isValidate = this.loadashCompare.compareObjects(
                body,
                validate
              );
              if (isValidate) {
                // Password change is allowed
                this.toastr.success("Password changed successfully.");
                this.logoutUserOnPasswordChange(); // Log out user after successful password change
              } else {
                // Unauthorized access
                this.toastr.error("Profile3 :Password change not allowed!");
                this.logoutUserOnPasswordChange();
              }
            }
          }
        });
    } else if (
      updatePasswordStatusCode === 401 ||
      updatePasswordStatusCode === 0
    ) {
      // Unauthorized access
      this.toastr.error("Cannot change password!");
    }
  };

  /**
   * Created by Shanu Jha
   * For logging out the user after password change
   */
  logoutUserOnPasswordChange = async () => {
    const encrDecr = new EncrDecrUtil();
    this.store.select(fromStore.getApiCallingList).subscribe((apiDetails) => {
      this.apiDetails = apiDetails;
      if (Object.keys(this.apiDetails).length > 0) {
        this.store.dispatch(
          new fromStore.Logout({
            url: environment.base_url + "/user/v1/logOut",
            method: "post",
            body: {
              userId: encrDecr.get(localStorage.getItem("userId")),
            },
          })
        );
      }
    });

    await this.router.navigate(["/Login/login"]);
    await //this.googleAnalyticsService.eventEmitter('Logout', 'User Logout on Change Passsword', 'Click Event', 1);
    await this.store.dispatch(new fromStore.ResetMobileNumber());
    await this.store.dispatch(new fromStore.ResetLogin());
    await this.store.dispatch(new fromStore.ResetUpdateProfile());
    await this.store.dispatch(new fromStore.ResetChangeMobileNumber());
    await this.store.dispatch(new fromStore.ResetForgetPassword());
    await this.store.dispatch(new fromStore.ResetUpdatePassword());
    await this.store.dispatch(new fromStore.ResetFeeds());
    await this.store.dispatch(new fromStore.ResetChatbot());
    await this.store.dispatch(new fromStore.ResetFavourite({}));
    await this.localStorageService.clearLocalStorage();
    localStorage.clear();
  };

  /**
   * Created by Prajkta Patil
   * For closing the popup
   */
  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy() {
    this.store.dispatch(new fromStore.ResetUpdatePassword());
    this.serverErrorMessage = null;
  }
}
