<!-- /*********************************
 * Component -  chatbot popup
 * Created By - Ajay Chavan
 * Updated By - Akanksha J
 * Updated On - 17/01/2020
 **************************************/ -->

<!-- open in iframe if userid -->
<div>
  <div class="cross-icon" (click)="closeChatbot()">
    <mat-icon class="cross-icon">clear</mat-icon>
  </div>
  <iframe [src]="chatbotWebLink" frameborder="0" width="{{deviceWidth}}" height="{{deviceHeight}}"></iframe>
</div>