import { Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked, AfterViewInit  } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { Images } from '../../config/images-config';
import { ObjectInterface } from '../../models/global-interface';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Router, RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { NgFor, NgIf, NgClass, UpperCasePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'app-related-article',
    templateUrl: './related-article.component.html',
    styleUrls: ['./related-article.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, RouterLink, NgClass, MatTooltip, MatButton, UpperCasePipe,CommonModule]
})
export class RelatedArticleComponent implements OnInit, AfterViewInit {
  @Input() feedsData: any;
  @Input() selectedLanguage: string;
  feedLabels: any;
  shareUrl: any;
  apiDetails: ObjectInterface;
  image = Images;
  timelineId: any;
  userId: string;
  token: any;
  favorite: string;
  feedsDate: any;
  updatedDate: any;
  @Input() landingLables:any;
  constructor(
    private store: Store<fromStore.CEPState>,
    private localStorageService: LocalStorageService,
    private encrDecr: EncrDecrService,
    private deviceService: DeviceDetectorService,
		private apiCallService: ApiCallService,
    private ref: ChangeDetectorRef,
    private router:Router
  ) { }

  ngOnInit() {

    
    if (this.feedsData) {
     

      this.feedsData.forEach((element, i) => {
        if (element.favourite) {
          this.feedsData[i] = Object.assign({}, element, { favouriteIcon: 'favorite', favourite: true })
        } else {
          this.feedsData[i] = Object.assign({}, element, { favouriteIcon: 'favorite_border', favourite: false })
        }
      });
    }

    this.store.select(fromStore.getToken)
    .subscribe((token) => {
      this.token = token;
      if (this.token) {
        const localUserId = this.localStorageService.getUserId();
        this.userId = this.encrDecr.get(localUserId);
      } else {
        this.userId = null;
      }
    });
    console.log('lang')
    // this.store.select(fromStore.getSelectedLanguage)
		// 	.subscribe((language) => {
		// 		this.selectedLanguage = language;

				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedLabels = labels;
            
					});
      //});
      this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails.shareUrl;
				}
      });
      
		// this.store.select(fromStore.isDetailsScreenLoading)
    // .subscribe(isLoaded => {
    //   this.isLoaded = isLoaded;
    //   if (this.isLoaded) {
    //     this.store.select(fromStore.feedDetailsResult)
    //       .subscribe((feedsData) => {
    //         this.feedsData = feedsData;
    //         if (Object.keys(this.feedsData).length !== 0) {
    //           this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
    //           if (this.feedsData.favourite) {
    //             this.favorite = 'favorite';
    //           } else {
    //             this.favorite = 'favorite_border';
    //           }
    //         }
    //         this.feedsDate = new Date(this.feedsData.updatedAt).toDateString();
    //       });
    //   }
    // });

    // this.store.select(fromStore.getLandingScreenLabels)
		// 	.subscribe((labels) => {
		// 		this.landingLables = labels;
		// 	});
  }

  ngAfterViewInit(){

    
  }

/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media, data) {
		var url =  this.shareUrl + '/cep/feeds/'+ data.engagementType +'/' + data.timelineId
		var metaTag  = {
			socialTitle: data.en.title,
			socialImageLink: data.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

  convertDate(updatedDate) {
    return new Date(updatedDate).toDateString();
  }

  	/**
	 * Created by Ajay C'
	 * For unmarking the favourite button
	 */
  unMarkFavorite(data, i) {
    if (data.favourite) {
      this.feedsData[i] = Object.assign({}, data, 
        { favourite: false , favouriteIcon:'favorite_border'})   
    } else {
      this.feedsData[i] = Object.assign({}, data, 
        { favourite: true, favouriteIcon:'favorite' })
    }
    if (Object.keys(this.apiDetails).length > 0) {
      if (this.apiDetails) {
        if (this.userId) {
          this.store.dispatch(new fromStore.MarkFavorite({
            url: this.apiDetails['user'].base_url +
              this.apiDetails['user'].addToFavourite.url,
            method: this.apiDetails['user'].addToFavourite.method,
            body: {
              "userId": this.userId,
              "engagementId": data.engagementId,
              "engagementType": data.engagementType
            }
          }));
        }
      }
    }
  }

  redirection(data){

    if(data.engagementType === 'forum'){
    this.router.navigate(['/cep/feeds/forum/',data.timelineId ,'/feeds'])
    }else{
			this.router.navigate([`/cep/feeds/${data.engagementType}/`, data.timelineId])

    }

  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

}
