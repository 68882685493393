import { Component, OnInit, ChangeDetectorRef, Inject, AfterContentChecked, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Images } from 'src/app/shared/config/images-config';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import * as fromStore from '../../cep/store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UntypedFormControl, FormGroupDirective, NgForm, UntypedFormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { EncrDecrService } from 'src/app/shared/services/encr-decr.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from '../../shared/services/localstorage.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { LoadshCompareService } from 'src/app/shared/services/loadsh-compare.service';
import { NgIf, UpperCasePipe } from '@angular/common';
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
		const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
		return (invalidCtrl || invalidParent);
	}
}

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, UpperCasePipe]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {
	images = Images;
	deviceHeight = window.innerHeight;
	state = 'default';
	color = 'primary';
	mode = 'indeterminate';
	apiDetails: {};
	mobileNumber: string;
	isLoading = false;
	isLoaded = false;
	forgotPasswordData: number;
	otp = null;
	newPassword = null;
	cPassword: string;
	myForm: UntypedFormGroup;
	matcher = new MyErrorStateMatcher();
	selectedLanguage: string;
	changePasswordLabels: {};
	passwordLabels: {};
	errMessage = null;
	cPasshHide = true;
	hide = true;
	encrPassword: any;
	successMessages: {};
	serverErrorMessage: string;
	serverMessage: boolean;
	registerLabels: {};
	errorMessages: {};
	isMobile: boolean;
	isTablet: boolean;
	isBlind;
	drawerLabels: {};
	showSelectedLanguage = 'English';
	get passwordInput() { return this.myForm.get('password'); }
	get password() { return this.myForm.get('cPassword'); }
	// public passwordRegx = /^[A-Za-z0-9`~!@#$%^&*()_+-=:'<>?;',./]{8,20}$/;

	public passwordRegx = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);

	constructor(
		public dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private spinner: NgxSpinnerService,
		private ref: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private encrDecr: EncrDecrService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private localStorageService: LocalStorageService,
		private router: Router,
		private toastr: ToastrService,
		private deviceService: DeviceDetectorService,
		private loadashCompare: LoadshCompareService
	) {
		this.isMobile = this.deviceService.isMobile();
		this.isTablet = this.deviceService.isTablet();
		this.myForm = this.formBuilder.group({
			password: ['', [Validators.required, Validators.pattern(this.passwordRegx)]],
			confirmPassword: ['', [Validators.pattern(this.passwordRegx)]]
		}, { validator: this.checkPasswords });

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getRegisterScreenLabels)
						.subscribe((labels) => {
							this.changePasswordLabels = labels;
						});
					this.store.select(fromStore.getPasswordScreenLabels)
						.subscribe((labels) => {
							this.passwordLabels = labels;
						});

					this.store.select(fromStore.getRegisterScreenLabels)
						.subscribe((labels) => {
							this.registerLabels = labels;
							
						});
				}
			});
	}

	ngOnInit() {


		this.googleAnalyticsService.eventEmitter(
			screenName.FORGOT_PASSWORD + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.FORGOT_PASSWORD + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
		  );	
		this.store.select(fromStore.fetchOtp)
			.subscribe(otp => {
				this.otp = otp;
			});
		this.store.select(fromStore.isForgotPasswordLoading)
			.subscribe((isLoading) => {
				this.isLoading = isLoading;
			});

		// server error handling
		this.store.select(fromStore.getUserStateServerErrorMessage)
			.subscribe((serverErrorMessage) => {
				if (serverErrorMessage != null) {
					if (serverErrorMessage === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverErrorMessage = serverErrorMessage;
						this.serverMessage = true;
						if (this.serverErrorMessage) {
							console.error(this.serverErrorMessage);
							// this.toastr.error(this.serverErrorMessage)
						}
					}
					this.serverErrorMessage = null;
				}
				serverErrorMessage = null;
			});
			this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage === 'mar') {
					this.showSelectedLanguage = 'English';
				} else {
					this.showSelectedLanguage = 'मराठी';	
				}
			});
			this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getDrawerLabels)
						.subscribe((labels) => {
							this.drawerLabels = labels;
						});
					this.store.select(fromStore.getPasswordScreenLabels)
						.subscribe((labels) => {
							this.changePasswordLabels = labels;
						});
				}
			});

		//  forget password response
		this.store.select(fromStore.isForgotPasswordLoaded)
			.subscribe((isLoaded) => {
				this.isLoaded = isLoaded;
				if (isLoaded) {
					this.store.select(fromStore.forgotPasswordResponse)
						.subscribe((forgotPasswordData) => {
							this.forgotPasswordData = forgotPasswordData;
							if (this.forgotPasswordData === 200) {
								this.store.select(fromStore.forgotPasswordErrorMessage)
									.subscribe((message) => {
										if (message) {
											this.store.select(fromStore.getSuccessMessages)
												.subscribe(res => {
													this.successMessages = res;
													this.toastr.success(this.successMessages[this.selectedLanguage][message]);													

													let body ={
														code:forgotPasswordData,
														message:message,
														result:'',
														mobile:'',
													}
													//console.log("body",body)
													this.store.select(fromStore.forgotPasswordValidate)
														.subscribe((validate) =>{
															//console.log("validate",validate)
															if(validate !== null){
																const isValidate = this.loadashCompare.compareObjects(body,validate);
																 //console.log("isValidate1",isValidate)
																if(isValidate){
																	this.store.dispatch(new fromStore.ResetLogin());
																	this.router.navigate(['/Login/login']);
																	message = null;
																	res = null;
																}else{
																	this.toastr.error("Forgot Password : Unauthorized Access!")
																}
															}

														})
												});
										}
									});
								// this.store.dispatch(new fromStore.ResetLogin());
								// this.router.navigate(['/Login/login']);
							} else {
								this.store.select(fromStore.forgotPasswordErrorMessage)
									.subscribe((message) => {
										if (message) {
											this.store.select(fromStore.getErrorMessages)
												.subscribe(res => {
													console.error(res[this.selectedLanguage][this.forgotPasswordData]);
													res = null;
												});
										}
										message = null;
									});
							}
						});
				}
			});


		this.store.select(fromStore.getUserStateResponse)
			.subscribe((getUserStateResponse) => {
				this.mobileNumber = getUserStateResponse['mobile'];
			});
	}

	onChangeButton(){
		if(this.selectedLanguage === 'mar' ){
			this.showSelectedLanguage = 'म';
			this.selectedLanguage = 'en'
		}else{
			this.showSelectedLanguage = 'Eng';
			this.selectedLanguage = 'mar'
		}
		let language = this.selectedLanguage;
		//this.googleAnalyticsService.eventEmitter('Language', 'Change Language to ' + this.selectedLanguage, 'Click Event', 1);
		this.store.dispatch(new fromStore.ChangeLanguage({
			language
		}));
	}

	/**
	 * Created by Akanksha J
	 * For changing the password and redirecting to login page
	 */
	redirectToPassword(password, cPassword) {

		this.encrPassword = this.encrDecr.set(this.newPassword);
		this.otp = this.otp;
		this.dialog.closeAll();
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				this.googleAnalyticsService.eventEmitter(screenName.FORGOT_PASSWORD, {
					log: clickedOn.SUBMIT_BTN,
					screenName: screenName.FORGOT_PASSWORD + "_SCREEN",
					clickedOn: clickedOn.SUBMIT_BTN,
					title: clickedOn.SUBMIT_BTN,
					time: new Date(),
					isInput: true,
					inputData:{
						mobile: this.mobileNumber,
						veriCode: this.otp,
						password: this.encrPassword
					}
				})
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.ForgotPassword({
						url: this.apiDetails['userauth'].base_url +
							this.apiDetails['userauth'].forgetPassword.url,
						method: this.apiDetails['userauth'].forgetPassword.method,
						body: {
							mobile: this.mobileNumber,
							veriCode: this.otp,
							password: this.encrPassword
						}
					}));
				}
			});
	}


	verifyPasswordStrength(newPassword: string, cPassword: string){

		const passwordRegx: RegExp = this.passwordRegx;
		const password: string = newPassword;
		const tested = passwordRegx.test(password);

		if(tested){
				this.redirectToPassword(newPassword, cPassword);
		} else {
				const passwordEnMessage = 'Passwords must be at least 8 characters in length and include at least one uppercase letter, one lowercase letter, one digit, and one special character.';
				const passwordMarMessage = 'आपला पासवर्ड हा 8 किंवा त्यापेक्षा जास्त कॅरेक्टरचा असावा आणि कमीत कमी एक अपरकेस अक्षर, एक लोअरकेस अक्षर, एक अंक आणि एक विशेष वर्ण असला पाहिजे.';
				this.toastr.error(this.selectedLanguage === 'en' ? passwordEnMessage : passwordMarMessage)
		}

}

	/**
	 * Created by Akanksha J
	 * For valicating password and confirm password
	 */
	checkPasswords(group: UntypedFormGroup) {
		let pass = group.controls.password.value;
		let confirmPass = group.controls.confirmPassword.value;
		return confirmPass.length === 0 && pass.length === 0 || pass === confirmPass ? null : { notSame: true };
	}

	/**
	 * Created by Akanksha J
	 * For closing the popup
	 */
	closeDialog() {
		const dialogClose = this.dialog.closeAll();
		this.store.dispatch(new fromStore.ResetLogin());
	}

	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	}

	stopSpeaking(){
		speechSynthesis.cancel();
	
	}

	changeLanguage(language) {
		if (language === 'en') {
			this.showSelectedLanguage = 'Eng';
		} else {
			this.showSelectedLanguage = 'म';
		}
		this.selectedLanguage = language;
		this.googleAnalyticsService.eventEmitter(screenName.FORGOT_PASSWORD, {
			log: clickedOn.CHANGE_LANG,
			screenName: screenName.FORGOT_PASSWORD + "_SCREEN",
			clickedOn: clickedOn.CHANGE_LANG,
			title: clickedOn.CHANGE_LANG,
			time: new Date(),
			isInput: false
		})
		//this.googleAnalyticsService.eventEmitter('Language', 'Change Language to ' + this.selectedLanguage, 'Click Event', 1);
		this.store.dispatch(new fromStore.ChangeLanguage({
			language
		}));
	}


	goBack() {
		this.router.navigate(['/Login/otp', 'change']);
	}

	/**
	 * Angular method which gets executed on view initialization
	 */
	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

	/**
	 * Angular method which gets executed on any chane on screen
	 */
	ngAfterContentChecked() {
		this.ref.detectChanges();
	}

	/**
	 * Angualr method whict gets executed on page destroy
	 */
	ngOnDestroy() {

		this.googleAnalyticsService.eventEmitter(
			screenName.FORGOT_PASSWORD + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.FORGOT_PASSWORD + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		this.errMessage = null;
		this.serverErrorMessage = null;
		this.store.dispatch( new fromStore.NullOTP(null) )
	}

}

