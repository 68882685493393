
<div class="dialogbox" *ngIf="data">
  <div *ngIf="isLoading" class="dialog-spinner">
      <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
      </mat-progress-spinner>
  </div>
  <div class="dialogbox" *ngIf="!isLoading">
    <div class="popup-header">
      <div class="popup-heading">
        <h1 mat-dialog-title>{{labels[selectedLanguage].partialPayment}}</h1>
        <button class="close-button" (click)="closeDialog()">X</button> 
      </div>
    </div>
    <div mat-dialog-content class="popup-container">
      <mat-form-field>
        <mat-label class="amountLabel">{{labels[selectedLanguage].enterAmount}}</mat-label>
        <span matPrefix class="currencyIcon">₹</span>
        <input matInput [(ngModel)]="partialAmount" type="number" placeholder="Enter Partial Amount" (input)="validateAmount()" (keypress)="numberOnly($event)"/>      
      </mat-form-field>
      <span class="errMsg" >{{ amountError }}</span>
    </div>
    <div mat-dialog-actions  class="mat-option">
      <button mat-button (click)="onCancel()" class="pay-button">{{labels[selectedLanguage].cancel}}</button>
      <button mat-button (click)="onConfirm()" class="pay-button" [disabled]="!isAmountValid">{{labels[selectedLanguage].confirm}}</button>
    </div>
  </div>
</div>