<!-- /*******************************
 * Component -  Product component
 * Created By - Akanksha J
 * Updated By - Akanksha J
 * Updated On - 17/01/2020
 ********************************/ -->
 <div *ngIf="productData !=undefined" class="post-card">
  <!-- event img -->
  <div class="post-image" [routerLink]="['/cep/feeds/product']">
    <img mat-card-image src="{{image.waterTaxImage}}" loading="lazy" alt="...">
  </div>
  <div class="post-content">
    <div class="post-tag-container">
      <div class="event-tag">
        <p>PRODUCT</p>
      </div>
      <div class="paid-free-button">
        <button mat-raised-button class="mat-button">{{productData.type}}</button>
      </div>
    </div>

    <!-- post heading -->
    <div class="post-heading font-heading" [routerLink]="['/cep/feeds/product']">
      <p class="font-card-heading">Water Tap</p>
    </div>

    <!-- short desc -->
    <div class="post-description" [routerLink]="['/cep/feeds/product']">
      <p> A steel water tap for water supplies</p>
    </div>

    <!-- post footer -->
    <!-- <div class="event-date-label">
      <p>{{productData[selectedLanguage].location}}</p>
    </div>
    <div class="post-footer">
      <div class="post-date">{{startDate}} - {{endDate}}</div>
    </div> -->

    <!-- action logo -->
    <div class="align-logo">
      <div class="post-date">{{postDate}} | {{productData.createdBy}}</div>
      <div class="post-action-logo" [ngClass]="{'login-active-favourite': !this.userId}">
        <div *ngIf="this.userId" class="share-logo" (click)="clickOnShare()"><i class="material-icons" >
            share
          </i></div>
        <!-- <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="generateShareLink('facebook')">
            Facebook</button>
          <button mat-menu-item (click)="generateShareLink('twitter')">
            Twitter</button>
            <button mat-menu-item (click)="generateShareLink('linkedin')">
                LinkedIn</button>
        </mat-menu> -->
        <div *ngIf="this.userId"><i class="material-icons favorite-icon"
            [ngClass]="{'active-favorite':productData.favourite}" (click)="unMarkFavorite()">
            {{favorite}}
          </i></div>
      </div>
    </div>

  </div>
</div>