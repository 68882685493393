<!--/*******************************
 * Component -  More services
 * Created By - Akanksha J
 * Created on - 28/03/2020
 * Updated By - Akanksha J
 * Updated On - 31/03/2020
 *************************************/-->
<div class='overlap-container'>
    <!-- heading -->
    <!-- <div class='feed-title font-heading'>
        {{data | uppercase}}
    </div> -->
    <!-- connection lost condition -->
    <div *ngIf='serverMessage' class='connectionDiv'>
        <img src={{images.connectionLost}} class='connectionLostImg' loading="lazy" alt="...">
        <span>{{serverMessage}}</span>
    </div>
    <!-- main page loader -->
    <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>

    <!-- main page -->
    <div *ngIf='!isLoading && !serverMessage' class="post-navigation-merchant font-sub-heading">
        <p>
            <span [routerLink]="[ '/cep/offers']" class="feeds-redirection font-card-heading">
                {{categoryLabels[selectedLanguage].offers | uppercase}}
            </span> > {{category[selectedLanguage] | uppercase}}
        </p>
    </div>
    <div *ngIf='!isLoading && !serverMessage' class='main-container'>
        <div class='service-container'>
            <div
                *ngIf="categoryServices && categoryServices['sponsared'] && !categoryServices['sponsared'].length 
            && categoryServices['merchant'] && !categoryServices['merchant'].length && categoryServices['forYou'] && !categoryServices['forYou'].length">
                <!-- data not found error handling -->
                <div class="error-messages">
                    <img src="{{images.noDataFound}}" class="no-data-image-favourite" loading="lazy" alt="...">
                    <span>{{errorMessages[selectedLanguage]['500']}}</span>
                </div>
            </div>
            <!-- sponsored images -->
            <div class="category-list"
                *ngIf="categoryServices && categoryServices['sponsared'] && categoryServices['sponsared'].length > 0">
                <div class='services-list-sponsored'>
                    <div class="overflow-div">
                        <!-- <div>sponsored</div> -->
                        <ng-container *ngFor="let data of categoryServices['sponsared']; let i = index">
                            <img (click)="openDealsDetailPage(data)" class="sponsored-image"
                                src="{{data.imageFavourite[0]}}" loading="lazy" alt="...">
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- merchant list-->
            <div class="category-list"
                *ngIf="categoryServices && categoryServices['merchant'] && categoryServices['merchant'].length > 0">
                <div class='services-list'>
                    <div class="overflow-div">
                        <!-- <div>Merchant List</div> -->
                        <div class='services' *ngFor="let data of categoryServices['merchant']; let i = index">
                            <img matTooltip="{{data.displayCompanyName}}" (click)="openDealListing(data.userId)"
                                class='service-image' src='{{data.logo}}' loading="lazy" alt="...">
                            <div matTooltip="{{data.displayCompanyName}}" (click)="openDealListing(data.userId)"
                                class="label">{{data.displayCompanyName}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="n-f-c" *ngIf="categoryServices && categoryServices['merchant'].length === 0">
                <div class="not-found"></div>
                <h1 style="width:100%;text-align: center;">
                    <span *ngIf=" selectedLanguage === 'en' ">{{ 'No data found' | uppercase }}</span>
                    <span *ngIf=" selectedLanguage === 'mar' ">माहिती आढळली नाही</span>
                </h1>
            </div>

            <!-- deals for you -->
            <div class="category-list"
                *ngIf="categoryServices && categoryServices['forYou'] && categoryServices['forYou'].length > 0">
                <h2 style="margin: 10px;">{{ categoryLabels[selectedLanguage].recommenddeals }} :-</h2>
                <div class='services-list-deal'>
                    <div class="overflow-div">
                        <div class="container " *ngFor='let item of categoryServices["forYou"]; let i = index'>
                            <img (click)="openDealsDetailPage(item)" class="deal-image" src="{{item.image[0]}}" loading="lazy" alt="...">
                            <div class="deal-container" style="width: 200px;">
                                <div (click)="openDealsDetailPage(item)" class="brand-name" style="width: 200px;">
                                    <strong>{{item.displayCompanyName}} |
                                    </strong>{{item[selectedLanguage].productName}}</div>
                                <!-- <div class="deals-available">
                                    <img class="sale-image" src="../../../../assets/images/more-services/sale.png">
                                    {{item[selectedLanguage].description | slice:0:40}}
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>