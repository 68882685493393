<!-- /******
 * Component -  blog component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */ -->
 <div class="feed-card">
    <div class="feed-image">
      <!-- image of card -->
      <img
        [routerLink]="['/cep/feeds/webinar/', webinarData.webinarId]"
        src="{{ webinarData.image}}" loading="lazy" alt="..."/>
    </div>
    <!-- main content -->
    <div class="feed-content">
      <div class="feed-tag">
        <p>{{ webinarData.engagementType | uppercase }}</p>
      </div>
      <div
        class="feed-heading"
        [routerLink]="['/cep/feeds/webinar/', webinarData.webinarId]"
      >
        <p class="font-card-heading" matTooltip="{{ webinarData[selectedLanguage].title }}">{{ webinarData[selectedLanguage].title }}</p>
      </div>
      <div
        class="feed-desc"
        [routerLink]="['/cep/feeds/webinar/', webinarData.webinarId]"
        matTooltip="{{ webinarData[selectedLanguage].description }}">
        <p>{{ webinarData[selectedLanguage].description }}</p>
      </div>
      <div class="blog-footer">
        <div class="feed-date">{{ blogDate }} | {{ webinarData.createdBy }}</div>
        <div
          class="blog-action-logo"
          [ngClass]="{ 'login-active-favourite': !this.userId }"
        >
        <!-- share options -->
        <!-- <div class="abuse-flag-container">
          <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon>
      </div> -->
          <div class="share-logo" (click)="clickOnShare()">
        
            <i class="material-icons" [matMenuTriggerFor]="menu" matTooltip="{{feedLabels[selectedLanguage].share}}">
              share
            </i>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')"> 
              <img class="social-share-img" src="{{image.facebookShareImg}}" loading="lazy" alt="...">
              Facebook
            </button>
            <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')">
              <img class="social-share-img" src="{{image.twitterShareImg}}" loading="lazy" alt="...">
              Twitter
            </button>
            <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')">
              <img class="social-share-img" src="{{image.linkedinShareImg}}" loading="lazy" alt="...">
              LinkedIn
            </button>
            <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')"> 
              <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}" loading="lazy" alt="...">
              Whatsapp
            </button>
          </mat-menu> 
          <div *ngIf="this.userId">
            <i
              class="material-icons favorite-icon"
              [ngClass]="{ 'active-favorite': webinarData.favourite }"
              (click)="unMarkFavorite()"   matTooltip="{{feedLabels[selectedLanguage].addToFavorites}}"
            >
              {{ favorite }}
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
  