import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as  AroundMeAction from '../action/around-me.action';
import { Observable, of } from 'rxjs';
import { AroundMeService } from '../api-services/around-me.service';


@Injectable()

export class AroundMeEffects {
    constructor(
        private actions$: Actions,
        private aroundMeMapService: AroundMeService
    ) {
    }

    aroundMeData$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AroundMeAction.GetAroundMeOnMap>(AroundMeAction.GET_AROUND_ME),
            switchMap((action) => {
                return this.aroundMeMapService.getAroundMeLocations(action.payload).pipe(
                    map(nearMeData => new AroundMeAction.GetAroundMeOnMapSuccess(nearMeData)),
                    catchError(error => of(new AroundMeAction.GetAroundMeOnMapFail(error)))
                );
            })
        )
    );

    aroundMECategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AroundMeAction.GetAroundMeCategory>(AroundMeAction.GET_AROUND_ME_CATEGORY),
            switchMap((action) => {
                return this.aroundMeMapService.getAroundMeCategory(action.payload).pipe(
                    map(nearMeCategoryData => new AroundMeAction.GetAroundMeCategorySuccess(nearMeCategoryData)),
                    catchError(error => of(new AroundMeAction.GetAroundMeCategoryFail(error)))
                );
            })
        )
    );

    // nearMeOnMap$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType<NearMeonMapAction.GetNearMeOnMap>(NearMeonMapAction.GET_NEAR_ME_ON_MAP),
    //         switchMap((action) => {

    //             return this.nearMeMapService.getAroundMECategory(action.payload).pipe(
    //                 map(labelData => new NearMeonMapAction.GetNearMeOnMapSuccess(labelData)),
    //                 catchError(error => of(new NearMeonMapAction.GetNearMeOnMapFail(error)))
    //             );
    //         })
    //     )
    // );

    // bicycleOnMap$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType<NearMeonMapAction.GetBicycleOnMap>(NearMeonMapAction.GET_BICYCLE_ON_MAP),
    //         switchMap((action) => {
    //             return this.nearMeMapService.getBicycleOnMap(action.payload).pipe(
    //                 map(bicycleData => new NearMeonMapAction.GetBicycleOnMapSuccess(bicycleData)),
    //                 catchError(error => of(new NearMeonMapAction.GetBicycleOnMapFail(error)))
    //             );
    //         })
    //     )
    // );


    // bikeOnMAp$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType<NearMeonMapAction.getBikeOnMap>(NearMeonMapAction.GET_BIKE_ON_MAP),
    //         switchMap((action) => {
    //             return this.nearMeMapService.getBikeOnMap(action.payload).pipe(
    //                 map(bikeData => new NearMeonMapAction.getBikeOnMapSuccess(bikeData)),
    //                 catchError(error => of(new NearMeonMapAction.getBikeOnMapFail(error)))
    //             );
    //         })
    //     )
    // );

}

