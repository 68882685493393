<!-- /******
 * Component -  blog component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */ -->
 <div class="feed-card">
  <div class="feed-image">
    <!-- image of card -->
    <img mat-card-image
      (click)="onClick()"
      src="{{ blogData.imageContent[0]}}" loading="lazy" alt="..."/>
  </div>
  <!-- main content -->
  <div class="feed-content">
    <div class="feed-tag" (mouseenter)="speakText(landingLables[selectedLanguage].blog)" (mouseleave)="stopSpeaking()">
      <p>{{ landingLables[selectedLanguage].blog | uppercase }}</p>
    </div>
    <div
      class="feed-heading" matTooltip="{{blogData[selectedLanguage].heading}}" 
      (click)="onClick()"
      (mouseenter)="speakText(blogData[selectedLanguage].heading)" (mouseleave)="stopSpeaking()"
    >
      <p class="font-card-heading">{{ blogData[selectedLanguage].heading }}</p>
    </div>
    <div
      class="feed-desc" matTooltip="{{blogData[selectedLanguage].description}}" 
      (click)="onClick()"
      (mouseenter)="speakText(blogData[selectedLanguage].description)" (mouseleave)="stopSpeaking()"
    >
      <p>{{ blogData[selectedLanguage].description }}</p>
    </div>
    <div class="blog-footer">
      <div class="feed-date" (mouseenter)="speakText('blog added by' + blogData.createdBy)" (mouseleave)="stopSpeaking()">{{ blogDate }}</div>
      <div
        class="blog-action-logo"
        [ngClass]="{ 'login-active-favourite': !this.userId }"
      >
      <!-- share options -->
      <div class="abuse-flag-container">
        <!-- <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()" (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)" (mouseleave)="stopSpeaking()">flag</mat-icon> --> 
     </div>
        <div class="share-logo"  *ngIf="this.userId" (click)="clickOnShare()">
      
          <i class="material-icons"  matTooltip="{{landingLables[selectedLanguage].share}}" (mouseenter)="speakText(landingLables[selectedLanguage].share)" (mouseleave)="stopSpeaking()">
            share
          </i>
        </div> 
        <!-- <mat-menu #menu="matMenu">
          <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')" (mouseenter)="speakText('share on facebook')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.facebookShareImg}}">
            Facebook
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on twitter')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.twitterShareImg}}">
            Twitter
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.linkedinShareImg}}">
            LinkedIn
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()"> 
            <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
            Whatsapp
          </button>
        </mat-menu>  -->
        <div *ngIf="this.userId">
          <i
            class="material-icons favorite-icon"
            [ngClass]="{ 'active-favorite': blogData.favourite }"
            (click)="unMarkFavorite()"
            (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)" (mouseleave)="stopSpeaking()"
          >
            {{ favorite }}
          </i>
        </div>
      </div>
    </div>
  </div>
</div>
