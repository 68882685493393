/******
 * Component -  slider banner
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */
import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, AfterContentChecked ,OnDestroy, EventEmitter, Output } from '@angular/core';
import * as M from "../../../../assets/materialize/js/materialize.min.js";
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as fromStore from '../../../cep/store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '../../services/localstorage.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from '../../services/dynamic-api-calling.service';
import { MatDialog } from "@angular/material/dialog";
import { environment } from 'src/environments/environment.js';      
import { ObjectInterface } from '../../models/global-interface.js';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-slider-banner',
    templateUrl: './slider-banner.component.html',
    styleUrls: ['./slider-banner.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class SliderBannerComponent implements OnInit, AfterViewInit, AfterContentChecked {
  // @Input() postImages: any;
  @Input() feedsImagesTexts: any;
  // @Input() postImages: string[];
  @Input() interval: number = 15000;
  @Output() electionPopupClicked: EventEmitter<any> = new EventEmitter<any>();

  carouselInstance:  M.Carousel;
  // : Observable<[]>;
  options = { fullWidth: true, indicators: true };
  selectedLanguage: string;
  feedImag: any;
  feedText:any;
  images: any;
  postImage: string;
  postImages:string[];
  postText: string;
  feedsImagesText:String;
  text:any;
  postImgeslabels:any;
  currentImageIndex: number = 0;
  apiDetails:ObjectInterface;
  shareUrl: any;
  filteredBannerImages: any;
  
  /**
   * angular method for declaring packages in variables
   * 
   * which runs first in this particulat component
   */
  constructor(
    private store: Store<fromStore.CEPState>,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private apiCallService: ApiCallService,


  ) { }

  ngOnInit() { 
    this.store.select(fromStore.getApiCallingList)
    .subscribe((apiDetails) => {
      this.apiDetails = apiDetails;
      if (Object.keys(this.apiDetails).length > 0) {
        this.shareUrl = this.apiDetails['shareUrl'];
      }     
    });
    this.googleAnalyticsService.eventEmitter(
			screenName.BANNER+ "_SCREEN",
			{
			  log: clickedOn.BANNER_CLICK,
			  screenName: screenName.BANNER + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
    );	
    this.store.select(fromStore.getSelectedLanguage)
    .subscribe((language) => {
      this.selectedLanguage = language;
      if (this.selectedLanguage) {
        this.fetchBannerImages();
      }
    })
    
    this.startImageInterval();

    
      // this.store.select(fromStore.getSelectedLanguage)
      // .subscribe((language) => {
      //   this.selectedLanguage = language;
      //   if (this.selectedLanguage) {
      //     this.store.select(fromStore.getBannerImages).subscribe((bannerImages) => {
      //       this.feedImag = bannerImages;            
      //       this.feedImag = bannerImages[this.selectedLanguage]['feed']
      //       if (this.feedImag) {
      //         this.postImages = this.feedImag['feedsImages']
            
      //         // console.log(this.postImages)
      //         // if (this.selectedLanguage && this.selectedLanguage === 'mar') {                
      //         //   this.images = this.postImages['mar']['feed']['feedsImages'] ? this.postImages['mar']['feed']['feedsImages']:'';
      //         //   this.text = this.feedsImagesText['mar']['feed']['feedsImagesText']? this.feedsImagesText['mar']['feed']['feedsImagesText']:''; 
      //         // }
      //         // else if (this.selectedLanguage && this.selectedLanguage === 'en') {
      //         //   this.images = this.postImages['en']['feed']['feedsImages'] ? this.postImages['en']['feed']['feedsImages']:'';
      //         //   this.text = this.feedsImagesText['en'] ['feed'] ['feedsImagesText'] ? this.feedsImagesText['en']['feed']['feedsImagesText']:'';                
      //         // }       
      //         this.currentImageIndex = 0;
      //       //  this.feedsImagesText = this.feedText['feedsImagesText']
      //       }
           
      //     });
      //   }
      // });


      // this.store.select(fromStore.getSelectedLanguage)
      // .subscribe((language) => {
      //   this.selectedLanguage = language;
      //   if (this.selectedLanguage) {
      //     this.store.select(fromStore.getBannerImages).subscribe((bannerImages) => {
      //       this.feedText = bannerImages;
      //       // console.log("bannerImages",bannerImages)
      //       this.feedText = bannerImages[this.selectedLanguage]['feed']
      //       if (this.feedText) {
      //         this.feedsImagesText = this.feedText['feedsImagesText']
      //       }
      //     });
      //   }
      // });

    // this.store.select(fromStore.getSelectedLanguage)
    //   .subscribe((language) => {
    //     this.selectedLanguage = language;
    //     if (this.selectedLanguage) {
    //       this.store.select(fromStore.getBannerImages).subscribe((bannerImages) => {
    //         if(this.postImages) {
    //           this.feedImag = this.postImages;
    //         }
    //         this.feedImag = bannerImages[this.selectedLanguage]['feed']
    //         if (this.feedImag) {
    //           this.postImages = this.feedImag['feedsImages']
    //         }
    //       });
    //     }
    //   });


   
  }

  fetchBannerImages() {     
   
      this.apiCallService.apiCall(
        this.apiDetails['user'].bannerImages.getAllBannerImage?.method, 
        this.apiDetails['user'].base_url + this.apiDetails['user'].bannerImages.getAllBannerImage?.url, 
        {})
        .subscribe((res: any) => {
          if (res.code === 200) {
            this.postImages = res.result;  
            //  console.log(this.postImages)   
            this.filteredBannerImages = this.filterBannerImagesByLanguage(this.postImages,this.selectedLanguage);
            //  console.log("filtered images",this.filteredBannerImages)    
            this.ref.detectChanges();
            this.initializeCarousel();   
          }
        });
    
  }

  filterBannerImagesByLanguage(data:any[], selectedLanguage) {   
    
    if (!data || !selectedLanguage) {
      return {message: "No data found for the selected language." };
    }    
    const filteredData = data.filter(item => item.lang === selectedLanguage);        
    //  console.log(filteredData)
    return filteredData;
  }
  
  //   imageLOad(){
  //     this.postImages.forEach(postImage => {
  //       this.postImage = postImage;
  //       setTimeout(this.imageLOad, 100000000000); 
      
  //     });

  //  }
  startImageInterval() {
    const intervalDuration = 15000; // 15 seconds
    setInterval(() => {
      this.nextImage();
    }, intervalDuration);
  }

  NavigateTo(object:any){   
    
    this.googleAnalyticsService.eventEmitter(
      screenName.BANNER + "_SCREEN",
    {
      log: clickedOn.BANNER_CLICK,
      screenName: screenName.BANNER + "_SCREEN",
      SECTION: screenName.BANNER,
      clickedOn: clickedOn.BANNER_CLICK,   
      time: new Date(),
      isInput: false,
      
    });  
      if (object.redirectLink.includes("/cep/election-popup")) {      
        this.electionPopupClicked.emit();
      }else if (object.redirectLink.includes("/cep/")) {
        // Split the part before "/cep/" and navigate
        const parts = object.redirectLink.split("/cep/");
        if (parts.length > 1) {
          this.router.navigate(["/cep/" + parts[1]]);
        }   
      }else if (object.redirectLink.startsWith("www")){
        console.log("link",object.redirectLink)
        window.open("https://" + object.redirectLink,'_blank');
      }else{ 
        window.open(object.redirectLink, '_blank');
      }
  }

  previousImage() {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
      this.carouselInstance.prev();
    } else {
      this.currentImageIndex = this.postImages.length - 1;
    }
  }

  nextImage() {
    if (this.carouselInstance) {
      this.carouselInstance.next();
      this.currentImageIndex = this.carouselInstance.center;
    }
  }

  initializeCarousel() {  
      const elems = document.querySelectorAll('.carousel');
      if (elems.length > 0) {
        this.carouselInstance = M.Carousel.init(elems, this.options)[0];
      }
    
  }

  ngAfterViewInit() {

    this.initializeCarousel();

    // Start the automatic image change
    this.startAutomaticImageChange();
  }
 
  ngOnDestroy() {
    this.googleAnalyticsService.eventEmitter(
			screenName.BANNER+ "_SCREEN",
			{
			  log: clickedOn.BANNER_CLICK,
			  screenName: screenName.BANNER + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
			);	
    // Stop the automatic image change when component is destroyed
    this.stopAutomaticImageChange();
  }

  startAutomaticImageChange() {
    if (this.carouselInstance && this.interval > 0) {
      setInterval(() => {
        // this.carouselInstance.next();
        this.carouselInstance.next();
      }, this.interval);
    } 
                                                          
  }

  stopAutomaticImageChange() {
    if (this.carouselInstance) {
      clearInterval(this.carouselInstance);
    }
  }

  ngAfterContentChecked() {
    // this.ref.detectChanges();
  }
}
