<!-- /********************************
 * Component -  news card component
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 *********************************/ -->
<div class="feed-card">
    <!-- post image -->
    <div class="feed-image">
        <img mat-card-image
        (click)="onClick()"
          src="{{newsData.imageFavourite[0]}}" loading="lazy" alt="...">
    </div>

    <!-- post content -->
    <div class="feed-content">
        <div class="feed-tag" (mouseenter)="speakText(landingLables[selectedLanguage].news)" (mouseleave)="stopSpeaking()">
            <p>{{landingLables[selectedLanguage].news | uppercase}}</p>
        </div>

        <div class="feed-heading" matTooltip="{{newsData[selectedLanguage].heading}}" 
        (click)="onClick()"
          (mouseenter)="speakText(newsData[selectedLanguage].heading)" (mouseleave)="stopSpeaking()">
            <p>{{newsData[selectedLanguage].heading}}</p>
        </div>

        <div class="feed-desc" matTooltip="{{newsData[selectedLanguage].description}}"  
        (click)="onClick()"
          (mouseenter)="speakText(newsData[selectedLanguage].description)" (mouseleave)="stopSpeaking()">
            <p class="font-card-desc">{{newsData[selectedLanguage].description}}</p>
        </div>

        <div class="news-footer">
            <div class="feed-date" (mouseenter)="speakText('news posted by' + newsData.createdBy)" (mouseleave)="stopSpeaking()">{{newsDate}} </div>
            <div class="news-action-logo">
                <div class="abuse-flag-container">
                    <!-- <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()" (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)" (mouseleave)="stopSpeaking()">flag</mat-icon> -->
                </div>
                <!-- share options -->
                <div *ngIf="this.userId" class="share-logo" (click)="clickOnShare()">
                    <i class="material-icons"  matTooltip="{{landingLables[selectedLanguage].share}}" (mouseenter)="speakText(landingLables[selectedLanguage].share)" (mouseleave)="stopSpeaking()">
                      share
                    </i>
                </div>
                <!-- <mat-menu #menu="matMenu">
                    <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')"  (mouseenter)="speakText('share on facebook')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.facebookShareImg}}">
            Facebook
          </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')" (mouseenter)="speakText('share on twitter')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.twitterShareImg}}">
            Twitter
          </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.linkedinShareImg}}">
            LinkedIn
          </button>
                    <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()"> 
            <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
            Whatsapp
          </button>
                </mat-menu> -->
                <div *ngIf="this.userId">
                    <i
                      class="material-icons favorite-icon"
                      [ngClass]="{ 'active-favorite': newsData.favourite }"
                     
                      (click)="unMarkFavorite()"
                       (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)" (mouseleave)="stopSpeaking()" 
                       >  
                      {{ favorite }}
                       
                    </i>
                  </div>
            </div>
        </div>
    </div>