import { Component, OnInit, Input, AfterContentChecked, NgZone, ViewChild, ElementRef } from '@angular/core';
// import { MapsAPILoader } from '@agm/core';
import { Images } from 'src/app/shared/config/images-config';
import { MyErrorStateMatcher } from '../../../login/forgot-password/forgot-password.component';
import { Validators, UntypedFormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from "src/app/shared/services/dynamic-api-calling.service";
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { LocalStorageService } from '../../../shared/services/localstorage.service';
import { EncrDecrService } from '../../../shared/services/encr-decr.service';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';


import { MerchantTermsAndConditionsComponent } from '../../../shared/components/merchant-terms-and-conditions/merchant-terms-and-conditions.component'
import { Title } from '@angular/platform-browser';
import { log } from 'console';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { MatCard } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';
// import { google } from '@agm/core/services/google-maps-types';
declare const google: any;
@Component({
    selector: 'app-merchant-registration-form',
    templateUrl: './merchant-registration-form.component.html',
    styleUrls: ['./merchant-registration-form.component.scss'],
    standalone: true,
    imports: [NgIf, MatCard, FormsModule, MatFormField, MatLabel, MatInput, ReactiveFormsModule, MatError, MatSelect, MatOption, NgFor, MatCheckbox, MatButton]
})
export class MerchantRegistrationFormComponent implements OnInit, AfterContentChecked {
	images = Images;
	color = 'primary';
	mode = 'indeterminate';
	value = 50;
	valueImg = 35;
	// Api response variables
	apiDetails: any;
	token: any;
	userId: any;
	userName: any;
	option: any;
	certificateFileAsResponse: any;
	propertyFileAsResponse: any;
	brandList: Array<string>;
	isBrandLoading = false;
	isSameAsHeadOffice: boolean;
	isSameAsOwnerDetails: boolean;
	category: any;
	isMerchantLoading = false;
	isMerchant: boolean;
	isMerchantRequest: boolean;
	isMerchantRejected: boolean;
	merchantUserId;
	selectedLanguage: string;
	urls: any;
	urls1: any;
	// ngModel variables
	imgURL: string | ArrayBuffer;
	companyName: string;
	companyNameMar: string;
	companyType: string;
	displayName: string;
	displayNameMar: string;
	headOfficeAddress: string;
	headOfficeCity: string;
	headOfficePincode: number;
	headOfficeState: string;
	headOfficeMobileNo: number;
	ownerFirstName: string;
	ownerMiddleName: string;
	ownerLastName: string;
	ownerMobileNumber: number;
	ownerTelNo: number;
	ownerEmailId: string;
	outletOfficeAddress: string;
	outletOfficeCity: string;
	outletOfficePincode: number;
	outletOfficeState: string;
	outletOfficeMobileNo: number;
	contactPersonFirstName: string;
	contactPersonMiddleName: string;
	contactPersonLastName: string;
	contactPersonDesignation: string;
	contactPersonMobileNo: number;
	contactPersonTelNo: number;
	contactPersonEmailId: string;
	// operatingDays: number;
	// operatingHrs: number;
	// openWeekDay:string;
	// openWeekTiming:string;
	// otherWeekDays:string;
	// otherWeekTiming:string;
	monStartTimeModel: string;
	monEndTimeModel: string;
	monHolidayModel = false;
	tueStartTimeModel: string;
	tueEndTimeModel: string;
	tueHolidayModel = false;
	wedStartTimeModel: string;
	wedEndTimeModel: string;
	wedHolidayModel = false;
	thuStartTimeModel: string;
	thuEndTimeModel: string;
	thuHolidayModel = false;
	friStartTimeModel: string;
	friEndTimeModel: string;
	friHolidayModel = false;
	satStartTimeModel: string;
	satEndTimeModel: string;
	satHolidayModel = false;
	sunStartTimeModel: string;
	sunEndTimeModel: string;
	sunHolidayModel = false;
	merchantDataStorage: any;


	minimumOrderValue: number;
	permanantAddress: string;
	fssCertificate: number;
	gstNumber: string;
	aboutUs: string;
	aboutUsMar:string;
	certificateFile: any;
	propertyRightsFile: any;
	brandId: string;
	// lng:18.62861241;
	// lat:73.80478858;
	initlat = 18.62861241;
	initlng = 73.80478858;
	lngModel;
	latModel;
	isClicked = false;
	isTCClicked = false;
	brandName: string;
	imageUrl: any;
	imageUrl1: any;
	matcher = new MyErrorStateMatcher();
	isInEditView: boolean;

	// formcontrol variables and pattern variables
	pinCode = /^\d{6}$/;
	onlyNumber = /^\d{1,}$/;
	noSpecialChar = /^[^!@#$%^&*)}{(_+-=~`:'<>?,./;'|]+$/;
	alphaNumericInput = /^[a-zA-Z0-9 ]*$/;
	marathiInput = /^[\u0900-\u097Fa-zA-Z0-9 ]*$/;
	mobile = /^\d{10}$/;
	tel = /^\d{11}$/;
	email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,50}$/;

	companyNameControl = new UntypedFormControl('', [Validators.pattern(this.alphaNumericInput), Validators.maxLength(50), Validators.required]);
	companyNameControlMar = new UntypedFormControl('', [Validators.pattern(this.marathiInput), Validators.maxLength(50), Validators.required]);
	companyTypeControl = new UntypedFormControl('', [Validators.required]);
	selectedCategoryControl = new UntypedFormControl('', [Validators.required]);

	userIdControl = new UntypedFormControl('', [Validators.required]);
	displayNameControl = new UntypedFormControl('', [Validators.pattern(this.alphaNumericInput), Validators.maxLength(50), Validators.required]);
	displayNameControlMar = new UntypedFormControl('', [Validators.pattern(this.marathiInput), Validators.maxLength(50), Validators.required]);
	headOfficeAddressControl = new UntypedFormControl('', [Validators.maxLength(200), Validators.required]);
	headOfficeCityControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	headOfficePincodeControl = new UntypedFormControl('', [Validators.pattern(this.pinCode), Validators.maxLength(6), Validators.required]);
	headOfficeStateControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	headOfficeMobileNoControl = new UntypedFormControl('', [Validators.pattern(this.mobile), Validators.maxLength(10), Validators.required]);
	ownerFirstNameControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	ownerMiddleNameControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	ownerLastNameControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	ownerMobileNumberControl = new UntypedFormControl('', [Validators.pattern(this.mobile), Validators.maxLength(10), Validators.required]);
	ownerTelNoControl = new UntypedFormControl('', [Validators.pattern(this.tel), Validators.maxLength(12)]);
	ownerEmailIdControl = new UntypedFormControl('', [Validators.pattern(this.email), Validators.maxLength(50), Validators.required]);
	outletOfficeAddressControl = new UntypedFormControl('', [Validators.maxLength(200)]);
	outletOfficeCityControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	outletOfficePincodeControl = new UntypedFormControl('', [Validators.pattern(this.pinCode), Validators.maxLength(6), Validators.required]);
	outletOfficeStateControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	outletOfficeMobileNoControl = new UntypedFormControl('', [Validators.pattern(this.mobile), Validators.maxLength(10), Validators.required]);
	brandIdControl = new UntypedFormControl('', [Validators.required]);
	contactPersonFirstNameControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	contactPersonMiddleNameControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	contactPersonLastNameControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	contactPersonDesignationControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	contactPersonMobileNoControl = new UntypedFormControl('', [Validators.pattern(this.mobile), Validators.maxLength(10), Validators.required]);
	contactPersonTelNoControl = new UntypedFormControl('', [Validators.pattern(this.tel), Validators.maxLength(12)]);
	contactPersonEmailIdControl = new UntypedFormControl('', [Validators.pattern(this.email), Validators.maxLength(50), Validators.required]);
	operatingDaysControl = new UntypedFormControl('', [Validators.pattern(this.onlyNumber), Validators.required]);
	operatingHrsControl = new UntypedFormControl('', [Validators.pattern(this.onlyNumber), Validators.required]);
	openWeekDaysControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	openWeekTimingControl = new UntypedFormControl('', [Validators.maxLength(50), Validators.required]);
	otherWeekDaysControl = new UntypedFormControl('', [Validators.pattern(this.noSpecialChar), Validators.maxLength(50), Validators.required]);
	otherWeekTimingControl = new UntypedFormControl('', [Validators.maxLength(50), Validators.required]);

	minimumOrderValueControl = new UntypedFormControl('', [Validators.pattern(this.onlyNumber)]);
	permanantAddressControl = new UntypedFormControl('', [Validators.maxLength(200)]);
	aboutUsControl = new UntypedFormControl('', [Validators.required]);
	aboutUsControlMarthi =new UntypedFormControl ('',[Validators.required]);
	longitude = new UntypedFormControl('', [Validators.required]);
	latitude = new UntypedFormControl('', [Validators.required]);
	fssCertificateControl = new UntypedFormControl('', [Validators.pattern(this.onlyNumber), Validators.maxLength(14)]);
	gstNumberControl = new UntypedFormControl('', [Validators.maxLength(50)]);

	// Controller for opening date and time of merchant
	monStartTimeControl = new UntypedFormControl('', [])
	monEndTimeControl = new UntypedFormControl('', [])
	monHolidayControl = new UntypedFormControl('', [])
	tueStartTimeControl = new UntypedFormControl('', [])
	tueEndTimeControl = new UntypedFormControl('', [])
	wedStartTimeControl = new UntypedFormControl('', [])
	wedEndTimeControl = new UntypedFormControl('', [])
	thuStartTimeControl = new UntypedFormControl('', [])
	thuEndTimeControl = new UntypedFormControl('', [])
	friStartTimeControl = new UntypedFormControl('', [])
	friEndTimeControl = new UntypedFormControl('', [])
	satStartTimeControl = new UntypedFormControl('', [])
	satEndTimeControl = new UntypedFormControl('', [])
	sunStartTimeControl = new UntypedFormControl('', [])
	sunEndTimeControl = new UntypedFormControl('', [])


	merchantAndBrandLabels: {};
	successMsg: {};
	isLoadingCertificate: boolean;
	isLoadingProperty: boolean;
	isLoadingGst: boolean;
	isLoadingFss: boolean;
	isLoadingPan: boolean;
	isLoadingShopAct: boolean;
	GstFileAsResponse: any;
	PanFileAsResponse: any;
	FssFileAsResponse: any;
	ShopActFileAsResponse: any;
	isLoadingUdyogAadhar: boolean;
	UdyogAadharFileAsResponse: any;
	isBlind;
	isNewMerchant: boolean;
	isLoading: boolean;
	categoryNames: any[] = [];
	selectedCategory: any;
	dataNotFoundMsg: string;
	private geoCoder;
	searchAddress: any
	// companyNameControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);
	// companyTypeControl = new FormControl('', [Validators.required]);
	// displayNameControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// headOfficeAddressControl = new FormControl('', [Validators.required, Validators.maxLength(200)]);
	// headOfficeCityControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// headOfficePincodeControl = new FormControl('', [Validators.required, Validators.pattern(this.pinCode), Validators.maxLength(6)]);
	// headOfficeStateControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// headOfficeMobileNoControl = new FormControl('', [Validators.required, Validators.pattern(this.mobile), Validators.maxLength(10)]);
	// ownerFirstNameControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// ownerMiddleNameControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// ownerLastNameControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// ownerMobileNumberControl = new FormControl('', [Validators.required, Validators.pattern(this.mobile), Validators.maxLength(10)]);
	// ownerTelNoControl = new FormControl('', [Validators.required, Validators.pattern(this.tel), Validators.maxLength(12)]);
	// ownerEmailIdControl = new FormControl('', [Validators.required, Validators.pattern(this.email), Validators.maxLength(50)]);
	// outletOfficeAddressControl = new FormControl('', [Validators.required, Validators.maxLength(200)]);
	// outletOfficeCityControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// outletOfficePincodeControl = new FormControl('', [Validators.required, Validators.pattern(this.pinCode), Validators.maxLength(6)]);
	// outletOfficeStateControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// outletOfficeMobileNoControl = new FormControl('', [Validators.required, Validators.pattern(this.mobile), Validators.maxLength(10)]);
	// brandIdControl = new FormControl('');
	// contactPersonFirstNameControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// contactPersonMiddleNameControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// contactPersonLastNameControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// contactPersonDesignationControl = new FormControl('', [Validators.required, Validators.pattern(this.noSpecialChar), Validators.maxLength(50)]);
	// contactPersonMobileNoControl = new FormControl('', [Validators.required, Validators.pattern(this.mobile), Validators.maxLength(10)]);
	// contactPersonTelNoControl = new FormControl('', [Validators.required, Validators.pattern(this.tel), Validators.maxLength(12)]);
	// contactPersonEmailIdControl = new FormControl('', [Validators.required, Validators.pattern(this.email), Validators.maxLength(50)]);
	// operatingDaysControl = new FormControl('', [Validators.required, Validators.pattern(this.onlyNumber)]);
	// operatingHrsControl = new FormControl('', [Validators.required, Validators.pattern(this.onlyNumber)]);
	// minimumOrderValueControl = new FormControl('', [Validators.required, Validators.pattern(this.onlyNumber)]);
	// permanantAddressControl = new FormControl('', [Validators.required, Validators.maxLength(200)]);
	// fssCertificateControl = new FormControl('', [Validators.required, Validators.pattern(this.onlyNumber), Validators.maxLength(14)]);
	// gstNumberControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);

	/**
	 * Angular method which runs first for this componsnt
	 */

	@ViewChild('search', { static: false })
	public search: ElementRef;


	constructor(
		public dialog: MatDialog,
		private activeParams: ActivatedRoute,
		private activeRoute: ActivatedRoute,
		private toastr: ToastrService,
		private store: Store<fromStore.CEPState>,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private router: Router,
		//private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone,
		private apiCallService: ApiCallService,
		private titleService: Title

	) {
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe(language => {
				this.selectedLanguage = language;
			});
	}



	/**
	 * Angular method which runs on page initialization
	 */
	ngOnInit() {

		this.titleService.setTitle('PMC CARE: Merchant Registration Form')
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				// console.log("Merchant Url",this.apiDetails['user'].base_url +
				// this.apiDetails['user'].saveDetails.url)

			});

		this.getCategoryNames();


		// =============================================For MAP search in form ==========================================
		// this.mapsAPILoader.load().then(() => {

		// 	this.setCurrentLocation();
		// 	this.geoCoder = new google.maps.Geocoder;

		// 	let autocomplete = new google.maps.places.Autocomplete(this.search.nativeElement, {
		// 		componentRestrictions: { country: 'IN' }
		// 	});

		// 	autocomplete.addListener("place_changed", () => {
		// 		this.ngZone.run(() => {
		// 			//get the place result
		// 			let place: any = autocomplete.getPlace();
		// 			//verify result
		// 			if (place.geometry === undefined || place.geometry === null) {
		// 				return;
		// 			}
		// 			//set latitude, longitude and zoom
		// 			this.latModel = place.geometry.location.lat();
		// 			this.lngModel = place.geometry.location.lng();
		// 		});
		// 	});
		// });

		// =============================================End for MAP search in form==========================================

		this.store.select(fromStore.getSuccessMessages)
			.subscribe((SuccMessage) => {
				this.successMsg = SuccMessage;
			});

		this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((response) => {
				// console.log(response)
				this.userName = response['firstName'];
				this.ownerFirstName = response['firstName'];
				this.ownerMiddleName = response['middleName'];
				this.ownerLastName = response['lastName'];
				this.ownerMobileNumber = response['mobile'].substring(3);
				this.ownerEmailId = response['emailId'];
				this.contactPersonFirstName = response['firstName'];
				this.contactPersonMiddleName = response['middleName'];
				this.contactPersonLastName = response['lastName'];
				this.contactPersonMobileNo = response['mobile'].substring(3);
				this.contactPersonEmailId = response['emailId'];
			});


		this.store.select(fromStore.updateProfilePhotoResponseCertificate)
			.subscribe((Response) => {
				if (Response[0]) {
					this.certificateFileAsResponse = Response[0];
				}
			});

		this.store.select(fromStore.updateProfilePhotoResponseProperty)
			.subscribe((Response) => {
				if (Response[0]) {
					this.propertyFileAsResponse = Response[0];
				}
			});
		this.store.select(fromStore.updateProfilePhotoResponseGst)
			.subscribe((Response) => {
				this.GstFileAsResponse = Response[0];
			});
		this.store.select(fromStore.updateProfilePhotoResponsePan)
			.subscribe((Response) => {
				this.PanFileAsResponse = Response[0];
			});
		this.store.select(fromStore.updateProfilePhotoResponseFss)
			.subscribe((Response) => {
				this.FssFileAsResponse = Response[0];
			});
		this.store.select(fromStore.updateProfilePhotoResponseShopAct)
			.subscribe((Response) => {
				this.ShopActFileAsResponse = Response[0];
			});
		this.store.select(fromStore.updateProfilePhotoResponseUdyogAadhar)
			.subscribe((Response) => {
				this.UdyogAadharFileAsResponse = Response[0];
			});
		
		this.store.select(fromStore.getAllBrandResponse)
			.subscribe(res => {
				this.brandList = res;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.GetAllBrand({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].getAllBrand.url,
						method: this.apiDetails['user'].getAllBrand.method,
						body: {
							// 	// userId: this.userId
						}
					}));
				}
			});

		this.store.select(fromStore.getIsRegisterAsMerchantLoading)
			.subscribe(loading => {
				this.isMerchantLoading = loading;
			});

		this.store.select(fromStore.getIsRegisterAsBrandLoading)
			.subscribe(loading => {
				this.isBrandLoading = loading;
			});

		if (this.selectedLanguage) {
			// 	this.store.select(fromStore.getIsRegisterAsBrandLoaded)
			// 		.subscribe(loaded => {
			// 			if (loaded) {
			// 				this.store.select(fromStore.getRegisterAsBrandStatusCode)
			// 					.subscribe(statusCode => {
			// 						if (statusCode === 200) {
			// 							this.store.select(fromStore.getRegisterAsBrandErrorMessage)

			// 								.subscribe(msg => {
			// 									this.store.select(fromStore.getSuccessMessages)
			// 										.subscribe(successMessages => {
			// 											this.toastr.success(successMessages[this.selectedLanguage][msg]);
			// 										});
			// 								});
			// 							this.router.navigate(['/cep/feeds']);
			// 						} else {
			// 							this.store.select(fromStore.getErrorMessages)
			// 								.subscribe(successMessages => {
			// 									console.error(successMessages[this.selectedLanguage][statusCode]);
			// 								});
			// 							return;
			// 						}
			// 					});
			// 			}
			// 		});

			// this.store.select(fromStore.getIsRegisterAsMerchantLoaded)
			// 	.subscribe(loaded => {
			// 		if (loaded) {
			// 			this.store.select(fromStore.getRegisterAsMerchantStatusCode)
			// 				.subscribe(statusCode => {
			// 					if (statusCode === 200) {
			// 						this.store.select(fromStore.getRegisterAsMerchantErrorMessage)
			// 							.subscribe(msg => {
			// 								this.store.select(fromStore.getSuccessMessages)
			// 									.subscribe(successMessages => {
			// 										this.toastr.success(successMessages[this.selectedLanguage][msg]);
			// 									});
			// 							});
			// 						this.router.navigate(['/cep/feeds']);
			// 					} else {
			// 						this.store.select(fromStore.getErrorMessages)
			// 							.subscribe(successMessages => {
			// 								console.error(successMessages[this.selectedLanguage][statusCode]);
			// 							});
			// 						return;
			// 					}
			// 				});
			// 		}
			// 	});

			this.store.select(fromStore.getMerchantAndBrandLabels)
				.subscribe(labels => {
					this.merchantAndBrandLabels = labels;
	
				});
		}


		// ====================================Prepopulating merchant data in the form ====================================================
		this.activeRoute.params.subscribe((param:any) => {

			if (Object.keys(param).length > 0) {
				
				if (param.option == 'new') {// if new merchant is registering 
					this.isNewMerchant = true;
					this.isInEditView = false;
					//  window.location.href = `cep/feeds/merchant-registration/${param.option}`;
					//  const url = `cep/feeds/merchant-registration/${param.option}`;
					//  window.location.reload();
					
				} else if (param.option == 'edit') {//if merchant is editing the form
					this.isNewMerchant = false;
					//  window.location.href = `cep/feeds/merchant-registration/${param.option}`;
					//  const url = `cep/feeds/merchant-registration/${param.option}`;
                    //  window.location.reload();

					this.store.dispatch(new fromStore.GetMerchantById({
						url: this.apiDetails['user'].base_url +
							this.apiDetails['user'].getMerchantById.url,
						method: this.apiDetails['user'].getMerchantById.method,
						body: {
							userId: this.userId
						}
					}));

					this.store.select(fromStore.isGetMerchantByIdLoading)
						.subscribe(loading => {
							this.isLoading = loading;
						});

					this.store.select(fromStore.isGetMerchantByIdLoaded)
						.subscribe(loaded => {
							if (loaded) {
								this.store.select(fromStore.getMerchantByIdStatusCode)
									.subscribe(code => {
										if (code) {
											if (code === 200) {
												this.store.select(fromStore.getMerchantByIdResult)
													.subscribe(merchantDataPrepopulate => {

														this.merchantDataStorage = merchantDataPrepopulate

														if (Object.keys(merchantDataPrepopulate).length > 0) {
															this.isMerchant = merchantDataPrepopulate['isMerchant']
															this.isMerchantRequest = merchantDataPrepopulate['isMerchantRequest']
															this.isMerchantRejected = merchantDataPrepopulate['isMerchantRejected']

															this.companyName = merchantDataPrepopulate['registerCompanyName']
															this.companyType = merchantDataPrepopulate['companyType']
															this.displayName = merchantDataPrepopulate['displayCompanyName']
															this.displayNameMar = merchantDataPrepopulate['displayCompanyNameMar']
															this.companyNameMar = merchantDataPrepopulate['registerCompanyNameMar']

															this.headOfficeAddress = merchantDataPrepopulate['headOfficeAddress']
															this.headOfficePincode = merchantDataPrepopulate['headOfficePinCode']
															this.headOfficeState = merchantDataPrepopulate['headOfficeState']
															this.headOfficeMobileNo = merchantDataPrepopulate['headOfficeMobileNumber']
															this.headOfficeCity = merchantDataPrepopulate['headOfficeCity']

															this.latModel = merchantDataPrepopulate['geometry'].coordinates[0]
															this.lngModel = merchantDataPrepopulate['geometry'].coordinates[1]

															this.ownerTelNo = merchantDataPrepopulate['ownerTelephoneNumber']

															this.isSameAsHeadOffice = merchantDataPrepopulate['isSameAsHeadOffice']
															this.isSameAsOwnerDetails = merchantDataPrepopulate['isSameAsOwnerDetails']

															this.outletOfficeAddress = merchantDataPrepopulate['outlets'].outletOfficeAddress
															this.outletOfficeCity = merchantDataPrepopulate['outlets'].outletOfficeCity
															this.outletOfficePincode = merchantDataPrepopulate['outlets'].outletOfficePinCode
															this.outletOfficeState = merchantDataPrepopulate['outlets'].outletOfficeState
															this.outletOfficeMobileNo = merchantDataPrepopulate['outlets'].outletOfficeMobileNumber

															this.contactPersonDesignation = merchantDataPrepopulate['contactPersonDesignation']
															this.contactPersonTelNo = merchantDataPrepopulate['contactPersonTelephoneNumber']

															// this.operatingDays = merchantDataPrepopulate['operatingDays']
															// this.operatingHrs = merchantDataPrepopulate['operatingHours']
															// this.otherWeekTiming = merchantDataPrepopulate['otherWeekTiming']
															// this.otherWeekDays = merchantDataPrepopulate['otherWeekDay']
															// this.openWeekDay = merchantDataPrepopulate['openWeekDay']
															// this.openWeekTiming = merchantDataPrepopulate['openWeekTiming']

															this.monStartTimeModel = merchantDataPrepopulate['workingDays'].mon.startTime
															this.monEndTimeModel = merchantDataPrepopulate['workingDays'].mon.endTime
															this.monHolidayModel = merchantDataPrepopulate['workingDays'].mon.holiday

															this.tueStartTimeModel = merchantDataPrepopulate['workingDays'].tue.startTime
															this.tueEndTimeModel = merchantDataPrepopulate['workingDays'].tue.endTime
															this.tueHolidayModel = merchantDataPrepopulate['workingDays'].tue.holiday

															this.wedStartTimeModel = merchantDataPrepopulate['workingDays'].wed.startTime
															this.wedEndTimeModel = merchantDataPrepopulate['workingDays'].wed.endTime
															this.wedHolidayModel = merchantDataPrepopulate['workingDays'].wed.holiday

															this.thuStartTimeModel = merchantDataPrepopulate['workingDays'].thus.startTime
															this.thuEndTimeModel = merchantDataPrepopulate['workingDays'].thus.endTime
															this.thuHolidayModel = merchantDataPrepopulate['workingDays'].thus.holiday

															this.friStartTimeModel = merchantDataPrepopulate['workingDays'].fri.startTime
															this.friEndTimeModel = merchantDataPrepopulate['workingDays'].fri.endTime
															this.friHolidayModel = merchantDataPrepopulate['workingDays'].fri.holiday

															this.satStartTimeModel = merchantDataPrepopulate['workingDays'].sat.startTime
															this.satEndTimeModel = merchantDataPrepopulate['workingDays'].sat.endTime
															this.satHolidayModel = merchantDataPrepopulate['workingDays'].sat.holiday

															this.sunStartTimeModel = merchantDataPrepopulate['workingDays'].sun.startTime
															this.sunEndTimeModel = merchantDataPrepopulate['workingDays'].sun.endTime
															this.sunHolidayModel = merchantDataPrepopulate['workingDays'].sun.holiday

															this.minimumOrderValue = merchantDataPrepopulate['minimumOrderValue']
															this.aboutUs = merchantDataPrepopulate['aboutUs']
															this.aboutUsMar = merchantDataPrepopulate ['aboutUsMar']
															this.permanantAddress = merchantDataPrepopulate['companyPAN']
															this.fssCertificate = merchantDataPrepopulate['FSSCertificate']
															this.gstNumber = merchantDataPrepopulate['GSTNumber']

															this.propertyFileAsResponse = merchantDataPrepopulate['intellectualProperty']
															this.certificateFileAsResponse = merchantDataPrepopulate['registrationCertificate']
															this.PanFileAsResponse = merchantDataPrepopulate['panCardDocument']
															this.GstFileAsResponse = merchantDataPrepopulate['gstDocument']
															this.FssFileAsResponse = merchantDataPrepopulate['fssCertificateDocument']
															this.ShopActFileAsResponse = merchantDataPrepopulate['shopActLicenseDocument']
															this.UdyogAadharFileAsResponse = merchantDataPrepopulate['udyogAadharDocument']
															this.selectedCategory = this.categoryNames.filter((item: any) => item.en === merchantDataPrepopulate['category'])[0]

															this.geoCoder.geocode({ 'location': { lat: this.latModel, lng: this.lngModel } }, (results, status) => {
																
																this.searchAddress = results[0].formatted_address
															});
														}

													});
												this.isInEditView = true;

											} else if (code === 500) {
												this.store.select(fromStore.getMerchantByIdSuccessMessage)
													.subscribe(errorMessages => {
														this.dataNotFoundMsg = errorMessages;
													});
											}
											else {
												this.store.select(fromStore.getErrorMessages)
													.subscribe(errorOtherMessages => {
														console.error(errorOtherMessages[this.selectedLanguage].code);
													});
											}
										}
									});
							}
						});

				}
			}

		});
		// ====================================End of Prepopulating merchant data in the form ====================================================






	}



	getCategoryNames() {
		const method = 'get';
		const url = this.apiDetails['user'].base_url + this.apiDetails['user'].getCategoryName.url;
		const body = {};

		this.apiCallService.apiCall(method, url, {}).subscribe(
			(response: any) => {
				
				this.categoryNames = response.result;
			},
			(error: any) => {
				console.error(error);
			}
		);
	}



	syncStartTimeForAllDays = true;
	syncEndTimeForAllDays = true;

	setAllDaysStartTime(startTime: string) {
		this.sunStartTimeModel = startTime;
		this.monStartTimeModel = startTime;
		this.tueStartTimeModel = startTime;
		this.wedStartTimeModel = startTime;
		this.thuStartTimeModel = startTime;
		this.friStartTimeModel = startTime;
		this.satStartTimeModel = startTime;
	}

	setAllDaysEndTime(endTime: string) {
		this.sunEndTimeModel = endTime;
		this.monEndTimeModel = endTime;
		this.tueEndTimeModel = endTime;
		this.wedEndTimeModel = endTime;
		this.thuEndTimeModel = endTime;
		this.friEndTimeModel = endTime;
		this.satEndTimeModel = endTime;
	}

	updateStartTime(day: string, startTime: string) {
		if (this.syncStartTimeForAllDays) {
			this.syncStartTimeForAllDays = false;
			this.setAllDaysStartTime(startTime);
		} else {
			switch (day) {
				case 'sun':
					this.sunStartTimeModel = startTime;
					break;
				case 'mon':
					this.monStartTimeModel = startTime;
					break;
				case 'tue':
					this.tueStartTimeModel = startTime;
					break;
				case 'wed':
					this.wedStartTimeModel = startTime;
					break;
				case 'thu':
					this.thuStartTimeModel = startTime;
					break;
				case 'fri':
					this.friStartTimeModel = startTime;
					break;
				case 'sat':
					this.satStartTimeModel = startTime;
					break;
			}
		}
	}

	updateEndTime(day: string, endTime: string) {
		if (this.syncEndTimeForAllDays) {
			this.syncEndTimeForAllDays = false;
			this.setAllDaysEndTime(endTime);
		} else {
			switch (day) {
				case 'sun':
					this.sunEndTimeModel = endTime;
					break;
				case 'mon':
					this.monEndTimeModel = endTime;
					break;
				case 'tue':
					this.tueEndTimeModel = endTime;
					break;
				case 'wed':
					this.wedEndTimeModel = endTime;
					break;
				case 'thu':
					this.thuEndTimeModel = endTime;
					break;
				case 'fri':
					this.friEndTimeModel = endTime;
					break;
				case 'sat':
					this.satEndTimeModel = endTime;
					break;
			}
		}
	}



	// for downloading pan card document uploaded by user
	downloadPanDocument() {
		if (this.PanFileAsResponse) {
			window.open(this.PanFileAsResponse)
		}
	}

	// for downloading leagal metorology document uploaded by user
	downloadLeagalMetrlgDocument() {
		if (this.certificateFileAsResponse) {
			window.open(this.certificateFileAsResponse)
		}
	}

	// for downloading intelactual property document uploaded by user
	downloadIntelActualDocument() {
		if (this.propertyFileAsResponse) {
			window.open(this.propertyFileAsResponse)
		}
	}

	// for downloading GST document uploaded by user
	downloadGSTDocument() {
		if (this.GstFileAsResponse) {
			window.open(this.GstFileAsResponse)
		}
	}

	// for downloading FSS Certificate document uploaded by user
	downloadFSSDocument() {
		if (this.FssFileAsResponse) {
			window.open(this.FssFileAsResponse)
		}
	}

	// for downloading Shop Act document uploaded by user
	downloadShopActDocument() {
		if (this.ShopActFileAsResponse) {
			window.open(this.ShopActFileAsResponse)
		}
	}

	// for downloading Udyog aadhar document uploaded by user
	downloadUdyogAadharDocument() {
		if (this.UdyogAadharFileAsResponse) {
			window.open(this.UdyogAadharFileAsResponse)
		}
	}


	// To enable the input fields in form 
	enableAllInputBox() {
		this.isInEditView = !this.isInEditView;
	}


	// Get Current Location Coordinates
	private setCurrentLocation() {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.latModel = position.coords.latitude;
				this.lngModel = position.coords.longitude;
				this.getAddress(this.latModel, this.lngModel);
			});
		}
	}

	// when marker on the map is dragging 
	// markerDragEnd($event: MouseEvent) {
	
	// 	this.latModel = $event.coords.lat;
	// 	this.lngModel = $event.coords.lng;
	// 	this.getAddress(this.latModel, this.lngModel);
	// }
	markerDragEnd($event: google.maps.KmlMouseEvent) { // Use native google.maps.MouseEvent

		this.latModel = $event.latLng.lat();
		this.lngModel = $event.latLng.lng();
		this.getAddress(this.latModel, this.lngModel);
	  }
	// To get the location after search with address
	getAddress(latitude, longitude) {
		this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
		
			if (status === 'OK') {
				if (results[0]) {
					//   this.address = results[0].formatted_address;
				} else {
					window.alert('No results found');
				}
			} else {
				window.alert('Geocoder failed due to: ' + status);
			}

		});
	}

	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		this.isBlind = this.localStorageService.getIsBlind();
		if (this.isBlind == 'true') {
			let speech = new SpeechSynthesisUtterance();
			speech.lang = "hi-IN";
			speech.text = textToSpeak;
			speech.volume = 1;
			speech.rate = 1;
			speech.pitch = 1;
			speechSynthesis.speak(speech);
		}
	}

	/**
   * Created by Vinita Sharma
   * Stops speaking when mouse leave the text content
   */
	stopSpeaking() {
		speechSynthesis.cancel();
	}



	/**
	 * Created By Akanksha J
	 * For getting BrandId from BrandName
	 */
	onBrandNameChanged(_i) {
		for (let i = 0; i < this.brandList.length; i++) {
			if (_i === i) {
				this.brandId = this.brandList[i]['brandId'];
			}
		}
	}

	/**
	 * Created  by Akanksha J
	 * For detecting the checbox is checked or not
	 * Date: 25/01/2020
	 */
	onChkChange() {
		this.isClicked = !this.isClicked;
		if (!this.isClicked) {
			this.brandId = null;
		}
	}

	autofillHeadOffice() {
		this.isSameAsHeadOffice = !this.isSameAsHeadOffice
		

		if (this.isSameAsHeadOffice) {
			this.outletOfficeAddress = this.headOfficeAddress
			this.outletOfficeCity = this.headOfficeCity
			this.outletOfficeMobileNo = this.headOfficeMobileNo
			this.outletOfficePincode = this.headOfficePincode
			this.outletOfficeState = this.headOfficeState
		} else {
			this.outletOfficeAddress = ''
			this.outletOfficeCity = ''
			this.outletOfficeMobileNo = 0
			this.outletOfficePincode = 0
			this.outletOfficeState = ''
		}

	}

	autofillOwnerDetails() {
		this.isSameAsOwnerDetails = !this.isSameAsOwnerDetails
		

		if (this.isSameAsOwnerDetails) {
			this.contactPersonFirstName = this.ownerFirstName
			this.contactPersonMiddleName = this.ownerMiddleName
			this.contactPersonLastName = this.ownerLastName
			this.contactPersonTelNo = this.ownerTelNo
			this.contactPersonMobileNo = this.ownerMobileNumber
			this.contactPersonEmailId = this.ownerEmailId
		} else {
			this.contactPersonFirstName = ''
			this.contactPersonMiddleName = ''
			this.contactPersonLastName = ''
			this.contactPersonTelNo = 0
			this.contactPersonMobileNo = 0
			this.contactPersonEmailId = ''
		}
	}

	onTandCChkChange() {
		this.isTCClicked = !this.isTCClicked;
	

	}


	// Terms and conditions link
	openTermsAndConditions() {
		// this.dialog.open(MerchantTermsAndConditionsComponent);
		// this.router.navigate(['/cep/merchant-terms-and-condition'])
		window.open('/cep/merchant-terms-and-condition')
	}

	/**
	 * Created by Akanksha J
	 * For fetching file input
	 * Date: 23/01/2020
	 */
	onSelectOfCertificateFile(event) {
		if (event.length === 0) {
			return;
		} else {
			const file = event.target.files[0];
			if (file.type !== 'application/pdf') {
				this.toastr.error('Please upload a PDF file.');
				this.selectedLanguage === 'en' ? alert("Please only upload pdf") : alert("कृपया केवळ pdf अपलोड करा");
				event.target.value = '';
				return;
			}
			const fd = new FormData();
			fd.append('userId', this.userId);
			fd.append('type', 'Certificate');
			fd.append('image', event.target.files[0]);
			fd.append('link', '');
			if (fd) {
				this.store.dispatch(new fromStore.UpdateProfilePhoto(
					{
						url: this.apiDetails.objectStorage.base_url +
							this.apiDetails.objectStorage.storeObject.url,
						method: this.apiDetails.objectStorage.storeObject.method,
						body: fd,
						option: 'certificateFileUpload',
						type: 'merchantDoc'
					}));
			}
			this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].itIsUploadingDocumentPleaseWaitToUploadDocument);
			this.store.select(fromStore.isUpdateProfilePhotoLoading)
				.subscribe(isLoading => {
					this.isLoadingCertificate = isLoading;
				});
		}
	}

	/**
	 * Created by Akanksha J
	 * For fetching file input
	 * Date: 24/01/2020
	 */
	onSelectOfPropertyFile(event) {
		if (event.length === 0) {
			return;
		} else {
			const file = event.target.files[0];
			if (file.type !== 'application/pdf') {
				this.toastr.error('Please upload a PDF file.');
				this.selectedLanguage === 'en' ? alert("Please only upload pdf") : alert("कृपया केवळ pdf अपलोड करा");
				event.target.value = '';
				return;
			}
			const fd = new FormData();
			fd.append('userId', this.userId);
			fd.append('type', 'Property');
			fd.append('image', event.target.files[0]);
			fd.append('link', '');
			if (fd) {
				this.store.dispatch(new fromStore.UpdateProfilePhoto(
					{
						url: this.apiDetails.objectStorage.base_url +
							this.apiDetails.objectStorage.storeObject.url,
						method: this.apiDetails.objectStorage.storeObject.method,
						body: fd,
						option: 'propertyFileUpload',
						type: 'merchantDoc'
					}));

			}
			this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].itIsUploadingDocumentPleaseWaitToUploadDocument);
			this.store.select(fromStore.isUpdateProfilePhotoLoading)
				.subscribe(isLoading => {
					this.isLoadingProperty = isLoading;
				});
		}
	}

	/**
 * Created by Neha R
 * For fetching file input
 * Date: 14/07/2020
 */
	onSelectOfGstFile(event) {
		if (event.length === 0) {
			return;
		} else {
			const file = event.target.files[0];
			if (file.type !== 'application/pdf') {
				this.toastr.error('Please upload a PDF file.');
				this.selectedLanguage === 'en' ? alert("Please only upload pdf") : alert("कृपया केवळ pdf अपलोड करा");
				event.target.value = ''
				return;
			}
			const fd = new FormData();
			fd.append('userId', this.userId);
			fd.append('type', 'Gst');
			fd.append('image', event.target.files[0]);
			fd.append('link', '');

			if (fd) {
				this.store.dispatch(new fromStore.UpdateProfilePhoto(
					{
						url: this.apiDetails.objectStorage.base_url +
							this.apiDetails.objectStorage.storeObject.url,
						method: this.apiDetails.objectStorage.storeObject.method,
						body: fd,
						option: 'gstFileUpload',
						type: 'merchantDoc'
					}));

			}
			this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].itIsUploadingDocumentPleaseWaitToUploadDocument);
			this.store.select(fromStore.isUpdateProfilePhotoGstLoading)
				.subscribe(isLoading => {
					this.isLoadingGst = isLoading;
				});
		}
	}
	/**
 * Created by Neha R
 * For fetching file input
 * Date: 14/07/2020
 */
	onSelectOfFssFile(event) {
		if (event.length === 0) {
			return;
		} else {
			const file = event.target.files[0];
			if (file.type !== 'application/pdf') {
				this.toastr.error('Please upload a PDF file.');
				this.selectedLanguage === 'en' ? alert("Please only upload pdf") : alert("कृपया केवळ pdf अपलोड करा");
				event.target.value = '';
				return;
			}
			const fd = new FormData();
			fd.append('userId', this.userId);
			fd.append('type', 'Fss');
			fd.append('image', event.target.files[0]);
			fd.append('link', '');

			if (fd) {
				this.store.dispatch(new fromStore.UpdateProfilePhoto(
					{
						url: this.apiDetails.objectStorage.base_url +
							this.apiDetails.objectStorage.storeObject.url,
						method: this.apiDetails.objectStorage.storeObject.method,
						body: fd,
						option: 'fssFileUpload',
						type: 'merchantDoc'
					}));

			}
			this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].itIsUploadingDocumentPleaseWaitToUploadDocument);
			this.store.select(fromStore.isUpdateProfilePhotoFssLoading)
				.subscribe(isLoading => {
					this.isLoadingFss = isLoading;
				});
		}
	}
	/**
 * Created by Neha R
 * For fetching file input
 * Date: 14/07/2020
 */
	onSelectOfPanFile(event) {
		if (event.length === 0) {
			return;
		} else {
			const file = event.target.files[0];
			if (file.type !== 'application/pdf') {
				this.toastr.error('Please upload a PDF file.');
				this.selectedLanguage === 'en' ? alert("Please only upload pdf") : alert("कृपया केवळ pdf अपलोड करा")
				event.target.value = '';
				return;
			}
			const fd = new FormData();
			fd.append('userId', this.userId);
			fd.append('type', 'Pan');
			fd.append('image', event.target.files[0]);
			fd.append('link', '');

			if (fd) {
				this.store.dispatch(new fromStore.UpdateProfilePhoto(
					{
						url: this.apiDetails.objectStorage.base_url +
							this.apiDetails.objectStorage.storeObject.url,
						method: this.apiDetails.objectStorage.storeObject.method,
						body: fd,
						option: 'panFileUpload',
						type: 'merchantDoc'
					}));

			}
			this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].itIsUploadingDocumentPleaseWaitToUploadDocument);
			this.store.select(fromStore.isUpdateProfilePhotoPanLoading)
				.subscribe(isPanLoading => {
					this.isLoadingPan = isPanLoading;
				});
		}
	}
	/**
 * Created by Neha R
 * For fetching file input
 * Date: 14/07/2020
 */
	onSelectOfShopActFile(event) {
		const file = event.target.files[0];
		// console.log("file type",file.type);
		if (!file) {
			return;
		} else {
			
			if (file.type !== 'application/pdf') {
				this.toastr.error('Please upload a PDF file.');
				this.selectedLanguage === 'en' ? alert("Please only upload pdf") : alert("कृपया केवळ pdf अपलोड करा");
				event.target.value = '';
				return;
			}
			const fd = new FormData();
			fd.append('userId', this.userId);
			fd.append('type', 'Shop Act');
			fd.append('image', file);
			fd.append('link', '');

			if (fd) {
				this.store.dispatch(new fromStore.UpdateProfilePhoto(
					{
						url: this.apiDetails.objectStorage.base_url +
							this.apiDetails.objectStorage.storeObject.url,
						method: this.apiDetails.objectStorage.storeObject.method,
						body: fd,
						option: 'shopActFileUpload',
						type: 'merchantDoc'
					}));

			}
			this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].itIsUploadingDocumentPleaseWaitToUploadDocument);
			this.store.select(fromStore.isUpdateProfilePhotoShopActLoading)
				.subscribe(isLoading => {
					this.isLoadingShopAct = isLoading;
				});
		}
	}
	/**
	 * Created By neha r
	 * for fetching document
	 */
	onSelectOfUdyogAadharFile(event) {
		if (event.length === 0) {
			return;
		} else {
			const file = event.target.files[0];
			if (file.type !== 'application/pdf') {
				this.toastr.error('Please upload a PDF file.');
				this.selectedLanguage === 'en' ? alert("Please only upload pdf") : alert("कृपया केवळ pdf अपलोड करा");
				event.target.value = '';
				return;
			}
			const fd = new FormData();
			fd.append('userId', this.userId);
			fd.append('type', 'Udyog Aadhar');
			fd.append('image', event.target.files[0]);
			fd.append('link', '');

			if (fd) {
				this.store.dispatch(new fromStore.UpdateProfilePhoto(
					{
						url: this.apiDetails.objectStorage.base_url +
							this.apiDetails.objectStorage.storeObject.url,
						method: this.apiDetails.objectStorage.storeObject.method,
						body: fd,
						option: 'udyogAadharFileUpload',
						type: 'merchantDoc'
					}));

			}
			this.toastr.success(this.merchantAndBrandLabels[this.selectedLanguage].itIsUploadingDocumentPleaseWaitToUploadDocument);
			this.store.select(fromStore.isUpdateProfilePhotoUdyogAadharLoading)
				.subscribe(isLoading => {
					this.isLoadingUdyogAadhar = isLoading;
				});
		}
	}
	/**
	 * Created by Akshata C
	 * For removing image from array
	 */
	removeImage(i) {
		this.urls.splice(i, 1);
		this.imageUrl = this.urls;
	}

	/**
	 * Created by Akshata C
	 * For removing image from array
	 */
	removeImage1(i) {
		this.urls1.splice(i, 1);
		this.imageUrl1 = this.urls1;
	}



	HolidayStatus(e, day) {
		switch (day) {
			case 'mon':
				if (e.checked) {
					this.monStartTimeModel = ""
					this.monEndTimeModel = ""
				} else {
					this.monStartTimeModel = this.merchantDataStorage['workingDays'].mon.startTime
					this.monEndTimeModel = this.merchantDataStorage['workingDays'].mon.endTime
				}
				break;
			case 'tue':
				if (e.checked) {
					this.tueStartTimeModel = ""
					this.tueEndTimeModel = ""
				} else {
					this.tueStartTimeModel = this.merchantDataStorage['workingDays'].tue.startTime
					this.tueEndTimeModel = this.merchantDataStorage['workingDays'].tue.endTime
				}
				break;
			case 'wed':
				if (e.checked) {
					this.wedStartTimeModel = ""
					this.wedEndTimeModel = ""
				} else {
					this.wedStartTimeModel = this.merchantDataStorage['workingDays'].wed.startTime
					this.wedEndTimeModel = this.merchantDataStorage['workingDays'].wed.endTime
				}
				break;

			case 'thu':

				if (e.checked) {
					this.thuStartTimeModel = ""
					this.thuEndTimeModel = ""
				} else {
					this.thuStartTimeModel = this.merchantDataStorage['workingDays'].thu.startTime
					this.thuEndTimeModel = this.merchantDataStorage['workingDays'].thu.endTime
				}
				break;
			case 'fri':

				if (e.checked) {
					this.friStartTimeModel = ""
					this.friEndTimeModel = ""
				} else {
					this.friStartTimeModel = this.merchantDataStorage['workingDays'].fri.startTime
					this.friEndTimeModel = this.merchantDataStorage['workingDays'].fri.endTime
				}
				break;

			case 'sat':

				if (e.checked) {
					this.satStartTimeModel = ""
					this.satEndTimeModel = ""
				} else {
					this.satStartTimeModel = this.merchantDataStorage['workingDays'].sat.startTime
					this.satEndTimeModel = this.merchantDataStorage['workingDays'].sat.endTime
				}
				break;
			case 'sun':

				if (e.checked) {
					this.sunStartTimeModel = ""
					this.sunEndTimeModel = ""
				} else {
					this.sunStartTimeModel = this.merchantDataStorage['workingDays'].sun.startTime
					this.sunEndTimeModel = this.merchantDataStorage['workingDays'].sun.endTime
				}
				break;

			default:
				break;
		}
	}

	uncheckHoliday(day) {
	

		switch (day) {
			case 'mon':
				this.monHolidayModel = false


				break;

			case 'tue':
				this.tueHolidayModel = false
			

				break;

			case 'wed':
				this.wedHolidayModel = false
			

				break;

			case 'thu':
				this.thuHolidayModel = false
	

				break;

			case 'fri':
				this.friHolidayModel = false
				

				break;

			case 'sat':
				this.satHolidayModel = false
	

				break;

			case 'sun':
				this.sunHolidayModel = false
		

				break;

			default:
				break;
		}

	}






	/**
	 * Created by Akanksha J
	 * For registering user as merchant
	 * Date: 24/01/2020
	 */
	registreAsMerchant() {
		if (Object.keys(this.apiDetails).length > 0) {

			const payload = {
				updatedBy: this.userName,
				userId: this.userId,
				geometry: {
					type: "point",
					coordinates: [
						this.latModel,
						this.lngModel
					]
				},
				outlets:
				{
					brandId: this.brandId,
					outletOfficeAddress: this.outletOfficeAddress,
					outletOfficeCity: this.outletOfficeCity,
					outletOfficePinCode: this.outletOfficePincode,
					outletOfficeState: this.outletOfficeState,
					outletOfficeMobileNumber: this.outletOfficeMobileNo,
					dayAndTime: [
						{ sun: '0', time: 'close' },
						{ mon: '1', time: '10am-8pm' },
						{ tue: '1', time: '10am-8pm' },
						{ wed: '1', time: '10am-8pm' },
						{ thus: '1', time: '10am-8pm' },
						{ fri: '1', time: '10am-8pm' },
						{ sat: '1', time: '10am-8pm' }
					]
				},

				workingDays: {
					sun: {
						startTime: this.sunStartTimeModel,
						endTime: this.sunEndTimeModel,
						holiday: this.sunHolidayModel
					},
					mon: {
						startTime: this.monStartTimeModel,
						endTime: this.monEndTimeModel,
						holiday: this.monHolidayModel
					},
					tue: {
						startTime: this.tueStartTimeModel,
						endTime: this.tueEndTimeModel,
						holiday: this.tueHolidayModel
					},
					wed: {
						startTime: this.wedStartTimeModel,
						endTime: this.wedEndTimeModel,
						holiday: this.wedHolidayModel
					},
					thus: {
						startTime: this.thuStartTimeModel,
						endTime: this.thuEndTimeModel,
						holiday: this.thuHolidayModel
					},
					fri: {
						startTime: this.friStartTimeModel,
						endTime: this.friEndTimeModel,
						holiday: this.friHolidayModel
					},
					sat: {
						startTime: this.satStartTimeModel,
						endTime: this.satEndTimeModel,
						holiday: this.satHolidayModel
					}
				},

				registerCompanyName: this.companyName,
				displayCompanyName: this.displayName,
				registerCompanyNameMar: this.companyNameMar,
				displayCompanyNameMar: this.displayNameMar,
				companyType: this.companyType,
				headOfficeAddress: this.headOfficeAddress,
				headOfficeCity: this.headOfficeCity,
				headOfficePinCode: this.headOfficePincode,
				headOfficeState: this.headOfficeState,
				headOfficeMobileNumber: this.headOfficeMobileNo,
				ownerFirstName: this.ownerFirstName,
				ownerMiddleName: this.ownerMiddleName,
				ownerLastName: this.ownerLastName,
				ownerMobileNumber: this.ownerMobileNumber,
				ownerTelephoneNumber: this.ownerTelNo,
				ownerEmailId: this.ownerEmailId,
				contactPersonFirstName: this.contactPersonFirstName,
				contactPersonMiddleName: this.contactPersonMiddleName,
				contactPersonLastName: this.contactPersonLastName,
				contactPersonDesignation: this.contactPersonDesignation,
				contactPersonMobileNumber: this.contactPersonMobileNo,
				contactPersonTelephoneNumber: this.contactPersonTelNo,
				contactPersonEmailId: this.contactPersonEmailId,
				// operatingDays: this.operatingDays,
				// operatingHours: this.operatingHrs,
				// openWeekDay:this.openWeekDay,
				// openWeekTiming:this.openWeekTiming,
				// otherWeekDay:this.otherWeekDays,
				// otherWeekTiming:this.otherWeekTiming,
				aboutUs: this.aboutUs,
				aboutUsMar:this.aboutUsMar,
				minimumOrderValue: this.minimumOrderValue,
				companyPAN: this.permanantAddress,
				GSTNumber: this.gstNumber,
				FSSCertificate: this.fssCertificate,
				registrationCertificate: this.certificateFileAsResponse,
				intellectualProperty: this.propertyFileAsResponse,
				logo: this.imageUrl,
				bannerImages: this.imageUrl1,
				panCardDocument: this.PanFileAsResponse,
				gstDocument: this.GstFileAsResponse,
				fssCertificateDocument: this.FssFileAsResponse,
				shopActLicenseDocument: this.ShopActFileAsResponse,
				udyogAadharDocument: this.UdyogAadharFileAsResponse,
				isSameAsOwnerDetails: this.isSameAsOwnerDetails,
				isSameAsHeadOffice: this.isSameAsHeadOffice,
				category: this.selectedCategory.en,
			};
			// console.log("payload",payload)
			this.store.dispatch(new fromStore.RegisterAsMerchant({
				url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].saveDetails.url,
				method: this.apiDetails['user'].saveDetails.method,
				body: {
					updatedBy: this.userName,
					userId: this.userId,
					geometry: {
						type: "point",
						coordinates: [
							this.latModel,
							this.lngModel
						]
					},
					outlets:
					{
						brandId: this.brandId,
						outletOfficeAddress: this.outletOfficeAddress,
						outletOfficeCity: this.outletOfficeCity,
						outletOfficePinCode: this.outletOfficePincode,
						outletOfficeState: this.outletOfficeState,
						outletOfficeMobileNumber: this.outletOfficeMobileNo,
						dayAndTime: [
							{ sun: '0', time: 'close' },
							{ mon: '1', time: '10am-8pm' },
							{ tue: '1', time: '10am-8pm' },
							{ wed: '1', time: '10am-8pm' },
							{ thus: '1', time: '10am-8pm' },
							{ fri: '1', time: '10am-8pm' },
							{ sat: '1', time: '10am-8pm' }
						]
					},

					workingDays: {
						sun: {
							startTime: this.sunStartTimeModel,
							endTime: this.sunEndTimeModel,
							holiday: this.sunHolidayModel
						},
						mon: {
							startTime: this.monStartTimeModel,
							endTime: this.monEndTimeModel,
							holiday: this.monHolidayModel
						},
						tue: {
							startTime: this.tueStartTimeModel,
							endTime: this.tueEndTimeModel,
							holiday: this.tueHolidayModel
						},
						wed: {
							startTime: this.wedStartTimeModel,
							endTime: this.wedEndTimeModel,
							holiday: this.wedHolidayModel
						},
						thus: {
							startTime: this.thuStartTimeModel,
							endTime: this.thuEndTimeModel,
							holiday: this.thuHolidayModel
						},
						fri: {
							startTime: this.friStartTimeModel,
							endTime: this.friEndTimeModel,
							holiday: this.friHolidayModel
						},
						sat: {
							startTime: this.satStartTimeModel,
							endTime: this.satEndTimeModel,
							holiday: this.satHolidayModel
						}
					},

					registerCompanyName: this.companyName,
					displayCompanyName: this.displayName,
					registerCompanyNameMar: this.companyNameMar,
					displayCompanyNameMar: this.displayNameMar,
					companyType: this.companyType,
					headOfficeAddress: this.headOfficeAddress,
					headOfficeCity: this.headOfficeCity,
					headOfficePinCode: this.headOfficePincode,
					headOfficeState: this.headOfficeState,
					headOfficeMobileNumber: this.headOfficeMobileNo,
					ownerFirstName: this.ownerFirstName,
					ownerMiddleName: this.ownerMiddleName,
					ownerLastName: this.ownerLastName,
					ownerMobileNumber: this.ownerMobileNumber,
					ownerTelephoneNumber: this.ownerTelNo,
					ownerEmailId: this.ownerEmailId,
					contactPersonFirstName: this.contactPersonFirstName,
					contactPersonMiddleName: this.contactPersonMiddleName,
					contactPersonLastName: this.contactPersonLastName,
					contactPersonDesignation: this.contactPersonDesignation,
					contactPersonMobileNumber: this.contactPersonMobileNo,
					contactPersonTelephoneNumber: this.contactPersonTelNo,
					contactPersonEmailId: this.contactPersonEmailId,
					// operatingDays: this.operatingDays,
					// operatingHours: this.operatingHrs,
					// openWeekDay:this.openWeekDay,
					// openWeekTiming:this.openWeekTiming,
					// otherWeekDay:this.otherWeekDays,
					// otherWeekTiming:this.otherWeekTiming,
					aboutUs: this.aboutUs,
					aboutUsMar:this.aboutUsMar,
					minimumOrderValue: this.minimumOrderValue,
					companyPAN: this.permanantAddress,
					GSTNumber: this.gstNumber,
					FSSCertificate: this.fssCertificate,
					registrationCertificate: this.certificateFileAsResponse,
					intellectualProperty: this.propertyFileAsResponse,
					logo: this.imageUrl,
					bannerImages: this.imageUrl1,
					panCardDocument: this.PanFileAsResponse,
					gstDocument: this.GstFileAsResponse,
					fssCertificateDocument: this.FssFileAsResponse,
					shopActLicenseDocument: this.ShopActFileAsResponse,
					udyogAadharDocument: this.UdyogAadharFileAsResponse,
					isSameAsOwnerDetails: this.isSameAsOwnerDetails,
					isSameAsHeadOffice: this.isSameAsHeadOffice,
					category: this.selectedCategory.en,
				}
			}));
		}

		if (this.selectedLanguage) {
			this.store.select(fromStore.getIsRegisterAsMerchantLoaded)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.getRegisterAsMerchantStatusCode)
							.subscribe(statusCode => {
								if (statusCode === 200) {
									this.store.select(fromStore.getRegisterAsMerchantErrorMessage)
										.subscribe(msg => {
											this.store.select(fromStore.getSuccessMessages)
												.subscribe(successMessages => {
													this.toastr.success(successMessages[this.selectedLanguage][msg]);
												});
										});
									this.router.navigate(['/cep/feeds']);
								} else {
									this.store.select(fromStore.getErrorMessages)
										.subscribe(successMessages => {
											console.error(successMessages[this.selectedLanguage][statusCode]);
										});
									return;
								}
							});
					}
				});
		}
	}

	/**
	 * Created by Akanksha J
	 * For registering as brand
	 * Date: 24/01/2020
	 */
	registerAsBrand() {
		if (Object.keys(this.apiDetails).length > 0) {
			this.store.dispatch(new fromStore.RegisterAsMerchant({
				url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].saveBrandDetails.url,
				method: this.apiDetails['user'].saveBrandDetails.method,

				body: {
					updatedBy: this.userName,
					userId: this.userId,
					registerCompanyName: this.companyName,
					displayCompanyName: this.displayName,
					companyType: this.companyType,
					headOfficeAddress: this.headOfficeAddress,
					headOfficeCity: this.headOfficeCity,
					headOfficePinCode: this.headOfficePincode,
					headOfficeState: this.headOfficeState,
					headOfficeMobileNumber: this.headOfficeMobileNo,
					ownerFirstName: this.ownerFirstName,
					ownerMiddleName: this.ownerMiddleName,
					ownerLastName: this.ownerLastName,
					ownerMobileNumber: this.ownerMobileNumber,
					ownerTelephoneNumber: this.ownerTelNo,
					ownerEmailId: this.ownerEmailId,
					contactPersonFirstName: this.contactPersonFirstName,
					contactPersonMiddleName: this.contactPersonMiddleName,
					contactPersonLastName: this.contactPersonLastName,
					contactPersonDesignation: this.contactPersonDesignation,
					contactPersonMobileNumber: this.contactPersonMobileNo,
					contactPersonTelephoneNumber: this.contactPersonTelNo,
					contactPersonEmailId: this.contactPersonEmailId,
					// operatingDays: this.operatingDays,
					// operatingHours: this.operatingHrs,
					// openWeekDay:this.openWeekDay,
					// openWeekTiming:this.openWeekTiming,
					// otherWeekDay:this.otherWeekDays,
					// otherWeekTiming:this.otherWeekTiming,
					aboutUs: this.aboutUs,
					aboutUsMar: this.aboutUsMar,
					minimumOrderValue: this.minimumOrderValue,
					companyPAN: this.permanantAddress,
					GSTNumber: this.gstNumber,
					FSSCertificate: this.fssCertificate,
					registrationCertificate: this.certificateFileAsResponse,
					intellectualProperty: this.propertyFileAsResponse,
					geometry: {
						type: "point",
						coordinates: [
							this.latModel,
							this.lngModel
						]
					}
				}
			}));
		}

		if (this.selectedLanguage) {
			this.store.select(fromStore.getIsRegisterAsBrandLoaded)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.getRegisterAsBrandStatusCode)
							.subscribe(statusCode => {
								if (statusCode === 200) {
									this.store.select(fromStore.getRegisterAsBrandErrorMessage)
										.subscribe(msg => {
											this.store.select(fromStore.getSuccessMessages)
												.subscribe(successMessages => {
													this.toastr.success(successMessages[this.selectedLanguage][msg]);
												});
										});
									this.router.navigate(['/cep/feeds']);
								} else {
									this.store.select(fromStore.getErrorMessages)
										.subscribe(successMessages => {
											console.error(successMessages[this.selectedLanguage][statusCode]);
										});
									return;
								}
							});
					}
				});
		}
	}


	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getCompanyNameControlErrorMessage() {
		return this.companyNameControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.companyNameControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.companyNameControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}

	getCompanyNameMarControlErrorMessage() {
		return this.companyNameControlMar.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.companyNameControlMar.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.companyNameControlMar.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getCompanyTypeControlErrorMessage() {
		return this.companyTypeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}

	/**
 * Created by Akanksha J
 * For getting error messages for form control
 */
	getUserIdControlErrorMessage() {
		return this.userIdControl.hasError('required') ? this.successMsg[this.selectedLanguage['requiredMsg']] :
			'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getDisplayNameControlErrorMessage() {
		return this.displayNameControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :

			'';
	}

	getDisplayNameMARControlErrorMessage() {
		return this.displayNameControlMar.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :

			'';
	}

	getSelectedCategoryControlErrorMessage() {
		return this.selectedCategoryControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.selectedCategoryControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.selectedCategoryControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getHeadOfficeAddressControlErrorMessage() {
		return this.headOfficeAddressControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.headOfficeAddressControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getHeadOfficeCityControlErrorMessage() {
		return this.headOfficeCityControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.headOfficeCityControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.headOfficeCityControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getHeadOfficePincodeControlErrorMessage() {
		return this.headOfficePincodeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.headOfficePincodeControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.headOfficePincodeControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getHeadOfficeStateControlErrorMessage() {
		return this.headOfficeStateControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.headOfficeStateControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.headOfficeStateControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}


	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getHeadOfficeMobileNoControlErrorMessage() {
		return this.headOfficeMobileNoControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.headOfficeMobileNoControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.headOfficeMobileNoControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOwnerFirstNameControlErrorMessage() {
		return this.ownerFirstNameControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.ownerFirstNameControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.ownerFirstNameControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOwnerMiddleNameControlErrorMessage() {
		return this.ownerMiddleNameControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
			this.ownerMiddleNameControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOwnerLastNameControlErrorMessage() {
		return this.ownerLastNameControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
			this.ownerLastNameControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOwnerMobileNumberControlErrorMessage() {
		return this.ownerMobileNumberControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.ownerMobileNumberControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.ownerMobileNumberControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOwnerTelNoControlErrorMessage() {
		return this.ownerTelNoControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
			this.ownerTelNoControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOwnerEmailIdControlErrorMessage() {
		return this.ownerEmailIdControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.ownerEmailIdControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.ownerEmailIdControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOutletOfficeAddressControlErrorMessage() {
		return this.outletOfficeAddressControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.outletOfficeAddressControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOutletOfficeCityControlErrorMessage() {
		return this.outletOfficeCityControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.outletOfficeCityControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.outletOfficeCityControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOutletOfficePincodeControlErrorMessage() {
		return this.outletOfficePincodeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.outletOfficePincodeControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.outletOfficePincodeControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOutletOfficeStateControlErrorMessage() {
		return this.outletOfficeStateControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.outletOfficeStateControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.outletOfficeStateControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOutletOfficeMobileNoControlErrorMessage() {
		return this.outletOfficeMobileNoControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.outletOfficeMobileNoControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.outletOfficeMobileNoControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getContactPersonFirstNameControlErrorMessage() {
		return this.contactPersonFirstNameControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.contactPersonFirstNameControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.contactPersonFirstNameControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getContactPersonMiddleNameControlErrorMessage() {
		return this.contactPersonMiddleNameControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.contactPersonMiddleNameControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.contactPersonMiddleNameControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getContactPersonLastNameControlErrorMessage() {
		return this.contactPersonLastNameControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.contactPersonLastNameControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.contactPersonLastNameControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getContactPersonDesignationControlErrorMessage() {
		return this.contactPersonDesignationControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.contactPersonDesignationControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.contactPersonDesignationControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getContactPersonMobileNoControlErrorMessage() {
		return this.contactPersonMobileNoControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.contactPersonMobileNoControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.contactPersonMobileNoControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getContactPersonTelNoControlErrorMessage() {
		return this.contactPersonTelNoControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
			this.contactPersonTelNoControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getContactPersonEmailIdControlErrorMessage() {
		return this.contactPersonEmailIdControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.contactPersonEmailIdControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.contactPersonEmailIdControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOperatingDaysControlErrorMessage() {
		return this.operatingDaysControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.operatingDaysControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getOperatingHrsControlErrorMessage() {
		return this.contactPersonEmailIdControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.contactPersonEmailIdControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}
	/**
		 * Created by Kiran
		 * For getting error messages for form control
		 */
	getOpenWeekDaysControlErrorMessage() {
		return this.openWeekDaysControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.openWeekDaysControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}

	/**
	 * Created by Kiran
	 * For getting error messages for form control
	 */
	getOpenWeekTimingControlErrorMessage() {
		return this.openWeekTimingControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.openWeekTimingControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}

	/**
	 * Created by Kiran
	 * For getting error messages for form control
	 */
	getOtherWeekDaysControlErrorMessage() {
		return this.otherWeekDaysControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.otherWeekDaysControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}

	/**
	 * Created by Kiran
	 * For getting error messages for form control
	 */
	getOtherWeekTimingControlErrorMessage() {
		return this.otherWeekTimingControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.otherWeekTimingControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}

	/**
 * Created by Vinita
 * For getting error messages for form control
 */
	getMonStartTimeControlErrorMessage() {
		return this.monStartTimeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}
	getMonEndTimeControlErrorMessage() {
		return this.monEndTimeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}
	getTueStartTimeControlErrorMessage() {
		return this.tueStartTimeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}
	getTueEndTimeControlErrorMessage() {
		return this.tueEndTimeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}
	getWedStartTimeControlErrorMessage() {
		return this.wedStartTimeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}
	getWedEndTimeControlErrorMessage() {
		return this.wedEndTimeControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}

	/**
	 * Created by Kiran
	 * For getting error messages for form control
	 */
	getAboutUsControlErrorMessage() {
		return this.aboutUsControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}
	getAboutUsControlMarthiErrorMessage() {
		return this.aboutUsControlMarthi.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}


	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getMinimumOrderValueControlErrorMessage() {
		return this.minimumOrderValueControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
			'';
	}
	/** this.minimumOrderValueControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
 * Created by Neha
 * For getting error messages for form control
 */
	getLatitudeControlErrorMessage() {
		return this.latitude.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}
	/**
 * Created by Neha
 * For getting error messages for form control
 */
	getLongitudeControlErrorMessage() {
		return this.longitude.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getPermanantAddressControlErrorMessage() {
		return this.permanantAddressControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.permanantAddressControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getFssCertificateControlErrorMessage() {
		return this.fssCertificateControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
			this.fssCertificateControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
				'';
	}
	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	getGstNumberControlErrorMessage() {
		return this.gstNumberControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
			this.gstNumberControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
				this.gstNumberControl.hasError('pattern') ? this.successMsg[this.selectedLanguage]['invalidMsg'] :
					'';

	}

	/**
	 * Created by Akanksha J
	 * For getting error messages for form control
	 */
	// getGstNumberControlErrorMessage() {
	// 	return this.gstNumberControl.hasError('required') ? this.successMsg[this.selectedLanguage]['requiredMsg'] :
	// 		this.gstNumberControl.hasError('maxLength') ? this.successMsg[this.selectedLanguage]['limitMsg'] :
	// 			'';
	// }

	/**
	 * Created by Akanksha J
	 * Angular method which is used to detect changes on the screen
	 */
	ngAfterContentChecked() { }
	/**
* Angualr method which runs after view initialization
*/
	ngAfterViewInit() {
		window.scroll(0, 0);

	}

}
