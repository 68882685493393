<!DOCTYPE html>
<html>
<head>
<title>Transaction Successful</title>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
<style>
body {
background-color: #f8f9fa;
}
.container {
text-align: center;
padding-top: 200px;
padding-bottom: 200px;
}
h1 {
font-size: 48px;
color: #28a745;
}
p {
font-size: 24px;
color: #6c757d;
}
img {
display: block;
margin: 0 auto;
max-width: 100%;
height: auto;
}
</style>
</head>
<body>
<div class="container">
<h1>Transaction Successful</h1>
<img width="50%" src="https://img.freepik.com/free-vector/man-paying-online-receiving-cashback-wallet_88138-692.jpg?w=1060&amp;t=st=1679050124~exp=1679050724~hmac=1c3c0cb5f30d0d824c91836dbb023044d573f46afb1e7757092800a122e82c6d" alt="Transaction Success Image" loading="lazy" alt="..." >
<p>Your transaction has been completed successfully.</p>
</div>
</body>
</html>