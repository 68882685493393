import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as ConstantsAction from '../action/constants.action';
import { ConstantsService } from '../api-services/constants.service';
import { of } from 'rxjs';

@Injectable()

export class ConstantsEffects {
    constructor(
        private actions$: Actions,
        private constantsService: ConstantsService
    ) {
    }

    apiRequestListing$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConstantsAction.GetApiRequestList>(ConstantsAction.GET_API_LIST),
            switchMap(() => {
                return this.constantsService.callApiRequestList().pipe(
                    map(labelData => new ConstantsAction.GetApiRequestListSuccess(labelData)),
                    catchError(error => of(new ConstantsAction.GetApiRequestListFail(error)))
                );
            })
        )
    );

    constantLabelsInBothLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConstantsAction.GetApiRequestListSuccess>(ConstantsAction.GET_API_LIST_SUCCESS),
            switchMap((action) => {
                return this.constantsService.getConstantsLabels(action.payload.result).pipe(
                    map(labelData => new ConstantsAction.GetConstantsLabelSuccess(labelData)),
                    catchError(error => of(new ConstantsAction.GetConstantsLabelFail(error)))
                );
            })
        )
    );

    labelEnableDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConstantsAction.GetLabelEnableDetails>(ConstantsAction.GET_LABEL_ENABLE_DETAILS),
            switchMap((action) => {
                return this.constantsService.getLabelEnableDetails(action.payload).pipe(
                    map(labelData => new ConstantsAction.GetLabelEnableDetailsSuccess(labelData)),
                    catchError(error => of(new ConstantsAction.GetLabelEnableDetailsFail(error)))
                );
            })
        )
    );

    hotFeedback$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConstantsAction.GetHotFeedback>(ConstantsAction.GET_HOT_FEEDBACK),
            switchMap((action) => {
                return this.constantsService.getHotFeedback(action.payload).pipe(
                    map(hotFeedback => new ConstantsAction.GetHotFeedbackSuccess(hotFeedback)),
                    catchError(error => of(new ConstantsAction.GetHotFeedbackFail(error)))
                );
            })
        )
    );

    chatbotKey$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConstantsAction.GetChatbotKey>(ConstantsAction.GET_CHATBOT_KEY),
            switchMap((action) => {
                return this.constantsService.getChatbotKey(action.payload).pipe(
                    map(chatbotKey => new ConstantsAction.GetChatbotKeySuccess(chatbotKey)),
                    catchError(error => of(new ConstantsAction.GetChatbotKeyFail(error)))
                );
            })
        )
    );

    getWeatherStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType<ConstantsAction.GetWeatherStatus>(ConstantsAction.GET_WEATHER_STATUS),
            switchMap((action) => {
                return this.constantsService.getWeatherStatus(action.payload).pipe(
                    map(getWeatherStatus => new ConstantsAction.GetWeatherStatusSuccess(getWeatherStatus)),
                    catchError(error => of(new ConstantsAction.GetWeatherStatusFail(error)))
                );
            })
        )
    );

}
