import { Component, OnInit, ViewChild } from '@angular/core';
import { Images } from 'src/app/shared/config/images-config';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { NgIf, UpperCasePipe } from '@angular/common';


@Component({
    selector: 'app-ocr-verify-otp',
    templateUrl: './ocr-verify-otp.component.html',
    styleUrls: ['./ocr-verify-otp.component.scss'],
    standalone: true,
    imports: [NgIf, MatInput, FormsModule, MatButton, NgOtpInputModule, UpperCasePipe]
})
export class OcrVerifyOtpComponent implements OnInit {
	images = Images
	config = {
		allowNumbersOnly: true,
		length: 4,
		inputStyles: {
			width: '40px',
			height: '40px',
			border: '1px solid grey',
			'border-radius': '5px',
			'font-weight': 'lighter',
			'font-size': '20px',
			// 'margin-right': '12px',
			// 'margin-left': '12px'
		},

	};
	selectedLanguage: string;
	otpLabels: {};
	mobileScreenLabels:{};
	sendOTPDOne = false;
	otpLength: any;
	otpInput: string = '';
	display: any;
	ishow: boolean = false;
	mobileNumber: string = '';
	apiDetails: any;
	errMessage: any;
	successMessages: any;
	statusCode: any;
	errorMessage: any;
	drawerLabels: any;
	showSelectedLanguage:any;

	@ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
	constructor(private dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private aRoute: ActivatedRoute,
		private toastr: ToastrService,
		private apiCallService: ApiCallService,
		private router: Router,
		private titleService: Title
	) { }

	ngOnInit() {
		// this.getParams()
		this.titleService.setTitle('PMC CARE: OCR OTP Page');
		this.store.select(fromStore.getSuccessMessages)
			.subscribe(res => {
				this.successMessages = res;
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				if (this.selectedLanguage) {
					this.store.select(fromStore.getDrawerLabels)
						.subscribe((labels) => {
							this.drawerLabels = labels;
						});
					this.store.select(fromStore.getOtpScreenLabels)
						.subscribe((labels) => {
							this.otpLabels = labels;
						});
						this.store.select(fromStore.getMobileScreenLabels)
						.subscribe((labels) => {
							this.mobileScreenLabels = labels;
						});
				}

				if (this.selectedLanguage === 'mar') {
					this.showSelectedLanguage = 'English';
				} else {
					this.showSelectedLanguage = 'मराठी';
				}
			});
			this.store
			.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
			  this.apiDetails=apiDetails;
			});

		this.store.select(fromStore.isSendOtpLoaded)
			.subscribe(loaded => {
				// this.isLoaded = loaded;
				if (loaded) {
					this.store.select(fromStore.sendOtpSuccessCode)
						.subscribe(code => {
							if (code === 200) {
								this.store.select(fromStore.sendOtpErrorMessage)
									.subscribe(res => {
										if (res) {
											this.store.select(fromStore.getSuccessMessages)
												.subscribe(Res => {

													if (this.successMessages) {
														this.toastr.success(this.successMessages[this.selectedLanguage][res]);
													}
													Res = null;
												});
										}
										res = null;
									});
								this.otpLength = null;
								this.otpInput = ''
								// this.reset()
							}
						});
				}
			});

		this.store.select(fromStore.isverifyOtpLoaded)
			.subscribe((isLoaded) => {
				if (isLoaded) {
					this.store.select(fromStore.getVerifyOtpSuccessCode)
						.subscribe((successCode) => {
							this.statusCode = successCode
							if (successCode === 200) {
								this.store.select(fromStore.verifyOtpErrorMessage)
									.subscribe((errMessage) => {
										this.errMessage = errMessage;
										this.store.select(fromStore.getSuccessMessages)
											.subscribe(res => {
												this.successMessages = res;
												if (this.successMessages) {
													this.toastr.success(this.successMessages[this.selectedLanguage].validateVerfCodeForNewMobileNo);
												}
												this.successMessages = null;
												res = null;

												this.apiCallService.apiCall(this.apiDetails['user'].checkpmcuser.method,  this.apiDetails['user'].base_url + this.apiDetails['user'].checkpmcuser.url, {
													mobile: this.mobileNumber
												})
													.subscribe((res: any) => {
														if (res.result.userData !== null) {
															this.router.navigate(['one-click-registration'],{
																queryParams: {
																	emailId:res.result.userData.email,
																	mobile:res.result.userData.mobile,
																	Name:res.result.userData.name
																}
															})
														}else{
															this.selectedLanguage === 'mar' ? this.toastr.success("तुम्ही नोंदणीकृत वापरकर्ता आहात तुम्ही लॉग इन करून सुरुवात करू शकता") : this.toastr.success("You are a registered user.You can start by login In")
															this.store.dispatch( new fromStore.GotoPageOnLogin('/cep/home') )
															this.router.navigate(["/Login/login"])
														}
													})
											});

										this.errMessage = null;
										errMessage = null;
										this.otpInput = ''
										this.reset()
									});

							} else if (this.statusCode === 0) {
								this.errorMessage = null;
							} else if (this.statusCode === 701 || 702 || 717) {
								this.store.select(fromStore.getErrorMessages)
									.subscribe((errMessage) => {
										this.errorMessage = errMessage;
										if (this.errorMessage) {
											this.toastr.error(this.errorMessage[this.selectedLanguage][this.statusCode]);
											this.otpLength = null;
											this.otpInput = ''
											this.errorMessage = null;
											this.reset()
										}
									});
							} else {
								this.selectedLanguage === 'en' ? this.toastr.error("Invalid OTP") :
								this.toastr.error("अवैध OTP")  
							}
						});
				}
			});
	}

	reset() {
		if(this.ngOtpInput !== undefined){
			this.ngOtpInput.setValue('')
		}
	}

	onOtpChange(event) {
		this.otpInput = event;
		this.otpLength = event.length;
	}

	submitOTP() {
		// const dialogRef = this.dialog.open(SuccessDialogComponent, {
		// 	width: '500px',
		// 	height: '300px',

		// });
		if (Object.keys(this.apiDetails).length > 0) {
			this.store.dispatch(new fromStore.VerifyOtp({
				url: this.apiDetails['userauth'].base_url +
					this.apiDetails['userauth'].validate.url,
				method: this.apiDetails['userauth'].validate.method,
				body: {
					mobile: '+91' + this.mobileNumber,
					veriCode: this.otpInput
				}
			}));
		}
		this.reset()

	}

	onChangeLangButton() {
		let language = ''
		if (this.selectedLanguage === 'mar') {
			language = 'en';
		} else {
			language = 'mar';
		}
		// //this.googleAnalyticsService.eventEmitter('Language', 'Change Language to ' + this.selectedLanguage, 'Click Event', 1);
		this.store.dispatch(new fromStore.ChangeLanguage({
			language
		}));
	}

	timer(minute) {
	
		this.ishow = true;
		// let minute = 1;
		let seconds: number = minute * 60;
		let textSec: any = '0';
		let statSec: number = 60;

		const prefix = minute < 10 ? '0' : '';

		const timer = setInterval(() => {
			seconds--;
			if (statSec != 0) statSec--;
			else statSec = 59;

			if (statSec < 10) {
				textSec = '0' + statSec;
			} else textSec = statSec;

			this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

			if (seconds == 0) {
	
				clearInterval(timer);
				this.ishow = false;
			}
		}, 1000);
	}

	sendOTP() {
		// this.dialog.closeAll();
		this.sendOTPDOne = true;
		this.timer(1)
		this.reset()
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.SendOtp({
						// url: this.apiDetails['userauth'].base_url +
						// 	this.apiDetails['userauth'].verification.url,
						url: environment.base_url + '/authenticationConfiguration/v1/sendlogin/otp',
						method: this.apiDetails['userauth'].verification.method,
						body: {
							mobile: '+91' + this.mobileNumber,
						}
					}));
				}
			});
	}

	getParams() {
		this.aRoute.queryParams.subscribe((params:any) => {
			this.mobileNumber = params.mobile;
		
		});
		if (this.mobileNumber !== undefined) {
			this.sendOTP()
		}else{
			this.selectedLanguage === 'en' ? alert("Mobile number not found") : alert("मोबाईल क्रमांक सापडला नाही")
			 this.router.navigate(["/cep/home"])
		}
	}

	numberOnly(event:any): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		  return false;
		}
		return true;
	  }

}
