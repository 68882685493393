<!DOCTYPE html>
<html>
<head>
<title>Transaction Failed</title>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
<style>
body {
background-color: #f8f9fa;
}
.container {
text-align: center;
padding-top: 200px;
padding-bottom: 200px;
}
h1 {
font-size: 48px;
color: #dc3545;
}
p {
font-size: 24px;
color: #6c757d;
}
img {
display: block;
margin: 0 auto;
max-width: 100%;
height: auto;
}
</style>
</head>
<body>
<div class="container">
<h1>Transaction Failed</h1>
<img style="width:50%" src="https://img.freepik.com/free-vector/depressed-sad-man-thinking-financial-problems_74855-5488.jpg?w=1060&t=st=1679049571~exp=1679050171~hmac=c01c8bd16f48c69c83df0b21836a8c14dfa19e364435abb9767c148ff46d8a84" alt="Sorry Image" loading="lazy">
<p>Sorry, your transaction could not be completed at this time. Please try again later.</p>
</div>
</body>
</html>