<!-- /******
 * Component -  Forum card component
 * Created By - Akshata Choulwar
 * Updated By - Akshata Choulwar
 * Updated On - 28/01/2020
 */ -->
<div class="feed-card" (click)="onCardClick()">
    <!-- image of card -->
    <div class="feed-image" 
    (click)="onClick()"
    >
    <!-- <img mat-card-image src="{{image.forumImg}}" loading="lazy" alt="..."> -->
    <img mat-card-image src="{{forumData.imageContent[0]}}" loading="lazy" alt="...">
     </div>
    
    <!-- main content -->
    <div class="feed-content">
        <div class="post-tag-container" 
        (mouseenter)="speakText(landingLables[selectedLanguage].forum)" (mouseleave)="stopSpeaking()"
        >
            <div class="feed-tag" >
                <p>{{landingLables[selectedLanguage].forum | uppercase}}</p>
            </div>
        </div>
        <!-- post heading -->
        <div class="feed-heading font-card-heading" 
        (click)="onClick()"
         
         (mouseenter)="speakText(forumData[selectedLanguage].question)" (mouseleave)="stopSpeaking()"
         >
            <p>{{forumData[selectedLanguage].question}}</p>
        </div>
        <!-- short desc -->
        <div class="feed-desc"
        (click)="onClick()"
        
        (mouseenter)="speakText(extractContent(forumData[selectedLanguage].content))" (mouseleave)="stopSpeaking()"
        >
            <p class="font-card-desc">{{extractContent(forumData[selectedLanguage].content)}}</p>
        </div>
        <!-- post footer -->
        <!-- <div class="event-date-label">
      <p>{{forumData[selectedLanguage].location}}</p>
    </div> -->
        <!-- <div class="post-footer">
      <div class="post-date">
        <p>{{forumStartDate}} - {{forumEndtDate}}</p>
      </div>
    </div> -->
        <!-- action logo -->
        <div class="news-footer">
            <div class="feed-date" (mouseenter)="speakText('forum posted by' + forumData.createdBy)" (mouseleave)="stopSpeaking()">
                {{postDate}}                
            </div>
            <div class="post-action-logo" [ngClass]="{'login-active-favourite': !this.userId}">
                <div class="abuse-flag-container">
                    <!-- <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()"  (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)" (mouseleave)="stopSpeaking()">flag</mat-icon> -->
                </div>
               
                <div class="share-logo"  *ngIf="this.userId" (click)="clickOnShare()">
                  <i class="material-icons"  matTooltip="{{landingLables[selectedLanguage].share}}"  (mouseenter)="speakText(landingLables[selectedLanguage].share)" (mouseleave)="stopSpeaking()">
            share
          </i></div>
                <!-- <mat-menu #menu="matMenu">
                    <button mat-menu-item class="button"  (click)="generateShareLink('facebook')"  (mouseenter)="speakText('share on facebook')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.facebookShareImg}}">
            Facebook
          </button>
                    <button mat-menu-item class="button" (click)="generateShareLink('twitter')"  (mouseenter)="speakText('share on twitter')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.twitterShareImg}}">
            Twitter
          </button>
                    <button mat-menu-item class="button"  (click)="generateShareLink('linkedin')" (mouseenter)="speakText('share on LinkedIn')" (mouseleave)="stopSpeaking()">
            <img class="social-share-img" src="{{image.linkedinShareImg}}">
            LinkedIn
          </button>
          <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')" (mouseenter)="speakText('share on Whatsapp')" (mouseleave)="stopSpeaking()"> 
            <img class="social-share-img whatsapp-share" src="{{image.whatsappShareImg}}">
            Whatsapp
          </button>
                </mat-menu> -->
                <!--  <div *ngIf="this.userId"><i class="material-icons favorite-icon"
            [ngClass]="{'active-favorite':forumData.favourite}" (click)="unMarkFavorite()">
            {{favorite}}
          </i></div> -->

          <div *ngIf="this.userId">
            <i
              class="material-icons favorite-icon"
              [ngClass]="{ 'active-favorite': forumData.favourite }"
              matTooltip="{{landingLables[selectedLanguage].addToFavorites}}"
              (click)="unMarkFavorite()"
               (mouseenter)="speakText(landingLables[selectedLanguage].addToFavorites)" (mouseleave)="stopSpeaking()" 
               >  
              {{ favorite }}
               
            </i>
          </div>
            </div>
        </div>
    </div>
</div>