<!--/*******************************
 * Component -  Deal detail page
 * Created By - Neha R
 * Created on - 1/04/2020
 * Updated By - Neha R
 * Updated On - 1/04/2020
 *************************************/-->

<div class='overlap-container'>
    <!-- connection lost condition -->
    <div *ngIf='serverMessage' class='connectionDiv'>
        <img src={{images.connectionLost}} class='connectionLostImg' loading="lazy" alt="...">
        <span>{{serverMessage}}</span> 
    </div>
    <!-- main page loader -->
    <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>
     <!-- data not found error handling -->
     <ng-container *ngIf="dataNotFoundMsg">
        <div class="error-messages" (mouseenter)="speakText(dataNotFoundMsg)"  (mouseleave)="stopSpeaking()">
            <img src="{{images.noDataFound}}" class="no-data-image-favourite" loading="lazy" alt="..." >
        <span class="data-not-found">{{dataNotFoundMsg}}</span>
        </div>
    </ng-container>
     <!-- main page -->
    <div *ngIf='!isLoading && dealDetail' class="post-navigation-merchant font-sub-heading">
        <p matTooltipClass="engagement-tooltip  " matTooltip="{{dealDetail[selectedLanguage].productName}}">
          <span (click)="goBack()" class="feeds-redirection font-card-heading" (mouseenter)="speakText(dealDetailLabels[selectedLanguage].moreservices + dealDetail[selectedLanguage].productName)"  (mouseleave)="stopSpeaking()">
            {{dealDetailLabels[selectedLanguage].dealsListing | uppercase}}
          </span> > {{dealDetail[selectedLanguage].productName}}
        </p>
    </div>
    
     <div *ngIf='!isLoading && dealDetail' class='main-container'>
        <mat-card class="deal-card">
            <div class="deal-detail-page" >
                <div class="deal-all-image">
                    <div class="scroll-image">
                        <div class="image-deal" *ngFor = "let item of dealDetail.image; let i = index"  >
                            <div class="deal-detail-other-image">
                                <img (click)="selectImage(item)"  class= "image-other" src="{{item}}" loading="lazy" alt="...">
                            </div>
                        </div>
                    </div>
                    <div class="deal-detail-image" *ngIf = !value>
                        <img class= "image" src="{{dealDetail.image[0]}}" loading="lazy" alt="...">
                    </div>
                    <div class="deal-detail-image" *ngIf = value>
                        <img class= "image" src={{value}} loading="lazy" alt="...">
                    </div>
                </div>
                <div class="deal-detail-information">
                    <div class="deal-row">
                        <div (click) = "openMerchantDetail()" class="deal-info-heading pointer font-heading" matTooltip="View Merchant Details"
                        (mouseenter)="speakText(dealDetail[selectedLanguage].productName)"  (mouseleave)="stopSpeaking()">{{dealDetail[selectedLanguage].productName}}
                    
                    <div class="offer1" (mouseenter)="speakText(dealDetail[selectedLanguage].description)"  (mouseleave)="stopSpeaking()">
                        <img class="sale-blue" src="../../../../../assets/images/more-services/sale-blue.png" loading="lazy" alt="...">
                        {{dealDetail[selectedLanguage].description}}
                    </div>
                   </div>
                        <!-- share options -->
                        <div class="flex">
                            <div class="abuse-flag-container ">
                                <mat-icon class="abuse-flag pointer"  
                                (mouseenter)="speakText(landingLables[selectedLanguage].reportAbuse)"  (mouseleave)="stopSpeaking()"
                                matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon>
                            </div>
                            <!-- <div (click)="clickOnShare()" class="">
                                <i class="material-icons pointer" [matMenuTriggerFor]="menu" 
                                (mouseenter)="speakText(landingLables[selectedLanguage].share)"  (mouseleave)="stopSpeaking()"
                                matTooltip="{{landingLables[selectedLanguage].share}}">share</i>
                            </div> -->
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')"
                                (mouseenter)="speakText('share on Facebook')"  (mouseleave)="stopSpeaking()">
                                    <img class="social-share-img" src="{{images.facebookShareImg}}" loading="lazy" alt="...">
                                    Facebook
                                </button>
                                <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')"
                                (mouseenter)="speakText('share on Twitter')"  (mouseleave)="stopSpeaking()">
                                
                                    <img class="social-share-img" src="{{images.twitterShareImg}}" loading="lazy" alt="...">
                                    Twitter
                                </button>
                                <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')"
                                (mouseenter)="speakText('share on LinkedIn')"  (mouseleave)="stopSpeaking()">
                                
                                    <img class="social-share-img" src="{{images.linkedinShareImg}}" loading="lazy" alt="...">
                                    LinkedIn
                                </button>
                                <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')"
                                (mouseenter)="speakText('share on Whatsapp')"  (mouseleave)="stopSpeaking()">
                            
                                    <img class="social-share-img whatsapp-share" src="{{images.whatsappShareImg}}" loading="lazy" alt="...">
                                    Whatsapp
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="sub-heading font-medium" (mouseenter)="speakText(dealDetail[selectedLanguage].productName)"  (mouseleave)="stopSpeaking()">{{dealDetail[selectedLanguage].productName}} 
                
                    </div>
                   
                    
                    <!-- <div class = "rating-class">
                        <div class="rating-container">
                            <star-rating [value]="dealDetail.rate" totalstars="5" 
                            checkedcolor="orange" uncheckedcolor="gray" 
                            size="18px" readonly=true></star-rating>
                            {{dealDetail.rate}}
                        </div> -->
                        <!-- <div class="rating font-medium" (mouseenter)="speakText('total rating' + dealDetail.rate)"  (mouseleave)="stopSpeaking()">{{dealDetail.rate}}</div> -->
                    <!-- </div> -->

                    <div class="rupees" style="display: none;">
                        <div class="price font-medium" 
                        (mouseenter)="speakText(dealDetailLabels[selectedLanguage].price)"  (mouseleave)="stopSpeaking()">{{dealDetailLabels[selectedLanguage].price}}: ₹ </div>
                        <div class="digit font-medium" 
                        (mouseenter)="speakText(dealDetail.actualAmount)"  (mouseleave)="stopSpeaking()">{{dealDetail.actualAmount}} </div>
                        <div class="offer font-medium"  (mouseenter)="speakText(dealDetail.price)"  (mouseleave)="stopSpeaking()"><s> ₹ {{dealDetail.price}}</s></div>
                        <!-- <div class="off-percentage font-small"  (mouseenter)="speakText(dealDetail[selectedLanguage].description)"  (mouseleave)="stopSpeaking()">{{dealDetail[selectedLanguage].description}}</div> -->
                        <div class="off-percentage font-small"  (mouseenter)="speakText(dealDetail.saveAmount)"  (mouseleave)="stopSpeaking()">₹{{dealDetail.saveAmount}} OFF</div>
                    </div>
                    <div class="tc font-small"  (mouseenter)="speakText(dealDetailLabels[selectedLanguage].inclusiveOfAllTaxes)"  (mouseleave)="stopSpeaking()">{{dealDetailLabels[selectedLanguage].inclusiveOfAllTaxes}}</div>
                    <div class="heading font-heading"  (mouseenter)="speakText(dealDetailLabels[selectedLanguage].dealDetails)"  (mouseleave)="stopSpeaking()">{{dealDetailLabels[selectedLanguage].dealDetails}}</div>
                    <div class="feature-list font-medium">
                        <ul (mouseenter)="speakText(dealDetailFeature)"  (mouseleave)="stopSpeaking()">
                            <li class="font-medium"><p [innerHTML]="dealDetailFeature"></p></li>
                            <!-- <li>7 Days deal return policy</li> -->
                        </ul>
                    </div>
                    
                    <div class="termsAndConditionsForDeal">
                        <div class="heading font-heading"  (mouseenter)="speakText(dealDetailLabels[selectedLanguage].termsAndConditions)"  (mouseleave)="stopSpeaking()">{{dealDetailLabels[selectedLanguage].termsAndConditions}}</div>
                        <div class="t&dData" *ngIf="dealDetail[selectedLanguage].TandC">
                            <p class="TandCDiv">{{dealDetail[selectedLanguage].TandC}}</p>
                        </div>
                        <div class="t&dData" *ngIf="!dealDetail[selectedLanguage].TandC">                            
                            <p class="TandCDiv">Any</p>
                        </div>
                    </div>

                    <div class="deal-action-button">
                        <div *ngIf="dealDetail.favourite == false" (click)="markAsFavourite()" class="favourite" 
                        (mouseenter)="speakText(dealDetailLabels[selectedLanguage].favorites)"  (mouseleave)="stopSpeaking()">{{dealDetailLabels[selectedLanguage].favorites | uppercase}}</div>
                        <div *ngIf="dealDetail.favourite == true" (click)="markAsFavourite()" 
                        (mouseenter)="speakText(dealDetailLabels[selectedLanguage].removeFromFavourite)"  (mouseleave)="stopSpeaking()"
                        class="favourite">{{dealDetailLabels[selectedLanguage].removeFromFavourite | uppercase}}</div>
                        <mat-button *ngIf="dealDetail.quantity !== 0 || this.isQuantity === false" (click)="addToCart()" 
                        (mouseenter)="speakText(dealDetailLabels[selectedLanguage].addToCart)"  (mouseleave)="stopSpeaking()"
                        class="cart font-small">{{dealDetailLabels[selectedLanguage].addToCart | uppercase}}</mat-button>
                        <!-- <button mat-stroked-button class="save-deal" (click)="saveDeal()">
                            Save Deal
                        </button> -->
                    </div>
                </div>

            </div>
        </mat-card> 
    </div>
</div>
