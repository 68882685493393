<!-- loader -->
<div *ngIf="loadingTC" class="spinner">
  <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div>
<!-- connection lost condition -->
<div *ngIf="serverErrorMessage" class="connectionDiv">
  <img src={{images.connectionLost}} loading="lazy" alt="..." class="connectionLostImg">
  <span>{{serverErrorMessage}}</span>
</div>
<!-- main page -->
<div *ngIf="termsAndConditionData">
  <div class="container" *ngIf="!loadingTC && !serverErrorMessage">
    <!-- heading -->
    <!-- <label class="heading font-heading"><strong class="font-fix">{{labels[selectedLanguage].termsAndConditions |
        uppercase}}</strong></label> -->
        <br>
    <!-- card of mayor info -->
    <mat-card class="card">
      <!-- <embed src="../../../../assets/terms/terms-and-conditions.pdf" style="width: 100%;height:500px;" type="text/html"
        frameBorder="0" scrolling="auto"> -->
      <p [innerHTML]="">
        <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">

        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <title>file_1685946654886</title>
          <meta name="author" content="Tanvi Phadke" />
          <style type="text/css">
            * {
              margin: 0;
              padding: 0;
              text-indent: 0;
            }

            .s1 {
              color: black;
              font-family: "Times New Roman", serif;
              font-style: normal;
              font-weight: normal;
              text-decoration: none;
              font-size: 12pt;
              vertical-align: 7pt;
            }

            h1 {
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: underline;
              font-size: 24pt;
            }

            p {
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              text-decoration: none;
              font-size: 12pt;
              margin: 0pt;
            }

            .a,
            a {
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              text-decoration: none;
              font-size: 12pt;
            }

            li {
              display: block;
            }

            #l1 {
              padding-left: 0pt;
              counter-reset: c1 1;
            }

            #l1>li>*:first-child:before {
              counter-increment: c1;
              content: counter(c1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l1>li:first-child>*:first-child:before {
              counter-increment: c1 0;
            }

            #l2 {
              padding-left: 0pt;
              counter-reset: d1 1;
            }

            #l2>li>*:first-child:before {
              counter-increment: d1;
              content: counter(d1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l2>li:first-child>*:first-child:before {
              counter-increment: d1 0;
            }

            #l3 {
              padding-left: 0pt;
              counter-reset: e1 1;
            }

            #l3>li>*:first-child:before {
              counter-increment: e1;
              content: counter(e1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l3>li:first-child>*:first-child:before {
              counter-increment: e1 0;
            }

            #l4 {
              padding-left: 0pt;
              counter-reset: f1 1;
            }

            #l4>li>*:first-child:before {
              counter-increment: f1;
              content: counter(f1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l4>li:first-child>*:first-child:before {
              counter-increment: f1 0;
            }

            #l5 {
              padding-left: 0pt;
              counter-reset: g1 1;
            }

            #l5>li>*:first-child:before {
              counter-increment: g1;
              content: counter(g1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l5>li:first-child>*:first-child:before {
              counter-increment: g1 0;
            }

            #l6 {
              padding-left: 0pt;
              counter-reset: h1 1;
            }

            #l6>li>*:first-child:before {
              counter-increment: h1;
              content: counter(h1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l6>li:first-child>*:first-child:before {
              counter-increment: h1 0;
            }

            #l7 {
              padding-left: 0pt;
              counter-reset: i1 1;
            }

            #l7>li>*:first-child:before {
              counter-increment: i1;
              content: counter(i1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l7>li:first-child>*:first-child:before {
              counter-increment: i1 0;
            }

            #l8 {
              padding-left: 0pt;
              counter-reset: j1 1;
            }

            #l8>li>*:first-child:before {
              counter-increment: j1;
              content: counter(j1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l8>li:first-child>*:first-child:before {
              counter-increment: j1 0;
            }

            #l9 {
              padding-left: 0pt;
              counter-reset: k1 1;
            }

            #l9>li>*:first-child:before {
              counter-increment: k1;
              content: counter(k1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l9>li:first-child>*:first-child:before {
              counter-increment: k1 0;
            }

            li {
              display: block;
            }

            #l10 {
              padding-left: 0pt;
              counter-reset: l1 1;
            }

            #l10>li>*:first-child:before {
              counter-increment: l1;
              content: counter(l1, decimal)". ";
              color: black;
              font-family: Calibri, sans-serif;
              font-style: normal;
              font-weight: bold;
              text-decoration: none;
              font-size: 12pt;
            }

            #l10>li:first-child>*:first-child:before {
              counter-increment: l1 0;
            }
          </style>
        </head>

        <body>
          <div style="padding:16px">
            <h1 style="text-align: center;font-size: 20px;font-weight: 900;">PRIVACY POLICY</h1>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Intent: We
              know that you care how information about you is used and shared and we appreciate your trust in us to do
              that
              carefully and sensibly.</p>
            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;"><a
                href="http://www.pmccare.in/" class="a" target="_blank">This Privacy Policy explains how we, Pune
                Municipal
                Corporation (referred as “PMC”, “we” or “us” or “our” in this document), collect, process and use
                information of our users (hereinafter addressed as “you”, “your”, “yourself”). We are owners of the
                website
              </a>www.pmccare.in, associated mobile application and a provider of a range of services thereto. The
              website
              provides Information from various departments, projects and services rendered by Pune Municipal
              Corporation. The
              contact details of PMC Offices are also available here. Apart from this, we provide a platform where we
              may list
              offers for local services, goods and travel municipal service benefits which are made available by us or
              other
              sellers (collectively: “Sellers”). The content of the site is the result of collaborative effort of
              various
              departments of PMC. It is our endeavor to continue the enhancement and enrichment of this site in terms of
              its
              content, coverage, design and technology on a regular basis. This Privacy Policy applies to information
              that we
              collect through our website, mobile application, electronic communications or services (collectively, the
              “Site”).</p>
            <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">We
              will
              routinely update this Privacy Policy to improve our practices and to reflect new or different privacy
              practices,
              such as when we add new services, functionality or features to the Site. You can determine when this
              version of
              the Privacy Policy was adopted by referring to the “Effective Date” above.</p>
            <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">By
              visiting
              this Website, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not
              agree,
              please do not use or access our Website.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">By mere use of the
              Website, you
              expressly consent to our use and disclosure of your personal information in accordance with this Privacy
              Policy.
              This Privacy Policy is incorporated into and subject to the Terms of Use.</p>
            <ol id="l1">
              <li data-list-text="1.">
                <p style="padding-top: 8pt;padding-left: 17pt;text-indent: -12pt;text-align: left;">Sensitive Personal
                  Data
                  and Information (SPDI)</p>
                <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  Pursuant to the Information Technology Act, 2000, we are required to give a disclosure of the SPDI
                  collected by us and how do we use it. We may collect and retain the following information from and
                  about
                  you if you interact with us through the Site:</p>
                <ol id="l2">
                  <li data-list-text="1.">
                    <p style="padding-top: 8pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Your email
                      address and Site password;</p>
                  </li>
                  <li data-list-text="2.">
                    <p
                      style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Your payment details, billing and delivery addresses, a credit / debit card number and a credit
                      / debit card expiration date and/ or other payment instrument details;</p>
                  </li>
                  <li data-list-text="3.">
                    <p style="padding-top: 8pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Your phone/
                      mobile numbers;</p>

                  </li>
                  <li data-list-text="4.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">
                      Your location, website browsing patterns (e.g., through cookies) and purchase history;</p>
                  </li>
                  <li data-list-text="5.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">
                      Other information you actively submit to us or we can determine about you based on your
                      interactions with our Site and services.</p>
                  </li>
                </ol>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">We get access to such information when
                  you:
                </p>
                <ol id="l3">
                  <li data-list-text="1.">
                    <p style="padding-top: 9pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Register,
                      subscribe, authorize the transfer of, or create an account with us;</p>
                  </li>
                  <li data-list-text="2.">
                    <p style="padding-top: 9pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Open or respond
                      to emails;</p>
                  </li>
                  <li data-list-text="3.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Provide information to enroll or participate in programs provided on behalf of, or together with
                      other Sellers, merchants, co-marketers, distributors, resellers and other business partners,
                      with your consent or as necessary to provide services you have requested;</p>
                  </li>
                  <li data-list-text="4.">
                    <p style="padding-top: 8pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Visit any page
                      online that displays our ads or content;</p>
                  </li>
                  <li data-list-text="5.">
                    <p style="padding-top: 9pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Purchase
                      products or services on or through the Site;</p>
                  </li>
                  <li data-list-text="6.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Interact or connect with or link to the Site via integrated social networking tools; and</p>
                  </li>
                  <li data-list-text="7.">
                    <p style="padding-top: 7pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Post comments to
                      the Site.</p>
                  </li>
                </ol>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="2.">
                <p style="padding-left: 27pt;text-indent: -22pt;text-align: left;">Managing User Choices</p>
                <ol id="l4">
                  <li data-list-text="1.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      You can manage the types of personal data you provide to us and can limit how we communicate
                      with you.</p>
                  </li>
                  <li data-list-text="2.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      You can manage your email and notice preferences by logging into your account through the Site
                      or by adjusting the settings in our mobile application.</p>
                  </li>
                  <li data-list-text="3.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      You can also manage your subscriptions by following subscription management instructions
                      contained in any commercial emails that we send you.</p>
                  </li>
                  <li data-list-text="4.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      You may update your subscription preferences at any time. Please note that even if you decide
                      not to subscribe to, or to unsubscribe, from promotional email messages, we may still need to
                      contact you with important transactional information related to your account and your purchases.
                      For example, even if you have unsubscribed from our promotional email messages, we will still
                      send you confirmations when you make purchases on the Site.</p>
                  </li>
                  <li data-list-text="5.">
                    <p
                      style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      You hereby consent to receive communications by SMS or calls from us or our affiliates or its
                      partners with regard to the services provided by us or as facilitated by our partners.</p>

                  </li>
                  <li data-list-text="6.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      You may manage how your browser handles cookies by adjusting its privacy and security settings.
                      Browsers are different, so refer to instructions related to your browser to learn about
                      cookie-related and other privacy and security settings that may be available. Please refer to
                      Paragraph 11 of this Policy for information of Cookies for more information.</p>
                  </li>
                  <li data-list-text="7.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      You may also manage how your mobile device and mobile browser share information on and about
                      your devices with us, as well as how your mobile browser handles cookies by adjusting the
                      privacy and security settings on your mobile device. Please refer to instructions provided by
                      your mobile service provider and the manufacturer of your device to learn how to adjust your
                      settings.</p>
                  </li>
                  <li data-list-text="8.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      You may also manage the sharing of certain personal data with us when you connect with us
                      through social networking platforms or applications. Please refer to Paragraph 9 below and also
                      the privacy policy and settings of the social networking websites or applications to determine
                      how you may adjust our permissions and manage the interactivity between us and your social
                      networking account or your mobile device.</p>
                  </li>
                  <li data-list-text="9.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      If you register for customized email newsletters containing offers for local services, goods and
                      travel, municipal services etc, we will work to make information more relevant for you and
                      customize newsletters based on information you share with us, your location, website browsing
                      preferences (for example, through cookies), purchase history or based on other attributes of
                      your relationship with us. You can reject and delete cookies and unsubscribe from newsletters at
                      any time by clicking links in each newsletter you wish to unsubscribe from.</p>
                  </li>
                </ol>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="3.">
                <p style="padding-left: 27pt;text-indent: -22pt;text-align: left;">Use of Information</p>
                <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">We process personal
                  data
                  for the following purposes:</p>
                <ol id="l5">
                  <li data-list-text="1.">
                    <p style="padding-top: 9pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Operate,
                      maintain and improve the Site;</p>
                  </li>
                  <li data-list-text="2.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">
                      Provide you with personalized information and updates related to city events, direct marketing
                      initiatives via email and direct marketing offers;</p>
                  </li>
                  <li data-list-text="3.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">
                      Facilitate and process orders – for example, for vouchers and other goods and services;</p>
                  </li>
                  <li data-list-text="4.">
                    <p style="padding-top: 8pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Facilitate table
                      reservation process;</p>
                  </li>
                  <li data-list-text="5.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Determine your eligibility for certain types of offers, products or services that may be of
                      interest to you, and analyze advertising effectiveness;</p>
                  </li>
                  <li data-list-text="6.">
                    <p style="padding-top: 7pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Answer your
                      questions and respond to your requests;</p>
                  </li>
                  <li data-list-text="7.">
                    <p style="padding-top: 9pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">To establish and
                      analyze individual and group profiles and customer behavior;</p>
                  </li>
                  <li data-list-text="8.">
                    <p
                      style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Communicate and provide additional information that may be of interest to you about us, the
                      Sellers and our business partners;</p>
                  </li>
                  <li data-list-text="9.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Send you reminders, technical notices, updates, security alerts, support and administrative
                      messages, service bulletins, marketing messages, and requested information, including on behalf
                      of business partners;</p>

                  </li>
                  <li data-list-text="10.">
                    <p style="padding-top: 8pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Administer
                      rewards, surveys, contests, or other promotional activities or events;</p>
                  </li>
                  <li data-list-text="11.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Manage our everyday business needs, such as administration of the Site, analytics, fraud
                      prevention, and enforcement of our corporate reporting obligations and Terms of Use or to comply
                      with the law;</p>
                  </li>
                  <li data-list-text="12.">
                    <p style="padding-top: 7pt;padding-left: 41pt;text-indent: -36pt;text-align: left;">Comply with our
                      legal obligations, resolve disputes, and enforce our agreements;</p>
                  </li>
                  <li data-list-text="13.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">
                      Allows you to sign up for special offers from merchants and other business partners through the
                      Site; and to</p>
                  </li>
                  <li data-list-text="14.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Enhance other information we have about you to help us better understand you, determine your
                      interests and provide you with more relevant and compelling services.</p>
                  </li>
                </ol>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="4.">
                <p style="padding-left: 27pt;text-indent: -22pt;text-align: left;">Disclosure of Information</p>
                <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">We
                  are
                  not in the business of selling or renting personal data. We will not share your personal data, except
                  in
                  the following manner:</p>
                <ol id="l6">
                  <li data-list-text="1.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      With the Sellers, so they can sell and deliver to you and provide such other ancillary services
                      such as table reservation to serve you better;</p>
                  </li>
                  <li data-list-text="2.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      To report or collect on payments owed to Sellers, merchants or other business partners;</p>
                  </li>
                  <li data-list-text="3.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      As necessary to perform contractual obligations towards you with business partners to the extent
                      you have purchased or redeemed our voucher, goods or services offered by a business partner or
                      participated in an offer, rewards, contest or other activity or program sponsored or offered
                      through us or the Sellers on behalf of a business partner;</p>
                  </li>
                  <li data-list-text="4.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">
                      To comply with legal orders and government requests, or as needed to support auditing,
                      compliance;</p>
                  </li>
                  <li data-list-text="5.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      To combat fraud or criminal activity, and to protect our rights or those of our affiliates,
                      business partners and users, or as part of legal proceedings affecting us;</p>
                  </li>
                  <li data-list-text="6.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      In response to a legal process, including to law enforcement agencies, regulators, and courts;
                      or with your consent or as otherwise required or permitted by law.</p>
                  </li>
                </ol>
                <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">We
                  encourage business partners to adopt and post privacy policies. However, while we share personal data
                  with business partners only for the above-mentioned purposes, their subsequent processing and use of
                  personal data obtained through us is governed by their own privacy policies and practices and are not
                  subject to our control.</p>
              </li>
              <li data-list-text="5.">
                <p style="padding-top: 1pt;padding-left: 27pt;text-indent: -22pt;text-align: left;">Collection of
                  Information: We collect your personal data,</p>
                <ol id="l7">
                  <li data-list-text="1.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      When you register, subscribe, create an account, make a purchase or redeem vouchers or otherwise
                      contact us or communicate with us actively. For example, you provide personal data to us when
                      you contact us online for customer service and other support using self-help tools, such as
                      email, text, or by posting to online communities.</p>

                  </li>
                  <li data-list-text="2.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      When you interact with the Site via cookies and other technologies as described in Paragraph 11
                      on Cookies.</p>
                  </li>
                  <li data-list-text="3.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Through other online and offline information from our business partners who may report to us
                      redemption, collection, or refund-return events.</p>
                  </li>
                </ol>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="6.">
                <p style="padding-left: 27pt;text-indent: -22pt;text-align: left;">Security of Personal Information</p>
                <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">We
                  use
                  strict security measures to ensure the security, integrity and privacy of Your Personal Information
                  and
                  to protect your Personal Information against unauthorized access or unauthorized alteration,
                  disclosure
                  or destruction. For this purpose, we have adopted internal reviews of the data collection, storage and
                  processing practices and security measures, including offering the use of a secure server to guard
                  against unauthorized access to systems where we store your personal data.</p>
                <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">We
                  shall, however, not be responsible for any breach of security or for any actions of any third parties
                  that receive Your Personal Information. The Website is also linked to many other sites and we are
                  not/shall be not responsible for their privacy policies or practices as it is beyond our control.</p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="7.">
                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">User’s Rights in
                  relation to Personal Data: By keeping your personal data updated you can help us to ensure that we can
                  respect your preferences and offer you the goods and services that are most relevant to you.</p>
                <ol id="l8">
                  <li data-list-text="1.">
                    <p
                      style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      <a href="http://www.pmccare.in/" class="a" target="_blank">You can access, update, rectify, and
                        delete your information you provided to us in your profile by logging into your account or
                        contacting us at </a><a href="http://www.pmccare.in/" target="_blank">http://www.pmccare.in.</a>
                    </p>
                  </li>
                  <li data-list-text="2.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      While we are ready to assist you in managing your subscriptions, closing your account, and
                      removing your active profile, we cannot always delete records of past interactions and
                      transactions. For example, we may be required to retain records relating to previous purchases
                      on the Site for financial reporting and compliance reasons.</p>
                  </li>
                </ol>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="8.">
                <p style="padding-left: 27pt;text-indent: -22pt;text-align: left;">Retention of Personal Data</p>
                <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  You
                  can at any time unsubscribe from our services, newsletters, e-mailers. We will, however, retain your
                  personal data for as long as your account is active or as needed to provide you services and to
                  maintain
                  a record of your transactions for financial reporting or</p>
                <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  other
                  compliance purposes. We will also retain your personal data as necessary to comply with our legal
                  obligations, resolve disputes, and enforce our agreements.</p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="9.">
                <p style="padding-left: 27pt;text-indent: -22pt;text-align: justify;">Social Site Features</p>

                <ol id="l9">
                  <li data-list-text="1.">
                    <p
                      style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      The Site may contain connections to areas where you may be able to publicly post information,
                      communicate with others such as discussion boards or blogs, review products and merchants, and
                      otherwise submit your own original content. Prior to posting in these areas, please read our
                      Terms of Use carefully. All the information you post may be accessible to anyone with Internet
                      access, and any information you include in your posting may be read, collected, and used by
                      others.</p>
                  </li>
                  <li data-list-text="2.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      Connecting through Social Networks: We offer social networking users the opportunity to interact
                      with friends and to share on social networks. If you are logged into both the Site and a social
                      network, when you use the Site’s social networking connection functions, you may connect your
                      social network account with our account (this happens automatically, if the email addresses
                      match). If the email addresses do not match, we ask you if you want to link them and you must
                      validate that you control the accounts. If you are already logged into the Site but not logged
                      into your social network site, when you use the Site’s social network connection functions, you
                      will be prompted to enter your social network website credentials or to sign up for the social
                      network.</p>
                  </li>
                  <li data-list-text="3.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      If you are not currently registered as our user and you use the Site’s social network connection
                      functions, you will first be asked to enter your social network credentials and then be given
                      the option to register and join us. Once you register with us and connect with the social
                      network, you will be able to automatically post our recent activity back to your social network.
                      Please refer to the privacy settings in your social network account to manage the data that is
                      shared through your account.</p>
                  </li>
                  <li data-list-text="4.">
                    <p
                      style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                      By using the Site’s social network connection function, you will grant us permission to access
                      all of the elements of your social network profile information that you have made available to
                      be shared (as per the settings chosen by you in your social network profile) and to use it in
                      accordance with the social network’s terms of use and this Privacy Policy.</p>
                  </li>
                </ol>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
              </li>
              <li data-list-text="10.">
                <p style="padding-left: 28pt;text-indent: -23pt;text-align: justify;">Privacy Policies of Third Parties
                </p>
                <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  This
                  Privacy Policy only addresses the collection, processing and use (including disclosure) of information
                  by us through your interaction with the Site. Other websites that may be accessible through links from
                  the Site may have their own privacy policies and personal information collection, processing, use, and
                  disclosure practices. Our business partners, may also have their own privacy policies. We encourage
                  you
                  to familiarize yourself with the privacy policies provided by these other parties prior to providing
                  them with information or taking advantage of a sponsored offer or promotion.</p>
              </li>
              <li data-list-text="11.">
                <p style="padding-top: 1pt;padding-left: 28pt;text-indent: -23pt;text-align: justify;">Cookies</p>
              </li>
            </ol>
            <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Consent: By
              using our website or mobile app (“Site”), using services provided through the Site, or receiving our
              emails, you
              consent to the use of cookies in accordance with this “Cookies Policy”.</p>

            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Intent: This
              Privacy Policy sets out how we, and third parties we authorize, use cookies when you interact with our
              Site or
              the services offered via the Site. Our Privacy Policy explains what we do (and don&#39;t do) with the
              information, including any personal data we may collect about you. Please read them both.</p>
            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Meaning:
              Cookies are unique identifiers, usually made up of small bits of text or code. Cookies are usually stored
              on
              your device or in your web browser and they send certain information back to the party who served the
              cookie.
              When we say cookies we are actually referring to a broader range of technologies than just cookies,
              including
              web beacons, clear gifs, pixels and other similar technologies. When we say first-party cookies we mean
              cookies
              that are served by us. When we say third party cookies we mean cookies that are served by parties other
              than us.
            </p>
            <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">Use of
              Cookies: Cookies are used in order to better understand how applications and websites work and to optimize
              online and mobile experiences. Cookies do this by allowing the party serving the cookie to analyze a
              website’s
              or mobile app’s performance, to identify certain users anonymously and remember their preferences, to
              better
              understand whether a computer or device (and probably its user) has visited a website previously and to
              serve
              personalized advertisements.</p>
            <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Types of Cookies: Our
              Site
              primarily uses the following four types of cookies:</p>
            <ol id="l10">
              <li data-list-text="1.">
                <p style="padding-top: 9pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  Operations based Cookies: These cookies are essential for the operation of our Site. They make it
                  possible for you to browse and use our Site. If you disable these cookies, the Site will not function
                  correctly.</p>
              </li>
              <li data-list-text="2.">
                <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  Performance based Cookies: These cookies gather information about how visitors use the Site and
                  monitor
                  Site performance. For example, performance cookies tell us which pages are popular, monitor traffic to
                  our Site and compile anonymous analytic information. Performance cookies can also be used to identify
                  and remedy operational problems with the Site.</p>
              </li>
              <li data-list-text="3.">
                <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  Functionality based Cookies: These cookies are used to recognize and remember your preferences. For
                  example, functionality cookies may use information, such as your location, to make sure that you
                  arrive
                  at a version of our Site that is specific to your city or region.</p>
              </li>
              <li data-list-text="4.">
                <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
                  Advertising Preferences based Cookies: We offer you choices about receiving interest-based ads from
                  us.
                  For this, we allow third parties to deliver personalized advertising content on our Site and/or on
                  third
                  party websites and applications. These third parties use cookies to understand your interest in the
                  advertisements and content delivered to you, as well as to serve you with more relevant advertisements
                  based on your activities on our Site. For example, third-party cookies could record information about
                  whether you have clicked</p>
              </li>
            </ol>
            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">on
              certain
              content or advertisements on our Site or other websites, or placed an item on our Site in your shopping
              cart.
            </p>
            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">We do
              not have
              control over, or access to, third-party advertising cookies or the information they contain. Third parties
              manage any information that they collect via cookies they place in accordance with their privacy policies.
            </p>

            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">You
              can
              opt-out of third-party personalized advertising cookies. You will still see ads but they will not be
              personalized.</p>
            <p style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Cookies and
              Browser settings: You may be able to avoid certain cookies by configuring your browser settings or
              opting-out
              directly with the party that operates and controls the cookie. Please check your browser settings for more
              information.</p>
            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;line-height: 108%;text-align: justify;">
              Cookies in
              E-mails: Our emails contain cookies that track whether you open our emails and whether you click any links
              within them. The cookies used in our emails to monitor these behaviors are unique to each email and are
              not
              stored on your computer or mobile device.</p>

          </div>

        </body>

        </html>

      </p>
    </mat-card>
  </div>
</div>