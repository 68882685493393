<!-- /*******************************
 * Component -  Remove from cart popup
 * Created By - Akshata Choulwar
 * Updated By - Akshata Choulwar
 * Updated On - 20/04/2020
 **************************************/ -->
 <div class="popup" *ngIf = "selectedLanguage && data">
    <div class="image-container">
        <div class="each-card-detail">
           <div class = "content">
                <div class="each-detail">
                    <div class="deal-info-heading font-small">{{popupLabel[selectedLanguage].doYouWantToClearCart}}</div>
                    <div class="sub-heading font-small">{{popupLabel[selectedLanguage].toAddThisProductYouNeedToClearCart}}</div>
                </div>
           </div>
           <div class="button">
            <button  class="button font-small remove" (click)="removeFromCart()">{{popupLabel[selectedLanguage].yes | uppercase}}</button>
            <button  class="button font-small" (click)="closeDialog()">{{popupLabel[selectedLanguage].no | uppercase}}</button>
          </div>    
        </div>
    </div>
    <!-- <mat-icon class="cross-button" aria-hidden="false" (click)="closeDialog()">clear</mat-icon> -->
</div>
 