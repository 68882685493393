<!-- /**********************************
* Created by: Akanksha Jadhav
* Created date: 18/03/2020
* Updated by: Akanksha Jadhav
* Updated data: 18/03/2020
* Component: popup for report abuse comment
**************************************/ -->
<div class="dialogbox">
    <div *ngIf="isLoading" class="dialog-spinner">
        <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
        </mat-progress-spinner>
    </div>
    <div class="dialogbox" *ngIf="!isLoading">
        <div class="popup-header">
            <div class="popup-heading">
               {{reportAbuseLabels[selectedLanguage].reportAsAbuse}}
            </div>
        </div>
        <div class="popup-container">
            <div class="comment-textarea">
                <textarea  matInput placeholder="{{reportAbuseLabels[selectedLanguage].writeReasonToReport}}" rows="5" cols="45" [(ngModel)]="comment"></textarea>
            </div>
        </div>
        <div class="popup-button-container">
            <div class="align-buttons">
                <button mat-flat-button class="popup-reverse-button" (click)="closeDialog()">
                    {{reportAbuseLabels[selectedLanguage].cancle}}
                </button>
                <button mat-flat-button class="popup-button" [disabled]="!comment" (click)="report()">
                    {{reportAbuseLabels[selectedLanguage].report}}
                </button>
            </div>
        </div>
    </div>
</div>