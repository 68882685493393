/*******************************
 * Component -  More services
 * Created By - Akanksha J
 * Created on - 28/03/2020
 * Updated By - Akanksha J
 * Updated On - 31/03/2020
 *************************************/
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { LocalStorageService } from '../../../services/localstorage.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { MatButton } from '@angular/material/button';
import { DetailsPageSliderComponent } from '../../details-page-slider/details-page-slider.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgClass, NgFor, UpperCasePipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-merchant-detail-screen',
    templateUrl: './merchant-detail-screen.component.html',
    styleUrls: ['./merchant-detail-screen.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatTooltip, RouterLink, DetailsPageSliderComponent, NgClass, MatButton, NgFor, UpperCasePipe, DatePipe]
})
export class MerchantDetailScreenComponent implements OnInit {

	/**
	 * loading status of spinner
	 */
	isLoading = false;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails = {};
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 */
	data: any;
	/**
	 * object for merchant
	 */
	merchantData: any
	/**
	 * merchant data
	 */
	merchantResult: {};
	/**
	 * Latitude and logitude variable
	 */
	lng: any;
	lat: any;
	/**
	 * Initial latitude and logitude
	 */
	initlat = 18.62861241;
	initlng = 73.80478858;
	dir: { origin: { latitude: number; longitude: number; }; destination: { latitude: any; longitude: any; }; };
	isOverview = true;
	isAboutUs = false;
	isReview = false;
	dataNotFoundMsg: any;
	image = ['../../../../../assets/images/more-services/banner1.png', '../../../../../assets/images/more-services/banner1.png',
	'../../../../../assets/images/more-services/banner1.png', '../../../../../assets/images/more-services/banner1.png'];
	
	ratePoint =3;
	merchantDetailLabels: {};
	weekTimingData:any ;
	merchantAndBrandLabels: {};
	/**
	 * Angular method which runs on component initialization
	 */
	constructor(
		private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private toastr: ToastrService,
		private activeRoute: ActivatedRoute
	) {
		this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});
	 }

	 /**
	 * Created by Neha
	 * For showing the location permission popup and getting the location of user 
	 */
	getUserLocation() {
		if (navigator) {
			navigator.geolocation.getCurrentPosition(pos => {
				this.lng = +pos.coords.longitude;
				this.lat = +pos.coords.latitude;
			},
				error => {
					this.lat = this.initlat;
					this.lng = this.initlng;
				});
		}
	}
	/**
	 * Angular method which runs on page initialization
	 */
	ngOnInit() {
		this.getUserLocation();
		// server error handling
		this.store.select(fromStore.getMoreServicesNetworkError)
			.subscribe((serverErrorMessage) => {
				if (serverErrorMessage != null) {
					if (serverErrorMessage === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverErrorMessage;
						this.isServerErrorMessage = true;
						console.error(this.serverMessage);
					}
				}
		});

		this.store.select(fromStore.getMoreServicesLabels)
		.subscribe(labels => {
			this.merchantDetailLabels = labels;
		});

		this.store.select(fromStore.getMerchantAndBrandLabels)
		.subscribe(labels => {
			this.merchantAndBrandLabels = labels;

		});

			// token from localstorage
		this.store.select(fromStore.getToken)
		.subscribe((token) => {
		  this.token = token;
		  if (this.token) {
			const localUserId = this.localStorageService.getUserId();
			this.userId = this.encrDecr.get(localUserId);
		  }
		});

		this.store.select(fromStore.isGetMerchantByIdLoading)
			.subscribe(loading => {
				this.isLoading = loading;
			});

		this.store.select(fromStore.getSelectedLanguage)
		.subscribe(lang => {
			this.selectedLanguage = lang;
			if (this.selectedLanguage) {
				this.store.select(fromStore.isGetMerchantByIdLoaded)
				.subscribe(loaded => {
					if (loaded) {
						this.store.select(fromStore.getMerchantByIdStatusCode)
						.subscribe(code => {
							if(code) {
								if (code === 200) {
									this.store.select(fromStore.getMerchantByIdResult)
									.subscribe(result => {
										if(result) {
											this.merchantData = result;
											this.weekTimingData = this.merchantData.workingDays
										}
									});
								} else if (code === 500) {
									this.store.select(fromStore.getMerchantByIdSuccessMessage)
									.subscribe(errorMessages => {
										this.dataNotFoundMsg = errorMessages;
									});
								}
								else {
								this.store.select(fromStore.getErrorMessages)
									.subscribe(errorMessages => {
										console.error(errorMessages[this.selectedLanguage].code);
									});
								}
							}
						});
					}
				});
			}
		});
		
		// merchant api call
		this.activeRoute.params.subscribe((param) => {
			this.data = param['merchantUserId'];
			if(this.data){
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.GetMerchantById({
						url: this.apiDetails['user'].base_url +
						this.apiDetails['user'].getMerchantById.url,
						method: this.apiDetails['user'].getMerchantById.method,
						body:{
							userId : this.data
						}
					}));
				}
			}
		});
	};

	convertTo12Hour(time: string): string {
		if (!time) return time;
	  
		const [hours, minutes] = time.split(':').map(Number);
		let period = 'AM';
	  
		let hour = hours;
	  
		if (hours === 0) {
		  hour = 12;
		} else if (hours === 12) {
		  period = 'PM';
		} else if (hours > 12) {
		  hour = hours - 12;
		  period = 'PM';
		}
	  
		return `${hour}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
	  }

	/**
	 * Created by Akanksha J
	 * For changing the active tab
	 */
	activeTab(value) {
		if (value === 'overview') {
			this.isOverview = true;
			this.isAboutUs = false;
			this.isReview = false;
		} else if (value === 'aboutUs') {
			this.isOverview = false;
			this.isAboutUs = true;
			this.isReview = false;
		} else if (value === 'review') {
			this.isOverview = false;
			this.isAboutUs = false;
			this.isReview = true;
		}
	}

	/**
	 * Created by neha
	 * redirect to deal listing on click deal now button
	 */
	dealListing(){
		this.router.navigate(['/cep/deals/', this.data]);
	} 


	/**
	 * Created by neha
	 * Redirect to guugle map 
	 */
	redirectNearMeToGoogleMap(directionLongitude, directionLatitude) {
		var lat = this.lat;
		var lng = this.lng;
		window.open('https://www.google.com/maps/dir/?api=1&origin='+ lat + ',' + lng +'&destination='+ directionLongitude + ',' + directionLatitude +'&travelmode=driving','_blank' );
		// window.open('https://www.google.com/maps/dir/?api=1&directionLatitudeorigin='+lat+','+lng+'&destination='+directionLatitude+','+directionLongitude+'&travelmode=driving','_blank' );
	}

}
