<!--/*******************************
 * Component -  More services
 * Created By - Akanksha J
 * Created on - 28/03/2020
 * Updated By - Akanksha J
 * Updated On - 31/03/2020
 *************************************/-->
 <div class='overlap-container'>
    <!-- connection lost condition -->
    <div *ngIf='serverMessage' class='connectionDiv'>
        <img src={{image.connectionLost}} class='connectionLostImg' loading="lazy" alt="...">
        <span>{{serverMessage}}</span>
    </div>
    <!-- main page loader -->
    <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>
    <!-- data not found error handling -->
    <ng-container *ngIf="dataNotFoundMsg">
        <div class="error-messages">
            <img src="{{images.noDataFound}}" class="no-data-image-favourite" loading="lazy" alt="...">
        <span class="data-not-found">{{dataNotFoundMsg}}</span>
        </div>
    </ng-container>
    <!-- main page -->
    <div *ngIf='!isLoading && !serverMessage && merchantData' class="post-navigation-merchant font-sub-heading">
        <p matTooltipClass="engagement-tooltip  " matTooltip="{{merchantData.displayCompanyName}}">
          <span [routerLink]="[ '/cep/deals/',merchantData.userId]" class="feeds-redirection font-card-heading">
            {{merchantDetailLabels[selectedLanguage].dealsListing | uppercase}}
          </span> > {{merchantData.displayCompanyName}}
            </p>
    </div>
    <div *ngIf='!isLoading && !serverMessage && merchantData' class='main-container'>
        <div class="sub-container">
            <div class="image-conatiner">
                <div class="merchant-info">
                    <div class="merchant-icon">
                        <img class="merchant-image" src={{merchantData.logo?merchantData.logo:images.defaultMerchantLogo}} loading="lazy" alt="..."> 
                    </div>
                    <div class="merchant-name">
                        <div class="name font-heading">{{merchantData.displayCompanyName}}</div>
                        <div class="powered-by font-small">{{merchantDetailLabels[selectedLanguage].poweredBy}}: {{merchantData.companyType}}</div>
                        <div class="location">
                            <img class="location-image" src="../../../../../assets/images/more-services/location.png" loading="lazy" alt="...">
                            <div class="address">{{merchantData.headOfficeAddress}}</div>
                        </div>
                    </div>
                    <!-- <div class="merchant-rating">
                        <div class="rating">{{merchantDetailLabels[selectedLanguage].rating}}</div>
                        <div class="rating-number">
                            <div class="actual-rating">{{merchantData.ratePoint}}/</div>
                            <div class="outoff-rating">5</div>
                        </div>
                        <div class="rating-container">
                            <star-rating [value]="merchantData.ratePoint" totalstars="5" 
                            checkedcolor="yellow" uncheckedcolor="#F1F1F1" readonly=true
                            size="18px"></star-rating>
                        </div>
                        
                    </div> -->
                </div>
                <div class="slider-container">
                    <app-details-page-slider [postImages]="merchantData.bannerImages"></app-details-page-slider>
                </div>
            </div>
            <div class="verticle-line"></div>
            <div class="information-container">
                <div class="tabs-container">
                    <div [ngClass]="{'active-tab': isOverview === true}" (click)="activeTab('overview')" matTooltip="{{merchantDetailLabels[selectedLanguage].overview}}">{{merchantDetailLabels[selectedLanguage].overview}}</div>
                    <div [ngClass]="{'active-tab': isAboutUs === true}" (click)="activeTab('aboutUs')" matTooltip="{{merchantDetailLabels[selectedLanguage].aboutUs}}">{{merchantDetailLabels[selectedLanguage].aboutUs}}</div>
                    <!-- <div [ngClass]="{'active-tab': isReview === true}" (click)="activeTab('review')" matTooltip="{{merchantDetailLabels[selectedLanguage].reviews}}">{{merchantDetailLabels[selectedLanguage].reviews}}</div> -->
                </div>
                <div class="container" *ngIf="isOverview">
                    <div class="time-and-addr">
                        <div class="time-div">
                            <div class="icon-and-heading">
                                <img class="icon-image" src="../../../../../assets/images/more-services/clock.png" loading="lazy" alt="...">
                                <label>{{merchantDetailLabels[selectedLanguage].timing}}</label>
                            </div>
                            <div class="time-day">
                                <label class="dark-blurr-font font-small">{{ merchantAndBrandLabels[selectedLanguage].monday }}</label>
                                <label class="blurr-font font-small" *ngIf="weekTimingData.mon.holiday">{{ merchantAndBrandLabels[selectedLanguage].holiday }}</label>
                                <label class="blurr-font font-small" *ngIf="!weekTimingData.mon.holiday">{{ convertTo12Hour(weekTimingData.mon.startTime) }} - {{ convertTo12Hour(weekTimingData.mon.endTime) }} </label>
                            </div>
                            <div class="time-day">
                                <label class="dark-blurr-font font-small">{{ merchantAndBrandLabels[selectedLanguage].tuesday }}</label>
                                <label class="blurr-font font-small" *ngIf="weekTimingData.tue.holiday">{{ merchantAndBrandLabels[selectedLanguage].holiday }}</label>
                                <label class="blurr-font font-small" *ngIf="!weekTimingData.tue.holiday">{{ convertTo12Hour(weekTimingData.tue.startTime) }} - {{ convertTo12Hour(weekTimingData.tue.endTime) }} </label>
                            </div>
                            <div class="time-day">
                                <label class="dark-blurr-font font-small">{{ merchantAndBrandLabels[selectedLanguage].wednesday }}</label>
                                <label class="blurr-font font-small" *ngIf="weekTimingData.wed.holiday">{{ merchantAndBrandLabels[selectedLanguage].holiday }}</label>
                                <label class="blurr-font font-small" *ngIf="!weekTimingData.wed.holiday">{{ convertTo12Hour(weekTimingData.wed.startTime) }} - {{ convertTo12Hour(weekTimingData.wed.endTime) }} </label>
                            </div>
                            <div class="time-day">
                                <label class="dark-blurr-font font-small">{{ merchantAndBrandLabels[selectedLanguage].thursday }}</label>
                                <label class="blurr-font font-small" *ngIf="weekTimingData.thus.holiday">{{ merchantAndBrandLabels[selectedLanguage].holiday }}</label>
                                <label class="blurr-font font-small" *ngIf="!weekTimingData.thus.holiday">{{ convertTo12Hour(weekTimingData.thus.startTime) }} - {{ convertTo12Hour(weekTimingData.thus.endTime) }} </label>
                            </div>
                            <div class="time-day">
                                <label class="dark-blurr-font font-small">{{ merchantAndBrandLabels[selectedLanguage].friday }}</label>
                                <label class="blurr-font font-small" *ngIf="weekTimingData.fri.holiday">{{ merchantAndBrandLabels[selectedLanguage].holiday }}</label>
                                <label class="blurr-font font-small" *ngIf="!weekTimingData.fri.holiday">{{ convertTo12Hour(weekTimingData.fri.startTime) }} - {{ convertTo12Hour(weekTimingData.fri.endTime) }} </label>
                            </div>
                            <div class="time-day">
                                <label class="dark-blurr-font font-small">{{ merchantAndBrandLabels[selectedLanguage].saturday }}</label>
                                <label class="blurr-font font-small" *ngIf="weekTimingData.sat.holiday">{{ merchantAndBrandLabels[selectedLanguage].holiday }}</label>
                                <label class="blurr-font font-small" *ngIf="!weekTimingData.sat.holiday">{{ convertTo12Hour(weekTimingData.sat.startTime) }} - {{ convertTo12Hour(weekTimingData.sat.endTime) }} </label>
                            </div>
                            <div class="time-day">
                                <label class="dark-blurr-font font-small">{{ merchantAndBrandLabels[selectedLanguage].sunday }}</label>
                                <label class="blurr-font font-small" *ngIf="weekTimingData.sun.holiday">{{ merchantAndBrandLabels[selectedLanguage].holiday }}</label>
                                <label class="blurr-font font-small" *ngIf="!weekTimingData.sun.holiday">{{ convertTo12Hour(weekTimingData.sun.startTime) }} - {{ convertTo12Hour(weekTimingData.sun.endTime) }} </label>
                            </div>
                        </div>
                        <div class="time-div">
                            <div class = "icon-and-heading"> 
                                <img class="location-image" src="../../../../../assets/images/more-services/location.png" loading="lazy" alt="...">
                                <label>{{merchantDetailLabels[selectedLanguage].address}}</label>
                            </div>
                            <div class="time-day">
                                <label class="address-blurr-font">{{merchantData.headOfficeAddress}}, {{merchantData.headOfficeCity}}</label>
                            </div> 
                            <div class = "icon-and-heading"> 
                                <img (click)="redirectNearMeToGoogleMap(merchantData.geometry.coordinates[0],merchantData.geometry.coordinates[1])"
                                class="direction-image" src="../../../../../assets/images/more-services/direction.png" loading="lazy" alt="...">
                                <label (click)="redirectNearMeToGoogleMap(merchantData.geometry.coordinates[0],merchantData.geometry.coordinates[1])" class="direction-label"
                                matTooltip="{{merchantDetailLabels[selectedLanguage].getDirection}}">{{merchantDetailLabels[selectedLanguage].getDirection}}</label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="offer">
                        <div class = "icon-and-heading"> 
                            <img class="icon-image" src="../../../../../assets/images/more-services/sale-blue.png">
                            <label>recent offers</label>
                        </div>
                        <div class="day">
                            <label class="blurr-font font-small">Description ....</label>
                        </div>
                    </div> -->
                    <div class="offers"></div>
                    <div class="button">
                        <button mat-raised-button class="deal-button" (click)="dealListing()" matTooltip="{{merchantDetailLabels[selectedLanguage].dealNow}}">{{merchantDetailLabels[selectedLanguage].dealNow}}</button>
                    </div>
                </div>
                <div class="container" *ngIf="isAboutUs">
                    <div class="about-us"> 
                        <div class = "icon-and-heading"> 
                            <img class="icon-image" src="../../../../../assets/images/more-services/question.png" loading="lazy" alt="...">
                            <label>{{merchantDetailLabels[selectedLanguage].aboutUs}}</label>
                        </div>
                        <div class="day">
                            <label class="blurr-font font-small" *ngIf=" selectedLanguage === 'en' ">{{merchantData.aboutUs}}</label>
                            <label class="blurr-font font-small" *ngIf=" selectedLanguage === 'mar' ">{{merchantData.aboutUsMar}}</label>
                        </div>
                    </div>
                    <div class="offer">
                        <div class = "icon-and-heading"> 
                            <img class="icon-image" src="../../../../../assets/images/more-services/contact-us-blue-icon.png" loading="lazy" alt="...">
                            <label>{{merchantDetailLabels[selectedLanguage].contact}}</label>
                        </div>
                        <div class="time-day">
                            <label class="dark-blurr-font">{{merchantDetailLabels[selectedLanguage].emailId}}</label>
                            <label class="blurr-font">{{merchantData.ownerEmailId}}</label>
                        </div>
                        <div class="day-timing">
                            <label class="dark-blurr-font">{{merchantDetailLabels[selectedLanguage].phone}}</label>
                            <label class="blurr-font">{{merchantData.ownerMobileNumber}}</label>
                        </div>
                    </div>
                    <div class="button">
                        <button mat-raised-button class="deal-button" (click)="dealListing()" matTooltip="{{merchantDetailLabels[selectedLanguage].dealNow}}">{{merchantDetailLabels[selectedLanguage].dealNow}}</button>
                    </div>
                </div>
                <div class="containerReview" *ngIf="isReview">
                    <div class="about-us" *ngFor = "let data of merchantData.feedbacks">
                        <div class="card">
                        <div class="comment-card">
                            <label class="circle"> <img class = "profile" src = "{{data.profilePhoto}}" loading="lazy" alt="..."></label>
                            <div class="comment-data">
                                <div class="user-comment-name">
                                    <div class="comment">
                                        <label class="dark-blurr-font">{{data.firstName}}</label>
                                        <!-- <img src="../assets/images/blog/trash.png" (click)="removeBlogComment(this.feedDetailsResultArray[i]['commentId'],this.feedDetailsResultArray[i]['updatedBy'])" class="trashImg" *ngIf="this.userId == this.feedDetailsResultArray[i]['userId']"> -->
                                    </div>
                                </div>
                                <div class="commentDesc">
                                    <div>
                                        <label>Rating: {{data.hotFeedbackValue}}</label>
                                        <label class="space blurr-font">{{data.createdAt | date}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="comment-card blurr-font">{{data.comment}}</label>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>