
 <div class="salary-slip">
  <div class="employee-info1">
  <h3>Salary Slip</h3>
  <mat-icon (click)="closePopup()">close</mat-icon>
  </div>
  <div class="employee-info2">
    <h4>EmployeeName:</h4>
    <p style="margin: 10px;">{{ UserProfileDetails.firstName }}</p>
</div>
<div class="employee-info">
    <label for="month">Month-Year:</label>
    <mat-select id="month" placeholder="Select month" class="styled-select">
      <mat-option *ngFor="let month of months" [value]="month" (click)="setselectedMonth(month)">
        {{month.salaryMonth}} - {{ month.salaryYear }}
      </mat-option>
    </mat-select>

    <!-- <label for="year">Year:</label>
    <mat-select id="year" placeholder="Select year" class="styled-select">
      <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
    </mat-select> -->
  </div>



  <button mat-raised-button color="primary" class="submit-button" (click)="onsubmit()">Generate Salary Slip</button>
</div>

