<!-- /*******************************
 * Component -  Remove from cart popup
 * Created By - Akshata Choulwar
 * Updated By - Akshata Choulwar
 * Updated On - 14/04/2020
 **************************************/ -->
 <div class="popup" *ngIf = "selectedLanguage && data">
    <div class="image-container">
        <!-- <img class="image-size" src = {{introImages[selectedLanguage].infoImage}}  />  -->
        <div class="each-card-detail">
           <div class = "content">
                <div class="each-image">
                    <img class="image" src={{data.image[0]}} loading="lazy" alt="...">
                </div>
                <div class="each-detail">
                    <div class="deal-info-heading font-small">{{myCartLables[selectedLanguage].removeItems}}</div>
                    <div class="sub-heading font-small">{{myCartLables[selectedLanguage].doYouWantToRemoveItem}}</div>
                </div>
           </div>
           <div class="button">
            <button mat-raised-button class="dark-blurr-font button font-small" (click)="addToFav()">{{myCartLables[selectedLanguage].addToFavourite}}</button>
            <button mat-raised-button class="button font-small remove" (click)="removeFromCart()">{{myCartLables[selectedLanguage].remove}}</button>

          </div>    
        </div>
    </div>
    <mat-icon class="cross-button" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>
</div>
 