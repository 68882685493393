import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as  BirthDateActionCreator from '../action-creator/birth-date.action-creator';
import * as  BirthDateAction from '../action/birth-date.action';
import { Observable, of } from 'rxjs';
import { BirthDateService } from '../api-services/birth-date.service';
import * as DeathDateAction from '../action/death-action'
import { DeathDateService } from '../api-services/death-date.service';


@Injectable()

export class DeathDateEffects {
    constructor(
        private actions$: Actions,
        private deathDateService: DeathDateService
    ) {
    }

    DeathDateEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeathDateAction.GetDEATHDateList>(DeathDateAction.GET_DEATH_DATE_LIST),
            switchMap((action) => {
                return this.deathDateService.getDeathDateList(action.payload).pipe(
                    map(data => new DeathDateAction.GetDEATHDateListSuccess(data)),
                    catchError(error => of(new DeathDateAction.GetDEATHDateListFail(error)))
                );
            })
        )
    );
}

