<!-- /*************************************
 * Component -  detail page survey
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ******************************************/ -->
 <!-- heading -->
<div class="navigation font-sub-heading" *ngIf="feedLabels && feedsData">
  <p class="heading-nav" matTooltipClass="engagement-tooltip  " matTooltip="{{feedsData[selectedLanguage].heading}}"><span [routerLink]="[ '/cep/feeds']" class="feeds-redirection">
         {{feedLabels[selectedLanguage].feed}} </span> > {{feedsData[selectedLanguage].heading}}
  </p>
  <!-- <mat-icon class="speaker-icon-class pointer" matTooltip="{{landingLables[selectedLanguage].speak}}" (click)="speakText(feedsData[selectedLanguage].heading)">volume_up</mat-icon> -->

</div>
<!-- opening survey in iframe -->
<ng-container>
  <div *ngIf="loading" class="spinner-loader">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
  <div class="container" *ngIf="!loading && isCorona == null">
    <iframe id="survey" [src]='surveylink' frameborder="0" width="100%" height="800"
    webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  </div>
  
  <div class="container" *ngIf="!loading && isCorona == 'true' && allow">
    <iframe id="survey" [src]='surveylink' frameborder="0" width="100%" height="800"
    webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  </div>
</ng-container>