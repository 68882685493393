/**
 * Created by: Kiran G
 * Updated by: kiran G
 * Component: Google analytics
 * Updated date: 20/12/2019
 */

import { Injectable } from "@angular/core";
import { ApiCallService } from "./dynamic-api-calling.service";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";
import { LocalStorageService } from "./localstorage.service";
import { EncrDecrUtil } from "src/app/util/EncrDecr";
import { environment } from "src/environments/environment";

// declare let ga: Function; // Declare ga as a function
declare let gtag: Function; // Declare ga as a function

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  deviceInfo: DeviceInfo;
  deviceType: any;
  isMobile: any;
  isDesktop: any;
  userId: any;
  /**
   * angular method for declaring packages in variables
   *
   * which runs first in this particulat component
   */
  constructor(
    private apiCallService: ApiCallService,
    private deviceDetectorService: DeviceDetectorService,
    private localStorageService: LocalStorageService
  ) {}

  public eventEmitter(
    eventName: string,
    params: any
  ) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.isMobile = this.deviceDetectorService.isMobile();
    this.isDesktop = this.deviceDetectorService.isDesktop();

    const encrDecr = new EncrDecrUtil();

    if (this.localStorageService.getItem("userId") === null) {
      this.userId = null;
    } else {
      this.userId = encrDecr.get(this.localStorageService.getItem("userId"));
    }
    if (this.isMobile) {
      this.deviceType = "Mobile";
    } else if (this.isDesktop) {
      this.deviceType = "Desktop";
    } else {
      this.deviceType = "Tablet";
    }
  

    let parameters = {
      ...params,
      userId: this.userId,
      deviceName: this.deviceType,
    };
    this.apiCallService
      .apiCall("post", environment.base_url + "/user/v1/analytics", {
        eventName,
        parameters,
      })
      .subscribe((res: any) => {
      
      });

      var eventValue = ''
      if(params.inputData){
        eventValue = params.inputData
      }else if(params.contentId){
        eventValue = params.contentId
      }
      gtag('send', 'event', {
        eventCategory: eventName,
        eventLabel:params.screenName,
        eventAction:  params.log ,
        eventValue: eventValue
      }); 
  }
}

