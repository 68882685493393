<!--/*******************************
 * Component -  More services
 * Created By - Akanksha J
 * Created on - 28/03/2020
 * Updated By - Vinita Sharma
 * Updated On - 10/28/2020
 *************************************/-->
 <div class='overlap-container'>
    <!-- comingsoon image -->
 <div  *ngIf="enableFeature == false" class="coming-soon-container">
     <img src="{{images.comingSoon}}" class="coming-soon-image" alt="ComingSoon" loading="lazy">
 </div>

<!-- heading -->
<div class="feed-title" *ngIf="dataNotFoundMsg && result.length == 0">
 <label class="font-heading">{{dealListingLabels[selectedLanguage].offers | uppercase }}</label>
</div>

 <!-- connection lost condition -->
 <div *ngIf='serverMessage' class='connectionDiv'>
     <img src={{images.connectionLost}} class='connectionLostImg' loading="lazy" alt="...">
     <span>{{serverMessage}}</span>
 </div>
 <!-- main page loader -->
 <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
     <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
     </mat-progress-spinner>
 </div>
<!-- data not found error handling -->
 <ng-container *ngIf="dataNotFoundMsg && result.length == 0">
     <div class="error-messages" (mouseenter)="speakText('no deals available')"  (mouseleave)="stopSpeaking()">
         <img src="{{images.noDataFound}}" class="no-data-image-favourite" loading="lazy" alt="...">
     <span class="data-not-found">{{dataNotFoundMsg}}</span>
     </div>
 </ng-container>

<div class="row-heading" *ngIf=" !dataNotFoundMsg">
     <div class="post-navigation-merchant font-sub-heading" >
         <p matTooltipClass="engagement-tooltip " matTooltip="{{merchantDetails?.displayCompanyName}}" 
         (mouseenter)="speakText(dealListingLabels[selectedLanguage].moreservices)"  (mouseleave)="stopSpeaking()">
             <span [routerLink]="[ '/cep/offers']" class="feeds-redirection font-card-heading">
             {{dealListingLabels[selectedLanguage].moreservices | uppercase}}
             </span> > {{merchantDetails?.displayCompanyName}}
         </p>
     </div>
     <!-- <div class="sort-by font-normal" (mouseenter)="speakText(sortByLabelsfor[selectedLanguage].sortBy)"  (mouseleave)="stopSpeaking()">{{sortByLabelsfor[selectedLanguage].sortBy}}
         <button mat-button [matMenuTriggerFor]="sort" (mouseenter)="speakText(sortByLabelsfor[selectedLanguage].sortBy)"  (mouseleave)="stopSpeaking()">
             <img class="sort-image pointer" src="{{images.downArrow}}" alt="">
         </button>
         <mat-menu #sort="matMenu">
             <ng-container *ngFor="let data of sortByListingArrayValue; let i = index">
                 <button mat-menu-item *ngIf="data[0] !== 'sortBy'" (click)="getEngagementSortBy(data[0])"> -->
                   <!-- {{data[1]}}  -->
                   <!-- <div id="{{data[0]}}" (mouseenter)="speakText(data[0])"  (mouseleave)="stopSpeaking()">
                     {{data[1]}}
                   </div>
                 </button>
             </ng-container>
         </mat-menu>
     </div> -->
</div>
<!--  [disabled]="data[0] == 'sortBy'" -->

 <!-- main page -->
 <div *ngIf='!isLoading && !serverMessage && result.length > 0' class='main-container'>
     <div class='service-container'>
         <hr>
         <div class="category-list">
             <div class='services-list-deal' *ngIf = "result.length > 0">
                 <div *ngFor = "let item of result;let i=index;">
                     <div class="container" *ngIf="item.quantity > 0">
                         <div class="image-container">
                             <img matTooltip="{{dealListingLabels[selectedLanguage].dealDetail}}" (click)="openDetail(item)" class="deals-image" src="{{item?.image[0]}}" loading="lazy" alt="...">
                             <img matTooltip="{{dealListingLabels[selectedLanguage].merchantDetail}}" (click)="openMerchantDetail()"  class="merchant-icon" src="{{item.logo?item.logo:images.defaultMerchantLogo}}" loading="lazy" alt="...">
                         </div>
                         <div class="info-container">
                             <div class="name-and-button">
                                 <div class="name-container">
                                     <label (click)="openMerchantDetail()" class="name font-sub-heading" matTooltip="{{item[selectedLanguage].productName}}" (mouseenter)="speakText(item[selectedLanguage].productName)"  (mouseleave)="stopSpeaking()">{{item[selectedLanguage].productName}}</label>
                                 </div>
                                 <!-- <button class="add-button" mat-flat-button>
                                     <label class="label">ADD</label>
                                 </button> -->
                                 
                             </div>

                             <div class="add-to-cart-buttons">
                                 <label class="addr font-small" matTooltip="{{item.displayCompanyName}}" (mouseenter)="speakText(item.displayCompanyName)"  (mouseleave)="stopSpeaking()">{{item.displayCompanyName}}</label>
                                 <label (mouseenter)="speakText(dealListingLabels[selectedLanguage].quantity)"  (mouseleave)="stopSpeaking()"> {{dealListingLabels[selectedLanguage].quantity}}</label>
                                 <button class="quantity" [disabled]="!cartLoaded || item.user_quantity == 0 || item.quantity == 0" (click) = "removeToCart(item, i)" (mouseenter)="speakText('remove from cart')"  (mouseleave)="stopSpeaking()">-</button>
                                 <label class="count"  (mouseenter)="speakText(item.user_quantity + 'quantity in cart')"  (mouseleave)="stopSpeaking()">{{item.user_quantity}}</label>
                                 <button class="quantity" [disabled]="!cartLoaded || item.user_quantity === 5" (click) = "addToCart(item, i)"  (mouseenter)="speakText('add to cart')"  (mouseleave)="stopSpeaking()">+</button>
                                 </div>

                             


                             <div class="offer" (mouseenter)="speakText(item[selectedLanguage].description)"  (mouseleave)="stopSpeaking()">
                                 <img class="sale-blue" src="../../../../../assets/images/more-services/sale-blue.png" loading="lazy" alt="...">
                                 {{item[selectedLanguage].description}}
                             </div>
                             <div class="deals-sold"  (mouseenter)="speakText(item.quantity + dealListingLabels[selectedLanguage].dealsAvailable)"  (mouseleave)="stopSpeaking()">
                                 <div class="font-small">{{item.quantity}} {{dealListingLabels[selectedLanguage].dealsAvailable}}</div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div><br>
               <!-- loader -->
               <div *ngIf="isNewDealsLoading" class="new-feed-list-spinner center">
                 <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
                 </mat-progress-spinner>
             </div>
             <ng-container *ngIf="isSeeMoreButtonShow && !isNewDealsLoading && result.length >= 5 && noMoreData == false">
                 <div class="load-more-button-allignment">
                   <button mat-button (click)="onScroll()" class="load-more-button" (mouseenter)="speakText(dealListingLabels[selectedLanguage].showMore)"  (mouseleave)="stopSpeaking()">
                     {{dealListingLabels[selectedLanguage].showMore}}
                   </button>
                 </div>
               </ng-container>
         </div>
     </div>
 </div>
</div>

