import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import * as FeedsAction from '../action/feeds.action';
import { FeedsService } from '../api-services/feeds.service';
import { LoginService } from '../../../login-management/store/api-services/login.service';
import { of } from 'rxjs';

@Injectable()

export class FeedsEffect {
	constructor(
		private actions$: Actions,
		private feedsService: FeedsService,
		private loginService: LoginService,
	) {
	}


	apiRequestListing$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetFeedsListing>(FeedsAction.GET_FEEDS_LISTING),
			switchMap((action) => {
				return this.feedsService.getFeedsListing(action.payload).pipe(
					map(feedsData => new FeedsAction.GetFeedsListingSuccess(feedsData)),
					catchError(error => of(new FeedsAction.GetFeedsListingFail(error)))
				);
			})
		)
	);

	apiRequestListingEmergency$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetFeedsListingEmergency>(FeedsAction.GET_FEEDS_LISTING_EMERGENCY),
			switchMap((action) => {
				return this.feedsService.getFeedsListing(action.payload).pipe(
					map(feedsDataEmergency => new FeedsAction.GetFeedsListingEmergencySuccess(feedsDataEmergency)),
					catchError(error => of(new FeedsAction.GetFeedsListingEmergencyFail(error)))
				);
			})
		)
	);

	apiRequestFeedDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetEngeagementDetails>(FeedsAction.GET_ENGEAGEMENT_DETAILS),
			switchMap((action) => {
				return this.feedsService.getFeedDetails(action.payload).pipe(
					map(feedsData => new FeedsAction.GetEngeagementDetailsSuccess(feedsData)),
					catchError(error => of(new FeedsAction.GetEngeagementDetailsFail(error)))
				);
			})
		)
	);

	AddOrRemoveBlogComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.AddOrRemoveBlogComment>(FeedsAction.ADD_OR_REMOVE_BLOG_COMMENT),
			switchMap((action) => {
				return this.feedsService.addOrRemoveBlogComment(action.payload).pipe(
					map(blogCommentData => new FeedsAction.AddOrRemoveBlogCommentSuccess(blogCommentData)),
					catchError(error => of(new FeedsAction.AddOrRemoveBlogCommentFail(error)))
				);
			})
		)
	);

	getAllBlogComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetAllBlogComments>(FeedsAction.GET_ALL_BLOG_COMMENTS),
			switchMap((action) => {
				return this.feedsService.getAllBlogComment(action.payload).pipe(
					map(blogCommentData => new FeedsAction.GetAllBlogCommentsSuccess(blogCommentData)),
					catchError(error => of(new FeedsAction.GetAllBlogCommentsFail(error)))
				);
			})
		)
	);

	getFeedsEmergency = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetFeedsEmergency>(FeedsAction.GET_FEEDS_EMERGENCY),
			switchMap((action) => {
				return this.feedsService.getFeedsEmergency(action.payload).pipe(
					map(feedEmergencyData => new FeedsAction.GetFeedsEmergencySuccess(feedEmergencyData)),
					catchError(error => of(new FeedsAction.GetFeedsEmergencyFail(error)))
				);
			})
		)
	);

	addBlog$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.AddBlog>(FeedsAction.ADD_BLOG),
			switchMap((action) => {
				return this.feedsService.addBlog(action.payload).pipe(
					map(blogData => new FeedsAction.AddBlogSuccess(blogData)),
					catchError(error => of(new FeedsAction.AddBlogFail(error)))
				);
			})
		)
	);

	addForum$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.AddForum>(FeedsAction.ADD_FORUM),
			switchMap((action) => {
				return this.feedsService.addForum(action.payload).pipe(
					map(forumData => new FeedsAction.AddForumSuccess(forumData)),
					catchError(error => of(new FeedsAction.AddForumFail(error)))
				);
			})
		)
	);

	updateForum$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.UpdateForum>(FeedsAction.UPDATE_FORUM),
			switchMap((action) => {
				return this.feedsService.updateForum(action.payload).pipe(
					map(forumData => new FeedsAction.UpdateForumSuccess(forumData)),
					catchError(error => of(new FeedsAction.UpdateForumFail(error)))
				);
			})
		)
	);

	removeForum$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.RemoveForum>(FeedsAction.REMOVE_FORUM),
			switchMap((action) => {
				return this.feedsService.removeForum(action.payload).pipe(
					map(forumData => new FeedsAction.RemoveForumSuccess(forumData)),
					catchError(error => of(new FeedsAction.RemoveForumFail(error)))
				);
			})
		)
	);

	getForumByUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetForumByUser>(FeedsAction.GET_FORUM_BY_USER),
			switchMap((action) => {
				return this.feedsService.getForumByUser(action.payload).pipe(
					map(forumData => new FeedsAction.GetForumByUserSuccess(forumData)),
					catchError(error => of(new FeedsAction.GetForumByUserFail(error)))
				);
			})
		)
	);

	reportAbuse$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.ReportAbuse>(FeedsAction.REPORT_ABUSE),
			switchMap((action) => {
				return this.feedsService.reportAbuse(action.payload).pipe(
					map(abuseDetails => new FeedsAction.ReportAbuseSuccess(abuseDetails)),
					catchError(error => of(new FeedsAction.ReportAbuseFail(error)))
				);
			})
		)
	);

	reportAbuseComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.ReportAbuseComment>(FeedsAction.REPORT_ABUSE_COMMENT),
			switchMap((action) => {
				return this.feedsService.reportAbuseComment(action.payload).pipe(
					map(abuseCommentDetails => new FeedsAction.ReportAbuseCommentSuccess(abuseCommentDetails)),
					catchError(error => of(new FeedsAction.ReportAbuseCommentFail(error)))
				);
			})
		)
	);

	ReportWebinarAbuse$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.ReportWebinarAbuse>(FeedsAction.REPORT_WEBINAR_ABUSE),
			switchMap((action) => {
				return this.feedsService.reportWebinarAbuse(action.payload).pipe(
					map(abuseWebinarDetails => new FeedsAction.ReportWebinarAbuseSuccess(abuseWebinarDetails)),
					catchError(error => of(new FeedsAction.ReportWebinarAbuseFail(error)))
				);
			})
		)
	);


	ReportWebinarAbuseComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.ReportWebinarAbuseComment>(FeedsAction.REPORT_WEBINAR_ABUSE_COMMENT),
			switchMap((action) => {
				return this.feedsService.reportWebinarAbuseComment(action.payload).pipe(
					map(abuseWebinarCommentDetails => new FeedsAction.ReportWebinarAbuseCommentSuccess(abuseWebinarCommentDetails)),
					catchError(error => of(new FeedsAction.ReportWebinarAbuseCommentFail(error)))
				);
			})
		)
	);

	registrationCompetition$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.ReportAbuse>(FeedsAction.REGISTRATION_COMPETITION),
			switchMap((action) => {
				return this.feedsService.registrationCompetition(action.payload).pipe(
					map(data => new FeedsAction.RegistrationCompetitionSuccess(data)),
					catchError(error => of(new FeedsAction.RegistrationCompetitionFail(error)))
				);
			})
		)
	);

	
	submitCompetition$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.ReportAbuse>(FeedsAction.SUBMIT_COMPETITION),
			switchMap((action) => {
				return this.feedsService.submitCompetition(action.payload).pipe(
					map(submitCompetitionDetail => new FeedsAction.SubmitCompetitionSuccess(submitCompetitionDetail)),
					catchError(error => of(new FeedsAction.SubmitCompetitionFail(error)))
				);
			})
		)
	);


	userNotification$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetUserNotification>(FeedsAction.GET_USER_NOTIFICATION),
			switchMap((action) => {
				return this.feedsService.userNotification(action.payload).pipe(
					map(submitCompetitionDetail => new FeedsAction.GetUserNotificationSuccess(submitCompetitionDetail)),
					catchError(error => of(new FeedsAction.GetUserNotificationFail(error)))
				);
			})
		)
	);


	getBlogListingByUserId$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetBlogListingByUserId>(FeedsAction.GET_BLOG_LISTING_BY_USER_ID),
			switchMap((action) => {
				return this.feedsService.getBlogListingByUserId(action.payload).pipe(
					map(blogListing => new FeedsAction.GetBlogListingByUserIdSuccess(blogListing)),
					catchError(error => of(new FeedsAction.GetBlogListingByUserIdFail(error)))
				);
			})
		)
	);


	getForumListingByUserId$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetForumListingByUserId>(FeedsAction.GET_FORUM_LISTING_BY_USER_ID),
			switchMap((action) => {
				return this.feedsService.getForumListingByUserId(action.payload).pipe(
					map(ForumListing => new FeedsAction.GetForumListingByUserIdSuccess(ForumListing)),
					catchError(error => of(new FeedsAction.GetForumListingByUserIdFail(error)))
				);
			})
		)
	);


	getBlogByBlogId$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetBlogByBlogId>(FeedsAction.GET_BLOG_BY_BLOG_ID),
			switchMap((action) => {
				return this.feedsService.getBlogByBlogId(action.payload).pipe(
					map(blogDetailById => new FeedsAction.GetBlogByBlogIdSuccess(blogDetailById)),
					catchError(error => of(new FeedsAction.GetBlogByBlogIdFail(error)))
				);
			})
		)
	);


	editBlog$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.EditBlog>(FeedsAction.EDIT_BLOG),
			switchMap((action) => {
				return this.feedsService.editBlog(action.payload).pipe(
					map(editBlog => new FeedsAction.EditBlogSuccess(editBlog)),
					catchError(error => of(new FeedsAction.EditBlogFail(error)))
				);
			})
		)
	);


	getForumByForumId$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.GetForumByForumId>(FeedsAction.GET_FORUM_BY_FORUM_ID),
			switchMap((action) => {
				return this.feedsService.getForumByForumId(action.payload).pipe(
					map(forumDetailById => new FeedsAction.GetForumByForumIdSuccess(forumDetailById)),
					catchError(error => of(new FeedsAction.GetForumByForumIdFail(error)))
				);
			})
		)
	);


	editForum$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.EditForum>(FeedsAction.EDIT_FORUM),
			switchMap((action) => {
				return this.feedsService.editForum(action.payload).pipe(
					map(editForum => new FeedsAction.EditForumSuccess(editForum)),
					catchError(error => of(new FeedsAction.EditForumFail(error)))
				);
			})
		)
	);


	updateBlogPhoto$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.UpdateBlogPhoto>(FeedsAction.UPDATE_BLOG_PHOTO),
			switchMap((action) => {
				return this.loginService.updateProfilePhoto(action.payload).pipe(
					map(updateUserProfileData => new FeedsAction.UpdateBlogPhotoSuccess(updateUserProfileData)),
					catchError(error => of(new FeedsAction.UpdateBlogPhotoFail(error)))
				);
			})
		)
	);


	updateBlogPhotoFav$ = createEffect(() =>
		this.actions$.pipe(
			ofType<FeedsAction.UpdateBlogPhotoFav>(FeedsAction.UPDATE_BLOG_PHOTO_FAV),
			switchMap((action) => {
				return this.loginService.updateProfilePhoto(action.payload).pipe(
					map(updateData => new FeedsAction.UpdateBlogPhotoFavSuccess(updateData)),
					catchError(error => of(new FeedsAction.UpdateBlogPhotoFavFail(error)))
				);
			})
		)
	);

}
