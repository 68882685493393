import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { environment } from 'src/environments/environment';
import { Store } from "@ngrx/store";
import { Images } from 'src/app/shared/config/images-config';
import * as fromStore from "../../../store/index";
import { OfficerDashboardLinksComponent } from '../officer-dashboard-links/officer-dashboard-links.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCard, MatCardContent } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss'],
    standalone: true,
    imports: [MatIcon, NgClass, FormsModule, NgIf, NgFor, MatCard, MatCardContent]
})
export class ContactListComponent implements OnInit {
  UserProfileDetails: any;
  contact:any;
  filtercontact:any;
  contactList:any;
  filtercontactList:any;
  searchTerm:any;
  images = Images;
  showContactListData: boolean = false;
  showFilteredContactData: boolean = false;
  dataNotFound: boolean = false;
  HODloader:boolean = false;
  otherLoader: boolean = false;
  color = "primary";
  mode = "indeterminate";
  value = 50;
  constructor(private http: HttpClient,  private store: Store<fromStore.CEPState>, private dialog: MatDialog,private apiCallService: ApiCallService, private dialogRef: MatDialogRef<OfficerDashboardLinksComponent>) { }

  ngOnInit() {
    this.store.select(fromStore.getVerifyPasswordResponse)
    .subscribe((data) => {
      if (data) {
        this.UserProfileDetails = data;
        // console.log("suvarna",this.UserProfileDetails);
        
      }
    });
    this.getContacts()
    this.getContactsList()
    this.showFilterContact()
    
  }

  showContactList() {
    this.showFilteredContactData = false; 
    this.showContactListData = true;
    this.searchTerm = "";
    this.filtercontactList = this.contactList;
  }
  
  showFilterContact() {
    this.showContactListData = false; 
    this.showFilteredContactData = true;
    this.searchTerm = "";this.filtercontact = this.contact

  }
  
  getContacts() {
    this.HODloader = true
    this.apiCallService.apiCall('post',environment.base_url + '/user/v1/contacList/getContactListHOD', {
    })
    .subscribe( (res:any) => {
       this.contact=res.result.data.nodes
       this.filtercontact = this.contact
      this.HODloader = false
    } )
  }

  getContactsList() {
    this.otherLoader = true;
    this.apiCallService.apiCall('post',environment.base_url + '/user/v1/contactList/getContactListOtherOfficer', {
    })
    .subscribe( (res:any) => {
       this.contactList=res.result.data.nodes
       this.filtercontactList = this.contactList
      this.otherLoader = false
    } )
  }

  filterData(){
    if(this.searchTerm){
      this.filtercontactList = this.contactList.filter(item =>
        `${item.node.Firstname} ${item.node.Middlename} ${item.node.Lastname}`
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase()) ||
           `${item.node.Designation}`.toLowerCase().includes(this.searchTerm.toLowerCase()) ) 
           
          this.filtercontact = this.contact.filter(item =>
            `${item.node.Departmentofficer}`
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) || 
               `${item.node.Designation}`
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())  )
            
    }else{
      this.filtercontact = this.contact;
      this.filtercontactList = this.contactList;
    }
    this.dataNotFound = (this.showContactListData && this.filtercontactList.length === 0) ||
    (this.showFilteredContactData && this.filtercontact.length === 0);
  }

  closePopup() {
    this.dialogRef.close();
  }
  

}
