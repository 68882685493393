<!-- /******************************************
 * Component -  event details screen
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 ***********************************************/ -->

<!-- loader -->
<!-- <div *ngIf="isLoading" class="spinner">
  <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div> -->
<!-- main content -->
<div>
    <div *ngIf="feedsData != undefined" class="post-navigation">
        <p matTooltipClass="engagement-tooltip" matTooltip="{{feedsData[selectedLanguage].heading}}">
            <span [routerLink]="[ '/cep/feeds']" class="feeds-redirection">
        {{ landingLables[selectedLanguage].feed | uppercase}}</span> > Water Tap</p>
    </div>
    <div class="post-container">
        <div class="post-image">
            <app-details-page-slider [postImages]="feedsData.image"></app-details-page-slider>
        </div>
        <div class="post-details">
            <div class="post-description">
                <div class="post-type">
                    <p>PRODUCT</p>
                    <button mat-raised-button class="mat-button">{{feedLabels[selectedLanguage][feedsData.type]}}</button>
                </div>
                <div class="post-heading font-heading">
                    <p>{{feedsData[selectedLanguage].heading}}</p>
                </div>
                <div *ngIf="feedsData[selectedLanguage].content" class="post-containt">
                    <p [innerHTML]="feedsData[selectedLanguage].content"></p>
                </div>
                <div class="post-containt">
                    <label class="website-link">{{feedLabels[selectedLanguage].websiteLink}}</label>
                    <p><a href="{{feedsData.webLink}}" target="_blank">{{feedsData.webLink}}</a></p>
                </div>
            </div>
            <div class="post-date-type">
                <div class="booking-sharing">
                    <div class="booking">
                        <button mat-raised-button class="mat-button" disabled>{{feedLabels[selectedLanguage].bookYourSeat}}</button>
                    </div>
                    <!-- share options -->
                    <div class="sharing">
                        <div class="abuse-flag-container">
                            <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon>
                        </div>
                        <div *ngIf="this.userId" (click)="clickOnShare()"><i class="material-icons" [matMenuTriggerFor]="menu">
                share
              </i></div>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="generateShareLink('facebook')">
                Facebook</button>
                            <button mat-menu-item (click)="generateShareLink('twitter')">
                Twitter</button>
                            <button mat-menu-item (click)="generateShareLink('linkedin')">
                LinkedIn</button>
                <button mat-menu-item (click)="generateShareLink('whatsapp')">
                    <!-- <img class="social-share-img whatsapp-share" src="{{images.whatsappShareImg}}"> -->
                    Whatsapp
                  </button>
                        </mat-menu>
                        
                        <div *ngIf="this.userId"><i class="material-icons favorite-icon" [ngClass]="{'active-favorite':feedsData.favourite}" (click)="unMarkFavorite()">
                {{favorite}}
              </i></div>
                    </div>
                </div>
                <!-- event details -->
                <div class="post-date-venue">
                    <!-- <div class="post-date">
              <label>{{feedLabels[selectedLanguage].eventDate}}</label>
              <p>{{startDate}} - {{endDate}}</p>
            </div> -->
                    <div class="post-venue-direction">
                        <!-- <div class="post-venue">
                <label>{{feedLabels[selectedLanguage].Venue}}</label>
                <p>{{feedsData[selectedLanguage].address}}</p>
              </div> -->
                        <!-- <div class="direction-icon">
                <img src="{{image.directionImage}}" (click)="redirectToGoogleMap()">
              </div> -->
                    </div>
                    <!-- posted by details -->
                    <div class="post-venue">
                        <label>{{feedLabels[selectedLanguage].postedOn}}</label>
                        <p>{{postDate}}</p>
                    </div>
                    <div class="post-venue">
                        <label>{{feedLabels[selectedLanguage].postedBy}}</label>
                        <p> - {{feedsData.createdBy}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- error messages -->
<div class="error-message" *ngIf="errMessage">
    <img src="{{images.error404}}" loading="lazy" alt="...">
    <span>{{errMessage}}</span>
</div>
<!-- <div class="top-events">
    <app-top-card-listing></app-top-card-listing>
  </div> -->