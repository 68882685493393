<!--/*******************************
 * Component -  Deal detail page
 * Created By - Neha R
 * Created on - 1/04/2020
 * Updated By - Neha R
 * Updated On - 1/04/2020
 *************************************/-->

 <div class='overlap-container'>
    <!-- connection lost condition -->
    <div *ngIf='serverMessage' class='connectionDiv'>
        <img src={{image.connectionLost}} loading="lazy" alt="..." class='connectionLostImg'>
        <span>{{serverMessage}}</span>
    </div>

      <!-- heading -->
      <div class="feed-title">
        <label class="font-heading">{{myDealsLables[selectedLanguage].dealHistory | uppercase }}</label>
    </div>

    <!-- main page loader -->
    <div *ngIf='isLoading && !serverMessage' class='spinner-loader'>
        <mat-progress-spinner [diameter]="35" class='example-margin' [color]='color' [mode]='mode' [value]='value'>
        </mat-progress-spinner>
    </div>
    <!-- data not found error handling -->
    <ng-container *ngIf="dataNotFoundMsg">
        <div class="error-messages">
            <img src="{{images.noDataFound}}" class="no-data-image-favourite" loading="lazy" alt="..."
            (mouseenter)="speakText(errorMessages[selectedLanguage]['500'])"  (mouseleave)="stopSpeaking()">
        <span class="data-not-found">{{errorMessages[selectedLanguage]['500']}}</span>
        </div>
    </ng-container>
     <!-- main page -->

     <div  *ngIf='!isLoading && !dataNotFoundMsg' class='main-container'>
        <mat-card class="my-deal-card" >
            <!-- <div class="deal-name font-sub-heading"
            (mouseenter)="speakText(myDealsLables[selectedLanguage].dealHistory)"  (mouseleave)="stopSpeaking()">{{myDealsLables[selectedLanguage].dealHistory | uppercase }}</div> -->
            <div class="mini-card" *ngIf="myDealsLables">
                <div *ngFor = "let item of myDeal">
                    <div class="each-info-card">
                        <div class="order font-normal"
                        (mouseenter)="speakText(myDealsLables[selectedLanguage].orderNo + item.orderId)"  (mouseleave)="stopSpeaking()">{{myDealsLables[selectedLanguage].orderNo | uppercase }} {{item.orderId}}</div>
                        <mat-card class="my-deal-mini-card" (click)="redirectToDetailScreenOfDeal(item)">
                            <div class="each-card-detail">
                                <div class="each-image">
                                    <img class="image" src={{item.image[0]}} loading="lazy" alt="..." (mouseenter)="speakText(item.displayCompanyName)"  (mouseleave)="stopSpeaking()">
                                </div>
                                <div class="each-detail">
                                        <span class="status-Scanned" *ngIf="item.isMerchantScans" >{{myDealsLables[selectedLanguage].qrScanned}}</span>
                                        <span class="status-not-scanned" *ngIf="!item.isMerchantScans">{{myDealsLables[selectedLanguage].qrNotScanned}}</span>
                                    <div class="deal-info-heading font-heading"
                                    (mouseenter)="speakText(item.displayCompanyName)"  (mouseleave)="stopSpeaking()">{{item.displayCompanyName}}</div>
                                    <div class="sub-heading font-medium"
                                    (mouseenter)="speakText(item[selectedLanguage].productName)"  (mouseleave)="stopSpeaking()">{{item[selectedLanguage].productName}}  </div>
                                    <div class="sub-heading font-medium"
                                    (mouseenter)="speakText(myDealsLables[selectedLanguage].quantity + item.quantity)"  (mouseleave)="stopSpeaking()">{{myDealsLables[selectedLanguage].quantity}}: {{item.quantity}}</div>
                                    <div class="rupees" style="display: none;">
                                        <div class="price font-medium" (mouseenter)="speakText(myDealsLables[selectedLanguage].price)"  (mouseleave)="stopSpeaking()">{{myDealsLables[selectedLanguage].price}}: ₹</div>
                                        <div class="digit font-medium" (mouseenter)="speakText('final price' + item.actualAmount)"  (mouseleave)="stopSpeaking()">{{item.actualAmount}}</div>
                                        <div class="off-percentage font-small" *ngIf=" selectedLanguage === 'en' " (mouseenter)="speakText('Saved ₹' + item.saveAmount)"  (mouseleave)="stopSpeaking()">Saved ₹ {{item.saveAmount}}</div>
                                        <div class="off-percentage font-small" *ngIf=" selectedLanguage === 'mar' " (mouseenter)="speakText('Saved ₹' + item.saveAmount)"  (mouseleave)="stopSpeaking()">₹ {{item.saveAmount}} वाचवले</div>
                                    </div>
                                    <div class="date font-normal" (mouseenter)="speakText('end date' + item.endDate)"  (mouseleave)="stopSpeaking()">{{item.updatedAt | date:'medium'}}</div>
                                    <!-- <div (click)="returnMoreService()" class="return-button">{{myDealsLables[selectedLanguage].return}}</div> -->
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>

