import { Component, OnInit, ChangeDetectorRef, Pipe, HostListener, Input, AfterContentChecked, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../cep/store/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { FeedbackComponent } from '../feedback/feedback.component';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Images } from '../../config/images-config';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectInterface } from '../../models/global-interface';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Location, NgIf, NgClass, NgFor, UpperCasePipe, SlicePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { screenName, clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ClipboardService } from '../../services/clipboard.service';
import { environment } from 'src/environments/environment';
import { RelatedArticleComponent } from '../related-article/related-article.component';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { DetailsPageSliderComponent } from '../details-page-slider/details-page-slider.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

// @Pipe({ name: 'safeHtml' })

@Component({
    selector: 'app-details-page-blog',
    templateUrl: './details-page-blog.component.html',
    styleUrls: ['./details-page-blog.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinner,
        MatTooltip,
        DetailsPageSliderComponent,
        MatIcon,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        NgClass,
        FormsModule,
        MatButton,
        NgFor,
        RelatedArticleComponent,
        UpperCasePipe,
        SlicePipe,
    ],
})


export class DetailsPageBlogComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {
	/**
	 * To fetch data frpm card-blog
	 */
	@Input() blogData: any;
	/**
	 * feedsData object
	 */
	public feedsData;
	/**
	 * fetched images from image.config
	 */
	images = Images;
	/**
	 * api details response object
	 */
	apiDetails: ObjectInterface;
	/**
	 * timelineId of blog
	 */
	timelineId: any;
	/**
	 * state of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * value of spinner
	 */
	value = 50;
	/**
	 * loading status of spinner
	 */
	isLoaded = false;
	/**
	 * loading status of spinner
	 */
	isLoading = false;
	/**
	 * selected language variable
	 */
	selectedLanguage: string;
	/**
	 * object for landing lables
	 */
	landingLables: ObjectInterface;
	/**
	 * feeds date variable
	 */
	feedsDate: string;
	/**
	 * favorite variable
	 */
	favorite: string;
	/**
	 * userId variable to store user id
	 */
	userId;
	isBlind;
	/**
	 * token variable to store user token
	 */
	token: string;
	/**
	 * error message
	 */
	errMessage: string;
	/**
	 * feedFilterLabels for forum name
	 */
	feedFilterLabels: ObjectInterface;
	/**
	 * feedLabels for postedOn and postedBy
	 */
	feedLabels: ObjectInterface;
	/**
	 * feeds status code
	 */
	feedStatusCode: number;
	/**
	 * shareUrl variable
	 */
	shareUrl: any;
	/**
	 * object for user details
	 */
	UserProfileDetails: ObjectInterface;
	/**
	 * variable for blog comment
	 */
	blogComment: any;
	/**
	 * object for successMessages
	 */
	successMessages: ObjectInterface;
	commentsById: ObjectInterface;
	/**
	 * object for feed details result
	 */
	feedDetailsResult: ObjectInterface;
	/**
	 * array for feed details result
	 */
	feedDetailsResultArray = [];
	/**
	 * array for feed details comments
	 */
	feedDetailsComments: ObjectInterface[];
	/**
	 * feedsContent variable
	 */
	feedsContent: any;
	/**
	 * variable for comment date
	 */
	commentDate: any;
	/**
	 * object for  remove blog comment
	 */
	removeBlogCommentData: ObjectInterface;
	blogId: any;
	isUserAdded = false;
	isApproved: any;
	blogLabels: {};
	isLoadingShareLink = false;

	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particulat component
	 */

	constructor(
		private activeParams: ActivatedRoute,
		private store: Store<fromStore.CEPState>,
		private changeDetectRef: ChangeDetectorRef,
		private spinner: NgxSpinnerService,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		public dialog: MatDialog,
		private domSanitizer: DomSanitizer,
		private deviceService: DeviceDetectorService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private toastr: ToastrService,
		private router: Router,
		private apiCallService: ApiCallService,
		private location:Location,
		private clipboardService: ClipboardService,
		private dynamicLink: DynamicLinksService
	) {
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					this.userId = this.encrDecr.get(this.localStorageService.getUserId());
					this.isBlind = this.localStorageService.getIsBlind();
					//this.googleAnalyticsService.eventEmitter('Dashboard', 'Click on blog by ' + this.userId, 'userLabel', 1);
				} else {
					this.userId = null;
				}
			});
		this.store.select(fromStore.getBlogLabels)
			.subscribe(res => {
				if (res) {
					this.blogLabels = res;
				}
			});
	}
	ngOnInit() {

		this.googleAnalyticsService.eventEmitter(
			screenName.BLOG_DETAILS+ "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.BLOG_DETAILS + "_MOUNT",
			  screenStartTime: new Date(),
			  isScroll: false,
			  screenEndTime: "",
			  scrollValue: "",
			}
			);	
		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});
		this.activeParams.params.subscribe((params:any) => {

			this.timelineId = params.timelineId;
		


			this.store.dispatch(new fromStore.CheckTimeline({
				timelineId: this.timelineId
			}))

			// this.store.select(fromStore.isTimelineChnaged)
			// 	.subscribe((isTimeline) => {
			// 		if (isTimeline && Object.keys(this.apiDetails).length > 0) {
			// 			this.shareUrl = this.apiDetails.shareUrl;
			// 			this.store.dispatch(new fromStore.GetEngeagementDetails({
			// 				url: this.apiDetails.userEngagement.base_url +
			// 					this.apiDetails.userEngagement.timeline.getEngagementByOwner.url,
			// 				method: this.apiDetails.userEngagement.timeline.getEngagementByOwner.method,
			// 				body: {
			// 					timelineId: this.timelineId,
			// 					// userId: this.userId
			// 				}
			// 			}))
			// 			// window.scrollTo(0, 0);
			// 		}
			// 	})

			// this.store.select(fromStore.isDetailsScreenLoading)
			// 	.subscribe(isLoading => {
			// 		this.isLoading = isLoading;
			// 	});
			// this.store.select(fromStore.isDetailsScreenLoaded)
			// 	.subscribe(isLoaded => {
			// 		this.isLoaded = isLoaded;
			// 		if (this.isLoaded) {
			// 			this.store.select(fromStore.getFeedsServerErrorMessage)
			// 				.subscribe((error) => {
			// 					this.errMessage = error;
			// 					if (this.errMessage) {
			// 					}
			// 				});

			// 			this.store.select(fromStore.feedDetailsResult)
			// 				.subscribe((feedsData) => {
			// 					if (Object.keys(feedsData).length > 0) {
			// 						if (params.isBlogFrom == 'feeds') {
			// 							this.isUserAdded = false;
			// 						}
			// 						else if (params.isBlogFrom == 'myBlog') {
			// 							this.isUserAdded = true;
			// 						}
			// 						this.feedsData = feedsData;
			// 						this.blogComment = null;
			// 						this.isApproved = this.feedsData.isApproved
			// 						this.feedDetailsResultArray = this.feedsData.comments;
			// 						if (this.feedDetailsResultArray) {
			// 							this.feedDetailsResultArray.forEach((element: any) => {
			// 								if (element.comment.length > 50) {
			// 									element['visible'] = false;
			// 								} else {
			// 									element['visible'] = true;
			// 								}
			// 							});
			// 						}

			// 						this.feedsDate = new Date(this.feedsData.updatedAt).toDateString();
			// 						try {
			// 							this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
			// 							if (this.feedsData.favourite) {
			// 								this.favorite = 'favorite';
			// 							} else {
			// 								this.favorite = 'favorite_border';
			// 							}
			// 						} catch (err) { }
			// 					}
			// 				});
			// 		}
			// 	});


		});

		// let link = "https://api.pmccare.in/userEngagement/v1" +
		// 	"/getEngagement";
		let link = this.apiDetails['userEngagement'].base_url + this.apiDetails['userEngagement'].timeline.getEngagement.url
		this.apiCallService.apiCall(this.apiDetails['userEngagement'].timeline.getEngagement.method, link,
			{
				"timelineId": this.timelineId,
				"userId": this.userId
			}).subscribe((res: any) => {
				this.feedsData = res.result
				this.feedsDate = new Date(this.feedsData.updatedAt).toDateString();
				this.blogComment = null;
				this.isApproved = this.feedsData.isApproved
				this.feedDetailsResultArray = this.feedsData.comments;
				if (this.feedDetailsResultArray) {
					this.feedDetailsResultArray.forEach((element: any) => {
						if (element.comment.length > 50) {
							element['visible'] = false;
						} else {
							element['visible'] = true;
						}
					});
				}
				try {
					this.feedsContent = this.domSanitizer.bypassSecurityTrustHtml(this.feedsData[this.selectedLanguage].content);
					if (this.feedsData.favourite) {
						this.favorite = 'favorite';
					} else {
						this.favorite = 'favorite_border';
					}
				} catch (err) { }
			})

		this.store.select(fromStore.getFeedsListingErrorMessage)
			.subscribe(errMessage => {
				if (errMessage) {
					this.errMessage = errMessage;
				}
			});
			this.store
    .select(fromStore.getApiCallingList)
    .subscribe((apiDetails) => {
      this.apiDetails=apiDetails;      
    });

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getLandingScreenLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});

				this.store.select(fromStore.getfeedsFilterLabel)
					.subscribe((labels) => {
						this.feedFilterLabels = labels;
					});

				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.feedLabels = labels;
					});
			});
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click on blog', 'userLabel', 1);
		this.store.select(fromStore.getVerifyPasswordResponse)
			.subscribe((data) => {
				this.UserProfileDetails = data;
			});
	}


	/**
		 * Created by Vinita Sharma
		 * For removing HTML tags from text
		 */

	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return this.splitSentance(span.textContent || span.innerText)
	};

	/**
   * Created by Vinita Sharma
   * to split the paragraph in sentances
   */
	splitSentance(sen) {
		if (!sen.match(/[^\.!\?]+[\.!\?]+/g)) {
			return [sen]
		} else {
			return sen.match(/[^\.!\?]+[\.!\?]+/g)
		}
	}

	/**
		 * Created by Vinita Sharma
		 * For text to speech functionality
		 */
	speakText(textToSpeak) {
		if (this.isBlind == 'true') {
			speechSynthesis.cancel();
			var newmessage = this.extractContent(textToSpeak)
			var voices = speechSynthesis.getVoices();

			for (let sentance = 0; sentance < newmessage.length; sentance++) {
				var message = new SpeechSynthesisUtterance(newmessage[sentance]);
				message.voice = voices[10];
				message.lang = 'hi-IN';
				message.volume = 1;
				speechSynthesis.speak(message);
			}
		}
	}


	/**
	 * Created by Akanksha J
	 * for reporting blog as abuse
	 */
	REPORT_ABUSE() {
		this.googleAnalyticsService.eventEmitter(screenName.BLOG_DETAILS, {
			log: clickedOn.REPORT_ABUSE,
			screenName: screenName.BLOG_DETAILS + "_SCREEN",
			SECTION: screenName.BLOG_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.REPORT_ABUSE,
			time: new Date(),
			isInput: false,
			contentId:this.blogData.timelineId
		  })
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.feedsData.timelineId,
				engagementType: 'blog',
				engagementId: this.feedsData.engagementId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}
	/**
	 * Created By Neha R
	 * For reporting comment as abuse
	 */
	reportComment(data) {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.feedsData.timelineId,
				engagementType: 'blog',
				engagementId: this.feedsData.engagementId,
				commentId: data.commentId
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
	}

	/**
	 * Created by Akanksha j
	 * for adding comment on blog
	 */
	async addBlogComment(comment) {
		let commentData = {};
		commentData['userId'] = this.userId;
		commentData['updatedBy'] = this.UserProfileDetails['firstName'];
		commentData['firstName'] = this.UserProfileDetails['firstName'];
		commentData['middleName'] = this.UserProfileDetails['middleName'];
		commentData['lastName'] = this.UserProfileDetails['lastName'];
		commentData['comment'] = comment;
		commentData['updatedAt'] = Date();
		commentData['engagementId'] = this.feedsData.engagementId;
		commentData['profilePhoto'] = this.UserProfileDetails['profilePhoto'];
		if (comment.length > 50) {
			commentData['visible'] = false;
		} else if (comment.length < 50) {
			commentData['visible'] = true;
		}
		this.feedDetailsResultArray.unshift(commentData);


		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				this.blogComment = '';
				this.store.dispatch(new fromStore.AddOrRemoveBlogComment({
					url: this.apiDetails['userEngagement'].base_url +
						this.apiDetails['userEngagement'].comment.addComment.url,
					method: this.apiDetails['userEngagement'].comment.addComment.method,
					body: {
						userId: this.userId,
						comment: comment,
						engagementId: this.feedsData.engagementId,
						firstName: this.UserProfileDetails['firstName'],
						middleName: this.UserProfileDetails['middleName'],
						lastName: this.UserProfileDetails['lastName'],
						profilePhoto: this.UserProfileDetails['profilePhoto']

					}
				}));
				this.store.select(fromStore.AddOrRemoveBlogCommentStatusCode)
					.subscribe(statusCode => {
						if (statusCode === 200) {
							this.store.select(fromStore.AddOrRemoveBlogCommentErrorMessage)
								.subscribe((message) => {
									if (message) {
										this.store.select(fromStore.getSuccessMessages)
											.subscribe(res => {
												this.successMessages = res;
												this.toastr.success(this.successMessages[this.selectedLanguage][message]);
											});
									}
								});
						}
					});
			} else if (!this.userId) {
				this.router.navigate(['/Login/login']);
			}
		}
	}

	async removeBlogComment(commentId, updatedBy, index) {
		this.feedDetailsResultArray.splice(index, 1);
		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				await this.store.dispatch(new fromStore.AddOrRemoveBlogComment({
					url: this.apiDetails['userEngagement'].base_url +
						this.apiDetails['userEngagement'].comment.removeComment.url,
					method: this.apiDetails['userEngagement'].comment.removeComment.method,
					body: {
						userId: this.userId,
						updatedBy,
						commentId
					}
				}));
				this.store.select(fromStore.AddOrRemoveBlogCommentStatusCode)
					.subscribe(statusCode => {
						if (statusCode === 200) {
							this.store.select(fromStore.AddOrRemoveBlogCommentErrorMessage)
								.subscribe((message) => {
									this.errMessage = message;
									// this.store.dispatch(new fromStore.GetEngeagementDetails({
									// 	url: this.apiDetails.userEngagement.base_url +
									// 		this.apiDetails.userEngagement.timeline.getEngagement.url,
									// 	method: this.apiDetails.userEngagement.timeline.getEngagement.method,
									// 	body: {
									// 		timelineId: this.timelineId,
									// 		userId: this.userId
									// 	}
									// }))
									// this.store.select(fromStore.isDetailsScreenLoading)
									// .subscribe(isLoading => {
									// 	this.isLoading = isLoading;
									// });
									if (this.errMessage) {

										this.store.select(fromStore.getSuccessMessages)
											.subscribe(res => {
												this.successMessages = res;
												this.toastr.success(this.successMessages[this.selectedLanguage][this.errMessage]);
											});
									}
								});
						}
					});

			} else if (!this.userId) {
				this.router.navigate(['/Login/login']);
			}
		}



	}

	ngAfterContentChecked() {
		this.changeDetectRef.detectChanges();
	}

	convCommentDateFormat(x) {
		this.commentDate = new Date(x).toDateString();
		return this.commentDate;
	}

	/**
	 * Created by Ajay C'
	 * For unmarking the favourite button
	 */
	unMarkFavorite() {
		if (this.feedsData.favourite) {
			this.feedsData = Object.assign({}, this.feedsData, { favourite: false });
			this.favorite = 'favorite_border';
			this.googleAnalyticsService.eventEmitter(screenName.BLOG_DETAILS, {
				log: clickedOn.UNLIKE,
				screenName: screenName.BLOG_DETAILS + "_SCREEN",
				SECTION: screenName.BLOG_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.UNLIKE,
				time: new Date(),
				isInput: false,
				contentId:this.blogData.timelineId
			  })
		} else {
			this.feedsData = Object.assign({}, this.feedsData, { favourite: true });
			this.favorite = 'favorite';
			this.googleAnalyticsService.eventEmitter(screenName.BLOG_DETAILS, {
				log: clickedOn.LIKE,
				screenName: screenName.BLOG_DETAILS + "_SCREEN",
				SECTION: screenName.BLOG_DETAILS ,
				clickedOn: clickedOn.BUTTON,
				title: clickedOn.LIKE,
				time: new Date(),
				isInput: false,
				contentId:this.blogData.timelineId
			  })
		}

		if (Object.keys(this.apiDetails).length > 0) {
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails['user'].base_url +
						this.apiDetails['user'].addToFavourite.url,
					method: this.apiDetails['user'].addToFavourite.method,
					body: {
						userId: this.userId,
						engagementId: this.feedsData.engagementId,
						engagementType: 'blog'
					}
				}));
			}
		}
	}
	/**
	 * Created by Neha
	 * For redirect to edit blog
	 */
	redirectToAddBlog(data) {
		this.router.navigate(['/cep/add-blog/' + data]);
	}

	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url = this.shareUrl + "/cep/feeds/blog/" + this.feedsData.timelineId
		var metaTag = {
			socialTitle: this.feedsData.en.heading,
			socialImageLink: this.feedsData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	}

	async clickOnShare() {
		// this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of blog', 'Click Event', 1);
		this.googleAnalyticsService.eventEmitter(screenName.FEEDS, {
			log: clickedOn.SHARE,
			screenName: screenName.FEEDS + "_SCREEN",
			SECTION: screenName.BLOG_DETAILS ,
			clickedOn: clickedOn.BUTTON,
			title: clickedOn.SHARE,
			time: new Date(),
			isInput: false,
			contentId:this.feedsData.timelineId
		  })
		let url = environment.shareurl + "/cep/feeds/blog/" + this.feedsData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toastr.success("Link Copied to Clipboard")
		}else{
			this.toastr.error("Unable to copy Link")
		}

	}


	ngAfterViewInit() {
		window.scrollTo(0, 0);
	}

	ngOnDestroy() {

		this.googleAnalyticsService.eventEmitter(
			screenName.BLOG_DETAILS + "_SCREEN",
			{
			  log: clickedOn.LOG_SCREEN,
			  screenName: screenName.BLOG_DETAILS + "_UNMOUNT",
			  screenStartTime: "",
			  isScroll: false,
			  screenEndTime: new Date(),
			  scrollValue: "",
			}
		  );
		//this.googleAnalyticsService.eventEmitter('Dashboard', 'User change the page', 'Click Event', 1);
		// if (this.userId && this.feedsData.feedback == false && this.feedsData.isApproved == true) {
		// 	const dialogRef = this.dialog.open(FeedbackComponent,
		// 		{
		// 			data: {
		// 				engagementId: this.feedsData.engagementId,
		// 				engagementType: this.feedsData.engagementType
		// 			},
		// 			maxHeight: '100vh',
		// 			maxWidth: '100vw',
		// 			autoFocus: false
		// 		});
		// }
	}



	goBack(){
		this.location.back()
	}

}
