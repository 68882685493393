<!--************************************* 
Created by: Vinita Sharma
Created Date: 25/11/2020
Description:  Terms and condition for merchant screen
***************************************** -->

<div *ngIf="loadingTC" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
 <!-- connection lost condition -->
 <div *ngIf="serverErrorMessage" class="connectionDiv">
    <img src={{images.connectionLost}} loading="lazy" alt="..." class="connectionLostImg">
    <span>{{serverErrorMessage}}</span>
</div>
<!-- main page -->
<div class="container" *ngIf="!loadingTC && !serverErrorMessage">
    <!-- heading -->
    <label class="heading font-heading"><strong class="font-fix">{{termsAndConditionData[selectedLanguage].title}}</strong></label><br>
    <!-- card of terms and conditions -->
    <mat-card class="card" *ngIf="termsAndConditionData && selectedLanguage ">
      <div class="content">
          <!-- <div class="font-medium">{{termsAndConditionData[selectedLanguage].termsAndConditions}}</div>  -->
          <p class="font-medium" [innerHTML]="termsAndConditionData[selectedLanguage].termsAndConditions"></p>
        </div>
    </mat-card>
</div>