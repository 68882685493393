<!--************************************* 
Created by: Neha R
Created Date: 17/07/20
updated by: Neha R
Updated Date:  17/07/20
Description:  Terms and condition screen
***************************************** -->
<!-- loader -->
<div *ngIf="loadingTC" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
<!-- connection lost condition -->
<div *ngIf="serverErrorMessage" class="connectionDiv">
    <img src={{images.connectionLost}} loading="lazy" alt="..." class="connectionLostImg">
    <span>{{serverErrorMessage}}</span>
</div>
<!-- main page -->
<div *ngIf="termsAndConditionData">
    <div class="container" *ngIf="!loadingTC && !serverErrorMessage">
        <!-- heading -->
        <!-- <label class="heading font-heading"><strong class="font-fix">{{labels[selectedLanguage].termsAndConditions |
                uppercase}}</strong></label> -->
        <br>
        <!-- card of mayor info -->
        <mat-card class="card">
            <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en" style="width: -webkit-fill-available;">

            <head>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <title>file_1685947710808</title>
                <meta name="author" content="sakal" />
                <style type="text/css">
                    * {
                        margin: 0;
                        padding: 0;
                        text-indent: 0;
                    }

                    .s1 {
                        color: black;
                        font-family: "Times New Roman", serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 12pt;
                        vertical-align: 8pt;
                    }

                    .s2 {
                        color: black;
                        font-family: Calibri, sans-serif;
                        font-style: normal;
                        text-decoration: underline;
                        font-size: 12pt;
                    }

                    .p,
                    p {
                        color: black;
                        font-family: Calibri, sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 12pt;
                        margin: 0pt;
                    }

                    .a {
                        color: black;
                        font-family: Calibri, sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 12pt;
                    }

                    h1 {
                        color: black;
                        font-family: Calibri, sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        text-decoration: none;
                        font-size: 16pt;
                    }

                    h2 {
                        color: black;
                        font-family: Calibri, sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        text-decoration: none;
                        font-size: 12pt;
                    }

                    li {
                        display: block;
                    }

                    #l1 {
                        padding-left: 0pt;
                    }

                    #l1>li>*:first-child:before {
                        content: " ";
                        color: black;
                        font-family: Wingdings;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 12pt;
                    }

                    li {
                        display: block;
                    }

                    #l2 {
                        padding-left: 0pt;
                    }

                    #l2>li>*:first-child:before {
                        content: "o ";
                        color: black;
                        font-family: "Courier New", monospace;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                    }

                    #l3 {
                        padding-left: 0pt;
                    }

                    #l3>li>*:first-child:before {
                        content: " ";
                        color: black;
                        font-family: Symbol, serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 10pt;
                    }

                    #l4 {
                        padding-left: 0pt;
                    }

                    #l4>li>*:first-child:before {
                        content: "- ";
                        color: black;
                        font-family: Calibri, sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 12pt;
                    }

                    #l5 {
                        padding-left: 0pt;
                    }

                    #l5>li>*:first-child:before {
                        content: " ";
                        color: black;
                        font-family: Symbol, serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 10pt;
                    }

                    #l6 {
                        padding-left: 0pt;
                    }

                    #l6>li>*:first-child:before {
                        content: "- ";
                        color: black;
                        font-family: Calibri, sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 12pt;
                    }

                    #l7 {
                        padding-left: 0pt;
                    }

                    #l7>li>*:first-child:before {
                        content: " ";
                        color: black;
                        font-family: Symbol, serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 10pt;
                    }

                    #l8 {
                        padding-left: 0pt;
                    }

                    #l8>li>*:first-child:before {
                        content: "- ";
                        color: black;
                        font-family: Calibri, sans-serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 12pt;
                    }

                    #l9 {
                        padding-left: 0pt;
                    }

                    #l9>li>*:first-child:before {
                        content: " ";
                        color: black;
                        font-family: Symbol, serif;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        font-size: 10pt;
                    }
                </style>
            </head>

            <body>
                <h1 style="text-align: center;">Terms and Condition</h1>
                <div style="padding:12px">
                    <p class="s2" style="text-align: center;font-weight: bolder;font-size: x-large;">
                        UNIVERSAL POLICY</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">These terms of
                        use are an
                        electronic record in the form of an electronic contract formed under The Information Technology
                        Act, 2000 and
                        the rules made thereunder and the amended provisions pertaining to electronic documents /
                        records in various
                        statutes as amended by The Information Technology Act, 2000. These terms and conditions do not
                        require any
                        physical, electronic or digital signature. These terms and conditions is published and shall be
                        construed in
                        accordance with the provisions of The Information Technology, 2000 and the rules made
                        thereunder.</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;"><a
                            href="https://www.nearbuy.com/presignup" class="a" target="_blank">There are many different
                            ways you can connect to us – when you create an account
                            with us, subscribe to our offers, contact us online, view our e-mails, browse through our
                            website etc. When
                            you share information with us, for example by creating a </a>PMC CARE account, you trust us
                        with your
                        information. We value your trust and your privacy is important to us. So, we’ve developed a
                        Privacy Policy that
                        covers the below and further details available at https://PMC CARE.pmc.gov.in</p>
                    <ul id="l1">
                        <li data-list-text="">
                            <p style="padding-left: 20pt;text-indent: -20pt;line-height: 15pt;text-align: left;">What
                                information we
                                collect and why we collect it.</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: left;">How we use that
                                information.</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: left;">The choices we offer,
                                including how to
                                access and update information.</p>
                        </li>
                    </ul>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 200%;text-align: left;"><a
                            href="http://www.nearbuy.com/" class="a" target="_blank">Please take a moment to familiarize
                            yourself with our Terms of Use and </a>Privacy
                        Policy. Thank You.</p>
                    <p class="s2" style="text-align: center;font-weight: bolder;font-size:x-large">
                        TERMS OF USE</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <h1 style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">ACCEPTANCE OF
                        TERMS AND
                        CONDITIONS</h1>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>

                    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">CAUTION: PLEASE READ THIS
                        AGREEMENT CAREFULLY.
                        BY BROWSING, ACCESSING OR USING THIS WEBSITE OR MOBILE APPLICATION OR BY USING ANY FACILITIES OR
                        SERVICES MADE
                        AVAILABLE THROUGH OR ON IT, YOU ARE AGREEING TO THE TERMS OF USE THAT APPEAR BELOW (ALL OF WHICH
                        ARE CALLED THE
                        “AGREEMENT”). THIS AGREEMENT IS MADE BETWEEN YOU AND US. USE OF THE SERVICES AND THE WEBSITE OR
                        MOBILE
                        APPLICATION AND ANY PURCHASE MADE THERETO ARE EACH SUBJECT TO THE TERMS OF USE SET OUT IN THIS
                        AGREEMENT.</h2>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">THIS AGREEMENT IS PUBLISHED IN
                        ACCORDANCE WITH
                        THE PROVISIONS OF RULE 3(1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES,
                        2011 THAT REQUIRE
                        PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS OF USE FOR ACCESS OR USAGE OF A
                        WEBSITE/APPLICATION/DIGITAL PLATFORM.</h2>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <h2 style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">PLEASE READ THIS AGREEMENT
                        CAREFULLY AS IT
                        CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS; AND
                        INCLUDES VARIOUS
                        LIMITATIONS AND EXCLUSIONS, A CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES, AND
                        OBLIGATIONS TO
                        COMPLY WITH APPLICABLE LAWS AND REGULATIONS.</h2>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">By reason of selecting the option
                        of ‘<b>I
                            Accept</b>’ in https://PMC CARE.pmc.gov.in (the “<b>Website</b>” in this document) or PMC
                        CARE mobile
                        application (the “mobile application”), you, the Customer (“you”, “your”, or the “User” in this
                        document)
                        expressly agree to be bound by the terms and conditions of this Policy. You hereby expressly
                        consent to the
                        processing of your personal information and non-personal information in accordance with this
                        Policy. Your
                        personal information may be stored, processed or transmitted in India or any other part of the
                        world, where laws
                        regarding storing, processing or transmitting of personal information may be less or more
                        restrictive than the
                        laws in your country. If you do not agree to the terms of this Policy, please do not use our
                        services and can
                        chose the option of ‘<b>I do not accept</b>’.</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Thank you for using PMC CARE the
                        service by Pune
                        Smart City Ltd. (“We”/ “Us” / “Our”/ “Company”)! We value the trust you place in us.</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">This Agreement governs the access
                        and use of all
                        services offered by us. We may, in our sole and absolute discretion and without any specific
                        notice to you,
                        update and change any part or all of this Agreement, including but not limited to the fees and
                        charges
                        associated with the use of or access to our services. If we update or change this Agreement,
                        then the updated
                        version will be posted at https://PMC CARE.pmc.gov.in or on the relevant services’ page. When we
                        change/modify
                        this Agreement, we will specify the ‘Last Modified’ date and the updated agreement will become
                        effective and
                        binding from such date. You are required to review this Agreement periodically. Unless
                        explicitly stated
                        otherwise, any new features or products that</p>

                    <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">change, augment
                        or enhance our
                        current services and/or products shall be subject to the terms of this Agreement. Your access to
                        and use of our
                        services is further subject to our Privacy Policy which is available on https://PMC
                        CARE.pmc.gov.in and is
                        incorporated herein by reference. Certain features of our products and services may be subject
                        to additional
                        guidelines, which will be posted on our respective products and services and shall be deemed to
                        be incorporated
                        herein by reference.</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">As part of our products and/or
                        services, you may
                        from time to time receive updates/upgrades to our services which may be automatically downloaded
                        and installed
                        to the Users device/systems or reflected on our websites. These updates may include bug fixes,
                        feature
                        enhancements or improvements, or entirely new versions of our products and/or services.</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">As of now You can select/deselect
                        option to
                        receive SMS/Email/Mobile push. However, in case of emergency notification and government related
                        alerts/
                        notifications, we need to bypass your notification settings to enable you to receive
                        notification via SMS/mobile
                        application/ Email. The message may be preceded by the words “Urgent”.</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">GENERAL</h1>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">The Merchants have entered into
                        agreements/arrangements with us pertaining to the provision of various offer and promotions with
                        respect to
                        their products and services. The Merchants have represented to us that they are completely
                        qualified and capable
                        of providing the said products and services. However, we, including our services, do not in any
                        way verify the
                        credentials or representations of any of the Merchants, the service levels or the quality of any
                        their products
                        or services offered by the Merchants, or the Merchant’s compliance with applicable laws. There
                        exists no
                        employer-employee relationship between the Merchants and us. We do not endorse any Merchant.
                        You/the User are
                        encouraged to independently verify any such information pertaining to the Merchants.</p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We clarify that all
                        commercial/contractual terms
                        are offered by the Merchants alone. We do not have any control or do not determine or advise or
                        in any way
                        involve ourselves in the offering or acceptance of such commercial/contractual terms between the
                        Merchant and
                        you/User. The contract is directly between you and the Merchant and we shall not and are not
                        required to mediate
                        or resolve any dispute or disagreement between you and the Merchant and hereby disclaim all
                        responsibilities and
                        liabilities. We are only a medium to communicate between you/User and the Merchant.</p>
                    <ul id="l2">
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">User Accounts: 
                            </h1>
                            <span
                            class="p">In
                            order to use most aspects of the Services, you must register for and maintain an
                            active personal
                            user services account (“Account”). You must be at least 18 years of age, to obtain
                            an Account.
                            Account registration requires you to submit to us certain personal information, such
                            as your name,
                            address, mobile phone number, email address, username. You agree to maintain
                            accurate, complete, and
                            up-to-date information in your Account. Your failure to maintain accurate, complete,
                            and up-to-date
                            Account information, may result in your inability to access and use the Services or
                            termination of
                            these services and agreement with you. You are responsible for all activity that
                            occurs under your
                            Account, and you agree to maintain the security and secrecy
                            of your Account
                            username and password at all times. Unless otherwise permitted by us in writing, you may
                            only possess
                            one Account.</span>
                            <p style="padding-top: 1pt;text-indent: 0pt;text-align: justify;"></p>
                        </li>
                        <!-- <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">Payment for the
                                Merchant’s services:
                                <span class="p">The price for the Merchant’s products and services will be calculated
                                    and provided
                                    before the booking/service session is confirmed. When the User places an order/books
                                    a service and/
                                    or avails of any offer of the Merchant through our services, and if any payment is
                                    required to be
                                    made, the User will be given a choice of payment options, including via credit/debit
                                    card, payment
                                    gateways, and/or direct payment to the relevant Merchant or on behalf of the
                                    Merchant to us or our
                                    representative. If the User pays via credit/debit card or payment gateways, we will
                                    ask for a valid
                                    card details or payment gateway’s account, as applicable, which will be billed,
                                    directly through our
                                    products and/or services, for the price/service fee of the applicable order/service
                                    booking, and /
                                    or such of our trade name will be the name that may appear on the payment
                                    statement/slip. As stated
                                    above, however, we, including our services, are not and shall not in any manner be
                                    considered the
                                    seller, dealer or supplier of any of the services/products ordered/booked. Further,
                                    the payments
                                    will be processed by third party service providers such as payment gateway service
                                    providers and the
                                    terms of services as imposed by them including disclaimers shall be mutatis mutandis
                                    be applicable
                                    to you. You have specifically authorized us or our Merchants to collect, process,
                                    facilitate and
                                    remit payments and / or the fee for the services booked by you. Your relationship
                                    with us is on a
                                    principal to principal basis and by accepting the terms of this Agreement you agree
                                    that we are an
                                    independent contractor for all purposes, and do not have control of or liability for
                                    the products or
                                    services that are listed/offered on our products and/or services that are paid for
                                    by using the
                                    payment facility provided by or through us. We do not guarantee the identity of any
                                    person nor do we
                                    ensure that a Merchants will complete a transaction.</span>
                            </h1>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-top: 3pt;padding-left: 29pt;text-indent: -6pt;text-align: left;"><br />
                            </p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">You, as a service
                                recipient, understand
                                that upon initiating a transaction you are entering into a legally binding and
                                enforceable contract with
                                the Merchant to purchase its products and/or avail its services using our booking and
                                payment facility,
                                and you shall be obligated to pay the price/fee for the Merchants services/products at
                                the time of
                                availing the said product and/ or service.</p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Notwithstanding anything
                                contained
                                herein, it is at our discretion to process a transaction or reject it. As such we
                                reserve the right to
                                refuse to process transactions by a User with a prior history of questionable charges
                                including without
                                limitation breach of any agreements by such User with us or breach/violation of any law
                                or any charges
                                imposed by banks or breach of any policy.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li> -->
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">Territory:
                            </h1>
                            <span
                            class="p">The
                            Website or mobile application and the Services and any purchase are directed solely
                            at those who
                            access the Website from the India. We make no representation that Service (or any
                            goods or services)
                            are available or otherwise suitable for use outside India. If you choose to access
                            the Website or
                            mobile application (or use the Service or make a purchase) from locations outside
                            India, you do so
                            on your own initiative and are responsible for the consequences thereof.</span>
                        </li>
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">Website and/or Mobile
                                Application:
                            </h1>
                            <span class="p">We provide an interactive online service owned and operated by us
                                through the Website on
                                the World Wide Web of the Internet (the “Web”or
                                “Internet”) and its
                                mobile application, consisting of services and products provided by third party with
                                whom We have
                                business relationships with</span>
                            <p style="padding-top: 1pt;text-indent: 0pt;text-align: justify;">.</p>
                        </li>
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">Right to Transfer:</h1>
                            <span class="p">The right to use this Website or mobile application is personal to the
                                User and is not
                                transferable to any other person or entity. The User shall be responsible for
                                protecting the
                                confidentiality of User’s password(s), if any. User understands and acknowledges
                                that, although the
                                Internet is often a secure environment, sometimes there are interruptions in service
                                or events that
                                are beyond our control, and we shall not be responsible for any data lost while
                                transmitting
                                information on the Internet.</span>
                        </li>
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">Not on Uninterrupted
                                Basis: </h1>
                                <span class="p">While it is our objective to make the Website or mobile
                                    application accessible at all
                                    times, the Website or mobile application may be unavailable from time to time for
                                    any reason
                                    including, without limitation, routine maintenance. You understand and acknowledge
                                    that due to
                                    circumstances both within and outside our control, access to the Website or mobile
                                    application may
                                    be interrupted, suspended or terminated from time to time; we reserve the right, in
                                    our sole
                                    discretion, to terminate the access to any or all our website(s) and the related
                                    services or any
                                    portion thereof at any time, without notice. We shall also have the right at any
                                    time to change or
                                    discontinue any aspect or feature of the Website or mobile application, including,
                                    but not limited
                                    to, content, graphics, Offers, settings, hours of availability and equipment needed
                                    for access or
                                    use. Further, we may discontinue disseminating any portion of information or
                                    category of
                                    information, may change or eliminate any transmission method and may change
                                    transmission speeds or
                                    other signal characteristics.</span>
                        </li>
                        <li data-list-text="o">
                            <!-- <p style="padding-top: 3pt;padding-left: 29pt;text-indent: -6pt;text-align: left;"><br />
                            </p> -->
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">CONTENT</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">&quot;Content&quot; will
                                include (but is
                                not limited to) reviews, images, photos, audio, video, location data, nearby places, and
                                all other forms
                                of information or data. &quot;Your Content&quot; or &quot;User Content&quot; means
                                content that you
                                upload, share or transmit to, through or in connection with the Services, such as likes,
                                ratings,
                                reviews, images, photos, messages, profile information, and any other materials that you
                                publicly
                                display or displayed in your Account. &quot;Our Content&quot; means content that we
                                create and make
                                available in connection with the Services including, but not limited to, visual
                                interfaces, interactive
                                features, graphics, design, compilation, computer code, products, software, aggregate
                                ratings, reports
                                and other usage-related data in connection with activities associated with your Account
                                and all other
                                elements and components of the Services excluding Your Content and Third-Party Content
                                Provider.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">REPRESENTATIONS REGARDING
                                YOUR OR USER
                                CONTENT</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">You are responsible for
                                Your Content. You
                                represent and warrant that you are the sole author of, own, or otherwise control all of
                                the rights of
                                Your Content or have been granted explicit permission from the rights holder to submit
                                Your Content;
                                Your Content was not copied from or based in whole or in part on any other content,
                                work, or website;
                                Your Content was not submitted via the use of any automated process such as a script
                                bot; use of Your
                                Content by us, third party services, and our and any third party users will not violate
                                or infringe any
                                rights of yours or any third party; Your Content is truthful and accurate; and Your
                                Content does not
                                violate any</p>

                            <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">
                                applicable laws. You
                                assume all risks associated with Your Content, including anyone&#39;s reliance on its
                                quality, accuracy,
                                or reliability, or any disclosure by you of information in Your Content that makes you
                                personally
                                identifiable. While we reserve the right to remove Content, we do not control actions or
                                Content posted
                                by our users and do not guarantee the accuracy, integrity or quality of any Content. You
                                acknowledge and
                                agree that Content posted by users and any and all liability arising from such Content
                                is the sole
                                responsibility of the user who posted the content, and not PMC CARE.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">EQUIPMENT</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">User’s
                                Responsibility: 
                                </h1>
                                <span class="p">The Services and use of the website or mobile
                                    application do not include the provision of
                                    a computer or other necessary equipment to access the Site or the Services. To use
                                    the Site or
                                    Services, you will require Internet connectivity, Wi-fi and appropriate
                                    telecommunication links. The
                                    User shall be responsible for obtaining and maintaining all telephone, mobile,
                                    computer hardware,
                                    software and other equipment needed for access to and use of this Website or mobile
                                    application and
                                    all charges related thereto. We shall not be liable for any damages to the User’s
                                    equipment
                                    resulting from the use of this Website or mobile application.</span>
                        </li>
                        <li data-list-text="o">
                            <!-- <p style="padding-top: 3pt;padding-left: 29pt;text-indent: -6pt;text-align: left;"><br />
                            </p> -->
                            <h1 style="padding-left: 5pt;text-indent: 0pt;">USER CONDUCT</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">Restrictions:</h1>
                            <span
                            class="p">The
                            User undertakes without limitation, not to use or permit anyone else to use the
                            Service or Website
                            or mobile application:</span>
                            <ul id="l3">
                                <li data-list-text="">
                                    <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">to upload,
                                        send or receive any
                                        information for which you have not obtained all necessary license and/or
                                        approvals (from us or
                                        third parties); or which constitutes or encourages conduct that would be
                                        considered a criminal
                                        offence, give rise to civil liability or otherwise be contrary to the law of or
                                        infringe the
                                        rights of any third party in any country in the world;</p>
                                </li>
                                <li data-list-text="">
                                    <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">to upload,
                                        send or receive any
                                        material which is technically harmful (including computer viruses, logic bombs,
                                        Trojan horses,
                                        worms, harmful components, corrupted data, malicious software, harmful data, or
                                        anything else
                                        which may interrupt, interfere with, corrupt or otherwise cause loss, damage,
                                        destruction or
                                        limitation to the functionality of any software or computer equipment);</p>
                                </li>
                                <li data-list-text="">
                                    <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">to intercept
                                        or attempt to
                                        intercept any communications transmitted by way of a telecommunication system;
                                    </p>
                                </li>
                                <li data-list-text="">
                                    <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">For a purpose
                                        other than which
                                        we have designed them or intended them to be used;</p>
                                </li>
                                <li data-list-text="">
                                    <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">For any
                                        fraudulent purposes;
                                    </p>
                                </li>
                                <li data-list-text="">
                                    <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">in any way which
                                        is calculated to
                                        incite hatred, ill will against any ethnic, religious or any community or is
                                        otherwise
                                        calculated to adversely affect any individual, group, community or entity.</p>
                                </li>
                                <li data-list-text="">
                                    <p
                                        style="padding-left: 20pt;text-indent: -20pt;line-height: 15pt;text-align: justify;">
                                        To
                                        upload, send or receive any material, which is not civil or tasteful.
                                    </p>
                                </li>
                                <li data-list-text="">
                                    <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">To
                                        upload, send or
                                        receive any material, including User Content, which is unlawful,
                                        harmful, tortious,
                                        defamatory, malicious, threatening, abusive, harassing, hateful, racist,
                                        homophobic,
                                        infringing, pornographic, violent, misleading, grossly offensive, of an
                                    </p>
                                    <p
                                        style="padding-top: 1pt;padding-left: 20pt;text-indent: 0pt;text-align: justify;">
                                        indecent, obscene or menacing character, blasphemous or of a libellous
                                        nature of any
                                        person or entity, in contempt of court or in breach of confidence, or
                                        which infringes
                                        the rights of another person or entity, including copyrights,
                                        trademarks, trade secrets,
                                        patents, rights of personality, publicity or privacy or any other third
                                        party rights;
                                    </p>
                                </li>
                                <li data-list-text="">
                                    <p
                                        style="padding-left: 20pt;text-indent: -20pt;line-height: 15pt;text-align: justify;">
                                        To
                                        cause annoyance, inconvenience or needless anxiety.</p>
                                </li>
                            </ul>
                        </li>
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">Forbidden uses: <span
                                    class="p">The
                                    following uses of the Site and Services/Products are expressly prohibited, and you
                                    undertake not to
                                    do (or to permit anyone else to do) any of the following:</span></h1>
                            <ul id="l6">
                                <ul id="l7">
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Furnish
                                            false data
                                            including false names, addresses and contact details and fraudulent use of
                                            credit/debit card
                                            numbers;</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Attempt to
                                            circumvent our
                                            security or network including accessing data not intended for you, logging
                                            into a server or
                                            Account you are not expressly authorized to access, or probe the security of
                                            other networks
                                            (such as running a port scan);</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Access the
                                            Services (or
                                            Site) in such a way as to, or commit any act that would or does, impose an
                                            unreasonable or
                                            disproportionately large burden on our infrastructure or that otherwise
                                            interrupts or
                                            interferes with its functionality, efficiency or operation;</p>
                                    </li>
                                    <li data-list-text="">
                                        <p
                                            style="padding-left: 20pt;text-indent: -20pt;line-height: 15pt;text-align: justify;">
                                            Execute
                                            any form of network monitoring which will intercept data not intended for
                                            you;</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Send
                                            unsolicited mail
                                            messages, including the sending of &quot;junk mail&quot; or other
                                            advertising material to
                                            individuals who did not specifically request such material.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Send
                                            malicious email/
                                            messages, including flooding a user or site with very large or numerous
                                            emails;</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Enter into
                                            fraudulent
                                            interactions or transactions with us, a Seller or a Merchant (which shall
                                            include entering
                                            into interactions or transactions purportedly on behalf of a third party
                                            where you have no
                                            authority to bind that third party or you are pretending to be a third
                                            party);</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Use the
                                            Services or Site
                                            (or any relevant functionality of either of them) in breach of this
                                            Agreement;</p>
                                    </li>
                                    <li data-list-text="">
                                        <p
                                            style="padding-left: 20pt;text-indent: -20pt;line-height: 15pt;text-align: justify;">
                                            Use in
                                            an unauthorized manner, or forge, mail header information;</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Engage in
                                            any unlawful or
                                            criminal activity in connection with the use of the Services and/or the Site
                                            or any Voucher;
                                            or</p>
                                    </li>
                                    <li data-list-text="">
                                        <p
                                            style="padding-left: 20pt;text-indent: -20pt;line-height: 15pt;text-align: justify;">
                                            Copy or
                                            use any User Content (not belonging to you) for any commercial purposes.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Any conduct by a User
                                            that in our
                                            exclusive discretion is in breach of the Terms of Use or which restricts or inhibits any
                                            other User from
                                            using or enjoying this Website or mobile application is strictly prohibited. The User
                                            shall not use this
                                            Website or mobile application to advertise or perform any commercial, religious,
                                            political or
                                            non-commercial solicitation, including, but not limited to, the solicitation of users of
                                            this Website or
                                            mobile application to become users of other online or offline services directly or
                                            indirectly
                                            competitive or potentially competitive with us.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We reserve the right
                                            to prevent you
                                            from using the Website (or mobile application) and the Service (or any part of thereof)
                                            and to prevent
                                            you from making any purchase, if your conduct is found to be in question or
                                            contravention of such Terms
                                            as mentioned above or in this Agreement.</p>
                                    </li>
                                </ul>
                            </ul>
                        </li>
                        <li data-list-text="o">
                            
                        </li>
                        <li data-list-text="o">
                            
                            <!-- <h1 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">PURCHASE
                                AND SALE OF
                                PRODUCTS AND/OR SERVICES</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p> -->

                        </li>
                        <!-- <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We take no
                                responsibility for the
                                services and/or products for which our Vouchers may be redeemed. Further, we make no
                                warranty to the
                                Users for the quality, safety, usability, or another aspect of the product or service
                                for which our
                                Voucher may be redeemed.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Some services for
                                which our Voucher(s)
                                can be redeemed are activities that involve potential bodily harm (such as different
                                forms of adventure
                                sports and activities.), and for those activities we take no responsibility for the
                                service or activity
                                being offered, and the User takes responsibility for his or her own actions in utilizing
                                the services
                                for which our Voucher can be redeemed.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">By purchasing any
                                Merchant offering
                                and services via the Website/App, you agree to the terms of sale specified below:</p>
                            <ul id="l8">
                                <ul id="l9">
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">
                                            Benefits/Services will
                                            expire on the date specified on the Website/App.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: left;">Our Benefits
                                            cannot be
                                            combined with any other gift vouchers, third party vouchers, coupons, or
                                            promotions, unless
                                            otherwise specified by the Institution.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p
                                            style="padding-left: 20pt;text-indent: -20pt;line-height: 15pt;text-align: left;">
                                            Sale or
                                            trade of PMC CARE benefits is strictly prohibited.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: left;">Booking,
                                            reservations and
                                            amendments are subject to availability</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: left;">Must be
                                            redeemed in a single
                                            visit (unless specified otherwise)</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">PMC CARE
                                            benefits can be
                                            redeemed only after due verification of the customer including, without
                                            limitation, matching
                                            the unique identification number provided to the customer at the time of
                                            purchase of PMC
                                            CARE Card.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">All
                                            local/national laws
                                            and rules, that apply to regular customers, apply to PMC CARE card holders
                                            too</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">The
                                            Merchant is solely
                                            responsible for the taxes levied/to be levied on the services for providing
                                            the Sales
                                            Invoice to the you against the services rendered.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p
                                            style="padding-left: 20pt;text-indent: -20pt;line-height: 15pt;text-align: justify;">
                                            In case
                                            of cancellation or transactional failure, we shall not be responsible.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We make
                                            every effort to
                                            ensure that any materials or rates displayed on its platform are accurate as
                                            at the date of
                                            issue. Changes in market conditions or circumstances may occur after the
                                            issue date, which
                                            may make information displayed on the platform no longer accurate, or no
                                            longer reflect the
                                            current position.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">PMC CARE
                                            hereby expressly
                                            disclaims any representation, warranty or undertaking in relation to the
                                            accuracy,
                                            completeness, quality or adequacy of any contents of its platform.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">While we
                                            makes every
                                            effort to work with Merchants, which provide an acceptable standard of
                                            service for the price
                                            paid, we hereby expressly disclaim any representation, warranty or
                                            undertaking in relation
                                            to the quality or adequacy of any service listed on our Platform.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">All images
                                            used are for
                                            illustrative purposes only and are only indicative in nature. The actual
                                            service may vary
                                            from the displayed image. The specifications of any service is subject to
                                            change as
                                            necessary and without notice. Specification is not intended to form a part
                                            of any purchase
                                            unless specifically incorporated on the Website.</p>
                                    </li>
                                    <li data-list-text="">
                                        <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Given the
                                            nature of
                                            various benefits, we do not take responsibility for any medical
                                            complications that arise
                                            during or post the use of services. We strongly advise our users to take all
                                            necessary
                                            precautions / consultations before availing of benefits.</p>
                                    </li>
                                </ul>
                            </ul>
                            <h1 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Purchase
                                and Redemption of
                                PMC CARE Offers</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>

                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Descriptions of the
                                Merchant offerings
                                advertised on the website are provided by the Merchant or other referenced third
                                parties. We are not
                                responsible for any claims associated with the description of the Merchant offerings.
                                Pricing relating
                                to certain Merchant offerings and services on the website may change at any time in our
                                sole discretion
                                without notice. So, it is advisable to go through the policies before making any
                                purchase on our
                                website.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">A Merchant may
                                advertise goods,
                                services or experiences on the website, that require Merchant to have an up-to-date
                                regulatory
                                authorization, license, or certification. We do not verify, validate, or collect
                                evidence of any
                                regulatory authorization, license or certification from any Merchant.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">PMC CARE is not a
                                health or wellness
                                provider and does not, will not and cannot refer, recommend or endorse any specific
                                professional,
                                services, products or procedures that are advertised on the website. The website is not
                                a substitute for
                                professional advice, including, without limitation, medical advice, diagnosis or
                                treatment. Always seek
                                the advice of your physician or other qualified health provider with any questions you
                                may have
                                regarding a health condition. Never neglect to seek out or delay or disregard
                                professional advice
                                relating to your health because of something you have read on the website.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We may, in our sole
                                discretion, verify
                                a user’s identity prior to processing a purchase. We may also refuse to process a
                                purchase, may cancel a
                                purchase, or may limit quantities, as reasonably deemed necessary, to comply with
                                applicable law or to
                                respond to a case of misrepresentation, fraud or known or potential violations of the
                                law or these terms
                                of sale. Refunds for cancelled orders may be issued where appropriate.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We do not guarantee
                                that the Merchant
                                offers best available rates or prices and does not guarantee against pricing errors. We
                                reserve the
                                right, in our sole discretion, to not process or to cancel any orders placed, including,
                                without
                                limitation, if the price was incorrectly posted on the website. In addition, we reserve
                                the right, in
                                our sole discretion, to correct any error in the stated retail price of the Merchant
                                offering or
                                services.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Any attempted
                                redemption not
                                consistent with these terms and conditions will render the PMC CARE benefits void and
                                invalid.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Institutions will have
                                their own
                                applicable terms and conditions, in relation to their own supply of their goods and
                                services, and you
                                agree to (and shall) abide by those terms and conditions. The responsibility to do so is
                                yours alone.
                            </p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We reserve the right
                                to change, modify
                                substitute, suspend or remove without notice any information or benefit or service from
                                the Website or
                                mobile application forming part of the Service from time to time. Your access to the
                                Website or mobile
                                application and/or the Services may also be occasionally restricted to allow for
                                repairs, maintenance or
                                the introduction of new facilities or services. We will attempt to restore such access
                                as soon as we
                                reasonably can. We assume no responsibility for functionality during the same. For the
                                avoidance of
                                doubt, we may also withdraw any information or benefit from the Website or Services at
                                any time.</p>
                        </li> -->
                        <li data-list-text="o">
                            <!-- <p style="padding-top: 3pt;padding-left: 29pt;text-indent: -6pt;text-align: left;"><br />
                            </p>
                            <h1 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">
                                PROMOTIONAL CODES</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>

                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We may, in our sole
                                discretion, create
                                promotional codes that may be redeemed for Account credit, or other features or benefits
                                related to the
                                Services and/or a Third-Party Provider’s services, subject to any additional terms that
                                we establish on
                                a per promotional code basis (“<i>Promo Codes</i>”). You agree that Promo Codes: (i)
                                must be used for
                                the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated,
                                sold or
                                transferred in any manner, or made available to the general public (whether posted to a
                                public form or
                                otherwise), unless expressly permitted by us; (iii) may be disabled by us at any time
                                for any reason
                                without liability to us ; (iv) may only be used pursuant to the specific terms that we
                                establish for
                                such Promo Code; (v) are not valid for cash; vi) may expire prior to your use; and vii)
                                unless otherwise
                                specified cannot be used more than once. We reserve the right to withhold or deduct
                                credits or other
                                features or benefits obtained through the use of Promo Codes by you or any other user in
                                the event that
                                we determine or believe that the use or redemption of the Promo Code was in error,
                                fraudulent, illegal,
                                or in violation of the applicable Promo Code terms or these Terms.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p> -->
                            <h1 style="padding-left: 5pt;text-indent: 0pt;">INTELLECTUAL PROPERTY
                                RIGHTS</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We grant you a
                                limited, personal,
                                non-transferable, nonexclusive, revocable license to access and use the Site pursuant to
                                this Agreement
                                and to any additional terms and policies set forth by us. All Intellectual Property
                                Rights (including
                                all copyright, patents, trademarks, service marks, trade names, designs (including the
                                &quot;look and
                                feel&quot; and other visual or non-literal elements)) whether registered or
                                unregistered) in the Website
                                or mobile application and Service, (subject to the Title &amp; Ownership Rights as
                                mentioned below)
                                information content on the Website or mobile application or accessed as part of the
                                Service, any
                                database operated by us and all the Website design, text and graphics, software, photos,
                                video, music,
                                sound, and their selection and arrangement, and all software compilations, underlying
                                source code and
                                software (including applets and scripts) shall remain our property (or that of our
                                licensors). You shall
                                not, and shall not attempt to, obtain any title to any such intellectual property. All
                                rights are
                                reserved.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">None of the material
                                listed in the
                                clause above may be reproduced or redistributed or copied, distributed, republished,
                                downloaded,
                                displayed, posted or transmitted in any form or by any means, sold, rented or
                                sub-licensed, used to
                                create derivative works, or in any way exploited without our prior express written
                                permission. You may,
                                however, retrieve and display the content of the Website on a computer screen, store
                                such content in
                                electronic form on disk (but not on any server or other storage device connected to a
                                network) or print
                                / save one copy of such content for your own personal, non-commercial use, provided you
                                keep intact all
                                and any copyright and proprietary notices. You may not otherwise reproduce, modify, copy
                                or distribute
                                or use for commercial purposes any of the materials or content on the Website or mobile
                                application
                                without our permission.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">“PMC CARE”, the Logos
                                and variations
                                thereof found on the website or mobile application are trademarks owned by us and all
                                use of these marks
                                inures to our benefit. All rights (including goodwill and, where relevant, trade marks)
                                in our name are
                                owned by us (or</p>
                            <p style="padding-top: 1pt;padding-left: 20pt;text-indent: 0pt;text-align: justify;">our
                                licensors). Other
                                product and company names mentioned on the Website or mobile application is the
                                trademarks or registered
                                trademarks of their respective owners.</p>

                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">The title, Ownership
                                Rights and
                                Intellectual Property Rights in and to the content accessed using the Service is the
                                property of the
                                applicable content owner or Merchant and may be protected by applicable copyright or
                                other law. The
                                Agreement gives you no rights to such content.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Subject to the clause
                                below, any
                                material you transmit or post or submit to the Website or mobile application (or
                                otherwise to us) shall
                                be considered (and we may treat it as) non-confidential and non-proprietary, subject to
                                our obligations
                                under data protection legislation or such legislation which provides for protection of
                                electronic data
                                under Indian Laws. If for some reason, any part of that statement does not work as a
                                matter of law, then
                                for anything which you supply to us from whatever source (i.e. via email, the Website or
                                otherwise) you
                                grant us a royalty-free, perpetual, irrevocable, non-exclusive right to use, copy,
                                modify, adapt,
                                translate, publish and distribute world-wide any such material.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">All comments,
                                suggestions, ideas,
                                notes, drawings, concepts or other information: (i) disclosed or offered to us by you;
                                or (ii) in
                                response to solicitations by us regarding the Service or the Website or mobile
                                application; (in each
                                foregoing case, these are called &quot;Ideas&quot;) shall be deemed to be and shall
                                remain our property
                                and you hereby assign by way of present and future assignment all intellectual property
                                rights in Ideas,
                                to us. You understand and acknowledge that we have both internal resources and other
                                external resources
                                which may have developed or may in the future develop ideas identical to or similar to
                                Ideas and that we
                                are only willing to consider Ideas on these terms. In any event, any Ideas are not
                                submitted in
                                confidence and we assume no obligation, express or implied by considering it. Without
                                limitation, we
                                shall exclusively own all now known or hereafter existing rights to the Ideas of every
                                kind and nature
                                throughout the world and shall be entitled to unrestricted use of the Ideas for any
                                purpose whatsoever,
                                commercial or otherwise without compensation to the provider of the Ideas.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We do not claim
                                exclusive ownership of
                                the User Data that the User provides to us including through our products and/or
                                services, and we
                                acknowledge and agree that, subject to the terms and conditions of this Agreement, the
                                User will retain
                                applicable copyright and other intellectual property rights in the User Data it already
                                has.</p>
                        </li>
                        <li data-list-text="o">
                            <!-- <p style="padding-top: 3pt;padding-left: 29pt;text-indent: -6pt;text-align: left;"><br />
                            </p> -->
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">DISCLAIMER OF WARRANTY;
                                LIMITATION OF
                                LIABILITY</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">The User expressly
                                agrees that use of
                                the Website or mobile application is at the User’s sole risk. Neither we, our holding
                                company or
                                subsidiaries (if any), business associates nor any of their respective employees,
                                agents, and
                                Third-Party Content Providers warrant that use of the Website or mobile application will
                                be
                                uninterrupted or error-free; nor do they make any warranty as to (i) the results that
                                may be obtained
                                from use of this Website or mobile application, or (ii) the accuracy, reliability or
                                content of any
                                information, service or merchandise provided through the Website or mobile application.
                            </p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">The Website or mobile
                                application are
                                made accessible on an “as is” basis without warranties of any kind, either express or
                                implied,
                                including, but not limited to, warranties</p>
                            <p style="padding-top: 1pt;padding-left: 20pt;text-indent: 0pt;text-align: justify;">of
                                title or implied
                                warranties of merchantability or fitness for a particular purpose, other than those
                                warranties which are
                                implied by and incapable of exclusion, restriction or modification under the laws
                                applicable to this

                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We do not give any
                                warranty that the
                                Services or the Website or mobile application are free from viruses or anything else
                                which may have a
                                harmful effect on any technology.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We reserve the right
                                to change, modify
                                substitute, suspend or remove without notice any information or Voucher or service from
                                the Website or
                                mobile application forming part of the Service from time to time. Your access to the
                                Website or mobile
                                application and/or the Services may also be occasionally restricted to allow for
                                repairs, maintenance or
                                the introduction of new facilities or services. We will attempt to restore such access
                                as soon as we
                                reasonably can. We assume no responsibility for functionality which is dependent on your
                                browser or
                                other third-party software to operate. For the avoidance of doubt, we may also withdraw
                                any information
                                or Voucher from the Website or Services at any time.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We reserve the right
                                to block access
                                to and/or to edit or remove any material which in our reasonable opinion may give rise
                                to a breach of
                                this Agreement.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">This disclaimer of
                                liability applies
                                to any damages or injury caused by any failure of performance, error, omission,
                                interruption, deletion,
                                defect, delay in operation or transmission, computer virus, communication line failure,
                                theft or
                                destruction or unauthorized access to, alteration of, or use of record, whether for
                                breach of contract,
                                tortuous behavior, negligence, or under any other cause of action. The User specifically
                                acknowledges
                                that we are not liable for the defamatory, offensive or illegal conduct of other users
                                or third-parties
                                and that the risk of injury from the foregoing rests entirely with the User.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">In no event shall we,
                                or any business
                                associates, Third Party Content Providers, Third Party Advertisers Or Third-Party
                                Service Providers,
                                producing or distributing the Website or the contents hereof, mobile application and any
                                software, be
                                liable for any damages, including, without limitation, direct, indirect, incidental,
                                special,
                                consequential or punitive damages arising out of the use of or inability to use the
                                Website or mobile
                                application. The User hereby acknowledges that the provisions of this Clause shall apply
                                to all content
                                on the Website and mobile application.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">In addition to the
                                terms set forth
                                above, neither we , nor its subsidiaries and business associates, Third Party Service
                                Providers or Third
                                Party Content Providers shall be liable regardless of the cause or duration, for any
                                errors,
                                inaccuracies, omissions, or other defects in, or un-timeliness or un-authenticity of,
                                the information
                                contained within the Website, or for any delay or interruption in the transmission
                                thereof to the user,
                                or for any claims or losses arising there from or occasioned thereby. None of the
                                foregoing parties
                                shall be liable for any third-party claims or losses of any nature, including without
                                limitation lost
                                profits, punitive or consequential damages.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We will not be liable
                                for indirect,
                                special, or consequential damages (or any loss of revenue, profits, or data) arising in
                                connection with
                                this Agreement, even if we have been advised of the possibility of such damages. Without
                                prejudice to
                                the foregoing, under no circumstances shall our aggregate liability arising with respect
                                to this
                                Agreement will exceed the total Offer price paid or payable under this Agreement to us.
                            </p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-top: 1pt;padding-left: 20pt;text-indent: -20pt;text-align: left;">Our
                                liability is limited
                                to the refund to the you the actual price paid by the you to purchase the Product/
                                Services. The
                                decision to refund the money is solely at our discretion.</p>
                        </li>
                        <li data-list-text="o">
                            <!-- <p style="padding-top: 3pt;padding-left: 29pt;text-indent: -6pt;text-align: left;"><br />
                            </p> -->
                            <h1 style="padding-left: 7pt;text-indent: 0pt;text-align: left;">MONITORING</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>

                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We shall have the right,
                                but not the
                                obligation, to monitor the content of the Website or mobile application at all times,
                                including any chat
                                rooms and forums that may hereinafter be included as part of the Website or mobile
                                application, to
                                determine compliance with this Agreement and any operating rules established by us, as
                                well as to
                                satisfy any applicable law, regulation or authorized government request. Without
                                limiting the foregoing,
                                we shall have the right to remove any material that we, in its sole discretion, finds to
                                be in violation
                                of the provisions hereof or otherwise objectionable.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">LICENSE GRANT</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">By posting communications
                                on or through
                                this Website or mobile application, the User shall be deemed to have granted to us, a
                                royalty-free,
                                perpetual, irrevocable &amp; non-exclusive license to use, reproduce, modify, publish,
                                edit, translate,
                                distribute, perform, and display the communication alone or as part of other works in
                                any form, media,
                                or technology whether now known or hereafter developed, and to sublicense such rights
                                through multiple
                                tiers of sublicenses.</p>
                            <!-- <p style="text-indent: 0pt;text-align: left;"><br /></p> -->
                            <!-- <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">INDEMNIFICATION</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p> -->
                        </li>
                        <!-- <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">You the User agree to,
                                jointly and
                                severally, fully indemnify, defend and hold us and our suppliers, licensors, affiliates,
                                partners,
                                subsidiaries, employees, representatives, agents and members (each an “<b>Indemnified
                                    Party</b>”)
                                harmless from and against any and all claims and demands, losses, liability, costs and
                                expenses
                                (including, but not limited to, attorneys’ fees and dispute resolutions costs)
                                (collectively,
                                “<b>Liabilities</b>”), incurred by an Indemnified Party arising out of or related to:
                                (i) the User’s
                                breach of this Agreement including without limitation the User’s breach of any of its
                                representations
                                and warranties or covenants herein; (ii) the creation, use, or maintenance of the User
                                Data, including,
                                without limitation, any allegation that any User Data or any other information or
                                content provided by
                                the User infringes a third person’s copyright, trademark or other proprietary or
                                intellectual property
                                right, or misappropriates a third person&#39;s trade secrets or is unlawful,
                                threatening, abusive,
                                harassing, tortious, defamatory, obscene, harmful, libelous, invasive of another’s
                                privacy, hateful, or
                                racially or ethnically objectionable; (iii) any dispute or litigation between an
                                Indemnified Party and a
                                third party or any other third party claim caused by, arising from or relating to your
                                and/or the User’s
                                actions or omissions in relation to this Agreement, our products and/or services, any
                                User Data or the
                                User Account; (iv) the User’s negligence or misconduct; or (v) the User’s business
                                dealings with any of
                                the Merchants. These obligations will survive any termination of the User’s use of our
                                products and/or
                                services or expiry or termination of this Agreement. To the fullest extent permitted by
                                law, the
                                foregoing indemnity will apply regardless of any</p>
                            <p style="padding-top: 1pt;padding-left: 20pt;text-indent: 0pt;text-align: justify;">fault,
                                negligence, or
                                breach of warranty or contract of or by us and/or our suppliers, licensors, affiliates,
                                partners,
                                subsidiaries, employees, representatives, agents and/or members.</p>

                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">The User agrees to
                                defend, indemnify
                                and hold harmless the Indemnified Party against all claims and expenses, including
                                attorneys’ fees,
                                arising out of (i) the use of this Website and/or the mobile application by the User;
                                (ii) the use of
                                the Voucher; (iii) the use of the Service or Website or mobile application through your
                                password; or
                                (iv) any breach of this Agreement by you.</p>
                        </li> -->
                        <li >
                            <!-- <p style="padding-top: 3pt;padding-left: 29pt;text-indent: -6pt;text-align: left;"><br />
                            </p> -->
                            <!-- <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">INJUNCTIVE RELIEF</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">You acknowledge that our
                                products and
                                services contain valuable trade secrets and proprietary information of us and our other
                                protected
                                rights, and that any actual or threatened breach of this Agreement will constitute
                                immediate,
                                irreparable harm to us for which monetary damages would be an inadequate remedy, and
                                hence injunctive
                                relief is an appropriate remedy for such breach, which we shall be entitled to avail
                                without any
                                protest.</p> -->
                            <!-- <p style="text-indent: 0pt;text-align: left;"><br /></p> -->
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">TERMINATION</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We may terminate this
                                Agreement at any
                                time. Without limiting the foregoing, we shall have the right to immediately terminate
                                any passwords or
                                accounts of a User in the event of any conduct by the User which we, in our sole
                                discretion, consider
                                being unacceptable, or in the event of any breach by User of this Agreement.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">One User can have only
                                one Account
                                with a unique e-mail ID and unique phone number. If we have any suspicion or knowledge
                                that any of its
                                Users have created multiple Accounts with different e-mail addresses or phone numbers
                                (including but not
                                limited to account creation by using false names or providing misleading data for
                                creating those
                                Accounts or using disposable email addresses or disposable phone numbers) to generate
                                additional cash
                                back credits or misuse or take undue advantage of the promotional benefits being
                                provided on our
                                Platform, then we may, while reserving its rights to initiate civil and/or criminal
                                proceedings against
                                such User, also at our sole discretion terminate, suspend, block, restrict, cancel the
                                Account of such
                                User and/or disqualify that User and any related Users from availing the services
                                ordered or undue
                                benefits availed through these accounts.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">All such provisions
                                wherein the
                                context so requires, including Clauses on Intellectual Property Rights, Disclaimer of
                                Warranty,
                                Limitation of Liability, License Grant, Indemnification and Termination above will
                                survive termination
                                of this Agreement.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Our right to terminate
                                this Agreement
                                shall not prejudice any other right or remedy we may have in respect of any breach or
                                any rights,
                                obligations or liabilities accrued prior to termination.</p>
                        </li>
                        <li>
                            <h1 style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">THIRD
                                PARTY CONTENT</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Any opinions, advice,
                                statements,
                                services, offers, or other information or content expressed or made available by third
                                parties,
                                including Third Party Content Providers, or any other Users are those of the respective
                                author(s) or
                                distributors and not ours.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Neither we nor any
                                third-party
                                provider of information guarantees the accuracy, completeness, or usefulness of any
                                content, nor its
                                merchantability or fitness for any particular purpose (refer to the Clause for
                                ‘Disclaimer of Warranty,
                                Limitation of Liability’ above for the complete provisions governing limitation of
                                liabilities and
                                disclaimers of warranty).</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">In many instances, the
                                content
                                available through this Website or mobile application represents the opinions and
                                judgments of the
                                respective information provider, User, or other users not under contract with us, we
                                neither endorses
                                nor is responsible for the accuracy or reliability of any opinion, advice or statement
                                made on the
                                Website by anyone other than our authorized employee/spokespersons while acting in
                                official capacities.
                            </p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Under no circumstances
                                will we be
                                liable for any loss or damage caused by User’s reliance on information obtained through
                                the Website. It
                                is the responsibility of User to evaluate the accuracy, completeness or usefulness of
                                any information,
                                opinion, advice etc. or other content available through the Website.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">The Website and mobile
                                application may
                                contain links to third-party websites maintained by other content providers. These links
                                are provided
                                solely as a convenience to you and not as an endorsement by us of the contents on such
                                third-party
                                websites and we hereby expressly disclaim any representations regarding the content or
                                accuracy of
                                materials on such third-party websites. If the User decides to access linked third-party
                                websites, the
                                User does so at his/her own risk.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Unless you have
                                executed a written
                                agreement with us expressly permitting you to do so, you may not provide a hyperlink to
                                the Website from
                                any other website. we reserve the right to revoke its consent to any link at any time in
                                its sole
                                discretion.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">ADVERTISEMENT</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">We may place
                                advertisements in
                                different locations on the Website and at different points during use of the Service.
                                These locations
                                and points may change from time to time - but we will always clearly mark which goods
                                and services are
                                advertisements (i.e. from persons other than us), so that it is clear to you which goods
                                and services
                                are provided on an objective basis and which are not (i.e. the advertisements).</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">You are free to select
                                or click on
                                advertised goods and services or not as you see fit.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Any advertisements may
                                be delivered on
                                our behalf by a Third-Party Advertiser.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">No personal data (for
                                example your
                                name, address, email address or telephone number) will be used during the course of
                                serving our
                                advertising, but, on our behalf, our Third-Party Advertiser or affiliate may place or
                                recognize a unique
                                &quot;cookie&quot; on your browser (see our Privacy Policy about this). This cookie will
                                not collect
                                personal data about you nor is it linked to any personal data about you. If you would
                                like more
                                information about</p>
                            <p style="padding-top: 1pt;padding-left: 20pt;text-indent: 0pt;text-align: left;">this
                                practice and to know
                                your choices about not having this information used by any company, see our Privacy
                                Policy for more
                                information.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">PUBLICITY</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">You will not use our
                                name and/or logo
                                publicly without our prior written consent.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">The User hereby
                                authorizes us to send
                                service related transactional SMS messages, even in the case that the User’s contact
                                number is listed as
                                DND with the concerned telecom regulatory authority.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">FORCE MAJEURE</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Without prejudice to any
                                other provision
                                herein, we shall not be liable for any loss, damage or penalty as a result of any delay
                                in or failure to
                                deliver or otherwise perform hereunder due to any cause beyond our control, including,
                                without
                                limitation, acts of the User, embargo or other governmental act, regulation or request
                                affecting the
                                conduct of our business, fire, explosion, accident, theft, vandalism, riot, acts of war,
                                strikes or
                                other labor difficulties, lightning, flood, windstorm or other acts of God.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;text-align: left;">MISCELLANEOUS</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;"><a
                                    href="http://www.pmccare.in/" class="a" target="_blank">This Agreement and our
                                    Privacy Policy https://</a>www.pmccare.in
                                constitutes the entire agreement between the parties with respect to the subject matter
                                hereof. No
                                representation, undertaking or promise shall be taken to have been given or be implied
                                from anything
                                said or written in negotiations between the parties prior to this Agreement except as
                                expressly stated
                                in this Agreement. Neither party shall have any remedy in respect of any untrue
                                statement made by the
                                other upon which that party relied on when entering into this Agreement (unless such
                                untrue statement
                                was made fraudulently or was as to a matter fundamental to a party’s ability to perform
                                this Agreement)
                                and that party’s only remedies shall be for breach of contract as provided in this
                                Agreement. However,
                                the Service is provided to you under our operating rules, policies, and procedures as
                                published from
                                time to time on the Website.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">No waiver by either
                                party of any
                                breach or default hereunder is a waiver of any preceding or subsequent breach or
                                default.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">The section headings
                                used herein are
                                for convenience only and shall be of no legal force or effect.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">If any provision of
                                this Agreement is
                                held invalid by a court of competent jurisdiction, such invalidity shall not affect the
                                enforceability
                                of any other provisions contained in this Agreement and the remaining portions of this
                                Agreement shall
                                continue in full force and effect. The failure of either party to exercise any of its
                                rights under this
                                Agreement shall not be deemed a waiver or forfeiture of such rights or any other rights
                                provided
                                hereunder.</p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">All provisions of this
                                Agreement apply
                                equally to and are for our benefit, it subsidiaries, any of it&#39;s holding companies,
                                its (for their)
                                affiliated and it (or their) Third Party Content</p>
                            <p style="padding-top: 1pt;padding-left: 20pt;text-indent: 0pt;text-align: justify;">
                                Providers and licensors
                                and each shall have the right to assert and enforce such provisions directly or on its
                                own behalf (save
                                that this Agreement may be varied or rescinded without the consent of those parties).
                            </p>
                        </li>
                        <li data-list-text="o">
                            <p style="padding-left: 20pt;text-indent: -20pt;text-align: justify;">Legal issues arising
                                out of, but not
                                exclusive to the use of, this Website or mobile application (unless otherwise
                                specifically stated) are
                                governed by and in accordance with the laws of India (exclusive of its rules regarding
                                conflicts of
                                laws) and the parties shall submit to the exclusive jurisdiction of the Courts in [●].
                            </p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;">PRODUCTS/SERVICES TAKE
                                DOWN POLICY:
                                REPORTING VIOLATION OF INFRINGEMENT</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We want to list the
                                products and services
                                of genuine sellers on our website or mobile application in order to ensure that
                                infringing products are
                                removed from the site, as they affect your and genuine Seller’s valuable trust.</p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">We do not and cannot
                                verify if the
                                Sellers have the right or ability to sell or distribute their listed products or
                                services. However, we
                                are committed to removing any infringing or unlicensed product or service once an
                                authorized
                                representative of the rights owner properly reports them to us. We sincerely want to
                                ensure that item
                                listings do not infringe upon the copyright, trademark or other intellectual property
                                rights of third
                                parties. We have the ability to identify and request removal of allegedly infringing
                                products and
                                materials. Any person or company who holds intellectual property rights (such as a
                                copyright, trademark
                                or patent) which may be infringed upon by-products listed by us is encouraged to report
                                the same to our
                                authorized Officer.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <h1 style="padding-left: 5pt;text-indent: 0pt;">COMMUNICATION AND
                                NOTICES</h1>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">The communications
                                between you/the User
                                and us use electronic means, whether you/the User use our products and/or services or
                                send us emails, or
                                whether we post notices on our websites/platform/applications or communicate with
                                you/the User <i>via
                                </i>email, for contractual purposes, you/the User: (a) consent to receive communications
                                from us in an
                                electronic form; and (b) agree that all terms and conditions, agreements, notices,
                                disclosures, and
                                other communications that we provide to you/the User electronically satisfy any legal
                                requirement as if
                                it were in hardcopy writing. Notice shall be sent to the contact address set forth here
                                (as such may be
                                changed by notice given to the other party) and shall be deemed delivered as of the date
                                of actual
                                receipt.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="o">
                            <h1 style="padding-left: 20pt;text-indent: -20pt;">To you/the User:
                     </h1>
                     <span class="p">As
                        per the contact details provided to us at the time of registration or as may be
                        updated from time to
                        time in our records. We may give electronic notices to our user base by means of a
                        general notice
                        via our products and/or services or may give electronic notices specific to the User
                        by email to
                        appropriate User e-mail addresses on record in our account information database for
                        the User. We may
                        give telephonic notice to the User by calls to appropriate numbers on record in our
                        account
                        information database for the User.</span>
                        </li>
                    </ul>

                </div>
            </body>

            </html>
        </mat-card>
    </div>
</div>