import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterContentChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Validators, UntypedFormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store/index';
import { ToastrService } from 'ngx-toastr';
import { Images } from 'src/app/shared/config/images-config';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../shared/services/localstorage.service';
import { MatButton } from '@angular/material/button';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-otp-verification-choices',
    templateUrl: './otp-verification-choices.component.html',
    styleUrls: ['./otp-verification-choices.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatRadioGroup, FormsModule, ReactiveFormsModule, MatRadioButton, MatButton]
})
export class OtpVerificationChoicesComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
	images = Images;
	// serverErrorMessage : string;
	option = new UntypedFormControl('', [Validators.required]);
	chooseOption = 'sms';
	selectedLanguage: string;
	apiDetails: {};
	// color = '#000000';
	mode = 'indeterminate';
	value = 50;
	mobileNumber: any;
	getOtpChoicesStatusCode: number;
	errorMessage: string;
	isLoading = false;
	isLoaded = false;
	verifyOtpScreenLabels: {};
	serverMessage: string;
	isServerMessage: boolean;
	successMessages: {};
	isBlind;
	constructor(
		private router: Router,
		public dialog: MatDialog,
		private ref: ChangeDetectorRef,
		private store: Store<fromStore.CEPState>,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<OtpVerificationChoicesComponent>,
		private localStorageService: LocalStorageService,

	) {
		dialogRef.disableClose = true;
	}

	ngOnInit() {
		// server error handling
		this.store.select(fromStore.getUserStateServerErrorMessage)
			.subscribe((serverErrorMessage) => {
				if (serverErrorMessage != null) {
					if (serverErrorMessage === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverErrorMessage;
						this.isServerMessage = true;
						if (this.serverMessage) {
							console.error(this.serverMessage);
						}
					}
					this.serverMessage = null;
				}
				serverErrorMessage = null;
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
			});

		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
			});

		this.store.select(fromStore.getUserStateResponse)
			.subscribe((getUserStateResponse) => {
				this.mobileNumber = getUserStateResponse['mobile'];
			});

		this.store.select(fromStore.getVerifyOtpScreenLabels)
			.subscribe((res) => {
				this.verifyOtpScreenLabels = res;
			});

		this.store.select(fromStore.getOtpChoicesLoading)
			.subscribe((res) => {
				this.isLoading = res;
			});

		this.store.select(fromStore.getOtpChoicesLoaded)
			.subscribe((res) => {
				this.isLoaded = res;
				if (this.isLoaded) {
					this.store.select(fromStore.getOtpChoicesStatusCode)
						.subscribe((response) => {
							this.getOtpChoicesStatusCode = response;
							if (this.getOtpChoicesStatusCode === 200) {
								this.store.select(fromStore.getOtpChoicesErrorMessage)
									.subscribe(successMessage => {
										if (successMessage) {
											this.store.select(fromStore.getSuccessMessages)
												.subscribe(res => {
													if (res) {
														this.toastr.success(res[this.selectedLanguage][successMessage]);
													}
													res = null;
													this.dialog.closeAll();
													if(this.chooseOption == 'sms') 
														this.router.navigate(['/Login/otp', 'forgot-pass', this.chooseOption]);
													else if(this.chooseOption  == 'email')
														this.router.navigate(['Login/login']);
													this.store.dispatch(new fromStore.ResetForgetPassword());
												});
											successMessage = null;
										}
										this.getOtpChoicesStatusCode = 0;
									});
							} else if (this.getOtpChoicesStatusCode === 205) {
								this.store.select(fromStore.getOtpChoicesErrorMessage)
									.subscribe((res) => {
										this.errorMessage = res;
										if (this.errorMessage && this.errorMessage != null) {
											console.error(this.errorMessage);
										}
									});
									this.getOtpChoicesStatusCode = 0;
							}
						});
				}
			});
	}


/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }





	/**
	 * Created by Akanksha J
	 * For getting the response of OTP choices
	 */
	redirectToOtp() {
		if (this.chooseOption) {
			if (Object.keys(this.apiDetails).length > 0) {
				this.store.dispatch(new fromStore.GetOtpChoices({
					url: this.apiDetails['userauth'].base_url +
						this.apiDetails['userauth'].verifyForgetPassword.url,
					method: this.apiDetails['userauth'].verifyForgetPassword.method,
					body: {
						mobile: this.mobileNumber,
						type: this.chooseOption
					}
				}));
			}
		}
	}

	/**
	 * Created by Akanksha J
	 * For closing the popup and redirecting to the previous page
	 */
	cancleButton() {
		this.dialog.closeAll();
		this.router.navigate(['/Login/password']);
	}

	ngOnDestroy() {
		this.serverMessage = null;
		this.successMessages = null;
		this.store.dispatch(new fromStore.ResetForgetPassword());
	}

	ngAfterViewInit() {
		window.scrollTo(0, 0);
		this.ref.detectChanges();
	}

	ngAfterContentChecked() {
		this.ref.detectChanges();
	}
}
