
<!-- *******************************************002
 * Created by: Shital W
 * Created Date:
 * Updated by: Akanksha J
 * Updated Date: 29/01/2020
 * Component: Select preference popup After registration of user
****************************************************-->
<!-- popup -->
<div class="popup">
  <div *ngIf="isLoading" class="dialog-spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
  </div>
  <!-- popup heading -->
  <div class="popup" *ngIf="!isLoading">
    <div class="popup-header">
      <mat-card-title class="card-title">{{updatelabels[selectedLanguage].preferences}}</mat-card-title>
    </div>
    <!-- popup content -->
    <div class="popup-container">
      <div class="preference-button-container">
        <div *ngFor="let preference of preferencesValuelist ; let id = index">
          <button id="status" class="preference-button" mat-stroked-button
            (click)="changePreferenceChoice(preference,id)" [ngClass]="{'button-color': preference.status==true}">
            {{preference.choosePreference[1]}}</button>
        </div>
        <!-- Update Button -->
      </div>
    </div>
    <!-- button action -->
    <div class="popup-footer btn-grp" style="display:flex; justify-content: space-between;">
      <button mat-raised-button class="prefrence-update-button" (click)="PreferenceUpdate()" [disabled]="isDisable"
        (keyup.enter)="PreferenceUpdate()">
        {{updatelabels[selectedLanguage].update}}
      </button>
      <br>
      <div>
          <button  mat-raised-button class="prefrence-update-button1" (click)="goToFeed()" *ngIf="selectedLanguage === 'en' ">Skip For Now</button> 
          <button  mat-raised-button class="prefrence-update-button1" (click)="goToFeed()" *ngIf="selectedLanguage === 'mar' ">आतासाठी वगळा</button>
      </div>
    </div>
  </div>
</div>