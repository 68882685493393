import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterContentChecked,
} from "@angular/core";
import { Images } from "../../config/images-config";
import * as fromStore from "../../../cep/store/index";
import { Store } from "@ngrx/store";
import { LocalStorageService } from "../../services/localstorage.service";
import { EncrDecrService } from "../../services/encr-decr.service";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";
import { ObjectInterface } from "../../models/global-interface";
import { ReportAbusePopupComponent } from "../report-abuse-popup/report-abuse-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { ApiCallService } from "src/app/shared/services/dynamic-api-calling.service";
import { Router } from "@angular/router";
import { CommonModule, UpperCasePipe, DatePipe } from "@angular/common";

@Component({
    selector: "app-card-feeds",
    // standalone:true,
    // imports:[UpperCasePipe,CommonModule],
    templateUrl: "./card-feeds.component.html",
    styleUrls: ["./card-feeds.component.scss"],
    standalone: true,
    imports: [UpperCasePipe, DatePipe],
})
export class CardFeedsComponent implements OnInit {
  @Input() item: any;
  selectedLanguage;
  eventType = "";
  dayName = "";

  constructor(
    private dialog: MatDialog,
    private store: Store<fromStore.CEPState>,
    private ref: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private encrDecr: EncrDecrService,
    private apiCallService: ApiCallService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.store.select(fromStore.getSelectedLanguage).subscribe((language) => {
      this.selectedLanguage = language;

      this.eventType = this.understandTypeByLanguage(
        this.item.engagementType,
        language
      );

      this.dayName = this.getDay(this.item.createdAt, language);
    });
  }

  understandTypeByLanguage(eType: string, lang: string): string {
    switch (eType) {
      case "event":
        return lang === "en" ? eType : "कार्यक्रम";
      case "blog":
        return lang === "en" ? eType : "ब्लॉग्स";
      case "article":
        return lang === "en" ? eType : "लेख";
      case "news":
        return lang === "en" ? eType : "बातम्या";
      case "survey":
        return lang === "en" ? eType : "सर्वेक्षण/पोल्स";
      case "forum":
        return lang === "en" ? eType : "फोरम";
      case "competition":
        return lang === "en" ? eType : "स्पर्धा";
      case "PMC":
        return lang === "en" ? eType : "म.न.पा. सेवा";
      default:
        return eType;
    }
  }

  getDay(date: string, language: string): string {
    const daysEng = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const daysMar = [
      "रविवार",
      "सोमवार",
      "मंगळवार",
      "बुधवार",
      "गुरुवार",
      "शुक्रवार",
      "शनिवार",
    ];
    var d = new Date(date);
    return language === "en" ? daysEng[d.getDay()] : daysMar[d.getDay()];
  }

  getImage(item) {
    if (item.image && item.image.length > 0) {
      return item.image[0];
    } else if (item.imageContent && item.imageContent.length > 0) {
      return item.imageContent[0];
    } else if (item.imageFavourite && item.imageFavourite.length > 0) {
      return item.imageFavourite[0];
    }else{
      return 'https://fastly.picsum.photos/id/1/5000/3333.jpg?hmac=Asv2DU3rA_5D1xSe22xZK47WEAN0wjWeFOhzd13ujW4'
    }
  }
}
