import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../../cep/store/index';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';


@Component({
    selector: 'app-instruction-popup',
    templateUrl: './instruction-popup.component.html',
    styleUrls: ['./instruction-popup.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, MatDialogContent]
})
export class InstructionPopupComponent implements OnInit {
  selectedLanguage: string;
  apiDetails = {};
  Labels: {};

  constructor(private store: Store<fromStore.CEPState>,
		private router: Router) 
    
    {
   }

  ngOnInit() {
    this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});

    this.store.select(fromStore.getSelectedLanguage)
		.subscribe(lang => {
			this.selectedLanguage = lang;
		});

    this.store.select(fromStore.getInsructionPopupLabels)
						.subscribe((labels) => {
							this.Labels = labels;
						});
  }

}
