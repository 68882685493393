/** *********************************
 * Component -  Product component
 * Created By - Akanksha J
 * Updated By - Akanksha J
 * Updated On - 20/01/2020
 **********************************/

import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Images } from '../../config/images-config';
import * as fromStore from '../../../cep/store/index';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../../services/localstorage.service';
import { EncrDecrService } from '../../services/encr-decr.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ReportAbusePopupComponent } from '../report-abuse-popup/report-abuse-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiCallService } from 'src/app/shared/services/dynamic-api-calling.service';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DynamicLinksService } from '../../services/dynamic-links';
import { ClipboardService } from '../../services/clipboard.service';
import { environment } from 'src/environments/environment';
import { MatButton } from '@angular/material/button';
import { MatCardImage } from '@angular/material/card';
import { NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'app-card-product',
    templateUrl: './card-product.component.html',
    styleUrls: ['./card-product.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, MatCardImage, MatButton, NgClass]
})
export class CardProductComponent implements OnInit {
	@Input() productData: any;
	image = Images;
	eventDate;
	selectedLanguage;
	landingLables;
	postDate: any;
	favorite: string;
	apiDetails: any;
	todaysDate: any;
	feedsDate: Date;
	dateDiff: number;
	userId: string;
	token: any;
	endDate: string;
	startDate: string;
	shareUrl: any;
	eventImage: any;

	/**
	 * angular method for declaring packages in variables
	 *
	 * which runs first in this particulat component
	 */
	constructor(
		private dialog: MatDialog,
		private store: Store<fromStore.CEPState>,
		private ref: ChangeDetectorRef,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private apiCallService: ApiCallService,
		private router: Router,
		private toast: ToastrService,
		private dynamicLink: DynamicLinksService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private clipboardService:ClipboardService) { }

		/**
		 * angulat method which gets call on page initialization
		 */
	ngOnInit() {
		if (this.productData) {
			if (this.productData.favourite) {
				this.favorite = 'favorite';
				//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
			} else {
				this.favorite = 'favorite_border';
			}
		}

		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					let localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
				} else {
					this.userId = null;
				}
			});

		this.store.select(fromStore.getSelectedLanguage)
			.subscribe((language) => {
				this.selectedLanguage = language;
				this.store.select(fromStore.getFeedsLabels)
					.subscribe((labels) => {
						this.landingLables = labels;
					});
			});


		this.store.select(fromStore.getApiCallingList)
			.subscribe((apiDetails) => {
				this.apiDetails = apiDetails;
				if (Object.keys(this.apiDetails).length > 0) {
					this.shareUrl = this.apiDetails['shareUrl'];
				}
			});
	}

	/**
	 * Created by Akanksha J
	 * for reporting product as abuse
	 */
	REPORT_ABUSE() {
		this.dialog.open(ReportAbusePopupComponent, {
			data: {
				userId: this.userId,
				timelineId: this.productData['timelineId'],
				engagementType: 'product',
				engagementId: this.productData['engagementId']
			},
			maxHeight: '100vh',
			maxWidth: '100vw',
			disableClose: false
		});
  }
  
	/**
	 * Craeted by Ajay C
	 * For unmarking the favourites
	 */
	unMarkFavorite() {
		if (this.productData.favourite) {
			this.productData = Object.assign({}, this.productData, { favourite: false })
			this.favorite = 'favorite_border';
			//this.googleAnalyticsService.eventEmitter('Dashboard', 'click Favourite button of event', 'Click Event', 1);
		} else {
			this.productData = Object.assign({}, this.productData, { favourite: true });
			this.favorite = 'favorite';
		}
		// this.store.select(fromStore.getApiCallingList)
		//   .subscribe((apiDetails) => {
		//     this.apiDetails = apiDetails;
		if (Object.keys(this.apiDetails).length > 0) {
			this.shareUrl = this.apiDetails['shareUrl'];
			if (this.userId) {
				this.store.dispatch(new fromStore.MarkFavorite({
					url: this.apiDetails['user'].base_url +
						this.apiDetails['user'].addToFavourite.url,
					method: this.apiDetails['user'].addToFavourite.method,
					body: {
						"userId": this.userId,
						"engagementId": this.productData.engagementId,
						"engagementType": "event"
					}
				}));
			}
		}
	}

	/**
	 * Created by Vinita Sharma'
	 * For generating dynamic link to share and redirect to new page to share post
	 */
	generateShareLink(media) {
		var url =  this.shareUrl + "/cep/feeds/event/" + this.productData.timelineId
		var metaTag  = {
			socialTitle: this.productData.en.title,
			socialImageLink: this.productData.image[0]
		}
		this.apiCallService.configFirebaseLink(url, metaTag, media)
	} 


	/**
	 * angular method which gets calld on any change in screen
	 */
	ngAfterContentChecked() {
		this.ref.detectChanges();
		this.startDate = new Date(this.productData.startDate).toDateString();
		this.endDate = new Date(this.productData.endDate).toDateString();
		this.postDate = new Date(this.productData.updatedAt).toDateString();
	}

	/**
	 * Created by akanksha J
	 * for google analytics
	 */
	// clickOnShare() {
	// 	//this.googleAnalyticsService.eventEmitter('Dashboard', 'User click share button of event', 'Click Event', 1);
	// }
	async clickOnShare() {
			
	
		let url = environment.shareurl +"/cep/feeds/product/" + this.productData.timelineId
		const shareUrl = await this.dynamicLink.generateDynamicLink(url)

		const success = this.clipboardService.copyTextToClipboard(shareUrl)
		if (success) {
			this.toast.success("Link Copied to Clipboard")
		}else{
			this.toast.error("Unable to copy Link")
		}

	}


}
