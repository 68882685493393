/*******************************
 * Component -  Deal listing screen
 * Created By - Akanksha J
 * Created on - 31/03/2020
 * Updated By - Akanksha J
 * Updated On - 31/03/2020
 *************************************/
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import * as fromStore from '../../../../cep/store/index';
import { Images } from 'src/app/shared/config/images-config';
import { LocalStorageService } from '../../../services/localstorage.service';
import { EncrDecrService } from '../../../services/encr-decr.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RemoveCartDataFromProductPopupComponent } from '../remove-cart-data-from-product-popup/remove-cart-data-from-product-popup.component';
import { ObjectInterface } from 'src/app/shared/models/global-interface';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { screenName,clickedOn } from 'src/app/shared/config/google-analytics-constants';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgIf, NgFor, UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-deal-listing',
    templateUrl: './deal-listing.component.html',
    styleUrls: ['./deal-listing.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatTooltip, RouterLink, NgFor, MatButton, UpperCasePipe]
})
export class DealListingComponent implements OnInit, AfterViewInit {
	/**
	 * loading status of spinner
	 */
	isLoading = true;
	/**
	 * State of spinner
	 */
	state = 'default';
	/**
	 * color of spinner
	 */
	color = 'primary';
	/**
	 * mode of spinner
	 */
	mode = 'indeterminate';
	/**
	 * images from image.config
	 */
	images = Images;
	/**
	 * current selected language
	 */
	selectedLanguage: string;
	/**
	 * api details from dynamic api calling
	 */
	apiDetails = {};
	/**
	 * deals response
	 */
	result = [];
	/**
	 * server error message
	 */
	serverMessage: string;
	/**
	 * to check if server message is there or not
	 */
	isServerErrorMessage: boolean;
	/**
	 * variable from active route
	 */
	data: any;
	
	/**
	 * user token
	 */
	token: string;
	/**
	 * user id
	 */
	userId: any;
	dataCode = 0;
	cartLoaded = false
	isNewDealsLoading = false;
	isSeeMoreButtonShow = true;
	isLoaded = false
	noMoreData= false;
	/**
	 * For labels of deal listing
	 */
	dealListingLabels: {};
	dataNotFoundMsg: any;
	/**
	 * For labels of sort by
	 */
	sortByLabels: {};
	count = 0;
	sortByListingArrayValue: any[];
	reqBody: {};
	sortByLabelsfor= {};
	errorMessage: {};
	engagementResult: any;
	merchantDetails: ObjectInterface;
	successMsg: {};
	resultMsg: string;
	resultForRemove: string;
	isBlind;
	enableFeature: boolean;
	/**
	 * Angular method which runs on component initialization
	 */
	constructor(
		private store: Store<fromStore.CEPState>,
		private router: Router,
		public dialog: MatDialog,
		private localStorageService: LocalStorageService,
		private encrDecr: EncrDecrService,
		private activeRoute: ActivatedRoute,
		private toastr: ToastrService,
		private ref: ChangeDetectorRef,
		private googleAnalyticsService:GoogleAnalyticsService
	) {
		this.store.select(fromStore.getApiCallingList)
		.subscribe((apiDetails) => {
			this.apiDetails = apiDetails;
		});

		this.store.select(fromStore.getSelectedLanguage)
		.subscribe(lang => {
			this.selectedLanguage = lang;
		});

		this.store.select(fromStore.getfeature)
			.subscribe((features) => {
			this.enableFeature = features['merchantFeature'];
			});
	}

	/**
	 * Angular method which runs on page initialization
	 */
	ngOnInit() {
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_LISTING,
			{
				log: clickedOn.LOG_SCREEN,
				screenName: screenName.DEAL_LISTING + "_MOUNT",
				screenStartTime: new Date(),
				isScroll: false,
				screenEndTime: "",
				scrollValue: "",
			}
		)


		// token from localstorage
		this.store.select(fromStore.getToken)
			.subscribe((token) => {
				this.token = token;
				if (this.token) {
					const localUserId = this.localStorageService.getUserId();
					this.userId = this.encrDecr.get(localUserId);
					}
		});

		this.store.select(fromStore.getSortByLabels)
		.subscribe(labels => {
			this.sortByLabelsfor = labels;
		
			this.sortByLabels = labels[this.selectedLanguage];
			this.sortByListingArrayValue = [];
			Object.entries(this.sortByLabels).forEach((item, index) => {
				this.sortByListingArrayValue.push(item);
			});
		});

		this.store.select(fromStore.getMoreServicesLabels)
		.subscribe(labels => {
			this.dealListingLabels = labels;
		});

		// server error handling
		this.store.select(fromStore.getMoreServicesNetworkError)
			.subscribe((serverErrorMessage) => {
				if (serverErrorMessage != null) {
					if (serverErrorMessage === 'InvalidCredentials') {
						this.router.navigate(['/Login/login']);
					} else {
						this.serverMessage = serverErrorMessage;
						this.isServerErrorMessage = true;
						console.error(this.serverMessage);
					}
				}
			});

			this.store.select(fromStore.getDealsPaginationCount)
			.subscribe(prevCount => {
				this.count = prevCount;
			
			});

		// parameter from web path
		this.activeRoute.params.subscribe((param) => {
			this.data = param['merchantUserId'];
			if (this.data) {
				if (Object.keys(this.apiDetails).length > 0) {
					this.store.dispatch(new fromStore.GetAllEngagementByMerchantId({
					  url: this.apiDetails['userEngagement'].base_url +
					    this.apiDetails['userEngagement'].approval.getAllEngagementByUserId.url,
					  method: this.apiDetails['userEngagement'].approval.getAllEngagementByUserId.method,
						body: {
							userId : this.data,  //merchant user id
							citizenId : this.userId,
							prevCnt: '0',
							sortBy : this.reqBody
						}
					}));
				}
			}
		});

		this.store.select(fromStore.isGetAllEngagementByMerchantIdLoading)
		.subscribe(loading => {
			this.isLoading = loading;
		});

		this.store.select(fromStore.isGetAllEngagementByMerchantIdLoaded)
		.subscribe(loaded => {
			if (loaded) {
				this.isNewDealsLoading = false;
				this.cartLoaded = loaded
				this.store.select(fromStore.getAllEngagementByMerchantIdStatusCode)
				.subscribe(code => {
					if (code === 200) {
						this.store.select(fromStore.getAllEngagementByMerchantIdResult)
						.subscribe(result => {
							this.result = result;
							this.engagementResult = this.result;
							if (this.engagementResult.length % 5 !== 0) {
								this.isSeeMoreButtonShow = false;
							}
						});
						this.store.select(fromStore.getMerchantDetailsOfListing)
						.subscribe(merchantDetails => {
							this.merchantDetails = merchantDetails;
						});
					
					} else if (code === 500) {
						this.store.select(fromStore.getAllEngagementByMerchantIdSuccessMessage)
						.subscribe(errorMessages => {
							this.noMoreData = true;
							this.dataNotFoundMsg = errorMessages;
						});
					} 
				});
			}
		});

		this.store.select(fromStore.addItemToCartStatusCode)
		.subscribe((code:any) => {
				if (code === 200) {
					 this.store.dispatch(new fromStore.GetAllEngagementByMerchantId({
						url: this.apiDetails['userEngagement'].base_url +
							this.apiDetails['userEngagement'].approval.getAllEngagementByUserId.url,
						method: this.apiDetails['userEngagement'].approval.getAllEngagementByUserId.method,
							body: {
								userId : this.data,  //merchant user id
								citizenId : this.userId
							}
						}));
				}
		});
	}

	
/**
	 * Created by Vinita Sharma
	 * For text to speech functionality
	 */	
	speakText(textToSpeak){
		this.isBlind = this.localStorageService.getIsBlind();
		if(this.isBlind == 'true'){
		let speech = new SpeechSynthesisUtterance();
		speech.lang = "hi-IN";
		speech.text = textToSpeak;
		speech.volume = 1;
		speech.rate = 1;
		speech.pitch = 1;                    
		speechSynthesis.speak(speech);
		}
	  }

	  /**
	 * Created by Vinita Sharma
	 * Stops speaking when mouse leave the text content
	 */	
	  stopSpeaking(){
		speechSynthesis.cancel();
	  }




	/**
	 * Created by Akshata c
	 * For add data to cart
	 */ 
	
	async addToCart(data, i){
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_LISTING, {
			log: clickedOn.ADD_ITEM,
			screenName: screenName.DEAL_LISTING + "_SCREEN",
			clickedOn: clickedOn.VIEW,
			title: clickedOn.ADD_ITEM,
			time: new Date(),
			isInput: false,
			contentId:data.engagementId,
			inputData:{
				userId: this.userId,
				engagementId: data.engagementId,
				quantity: data.user_quantity + 1,
			}
		  })
		if (Object.keys(this.apiDetails).length > 0) {
			  await this.store.dispatch(new fromStore.AddItemToCart({
				  url: this.apiDetails["user"].base_url +
				  this.apiDetails["user"].addItemToTheCart.url,
				  method: this.apiDetails["user"].addItemToTheCart.method,
				  body:  {
					  userId: this.userId,
					  engagementId: data.engagementId,
					  quantity: data.user_quantity + 1,
					//   loyaltySpend: data.loyaltySpend
					// loyaltySpend: 0
				  }
				}));
		  await this.store.select(fromStore.addItemToCartStatusCode)
		    .subscribe((code:any) => {
		  		  if(code !== "You can't purchase more deal") {
					switch (code) {
						case 200:
							this.store.select(fromStore.getSuccessMessages)
						  	.subscribe(res => {
							  this.successMsg = res;
							  this.store.select(fromStore.addItemToCartSuccessMessage)
							  .subscribe(resultMessage => {
								  this.resultMsg = resultMessage
								if(data.user_quantity === 0){
									this.toastr.success(this.successMsg[this.selectedLanguage][this.resultMsg]);
								}else{
									this.selectedLanguage === 'en' ? this.toastr.success("Cart Quantity Updated")
								: this.toastr.success("कार्ट संख्या अपडेट केली")
								}
							  })
				  				
						  });
		  				  this.engagementResult[i].user_quantity = data.user_quantity + 1;
							break;
					    case 404:
							this.dialog.open(RemoveCartDataFromProductPopupComponent, {
								data: {
									disableClose: true,
									maxWidth: '100vw',
									autoFocus: false,
									label: this.data
								}
								}); 
						default:
							break;
					}
				}else if( code === "You can't purchase more deal" ){
					this.selectedLanguage === 'en' ? this.toastr.error("You can't purchase more deal")
					: this.toastr.error("तुम्ही अधिक डील खरेदी करू शकत नाही")
				}
			});
		 
	  	}
	
	}
	/**
	 * Created by Neha
	 * for remove to cart
	 */

	
	async removeToCart(data, i){
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_LISTING, {
			log: clickedOn.REMOVE_ITEM,
			screenName: screenName.DEAL_LISTING + "_SCREEN",
			clickedOn: clickedOn.VIEW,
			title: clickedOn.REMOVE_ITEM,
			time: new Date(),
			isInput: false,
			contentId:data.engagementId,
			inputData:{
				userId: this.userId,
				engagementId: data.engagementId,
				quantity: data.user_quantity - 1,
			}
		  })
		if (Object.keys(this.apiDetails).length > 0) {
			if(data.user_quantity == 1) {
				this.store.dispatch(new fromStore.RemoveItemFromCart({
					url: this.apiDetails['user'].base_url +
					this.apiDetails['user'].removeItemFromCart.url,
					method: this.apiDetails['user'].removeItemFromCart.method,
					body:  {
					userId: this.userId,
					engagementId: data.engagementId,
					quantity: 0,
					loyaltySpend: 0
					}
				}));
				this.store.select(fromStore.removeItemFromCartStatusCode)
				.subscribe(code => {
					if(code) {
						if (code === 200) {
							this.store.select(fromStore.getSuccessMessages)
						  .subscribe(res => {
							  this.successMsg = res;
							  this.store.select(fromStore.removeItemFromCartSuccessMessage)
							  .subscribe(resultMessage => {
								  this.resultForRemove = resultMessage
								this.toastr.error(this.successMsg[this.selectedLanguage][this.resultForRemove]);
							  })
						  });
						this.engagementResult[i].user_quantity = 0;
				  	}
			  	}
		  		});
				
			}
			else{
				await this.store.dispatch(new fromStore.AddItemToCart({
					url: this.apiDetails["user"].base_url +
					this.apiDetails["user"].addItemToTheCart.url,
					method: this.apiDetails["user"].addItemToTheCart.method,
					body:  {
						userId: this.userId,
						engagementId: data.engagementId,
						quantity: data.user_quantity - 1,
						// loyaltySpend: data.loyaltySpend
						// loyaltySpend: 0
					}
				}));
				await this.store.select(fromStore.addItemToCartStatusCode)
				.subscribe((code:any) => {
					if(code !== "You can't purchase more deal") {
						if (code === 200) {
							  this.store.select(fromStore.getSuccessMessages)
							  .subscribe(res => {
								  this.successMsg = res;
								  this.store.select(fromStore.addItemToCartSuccessMessage)
								  .subscribe(resultMessage => {
									  this.resultMsg = resultMessage
									this.selectedLanguage === 'en' ? this.toastr.error("Cart Quantity Updated")
									: this.toastr.error("कार्ट संख्या अपडेट केली")
								  })
									  
							  });
								this.engagementResult[i].user_quantity = data.user_quantity - 1;
						} 
						else if (code === 404) {
							this.dialog.open(RemoveCartDataFromProductPopupComponent, {
							data: {
								disableClose: true,
								maxWidth: '100vw',
								autoFocus: false,
								label: this.data
							}
							});
						}
						else if (code === 101 || code === 100) {
							this.store.select(fromStore.getErrorMessages)
							.subscribe(res => {
								this.errorMessage = res;
								console.error(this.errorMessage[this.selectedLanguage][code]);
							});
						}
					}else if( code === "You can't purchase more deal" ){
						this.selectedLanguage === 'en' ? this.toastr.error("You can't purchase more deal")
						: this.toastr.error("तुम्ही अधिक डील खरेदी करू शकत नाही")
					}
				});
			}
		}
	}

	

	/**
	 * Created by neha r
	 * for sort by api call
	 */
	async getEngagementSortBy(value){		
		await this.store.dispatch(new fromStore.SetPaginationCountDeal({
			dealsPaginationCount: 0
		}));
		if(value=='date'){
			document.getElementById('date').style.fontWeight = "bolder";
			document.getElementById('mostLiked').style.fontWeight = "unset";
			document.getElementById('mostViewed').style.fontWeight = "unset";
			document.getElementById('priceLowToHigh').style.fontWeight = "unset";
			document.getElementById('priceHighToLow').style.fontWeight = "unset";
			document.getElementById('dummy').style.fontWeight = "unset";		
			this.reqBody = {updatedAt: 'asc'}

		}else if(value=='priceLowToHigh'){
			document.getElementById('date').style.fontWeight = "unset";
			document.getElementById('mostLiked').style.fontWeight = "unset";
			document.getElementById('mostViewed').style.fontWeight = "unset";
			document.getElementById('priceLowToHigh').style.fontWeight = "bolder";
			document.getElementById('priceHighToLow').style.fontWeight = "unset";
			document.getElementById('dummy').style.fontWeight = "unset";		
			this.reqBody = {actualAmount: 'asc'}
		}
		else if(value=='priceHighToLow'){
			document.getElementById('date').style.fontWeight = "unset";
			document.getElementById('mostLiked').style.fontWeight = "unset";
			document.getElementById('mostViewed').style.fontWeight = "unset";
			document.getElementById('priceLowToHigh').style.fontWeight = "unset";
			document.getElementById('priceHighToLow').style.fontWeight = "bolder";
			document.getElementById('dummy').style.fontWeight = "unset";		
			this.reqBody = {actualAmount: 'desc'}
		}
		else if(value=='mostLiked'){
			document.getElementById('date').style.fontWeight = "unset";
			document.getElementById('mostLiked').style.fontWeight = "bolder";
			document.getElementById('mostViewed').style.fontWeight = "unset";
			document.getElementById('priceLowToHigh').style.fontWeight = "unset";
			document.getElementById('priceHighToLow').style.fontWeight = "unset";
			document.getElementById('dummy').style.fontWeight = "unset";		
			this.reqBody = {fav: 'desc'}
		}
		else if(value=='mostViewed'){
			document.getElementById('date').style.fontWeight = "unset";
			document.getElementById('mostLiked').style.fontWeight = "unset";
			document.getElementById('mostViewed').style.fontWeight = "bolder";
			document.getElementById('priceLowToHigh').style.fontWeight = "unset";
			document.getElementById('priceHighToLow').style.fontWeight = "unset";
			document.getElementById('dummy').style.fontWeight = "unset";		
			this.reqBody = {readCount: 'desc'}
		}
		else if(value=='dummy'){
				document.getElementById('date').style.fontWeight = "unset";
				document.getElementById('mostLiked').style.fontWeight = "unset";
				document.getElementById('mostViewed').style.fontWeight = "unset";
				document.getElementById('priceLowToHigh').style.fontWeight = "unset";
				document.getElementById('priceHighToLow').style.fontWeight = "unset";
				document.getElementById('dummy').style.fontWeight = "bolder";		
				this.reqBody = {updatedAt: 'desc'}
		}
		if(this.reqBody){
			this.activeRoute.params.subscribe((param) => {
				this.data = param['merchantUserId'];
				if (this.data) {
					if (Object.keys(this.apiDetails).length > 0) {
						this.store.dispatch(new fromStore.GetAllEngagementByMerchantId({
						  url: this.apiDetails['userEngagement'].base_url +
							this.apiDetails['userEngagement'].approval.getAllEngagementByUserId.url,
						  method: this.apiDetails['userEngagement'].approval.getAllEngagementByUserId.method,
							body: {
								userId : this.data,  //merchant user id
								citizenId : this.userId,
								prevCnt: 0,
								sortBy : this.reqBody
							},
							isSort: true
						}));
					}
				}
			});
		}
	}
	/**
	 * Created by neha
	 * Pagination count
	 */
	async onScroll(){
		await this.store.dispatch(new fromStore.SetPaginationCountDeal({
			dealsPaginationCount: this.count + 1
		}));
		if (this.data) {
			
			if (Object.keys(this.apiDetails).length > 0) {
				await this.store.dispatch(new fromStore.GetAllEngagementByMerchantId({
				  url: this.apiDetails['userEngagement'].base_url +
					this.apiDetails['userEngagement'].approval.getAllEngagementByUserId.url,
				  method: this.apiDetails['userEngagement'].approval.getAllEngagementByUserId.method,
					body: {
						userId : this.data,  //merchant user id
						citizenId : this.userId,
						prevCnt: JSON.stringify(this.count),
						sortBy : this.reqBody
					}
				}));
			}
		}
		if (this.result.length % 5 !== 0) {
			this.isSeeMoreButtonShow = false;
		}

		await this.store.select(fromStore.isGetAllEngagementByMerchantIdLoading)
			.subscribe(isLoading => {
				if (isLoading) {
					if (this.count ) {
						this.isNewDealsLoading = true;
						this.isLoading = !isLoading;
					} else {
						this.isNewDealsLoading = false;
						this.isLoading = isLoading;
					}
				}
			});
	}
	/**
	 * Created by Akanksha J
	 * FOr redirecting to merchant details screen
	 */
	openMerchantDetail() {
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_LISTING, {
			log: clickedOn.MERCHANT_DETAILS,
			screenName: screenName.DEAL_LISTING + "_SCREEN",
			clickedOn: clickedOn.VIEW,
			title: clickedOn.MERCHANT_DETAILS,
			time: new Date(),
			isInput: false,
			merchantId:this.merchantDetails.userId
		  })
		this.router.navigate(['/cep/merchant-details/', this.merchantDetails.userId]);
	} 

	/**
	 * Created by Akshata c
	 * FOr redirecting to deal details screen
	 */
	openDetail(data) {
		this.googleAnalyticsService.eventEmitter(screenName.DEAL_LISTING, {
			log: clickedOn.VIEW_DEAL,
			screenName: screenName.DEAL_LISTING + "_SCREEN",
			clickedOn: clickedOn.VIEW,
			title: clickedOn.VIEW_DEAL,
			time: new Date(),
			isInput: false,
			contentId:data.engagementId
		  })
		this.router.navigate(['/cep/feeds/product/', data.engagementId]);	
	} 

	/**
	 * Angualr method which runs after view initialization
	 */
	ngAfterViewInit() {
		window.scroll(0, 0); 
	}
	ngAfterContentChecked() {
		this.ref.detectChanges();
	}
	ngOnDestroy() {
		this.store.dispatch(new fromStore.resetDeal({
			dealReset:{}
		}))

		this.googleAnalyticsService.eventEmitter(screenName.DEAL_LISTING,
			{
				log: clickedOn.LOG_SCREEN,
				screenName: screenName.DEAL_LISTING + "_UNMOUNT",
				screenStartTime: "",
				isScroll: false,
				screenEndTime: new Date(),
				scrollValue: "",
			}
		)
	}
	
}
