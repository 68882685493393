<!-- *******************************************************************
*  Component -  Forgot password screen
   Created By - Akanksha Jadhav
   Created On - 07/02/2020
   Updated By - Akanksha Jadhav
   Updated On - 08/02/2020
*****************************************************************  -->
<div *ngIf="isLoading" class="spinner-loader">
  <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div>
<div  *ngIf="!isMobile && !isTablet">
<ng-container>
  <div class="container">
    <button mat-button mat-stroked-button class="language-button" (click)="onChangeButton()">
      {{showSelectedLanguage}}
    </button>
  <!-- <button mat-raised-button class="select-language" [matMenuTriggerFor]="menu" (mouseenter)="speakText('Select language')"
    (mouseleave)="stopSpeaking()"
    style="float: right; background-color: #3A067B; color: white; margin-top: 8px; border-radius: 11px;">
    {{showSelectedLanguage}}
    <mat-icon class="drop-down">keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [value]='en' (mouseenter)="speakText(drawerLabels['en'].english)" (click)="changeLanguage('en')"
      (mouseleave)="stopSpeaking()">{{drawerLabels['en'].english}}
    </button>
    <button mat-menu-item [value]='mar' (mouseenter)="speakText(drawerLabels['mar'].marathi)"
      (click)="changeLanguage('mar')" (mouseleave)="stopSpeaking()">{{drawerLabels['mar'].marathi}}
    </button>
  </mat-menu> -->
  <div class="loginscreen" *ngIf="!isLoading && changePasswordLabels && selectedLanguage">
    <mat-icon class="back-arrow font-heading" (click)="goBack()" (mouseenter)="speakText('Go back')"
       (mouseleave)="stopSpeaking()">keyboard_backspace</mat-icon>

        <img src="{{images.backgroundSigninImg1}}"  loading="lazy" alt="..." style="width: 62%;margin-bottom: -9rem;">

        <img class="align-content" style="left:14%;top: 26%;" src='{{images.finalLogin}}' loading="lazy" alt="...">
        <img class="signinImg" src="{{images.backgroundSigninImg}}" loading="lazy" alt="..." style="width: 100%;margin-bottom: -8px;opacity: 0.5;background-image: url(gradient.png);">

        <div class="align-content" style="left:50%; top:19%;line-height: 51px;">
          <div class=" blue-heading font-heading" style="margin-top: 20%;margin-left: 40%;">{{passwordLabels[selectedLanguage].enterPassword}}</div>
      <div class="form-container" style="margin-left: 56%;">
        <form [formGroup]="myForm">
          <mat-form-field floatLabel="always" floatLabel="auto" class="text-boxes" appearance="outline" class="text-boxes" style="width: 98%;">
            <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="newPassword" formControlName="password"
              autocomplete="off" placeholder="{{changePasswordLabels[selectedLanguage].password}}">
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
            
          </mat-form-field>
          <mat-error *ngIf="myForm.hasError('pattern', 'password')">
            {{registerLabels[selectedLanguage].passwordMessage}}
          </mat-error>
          <mat-form-field floatLabel="always" floatLabel="auto" class="text-box-two" appearance="outline"
            class="text-boxes" style="width: 98%;">
            <input matInput [type]="cPasshHide ? 'password' : 'text'" [(ngModel)]="cPassword" autocomplete="off"
              formControlName="confirmPassword" [errorStateMatcher]="matcher"
              placeholder="{{changePasswordLabels[selectedLanguage].confirmPassword}}">
            <!-- <mat-icon matSuffix (click)="cPasshHide = !cPasshHide">
              {{cPasshHide ? 'visibility_off' : 'visibility'}}
            </mat-icon> -->
          </mat-form-field>
          <mat-error  *ngIf=" myForm.hasError('notSame')">
            {{registerLabels[selectedLanguage].passwordDoNotMatch}}
          </mat-error>
          <label *ngIf="errMessage">{{errMessage}}</label>
          <div class="button-container">
            <button mat-raised-button class="button" type="submit"
              [disabled]="!myForm.valid || myForm.hasError('notSame')"
              (click)='verifyPasswordStrength(newPassword, cPassword)' style="margin-top: 6px;">
              {{passwordLabels[selectedLanguage].reset | uppercase}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</ng-container>
</div>

<ng-container *ngIf="isMobile || isTablet">
  <div *ngIf="!isLoading && changePasswordLabels && selectedLanguage">
    <div class="parent">
      <img src="{{images.backgroundSigninImg1}}" loading="lazy" alt="..." style="width: 143%;">
      <div class="inner"><img class="background-image-mobile" src='{{images.finalLogin}}' loading="lazy" alt="..."
          style="margin-left: 77px; margin-top: 31px;"></div>
    </div>
    <div class="main-content-mobile" style="margin-left: 50px;">
      <div class=" blue-heading font-heading" style="margin-top: 10%;">{{passwordLabels[selectedLanguage].enterPassword}}</div>
      <div class="form-container">
        <form [formGroup]="myForm">
          <div class="form-container">
            <mat-form-field floatLabel="always" floatLabel="auto" class="text-box" appearance="outline"
              class="text-box" style="width: 244px; margin-top: 13px;">
              <!-- <mat-label class="label">{{changePasswordLabels[selectedLanguage].password}}</mat-label> -->
              <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="newPassword" formControlName="password"
                autocomplete="off" placeholder="{{changePasswordLabels[selectedLanguage].password}}">
              <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
              </mat-icon>
              <mat-error *ngIf="myForm.hasError('pattern', 'password')">
                {{registerLabels[selectedLanguage].passwordLength}}
              </mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="always" floatLabel="auto" class="text-box-two" appearance="outline"
              class="text-box" style="width: 244px; margin-top: 14px;">
              <input matInput [type]="cPasshHide ? 'password' : 'text'" [(ngModel)]="cPassword" autocomplete="off"
                formControlName="confirmPassword" [errorStateMatcher]="matcher"
                placeholder="{{changePasswordLabels[selectedLanguage].confirmPassword}}">
              <mat-icon matSuffix (click)="cPasshHide = !cPasshHide">
                {{cPasshHide ? 'visibility_off' : 'visibility'}}
              </mat-icon>
              <mat-error *ngIf="myForm.hasError('notSame')">
                {{registerLabels[selectedLanguage].passwordDoNotMatch}}
              </mat-error>
            </mat-form-field>
            <div class="button-container">
              <button mat-raised-button class="button" type="submit"
                [disabled]="!myForm.valid || myForm.hasError('notSame')"
                (click)='verifyPasswordStrength(newPassword, cPassword)' style="margin-top: 12px;">
                {{passwordLabels[selectedLanguage].reset | uppercase}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <img src='{{images.backgroundWithLogo1}}' loading="lazy" alt="..." class="footer-image1" style="margin-top: 140%;">
  </div>
</ng-container>
