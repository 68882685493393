    <!-- /******
 * Component -  event details screen
 * Created By - Ajay Chavan
 * Updated By - Ajay Chavan
 * Updated On - 22/09/2019
 */ -->

<!-- loader -->
<div *ngIf="isLoading || isRegistrationLoading || isSubmitLoading" class="spinner-loader">
  <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner>
</div>
<!-- main page -->
<div *ngIf="!isLoading">
  <div *ngIf="feedsData != undefined" class="post-navigation font-sub-heading">
    <p matTooltipClass="engagement-tooltip  " matTooltip="{{feedsData[selectedLanguage].heading}}" style="width: 80%;">
      <span [routerLink]="[ '/cep/feeds']" class="feeds-redirection font-card-heading">
        {{ landingLables[selectedLanguage].feed | uppercase}}</span> > {{feedsData[selectedLanguage].heading}}</p>
      </div>
    <div class="post-container">
      <div class="post-image">
        <app-details-page-slider [postImages]="feedsData.image"></app-details-page-slider>
      </div>
      <div class="post-details">
        <div class="details-page-post-description">
          <div class="post-type opacity">
            <p class="opacity">{{feedFilterLabels[selectedLanguage].competition | uppercase}}</p>
            <button mat-raised-button class="mat-button font-normal">{{feedsData.type}}</button>
          </div>
          <div class="post-heading font-heading opacity">
            <p class="font-heading">{{feedsData[selectedLanguage].heading}}</p>
          <!-- <mat-icon class="speaker-icon-class pointer" matTooltip="{{landingLables[selectedLanguage].speak}}" (click)="speakText(feedsData[selectedLanguage].heading)">volume_up</mat-icon> -->
          </div>
          <div *ngIf="feedsData[selectedLanguage].content" class="post-containt ">
            <p class=" font-normal" [innerHTML]="feedsData[selectedLanguage].content"></p>
        <!-- <mat-icon class="speaker-icon-class pointer" matTooltip="{{landingLables[selectedLanguage].speak}}" (click)="speakText(feedsData[selectedLanguage].content, feedsData[selectedLanguage].heading)">volume_up</mat-icon> -->

          </div>
          <div class="post-containt post-date ">
            <label class="opacity font-medium">{{feedLabels[selectedLanguage].competitionDate}}</label>
            <p class="font-normal">{{startDate}} - {{endDate}}</p>
          </div>
          <div class="post-containt post-date ">
            <label class="opacity font-medium">{{feedLabels[selectedLanguage].registrationDate}}</label>
            <p class="font-normal">{{startDateRegistration}} - {{registrationEndDate}}</p>
          </div>
          <div class="post-containt ">
            <label class="terms-heading font-medium opacity">{{feedLabels[selectedLanguage].termsAndCondition}}</label>
            <p class="font-normal">{{feedsData[selectedLanguage].termAndCondition}}</p>
          </div>
          <div class="post-containt ">
            <label class="website-link font-medium opacity">{{feedLabels[selectedLanguage].websiteLink}}</label>
            <p class="font-normal"><a href="{{feedsData.webLink}}" target="_blank">{{feedsData.webLink}}</a></p>
          </div>
          <div class="button labels">
            <button *ngIf="feedsData.isRegistrationStarted == true &&
            feedsData.isRegisterted == false &&
            feedsData.isResultAnnouncement === false" (click)="registerCompetition()"
            mat-raised-button class="deal-button labels font-normal opacity">{{feedLabels[selectedLanguage].register}}</button>
        </div>
        <div class="inputCard" *ngIf="feedsData.isSubmitted === false &&
        feedsData.isSubmissionStarted == true && feedsData.isResultAnnouncement === false" >
          <label class="font-normal opacity"><strong>{{feedLabels[selectedLanguage].upload}}</strong></label>
          <input id="FileInput" #file class="chooseImage" type="file" accept="application/pdf," (change)="preview($event)" />
          <label for="FileInput" class="margin-left-img">
            <!-- <mat-icon class="circle">add_circle</mat-icon> -->
            <img class="upload-doc" src="{{images.uploadImg}}" loading="lazy" alt="...">
        </label><br>
       <label class="font-small">{{fileData}}</label>
          <div class="button labels" *ngIf="feedsData.isSubmissionStarted == true &&
          feedsData.isSubmitted == false &&
          feedsData.isResultAnnouncement === false">
            <button mat-raised-button class="deal-button labels font-small opacity" 
            (click)="submitCompetition()">{{feedLabels[selectedLanguage].submit}}</button>
          </div>
         
      </div><br>
        </div>
        <div class="post-date-type">
          <div class="booking-sharing">
            <div class="booking" *ngIf="feedsData.isRegistrationStarted == true &&
            feedsData.isRegisterted == false &&
            feedsData.isResultAnnouncement === false"> 
              <button mat-raised-button class="mat-button font-normal opacity"
                >{{feedLabels[selectedLanguage].registerNow}}</button>
            </div>
            <!-- share options -->
            <div class="sharing">
              <div class="abuse-flag-container">
                <mat-icon class="abuse-flag pointer" matTooltipClass="engagement-tooltip" matTooltip="{{landingLables[selectedLanguage].reportAbuse}}" (click)="reportAsAbuse()">flag</mat-icon>
            </div>
              <div *ngIf="this.userId" (click)="clickOnShare()"><i class="material-icons" [matMenuTriggerFor]="menu" matTooltip="{{landingLables[selectedLanguage].share}}">
                  share
                </i></div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="share-button" (click)="generateShareLink('facebook')">
                  <img class="social-share-img" src="{{images.facebookShareImg}}" loading="lazy" alt="...">
                  Facebook
                </button>
                <button mat-menu-item class="share-button" (click)="generateShareLink('twitter')">
                  <img class="social-share-img" src="{{images.twitterShareImg}}" loading="lazy" alt="...">
                  Twitter
                </button>
                <button mat-menu-item class="share-button" (click)="generateShareLink('linkedin')">
                  <img class="social-share-img" src="{{images.linkedinShareImg}}" loading="lazy" alt="...">
                  LinkedIn
                </button>
                <button mat-menu-item class="share-button" (click)="generateShareLink('whatsapp')"> 
                  <img class="social-share-img whatsapp-share" src="{{images.whatsappShareImg}}" loading="lazy" alt="...">
                  Whatsapp
                </button>
              </mat-menu>
              <div *ngIf="userId"><i class="material-icons favorite-icon"
                  [ngClass]="{'active-favorite':feedsData.favourite}" matTooltip="{{landingLables[selectedLanguage].addToFavorites}}" (click)="unMarkFavorite()">
                  {{favorite}}
                </i></div>
            </div>
          </div>
          <div class="post-date-venue">
            
            <!-- posted by details -->
            <div class="post-venue  ">
              <label class="opacity font-medium">{{feedLabels[selectedLanguage].postedOn}}</label>
              <p class="font-normal">{{postDate}}</p>
            </div>
            <div class="post-venue  ">
              <label  class="opacity font-medium">{{feedLabels[selectedLanguage].postedBy}}</label>
              <p class="font-normal">{{feedsData.createdBy}}</p>
            </div>
            <div class="post-venue-direction">
              <div class="post-venue">
                <label class="opacity">{{feedLabels[selectedLanguage].venue}}</label>
                <p class="font-normal">{{feedsData[selectedLanguage].address}}</p>
              </div>
              <div class="direction-icon">
                <img src="{{images.directionImage}}" loading="lazy" alt="..." (click)="redirectToGoogleMap()">
              </div>
            </div>
          </div>
          <hr class="hr-opacity"  *ngIf = "feedsData.isResultAnnouncement === true">
          <div class="competition-declared-winners" *ngIf = "feedsData.isResultAnnouncement === true">
            <label class="post-date-venue font-medium opacity "><strong>{{feedLabels[selectedLanguage].winners | uppercase}}</strong></label>
            <div *ngFor = "let item of feedsData.winners">
            <div class="assignRank" *ngIf="item.rankNumber == 3">
              <mat-card class="mat-card-winners">
                <label class="circle"> <img class = "profile" src = "{{item.profilePhoto}}" loading="lazy" alt="..."></label>
                <label class="winner-name font-medium">{{item.firstName}} {{item.lastName}}</label>
                <label class="circle-winner"> <img class = "winner-image" src = "{{images.thirdMedal}}" loading="lazy" alt="..."></label>
              </mat-card>
            </div>
            <div class="assignRank" *ngIf="item.rankNumber == 2">
              <mat-card class="mat-card-winners">
                <label class="circle"> <img class = "profile" src = "{{item.profilePhoto}}" loading="lazy" alt="..."></label>
                <label class="winner-name font-medium">{{item.firstName}} {{item.lastName}}</label>
                <label class="circle-winner"> <img class = "winner-image" src = "{{images.secondMedal}}" loading="lazy" alt="..."></label>
              </mat-card>
            </div>
            <div class="assignRank" *ngIf="item.rankNumber == 1">
              <mat-card class="mat-card-winners">
                <label class="circle"> <img class = "profile" src = "{{item.profilePhoto}}" loading="lazy" alt="..."></label>
                <label class="winner-name font-medium">{{item.firstName}} {{item.lastName}}</label>
                <label class="circle-winner"> <img class = "winner-image" src = "{{images.firstMedal}}" loading="lazy" alt="..."></label>
              </mat-card>
            </div>
            <div class="assignRank" *ngIf="item.rankNumber > 3">
              <mat-card class="mat-card-winners">
                <label class="circle"> <img class = "profile" src = "{{item.profilePhoto}}" loading="lazy" alt="..."></label>
                <label class="winner-name font-medium">{{item.firstName}} {{item.lastName}}</label>
              </mat-card>
            </div>
           </div>
          </div>
        </div>
      </div>
      <div class = "related-article-portion" *ngIf = "feedsData.relatedArticle.length > 0">
        <div class="article-heading">
          <label  class="opacity">{{feedLabels[selectedLanguage].relatedArticle}}</label>
        </div>
        <app-related-article [feedsData]="feedsData?.relatedArticle" [selectedLanguage]="selectedLanguage" [landingLables]="landingLables"></app-related-article>
      </div>
    </div>
 
  <div class="details-page-error-message" *ngIf="errMessage && feedsData === undefined">
    <img src="{{images.pageNotFound}}" loading="lazy" alt="...">
    <span>{{errMessage}}</span>
  </div>
