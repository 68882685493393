<!--************************************* 
Created by: Akanksha Jadhav
Created Date: 27/12/19
updated by: Akanksha Jadhav
Updated Date: 10/01/2020
Description: city info screen
***************************************** -->
<!-- loaded of this page -->
<div *ngIf="isLoading" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
<!-- connection lost condition -->
<div *ngIf="serverErrorMessage" class="connectionDiv">
    <img src={{images.connectionLost}} class="connectionLostImg" loading="lazy" alt="...">
    <span>{{serverErrorMessage}}</span>
</div>
<!-- content -->
<div class="container" *ngIf="!isLoading && !serverErrorMessage">
    <!-- header -->
    <div class="heading-container">
        <label class="heading font-heading"><strong class="font-fix"
            (mouseenter)="speakText(aboutUsLabels[selectedLanguage].cityInformation)"  (mouseleave)="stopSpeaking()"
            >{{aboutUsLabels[selectedLanguage].cityInformation}}</strong></label><br>
    </div>
    <!-- card of location info -->
    <mat-card class="card">
        <div>
            <div class="paragraph" (mouseenter)="speakText(cityData[selectedLanguage]['paragraph'][0])"  (mouseleave)="stopSpeaking()">{{cityData[selectedLanguage]['paragraph'][0]}}</div>
            <div class="header-container">
                <div class="header"><strong class="font-fix">{{aboutUsLabels[selectedLanguage].locationInformation}} :</strong></div>
            </div>
            <div class="content" *ngIf="cityData && selectedLanguage">
                <div class="row" *ngFor="let data of cityData[selectedLanguage]['locationInformation']; let i = index">
                    <div class="label" (mouseenter)="speakText(data[0])"  (mouseleave)="stopSpeaking()">{{data[0]}}</div>
                    <div class="description" (mouseenter)="speakText(data[1])"  (mouseleave)="stopSpeaking()">{{data[1]}}</div>
                </div>
            </div>
        </div>
    </mat-card>
    <!-- card of city info -->
    <mat-card class="card">
        <div>
            <div class="header-container">
                <div class="header" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].cityInformation)"  (mouseleave)="stopSpeaking()"><strong class="font-fix">{{aboutUsLabels[selectedLanguage].cityInformation}} :</strong></div>
                <div class="font-card-heading" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].populationAndArea)"  (mouseleave)="stopSpeaking()"><strong class="font-fix">{{aboutUsLabels[selectedLanguage].populationAndArea}}</strong></div>
            </div>
            <div class="content" *ngIf="cityData && selectedLanguage">
                <div class="row" *ngFor="let data of cityData[selectedLanguage]['cityInformation']">
                    <div class="label" (mouseenter)="speakText(data[0])"  (mouseleave)="stopSpeaking()">{{data[0]}}</div>
                    <div class="description" (mouseenter)="speakText(data[1])"  (mouseleave)="stopSpeaking()">{{data[1]}}</div>
                </div>
            </div>
        </div>
    </mat-card>
    <!-- card of no of employees -->
    <mat-card class="card">
        <div>
            <div class="header-container">
                <div class="header"><strong class="font-fix" (mouseenter)="speakText(aboutUsLabels[selectedLanguage].noOfCorpnEmployees)"  (mouseleave)="stopSpeaking()">{{aboutUsLabels[selectedLanguage].noOfCorpnEmployees}} :</strong></div>
            </div>
            <div class="content" *ngIf="cityData && selectedLanguage">
                <div class="row" *ngFor="let data of cityData[selectedLanguage]['employeesInformation']">
                    <div class="label" (mouseenter)="speakText(data[0])"  (mouseleave)="stopSpeaking()">{{data[0]}}</div>
                    <div class="description" (mouseenter)="speakText(data[1])"  (mouseleave)="stopSpeaking()">{{data[1]}}</div>
                </div>
            </div>
        </div>
    </mat-card>
</div>