import { Component, OnInit , HostListener, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService } from '../../services/localstorage.service';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-speak-for-blind-people',
    templateUrl: './speak-for-blind-people.component.html',
    styleUrls: ['./speak-for-blind-people.component.scss'],
    standalone: true,
    imports: [MatButton]
})
export class SpeakForBlindPeopleComponent implements OnInit {
  isMobile: boolean;
  textSpeak: any;
// hostlistener to listen the double click event and redirect to the NVDA website
  @HostListener('document:dblclick', ['$event'])
  clickout() {
    if(!this.isMobile){
      this.localStorageService.setToBlind('true');//This will set the isBlind global global variable to TRUE and speech synthesis will be enabled
      this.speakFunction("Screan reader activated.")
      const dialogClose = this.dialog.closeAll();
    }
  }

  constructor(
    private localStorageService: LocalStorageService,
    private dialogRef: MatDialogRef<SpeakForBlindPeopleComponent>,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService,
  ) {
    dialogRef.disableClose = true;

   }

  ngOnInit() {
    speechSynthesis.cancel();
    this.isMobile = this.deviceService.isMobile();

    if(!this.isMobile){
      this.textSpeak = " If you are visually impaired person, We recommend you to activate screan reader. This will help you to interact with the system. to confirm double click anywhare on the screen."
    }else{
      this.textSpeak = " If you are visually impaired person, We recommend you to activate screan reader accesibility."
    }
    this.speakFunction("Are you visually impaired |"+ this.textSpeak)

  }

/**
   * Created by Vinita Sharma
   * For speaking the message for blind people
   */
  speakFunction(textToSpeak){
    let speech = new SpeechSynthesisUtterance();
    speech.lang = "hi-IN";
    speech.text = textToSpeak;
    speech.volume = 1;
    speech.rate = 1;
    speech.pitch = 1;                    
    speechSynthesis.speak(speech);
  }

  /**
   * Created by Vinita Sharma
   * For closing the popup
   */
  closeDialog() {
    const dialogClose = this.dialog.closeAll();
    this.localStorageService.setToBlind('false');//This will set the isBlind global global variable to FALSE and speech synthesis will be disabled
    speechSynthesis.cancel();

  }
}
