<!--************************************* 
Created by: Akshata Choulwar
Created Date: 15/04/20
Description: Apply coupon popup
***************************************** -->
<div class="popup" *ngIf="!ifqrCode">
    <mat-icon class="cross-button" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>
   
    <div class="apply-coupon-popup-header" (mouseenter)="speakText(myCartLables[selectedLanguage].enterCouponNumber)"  (mouseleave)="stopSpeaking()">
            <span class="apply-coupon-title" >{{myCartLables[selectedLanguage].enterCouponNumber}}</span>
    </div>
    <div class="popup-container" (mouseenter)="speakText('enter coupon')"  (mouseleave)="stopSpeaking()">
        <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="couponNumber">
        </mat-form-field>
    </div>
    <div class="apply-coupon-popup-button-container">
        <mat-dialog-actions>
            <div class="cancel-button-align" (click) = "applyCoupon()" (mouseenter)="speakText(myCartLables[selectedLanguage].apply)"  (mouseleave)="stopSpeaking()">
                {{myCartLables[selectedLanguage].apply}}
            </div>
            <!-- <div class="ok-button-align">
                <button mat-raised-button >
                    Ok
                    </button>
            </div> -->
            </mat-dialog-actions>
    </div>
</div>


<div class="popup" *ngIf="ifqrCode">
    <mat-icon class="cross-button" aria-hidden="false" (click)="closeDialog()">clear</mat-icon>
   
    <div class="apply-coupon-popup-header">
            <span class="apply-coupon-title" >{{myCartLables[selectedLanguage].enterScanId}}</span>
    </div>
    <div class="popup-container">
        <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="scanValue">
        </mat-form-field>
    </div>
    <div class="apply-coupon-popup-button-container">
        <mat-dialog-actions>
            <div class="cancel-button-align" (click) = "addMerchantTransaction()">
                {{myCartLables[selectedLanguage].ok}}
            </div>
            <!-- <div class="ok-button-align">
                <button mat-raised-button >
                    Ok
                    </button>
            </div> -->
            </mat-dialog-actions>
    </div>
</div>