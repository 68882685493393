<div id="card">
  <section id="image">
    <img *ngIf="data" [src]="getIconByState(data)" alt="money-state" loading="lazy" />
    <img *ngIf="!data" [src]="images.pending" alt="money-state" loading="lazy"  />
  </section>
  <section id="content">
    <div id="pid">{{ data.Consumer_No }}</div>
    <div id="ownerName">
      {{ data.Name }} <span id="ownerState">({{ data.category }})</span>
    </div>
    <div id="address">{{ labels[selectedLanguage].address }}</div>
    <div id="addressField">
      {{ data.Address }}
    </div>
    <div id="due" *ngIf="state === states.PENDING">
      {{ labels[selectedLanguage].Nonewbillatthistime }}
    </div>
    <div id="due" *ngIf="state === states.PAID">
      {{ labels[selectedLanguage].Nonewbillatthistime }}
    </div>
    <div id="due" *ngIf="state === states.DUE">
      {{ labels[selectedLanguage].Newbillreceived }},
      {{ labels[selectedLanguage].payNow }} ₹{{ data.Arrears }}
    </div>
    <div id="btnContainer">
      <div id="viewBtnText" (click)="view(data)">
        <mat-icon style="scale: 0.8; color: black">visibility</mat-icon>
        <span id="buttonText">{{ labels[selectedLanguage].viewProperty }}</span>
      </div>
      <div id="payBtnText" (click)="openPaymentPopup(data)" *ngIf="state === states.DUE">
        <mat-icon style="scale: 0.8; color: white">arrow_forward</mat-icon>
        <span id="buttonText">{{ labels[selectedLanguage].payNow }} ₹{{ data.Arrears }}</span>
     </div>

   
      <!-- <div
        id="payBtnText"
        (click)="pay(data)"
        *ngIf="state === states.DUE"
      >
        <mat-icon style="scale: 0.8; color: white">arrow_forward</mat-icon>
        <span id="buttonText"
          >{{ labels[selectedLanguage].payNow }} ₹{{ data.Arrears }}</span
        >
      </div> -->
    </div>
  </section>
</div>

