<div class="item-container">
  <div class="container" *ngIf="item.iconStyle == 'circle'" >
    <img src="{{ item.iconUrl }}" width="50%" loading="lazy" alt="..." />
  </div>
  <div class="gradient-container" *ngIf="item.iconStyle == 'gradient-effect'">
    <img src="{{ item.iconUrl }}" width="70%" loading="lazy" alt="..." />
  </div>
  <div class="title-container">
    <div class="title">
      {{ item.title }}
    </div>
  </div>

  <!-- <div class="title-container">
    <div class="title" *ngIf=" item.title === 'My Blogs' ">
      {{ drawerLabels[selectedLanguage].myBlogs}}
    </div>
    <div class="title" *ngIf=" item.title === 'My Forums' ">
      {{ drawerLabels[selectedLanguage].myForums }}
    </div>
    <div class="title" *ngIf=" item.title === 'My Deals' ">
      {{ drawerLabels[selectedLanguage].myDeals}}
    </div>
    <div class="title" *ngIf=" item.title === 'Groups' ">
      {{ drawerLabels[selectedLanguage].group}}
    </div>
  </div>
  <div class="title-container" *ngIf="item.iconStyle == 'gradient-effect'">
    <div class="title">
      {{ item.title }}
    </div>
  </div> -->
</div>
