<div id="card">
  <section id="image">
    <img *ngIf="data" [src]="getIconByState(data)" alt="money-state" loading="lazy"  />
    <img *ngIf="!data" [src]="images.PENDING" alt="money-state" loading="lazy"  />
  </section>
  <section id="content">
    <div id="pid">{{ data.ACCT_NO }}</div>
    <div id="ownerName">
      {{ data.BILL_NAME }} <span id="ownerState">({{ data.PROPERTY_TYPE }})</span>
    </div>
    <div id="address">{{ labels[selectedLanguage].address }}</div>
    <div id="addressField">
      {{ data.BILL_ADDRESS }}
    </div>
    <div id="due" *ngIf="state === arrears.PENDING">
      {{ labels[selectedLanguage].Nonewbillatthistime }}
    </div>
    <div id="due" *ngIf="state === arrears.PAID">
      {{ labels[selectedLanguage].Nonewbillatthistime }}
    </div>
    <div id="due" *ngIf="state === arrears.DUE">
      {{ labels[selectedLanguage].Newbillreceived }},
      {{ labels[selectedLanguage].payNow }} ₹{{ data.DUES }}
    </div>
    
    <div id="btnContainer">    
      <!-- <div id="viewBtnText" (click)="view(data)">
        <mat-icon style="scale: 0.8; color: black">visibility</mat-icon>
        <span id="buttonText">{{ labels[selectedLanguage].viewProperty }}</span>
      </div>   -->
      <div
        id="payBtnText"
        (click)="pay(data)"
        *ngIf="state === arrears.DUE"
      >
        <mat-icon style="scale: 0.8; color: white">arrow_forward</mat-icon>
        <span id="buttonText"
          >{{ labels[selectedLanguage].payNow }} ₹{{ data.DUES }}</span
        >
      </div>
    
      <div id="payBtnText" (click)="partialPay(data)" *ngIf="state === arrears.DUE">
        <mat-icon style="scale: 0.8; color: white">payment</mat-icon>
        <span id="buttonText">{{labels[selectedLanguage].partialPayment}}</span>
      </div>
      <div id="viewBtnText" (click)="view(data)">
        <mat-icon style="scale: 0.8; color: black">visibility</mat-icon>
        <span id="buttonText">{{ labels[selectedLanguage].viewProperty }}</span>
      </div>

    </div>   

  </section>
</div>
