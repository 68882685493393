<!--************************************* 
Created by: Neha R
Created Date: 21/04/2020
updated by: Neha R
Updated Date: 21/04/2020
Description: Add Deals screen
***************************************** -->

<!-- loader -->
<div *ngIf="isLoading" class="spinner">
    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="value">
    </mat-progress-spinner>
</div>
<!-- main page -->
<div class="main-container">
    <mat-card class="card" >
        <div class="container">
            <h2>{{addDealsLabels[selectedLanguage].addDeals | uppercase}}</h2>
            <div class="category-card">
                <label><b>{{addDealsLabels[selectedLanguage].selectCategory}} <span class="required">*</span></b></label>
                <mat-form-field appearance="outline">
                    <mat-select placeholder="{{addDealsLabels[selectedLanguage].selectCategory}}" [(ngModel)]="category">
                        <mat-option *ngFor="let category of categoryArray" [value]="category">
                            <span *ngIf="selectedLanguage === 'en' ">{{category.en}}</span>
                            <span *ngIf="selectedLanguage === 'mar' ">{{category.mar}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div><br>

            <div class="container-card">
                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].dealNameInEnglish}}<span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <textarea [maxlength]="80" matInput placeholder="{{addDealsLabels[selectedLanguage].enterDealNameInEnglish}}" [(ngModel)]="englishName"></textarea>
                    </mat-form-field>
                    <label class="widthMaxlength">{{blogLabels[selectedLanguage].noteTitleLengthShouldBeLessThan80Characters}}</label>
                </div>

                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].dealNameInMarathi}}<span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <textarea [maxlength]="80" matInput placeholder="{{addDealsLabels[selectedLanguage].enterDealNameInMarathi}}" [(ngModel)]="marathiName"></textarea>
                    </mat-form-field>
                    <label class="widthMaxlength">{{blogLabels[selectedLanguage].noteTitleLengthShouldBeLessThan80Characters}}</label>
                </div>
            </div><br>

            <div class="container-card">
                <div class="inputCard">
                    <label> <b>{{addDealsLabels[selectedLanguage].dealDescriptionInEnglish}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input matInput  placeholder="{{addDealsLabels[selectedLanguage].enterDealDescriptionInEnglish}}" [(ngModel)]="englishDescription">
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" class="inputArea">
                        <textarea [maxlength]="200" matInput placeholder="{{addDealsLabels[selectedLanguage].enterDealDescriptionInEnglish}}" [(ngModel)]="englishDescription"></textarea>
                    </mat-form-field> -->
                    <label class="widthMaxlength">{{blogLabels[selectedLanguage].noteDescriptionLengthShouldBeLessThan200Characters}}</label>
                </div>
                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].dealDescriptionInMarathi}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input matInput  placeholder="{{addDealsLabels[selectedLanguage].enterDealDescriptionInMarathi}}" [(ngModel)]="marathiDescription">
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" class="inputArea">
                        <textarea [maxlength]="200" matInput placeholder="{{addDealsLabels[selectedLanguage].enterDealDescriptionInMarathi}}" [(ngModel)]="marathiDescription"></textarea>
                    </mat-form-field> -->
                    <label class="widthMaxlength">{{blogLabels[selectedLanguage].noteDescriptionLengthShouldBeLessThan200Characters}}</label>
                </div>
            </div><br>

            <div class="container-card">
                <div class="inputCard">
                    <label> <b>{{addDealsLabels[selectedLanguage].dealFeatureInEnglish}} <span class="required">*</span></b></label>
                    
                    <mat-form-field appearance="outline" class="inputArea">
                        <textarea matInput placeholder="{{addDealsLabels[selectedLanguage].enterDealFeatureInEnglish}}" [(ngModel)]="englishFeature"></textarea>
                    </mat-form-field>
                </div>
                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].dealFeatureInMarathi}} <span class="required">*</span></b></label>
                  
                    <mat-form-field appearance="outline" class="inputArea">
                        <textarea matInput placeholder="{{addDealsLabels[selectedLanguage].enterDealFeatureInMarathi}}" [(ngModel)]="marathiFeature"></textarea>
                    </mat-form-field>
                </div>
            </div><br>
            <!-- for deals terms and conditiond -->
            <div class="container-card">
                <div class="inputCard">
                    <label> <b>{{addDealsLabels[selectedLanguage].termsAndConditionsForDealInEnglish}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <textarea matInput placeholder="{{addDealsLabels[selectedLanguage].termsAndConditionsForDealInEnglish}}" [(ngModel)]="englishTandC"></textarea>
                    </mat-form-field>
                </div>
                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].termsAndConditionsForDealInMarathi}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <textarea matInput placeholder="{{addDealsLabels[selectedLanguage].termsAndConditionsForDealInMarathi}}" [(ngModel)]="marathiTandC"></textarea>
                    </mat-form-field>
                </div>
            </div><br>


            <div class="container-card">
                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].dealPrice}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input type="number" matInput  placeholder="{{addDealsLabels[selectedLanguage].dealPrice}}" [(ngModel)]="dealPrice">
                    </mat-form-field>
                </div>

                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].dealCommissionInPercentage}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input type="number" matInput  placeholder="{{addDealsLabels[selectedLanguage].dealCommissionInPercentage}}" [(ngModel)]="dealCommission">
                    </mat-form-field>
                </div>
            </div><br>

            <div class="container-card">
                <div class="inputCard">
                    <label> <b>{{addDealsLabels[selectedLanguage].totalAmount}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input type="number" matInput placeholder="{{addDealsLabels[selectedLanguage].totalAmount}}" [(ngModel)]="dealAmount">
                    </mat-form-field>
                </div>

                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].sellerName}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input type="text" matInput placeholder="{{addDealsLabels[selectedLanguage].enterSellerName}}" [(ngModel)]="dealSellerName">
                    </mat-form-field>
                </div>
            </div><br>

            <!-- <div class="container-card">
                <div class="inputCard">
                    <label>{{addDealsLabels[selectedLanguage].unitsOfDeals}}</label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input type="number" matInput placeholder="{{addDealsLabels[selectedLanguage].unitsOfDeals}}" [(ngModel)]="dealProduct">
                    </mat-form-field>
                </div>

                <div class="inputCard">
                    <label>{{addDealsLabels[selectedLanguage].availableDeal}}</label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input type="number" matInput placeholder="{{addDealsLabels[selectedLanguage].availableDeal}}" [(ngModel)]="dealAvailableProduct">
                    </mat-form-field>
                </div>
            </div><br> -->
            <div class="container-card">
                <div class="inputCard">
                    <label> <b>{{addDealsLabels[selectedLanguage].quantityOfDeals}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input type="number" matInput placeholder="{{addDealsLabels[selectedLanguage].quantityOfDeals}}" [(ngModel)]="quantity">
                    </mat-form-field>
                </div>

                <div class="inputCard">
                    <label> <b>{{addDealsLabels[selectedLanguage].startDateOfDeal}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input matInput [matDatepicker]="pickerStart" [min]="currentDate" [(ngModel)]="startDate" placeholder="{{addDealsLabels[selectedLanguage].startDateOfDeal}}">
                            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                    </mat-form-field>
                </div>
            </div><br>

            <div class="container-card">
                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].lastDateOfDeal}} <span class="required">*</span></b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input matInput [matDatepicker]="pickerEnd" [min]="currentDate" [(ngModel)]="endDate" placeholder="{{addDealsLabels[selectedLanguage].lastDateOfDeal}}">
                            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="inputCard">
                    <label><b>{{addDealsLabels[selectedLanguage].minimumOrderQuantity}} </b></label>
                    <mat-form-field appearance="outline" class="inputArea">
                        <input type="number" matInput placeholder="{{addDealsLabels[selectedLanguage].minimumOrderQuantity}}" [(ngModel)]="maxDealPerPerson">
                    </mat-form-field>
                </div>
            </div><br>          


            <div class="inputCard">
                <label><b>{{addDealsLabels[selectedLanguage].uploadImagesForDeals}}</b></label>
                <span class="imageSizeGuid">{{addDealsLabels[selectedLanguage].imageSizeShouldNotBeGraterThan15mb}}</span>
                <input id="FileInput" #file class="chooseImage" type="file" accept="image/png,image/jpeg,image/jpg" multiple (change)="preview($event)" />
                <label for="FileInput">
                    <mat-icon class="circle">add_circle</mat-icon>
                </label>
                <div class="spinner-img"  *ngIf="isLoadingPhoto">
                    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="valueImg"></mat-progress-spinner>
                </div>
               <div *ngIf="!isLoadingPhoto">
                    <div *ngIf="imageUrl">
                        <img src="{{imageUrl}}" loading="lazy" alt="..." class="rounded" width="180">
                        <button mat-button (click)="removeImage(i)">{{addDealsLabels[selectedLanguage].remove}}</button>
                    </div>
               </div>
            </div><br>

            <div class="inputCard">
                <label><b>{{addDealsLabels[selectedLanguage].uploadBannerImagesForDeals}}</b></label>
                <span class="imageSizeGuid">{{addDealsLabels[selectedLanguage].imageSizeShouldNotBeGraterThan15mb}}</span>
                <input id="FileInputBanner" #file class="chooseImage" type="file" accept="image/png,image/jpeg,image/jpg" multiple  (change)="prview($event)"  />
                <label for="FileInputBanner" *ngIf="urlsBanner.length<5">
                    <mat-icon class="circle">add_circle</mat-icon>
                </label>
                <div class="spinner-img"  *ngIf="isLoadingBannerPhoto">
                    <mat-progress-spinner [diameter]="35" class="example-margin" [color]="color" [mode]="mode" [value]="valueImg">
                    </mat-progress-spinner>
                </div>
                <div *ngIf="!isLoadingBannerPhoto">
                    <div *ngIf="imageUrlBanner">
                        <img src="{{imageUrlBanner}}"  loading="lazy" alt="..."class="rounded" width="180">
    
                        <button mat-button (click)="removeImageBanner(i)">{{addDealsLabels[selectedLanguage].remove}}</button>
                    </div>
                </div>
            </div><br>

            
            <mat-card class="tags-container">
                <label class="tags-heading"><strong> {{addDealsLabels[selectedLanguage].addTags}}</strong></label>
                <div>
                    <tag-input  [maxlength]="30" placeholder="{{addDealsLabels[selectedLanguage].enterTag}}" [(ngModel)]="items"  [modelAsStrings]="true">
                    </tag-input>
                </div>
            </mat-card><br>
            <div>
                <button mat-button class="submitButton" *ngIf="imageUrl && imageUrlBanner
                    && endDate && category && englishName && marathiName 
                    && englishFeature && marathiFeature && englishDescription && marathiDescription 
                    && dealPrice && dealCommission && dealSellerName && dealAmount && quantity && !isLoadingPhoto && !isLoadingBannerPhoto" (click)="addDeals()">{{addDealsLabels[selectedLanguage].submit}}</button>
            </div>
            <!-- <div>
                <button mat-button class="submitButton"  (click)="addDeals()">{{addDealsLabels[selectedLanguage].submit}}</button>
            </div> -->
        </div>
    </mat-card>
</div>