import { Component, Input, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';

export interface INumberedListItem {
  order: number;
  label: String;
}

@Component({
    selector: 'app-numbered-list',
    templateUrl: './numbered-list.component.html',
    styleUrls: ['./numbered-list.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class NumberedListComponent implements OnInit {

  @Input() list: INumberedListItem[];
  @Input() title: String;
  constructor() { }

  ngOnInit() {
  }

}
