<!-- /*************************************
 * Component -  carousel section
 * Created By - Ajay Chavan
 * Updated By - Akanksha J
 * Updated On - 20/02/2020
 ******************************************/ -->

 <div class="dialogbox">
  <div class="popup-header">
    <div class="popup-heading font-heading"  (mouseenter)="speakText(feedsLabels[selectedLanguage].writeYourFeedback)"  (mouseleave)="stopSpeaking()">
      {{feedsLabels[selectedLanguage].writeYourFeedback}}
    </div>
  </div>
  <div class="popup-container">
    <!-- <star-rating-comp [starType]="'svg'" staticColor="ok" size="large" (onClick)="onClick($event)"
      (onRatingChange)="onRatingChange($event)" (onHoverRatingChange)="onHoverRatingChange($event)"
      [showHalfStars]="true" [step]="0.5" [hoverEnabled]="true">
    </star-rating-comp> -->
    
<div class="rating-container">
  <div class="rating">
    <input type="radio" (click)="changeRating($event)" id="star5" name="rating" value="5" />
    <label class = "full" for="star5" (mouseenter)="speakText('five star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="star4half" name="rating" value="4.5" />
    <label class="half" for="star4half"  (mouseenter)="speakText('4.5 star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="star4" name="rating" value="4" />
    <label class = "full" for="star4" (mouseenter)="speakText('four star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="star3half" name="rating" value="3.5" />
    <label class="half" for="star3half" (mouseenter)="speakText('3.5 star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="star3" name="rating" value="3" />
    <label class = "full" for="star3" (mouseenter)="speakText('three star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="star2half" name="rating" value="2.5" />
    <label class="half" for="star2half" (mouseenter)="speakText('2.5 star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="star2" name="rating" value="2" />
    <label class = "full" for="star2" (mouseenter)="speakText('two star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="star1half" name="rating" value="1.5" />
    <label class="half" for="star1half" (mouseenter)="speakText('1.5 star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="star1" name="rating" value="1" />
    <label class = "full" for="star1" (mouseenter)="speakText('one star rating')"  (mouseleave)="stopSpeaking()"></label>
    <input type="radio" (click)="changeRating($event)" id="starhalf" name="rating" value="0.5" />
    <label class="half" for="starhalf" (mouseenter)="speakText('half star rating')"  (mouseleave)="stopSpeaking()"></label>
  </div>
</div>
    <div class="comment-textarea" (mouseenter)="speakText(feedsLabels[selectedLanguage].writeYourFeedbackHere)"  (mouseleave)="stopSpeaking()">
      <textarea matInput placeholder="{{feedsLabels[selectedLanguage].writeYourFeedbackHere}}" rows="5" cols="45"
        [(ngModel)]="feedbackComment"></textarea>
    </div>
  </div>
  <div class="popup-button-container">
    <div class="align-buttons">
      <button mat-flat-button class="popup-reverse-button" (click)="cancleButton()" (mouseenter)="speakText(feedsLabels[selectedLanguage].notNow)"  (mouseleave)="stopSpeaking()">
        {{feedsLabels[selectedLanguage].notNow}}
      </button>
      <button (click)="hotFeedbackApiCall()" mat-raised-button class="popup-button" (mouseenter)="speakText(feedsLabels[selectedLanguage].submitFeedback)"  (mouseleave)="stopSpeaking()">
        {{feedsLabels[selectedLanguage].submitFeedback}}
      </button>
    </div>
  </div>
</div>
